import { Warning } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import SinglePostAIAnalyzeButton from '@/components/posts/singlePost/postActions/SinglePostAIAnalyzeButton'
import PostInsightKeywords from '@/components/posts/singlePost/postInsights/PostInsightKeywords'
import PostInsightsSentiment from '@/components/posts/singlePost/postInsights/PostInsightsSentiment'
import PostInsightSummary from '@/components/posts/singlePost/postInsights/PostInsightSummary'
import CollapsibleCard from '@/components/shared/common/CollapsibleCard/CollapsibleCard'
import AISparkle from '@/components/shared/icons/AISparkle'
import EmptyState from '@/components/shared/ui/EmptyState'
import { useHNAdminPost } from '@/context/HNAdminPostContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import { getAIPostInsights } from '@/models/Post'
import type { IPostInsights } from '@/types/post'

export default function PostInsights() {
  const t = useTranslations('post')

  const post = useHNAdminPost()
  const [insightsData, setInsightsData] = useState<IPostInsights | null>(null)
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const fetchData = () => {
    setLoading(true)
    return getAIPostInsights(post.slug)
      .then(setInsightsData)
      .catch((error) => {
        setErrorMessage(error.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdateEvent = (data: any) => {
    if (data.entity === 'POST_INSIGHTS' && data.data.id === post.id) {
      setInsightsData(data.data.data)
    }
  }
  useEffect(() => {
    fetchData()
    EventEmitter.subscribe('ENTITY_UPDATE', handleUpdateEvent)
    return () => {
      EventEmitter.unsubscribe('ENTITY_UPDATE', handleUpdateEvent)
    }
  }, [])

  if (loading) {
    return null
  }

  if (!insightsData) {
    return null
  }

  if (errorMessage) {
    return <EmptyState description={errorMessage} customIcon={<Warning />} />
  }

  if (insightsData.sentiment_type === 'unprocessed') {
    return null
  }

  return (
    <CollapsibleCard
      icon={<AISparkle className='h-4 w-4' />}
      collapseLocalKey='post-insights'
      title={t('insights.title')}
      classNames={{
        item: clsx(
          'my-2 rounded-md border border-gray5 bg-gradient-to-br to-snow',
          {
            'from-green3': insightsData.sentiment_type === 'positive',
            'from-red2': insightsData.sentiment_type === 'negative',
            'from-yellow2': insightsData.sentiment_type === 'neutral',
          }
        ),
      }}
      actions={
        <div className='flex items-center gap-2'>
          <PostInsightsSentiment sentiment={insightsData.sentiment_type} />
          <SinglePostAIAnalyzeButton
            post={post}
            buttonProps={{
              size: 'xxs',
            }}
          />
        </div>
      }
    >
      <div className='flex flex-col gap-2'>
        <PostInsightSummary summary={insightsData?.ai_summary} />
        <PostInsightKeywords keywords={insightsData.top_keywords} />
      </div>
    </CollapsibleCard>
  )
}
