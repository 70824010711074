import { type VariantProps, cva } from 'class-variance-authority'
import React from 'react'

import UserAvatar from '@/components/shared/components/UserAvatar'
import Tooltip from '@/components/shared/ui/Tooltip/'

const avatarGroupVariants = cva(
  'z-[1] flex cursor-pointer items-center justify-center rounded-full bg-gray5 ring-2 ring-snow transition-transform duration-200',
  {
    variants: {
      size: {
        xxs: 'h-4 w-4 text-[8px]',
        xs: 'h-6 w-6 text-[10px]',
        sm: 'h-8 w-8 text-xs',
        md: 'h-10 w-10 text-sm',
        lg: 'h-12 w-12 text-base',
        xl: 'h-14 w-14 text-lg',
      },
    },
    defaultVariants: {
      size: 'xs',
    },
  }
)

interface IAvatarPropType extends VariantProps<typeof avatarGroupVariants> {
  avatars: {
    id: string
    name?: string
    profile_picture?: {
      url?: string
    }
  }[]
  limit?: number
  rounded?: boolean
  handleClick?: () => void
  totalCounts: number
  dataTestId?: string
  extras: number
}

export default function AvatarGroup({
  avatars,
  limit = 2,
  size = 'xs',
  rounded,
  handleClick,
  totalCounts,
  dataTestId = '',
  extras = 0,
}: IAvatarPropType) {
  const renderAvatar = (user: any, index: number) => {
    return (
      index < limit && (
        <Tooltip key={user?.id} text={user?.name}>
          <UserAvatar user={user} rounded={rounded} size={size} isMulti />
        </Tooltip>
      )
    )
  }

  const renderCount = () => {
    if (totalCounts > limit && extras > 0) {
      return (
        <span className={avatarGroupVariants({ size })}>{`+${extras}`}</span>
      )
    }
    return false
  }

  return (
    <div
      className='relative flex items-center space-x-[-7%]'
      data-testid={dataTestId}
      onClick={handleClick}
    >
      {avatars?.map(renderAvatar)}
      {renderCount()}
    </div>
  )
}
