import { Circle, X } from '@phosphor-icons/react'
import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import clsx from 'clsx'
import type { FC } from 'react'
import React, { useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Image from '@/components/shared/ui/Image/NextImage'
import { STATUS_COLORS } from '@/config/module/colorConstant'
import { useTranslations } from '@/hooks/useTranslations'
import { changeSpecialCharactersToNormal } from '@/lib/helpers/stringHelpers'

import Spinner from '../Loader'
import type { IVariantTypes } from '../types/element'

const badgeVariants = cva(
  'relative flex max-w-[10rem] items-center transition duration-200 select-none rounded-md font-medium',
  {
    variants: {
      variant: {
        red: 'bg-red3 text-red11 border border-red3',
        yellow: 'bg-yellow3 text-yellow11 border border-yellow3',
        green: 'bg-green3 text-green11 border border-green3',
        gray: 'bg-gray3 dark:bg-gray6 text-gray11 border border-gray3',
        blue: 'bg-blue3 text-blue11 border border-blue3',
        indigo: 'bg-indigo3 text-indigo11 border border-indigo3',
        purple: 'bg-purple3 text-purple11 border border-purple3',
        orange: 'bg-orange3 text-orange11 border border-orange3',
        rose: 'bg-pink3 text-pink11 border border-pink3',
        teal: 'bg-teal3 text-teal11 border border-teal3',
        violet: 'bg-violet3 text-violet11 border border-violet3',
        cyan: 'bg-cyan3 text-cyan11 border border-cyan3',
        outline:
          'border border-gray6 bg-snow dark:bg-gray3 dark:hover:bg-gray4 hover:bg-gray3 text-gray11',
        default:
          'bg-primary dark:bg-primary-light text-snow border border-primary',
      },
      size: {
        xxs: 'px-[0.25rem] py-0.5 text-[10px]',
        xs: 'py-0.5 px-2 text-xs',
        sm: 'px-2.5 py-0.5 text-sm',
        md: 'px-3.5 py-1 text-base',
      },
      rounded: {
        true: 'rounded-full',
      },
      close: {
        true: 'pr-6', // Space for close button
      },
      showDot: {
        true: '', // We'll handle dot spacing in the inner content
      },
      clickable: {
        true: 'transform cursor-pointer',
      },
    },
    defaultVariants: {
      variant: 'gray',
      size: 'xs',
      rounded: false,
      close: false,
      showDot: false,
      clickable: false,
    },
  }
)

interface IAlertTexts {
  title: string
  description: string
  confirmButtonText?: string
  cancelButtonText?: string
}

interface BadgePropType extends VariantProps<typeof badgeVariants> {
  className?: string
  value?: string | number | React.ReactNode
  icon?: React.ReactNode
  suffixIcon?: React.ReactNode
  onClick?: (args: any) => any
  isLoading?: boolean
  data?: any
  onClose?: (args: any) => void
  imageLink?: string
  showAlertBox?: boolean
  alertTexts?: IAlertTexts
  customStyle?: React.CSSProperties
  testId?: string
  containerClassNames?: string
  dotColor?: IVariantTypes
}

export const Badge: FC<BadgePropType> = ({
  variant = 'gray',
  value,
  size = 'xs',
  icon,
  close,
  showDot = false,
  rounded,
  onClick,
  isLoading,
  suffixIcon,
  data,
  onClose,
  className,
  dotColor = 'blue',
  imageLink,
  showAlertBox = false,
  alertTexts,
  customStyle = {},
  testId,
  containerClassNames = '',
}) => {
  const t = useTranslations('buttons')
  const [openAlertDialog, setOpenAlertDialog] = useState(false)

  const handleClick = () => {
    if (onClick && typeof onClick === 'function') onClick(data)
  }

  const handleClose = () => {
    if (showAlertBox && close && onClose && typeof onClose === 'function')
      return setOpenAlertDialog(true)
    if (close && onClose && typeof onClose === 'function') return onClose(data)
    return false
  }

  const handleOnConfirm = () => {
    if (onClose) return onClose(data)
    return null
  }

  const getValue = () => {
    if (value && typeof value === 'string') {
      return changeSpecialCharactersToNormal(value)
    }
    return value
  }

  const getTruncateClass = () => {
    if (value && typeof value === 'string' && value.length > 10) {
      return 'truncate'
    }
    return ''
  }

  if (isLoading) return <Spinner />

  return (
    <span
      style={customStyle}
      className={clsx(
        badgeVariants({
          variant,
          size,
          rounded,
          close,
          showDot,
          clickable: !!onClick && !close,
        }),
        containerClassNames,
        className
      )}
    >
      <span
        onClick={handleClick}
        data-testid={testId}
        className={clsx('flex items-center overflow-auto', getTruncateClass(), {
          'cursor-pointer': !!onClick,
        })}
      >
        {!close && <span className='absolute inset-0' />}
        {showDot && !icon && (
          <Circle
            weight='fill'
            color={STATUS_COLORS?.find((c) => c.value === dotColor)?.hex}
            className='shrink-0'
          />
        )}
        {icon}
        {imageLink && (
          <Image
            src={imageLink}
            className='h-3 w-3'
            alt={value as string}
            height={8}
            width={8}
          />
        )}
        {value !== undefined && value !== null && (
          <span className='truncate px-1 font-medium'>{getValue()}</span>
        )}
        {suffixIcon}
      </span>
      {close && (
        <X
          data-testid={`${value}_badge_close_button`}
          className='absolute right-2 top-1/2 h-3 w-3 shrink-0 -translate-y-1/2 cursor-pointer rounded-full hover:opacity-80'
          onClick={handleClose}
        />
      )}
      <AlertDialog
        type='danger'
        open={openAlertDialog}
        title={alertTexts?.title}
        description={alertTexts?.description}
        confirmText={alertTexts?.confirmButtonText || t('confirm')}
        cancelText={alertTexts?.cancelButtonText || t('cancel')}
        onConfirm={() => handleOnConfirm()}
        onCancel={() => setOpenAlertDialog(false)}
      />
    </span>
  )
}
