import { Plus } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'

import AdminIntegrationHellonextSyncRule from '@/components/admin/settings/Integrations/config/Shared/AdminIntegrationHellonextSyncRule'
import AdminIntegrationNewSyncRule from '@/components/admin/settings/Integrations/config/Shared/AdminIntegrationNewSyncRule'
import AdminIntegrationProviderSyncRule from '@/components/admin/settings/Integrations/config/Shared/AdminIntegrationProviderSyncRule'
import Button from '@/components/shared/ui/Button'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { cloneArray } from '@/lib/helpers/dataHelpers'
import { parseStatusSyncData } from '@/lib/helpers/modules/Integration/syncHelper'
import type { IAsanaIntegrationConfig } from '@/types/integration/asana'
import type {
  IIntegrationStatusSyncData,
  IStatusSyncRule,
} from '@/types/integration/statusSync'

interface IAdminIntegrationAsanaSyncStatusProps {
  configData: IAsanaIntegrationConfig
  onRemove: (newSyncRules: IStatusSyncRule[]) => Promise<void>
  onAdd: (newSyncRule: IStatusSyncRule[]) => void
}

export default function AdminIntegrationAsanaSyncRule({
  configData,
  onRemove,
  onAdd,
}: IAdminIntegrationAsanaSyncStatusProps) {
  const t = useTranslations(`integrations.asana`)
  const [syncRules, setSyncRules] = useState(configData.sync_rules)
  const [showNewRule, setShowNewRule] = useState(false)
  const syncData = parseStatusSyncData(
    configData,
    'asana'
  ) as IIntegrationStatusSyncData

  const handleNewSyncRule = (syncRule: IStatusSyncRule) => {
    setSyncRules([...syncRules, syncRule])
    onAdd([...syncRules, syncRule])
    setShowNewRule(false)
  }

  const handleRemoveSyncRule = (index: number) => {
    const newSyncRules = cloneArray(syncRules)
    newSyncRules.splice(index, 1)
    setSyncRules(newSyncRules)
    onRemove(newSyncRules)
  }

  const handleCloseSyncRule = () => {
    setShowNewRule(false)
  }

  useEffect(() => {
    setSyncRules(configData.sync_rules)
  }, [configData.sync_rules])

  return (
    <div className='mt-4 border-t border-gray5 pt-4 '>
      <div className='flex items-center justify-between space-x-2'>
        <div className='flex flex-col'>
          <Typography.Text className='mb-0 !font-medium !text-carbon '>
            {t(`configure.syncRule.title`)}
          </Typography.Text>
          <Typography.Text className='mb-3 mt-1 !text-sm'>
            {t(`configure.syncRule.description`)}
          </Typography.Text>
        </div>
        <span className='flex items-center'>
          <Button
            size='xs'
            icon={<Plus weight='bold' />}
            onClick={() => setShowNewRule(true)}
            variant='outline'
          >
            {t(`configure.syncRule.add`)}
          </Button>
        </span>
      </div>
      <>
        {syncRules.map((syncRule: IStatusSyncRule, index: number) => {
          if (syncRule.source === 'hellonext') {
            return (
              <AdminIntegrationHellonextSyncRule
                key={index}
                provider='asana'
                configData={syncData}
                syncRule={syncRule}
                edit={false}
                handleRemove={handleRemoveSyncRule}
                index={index}
                newRule={false}
                useCustomStates={configData.sync_by_custom_state}
              />
            )
          }

          if (syncRule.source === 'asana') {
            return (
              <AdminIntegrationProviderSyncRule
                key={index}
                provider='asana'
                configData={syncData}
                syncRule={syncRule}
                edit={true}
                handleRemove={handleRemoveSyncRule}
                index={index}
                newRule={false}
                useCustomStates={configData.sync_by_custom_state}
              />
            )
          }

          return <></>
        })}
      </>
      <AdminIntegrationNewSyncRule
        showSyncRule={showNewRule}
        syncRule={configData.default_sync_rule as any}
        configData={syncData}
        provider='asana'
        onAdd={handleNewSyncRule}
        onCancel={handleCloseSyncRule}
        useCustomStates={configData.sync_by_custom_state}
      />
    </div>
  )
}
