import { Plus } from '@phosphor-icons/react'
import React, { useContext, useMemo } from 'react'

import Status from '@/components/posts/Status'
import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectPropTypes,
} from '@/components/shared/ui/Select/Select'
import HNContext from '@/context/HNContext'
import type { IFilters } from '@/types/common'
import type { ICustomState, ICustomStatusItems } from '@/types/organization'
import type { IPostListAPIParams } from '@/types/post'

const FILTER_KEY = 'state'

export interface IAdminPostStatusFilterPropType {
  onChange?: (key: string, value: any) => void
  filters: IFilters | IPostListAPIParams
  multiple?: boolean
  placeholder?: string
  disabled?: boolean
  behaviour: string
  clearable?: boolean
  showSelectedOptionsCount?: boolean
  variant?: 'blank' | 'outline' | 'dashed'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  itemFilter?: (item: ICustomState) => boolean
  selectProps?: Partial<ISelectPropTypes>
}

const statusOptions = (st: ICustomState, customStatus: ICustomStatusItems) => ({
  label: st.name,
  value: st.slug,
  group: customStatus[st.status],
  icon: <Status iconOnly status={{ ...st, value: st.name }} />,
  className: `font-medium text-${st.slug}`,
  status: {
    title: `${st.name}`,
    value: st.slug,
    ...st,
  },
})

export default function AdminPostStatusFilter({
  onChange,
  filters,
  multiple = true,
  placeholder = '',
  behaviour = 'filter',
  disabled = false,
  clearable = false,
  showSelectedOptionsCount = true,
  size = 'xs',
  variant = 'outline',
  itemFilter,
  selectProps = {},
}: IAdminPostStatusFilterPropType) {
  const { custom_states = [], customStatus = {} } = useContext(HNContext)

  const options = useMemo(
    () =>
      custom_states
        .sort((a, b) => a.position - b.position)
        .filter(itemFilter || (() => true))
        .map((st) => statusOptions(st, customStatus)),
    [custom_states, customStatus]
  )

  const handleChange = (value: ISelectOption | ISelectOption[] | undefined) => {
    if (!onChange) return
    if (!value) {
      onChange(FILTER_KEY, [])
      return
    }
    const selectedValue = Array.isArray(value) ? value : [value]
    const selectedStatus = selectedValue.map((item) => item.value)
    onChange(FILTER_KEY, selectedStatus)
  }

  const value = useMemo(() => {
    if (filters && filters[FILTER_KEY]) {
      const filteredStatus = Array.isArray(filters[FILTER_KEY])
        ? filters[FILTER_KEY]
        : [filters[FILTER_KEY]]
      return options.filter((s) =>
        filteredStatus.map((id) => id.toString()).includes(s.value.toString())
      )
    }
    return null
  }, [filters, options])

  return (
    <>
      <Select
        {...selectProps}
        disabled={disabled}
        size={size}
        searchable
        variant={variant}
        value={value}
        group
        clearable={clearable}
        onChange={handleChange}
        options={options}
        multiple={multiple}
        placeholder={placeholder.toString()}
        placeholderIcon={behaviour === 'filter' ? <></> : <Plus />}
        inputProps={{
          placeholder,
        }}
        avoidSort
        dataTestId='admin_post_status_filter'
        showSelectedOptionsCount={showSelectedOptionsCount}
      />
    </>
  )
}
