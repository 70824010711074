import { X } from '@phosphor-icons/react'
import React, { useMemo } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'
import { removePostVote, removeSubscriber } from '@/models/Post'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  user: IUserProfile
  type: string
  onRemove: (user: IUserProfile) => void
}

export default function AdminSingleUserRemove({
  post,
  user,
  type,
  onRemove,
}: IPropTypes) {
  const t = useTranslations('')

  const handleRemoveSubscriber = (data: IUserProfile) => {
    return removeSubscriber(post.slug, {
      email: data.email,
    })
      .then(() => {
        onRemove(user)
        toaster.success({ message: t('posts.voteMessages.subscriberRemoved') })
      })
      .catch((err) => {
        toaster.error({ message: err.message })
      })
  }
  const handleRemoveVote = (data: IUserProfile) => {
    return removePostVote(post.slug, data.id)
      .then(() => {
        onRemove(user)
        toaster.success({ message: t('posts.voteMessages.voteRemoved') })
      })
      .catch((err) => {
        toaster.error({ message: err.message })
      })
  }

  const handleRemove = (data: IUserProfile) => {
    if (type === 'subscribers') {
      return handleRemoveSubscriber(data)
    }
    return handleRemoveVote(data)
  }

  const translationPrefix = useMemo(() => {
    if (type === 'subscribers') {
      return 'post.messages.removeSubscriber.alertTexts'
    }
    return 'post.messages.removeVotes.alertTexts'
  }, [type])

  return (
    <AlertDialog
      title={t(`${translationPrefix}.title`, {
        data: {
          userName: user.name,
          userMail: user.email,
          voteType:
            type === 'upvote'
              ? t('post.labels.upvote')?.toLowerCase()
              : t('post.labels.downvote')?.toLowerCase(),
        },
      })}
      descriptionComponent={t(`${translationPrefix}.description`)}
      type='danger'
      onConfirm={() => handleRemove(user)}
      confirmText={t('buttons.remove', { ignorePrefix: true })}
    >
      <Button
        size='xs'
        variant='text'
        fab
        icon={<X weight='bold' />}
        dataTestId={`admin_single_user_vote_remove_${user.id}`}
      />
    </AlertDialog>
  )
}
