import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import Spinner from '@/components/shared/ui/Loader'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getIntegrationConfig,
  updateIntegrationConfig,
} from '@/models/Integration'
import type {
  IGithubConfigUpdatePayload,
  IGithubConfigurationProps,
  IGithubIntegrationConfig,
  IGithubPushRule,
  IIntegrationPushRuleKey,
  IIntegrationPushRuleValue,
} from '@/types/integration/github'
import type { IStatusSyncRule } from '@/types/integration/statusSync'
import toaster from '@/utils/toast'

import AdminIntegrationGithubPushRule from './Github/AdminIntegrationGithubPushRule'
import AdminIntegrationGithubSyncStatus from './Github/AdminIntegrationGithubSyncStatus'

export default function GithubConfiguration({
  onDisconnect,
}: IGithubConfigurationProps) {
  const t = useTranslations(`integrations.github`)
  const router = useRouter()
  const { provider, integrated } = router.query as {
    provider: string
    integrated: string
  }
  const [showConfig, setShowConfig] = useState(false)
  const [configData, setConfigData] = useState<IGithubIntegrationConfig>()
  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [autoPushRule, setAutoPushRule] = useState<IGithubPushRule>()

  const fetchConfiguration = () => {
    if (!configData) {
      setIsLoading(true)
    } else {
      setIsSyncing(true)
    }
    return getIntegrationConfig('github')
      .then((data: IGithubIntegrationConfig) => {
        if (configData) {
          setConfigData({
            ...configData,
            ...data,
          })
        } else {
          setConfigData(data)
        }
        setAutoPushRule(data.push_rule)
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => {
        setIsLoading(false)
        setIsSyncing(false)
      })
  }

  const handlePushRuleChange = (
    key: IIntegrationPushRuleKey,
    value: IIntegrationPushRuleValue
  ) => {
    const newRule = {
      ...autoPushRule,
      [key]: value,
    } as IGithubPushRule
    setAutoPushRule(newRule)
  }

  const handleUpdateConfig = (config: IGithubConfigUpdatePayload) => {
    setIsSubmitting(true)
    return updateIntegrationConfig(
      'github',
      configData?.id.toString() || '',
      config
    )
      .then((data) => {
        setConfigData(data)
        setAutoPushRule(data.push_rule)
        toaster.success({
          message: t(`messages.successfullySaved`, {
            ignorePrefix: true,
          }),
        })
      })
      .catch((error) => toaster.error({ message: error.message }))
      .finally(() => setIsSubmitting(false))
  }

  const handleRemoveSyncRule = (newSyncRules: IStatusSyncRule[]) => {
    const config = {
      push_rule: autoPushRule,
      rules: newSyncRules,
    } as IGithubConfigUpdatePayload
    return handleUpdateConfig(config)
  }

  const handleSubmit = () => {
    const config = {
      push_rule: autoPushRule,
    } as IGithubConfigUpdatePayload
    return handleUpdateConfig(config)
  }

  const handleDialogClose = () => {
    setShowConfig(false)
    setAutoPushRule(configData?.push_rule)
  }

  const handleToggleCustomStatus = (checked: boolean) => {
    if (!configData) return null
    return updateIntegrationConfig('github', configData.id.toString(), {
      sync_by_custom_state: checked,
    }).then((updatedSyncData) => {
      setConfigData(updatedSyncData)
    })
  }

  useEffect(() => {
    if (provider === 'github' && integrated === 'true') {
      setShowConfig(true)
    }
  }, [])

  useEffect(() => {
    if (showConfig) fetchConfiguration()
  }, [showConfig])

  const renderLoadingOrEmpty = () => {
    if (isLoading) return <Spinner />
    return (
      <EmptyState
        title={t('messages.something_went_wrong', { ignorePrefix: true })}
      />
    )
  }

  return (
    <Dialog open={showConfig} onClose={() => handleDialogClose()}>
      <DialogTrigger asChild>
        <Button size='xs' onClick={() => setShowConfig(true)}>
          {t('buttons.configure', {
            ignorePrefix: true,
          })}
        </Button>
      </DialogTrigger>
      <DialogContent size='lg' backdrop>
        <DialogTitle>
          <div className='flex items-center justify-between'>
            {t(`configure.title`)}
            <div className='flex items-center gap-2'>
              {isSyncing && <SpinnerIcon className='h-4 w-4 animate-spin' />}
              {!!configData && (
                <Checkbox
                  isSwitch
                  defaultChecked={configData?.sync_by_custom_state}
                  onCheckedChange={handleToggleCustomStatus}
                  alertTexts={{
                    title: 'Are sure you want to use custom states?',
                    description:
                      'All your sync rule will be used to sync custom statuses.',
                  }}
                  disabledAlertTexts={{
                    title: 'Use parent status for sync?',
                    description:
                      'All your sync rule will be used to sync parent statuses.',
                  }}
                  label='Use custom states'
                />
              )}
            </div>
          </div>
        </DialogTitle>
        {isLoading || !configData || !autoPushRule ? (
          renderLoadingOrEmpty()
        ) : (
          <>
            <AdminIntegrationGithubPushRule
              configData={configData}
              onChange={handlePushRuleChange}
              pushRule={autoPushRule}
              isSubmitting={isSubmitting}
              onSave={handleSubmit}
              useCustomStates={configData.sync_by_custom_state}
            />
            <AdminIntegrationGithubSyncStatus
              configData={configData}
              onRemove={handleRemoveSyncRule}
            />
            <div className='mb-2 mt-4 flex w-full justify-end space-x-2'>
              <AlertDialog
                type='danger'
                confirmText={t(`buttons.disconnect`, {
                  ignorePrefix: true,
                })}
                title={t(`disconnectConfirmationTitle`)}
                description={t(`disconnectConfirmationMessage`)}
                onConfirm={onDisconnect}
              >
                <Button variant='danger' size='xs' disabled={isSubmitting}>
                  {t(`buttons.disconnect`, {
                    ignorePrefix: true,
                  })}
                </Button>
              </AlertDialog>
            </div>
          </>
        )}
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
