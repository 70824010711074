export const COMMENT_REACTIONS = [
  {
    id: '1',
    name: '+1',
    emoji: '👍',
  },
  {
    id: '3',
    name: 'smile',
    emoji: '😄',
  },
  {
    id: '4',
    name: 'tada',
    emoji: '🎉',
  },
  {
    id: '6',
    name: 'heart',
    emoji: '❤️',
  },
  {
    id: '7',
    name: 'rocket',
    emoji: '🚀',
  },
  {
    id: '8',
    name: 'eyes',
    emoji: '👀',
  },
  {
    id: '9',
    name: 'fire',
    emoji: '🔥',
  },
]

export type IReaction = typeof COMMENT_REACTIONS[0]
