import React, { useEffect, useState } from 'react'

import PowerUpPurchaseButton from '@/components/admin/powerups/PowerUpPurchaseButton'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import type { IEntityUpdateEventData } from '@/lib/eventEmitter'
import { ENTITIES, EventEmitter } from '@/lib/eventEmitter'
import { intercomTrackEvent } from '@/lib/helpers/appHelpers'

export default function AdminMigrateToPaddleModal() {
  const t = useTranslations('billing')
  const [openDialog, setOpenDialog] = useState(false)

  const handleOpenDialog = (eventData: IEntityUpdateEventData) => {
    const { entity } = eventData
    if (entity !== ENTITIES.TOGGLE_MIGRATE_MODAL) return null
    return setOpenDialog(true)
  }

  useEffect(() => {
    EventEmitter.subscribe('CONFIG_PUSH', handleOpenDialog)
    return () => {
      EventEmitter.unsubscribe('CONFIG_PUSH', handleOpenDialog)
    }
  }, [])

  useEffect(() => {
    if (openDialog) intercomTrackEvent('MIGRATE_WITHOUT_POWERUP')
  }, [openDialog])

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogContent size='sm'>
        <DialogTitle>{t('migrateToPaddle')}</DialogTitle>
        <DialogClose />
        <div className='mt-3 flex flex-col'>
          <Typography.Text
            dangerouslySetInnerHTML={{ __html: t('migrateToPaddleAlertText') }}
          />
          <div className='float-right mt-10'>
            <PowerUpPurchaseButton
              variant='warning'
              migrate={true}
              disabled={false}
              loading={false}
              powerUps={null}
              interval={undefined}
              onClose={() => {}}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
