import { ArrowLeft } from '@phosphor-icons/react'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import ForgetPasswordModal from '@/components/auth/actions/ForgetPasswordModal'
import AuthProviders from '@/components/auth/providers/AuthProviders'
import PasskeyButton from '@/components/auth/providers/PasskeyButton'
import SignIn from '@/components/auth/screens/SignIn'
import SignUp from '@/components/auth/screens/SignUp'
import AuthModalHome from '@/components/auth/shared/authModal/AuthModalHome'
import ResendConfirmation from '@/components/auth/shared/authModal/ResendConfirmation'
import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
} from '@/components/shared/ui/Dialog/Dialog'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import { objectHasProperty } from '@/lib/helpers/dataHelpers'
import type { IAuthProviders } from '@/types/organization'

interface IPropTypes {
  children?: React.ReactNode
  noTrigger?: boolean
  hideWhenSingleAuth?: boolean
  disableShortcut?: boolean
  inline?: boolean
}
export default function AuthModal({
  children = <></>,
  noTrigger = false,
  hideWhenSingleAuth = false,
  disableShortcut,
  inline,
}: IPropTypes) {
  const t = useTranslations()
  const { auth } = useContext(HNContext)

  const [open, setOpen] = useState(false)
  const [page, setPage] = useState('home')

  const availableAuths = useMemo(() => {
    if (!auth) return []
    return (Object.keys(auth) as Array<keyof typeof auth>).filter(
      (key: IAuthProviders) => objectHasProperty(auth, key) && auth[key]
    )
  }, [auth])

  const updatedChildren =
    children &&
    React.cloneElement(children as React.ReactElement, {
      onClick: () => setOpen(true),
    })

  const handleEventHandler = (data: any) => {
    if (data.action !== 'TOGGLE_AUTH_MODAL') return
    setOpen(!open)
  }

  useEffect(() => {
    if (disableShortcut) return () => {}
    EventEmitter.subscribe('CONFIG_PUSH', handleEventHandler)
    return () => {
      EventEmitter.unsubscribe('CONFIG_PUSH', handleEventHandler)
    }
  }, [])

  const renderNav = (title: string) => {
    return (
      <div className='mb-4 flex items-center'>
        {page !== 'home' && (
          <Button
            fab
            variant='text'
            icon={<ArrowLeft weight='bold' />}
            onClick={() => setPage('home')}
          />
        )}
        <span className='pl-2'>{title}</span>
      </div>
    )
  }

  const renderContent = () => {
    switch (page) {
      case 'signin':
        return (
          <>
            {renderNav(t('auth.labels.signIn'))}
            <SignIn showSocialLogins={false} isModal onNavigation={setPage} />
            <div className='mt-2 grid gap-2'>
              <Button
                variant='secondary'
                size='sm'
                onClick={() => setPage('resendConfirmation')}
              >
                {t('auth.labels.resendConfirmation')}
              </Button>
              <Button
                variant='secondary'
                size='sm'
                onClick={() => setPage('signup')}
              >
                {t('auth.labels.signUp')}
              </Button>
            </div>
          </>
        )
      case 'signup':
        return (
          <>
            {renderNav(t('auth.labels.signUp'))}
            <div className='mt-2 grid gap-2'>
              <SignUp showSocialLogins={false} isModal />
              <Button
                size='sm'
                variant='secondary'
                block
                onClick={() => setPage('signin')}
              >
                {t('auth.signUp.signinButton')}
              </Button>
            </div>
          </>
        )
      case 'resendConfirmation':
        return (
          <>
            {renderNav(t('auth.accountConfirmation.title'))}
            <ResendConfirmation onNavigation={setPage} />
          </>
        )
      case 'forgotPassword':
        return (
          <>
            {renderNav(t('auth.forgotPassword.title'))}
            <ForgetPasswordModal />
          </>
        )
      case 'home':
      default:
        return (
          <>
            {renderNav(
              `${t('auth.labels.signIn')} / ${t('auth.labels.signUp')}`
            )}
            {auth?.email && (
              <>
                <AuthModalHome onNavigation={setPage} />
                {/* <div className='my-2 border-b ' /> */}
              </>
            )}

            <AuthProviders isModal />
          </>
        )
    }
  }

  useEffect(() => {
    if (open) {
      setPage('home')
    }
  }, [open])

  const onlyButtons = useMemo(() => {
    const auths = availableAuths.filter((item) => item !== 'passkey')
    return auths.length === 1 && auths.find((item) => item !== 'email')
  }, [availableAuths])

  if (
    hideWhenSingleAuth &&
    availableAuths.filter((item) => item !== 'passkey').length === 1
  )
    return null

  if (inline) return renderContent()

  return (
    <div>
      {!noTrigger && onlyButtons ? (
        <div className='flex items-center gap-1'>
          {availableAuths.includes('passkey') && (
            <Tooltip text={t('auth.labels.signInWithPasskey')}>
              <div>
                <PasskeyButton onlyButtons isModal />
              </div>
            </Tooltip>
          )}
          <div>
            <AuthProviders onlyButtons />
          </div>
        </div>
      ) : (
        updatedChildren
      )}
      <Dialog open={open} modal={true} onClose={() => setOpen(false)}>
        <DialogContent className='!max-w-[20.3rem]' backdrop>
          {renderContent()}
          <DialogClose />
        </DialogContent>
      </Dialog>
    </div>
  )
}
