import { BroadcastChannel } from 'broadcast-channel'

import type { IEventType } from '@/lib/eventEmitter'
import { EventEmitter } from '@/lib/eventEmitter'

export const broadcastChannel = new BroadcastChannel('HELLONEXT_CHANNEL')

const channelListener = (event: MessageEvent) => {
  const { entityType, data } = event.data as {
    entityType: IEventType
    data: any
  }
  return EventEmitter.dispatch(entityType, data)
}

broadcastChannel.addEventListener('message', channelListener)

export const broadcastPostMessage = (entityType: IEventType, data: any) => {
  try {
    broadcastChannel?.postMessage({ entityType, data }).catch((error) => {
      console.error('Error in broadcasting message', error)
    })
  } catch {
    console.error('Error in broadcasting message')
  }
}
