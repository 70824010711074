import React, { useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { capitalize } from '@/lib/helpers/stringHelpers'
import { updateIntegrationConfig } from '@/models/Integration'
import type {
  IAdminSlackIntegrationMapping,
  ISlackIntegrationConfig,
} from '@/types/integration/slack'
import toaster from '@/utils/toast'

interface IAdminIntegrationSlackAdditionalConfigProps {
  configData: ISlackIntegrationConfig
  integrationMapping: IAdminSlackIntegrationMapping
  onChange: (data: ISlackIntegrationConfig) => void
}
export default function AdminIntegrationSlackAdditionalConfig({
  configData,
  onChange,
}: IAdminIntegrationSlackAdditionalConfigProps) {
  const translationMapping = `integrations.slack.configure.config`
  const t = useTranslations(translationMapping)
  const [formData, setFormData] = useState({
    notifications_rules: configData.notifications_rules,
  })
  const [isUpdating, setIsUpdating] = useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsUpdating(true)
    return updateIntegrationConfig('slack', configData.id.toString(), {
      notification_rules: formData.notifications_rules,
    })
      .then((config: ISlackIntegrationConfig) => {
        onChange(config)
        toaster.success({
          message: capitalize(
            t(`messages.changesSuccessfullySaved`, {
              ignorePrefix: true,
            }).toLowerCase()
          ),
        })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsUpdating(false))
  }

  return (
    <div className='my-3'>
      <div className='flex flex-col'>
        <Typography.Text className='mb-0.5 mt-2 !font-medium !text-gray11 '>
          {t(`title`)}
        </Typography.Text>
        <Typography.Text className='mb-2 text-gray8'>
          {t(`description`)}
        </Typography.Text>
      </div>
      <form className='flex items-center gap-2' onSubmit={handleSubmit}>
        <Input
          value={formData.notifications_rules.custom_description_length}
          placeholder={t(`placeholder`)}
          onChange={(e) => {
            const isNumberRegex = /^\d+$/
            if (isNumberRegex.test(e.target.value) || e.target.value === '') {
              setFormData({
                ...configData,
                notifications_rules: {
                  ...configData.notifications_rules,
                  custom_description_length: e.target.value,
                },
              })
            }
          }}
        />
        <Button type='submit' disabled={isUpdating}>
          {t('buttons.save', { ignorePrefix: true })}
        </Button>
      </form>
    </div>
  )
}
