import React, { useEffect, useState } from 'react'

import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import { cleanCannedResponseForSelect } from '@/lib/helpers/modules/commentHelper'
import type { ICannedResponse } from '@/models/CannedResponse'
import { getCannedResponses } from '@/models/Comment'
import type { IBoardDetails } from '@/types/board'
import type { IPostBucket } from '@/types/post'

interface IPropTypes {
  board?: IPostBucket | IBoardDetails
  onSelect: (response: ISelectOption | ISelectOption[] | undefined) => void
  selectedResponse?: ISelectOption
}
export default function CannedResponseDropdown({
  board,
  onSelect,
  selectedResponse: _selectedResponse,
}: IPropTypes) {
  const t = useTranslations('comments.newComment.cannedResponse')
  // const [cannedResponses, setCannedResponses] = useState<ISelectOption[]>([])
  const [selected, setSelected] = useState(_selectedResponse)

  const fetchCannedResponses = () => {
    return getCannedResponses({
      bucket_id: board?.id,
      visible: true,
    })
      .then((newCannedResponses: ICannedResponse[]) =>
        newCannedResponses.map(cleanCannedResponseForSelect)
      )
      .then((responses) => {
        return responses.map((cr) => ({
          ...cr,
          group:
            cr.source === 'organization'
              ? t('groups.global')
              : t('groups.board', {
                  data: {
                    boardName: board?.display_name || '',
                  },
                }),
        }))
      })
  }
  useEffect(() => {
    setSelected(_selectedResponse)
  }, [_selectedResponse])

  return (
    <div className='min-w-0 sm:min-w-[10rem]'>
      <Select
        size='xs'
        loadOptions={fetchCannedResponses}
        placeholder={t('placeholder')}
        onChange={onSelect}
        clearable
        value={selected ? [selected] : null}
        width='min-w-0 sm:min-w-[10rem] max-w-[10rem]'
        group
      />
    </div>
  )
}
