import { ArrowUpRight } from '@phosphor-icons/react'
import { LockIcon } from '@primer/octicons-react'
import React, { useContext } from 'react'

import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'

interface IProps {
  title?: string
  link?: string
}
export default function AsideHeader({ title, link }: IProps) {
  const t = useTranslations()

  const { organization, organizationSetting } = useContext(HNContext)

  return (
    <div className='flex items-center justify-between border-b border-b-gray5 bg-snow p-[9.5px] transition-colors duration-200 dark:border-gray-dark-border dark:bg-gray1'>
      <div className='flex w-full items-center justify-between overflow-hidden'>
        <div className='flex flex-1 items-center space-x-1 truncate'>
          <>
            <Typography.Title className='truncate font-semibold' level={5}>
              {title || organization?.name}
            </Typography.Title>
            {organizationSetting?.organization_privacy && (
              <p className='flex items-center text-xs leading-tight text-red10'>
                <LockIcon size={10} />
                <span className='hidden pl-0.5'>
                  {t('common.privacy.private')}
                </span>
              </p>
            )}
          </>
        </div>
        {link && (
          <UnstyledLink
            href={link}
            target='_blank'
            className='block rounded bg-elementHighlight p-1 hover:bg-elementHighlightSecondary'
          >
            <ArrowUpRight size={12} />
          </UnstyledLink>
        )}
      </div>
    </div>
  )
}
