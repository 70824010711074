import { Calendar } from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import DatePicker from '@/components/shared/ui/DatePicker'
import Input from '@/components/shared/ui/Input'
import Textarea from '@/components/shared/ui/Textarea'
import { DATE_FORMAT } from '@/config/appConstants'
import { USER_ATTRIBUTE_FIELD_TYPES } from '@/config/module/userConstants'
import { useHNAdminPost } from '@/context/HNAdminPostContext'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { formatDate } from '@/lib/helpers/dateHelpers'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import { convertMarkdownLinksToHtml } from '@/lib/helpers/stringHelpers'
import {
  addPostCustomField,
  getPostCustomFields,
  updatePostCustomField,
} from '@/models/Post'
import type {
  IBucketMetaCustomField,
  IOrganizationPlan,
} from '@/types/organization'
import toaster from '@/utils/toast'

type ICustomFieldValue = {
  id?: string
  value?: string | number | boolean | null
  custom_field: IBucketMetaCustomField
}

const CustomFieldValue = ({
  field,
  onSubmit,
}: {
  field: ICustomFieldValue
  onSubmit: (value: string | number | boolean | null) => Promise<void>
}) => {
  const t = useTranslations()
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(field.value)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { userProfile } = useContext(HNContext)

  const customFieldMeta = useMemo(() => {
    return USER_ATTRIBUTE_FIELD_TYPES.find(
      (type) => type.value === field.custom_field.field_type
    )
  }, [field.custom_field.field_type])

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (value === undefined) return
      setIsSubmitting(true)
      await onSubmit(value)
        .then(() => {
          setIsEditing(false)
        })
        .catch(toaster.error)
        .finally(() => {
          setIsSubmitting(false)
        })
    },
    [onSubmit, value]
  )

  const editButton = useMemo(() => {
    if (!userProfile?.is_member_of_organization) {
      return null
    }
    if (isEditing) {
      return (
        <div className='flex items-center gap-2'>
          <Button
            variant='primary'
            size='xxs'
            disabled={isSubmitting}
            type='submit'
            loading={isSubmitting}
          >
            {isSubmitting ? t('buttons.saving') : t('buttons.save')}
          </Button>
          <Button
            variant='secondary'
            type='button'
            size='xxs'
            disabled={isSubmitting}
            onClick={() => setIsEditing(false)}
          >
            {t('buttons.cancel')}
          </Button>
        </div>
      )
    }
    return (
      <Button
        variant='secondary'
        size='xxs'
        type='button'
        onClick={() => setIsEditing(true)}
      >
        {t('buttons.edit')}
      </Button>
    )
  }, [isEditing, field.custom_field.field_type, isSubmitting])

  const renderField = useCallback(() => {
    if (field.custom_field.field_type === 'short_text') {
      return isEditing ? (
        <Input
          size='xxs'
          defaultValue={value?.toString() || ''}
          autoFocus
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <p className='cursor-pointer'>{value}</p>
      )
    }
    if (field.custom_field.field_type === 'long_text') {
      return isEditing ? (
        <Textarea
          defaultValue={value?.toString() || ''}
          autoFocus
          onChange={(e) => setValue(e.target.value)}
        />
      ) : (
        <p className='cursor-pointer'>{value}</p>
      )
    }
    if (field.custom_field.field_type === 'integer') {
      return isEditing ? (
        <Input
          size='xxs'
          defaultValue={Number.isNaN(Number(value)) ? '' : value?.toString()}
          autoFocus
          type='number'
          onChange={(e) => {
            const val = e.target.value as string
            if (val === '' || !Number.isNaN(Number(val))) {
              setValue(Number(val))
            } else {
              setValue(0)
            }
          }}
        />
      ) : (
        <p className='cursor-pointer'>{value}</p>
      )
    }
    if (field.custom_field.field_type === 'boolean') {
      return (
        <Checkbox
          defaultChecked={Boolean(value)}
          onCheckedChange={(newState) => {
            setValue(newState)
          }}
          disabled={!isEditing}
        />
      )
    }

    if (field.custom_field.field_type === 'date') {
      return (
        <DatePicker
          mode='single'
          onDaySelect={(day) => {
            setValue(formatDate(day, DATE_FORMAT))
          }}
          placeholder={
            field.custom_field.meta_info?.placeholder || 'Select Date'
          }
          disabled={!isEditing}
          renderCustomTrigger={
            <div className='flex items-center gap-1 rounded-lg border border-gray5 px-2'>
              <Calendar weight='fill' />
              <p className='text-xs'>
                {value
                  ? formatDate(new Date(String(value)), DATE_FORMAT)
                  : 'No Date'}
              </p>
            </div>
          }
          numberOfMonths={1}
          rounded={false}
          showInPopOver
          range={{
            from: value ? new Date(String(value)) : undefined,
          }}
        />
      )
    }
    return <p>{value}</p>
  }, [field.value, isEditing, value])

  return (
    <tr className='border-b border-gray4'>
      <td className='p-2'>
        <div className='flex items-center gap-2'>
          {customFieldMeta?.typeIcon && (
            <customFieldMeta.typeIcon className='size-4' />
          )}
          <span
            className='[&_a]:text-primary [&_a]:underline'
            dangerouslySetInnerHTML={{
              __html: convertMarkdownLinksToHtml(field.custom_field.title),
            }}
          />
        </div>
      </td>
      <td className='p-2'>
        <form
          className='flex items-center justify-between gap-2'
          onSubmit={handleSubmit}
        >
          {renderField()}
          {editButton}
        </form>
      </td>
    </tr>
  )
}

export default function SinglePostCustomFields() {
  const { slug, bucket } = useHNAdminPost()
  const { buckets_meta } = useContext(HNContext)
  const { organizationPlan } = useContext(HNContext)
  const bucketMeta = useMemo(() => {
    if (!slug) return null
    return buckets_meta?.find(
      (meta) => meta.bucket_id.toString() === bucket.id?.toString()
    )
  }, [buckets_meta, slug, bucket.id])

  const { data: customFields, isLoading } = useQuery<
    ICustomFieldValue[],
    Error
  >({
    queryKey: ['customFields', slug],
    queryFn: () => getPostCustomFields(slug),
    enabled: featureIsEnabled(
      'custom_fields',
      organizationPlan as IOrganizationPlan
    ),
  })

  const handleAddCustomField = (
    field: ICustomFieldValue,
    value: string | number | boolean | null
  ) => {
    return addPostCustomField(slug, {
      custom_field_slug: field.custom_field.slug,
      value,
    })
  }

  const handleUpdate = (
    field: ICustomFieldValue,
    value: string | number | boolean | null
  ) => {
    if (!field.id) return Promise.resolve()
    return updatePostCustomField(slug, field.id, {
      value,
    })
  }

  const handleSubmit = (
    field: ICustomFieldValue,
    value: string | number | boolean | null
  ) => {
    if (field?.id) {
      return handleUpdate(field, value)
    }
    return handleAddCustomField(field, value)
  }
  const renderCustomFieldValue = (field?: ICustomFieldValue) => {
    if (!field) return '-'
    return (
      <CustomFieldValue
        field={field}
        onSubmit={(value) => handleSubmit(field, value)}
      />
    )
  }

  if (isLoading) return null
  if (!bucketMeta || !bucketMeta?.custom_fields.length) return null

  if (customFields) {
    return (
      <table className='min-w-full table-fixed divide-y divide-gray4'>
        <thead className='bg-gray1'>
          <tr>
            <th className='p-2 text-left text-xs font-medium uppercase tracking-wider'>
              Field
            </th>
            <th className='p-2 text-left text-xs font-medium uppercase tracking-wider'>
              Value
            </th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray4'>
          {bucketMeta?.custom_fields.map((field) =>
            renderCustomFieldValue(
              customFields.find((f) => f.custom_field.id === field.id) || {
                custom_field: field,
                value: null,
              }
            )
          )}
        </tbody>
      </table>
    )
  }

  return <div>SinglePostCustomFields</div>
}
