import { ArrowRight } from '@phosphor-icons/react'
import React, { useContext, useEffect, useState } from 'react'

import IconPopover from '@/components/shared/components/IconPopover'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/shared/ui/Accordion/Accordion'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Spinner from '@/components/shared/ui/Loader'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  customRoadmapAdminPath,
  customRoadmapUserPath,
} from '@/lib/helpers/pathHelpers'
import { getPostRoadmaps } from '@/models/Post'
import type { IPost } from '@/types/post'
import type { IRoadmap } from '@/types/roadmap'

interface IPropTypes {
  post: IPost
}
export default function SinglePostRoadmaps({ post }: IPropTypes) {
  const { organizationSetting, isAdmin } = useContext(HNContext)
  const t = useTranslations('post')

  const [roadmaps, setRoadmaps] = useState<IRoadmap[]>([])
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const fetchRoadmaps = () => {
    getPostRoadmaps(post.slug)
      .then(setRoadmaps)
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchRoadmaps()
  }, [])

  const renderRoadmap = (roadmap: IRoadmap) => (
    <UnstyledLink
      key={roadmap.id}
      href={
        isAdmin
          ? customRoadmapAdminPath(roadmap.slug)
          : customRoadmapUserPath(roadmap.slug)
      }
    >
      <div className='my-2 flex items-center justify-between rounded bg-gray4 p-2 transition duration-200 hover:bg-gray5/50   '>
        <div className='flex items-center space-x-2'>
          <IconPopover icon={roadmap.icon} readonly />
          <Typography.Text className='!font-medium !text-gray11 '>
            {roadmap.title}
          </Typography.Text>
        </div>
        <ArrowRight weight='bold' />
      </div>
    </UnstyledLink>
  )

  const renderContent = () => {
    if (loading) return <Spinner />
    if (errorMessage) return <Typography.Text>{errorMessage}</Typography.Text>
    if (roadmaps.length === 0)
      return <Typography.Text>{t('fields.roadmaps.empty')}</Typography.Text>
    return roadmaps.map(renderRoadmap)
  }

  return (
    <div className='border-t border-gray5 px-4 py-1.5'>
      <Accordion type='multiple'>
        <AccordionItem value='_voter'>
          <AccordionTrigger>
            <Typography.Text className='!font-medium'>
              {organizationSetting?.roadmap_name}
            </Typography.Text>
          </AccordionTrigger>
          <AccordionContent>
            <div className='mt-2'>{renderContent()}</div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
