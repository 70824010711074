import React, { useEffect, useRef } from 'react'

import useIntersectionObserver from '@/hooks/useIntersectionObserver'
import { useTranslations } from '@/hooks/useTranslations'

import Button from '../ui/Button'
import Spinner from '../ui/Loader'

interface IPropTypes {
  isButton?: boolean
  showIf: boolean
  threshold?: number
  canFetchMore: boolean
  enabled?: boolean
  onIntersect: () => any
  count?: number
  pageLoading?: boolean
  loaderComponent?: React.ReactNode
}

export default function InfiniteScroll({
  isButton = false,
  showIf = false,
  canFetchMore = true,
  onIntersect = () => null,
  threshold = 0.1,
  count = 0,
  pageLoading = false,
  loaderComponent,
}: IPropTypes) {
  const t = useTranslations('buttons')
  const loadMoreElementRef = useRef<HTMLDivElement | null>(null)

  useEffect(
    () => () => {
      loadMoreElementRef.current = null
    },
    []
  )

  useIntersectionObserver(
    {
      target: loadMoreElementRef,
      onIntersect: () => onIntersect(),
      enabled: canFetchMore,
      threshold,
    },
    [showIf && canFetchMore]
  )

  if (canFetchMore) {
    if (typeof window.IntersectionObserver === 'undefined' || isButton) {
      return (
        <div className='flex items-center justify-center pt-8'>
          <Button variant='outline' onClick={() => onIntersect()}>
            {pageLoading && canFetchMore ? `${t('loading')}...` : t('loadMore')}
          </Button>
        </div>
      )
    }
    return (
      <div
        ref={showIf ? loadMoreElementRef : null}
        data-testid='infinite_scroll_trigger'
      >
        {loaderComponent || <Spinner isScreen={count === 0} />}
      </div>
    )
  }
  return <></>
}
