import clsx from 'clsx'
import { useContext } from 'react'

import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import { DOMAIN } from '@/config/appConstants'
import { featureOSLogoURL } from '@/config/staticURLs'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'

export default function PoweredByButton() {
  const t = useTranslations()
  const { organizationCustomization, organization, organizationPlan } =
    useContext(HNContext)

  if (!organization) return <></>
  if (
    organizationCustomization &&
    !organizationCustomization.display_branded_content &&
    organizationPlan &&
    organizationPlan.days_left !== 'Expired'
  )
    return <></>

  return (
    <div className='fixed inset-x-0 bottom-0 whitespace-nowrap rounded-t-lg text-center md:left-auto md:right-32'>
      <UnstyledLink
        target='_blank'
        href={`https://${DOMAIN}?utm_source=feedback_subdomain&product=${organization.name}`}
        className={clsx(
          'inline-flex items-center justify-center space-x-1 rounded-t-lg border-x border-t border-gray7 bg-snow px-3 py-1 text-center text-xs font-medium tracking-normal text-gray11 shadow-xl duration-200 hover:border-fobase/50 hover:bg-fobase/5 hover:text-gray12 hover:no-underline hover:shadow-none md:text-xs'
        )}
      >
        <img
          src={featureOSLogoURL}
          alt='Feedback board'
          className='h-[16px] w-[16px]'
        />
        <div>{t('common.labels.branding.poweredBy')}</div>
      </UnstyledLink>
    </div>
  )
}
