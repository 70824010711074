import type { AxiosError, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import qs from 'qs'

import { SIGN_IN_WITH_TOKEN_INTERNAL_PATH } from '@/config/routes'
import { sentry } from '@/lib/helpers/appHelpers'
import { removeNullOrUndefinedKeys } from '@/lib/helpers/dataHelpers'
import type { IRequestHeaders } from '@/types/common'

// import { deleteLocalHashData } from './cache'

// const resetSession = () => {
//   // eslint-disable-next-line @typescript-eslint/no-use-before-define
//   return API.delete(SIGN_OUT_INTERNAL_PATH)
// }

const handleErrors = (error: AxiosError | any) => {
  const message =
    error?.code === 'ERR_NETWORK' ? 'ERROR_CODE_ERR_NETWORK' : error?.message
  let errorObject: any = {
    code: error.code || error.status,
    message: message || 'ERROR_CODE_UNABLE_TO_COMPLETE',
  }

  if (error.code === 'ERROR_CODE_ERR_NETWORK') {
    sentry.exception(new Error(`Network Error: ${error}`))
    const networkErr = {
      ...errorObject,
      message: 'Network Error. Please check your connection and try again!',
    }
    return Promise.reject(networkErr)
  }

  if (error.response) {
    const { status, data } = error.response
    if (status === 401) {
      errorObject = {
        ...errorObject,
        ...error.response.data,
        message: error.response.data.message,
      }
      if (typeof window !== 'undefined' && window.location) {
        try {
          const isWidget = error.response.config.headers['x-widget-key']
          if (isWidget) return Promise.reject(errorObject)
        } catch (err) {
          console.error(err)
        }
        return Promise.reject(errorObject)
        // return resetSession().then(() => {
        //   // window.location.href = '/auth/signin'
        //   return Promise.reject(errorObject)
        // })
      }
    } else if (
      status === 403 &&
      data.message !== 'ERROR_CODE_RESOURCE_POST_RESTRICTED_ACCESS'
    ) {
      errorObject = {
        ...errorObject,
        ...error.response.data,
        ...error,
        message:
          'You do not have access to this page. Please contact your administrator!',
      }
      if (typeof window !== 'undefined' && window.location) {
        // deleteLocalHashData()
        return Promise.reject(errorObject)
      }
    } else if (status === 422) {
      errorObject = {
        ...errorObject,
        ...error.response.data,
        message: 'Unable to process this request, please try again later',
      }
    } else {
      errorObject = {
        ...errorObject,
        ...error.response.data,
      }
    }
    return Promise.reject(errorObject)
  }
  errorObject = {
    ...errorObject,
    message: 'Network Error. Please check your connection and try again!',
  }
  return Promise.reject(errorObject)
}

export default class API {
  static setHeaders(headers: IRequestHeaders = {}): IRequestHeaders {
    axios.defaults.headers.common = removeNullOrUndefinedKeys({
      ...axios.defaults.headers.common,
      ...headers,
    })
    return {
      ...axios.defaults.headers.common,
      ...headers,
    }
  }

  static handleNewToken = (responseJson: any) => {
    const accessToken = responseJson.headers['access-token']
    if (accessToken) {
      return API.post(
        SIGN_IN_WITH_TOKEN_INTERNAL_PATH,
        {
          token: accessToken,
        },
        {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
          },
        }
      ).then(() => {
        if (accessToken) {
          API.setHeaders({
            Authorization: `Bearer ${accessToken}`,
          })
        }
        return responseJson
      })
    }
    return responseJson
  }

  static setBaseURL(url: string) {
    if (url) {
      axios.defaults.baseURL = url
    }
  }

  static get(
    route: string,
    params: any = {},
    otherOptions: AxiosRequestConfig = {}
  ): Promise<any> {
    return this.xhr(route, params, 'GET', otherOptions)
  }

  static put(
    route: string,
    params: any = {},
    otherOptions: AxiosRequestConfig = {}
  ): Promise<any> {
    return this.xhr(route, params, 'PUT', otherOptions)
  }

  static post(
    route: string,
    params: any = {},
    otherOptions: AxiosRequestConfig = {}
  ): Promise<any> {
    return this.xhr(route, params, 'POST', otherOptions)
  }

  static postWithForm(
    route: string,
    params: any = {},
    otherOptions: AxiosRequestConfig = {}
  ): Promise<any> {
    return this.xhr(route, qs.stringify(params), 'POST', {
      ...otherOptions,
      headers: {
        ...(otherOptions.headers || {}),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  }

  static postWithFiles(
    route: string,
    params: FormData,
    otherOptions: AxiosRequestConfig = {}
  ): Promise<any> {
    return this.xhr(route, params, 'POST', {
      ...otherOptions,
      headers: {
        ...(otherOptions.headers || {}),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  }

  static putWithFiles(
    route: string,
    params: FormData,
    otherOptions: AxiosRequestConfig = {}
  ): Promise<any> {
    return this.xhr(route, params, 'PUT', {
      ...otherOptions,
      headers: {
        ...(otherOptions.headers || {}),
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
  }

  static delete(
    route: string,
    params: any = {},
    otherOptions: AxiosRequestConfig = {}
  ): Promise<any> {
    return this.xhr(route, params, 'DELETE', otherOptions)
  }

  static patch(
    route: string,
    params: any,
    otherOptions: AxiosRequestConfig = {}
  ): Promise<any> {
    return this.xhr(route, params, 'PATCH', otherOptions)
  }

  static xhr(
    route: string,
    params: any,
    verb: AxiosRequestConfig['method'],
    otherOptions: AxiosRequestConfig = {}
  ): Promise<any> {
    const dataOption: AxiosRequestConfig = {}
    if (params && verb === 'GET') {
      dataOption.params = params
    } else {
      dataOption.data = params
    }
    const options = { method: verb, url: route, ...dataOption, ...otherOptions }
    console.warn(`${verb}: ${route}`)
    return axios(options)
      .then(API.handleNewToken)
      .then((responseJson) => responseJson.data)
      .catch(handleErrors)
  }
}
