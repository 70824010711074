import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'

import AdminPostBoardFilter from '@/components/admin/posts/filters/AdminPostBoardFilter'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import Label from '@/components/shared/ui/Label'
import Spinner from '@/components/shared/ui/Loader'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { templateStringReplace } from '@/lib/helpers/stringHelpers'
import {
  getIntegrationConfig,
  updateIntegrationConfig,
} from '@/models/Integration'
import type { IBoardDetails } from '@/types/board'
import type {
  IAzureConfigButtonProps,
  IAzureConfigInformation,
  IAzureIntegrationConfig,
} from '@/types/integration/azure_ad'
import type { IOrganizationSetting } from '@/types/organization'
import toaster from '@/utils/toast'

export default function AzureConfiguration({
  onDisconnect,
  integration,
}: IAzureConfigButtonProps) {
  const t = useTranslations(`integrations.azureAd`)
  const router = useRouter()
  const { provider, integrated } = router.query
  const { organizationSetting } = useContext(HNContext) as {
    organizationSetting: IOrganizationSetting
  }
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, toggleUpdating] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [configData, setConfigData] = useState<IAzureIntegrationConfig>()
  const [showConfig, setShowConfig] = useState(false)

  const fetchConfig = () => {
    if (!configData) {
      setIsLoading(true)
    } else {
      setIsSyncing(true)
    }
    getIntegrationConfig('azure_ad')
      .then((data) => {
        if (configData) {
          setConfigData({ ...configData, ...data })
        } else {
          setConfigData(data)
        }
      })
      .catch((err) => toaster.error(err.message))
      .finally(() => {
        setIsLoading(false)
        setIsSyncing(false)
      })
  }

  useEffect(() => {
    fetchConfig()
  }, [])

  useEffect(() => {
    if (provider === 'azure_ad' && integrated === 'true') {
      setShowConfig(true)
    }
  }, [])

  useEffect(() => {
    if (showConfig) fetchConfig()
  }, [showConfig])

  const renderLoadingOrEmpty = () => {
    if (isLoading) return <Spinner />
    return <EmptyState title={t('integrations.no_config')} />
  }

  const updateInformation = (info: {
    information: IAzureConfigInformation
  }) => {
    if (isUpdating) return null
    if (!configData) return null

    toggleUpdating(true)
    return updateIntegrationConfig('azure_ad', configData.id.toString(), info)
      .then((data: IAzureIntegrationConfig) => {
        setConfigData(data)
        toaster.success({
          message: t('messages.successfullySaved', {
            ignorePrefix: true,
          }),
        })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => toggleUpdating(false))
  }

  const handleBoardsUpdate = (board_ids: string[]) => {
    if (!configData) return null

    const info = {
      information: {
        ...configData.information,
        add_to_boards: board_ids,
      },
    }
    return updateInformation(info)
  }

  return (
    <Dialog open={showConfig} onClose={() => setShowConfig(false)}>
      <DialogTrigger asChild>
        <Button size='xs' onClick={() => setShowConfig(true)}>
          {t('buttons.configure', { ignorePrefix: true })}
        </Button>
      </DialogTrigger>
      <DialogContent size='lg' backdrop>
        <DialogTitle>
          <div className='mr-8 flex items-center justify-between'>
            {t(`configure.title`)}
            {isSyncing && <SpinnerIcon className='h-4 w-4 animate-spin' />}
          </div>
        </DialogTitle>
        {!configData || isLoading ? (
          renderLoadingOrEmpty()
        ) : (
          <div className='mt-4'>
            <Typography.Text className='mb-2 text-sm text-gray8 '>
              {templateStringReplace(t(`configure.description`), {
                board: organizationSetting.bucket_singular_name,
                boards: organizationSetting.bucket_plural_name,
                name: configData.tenant.name,
              })}
            </Typography.Text>
            <div className='mt-4 flex justify-between border-t border-gray5 pt-4'>
              <div className='flex flex-col justify-between'>
                <Typography.Text className='!font-medium !text-gray11 '>
                  {templateStringReplace(t(`configure.boardSetting.title`), {
                    boards: organizationSetting.bucket_plural_name,
                  })}
                </Typography.Text>
                <p
                  dangerouslySetInnerHTML={{
                    __html: templateStringReplace(
                      t(`configure.boardSetting.description`),
                      {
                        board:
                          organizationSetting.bucket_singular_name.toLowerCase(),
                      }
                    ),
                  }}
                  className='mb-3 mt-1 text-sm text-gray8 '
                />
              </div>
              <span className='w-44'>
                <AdminPostBoardFilter
                  onChange={(_key, value) => {
                    handleBoardsUpdate(value)
                  }}
                  itemFilter={(item: IBoardDetails) => item.private}
                  multiple
                  filters={{ bucket_id: configData.information.add_to_boards }}
                  placeholder={t(`configure.boardSetting.placeholder`, {
                    data: {
                      board:
                        organizationSetting.bucket_singular_name.toLowerCase(),
                    },
                  })}
                />
              </span>
            </div>
            <div className='mt-4 flex items-start justify-between border-t border-gray5 pt-4'>
              <div>
                <Label
                  htmlfor={t(`configure.interactorAccess.title`)}
                  className='!text-sm !font-medium !text-gray11 '
                >
                  {t(`configure.interactorAccess.title`)}
                </Label>
                <Typography.Text className='mb-3 !text-sm !text-gray8 '>
                  {t(`configure.interactorAccess.description`)}
                </Typography.Text>
              </div>
              <Checkbox
                isSwitch
                disabled={isUpdating}
                id={t(`configure.interactorAccess.title`)}
                checked={configData.information.customer_access}
                onCheckedChange={(checked) => {
                  const info = {
                    information: {
                      ...configData.information,
                      customer_access: checked,
                    },
                  }
                  updateInformation(info)
                }}
              />
            </div>
            <div className='mt-4 border-t border-gray5 pt-4 '>
              <div className='my-2 flex flex-col'>
                <Typography.Text className='!text-base !font-medium !text-gray11 '>
                  {t(`configure.revokeAccess.title`)}
                </Typography.Text>
                <Typography.Text className='mb-3 !text-sm !text-gray8 '>
                  {t(`configure.revokeAccess.description`)}
                </Typography.Text>
              </div>
              <div className='space-y-5'>
                <div className='flex items-start justify-between'>
                  <div>
                    <Label
                      className='!text-sm !font-medium !text-gray11 '
                      htmlfor={t(
                        `configure.revokeAccess.revokeMemberAccess.title`
                      )}
                    >
                      {t(`configure.revokeAccess.revokeMemberAccess.title`)}
                    </Label>

                    <Typography.Text className='mb-3 !text-sm !text-gray8 '>
                      {t(
                        `configure.revokeAccess.revokeMemberAccess.description`
                      )}
                    </Typography.Text>
                  </div>
                  <Checkbox
                    isSwitch
                    disabled={isUpdating}
                    id={t(`configure.revokeAccess.revokeMemberAccess.title`)}
                    checked={configData.information.revoke_access}
                    onCheckedChange={(checked) => {
                      const info = {
                        information: {
                          ...configData.information,
                          revoke_access: checked,
                        },
                      }
                      updateInformation(info)
                    }}
                  />
                </div>
                <div className='flex items-start justify-between'>
                  <div>
                    <Label
                      className='!text-sm !font-medium !text-gray11 '
                      htmlfor={t(
                        `configure.revokeAccess.revokeBoardAccess.title`
                      )}
                    >
                      {t(`configure.revokeAccess.revokeBoardAccess.title`)}
                    </Label>
                    <Typography.Text className='mb-3 !text-sm !text-gray8 '>
                      {templateStringReplace(
                        t(
                          `configure.revokeAccess.revokeBoardAccess.description`
                        ),
                        {
                          board: organizationSetting.bucket_singular_name,
                        }
                      )}
                    </Typography.Text>
                  </div>
                  <Checkbox
                    isSwitch
                    disabled={isUpdating}
                    id={t(`configure.revokeAccess.revokeBoardAccess.title`)}
                    checked={configData.information.revoke_board_access}
                    onCheckedChange={(checked) => {
                      const info = {
                        information: {
                          ...configData.information,
                          revoke_board_access: checked,
                        },
                      }
                      updateInformation(info)
                    }}
                  />
                </div>
                <div className='flex items-start justify-between'>
                  <div>
                    <Label
                      className='!text-sm !font-medium !text-gray11 '
                      htmlfor={t(
                        `configure.revokeAccess.revokeInteractorAccess.title`
                      )}
                    >
                      {t(`configure.revokeAccess.revokeInteractorAccess.title`)}
                    </Label>
                    <Typography.Text className='mb-3 !text-sm !text-gray8 '>
                      {templateStringReplace(
                        t(
                          `configure.revokeAccess.revokeInteractorAccess.description`
                        ),
                        {
                          board: organizationSetting.bucket_singular_name,
                        }
                      )}
                    </Typography.Text>
                  </div>
                  <Checkbox
                    isSwitch
                    disabled={isUpdating}
                    id={t(
                      `configure.revokeAccess.revokeInteractorAccess.title`
                    )}
                    checked={configData.information.revoke_customer_access}
                    onCheckedChange={(checked) => {
                      const info = {
                        information: {
                          ...configData.information,
                          revoke_customer_access: checked,
                        },
                      }
                      updateInformation(info)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='mt-4 flex justify-end gap-2'>
              <Button
                variant='text'
                size='xs'
                as='a'
                href={integration.connect_path}
              >
                {t(`buttons.reconnect`, {
                  ignorePrefix: true,
                })}
              </Button>
              <AlertDialog
                type='danger'
                confirmText={t(`buttons.disconnect`, {
                  ignorePrefix: true,
                })}
                title={t(`disconnectConfirmationTitle`)}
                description={t(`disconnectConfirmationMessage`)}
                onConfirm={onDisconnect}
              >
                <Button variant='danger' size='xs' disabled={isUpdating}>
                  {t(`buttons.disconnect`, {
                    ignorePrefix: true,
                  })}
                </Button>
              </AlertDialog>
            </div>
          </div>
        )}
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
