import clsx from 'clsx'
import type { IKeyValueMap } from 'mobx'
import { useRouter } from 'next/router'
import React from 'react'

import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import { pushQueryToMatchRoute } from '@/lib/helpers/pathHelpers'

interface IPropTypes {
  post: {
    slug: string
  }
  children: React.ReactNode
  className?: string
  newTabOpen?: boolean
  requiredQueryValues?: IKeyValueMap
  onClick?: () => void
}

export default function PostLink({
  post,
  children,
  className = '',
  newTabOpen = false,
  requiredQueryValues = {},
  ...rest
}: IPropTypes) {
  const router = useRouter()
  const isAdmin = router.pathname.includes('/admin')

  const classNames = clsx('hover:no-underline', className)
  return (
    <UnstyledLink
      href={pushQueryToMatchRoute(post.slug, {
        ...router.query,
        ...requiredQueryValues,
      })}
      as={isAdmin ? `/admin/p/${post.slug}` : `/p/${post.slug}`}
      className={classNames}
      target={newTabOpen ? '_blank' : undefined}
      {...rest}
    >
      {children}
    </UnstyledLink>
  )
}
