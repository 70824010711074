import { Info, Plug, ToggleLeft, X } from '@phosphor-icons/react'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import AdminExportPostVotersButton from '@/components/admin/posts/AdminExportPostVotersButton'
import AuthenticatedView from '@/components/auth/shared/AuthenticatedView'
import PushToAsana from '@/components/posts/singlePost/postActions/integrations/asana/PushToAsana'
import PushToGitlab from '@/components/posts/singlePost/postActions/integrations/gitlab/PushToGitlab'
import OpportunityAddons from '@/components/posts/singlePost/postActions/integrations/salesforce/OpportunityAddons'
import SinglePostManageOptions from '@/components/posts/singlePost/postActions/SinglePostManageOptions'
import SinglePostPriorityChange from '@/components/posts/singlePost/postActions/SinglePostPriorityChange'
import SinglePostReadOnlyStatus from '@/components/posts/singlePost/postActions/SinglePostReadOnlyStatus'
import SinglePostRoadmaps from '@/components/posts/singlePost/postActions/SinglePostRoadmaps'
import SinglePostScoreAction from '@/components/posts/singlePost/postActions/SinglePostScoreAction'
import SinglePostSentimentChange from '@/components/posts/singlePost/postActions/SinglePostSentimentChange'
import SinglePostActionsLoader from '@/components/posts/singlePost/SinglePostActionsLoader'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { canShowIntegrationBlock } from '@/lib/helpers/modules/postHelper'
import { checkRoadmapPath } from '@/lib/helpers/pathHelpers'
import type { IIntegrations } from '@/types/organization'
import type { IPost } from '@/types/post'

import SinglePostUnmergeModal from './mergepost/SinglePostUnmergeModal'
import PushToClickUp from './postActions/integrations/clickUp/PushToClickUp'
import PushToGithub from './postActions/integrations/github/PushToGithub'
import PushToJira from './postActions/integrations/jira/PushToJira'
import PushToLinear from './postActions/integrations/linear/PushToLinear'
import SinglePostAssigneeChange from './postActions/SinglePostAssigneeChange'
import SinglePostBoardChange from './postActions/SinglePostBoardChange'
import SinglePostDownvoters from './postActions/SinglePostDownvoters'
import SinglePostETC from './postActions/SinglePostETC'
import SinglePostLinkedChangelog from './postActions/SinglePostLinkedChangelog'
import SinglePostPostedBy from './postActions/SinglePostPostedBy'
import SinglePostPostedOn from './postActions/SinglePostPostedOn'
import SinglePostPriorityProgress from './postActions/SinglePostPriorityProgress'
import SinglePostSubscribeAction from './postActions/SinglePostSubscribeAction'
import SinglePostTagsAction from './postActions/SinglePostTagsAction'
import SinglePostUpvoters from './postActions/SinglePostUpvoters'
import SinglePostUserSubscribeButton from './postActions/SinglePostUserSubscribeButton'
import SinglePostVoteOnBehalf from './postActions/SinglePostVoteOnBehalf'

const SinglePostStatusChange = dynamic(
  import('./postActions/SinglePostStatusChange'),
  {
    ssr: false,
  }
)

interface IPropTypes {
  post: IPost
  toggleEdit?: () => void
  onClose?: () => void
  onCloseMenu?: () => void | undefined
}

export default function SinglePostActions({
  post,
  toggleEdit,
  onClose,
  onCloseMenu,
}: IPropTypes) {
  const {
    isAdmin,
    default_module,
    organizationCustomization,
    userProfile,
    organizationPlan,
    integrations,
  } = useContext(HNContext)
  const t = useTranslations('post')

  const router = useRouter()
  const handleEdit = () => {
    if (toggleEdit) toggleEdit()
  }

  if (!post) return <SinglePostActionsLoader />

  const showIntegrations = canShowIntegrationBlock(
    integrations as IIntegrations
  )

  const renderContent = () => (
    <div className='flex flex-col gap-2 px-4'>
      {userProfile?.is_member_of_organization && (
        <div className='fo-meta-card-wrapper'>
          <div className='fo-meta-card-header'>
            <div className='flex items-center gap-x-1'>
              <Plug className='h-4 w-4' />
              <div>{t('integrations.title')}</div>
            </div>
            {isAdmin && (
              <div>
                <Link
                  href='/admin/settings/integrations'
                  className='inline-flex items-center gap-1'
                >
                  <Info className='h-3 w-3' />
                  <div className='text-xs'>Learn More</div>
                </Link>
              </div>
            )}
          </div>
          {showIntegrations ? (
            <div className='mt-2 grid grid-cols-1 gap-2 px-4 pb-3 pt-1 md:grid-cols-2'>
              <PushToAsana post={post} />
              <PushToLinear post={post} />
              <PushToClickUp post={post} />
              <PushToGithub post={post} />
              <PushToGitlab post={post} />
            </div>
          ) : (
            <div className='mt-2 grid grid-cols-1 gap-2 px-4 pb-3 pt-1 text-gray9 md:grid-cols-2'>
              No integrations yet
            </div>
          )}
        </div>
      )}
      <div className='fo-meta-card-wrapper'>
        <div className='fo-meta-card-header'>
          <div className='flex items-center gap-1'>
            <Info className='h-4 w-4' />
            <div>{t('labels.info')}</div>
          </div>
          <AdminExportPostVotersButton post={post} />
        </div>
        <div className='mt-2 flex flex-col gap-y-5 px-4 pb-3'>
          {!isAdmin && <SinglePostUserSubscribeButton post={post} />}
          <SinglePostUpvoters post={post} />
          <SinglePostDownvoters post={post} />
        </div>
      </div>
      <div className='fo-meta-card-wrapper'>
        <div className='fo-meta-card-header'>
          <div className='flex items-center  gap-x-1'>
            <ToggleLeft className='h-4 w-4' />
            {userProfile?.is_csm_of_organization ? (
              <div>{t('labels.manage')}</div>
            ) : (
              <div>{t('labels.details')}</div>
            )}
          </div>
          <div className='z-[5] flex items-center justify-end gap-1 pt-4 lg:pt-0'>
            <SinglePostReadOnlyStatus post={post} />
            {post.can_edit &&
              !isAdmin &&
              !checkRoadmapPath(router) &&
              (default_module === 'feedback' ||
                Boolean(
                  default_module === 'roadmap' && router.pathname !== '/'
                )) && (
                <SinglePostManageOptions
                  aside
                  post={post}
                  onEdit={handleEdit}
                  onClose={onClose}
                />
              )}
          </div>
        </div>
        <div className='mt-2 flex flex-col gap-5 pb-3'>
          <SinglePostStatusChange post={post} />
          <SinglePostAssigneeChange post={post} />
          <SinglePostBoardChange post={post} />
          {isAdmin && <SinglePostSentimentChange post={post} />}
          <SinglePostPriorityChange post={post} />
          <SinglePostTagsAction post={post} />
          <SinglePostPostedOn post={post} />
          <SinglePostPostedBy post={post} />
          <SinglePostETC post={post} />
          <AuthenticatedView shouldShow={!!post.can_unmerge}>
            <SinglePostUnmergeModal post={post} />
          </AuthenticatedView>
          <SinglePostLinkedChangelog post={post} />
          <div>
            <SinglePostSubscribeAction post={post} />
            <PushToJira post={post} />
            <SinglePostVoteOnBehalf post={post} />
            <AuthenticatedView
              shouldShow={
                !!(
                  organizationCustomization?.roadmap_type === 'custom' &&
                  userProfile?.is_csm_of_organization &&
                  organizationPlan?.allow_multiple_roadmaps
                )
              }
            >
              <SinglePostRoadmaps post={post} />
              <SinglePostScoreAction post={post} />
            </AuthenticatedView>

            <OpportunityAddons post={post} />
          </div>
          <SinglePostPriorityProgress post={post} />
        </div>
      </div>
    </div>
  )

  return (
    <div className='flex flex-col gap-y-4'>
      <div className='flex w-full items-center justify-end'>
        <div
          onClick={onCloseMenu}
          className='rounded-md p-0.5 transition hover:bg-gray4 focus:outline-none focus:ring focus:ring-gray5/50   lg:hidden'
        >
          <X weight='bold' className='h-3.5 w-3.5 shrink-0 text-gray11' />
        </div>
      </div>
      {renderContent()}
    </div>
  )
}
