import reactStringReplace from 'react-string-replace'
// @ts-ignore
import sanitizeHtml from 'sanitize-html'

import { isNullOrUndefined } from '@/lib/helpers/dataHelpers'
import type { IKeyValueMap } from '@/types/common'

export const templateStringReplace = (str: string, obj: any): string => {
  return !isNullOrUndefined(str)
    ? str.replace(/{([^{}]+)}/g, (_keyExpr, key) =>
        isNullOrUndefined(obj[key]) ? `{${key}}` : obj[key]
      )
    : ''
}

export const pluralize = (value: number, singular: string, plural: string) =>
  value > 1 || value === 0 ? plural : singular

export const templateComponentReplace = (str: string, obj: any) => {
  return !isNullOrUndefined(str)
    ? reactStringReplace(
        str,
        /{([^{}]+)}/g,
        (key, _i) => obj[key] || `{${key}}`
      )
    : ''
}

export const stringifyValue = (data: string) => (data ? data.toString() : '')

const REGEX: IKeyValueMap = {
  reg_url:
    /(ftp|http|https|www):\/\/(\w+:{0,1}\w*@)?([-.]\w+)*\w+([-.:]\w.+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/,
  reg_url_or_email_or_tel:
    /(ftp|http|https|www):\/\/(\w+:{0,1}\w*@)?([-.]\w+)*\w+([-.]\w.+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?|^mailto:[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$|^tel:((\s{0,1})([+]{0,1})(\d{2}))(?:\+\d{1,2})?(?!.*[\s-]{2})[0-9\s-]+$/,
  reg_domain:
    /^(?:(?:[a-zA-Z0-9*](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z]{2,}))(?:\/[\w-]+(?:\/[\w-]+)*)?$/,
  reg_email: /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  reg_html_empty: /^<p>\s*<\/p>$/,
}

export const RegexValidator = {
  isValid: (matchString: string, regexKey: string) => {
    try {
      return REGEX[regexKey].test(matchString)
    } catch {
      return false
    }
  },
  isValidWithRegex: (matchString: string, regex: RegExp) =>
    regex.test(matchString),
}

// Snake to camel case
export const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z])/gi, ($1) =>
    $1.toUpperCase().replace('-', '').replace('_', '')
  )

// Camel case to snake case
export const camelToSnake = (str: string) =>
  str.replace(/([A-Z])/g, ($1) => `_${$1.toLowerCase()}`)

// Capitalize first letter
export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)

export const stringToSlug = (str?: string) => {
  if (!str) return ''
  const slug = str
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')

  return slug
}

export const titleize = (str: string) =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')

export const changeSpecialCharactersToNormal = (str: string) => {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&nbsp;/g, ' ')
    .replace(/&rdquo;/g, '"')
    .replace(/&ndash;/g, '–')
    .replace(/&mdash;/g, '—')
    .replace(/&excl;/g, '!')
    .replace(/&ldquo;/g, '"')
    .replace(/&rdquo;/g, '"')
}

// Friendly number
export const friendlyNumber = (num: number) => {
  if (num < 1000) return num
  if (num < 1000000) return `${(num / 1000).toFixed(1)}K`
  if (num < 1000000000) return `${(num / 1000000).toFixed(1)}M`
  if (num < 1000000000000) return `${(num / 1000000000).toFixed(1)}B`
  return `${(num / 1000000000000).toFixed(1)}T`
}

export const checkStringHasSpecialChar = (value: string) => {
  const regex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
  return regex.test(value)
}

export const slugify = (
  value: string,
  maxLength: number,
  delimiter: string
) => {
  let slug = value
    .toLowerCase()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, delimiter)
    .replace(/-+/g, delimiter)

  if (slug.length > maxLength) {
    slug = slug.substr(0, maxLength)
  }

  return slug
}

// Show as currency with default currency USD
export const showAsCurrency = (value: number, currency: string = 'USD') => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(value)
}

export const removeNewLine = (str: string) => str.replace(/^\s*\n|\n\s*$/g, '')

export const stringHasLength = (value: string) => {
  if (!value) return false
  return value?.length > 0
}

export const isHTMLContentEmpty = (content?: string) =>
  !content || REGEX.reg_html_empty.test(content)

export const encodeURLPath = (path: string) => {
  return path
    .split('/')
    .map((p) => encodeURIComponent(p))
    .join('/')
}

export const isValidEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

export const removeHtmlTags = (inputString: string) => {
  return inputString.replace(/<[^>]*>/g, '')
}

export const cleanHTMLString = (htmlString: string) => {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlString
  const liElements = tempDiv.querySelectorAll('li')
  liElements.forEach((li) => {
    const pTag = li.querySelector('p')
    if (pTag === null) {
      // eslint-disable-next-line no-param-reassign
      li.innerHTML = `<p>${li.textContent}</p>`
    }
  })
  return tempDiv.innerHTML
}

export const hexToHSL = (color: string): string => {
  // Remove the hash (#) if it exists
  const hex = color.replace(/^#/, '')

  // Parse the hex values
  const r = parseInt(hex.substring(0, 2), 16) / 255
  const g = parseInt(hex.substring(2, 4), 16) / 255
  const b = parseInt(hex.substring(4, 6), 16) / 255

  // Find the maximum and minimum values
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)

  // Calculate the lightness
  const l = (max + min) / 2

  let h = 0
  let s

  if (max === min) {
    // Achromatic (gray)
    // eslint-disable-next-line no-multi-assign
    h = s = 0
  } else {
    // Calculate the saturation
    s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min)

    // Calculate the hue
    // eslint-disable-next-line default-case
    switch (max) {
      case r:
        h = (g - b) / (max - min) + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / (max - min) + 2
        break
      case b:
        h = (r - g) / (max - min) + 4
        break
    }

    h *= 60
  }

  // Round the values and format as HSL string
  return `${h.toFixed(3)} ${(s * 100).toFixed(3)}% ${(l * 100).toFixed(3)}%`
}

export const getDomainFromEmail = (email: string) => {
  return email.split('@')[1]
}

export const sanitizeHTMLText = (html: string) => {
  return sanitizeHtml(html, {
    allowedTags: ['em'],
    allowedAttributes: {},
  })
}

export const convertMarkdownLinksToHtml = (markdown: string): string => {
  return markdown.replace(
    /\[([^\]]+)]\((https?:\/\/[^\s)]+)\)/g,
    '<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>'
  )
}

export const convertMarkdownLinksToText = (markdown: string): string => {
  return markdown.replace(/\[([^\]]+)]\((https?:\/\/[^\s)]+)\)/g, '$1')
}
