import { removeKeyFromObject } from '@/lib/helpers/dataHelpers'
import type {
  IChangelog,
  IChangelogLabel,
  IChangelogList,
} from '@/types/changelog'
import type { IDateTime, IKeyValueMap } from '@/types/common'

import { templateStringReplace } from '../stringHelpers'
import { updateUniqueQuery } from '../urlHelpers'

export const SINGLE_CHANELOG_DRAFT = 'CHANGELOG_DRAFT'

const SHARE_URL = {
  twitter: 'https://twitter.com/intent/tweet?text={title}&url={url}',
  mail: 'mailto:?body={url}&subject={title}',
}

export const getChangelogURL = (
  changelog: IChangelog | IChangelogList,
  baseURL?: string
) => {
  const url = baseURL || '/'
  return url.concat('changelog/').concat(changelog.slug)
}

export const getChangelogShareURL = (
  changelog: IChangelog | IChangelogList,
  service: keyof typeof SHARE_URL,
  baseURL: string
) =>
  templateStringReplace(SHARE_URL[service], {
    title: changelog.title,
    url: getChangelogURL(changelog, baseURL),
  })

export const changelogLabelFilter = (
  labels: string[] | string | undefined,
  label: IChangelogLabel,
  removeFilter: boolean
) => {
  let queryParams: IKeyValueMap = {}
  if (!labels) {
    queryParams = { labels: [label.id] }
  } else if (typeof labels === 'string') {
    if (removeFilter) {
      queryParams = { labels: [] }
    } else queryParams = { labels: [labels, label.id] }
  } else if (Array.isArray(labels)) {
    if (removeFilter) {
      queryParams = {
        labels: labels.filter((lab) => lab.toString() !== label.id.toString()),
      }
    } else queryParams = { labels: [...labels, label.id] }
  }
  return updateUniqueQuery(queryParams.labels)
}

export const checkAndFilterChangelog = (
  changelog: IChangelog,
  criteria: any
) => {
  const cleanedFilter = removeKeyFromObject(['query', 'page'], criteria || {})
  if (!Object.keys(cleanedFilter).length) return true
  const newChangelog = { ...changelog }
  if (
    (cleanedFilter.status?.length &&
      cleanedFilter.status === 'published' &&
      !!newChangelog.published_at) ||
    (cleanedFilter.status?.length &&
      cleanedFilter.status === 'unpublished' &&
      !newChangelog.published_at) ||
    (cleanedFilter.status?.length &&
      cleanedFilter.status === 'scheduled' &&
      !newChangelog.published_at &&
      !!newChangelog.scheduled_published_at)
  ) {
    return true
  }
  return false
}

export const updateChangelogCount = (
  unpublishedChangelogCount: number,
  publishedChangelogCount: number,
  action:
    | 'PUBLISH'
    | 'UNPUBLISH'
    | 'ADD_PUBLISHED'
    | 'ADD_UNPUBLISHED'
    | 'REMOVE_PUBLISHED'
    | 'REMOVE_UNPUBLISHED'
): {
  publishedChangelogCount: number
  unpublishedChangelogCount: number
} => {
  if (action === 'PUBLISH') {
    return {
      publishedChangelogCount: publishedChangelogCount + 1,
      unpublishedChangelogCount: unpublishedChangelogCount - 1,
    }
  }
  if (action === 'UNPUBLISH') {
    return {
      publishedChangelogCount: publishedChangelogCount - 1,
      unpublishedChangelogCount: unpublishedChangelogCount + 1,
    }
  }
  if (action === 'ADD_PUBLISHED') {
    return {
      publishedChangelogCount: publishedChangelogCount + 1,
      unpublishedChangelogCount,
    }
  }
  if (action === 'ADD_UNPUBLISHED') {
    return {
      publishedChangelogCount,
      unpublishedChangelogCount: unpublishedChangelogCount + 1,
    }
  }
  if (action === 'REMOVE_PUBLISHED') {
    return {
      publishedChangelogCount: publishedChangelogCount - 1,
      unpublishedChangelogCount,
    }
  }
  if (action === 'REMOVE_UNPUBLISHED') {
    return {
      publishedChangelogCount,
      unpublishedChangelogCount: unpublishedChangelogCount - 1,
    }
  }
  return {
    publishedChangelogCount,
    unpublishedChangelogCount,
  }
}

export const getChangelogStatus = (changelog: {
  published?: boolean
  scheduled_published_at?: IDateTime
}) => {
  if (changelog.published) return 'published'
  if (changelog.scheduled_published_at) return 'scheduled'
  return 'draft'
}

export const cleanChangelogSearchResults = (
  changelog: IChangelog
): IChangelog => {
  return {
    ...changelog,
    created_at: changelog.created_at ?? {
      timestamp: new Date(changelog.numeric_created_at || 0),
      humanized_date: '',
      relative_time: '',
    },
    published_at:
      changelog.published_at ??
      (changelog.numeric_published_at
        ? {
            timestamp: new Date(changelog.numeric_published_at || 0),
            humanized_date: '',
            relative_time: '',
          }
        : null),
    scheduled_published_at:
      changelog.scheduled_published_at ??
      (changelog.numeric_scheduled_published_at
        ? {
            timestamp: new Date(changelog.numeric_scheduled_published_at || 0),
            humanized_date: '',
            relative_time: '',
          }
        : undefined),
    updated_at: changelog.updated_at ?? {
      timestamp: new Date(changelog.numeric_updated_at || 0),
      humanized_date: '',
      relative_time: '',
    },
  }
}
