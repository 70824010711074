import clsx from 'clsx'
import React, { memo, useContext, useMemo } from 'react'

import ProfileHoverWrapper from '@/components/admin/shared/common/ProfileHoverWrapper'
import HNContext from '@/context/HNContext'
import { EventEmitter } from '@/lib/eventEmitter'
import { recordEvent } from '@/lib/helpers/appHelpers'
import type { IUserProfileWithoutStar } from '@/types/user'

import Avatar from '../ui/Avatar'
import type { IAvatarPropsWithoutName } from '../ui/Avatar/Avatar'
import Tooltip from '../ui/Tooltip'
import Typography from '../ui/Typography'
import ConditonalWrapper from './ConditonalWrapper'

interface IPropTypes extends IAvatarPropsWithoutName {
  user: IUserProfileWithoutStar & {
    is_admin_of_organization?: boolean
  }
  showName?: boolean
  className?: string
  showBadge?: boolean
  showProfileCard?: boolean
  allowUserInfoTrigger?: boolean
  hideTooltip?: boolean
  profileCardLabel?: string
}

const UserAvatar = (props: IPropTypes) => {
  const {
    user,
    className,
    showName = false,
    showBadge,
    showProfileCard,
    allowUserInfoTrigger = false,
    hideTooltip = false,
    rounded = true,
    profileCardLabel,
    ...rest
  } = props
  const { showProfileCard: orgWiseShowProfileCard } = useContext(HNContext)
  const name = useMemo(() => {
    return user?.name === '[Deleted Account]' ? '?' : user?.name
  }, [user?.name])

  const handleUserOpen = () => {
    if (!allowUserInfoTrigger) return
    recordEvent('USER_INFO_MODAL_OPENED', {
      skipSegment: true,
    })
    EventEmitter.dispatch('OPEN_USER_INFO', {
      userId: user?.id,
    })
  }

  const avatarURL = useMemo(() => {
    const url = user?.profile_picture?.url
    if (
      url &&
      typeof url === 'string' &&
      url.startsWith('https://vault.hnxt.dev')
    ) {
      return url.replace(
        'https://vault.hnxt.dev',
        'https://vault.featureos.app'
      )
    }
    return url
  }, [user?.profile_picture?.url])

  const renderBaseComponent = () => {
    return (
      <ConditonalWrapper
        condition={showName}
        wrapper={(children) => (
          <span className={clsx(className)}>{children}</span>
        )}
      >
        <div className='flex items-center space-x-2' onClick={handleUserOpen}>
          <Avatar
            url={avatarURL}
            name={name}
            showBadge={showBadge && user?.is_admin_of_organization}
            rounded={rounded}
            {...rest}
          />
          {showName && (
            <Typography.Text className='font-medium !text-gray12'>
              {user?.name}
            </Typography.Text>
          )}
        </div>
      </ConditonalWrapper>
    )
  }

  if (showProfileCard && orgWiseShowProfileCard)
    return (
      <ProfileHoverWrapper user={user} profileCardLabel={profileCardLabel}>
        {renderBaseComponent()}
      </ProfileHoverWrapper>
    )
  return (
    <Tooltip text={user?.name} show={!showName && !hideTooltip} asChild>
      {renderBaseComponent()}
    </Tooltip>
  )
}

export default memo(UserAvatar)
