import type { IRootData } from '@/types/organization'

export const cleanUserRootData = (data: IRootData) => {
  const sortedBuckets = (data.buckets || []).sort(
    (a, b) => a.position - b.position
  )
  // .sort((b) => (b.default ? -1 : 1))

  return {
    ...data,
    buckets: sortedBuckets,
  }
}
