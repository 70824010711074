import {
  Bookmark,
  Gear,
  UserGear,
  UsersFour,
  UserSound,
  UsersThree,
} from '@phosphor-icons/react'

import type { INavigationItem } from '@/types/sidebar'

export const peopleNavigation: INavigationItem[] = [
  {
    key: 'allCustomers',
    link: '/admin/people',
    labelTransKey: 'sidebar.items.allCustomers',
    anotherHref: ['/admin/people'],
    icon: <UsersFour size={16} />,
    availableRoles: ['admin'],
    isExact: true,
    group: 'customer',
    countKey: 'allCustomersCount',
  },
  {
    key: 'starredCustomers',
    link: '/admin/people/starred',
    labelTransKey: 'sidebar.items.starredCustomers',
    anotherHref: ['/admin/people/starred'],
    icon: <UserGear size={16} />,
    availableRoles: ['admin'],
    isExact: true,
    group: 'customer',
    countKey: 'starredCustomersCount',
  },

  {
    key: 'activeCustomers',
    link: '/admin/people/active',
    labelTransKey: 'sidebar.items.activeCustomers',
    anotherHref: ['/admin/people/active'],
    icon: <UserSound size={16} />,
    availableRoles: ['admin'],
    isExact: true,
    group: 'customer',
  },

  {
    key: 'user_segment',
    link: '/admin/people/user-segments',
    anotherHref: ['/admin/people/user-segments'],
    labelTransKey: 'sidebar.items.viewAllSegments',
    icon: <Bookmark weight='regular' size={16} />,
    group: 'user_segments',
    availableRoles: ['admin'],
    isExact: true,
  },
  {
    key: 'settings',
    link: '/admin/people/settings',
    labelTransKey: 'sidebar.items.peopleSettings',
    anotherHref: ['/admin/people/settings'],
    icon: <Gear size={16} />,
    availableRoles: ['admin'],
    isExact: true,
    group: 'settings',
  },
]

export const peopleNavigationGroups = {
  user_segments: {
    label: 'User Segments',
  },
  customer: {
    label: 'Customer',
    icon: <UsersFour size={16} />,
    open: true,
  },
  settings: {
    label: 'People Settings',
    icon: <UsersThree size={16} />,
    open: true,
  },
}
