import React from 'react'

import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'

interface IPropTypes {
  onNavigation: (page: string) => void
}
export default function AuthModalHome({ onNavigation }: IPropTypes) {
  const t = useTranslations()
  return (
    <div className='my-2 space-y-2'>
      <Button
        className='w-full'
        variant='secondary'
        onClick={() => onNavigation('signin')}
      >
        {t('auth.labels.continueWithEmail')}
      </Button>
      <Button
        className='w-full'
        variant='secondary'
        onClick={() => onNavigation('signup')}
      >
        {t('auth.labels.signupWithEmail')}
      </Button>
    </div>
  )
}
