import { CaretDown } from '@phosphor-icons/react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'

import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownLabel,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { pluralize } from '@/lib/helpers/appHelpers'
import { userSideModulePath } from '@/lib/helpers/pathHelpers'
import { templateStringReplace } from '@/lib/helpers/stringHelpers'
import { getBasePathPrefix } from '@/lib/helpers/urlHelpers'
import type { IBoardDetails } from '@/types/board'
import type { IEmbedType } from '@/types/organization'

export default function CustomHeaderBoardsButton() {
  const {
    show_boards_tab,
    default_module,
    embedType,
    buckets,
    organizationSetting,
  } = useContext(HNContext)
  const router = useRouter()

  const t = useTranslations()

  const activeBoard = useMemo(() => {
    const { bucket_id, boardSlug } = router.query
    let board
    if (boardSlug) board = buckets?.find((bucket) => bucket.name === boardSlug)
    if (bucket_id)
      board = buckets?.find((bucket) => bucket?.id?.toString() === bucket_id)
    return board
  }, [buckets, router.query.boardSlug, router.query.bucket_id])

  const publicBoards = useMemo(
    () => buckets?.filter((bucket) => !bucket.private) || [],
    [buckets]
  )
  const privateBoards = useMemo(
    () => buckets?.filter((bucket) => bucket.private) || [],
    [buckets]
  )

  const isActive = (module: string) => default_module === module

  const renderBoardLink = (board: IBoardDetails) => (
    <DropdownItem
      dataTestId={`user_side_header_${board.name}_dropdown_item`}
      textClassName='!text-primary'
      key={board.id}
      itemValue={board.display_name}
      href={`${embedType === 'orgEmbed' ? '/embed/home/' : ''}/b/${board.name}`}
    />
  )

  if (!show_boards_tab) return null

  return (
    <Dropdown className='z-20' modal={false}>
      <DropdownTrigger asChild={false}>
        <div
          className={clsx(
            'flex items-center gap-1',
            default_module === 'roadmap' ? 'order-first' : ''
          )}
          data-testid='user_side_header_boards_tab'
        >
          <p className={clsx(isActive('boards') ? '!text-primary' : '')}>
            {activeBoard && isActive('boards')
              ? activeBoard.display_name
              : organizationSetting?.bucket_plural_name}
          </p>
          <CaretDown />
        </div>
      </DropdownTrigger>
      <DropdownContent
        data-testid='user_side_header_boards_dropdown'
        align='center'
        className='z-20'
      >
        <DropdownItem
          dataTestId='user_side_header_all_boards_dropdown_item'
          textClassName='!text-primary'
          href={userSideModulePath(
            'boards',
            default_module as string,
            getBasePathPrefix(embedType as IEmbedType)
          )}
          itemValue={templateStringReplace(t('posts.moduleLabels.allBoards'), {
            boards: organizationSetting?.bucket_plural_name,
          })}
        />
        {Boolean(!!publicBoards.length && !!privateBoards.length) && (
          <DropdownLabel>
            {templateStringReplace(t('posts.moduleLabels.publicBoards'), {
              boards: pluralize(
                publicBoards.length,
                organizationSetting?.bucket_singular_name as string,
                organizationSetting?.bucket_plural_name as string
              ),
            })}
          </DropdownLabel>
        )}
        {publicBoards.map(renderBoardLink)}
        {Boolean(privateBoards.length) && (
          <DropdownLabel>
            {templateStringReplace(t('posts.moduleLabels.privateBoards'), {
              boards: pluralize(
                privateBoards.length,
                organizationSetting?.bucket_singular_name as string,
                organizationSetting?.bucket_plural_name as string
              ),
            })}
          </DropdownLabel>
        )}
        {privateBoards.map(renderBoardLink)}
      </DropdownContent>
    </Dropdown>
  )
}
