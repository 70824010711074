import React from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import { setSelectedValue } from '@/lib/helpers/dataHelpers'
import type { IStatusSyncRule } from '@/types/integration/statusSync'

interface IAdminIntegrationSyncNotifyDropdownProps {
  notifyData: ISelectOption[]
  syncRule: IStatusSyncRule
  edit: boolean
  onChange: (key: string, value: string) => void
}

export default function AdminIntegrationSyncNotifyDropdown({
  notifyData,
  syncRule,
  edit,
  onChange,
}: IAdminIntegrationSyncNotifyDropdownProps) {
  const t = useTranslations(`integrations`)

  const handleChange = (notify: ISelectOptionGroup) => {
    if (Array.isArray(notify)) {
      const value = notify[0]?.value
      onChange('notify_customers', value)
    } else {
      const value = notify?.value
      onChange('notify_customers', value)
    }
  }

  const notifyOptions =
    notifyData.length > 0
      ? notifyData
      : [
          {
            label: t(`statusSync.notifyOptions.notify`),
            value: true,
          },
          {
            label: t(`statusSync.notifyOptions.doNotNotify`),
            value: false,
          },
        ]
  return (
    <Select
      size='xs'
      options={notifyOptions}
      placeholder={t(`statusSync.notifyOptions.placeholder`)}
      defaultValue={setSelectedValue(notifyOptions, syncRule.notify_customers)}
      onChange={handleChange}
      disabled={!edit}
    />
  )
}
