import React, { useState } from 'react'

import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'
import { createInJira } from '@/models/Post'
import type { IPost, IPostJiraInfo } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  jiraInfo: IPostJiraInfo
  onCreate: (data: IPost) => void
}
export default function CreateJiraIssue({
  post,
  jiraInfo,
  onCreate,
}: IPropTypes) {
  const t = useTranslations(`post.integrations.jira`)
  const [jiraUrl, setJiraUrl] = useState<string>(post.jira_url || ``)
  const [isLoading, setIsLoading] = useState(false)
  if (!jiraInfo) return null

  const handleCreate = () => {
    setIsLoading(true)
    return createInJira(post.slug)
      .then((data) => {
        setJiraUrl(data.jira_url)
        onCreate(data)
      })
      .then(() => toaster.success({ message: t(`addToJiraSuccess`) }))
      .catch((err) =>
        toaster.error({
          message: err.message || t(`addToJiraError`),
        })
      )
      .finally(() => setIsLoading(false))
  }

  if (Number(jiraUrl.length) > 0) {
    return (
      <a
        href={jiraUrl}
        target='_blank'
        className='text-sm text-primary'
        rel='noreferrer'
      >
        {t(`openInJira`)}
      </a>
    )
  }

  return (
    <Button
      variant='outline'
      disabled={isLoading}
      size='xs'
      onClick={handleCreate}
    >
      {t(`addToJiraButton`)}
    </Button>
  )
}
