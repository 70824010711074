import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import PasskeyButton from '@/components/auth/providers/PasskeyButton'
import SSOSigninButton from '@/components/auth/providers/SSOSigninButton'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { redirectAfterSocialLogin } from '@/lib/helpers/authHelpers'
import { loginWithPopup } from '@/lib/helpers/loginHelper'
import toaster from '@/utils/toast'

const AppleSigninButton = dynamic(
  () => import('@/components/auth/providers/AppleSigninButton'),
  {
    ssr: false,
  }
)
const GithubSigninButton = dynamic(
  () => import('@/components/auth/providers/GithubSigninButton'),
  {
    ssr: false,
  }
)
const GoogleSigninButton = dynamic(
  () => import('@/components/auth/providers/GoogleSigninButton'),
  {
    ssr: false,
  }
)
const MicrosoftSigninButton = dynamic(
  () => import('@/components/auth/providers/MicrosoftSigninButton'),
  {
    ssr: false,
  }
)

interface IPropTypes {
  redirectTo?: string
  isModal?: boolean
  onlyButtons?: boolean
  showAllProviders?: boolean
}
export default function AuthProviders({
  redirectTo,
  isModal = false,
  onlyButtons = false,
  showAllProviders = false,
}: IPropTypes) {
  const t = useTranslations('auth.labels')
  const router = useRouter()

  const { auth } = useContext(HNContext)

  const handleLogin = (url: string) => {
    return loginWithPopup({ url })
      .then((response) => {
        return redirectAfterSocialLogin(response, router, !!isModal, redirectTo)
      })
      .catch(toaster.error)
  }
  return (
    <div
      data-testid='authProvider_component'
      className={!onlyButtons ? 'space-y-2' : ''}
    >
      {!onlyButtons && <PasskeyButton isModal={isModal} />}
      {auth?.sso && (
        <SSOSigninButton
          onlyButtons={onlyButtons}
          size={onlyButtons ? 'xs' : undefined}
        />
      )}
      {!!(auth?.google || showAllProviders) && (
        <GoogleSigninButton
          label={t('signInWithGoogle')}
          onButtonClick={handleLogin}
          size={onlyButtons ? 'xs' : undefined}
          skipValidation={showAllProviders}
        />
      )}
      {!!(auth?.apple || showAllProviders) && (
        <AppleSigninButton
          label={t('signInWithApple')}
          size={onlyButtons ? 'xs' : undefined}
          skipValidation={showAllProviders}
        />
      )}
      {!!(auth?.github || showAllProviders) && (
        <GithubSigninButton
          label={t('signInWithGithub')}
          className='!focus:ring-[#02101f]'
          onButtonClick={handleLogin}
          size={onlyButtons ? 'xs' : undefined}
          skipValidation={showAllProviders}
        />
      )}
      {auth?.azure_ad && (
        <MicrosoftSigninButton
          label={t('signInWithMicrosoft')}
          className=''
          onButtonClick={handleLogin}
          size={onlyButtons ? 'xs' : undefined}
        />
      )}
    </div>
  )
}
