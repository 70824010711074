import type { SVGProps } from 'react'
import React from 'react'

import type { IPost } from '@/types/post'

const UrgentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-4 w-4'
    width={32}
    height={32}
    fill='none'
    viewBox='0 0 32 32'
    {...props}
  >
    <rect width={32} height={32} fill='#F48A0D' rx={5} />
    <path
      fill='#fff'
      d='M18.739 10h1.428v7.808c0 .806-.19 1.526-.57 2.16a3.991 3.991 0 0 1-1.595 1.49c-.687.362-1.493.542-2.418.542-.926 0-1.732-.18-2.419-.541a4.032 4.032 0 0 1-1.6-1.492c-.377-.633-.565-1.353-.565-2.159V10h1.428v7.693c0 .576.127 1.088.38 1.537.253.446.614.797 1.083 1.054.472.253 1.036.38 1.693.38.656 0 1.22-.127 1.692-.38a2.676 2.676 0 0 0 1.083-1.054c.253-.449.38-.961.38-1.537V10Z'
    />
  </svg>
)

const HighPriorityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-4 w-4'
    width={16}
    height={16}
    fill='#9c9da0'
    aria-label='Priority 0'
    {...props}
  >
    <rect width={3} height={6} x={1.5} y={8} rx={1} />
    <rect width={3} height={9} x={6.5} y={5} rx={1} />
    <rect width={3} height={12} x={11.5} y={2} rx={1} />
  </svg>
)

const MediumPriorityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-4 w-4'
    width={16}
    height={16}
    fill='#9c9da0'
    aria-label='Priority 1'
    {...props}
  >
    <rect width={3} height={6} x={1.5} y={8} rx={1} />
    <rect width={3} height={9} x={6.5} y={5} rx={1} />
    <rect width={3} height={12} x={11.5} y={2} rx={1} fillOpacity={0.4} />
  </svg>
)

const LowPriorityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-4 w-4'
    width={16}
    height={16}
    fill='#9c9da0'
    aria-label='Priority 2'
    {...props}
  >
    <rect width={3} height={6} x={1.5} y={8} rx={1} />
    <rect width={3} height={9} x={6.5} y={5} rx={1} fillOpacity={0.4} />
    <rect width={3} height={12} x={11.5} y={2} rx={1} fillOpacity={0.4} />
  </svg>
)

const NoPriorityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='h-4 w-4'
    width={16}
    height={16}
    fill='#9c9da0'
    aria-label='No Priority'
    {...props}
  >
    <rect width={3} height={1.5} x={1.5} y={7.25} opacity={0.9} rx={0.5} />
    <rect width={3} height={1.5} x={6.5} y={7.25} opacity={0.9} rx={0.5} />
    <rect width={3} height={1.5} x={11.5} y={7.25} opacity={0.9} rx={0.5} />
  </svg>
)

interface IProps {
  post: Pick<IPost, 'priority'>
}
export default function PriorityIcon(props: IProps) {
  switch (props.post.priority) {
    case 'high':
      return <HighPriorityIcon />
    case 'low':
      return <LowPriorityIcon />
    case 'medium':
      return <MediumPriorityIcon />
    case 'urgent':
      return <UrgentIcon />
    default:
      return <NoPriorityIcon />
  }
}
