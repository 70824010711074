import React, { useContext, useState } from 'react'

import AuthenticatedView from '@/components/auth/shared/AuthenticatedView'
import NoSSR from '@/components/shared/NoSSR'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/shared/ui/Accordion/Accordion'
import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { cleanUserForSegment, recordEvent } from '@/lib/helpers/appHelpers'
import { postVoteOnBehalf } from '@/models/Post'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export interface IVoter {
  email: string
  name?: string
}

export default function SinglePostVoteOnBehalf({ post }: IPropTypes) {
  const t = useTranslations()
  const { userSignedIn, userProfile } = useContext(HNContext)
  const [voter, setVoter] = useState<IVoter>({ email: '', name: '' })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleChange = (key: string, value: string) => {
    setVoter((_voter) => ({ ..._voter, [key]: value }))
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    if (!voter?.email?.length) return null
    setIsSubmitting(true)
    recordEvent('POST_VOTE_ON_BEHALF', {
      post_id: post.id,
      votedBy: cleanUserForSegment(userProfile),
      votedFor: voter,
    })
    return postVoteOnBehalf(post.slug, voter)
      .then((response) => {
        postStore.updateSinglePost(response.slug, response)
        toaster.success({ message: t('post.messages.voteOnBehalf.success') })
        setVoter({ email: '', name: '' })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsSubmitting(false))
  }

  return (
    <AuthenticatedView
      shouldShow={Boolean(post.can_vote_on_behalf && userSignedIn)}
    >
      <div className='border-t border-gray5 px-4 py-3'>
        <form onSubmit={handleSubmit}>
          <NoSSR>
            <Accordion type='multiple'>
              <AccordionItem value='_voter'>
                <AccordionTrigger className='!py-0'>
                  <div>
                    <Typography.Text className='!font-medium'>
                      {t('post.fields.voteOnBehalf.label')}
                    </Typography.Text>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className='mt-1 space-y-2'>
                    <Input
                      value={voter.email}
                      rounded='md'
                      size='sm'
                      placeholder={t(
                        'post.fields.voteOnBehalf.emailPlaceholder'
                      )}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange('email', e.target.value)
                      }
                    />
                    <Input
                      value={voter.name}
                      rounded='md'
                      size='sm'
                      placeholder={t(
                        'post.fields.voteOnBehalf.namePlaceholder'
                      )}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange('name', e.target.value)
                      }
                    />
                    <div className='flex items-center justify-end'>
                      <Button
                        variant='primary'
                        size='sm'
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        {t('buttons.vote')}
                      </Button>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </NoSSR>
        </form>
      </div>
    </AuthenticatedView>
  )
}
