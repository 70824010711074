import { Plus } from '@phosphor-icons/react'
import React, { useState } from 'react'

import Button from '@/components/shared/ui/Button'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import { useTranslations } from '@/hooks/useTranslations'
import type { IJiraPushRule, IJiraSyncRule } from '@/types/integration/jira'

import AdminIntegrationJiraSyncRule from './AdminIntegrationJiraSyncRule'

interface IAdminIntegrationJiraNewSyncRuleProps {
  pushRule: IJiraPushRule
  onAdd: (rule: IJiraSyncRule) => void
  useCustomStates: boolean
}

export default function AdminIntegrationJiraNewSyncRule({
  pushRule,
  onAdd,
  useCustomStates,
}: IAdminIntegrationJiraNewSyncRuleProps) {
  const t = useTranslations(`integrations.jira.configure`)
  const [newSyncRule, setNewSyncRule] = useState<IJiraSyncRule>({
    qualifier: ``,
    hn_status: ``,
    jira_status: ``,
    notify: false,
    sync_while_create: false,
  })

  const [showNewRule, setShowNewRule] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const validate = () => {
    return !(
      newSyncRule.qualifier !== `` &&
      newSyncRule.hn_status !== `` &&
      newSyncRule.jira_status !== ``
    )
  }

  const reset = () => {
    setShowNewRule(false)
    setIsSubmitting(false)
    setNewSyncRule({
      qualifier: ``,
      hn_status: ``,
      jira_status: ``,
      notify: false,
    })
  }

  const handleAddRule = () => {
    setIsSubmitting(true)
    onAdd(newSyncRule)
    reset()
  }

  const handleChange = (syncRule: IJiraSyncRule) => {
    setNewSyncRule(syncRule)
  }

  if (showNewRule) {
    return (
      <div className='flex flex-col rounded border-2 border-dashed border-gray5 p-3 '>
        <AdminIntegrationJiraSyncRule
          syncRule={newSyncRule}
          onChange={handleChange}
          pushRule={pushRule}
          newRule
          useCustomStates={useCustomStates}
        />
        <div className='mt-3 flex space-x-1'>
          <Button
            data-testid='add_sync_rule_button'
            size='xs'
            disabled={validate() || isSubmitting}
            onClick={handleAddRule}
          >
            {isSubmitting ? (
              <SpinnerIcon className='h-4 w-4 animate-spin' />
            ) : (
              <span>{t(`statusSync.addRule`)}</span>
            )}
          </Button>
          <Button
            data-testid='cancel_sync_rule_button'
            size='xs'
            onClick={() => reset()}
            variant='outline'
          >
            <span>
              {t(`buttons.cancel`, {
                ignorePrefix: true,
              })}
            </span>
          </Button>
        </div>
      </div>
    )
  }

  return (
    <Button
      size='xs'
      icon={<Plus weight='bold' />}
      onClick={() => setShowNewRule(true)}
      variant='outline'
    >
      {t(`statusSync.addRule`)}
    </Button>
  )
}
