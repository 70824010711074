import { PushPinSlash } from '@phosphor-icons/react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'

import { ACTIVITY } from '@/config/module/recentActivity'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  convertSnakeCaseToCamelCase,
  objectHasProperty,
} from '@/lib/helpers/dataHelpers'
import { relativeDate } from '@/lib/helpers/dateHelpers'
import {
  activityFieldResolver,
  activityValueResolver,
  getActivityTypeLabel,
} from '@/lib/helpers/modules/activityHelper'
import { userSideChangelogSinglePath } from '@/lib/helpers/pathHelpers'
import { templateComponentReplace } from '@/lib/helpers/stringHelpers'
import { buildURL } from '@/lib/helpers/urlHelpers'
import postStore from '@/stores/PostListStore'
import type { IActivityComponentProps, IActivityItem } from '@/types/activity'
import type { IDateTime } from '@/types/common'
import type { IPostTag } from '@/types/post'

import Status from '../posts/Status'
import UserAvatar from '../shared/components/UserAvatar'
import Badge from '../shared/ui/Badge'
import UnstyledLink from '../shared/ui/Links/UnstyledLink'

export default function ActivityItem({
  activity,
  mini,
  resource,
}: IActivityComponentProps) {
  const router = useRouter()
  const t = useTranslations()
  const { organizationSetting, isAdmin } = useContext(HNContext)

  const { postSlug } = router.query

  const Icon =
    // @ts-ignore
    ACTIVITY[activity?.activity_type]?.icon ||
    // @ts-ignore
    ACTIVITY[activity?.resource_source]?.icon ||
    PushPinSlash

  const iconColor =
    `${
      // @ts-ignore
      ACTIVITY[activity?.activity_type]?.color ||
      // @ts-ignore
      ACTIVITY[activity?.resource_source]?.color
    } h-4 w-4 shrink-0` || 'text-gray8'

  const handleTagFilter = (tagData: IPostTag) => {
    if (!isAdmin) {
      window.location.href = buildURL('/admin', {
        tag_id: tagData.id,
      })
    } else {
      router.push({
        pathname: '/admin',
        query: { tag_id: tagData.id },
      })
      postStore.updateFilters(
        {
          page: 1,
          sort: postStore.filters.sort,
          tag_id: [tagData.id],
        },
        { hardReset: true }
      )
    }
  }

  const value = useMemo(() => {
    const { activity_info, activity_type, field_type } = activity
    if (field_type === 'boolean') {
      return activity_info?.value
        ? t('common.labels.flags.enabled')?.toLowerCase()
        : t('common.labels.flags.disabled')?.toLowerCase()
    }
    if (activity_type === 'organization_setting_activity') {
      if (objectHasProperty(activity_info, 'default_sort')) {
        return activity_info.default_sort
      }
      if (objectHasProperty(activity_info, 'roadmap_default_sort')) {
        return activity_info.roadmap_default_sort
      }
      if (objectHasProperty(activity_info, 'max_votes')) {
        return activity_info.max_votes
      }
    }

    if (typeof activity_info?.to_value === 'boolean') {
      if (activity_info?.field === 'read_only') {
        return activity_info?.to_value
          ? t('common.labels.flags.locked')?.toLowerCase()
          : t('common.labels.flags.unlocked')?.toLowerCase()
      }
    }
    return activity.activity_info.value
  }, [activity])

  const getActivityActionText = () => {
    const { activity_type, activity_info } = activity

    if (activity_type === 'internal_resource_updated') {
      if (activity_info?.field === 'read_only') {
        if (activity_info?.to_value) {
          return activity_info.read_only_reason
            ? t('activities.lockedWithReason')
            : t('activities.locked')
        }
        return t('activities.unlocked')
      }
    }

    const shouldRemove = Boolean(
      resource?.resource === activity?.resource?.resource_type
    )

    let actionText = ''
    let suffix = ''
    if (Object.keys(activity_info)?.length) {
      if (
        objectHasProperty(activity_info, 'to_value') &&
        ((activity_info?.to_value &&
          typeof activity_info?.to_value === 'object' &&
          !Object.keys(activity_info?.to_value)?.length) ||
          (typeof activity_info?.to_value === 'string' &&
            !activity_info?.to_value?.length) ||
          activity_info?.to_value === null)
      ) {
        suffix = 'WithoutToValue'
      }
      if (
        objectHasProperty(activity_info, 'from_value') &&
        ((activity_info?.from_value &&
          typeof activity_info?.from_value === 'object' &&
          !Object.keys(activity_info?.from_value)?.length) ||
          (typeof activity_info?.from_value === 'string' &&
            !activity_info?.from_value?.length) ||
          activity_info?.from_value === null)
      ) {
        suffix = 'WithoutFromValue'
      }
    }

    actionText = t(
      `activities.${convertSnakeCaseToCamelCase(
        activity_type || activity.activity
      )}.${activity_info.action}`,
      {
        fallback: () => null,
      }
    )
    if (!actionText) {
      actionText = t(
        `activities.${convertSnakeCaseToCamelCase(
          activity_type || activity.activity
        )}${suffix}`
      )
    }

    const activityTextKey = (
      typeof actionText === 'object'
        ? actionText[convertSnakeCaseToCamelCase(activity_info?.field) + suffix]
        : actionText
    )?.replace(shouldRemove ? ' of {title}' : '', '')

    if (activity.resource_source?.length > 0 && !activity.resource_source)
      return `${activityTextKey} ${t('common.via', { ignorePrefix: true })} ${
        activity.resource_source
      }`

    return activityTextKey
  }

  const getUserData = (_activity: IActivityItem) => {
    if (!mini && !_activity.resource_source)
      return (
        <UserAvatar
          className={clsx(
            'relative bottom-0.5 mr-1 inline-flex items-center space-x-1 align-middle'
          )}
          size='xs'
          rounded
          user={_activity.user}
          showName
        />
      )
    if (_activity.resource_source === 'ai') return 'AI'
    return _activity?.user.name
  }

  const renderPost = () => (
    <UnstyledLink
      className='text-sm text-fosblue'
      href={`/admin/p/${activity?.feature_request?.slug}`}
    >
      {activity.feature_request?.title}
    </UnstyledLink>
  )

  const renderGeneratedActivity = () => {
    let cloneActivity = { ...activity }

    if (!activity.action_text)
      cloneActivity = {
        ...activity,
        action_text: getActivityActionText(),
      }
    return templateComponentReplace(cloneActivity.action_text, {
      user: getUserData(activity),
      title:
        postSlug === activity?.feature_request?.slug ? (
          <span className='text-sm text-fosblue dark:text-fosblue'>
            {activity.feature_request?.title}
          </span>
        ) : (
          renderPost()
        ),
      time: <></>,
      status: (
        <span className='inline-block'>
          <Status size={10} status={activity?.activity_state} />
        </span>
      ),
      tag: (
        <span className='pr-1'>
          <Badge
            size='xxs'
            variant='outline'
            rounded
            showDot
            dotColor={activity.tag?.color}
            value={activity.tag?.name}
            onClick={() => handleTagFilter(activity?.tag)}
          />
        </span>
      ),
      assignee: activity?.assignee?.name,
      bucket: activity.bucket
        ? activity.bucket?.display_name
        : activity.bucket_singular_name,
      etc_date: activity.etc_date,
      child_title: activity.child_feature_request?.title,
      changelog: (
        <UnstyledLink
          className='text-xs text-fosblue'
          href={userSideChangelogSinglePath(activity.changelog?.slug)}
        >
          {activity.changelog?.title || activity?.activity_info?.info}
        </UnstyledLink>
      ),
      deletedData: (
        <span className='text-xs text-fosblue'>
          {activity?.activity_info?.title}
        </span>
      ),
      // value: Object.keys(activity.activity_info).join(','),
      value,
      roadmap: activity.roadmap_name,
      setting: t(
        `activities.${convertSnakeCaseToCamelCase(
          activity?.activity_info?.setting
        )}`
      ),
      source: t(
        `activities.source.${convertSnakeCaseToCamelCase(
          activity.activity_info.source || activity.resource_source
        )}`
      ),
      name: activity.activity_info.name,
      action: t(`common.labels.${getActivityTypeLabel(activity)}`),
      bucket_singular_name: organizationSetting?.bucket_singular_name,
      bucket_name: activity.bucket?.display_name,
      member: (
        <UserAvatar
          className={clsx(
            'relative bottom-0.5 inline-flex items-center align-middle'
          )}
          size='xs'
          rounded
          user={activity.member}
          showName
        />
      ),
      role:
        activity.activity_info?.role || activity.activity_info?.updated_value,
      resource: activity.resource?.title || activity.resource?.name,
      roadmapItem: renderPost(),
      field: t(activityFieldResolver(activity, activity.activity_info?.field)),
      roadmapName:
        activity?.resource?.title ||
        activity?.activity_info.resource_type ||
        activity.roadmap_name,
      fromValue: t(
        activityValueResolver(
          activity,
          activity.activity_info?.field,
          activity.activity_info?.from_value
        )
      ),
      toValue: t(
        activityValueResolver(
          activity,
          activity.activity_info?.field,
          activity.activity_info?.to_value
        )
      ),
      reason: (
        <span className='font-semibold'>
          {activity.activity_info?.read_only_reason}
        </span>
      ),
      changelogModule: organizationSetting?.changelog_singular_name,
    })
  }

  return (
    <>
      <li className='group mt-3 space-y-2 p-2'>
        <div className='relative w-full pb-2'>
          {!mini && (
            <span className='absolute left-[10px] top-6 z-0 -ml-px h-full w-0.5 bg-gray5 transition-colors duration-200 group-last:hidden '></span>
          )}
          <div className='relative flex items-start gap-4 align-middle'>
            {!mini && (
              <span className='flex h-5 w-5 shrink-0 items-center justify-center rounded-full  ring-gray5'>
                {Boolean(Icon) && (
                  <Icon weight='regular' className={iconColor} />
                )}
              </span>
            )}
            <div className='flex items-center justify-between space-x-2 whitespace-normal'>
              <div className='inline-flex items-center space-x-1'>
                <div
                  className={clsx(
                    'inline-block items-center align-middle font-normal'
                  )}
                >
                  {renderGeneratedActivity()}
                  <div className='pl-8 text-gray11'>
                    {relativeDate(
                      (activity.created_at as IDateTime).timestamp ||
                        activity.created_at
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      {/* <Accordion type='multiple'>
        <AccordionItem value='1'>
          <AccordionTrigger>Show Data</AccordionTrigger>
          <AccordionContent>
            <pre>{JSON.stringify(activity, null, 2)}</pre>
          </AccordionContent>
        </AccordionItem>
      </Accordion> */}
    </>
  )
}
