import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import AdminIntegrationAsanaPullRule from '@/components/admin/settings/Integrations/config/Asana/AdminIntegrationAsanaPullRule'
import AdminIntegrationAsanaPushRule from '@/components/admin/settings/Integrations/config/Asana/AdminIntegrationAsanaPushRule'
import AdminIntegrationAsanaSyncRule from '@/components/admin/settings/Integrations/config/Asana/AdminIntegrationAsanaSyncRule'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import Spinner from '@/components/shared/ui/Loader'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getIntegrationConfig,
  updateIntegrationConfig,
} from '@/models/Integration'
import type {
  IAsanaConfigUpdatePayload,
  IAsanaConfigurationProps,
  IAsanaIntegrationConfig,
  IAsanaPushRule,
  IAsanaReceiveRule,
} from '@/types/integration/asana'
import type {
  IIntegrationPushRuleKey,
  IIntegrationPushRuleValue,
} from '@/types/integration/github'
import type { IStatusSyncRule } from '@/types/integration/statusSync'
import toaster from '@/utils/toast'

export default function AsanaConfiguration({
  onDisconnect,
}: IAsanaConfigurationProps) {
  const t = useTranslations('integrations.asana')
  const router = useRouter()
  const { provider, integrated } = router.query as {
    provider: string
    integrated: string
  }
  const [showConfig, setShowConfig] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [configData, setConfigData] = useState<IAsanaIntegrationConfig>()
  const [autoPushRule, setAutoPushRule] = useState<IAsanaPushRule>()
  const [autoPullRule, setAutoPullRule] = useState<IAsanaReceiveRule>()
  const [errorMessages, setErrorMessages] = useState<string | null>(null)
  const [isPushRuleSubmitting, setIsPushRuleSubmitting] = useState(false)
  const [isPullRuleSubmitting, setIsPullRuleSubmitting] = useState(false)

  const fetchConfiguration = () => {
    if (!configData) {
      setIsLoading(true)
    } else {
      setIsSyncing(true)
    }
    return getIntegrationConfig('asana')
      .then((data: IAsanaIntegrationConfig) => {
        setErrorMessages(null)
        if (configData) {
          setConfigData({
            ...configData,
            ...data,
          })
        } else {
          setConfigData(data)
        }
        setAutoPushRule(data.push_rule)
        setAutoPullRule(data.receive_rule)
      })
      .catch((err) => setErrorMessages(err.message))
      .finally(() => {
        setIsLoading(false)
        setIsSyncing(false)
      })
  }

  const handleUpdateConfig = (config: IAsanaConfigUpdatePayload) => {
    return updateIntegrationConfig(
      'asana',
      configData?.id.toString() || '',
      config
    )
      .then((data) => {
        setConfigData(data)
        setAutoPushRule(data.push_rule)
        setAutoPullRule(data.receive_rule)
        toaster.success({
          message: t(`messages.successfullySaved`, {
            ignorePrefix: true,
          }),
        })
      })
      .catch((error) => toaster.error({ message: error.message }))
      .finally(() => {
        setIsPushRuleSubmitting(false)
        setIsPullRuleSubmitting(false)
      })
  }

  const handleSubmitPushRule = (data: IAsanaPushRule) => {
    const config = {
      push_rule: data,
    } as IAsanaConfigUpdatePayload
    let errorText = ''
    if (!config.push_rule.status) errorText = t('errorMessages.statusMandatory')
    if (!config.push_rule.buckets?.length)
      errorText = t('errorMessages.bucketMandatory')
    if (!config.push_rule?.project_id)
      errorText = t('errorMessages.projectMandatory')
    if (errorText?.length) return toaster.error({ message: errorText })
    setIsPushRuleSubmitting(true)
    return handleUpdateConfig(config)
  }

  const handleSubmitPullRule = (data: IAsanaReceiveRule) => {
    const config = {
      receive_rule: data,
    } as IAsanaConfigUpdatePayload
    let errorText = ''
    if (!config.receive_rule?.project_id)
      errorText = t('errorMessages.projectMandatory')
    if (!config.receive_rule?.bucket_id?.length)
      errorText = t('errorMessages.bucketMandatory')
    if (errorText?.length) return toaster.error({ message: errorText })
    setIsPullRuleSubmitting(true)
    return handleUpdateConfig(config)
  }

  const handlePushRuleChange = (
    key: IIntegrationPushRuleKey,
    value: IIntegrationPushRuleValue
  ) => {
    const newRule = {
      ...autoPushRule,
      [key]: value,
    } as IAsanaPushRule
    setAutoPushRule(newRule)
  }

  const handlePullRuleChange = (
    key: IIntegrationPushRuleKey,
    value: IIntegrationPushRuleValue
  ) => {
    const newRule = {
      ...autoPushRule,
      [key]: value,
    } as IAsanaReceiveRule
    setAutoPullRule(newRule)
  }

  const handleAddSyncRule = (newSyncRules: IStatusSyncRule[]) => {
    setConfigData((prevData) => {
      if (prevData) return { ...prevData, sync_rules: newSyncRules }
      return prevData
    })
  }

  const handleRemoveSyncRule = (newSyncRules: IStatusSyncRule[]) => {
    const config = {
      push_rule: autoPushRule,
      rules: newSyncRules,
    } as IAsanaConfigUpdatePayload
    return handleUpdateConfig(config)
  }

  const handleDialogClose = () => {
    setShowConfig(false)
    setAutoPushRule(configData?.push_rule)
    setAutoPullRule(configData?.receive_rule)
    router.push('/admin/settings/integrations', '', { shallow: true })
  }

  const handleToggleCustomStatus = (checked: boolean) => {
    if (!configData) return null
    return updateIntegrationConfig('asana', configData.id.toString(), {
      sync_by_custom_state: checked,
    }).then((updatedSyncData) => {
      setConfigData(updatedSyncData)
    })
  }

  useEffect(() => {
    if (provider === 'asana' && integrated === 'true') {
      setShowConfig(true)
    }
  }, [])

  useEffect(() => {
    if (showConfig) fetchConfiguration()
  }, [showConfig])

  const renderLoadingOrEmpty = () => {
    if (isLoading) return <Spinner />
    return (
      <>
        <EmptyState
          title={
            errorMessages ||
            t('messages.something_went_wrong', { ignorePrefix: true })
          }
        />
        <div className='mb-2 mt-4 flex w-full justify-end space-x-2'>
          <AlertDialog
            type='danger'
            confirmText={t(`buttons.disconnect`, {
              ignorePrefix: true,
            })}
            title={t(`disconnectConfirmationTitle`)}
            description={t(`disconnectConfirmationMessage`)}
            onConfirm={onDisconnect}
          >
            <Button
              variant='danger'
              size='xs'
              disabled={isPushRuleSubmitting || isPullRuleSubmitting}
            >
              {t(`buttons.disconnect`, {
                ignorePrefix: true,
              })}
            </Button>
          </AlertDialog>
        </div>
      </>
    )
  }

  return (
    <Dialog open={showConfig} onClose={() => handleDialogClose()}>
      <DialogTrigger asChild>
        <Button size='xs' onClick={() => setShowConfig(true)}>
          {t('buttons.configure', {
            ignorePrefix: true,
          })}
        </Button>
      </DialogTrigger>
      <DialogContent size='lg' backdrop>
        <DialogClose />
        <DialogTitle>
          <div className='mr-1 flex items-center justify-between'>
            {t(`configure.title`)}
            <div className='flex items-center gap-2'>
              {isSyncing && <SpinnerIcon className='h-4 w-4 animate-spin' />}
              {!!configData && (
                <Checkbox
                  isSwitch
                  defaultChecked={configData?.sync_by_custom_state}
                  onCheckedChange={handleToggleCustomStatus}
                  alertTexts={{
                    title: 'Are sure you want to use custom states?',
                    description:
                      'All your sync rule will be used to sync custom statuses.',
                  }}
                  disabledAlertTexts={{
                    title: 'Use parent status for sync?',
                    description:
                      'All your sync rule will be used to sync parent statuses.',
                  }}
                  label='Use custom states'
                />
              )}
            </div>
          </div>
        </DialogTitle>
        {isLoading || !configData || !autoPushRule || !autoPullRule ? (
          renderLoadingOrEmpty()
        ) : (
          <>
            <AdminIntegrationAsanaPushRule
              configData={configData}
              onChange={handlePushRuleChange}
              pushRule={autoPushRule}
              isSubmitting={isPushRuleSubmitting}
              onSave={(data) => handleSubmitPushRule(data)}
              useCustomStates={configData.sync_by_custom_state}
            />
            <AdminIntegrationAsanaPullRule
              configData={configData}
              onChange={handlePullRuleChange}
              pullRule={autoPullRule}
              isSubmitting={isPullRuleSubmitting}
              onSave={(data) => handleSubmitPullRule(data)}
            />
            <AdminIntegrationAsanaSyncRule
              configData={configData}
              onRemove={handleRemoveSyncRule}
              onAdd={handleAddSyncRule}
            />
            <div className='mb-2 mt-4 flex w-full justify-end space-x-2'>
              <AlertDialog
                type='danger'
                confirmText={t(`buttons.disconnect`, {
                  ignorePrefix: true,
                })}
                title={t(`disconnectConfirmationTitle`)}
                description={t(`disconnectConfirmationMessage`)}
                onConfirm={onDisconnect}
              >
                <Button
                  variant='danger'
                  size='xs'
                  disabled={isPushRuleSubmitting || isPullRuleSubmitting}
                >
                  {t(`buttons.disconnect`, {
                    ignorePrefix: true,
                  })}
                </Button>
              </AlertDialog>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}
