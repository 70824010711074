import type { Editor } from '@tiptap/core'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import Alert from '@/components/shared/ui/Alert/Alert'
import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import Input from '@/components/shared/ui/Input'
import { useTranslations } from '@/hooks/useTranslations'
import { RegexValidator } from '@/lib/helpers/stringHelpers'

interface IPropTypes {
  editor: Editor
}

const LINK_REGEX =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z0-9-]+)\/?([a-zA-Z0-9-]+)?/

const EditorImageEmbedDialog = forwardRef(
  (props: IPropTypes, ref: ForwardedRef<any>) => {
    const { editor } = props
    const [open, setOpen] = React.useState(false)
    const [linkURL, setLinkURL] = useState<string | null>(null)
    const t = useTranslations('editor')

    const [errorMessage, setErrorMessage] = useState(null)

    const handleLink = (link: string | null) => {
      if (link && link.trim().length) {
        if (link) {
          editor
            .chain()
            .focus()
            .setImage({ src: link, alt: link, title: link })
            .run()
          setOpen(false)
        } else {
          setErrorMessage(t('invalidMessage'))
        }
      }
    }

    useImperativeHandle(ref, () => ({
      openDialog: () => {
        setOpen(true)
      },
    }))

    const isValidURL = useMemo(
      () => RegexValidator.isValidWithRegex(linkURL || '', LINK_REGEX),
      [linkURL]
    )

    return (
      <Dialog
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <DialogClose />
          <DialogTitle>{t(`embed.image.title`)}</DialogTitle>
          <form
            className='mt-3'
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              return handleLink(linkURL)
            }}
          >
            {errorMessage && <Alert message={errorMessage} type='error' />}
            <Input
              autoFocus
              placeholder={t(`embed.image.placeholder`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLinkURL(e.target.value.trim())
              }
              type='url'
              required
            />
            <button type='submit' className='sr-only'>
              {t('buttons.submit', { ignorePrefix: true })}
            </button>
          </form>
          <div className='mt-4 flex justify-end space-x-1'>
            <Button onClick={() => handleLink(linkURL)} disabled={!isValidURL}>
              {t('buttons.embed', { ignorePrefix: true })}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
)

EditorImageEmbedDialog.displayName = 'EditorImageEmbedDialog'
export default EditorImageEmbedDialog
