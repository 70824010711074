import { InfoIcon } from '@primer/octicons-react'
import React from 'react'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/ui/Popover/Popover'
import { useTranslations } from '@/hooks/useTranslations'

export default function PasswordRuleHintPopover() {
  const t = useTranslations('auth.passwordRestrictions')

  return (
    <div>
      <Popover>
        <PopoverTrigger asChild>
          <span className='flex max-w-2xl appearance-none items-center space-x-2 text-xs text-gray10 transition '>
            <p>{t('prompt')}</p>
            <InfoIcon className='h-3.5 w-3.5' />
          </span>
        </PopoverTrigger>
        <PopoverContent>
          <p className='font-semibold'>{t('title')}</p>
          <ul className='list-disc space-y-0.5 py-1 pl-3.5 text-xs text-gray10'>
            {t('rules').map((rule: string, i: number) => (
              <li key={i}>{rule}</li>
            ))}
          </ul>
        </PopoverContent>
      </Popover>
    </div>
  )
}
