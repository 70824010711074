import { X } from '@phosphor-icons/react'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

import Button from '../Button'

export const PopoverPrimitives = PopoverPrimitive

export const PopoverClose: FC = ({ ...props }) => (
  <PopoverPrimitive.Close {...props}>
    <Button variant='text' size='xs' className='absolute right-2 top-2 !p-0.5'>
      <X weight='bold' className='h-3 w-3' />
    </Button>
  </PopoverPrimitive.Close>
)

interface PopoverArrowPropType extends PopoverPrimitive.PopperArrowProps {
  children?: React.ReactNode
}

export const PopoverArrow: FC<PopoverArrowPropType> = ({
  children,
  ...props
}) => (
  <PopoverPrimitive.Arrow
    {...props}
    className='fill-current text-gray5 dark:text-gray-700'
  >
    {children}
  </PopoverPrimitive.Arrow>
)

export interface PopoverContentPropType
  extends PopoverPrimitive.PopoverContentProps {
  children?: React.ReactNode
  className?: string
  size?: '1' | '2'
}

export const PopoverContent: FC<PopoverContentPropType> = ({
  children,
  className,
  size = '1',
  ...props
}) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      {...props}
      data-size={size}
      className={clsx(
        'z-50 min-w-[8rem] overflow-hidden rounded-md border border-gray-200 bg-white p-1 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-gray-700 dark:bg-gray-800',
        size === '2' ? 'p-4' : 'p-2',
        className
      )}
    >
      {children}
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
)

export const PopoverAnchor: FC<PopoverContentPropType> = ({
  children,
  ...props
}) => <PopoverPrimitive.Anchor {...props}>{children}</PopoverPrimitive.Anchor>

interface PopoverTriggerPropType extends PopoverPrimitive.PopoverTriggerProps {
  children?: React.ReactNode
}

export const PopoverTrigger: FC<PopoverTriggerPropType> = ({
  children,
  ...props
}) => <PopoverPrimitive.Trigger {...props}>{children}</PopoverPrimitive.Trigger>

interface PopoverRootPropType extends PopoverPrimitive.PopoverProps {
  children?: React.ReactNode
}

export const Popover: FC<PopoverRootPropType> = ({ children, ...props }) => (
  <PopoverPrimitive.Root {...props}>{children}</PopoverPrimitive.Root>
)
