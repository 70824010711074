import {
  Code,
  Eraser,
  Image as ImageIcon,
  Info,
  Link,
  ListBullets,
  ListNumbers,
  Quotes,
  Table,
  TextAlignCenter,
  TextAlignJustify,
  TextAlignLeft,
  TextAlignRight,
  TextBolder,
  TextHFour,
  TextHThree,
  TextHTwo,
  TextItalic,
  TextStrikethrough,
  TextUnderline,
  Video,
} from '@phosphor-icons/react'
import type { Editor } from '@tiptap/react'
import { createPortal } from 'react-dom'

import ImageUploadButton from '@/components/shared/components/editor/menu/buttons/ImageUploadButton'
import TableButton from '@/components/shared/components/editor/menu/buttons/TableButton'
import VideoUploadButton from '@/components/shared/components/editor/menu/buttons/VideoUploadButton'
import type { IEditorActions, IRichTextEditorProps } from '@/types/editor'

interface IPropTypes {
  editor: Editor
  actions: IEditorActions
  support?: IRichTextEditorProps['support']
}

const buttons = [
  {
    label: 'Bold',
    icon: TextBolder,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleBold().run()
    },
    isActive: (editor: Editor) => editor.isActive('bold'),
  },
  {
    label: 'Italic',
    icon: TextItalic,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleItalic().run()
    },
    isActive: (editor: Editor) => editor.isActive('italic'),
  },

  {
    label: 'Underline',
    icon: TextUnderline,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleUnderline().run()
    },
    isActive: (editor: Editor) => editor.isActive('italic'),
  },
  {
    label: 'Code',
    icon: Code,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleCode().run()
    },
    isActive: (editor: Editor) => editor.isActive('code_block'),
  },
  {
    label: 'Heading2',
    icon: TextHTwo,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleHeading({ level: 2 }).run()
    },
    isActive: (editor: Editor) => editor.isActive('heading', { level: 2 }),
  },
  {
    label: 'Heading3',
    icon: TextHThree,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleHeading({ level: 3 }).run()
    },
    isActive: (editor: Editor) => editor.isActive('heading', { level: 3 }),
  },
  {
    label: 'Heading4',
    icon: TextHFour,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleHeading({ level: 4 }).run()
    },
    isActive: (editor: Editor) => editor.isActive('heading', { level: 4 }),
  },
  {
    label: 'Strike',
    icon: TextStrikethrough,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleStrike().run()
    },
    isActive: (editor: Editor) => editor.isActive('strike'),
  },

  {
    // List
    label: 'Bullet List',
    icon: ListBullets,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleBulletList().run()
    },
    isActive: (editor: Editor) => editor.isActive('bulletList'),
  },
  {
    // List
    label: 'Number List',
    icon: ListNumbers,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleOrderedList().run()
    },
    isActive: (editor: Editor) => editor.isActive('orderedList'),
  },
  // Quote
  {
    label: 'Quote',
    icon: Quotes,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleBlockquote().run()
    },
    isActive: (editor: Editor) => editor.isActive('blockquote'),
  },
  {
    label: 'Callout',
    icon: Info,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().toggleCallout().run()
    },
    isActive: (editor: Editor) => editor.isActive('callout'),
  },
  // Link Menu
  {
    label: 'Link',
    icon: Link,
    onClick: ({ actions }: IPropTypes) => {
      actions.openLinkDialog()
    },
    isActive: () => false,
  },
  {
    label: 'Image',
    customComponent: ({ actions }: IPropTypes) => (
      <ImageUploadButton actions={actions}>
        <button className='group rounded-lg px-1 py-1 duration-200 hover:bg-gray3'>
          <ImageIcon
            className={`h-5 w-5 text-gray10 group-hover:text-gray12`}
          />
        </button>
      </ImageUploadButton>
    ),
  },
  {
    label: 'Video',
    customComponent: ({ actions, support }: IPropTypes) => (
      <VideoUploadButton actions={actions} support={support}>
        <button className='group rounded-lg px-1 py-1 duration-200 hover:bg-gray3'>
          <Video className={`h-5 w-5 text-gray10 group-hover:text-gray12`} />
        </button>
      </VideoUploadButton>
    ),
  },

  // Align
  {
    label: 'Align Left',
    icon: TextAlignLeft,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().setTextAlign('left').run()
    },
    isActive: (editor: Editor) => editor.isActive({ textAlign: 'left' }),
  },
  {
    label: 'Align Center',
    icon: TextAlignCenter,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().setTextAlign('center').run()
    },
    isActive: (editor: Editor) => editor.isActive('align', { align: 'center' }),
  },
  {
    label: 'Align Right',
    icon: TextAlignRight,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().setTextAlign('right').run()
    },
    isActive: (editor: Editor) => editor.isActive('align', { align: 'right' }),
  },
  {
    label: 'Align Justify',
    icon: TextAlignJustify,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().setTextAlign('justify').run()
    },
    isActive: (editor: Editor) =>
      editor.isActive('align', { align: 'justify' }),
  },
  // Clear formating
  {
    label: 'Clear Formatting',
    icon: Eraser,
    onClick: ({ editor }: IPropTypes) => {
      editor.chain().focus().clearNodes().run()
    },
    isActive: () => false,
  },
  {
    title: 'Table',
    customComponent: ({ actions, editor }: IPropTypes) => (
      <TableButton actions={actions} editor={editor}>
        <button className='group rounded-lg px-1 py-1 duration-200 hover:bg-gray3'>
          <Table className={`h-5 w-5 text-gray10 group-hover:text-gray12`} />
        </button>
      </TableButton>
    ),
    isActive: () => false,
  },
]
export default function EditorFixedMenu({
  editor,
  actions,
  support,
}: IPropTypes) {
  // Render with react portal
  return createPortal(
    <div className=''>
      <div className='flex items-center gap-2'>
        {buttons.map((btn) =>
          btn.customComponent ? (
            btn.customComponent({ actions, editor, support })
          ) : (
            <button
              key={btn.label}
              onClick={() => btn.onClick({ actions, editor, support })}
              className='group rounded-lg px-1 py-1 duration-200 hover:bg-gray3'
            >
              <btn.icon
                className={`h-5 w-5 ${
                  btn.isActive(editor)
                    ? 'text-primary'
                    : 'text-gray10 group-hover:text-gray12'
                }`}
              />
            </button>
          )
        )}
      </div>
    </div>,
    document.getElementById('header-left') as HTMLElement
  )
}
