import React from 'react'

import Badge from '@/components/shared/ui/Badge'
import { removeInteractorLabel } from '@/models/User'
import type { IInteractorRecord, IUserLabel } from '@/types/user'
import toaster from '@/utils/toast'

import AdminAddUserLabel from './AdminAddUserLabel'

interface IPropTypes {
  user: IInteractorRecord
  onUpdate: (user: IInteractorRecord) => void
}

export default function AdminUserLabels({ user, onUpdate }: IPropTypes) {
  const handleRemove = (label: IUserLabel) => {
    removeInteractorLabel(user.id, { value: label.id })
      .then(onUpdate)
      .catch(toaster.error)
  }

  const renderLabel = (label: IUserLabel) => {
    return (
      <Badge
        rounded
        variant='outline'
        key={label.id}
        value={label.title}
        onClose={() => handleRemove(label)}
        close
      />
    )
  }
  return (
    <div className='flex flex-wrap items-center space-x-1 space-y-1'>
      {user.interactor_labels.length < 5 && (
        <AdminAddUserLabel size='xs' user={user} onUpdate={onUpdate} />
      )}
      {user.interactor_labels.map(renderLabel)}
    </div>
  )
}
