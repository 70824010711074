import type { IBoardDetails, IBoardMaxVoteSummary } from '@/types/board'
import type {
  IOrganizationCustomization,
  IOrganizationPlan,
} from '@/types/organization'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'

import { getIndexInArray, objectHasProperty } from '../../dataHelpers'
import { templateStringReplace } from '../../stringHelpers'

export const getTooltipMessage = (
  text: string,
  currentBoard: IBoardDetails,
  maxVotesSummary: IBoardMaxVoteSummary
) => {
  if (!currentBoard || !maxVotesSummary) return ''
  const { remaining_votes: orgRemainingVotes, max_votes: orgMaxVotes } =
    maxVotesSummary
  const { max_votes_summary: boardMaxVotesSummary } = currentBoard

  return templateStringReplace(text, {
    // eslint-disable-next-line no-nested-ternary
    remaining_votes: currentBoard?.enable_max_votes
      ? orgRemainingVotes === 0
        ? orgRemainingVotes?.toString()
        : boardMaxVotesSummary?.remaining_votes?.toString()
      : orgRemainingVotes?.toString(),
    max_votes: currentBoard?.enable_max_votes
      ? boardMaxVotesSummary?.max_votes?.toString()
      : orgMaxVotes?.toString(),
  })
}

export const updateBoards = (
  boards: IBoardDetails[],
  updatedVote: IPost,
  currentPostBoard: IBoardDetails
) => {
  const newBoards = [...boards]
  const index = newBoards.findIndex((b) => b.id === currentPostBoard?.id)
  let boardData
  if (index !== -1) {
    boardData = newBoards[index]
    if (boardData) boardData = { ...boardData, ...updatedVote }
  }
  // @ts-ignore
  newBoards[index] = boardData
  return newBoards
}

export const checkVoteLimitExistsAndThrowError = (
  post: IPost,
  userSignedIn: Boolean,
  userProfile: IUserProfile | undefined,
  currentBoard: IBoardDetails | undefined,
  globalMaxVoteSummary: IBoardMaxVoteSummary | undefined,
  translations: any
) => {
  if (!currentBoard) return ''
  const userNotVoted = Boolean(!post.downvoted && !post.upvoted)
  const {
    enable_max_votes: boardEnableMaxVotes,
    max_votes_summary: boardMaxVotesSummary,
    display_name: boardDisplayName,
  } = currentBoard

  if (
    userNotVoted &&
    boardEnableMaxVotes &&
    boardMaxVotesSummary?.remaining_votes === 0 &&
    ((userSignedIn && !userProfile?.is_csm_of_organization) || !userSignedIn)
  ) {
    return templateStringReplace(translations?.bucketVoteExceedMessage, {
      board: boardDisplayName,
      max_votes: boardMaxVotesSummary?.max_votes,
      max_votes_period: boardMaxVotesSummary?.max_votes_period,
    })
  }

  if (
    userNotVoted &&
    globalMaxVoteSummary &&
    globalMaxVoteSummary.remaining_votes === 0 &&
    ((userSignedIn && !userProfile?.is_csm_of_organization) ||
      (globalMaxVoteSummary && !userSignedIn))
  ) {
    return templateStringReplace(translations.voteExceedMessage, {
      max_votes: globalMaxVoteSummary.max_votes,
      max_votes_period: globalMaxVoteSummary?.max_votes_period,
    })
  }
  return ''
}

export const updateUpvoterAndDownvotersList = (
  post: IPost,
  userProfile: IUserProfile | undefined,
  type: string
) => {
  const updatedPost = { ...post }
  const upvoterIndex = getIndexInArray(
    userProfile?.id,
    'id',
    updatedPost?.upvoters || []
  )
  const downvoterIndex = getIndexInArray(
    userProfile?.id,
    'id',
    updatedPost?.downvoters || []
  )
  updatedPost.upvoters = updatedPost?.upvoters || []
  updatedPost.downvoters = updatedPost?.downvoters || []
  if (updatedPost.upvoters && userProfile && type === 'upvote') {
    if (updatedPost.upvoted) {
      if (upvoterIndex < 0) {
        updatedPost.upvoters = [...updatedPost.upvoters, userProfile]
      }
      if (updatedPost.can_downvote) {
        if (downvoterIndex >= 0) {
          updatedPost.downvoters.splice(downvoterIndex, 1)
        }
      }
    } else if (upvoterIndex > -1) {
      updatedPost.upvoters.splice(upvoterIndex, 1)
    }
  }
  if (updatedPost.downvoters && userProfile && type === 'downvote') {
    if (updatedPost.downvoted) {
      if (downvoterIndex === -1) {
        updatedPost.downvoters = [...updatedPost.downvoters, userProfile]
      }
      if (upvoterIndex > -1) {
        updatedPost.upvoters.splice(upvoterIndex, 1)
      }
    } else if (downvoterIndex > -1) {
      updatedPost.downvoters.splice(downvoterIndex, 1)
    }
  }
  return updatedPost
}

export const updateOrgMaxVoteSummary = (
  globalMaxVoteSummary: IBoardMaxVoteSummary,
  updatedVoteData: IPost,
  post: IPost
) => {
  let newMaxVotesSummary = { ...globalMaxVoteSummary }
  if (!newMaxVotesSummary) return newMaxVotesSummary
  if (!updatedVoteData.max_votes_summary) return newMaxVotesSummary

  if (newMaxVotesSummary?.remaining_votes !== 0) {
    if (post.upvoted && updatedVoteData.downvoted)
      return updatedVoteData.max_votes_summary
    newMaxVotesSummary = updatedVoteData.max_votes_summary
  }

  if (newMaxVotesSummary.remaining_votes === 0) {
    if (post.upvoted && updatedVoteData.downvoted)
      return updatedVoteData.max_votes_summary
    if (
      newMaxVotesSummary.user_votes ===
      updatedVoteData?.max_votes_summary.user_votes
    )
      return updatedVoteData.max_votes_summary
    newMaxVotesSummary.remaining_votes += 1
    if (newMaxVotesSummary.user_votes) newMaxVotesSummary.user_votes -= 1
  }
  return newMaxVotesSummary
}

export const updateBoardMaxVoteSummary = (
  globalMaxVoteSummary: IBoardMaxVoteSummary,
  updatedVoteData: IPost,
  post: IPost,
  currentPostBucket: IBoardDetails
) => {
  const newMaxVotesSummary = { ...globalMaxVoteSummary }
  if (globalMaxVoteSummary?.remaining_votes !== 0) {
    if (post.upvoted && updatedVoteData?.downvoted) return updatedVoteData
    if (newMaxVotesSummary.remaining_votes)
      newMaxVotesSummary.remaining_votes -= 1
    if (newMaxVotesSummary.user_votes) newMaxVotesSummary.user_votes += 1
  }

  if (globalMaxVoteSummary?.remaining_votes === 0) {
    if (
      currentPostBucket?.max_votes_summary?.user_votes ===
      updatedVoteData.max_votes_summary?.user_votes
    )
      return newMaxVotesSummary

    if (objectHasProperty(newMaxVotesSummary, 'remaining_votes')) {
      // @ts-ignore
      newMaxVotesSummary.remaining_votes += 1
    }
    if (newMaxVotesSummary.user_votes) newMaxVotesSummary.user_votes -= 1
  }
  return newMaxVotesSummary
}

const getToastHelpText = (
  userVotes: any,
  translation: any,
  maxVotesSummary: IBoardMaxVoteSummary
) => {
  let toastMessage = translation.toastHelpMessage
  toastMessage = templateStringReplace(toastMessage, {
    remaining_votes: userVotes?.toString(),
    max_votes_period: maxVotesSummary?.max_votes_period?.toString(),
  })
  return toastMessage
}

export const votedToastMessage = (
  voted: boolean,
  userSignedIn: Boolean,
  userProfile: IUserProfile | undefined,
  organizationCustomization: IOrganizationCustomization | undefined,
  organizationPlan: IOrganizationPlan | undefined,
  translations: any,
  userVotes: any,
  maxVotesSummary: IBoardMaxVoteSummary,
  voteType: string
) => {
  if (voted) {
    if (
      organizationCustomization?.enable_max_votes &&
      !organizationPlan?.trial_expired &&
      ((organizationPlan?.enterprise_features_plans &&
        !userProfile?.is_csm_of_organization) ||
        !userSignedIn)
    ) {
      return `${translations.voteMessages[voteType]}. ${getToastHelpText(
        userVotes,
        translations.maxVoteSummary,
        maxVotesSummary
      )}`
    }
    return translations.voteMessages[voteType]
  }
  if (
    organizationCustomization?.enable_max_votes &&
    !organizationPlan?.trial_expired &&
    ((organizationPlan?.enterprise_features_plans &&
      !userProfile?.is_csm_of_organization) ||
      !userSignedIn)
  ) {
    return `${
      translations.voteMessages[`${voteType}Removed`]
    }. ${getToastHelpText(
      userVotes,
      translations.maxVoteSummary,
      maxVotesSummary
    )}`
  }
  return translations.voteMessages[`${voteType}Removed`]
}
