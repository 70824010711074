import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

export const selectTriggerVariants = cva(
  [
    'group flex w-full items-center justify-between space-x-2 truncate border-[0.5px] border-gray5 bg-snow font-medium text-gray12/80 caret-transparent shadow-form outline-none ring-0 transition-colors duration-200',
    'placeholder:text-gray10 hover:border-gray7 focus:border-gray7',
    'focus:!outline-none focus:!ring-transparent focus-visible:outline-none',
    'dark:bg-gray-dark',
  ],
  {
    variants: {
      size: {
        xxs: 'px-2 py-[1px] text-xs',
        xs: 'px-2.5 py-[4px] text-xs',
        sm: 'px-3 py-2 text-sm leading-4',
        md: 'px-4 py-2 text-sm',
        lg: 'px-4 py-2 text-base',
        xl: 'px-6 py-3 text-base',
      },
      rounded: {
        // Renamed from 'rounded' to avoid conflict with boolean prop
        xs: 'rounded-sm',
        sm: 'rounded',
        md: 'rounded-md',
        lg: 'rounded-lg',
        xl: 'rounded-xl',
        full: 'rounded-full',
      },
      disabled: {
        true: 'cursor-not-allowed !bg-gray6 opacity-40 hover:!border-gray6',
        false: '',
      },
      loading: {
        true: 'cursor-progress opacity-50',
        false: '',
      },
    },
    defaultVariants: {
      size: 'sm',
      rounded: 'md',
      disabled: false,
      loading: false,
    },
  }
)

export type SelectTriggerVariants = VariantProps<typeof selectTriggerVariants>

export const selectItemVariants = cva(
  [
    'flex w-full cursor-pointer select-none appearance-none items-center justify-start space-x-1.5 overflow-hidden rounded-md px-2.5 py-1.5 text-left text-sm text-gray12',
    'hover:bg-gray4 focus:bg-gray5 focus:outline-none',
    'dark:hover:bg-gray6',
  ],
  {
    variants: {
      isHighlighted: {
        true: 'bg-gray4 text-gray11',
        false: '',
      },
      isSelected: {
        // Variant for selected state (to be applied conditionally)
        true: 'bg-gray2 !text-primary',
        false: '',
      },
      disabled: {
        true: 'pointer-events-none text-gray8',
        false: '',
      },
    },
    defaultVariants: {
      isHighlighted: false,
      isSelected: false,
      disabled: false,
    },
  }
)

export const selectLabelVariants = cva(
  'my-1 select-none truncate px-3 text-xs font-medium text-gray9'
)

export const selectPopoverContentVariants = cva([
  'z-[60] mt-1 max-h-96 w-full min-w-[11rem] overflow-y-auto rounded-[8px] border-[0.5px] border-gray6 bg-snow p-1 shadow-lg transition-opacity will-change-[transform,opacity] animate-in duration-200',
  'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
  'dark:border-gray-dark-border dark:bg-gray3',
])

export const selectDialogOverlayVariants = cva([
  'fixed inset-0 z-[3] grid overflow-y-auto overflow-x-hidden bg-gray-dark/70 py-1 transition-opacity duration-200',
  'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
])

export const selectDialogContentVariants = cva([
  'fixed left-1/2 top-1/3 z-[6] mx-auto h-fit max-h-96 w-11/12 max-w-sm -translate-x-1/2 overflow-y-auto overflow-x-hidden rounded-[8px] border-[0.5px] border-gray5 bg-snow p-1 shadow-lg transition focus:outline-none',
  'data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
  'dark:border-gray-dark-border dark:bg-gray3 dark:shadow-xl',
])
