import { Check } from '@phosphor-icons/react'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import AdminPostParentStatusFilter from '@/components/admin/posts/filters/AdminPostParentStatusFilter'
import AdminPostStatusFilter from '@/components/admin/posts/filters/AdminPostStatusFilter'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import { Pill } from '@/components/shared/ui/Pill/Pill'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import postStore from '@/stores/PostListStore'
import type { IComment } from '@/types/comment'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

export interface IMarkAsAnswerProps {
  post: IPost
  onUpdate: (comment: IComment) => void
}

export interface INewChangelogButtonActions {
  open: (comment: IComment) => void
}

export const MarkAsAnswerDialog = forwardRef(
  (
    { post, onUpdate }: IMarkAsAnswerProps,
    ref: ForwardedRef<INewChangelogButtonActions>
  ) => {
    const { organizationCustomization } = useContext(HNContext)

    const t = useTranslations()
    const [skipNotification, setSkipNotification] = useState(false)

    const [changedStatus, setChangedStatus] = useState<string | undefined>(
      post.custom_status.value
    )
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [comment, setComment] = useState<IComment>()

    const buttonLoadStatus = useMemo(() => {
      return changedStatus !== post.custom_status.value
    }, [changedStatus, post.custom_status.value])

    const handleStatusSelect = (_key: string, value: ISelectOption) => {
      const selectedValue = Array.isArray(value) ? value[0] : value
      return setChangedStatus(selectedValue)
    }

    const renderStatus = () => {
      if (post.can_change_status)
        if (organizationCustomization?.enable_custom_status)
          return (
            <AdminPostStatusFilter
              filters={{ state: changedStatus }}
              multiple={false}
              onChange={handleStatusSelect}
              behaviour='select'
              placeholder={t('fields.status.placeholder')}
            />
          )
      return (
        <AdminPostParentStatusFilter
          filters={{ status: changedStatus }}
          multiple={false}
          placeholder={t('post.fields.status.placeholder')}
          onChange={handleStatusSelect}
        />
      )
    }

    const handleStatusChange = () => {
      if (!comment) return Promise.resolve()
      setLoading(true)
      const statusData = {
        status: changedStatus,
        do_not_notify: skipNotification,
        solution_comment_id: comment?.id,
      }
      return postStore
        .updatePostStatus(post.slug, statusData)
        .then(() => {
          toaster.success({
            message: t('post.actions.messageAnswered.successToast'),
          })
          onUpdate(comment)
        })
        .catch((err) => {
          toaster.error({ message: err.message })
        })
        .finally(() => {
          setLoading(false)
          setSkipNotification(!skipNotification)
          setOpen(false)
        })
    }

    const handleClose = () => {
      setSkipNotification(false)
      setChangedStatus(post.custom_status.value)
      setOpen(false)
    }

    useImperativeHandle(ref, () => ({
      open: (_comment) => {
        setComment(_comment)
        setOpen(true)
      },
    }))

    return (
      <Dialog open={open} onOpen={() => setOpen(true)} onClose={handleClose}>
        <DialogContent>
          <div className='flex flex-col gap-2'>
            <div
              className={` flex w-fit cursor-pointer items-center justify-center text-[10px] font-bold tracking-wider text-primary opacity-100 transition-all duration-200 ease-in dark:bg-[#10281E]`}
            >
              <Pill className={`flex gap-1 bg-green-600 bg-opacity-[.2]`}>
                <Check
                  size={10}
                  weight='bold'
                  className={`z-10 rounded-full bg-green-600 p-0.5 text-white`}
                />
                <p
                  className={`text-xs font-[500] text-green-900 transition-all duration-150 ease-linear dark:text-green-500`}
                >
                  {t('comments.commentItem.actions.marked_as_asnwer')}
                </p>
              </Pill>
            </div>
            <DialogTitle>
              {t('post.actions.messageAnswered.labeltitle')}
            </DialogTitle>
          </div>
          <DialogDescription>
            {t('post.actions.messageAnswered.labeldescription')}
          </DialogDescription>
          <DialogClose />
          <div className='mt-2'>
            <div
              className='flex flex-col gap-4'
              data-testid='single_post_status_change'
            >
              <div className='flex flex-row items-center justify-between gap-6'>
                <Typography.Text className='font-medium'>
                  {t('post.labels.status')}
                </Typography.Text>
                <span className='w-full'>{renderStatus()}</span>
              </div>

              <div className='flex items-center justify-between'>
                <Checkbox
                  checked={skipNotification}
                  data-testid='post_status_change_modal_do_not_notify'
                  label={t('post.statusChangeNoteAlertTexts.checkboxLabel')}
                  onCheckedChange={() => {
                    setSkipNotification(!skipNotification)
                  }}
                />
                <div className='flex items-center justify-between gap-2'>
                  <Button
                    className={'w-fit'}
                    onClick={handleClose}
                    type='button'
                    variant='outline'
                  >
                    {t('buttons.skip', { ignorePrefix: true })}
                  </Button>
                  <Button
                    className={'w-fit'}
                    onClick={handleStatusChange}
                    type='button'
                    disabled={buttonLoadStatus ? loading : !loading}
                  >
                    {loading
                      ? t('buttons.updating', { ignorePrefix: true })
                      : t('buttons.update', { ignorePrefix: true })}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
)

MarkAsAnswerDialog.displayName = 'MarkAsAnswerDialog'
