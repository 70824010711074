import React, { useEffect, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import Spinner from '@/components/shared/ui/Loader'
import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import Textarea from '@/components/shared/ui/Textarea'
import { useTranslations } from '@/hooks/useTranslations'
import {
  convertObjForSelect,
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import { searchGithubRepos } from '@/models/Integration'
import { createGithubIssue, getGithubConfig } from '@/models/integration/Github'
import type {
  IGithubIntegrationConfig,
  IGithubIssue,
} from '@/types/integration/github'
import type { IPost } from '@/types/post'
import type { IPostAddon } from '@/types/post/postAddon'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  onLink: (data: IPostAddon) => void
}
export default function CreateGithubIssue({ post, onLink }: IPropTypes) {
  const t = useTranslations(`post.integrations.github`)
  const [issue, setIssue] = useState<IGithubIssue>({
    title: post.title,
    description: post.text_description,
    repo_id: ``,
    feature_request_id: post.id,
  })
  const [githubData, setGithubData] = useState<IGithubIntegrationConfig>()
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filteredRepos, setFilteredRepos] = useState<ISelectOption[]>([])

  const fetchData = () => {
    setIsLoading(true)
    return getGithubConfig()
      .then((data) => setGithubData(data))
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsLoading(false))
  }

  const validate = () => !(issue.title && issue.repo_id)

  const handleSubmit = () => {
    setIsSubmitting(true)
    createGithubIssue(issue)
      .then((data) => {
        toaster.success({ message: t(`githubIssueCreated`) })
        onLink(data)
      })
      .catch((err) => toaster.error({ message: err.message }))
      .then(() => setIsSubmitting(false))
  }

  const handleChange = (key: keyof IGithubIssue, value: string) => {
    const newIssue = { ...issue, [key]: value }
    setIssue(newIssue)
  }

  const handleRepoSearch = (query: string) => {
    return searchGithubRepos({ query }).then((data) => {
      return convertObjForSelect(data || [], 'name', 'id')
    })
  }

  useEffect(() => {
    if (!githubData) fetchData()
  }, [])

  useEffect(() => {
    setIssue({
      title: post.title,
      description: post.text_description,
      feature_request_id: post.id.toString(),
      repo_id: githubData?.push_rule?.repo_id || '',
    })
    // remove the last item from the array
    setFilteredRepos(convertObjForSelect(githubData?.repos || [], 'name', 'id'))
  }, [githubData])

  if (isLoading) return <Spinner />

  return (
    <div className='mt-3 space-y-3'>
      <Input
        autoFocus
        label={t(`form.title`)}
        onChange={(event) =>
          handleChange('title', (event.target as HTMLInputElement).value)
        }
        type='text'
        placeholder={t(`form.title`)}
        value={issue?.title}
        defaultValue={post.title}
      />
      <div className='w-full'>
        <Textarea
          label={t(`form.description`)}
          placeholder={t(`form.description`)}
          onChange={(event: { target: { value: string } }) =>
            handleChange('description', event.target.value.trim())
          }
        >
          {issue.description}
        </Textarea>
      </div>
      <div className='w-full space-y-1'>
        <Label>{t(`form.repo.title`)}</Label>
        <Select
          searchable
          clearable
          placeholder={t(`form.repo.placeholder`)}
          searchPlaceholder={t(`form.repo.searchPlaceholder`)}
          options={filteredRepos}
          loadOptions={handleRepoSearch}
          value={setSelectedValue(filteredRepos, issue?.repo_id || ``)}
          onChange={(value) => {
            const selectedValue = getSelectedValueByKey(filteredRepos, value)

            if (selectedValue && Array.isArray(selectedValue)) {
              handleChange('repo_id', selectedValue[0]?.value)
            } else {
              handleChange('repo_id', selectedValue?.value)
            }
          }}
        />
      </div>
      <div className='flex w-full justify-end pt-3'>
        <Button
          size='xs'
          disabled={validate() || isSubmitting}
          onClick={handleSubmit}
        >
          {t(`pushToGithub`)}
        </Button>
      </div>
    </div>
  )
}
