import {
  LINEAR_AUTH,
  LINEAR_ISSUES,
  LINEAR_PROJECTS,
  LINEAR_STATES,
  LINEAR_USERS,
  POST_LINEAR_ISSUE,
  POST_LINEAR_ISSUES,
} from '@/config/routes'
import API from '@/lib/API'
import type {
  ILinearIssuePayload,
  ILinearSearchIssue,
  ILinearSearchRequestPayload,
  ILinearTeamSearchRequestPayload,
} from '@/types/integration/linear'

export const getLinearIssues = (data: ILinearSearchRequestPayload) =>
  API.get(LINEAR_ISSUES, data)
export const getLinearTeamData = () => API.get(LINEAR_AUTH)
export const createLinearIssue = (data: ILinearIssuePayload) =>
  API.post(LINEAR_ISSUES, data)
export const linkPostLinearIssue = (slug: string, data: ILinearSearchIssue) =>
  API.post(POST_LINEAR_ISSUES(slug), data)
export const removePostLinearIssue = (slug: string, id: string) =>
  API.delete(POST_LINEAR_ISSUE(slug, id))
export const getPostLinearIssues = (slug: string) =>
  API.get(POST_LINEAR_ISSUES(slug))
export const searchTeamProjects = (data: ILinearTeamSearchRequestPayload) =>
  API.get(LINEAR_PROJECTS, data)
export const searchTeamUsers = (data: ILinearTeamSearchRequestPayload) =>
  API.get(LINEAR_USERS, data)
export const getLinearStates = () => API.get(LINEAR_STATES)
