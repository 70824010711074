import { useMemo } from 'react'

import Label from '@/components/shared/ui/Label'

const FieldBlock = ({
  children,
  error,
  label,
  mandatory = false,
  wrapLabel = false,
  name,
  rightComponent,
}: {
  children: React.ReactNode
  error?: string
  label?: string | React.ReactNode
  mandatory?: boolean
  wrapLabel?: boolean
  name?: string
  rightComponent?: React.ReactNode
}) => {
  const content = useMemo(() => {
    if (wrapLabel && label) {
      return (
        <div className='flex flex-col gap-1'>
          <Label htmlfor={name} className='flex gap-1'>
            {children}
            {label} {mandatory && <span className='text-red-500'>*</span>}
          </Label>
        </div>
      )
    }
    return (
      <div className='flex flex-col gap-1'>
        <div className='flex justify-between gap-1'>
          {label && (
            <Label htmlfor={name} className='flex gap-1'>
              {label} {mandatory && <span className='text-red-500'>*</span>}
            </Label>
          )}
          {rightComponent}
        </div>
        {children}
      </div>
    )
  }, [wrapLabel, label, children, mandatory])

  return (
    <div className='flex flex-col gap-1'>
      {content}
      {error && <p className='text-xs text-red-500'>{error}</p>}
    </div>
  )
}

export default FieldBlock
