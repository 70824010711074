/* eslint-disable no-unsafe-optional-chaining */
import React, { useContext, useEffect, useMemo, useState } from 'react'

import RoadmapItemScorePicker from '@/components/roadmap/multipleRoadmap/shared/RoadmapItemScorePicker'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/shared/ui/Accordion/Accordion'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { checkIsFloatValue } from '@/lib/helpers/dataHelpers'
import { getPostScore } from '@/models/Post'
import { updateRoadmapItemScore } from '@/models/Roadmap'
import type { IKeyValueMap } from '@/types/common'
import type { IPost } from '@/types/post'
import type { IRoadmap, IRoadmapItem } from '@/types/roadmap'

interface IPropTypes {
  post: IPost
}

export default function SinglePostScoreAction({ post }: IPropTypes) {
  const t = useTranslations('post')
  const { userProfile } = useContext(HNContext)

  const [scores, setScores] = useState<IKeyValueMap[]>([])

  const riceScore = useMemo(
    () => scores.find((s) => s.score_type === 'rice') || { score_type: 'rice' },
    [scores]
  )
  const valueEffortScore = useMemo(
    () =>
      scores.find((s) => s.score_type === 'value_vs_effort') || {
        score_type: 'value_vs_effort',
      },
    [scores]
  )
  const fetchData = () => {
    if (userProfile?.is_member_of_organization)
      getPostScore(post.id).then(setScores)
  }

  const handleScoreUpdate = (
    item: IRoadmapItem,
    scoreType: IRoadmap['score_type']
  ) => {
    setScores(() =>
      [riceScore, valueEffortScore].map((s) => {
        if (s.score_type === scoreType) {
          return {
            ...s,
            ...item.score,
          }
        }
        return s
      })
    )
  }

  const handleSubmit = (scoreType: IRoadmap['score_type']) => {
    return updateRoadmapItemScore({
      resource_type: 'FeatureRequest',
      resource_id: post.id,
      score_type: scoreType,
      ...(scoreType === 'rice' ? riceScore : valueEffortScore),
    }).then((scoreData) => {
      setScores((prevScores) =>
        prevScores.map((s) => {
          if (s.score_type === scoreType) {
            return {
              ...s,
              ...scoreData,
            }
          }
          return s
        })
      )
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const renderScore = (
    score: IKeyValueMap,
    scoreType: IRoadmap['score_type']
  ) => (
    <RoadmapItemScorePicker
      roadmap={{
        score_type: scoreType,
      }}
      item={{
        ...post,
        resource_id: post.id,
        user: post.submitter,
        laneId: post.status.value,
        post_created_at: (
          post.created_at?.timestamp || post?.created_at
        ).toString(),
        score,
      }}
      onUpdate={(item: IRoadmapItem) => handleScoreUpdate(item, scoreType)}
      onSave={() => handleSubmit(scoreType)}
    >
      <div className='min-w-[30px] max-w-[30px] overflow-x-scroll rounded-md border border-gray5 bg-snow px-2.5 py-1.5 text-xs font-medium text-gray11 shadow-sm transition duration-200 hover:border-primary dark:border-gray-dark-border dark:bg-gray-dark '>
        {checkIsFloatValue(score.aggregate)
          ? score?.aggregate?.toFixed(2) || 0
          : score?.aggregate || 0}
      </div>
    </RoadmapItemScorePicker>
  )

  return (
    <div className='border-t border-gray5 px-4 py-3'>
      <Accordion type='multiple'>
        <AccordionItem value='_voter'>
          <AccordionTrigger>
            <Typography.Text className='!font-medium'>
              {t('fields.score.title')}
            </Typography.Text>
          </AccordionTrigger>
          <AccordionContent>
            <div className='mt-1 flex items-center justify-between py-1'>
              <Typography.Text className='!font-medium !text-gray11 '>
                {t('common.labels.scoreTypes.rice', { ignorePrefix: true })}
              </Typography.Text>
              {renderScore(riceScore || {}, 'rice')}
            </div>
            <div className='flex items-center justify-between py-1'>
              <Typography.Text className='!font-medium !text-gray11 '>
                {t('common.labels.scoreTypes.valueVsEffort', {
                  ignorePrefix: true,
                })}
              </Typography.Text>
              {renderScore(valueEffortScore || {}, 'value_vs_effort')}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
