import clsx from 'clsx'
import { observer } from 'mobx-react'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import { useMediaQuery } from '@/hooks/useMediaQuery'

export interface IPropsActionType {
  toggle?: () => void
  children?: React.ReactNode
  className?: string
}

const AsideActions = forwardRef(
  (
    { children, className }: IPropsActionType,
    ref: ForwardedRef<IPropsActionType>
  ) => {
    const [showActions, setShowActions] = useState(false)
    const postActionScreen = useMediaQuery({ width: 1024 })

    useEffect(() => {
      if (postActionScreen) setShowActions(false)
    }, [postActionScreen])

    useImperativeHandle(ref, () => ({
      toggle: () => setShowActions(!showActions),
    }))

    return (
      <>
        <div
          className={clsx(
            className || 'col-span-4 px-1 md:px-2',
            showActions
              ? 'fixed inset-y-0 right-0 z-[5] my-0 w-full overflow-y-auto bg-gray1 px-4 py-8 shadow-[-3px_0_7px_-1px_rgba(0,0,0,0.1)] sm:w-[380px] '
              : 'mb-8 hidden lg:block'
          )}
        >
          {children}
        </div>
        {showActions && (
          <div
            onClick={() => setShowActions(false)}
            className='fixed inset-0 z-[4] grid overflow-x-hidden py-1 transition-opacity duration-200'
          />
        )}
      </>
    )
  }
)

AsideActions.displayName = 'AsideActions'
export default observer(AsideActions)
