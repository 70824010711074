import React from 'react'

import WidgetHeader from '../components/admin/shared/WidgetHeader'

interface IPropTypes {
  children: React.ReactNode
}
export default function WidgetLayout({ children }: IPropTypes) {
  return (
    <div className='mb-6 overflow-y-auto overflow-x-hidden'>
      <WidgetHeader />
      <div className='mt-2 px-2'>{children}</div>
    </div>
  )
}
