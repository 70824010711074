import { File } from '@phosphor-icons/react'
import React, { useContext, useEffect, useRef, useState } from 'react'

import CollapsibleCard from '@/components/shared/common/CollapsibleCard/CollapsibleCard'
import EditorAddExternalAttchments from '@/components/shared/components/attachments/EditorAddExternalAttchments'
import type { IEditorExternalAttchmentsListProps } from '@/components/shared/components/attachments/EditorExternalAttchmentsList'
import EditorExternalAttchmentsList from '@/components/shared/components/attachments/EditorExternalAttchmentsList'
import Alert from '@/components/shared/ui/Alert'
import HNContext from '@/context/HNContext'
import { canViewAttachments } from '@/lib/helpers/rules/postACLRule'
import { getAttachments } from '@/models/Common'
import type { IAttachment } from '@/types/organization'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
}
export default function SinglePostAttachments({ post }: IPropTypes) {
  const [loading, setLoading] = useState(true)
  const [attachments, setAttachments] = useState<IAttachment[]>([])
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const attachmentUploaderRef = useRef<IEditorExternalAttchmentsListProps>(null)
  const { userProfile } = useContext(HNContext)

  const fetchData = async () => {
    setLoading(true)
    getAttachments(post.id, 'FeatureRequest')
      .then(setAttachments)
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [])

  // const getActions = () => {
  //   return (
  //     <EditorAddExternalAttchments
  //       buttonSize='xs'
  //       onAdd={(files) => {
  //         attachmentUploaderRef.current?.attachFiles(files)
  //       }}
  //       buttonProps={{
  //         children: `Add Attachment`,
  //         variant: 'outline',
  //         size: 'xxs',
  //         fab: false,
  //       }}
  //     />
  //   )
  // }
  if (loading) return <></>
  if (errorMessage) return <Alert type='error' message={errorMessage} />

  if (!canViewAttachments(post, userProfile)) return null

  return (
    <CollapsibleCard
      title={`Attachments (${attachments.length})`}
      collapseLocalKey='post-attachments'
      icon={<File />}
      defaultCollapsed={false}
    >
      {!attachments.length
        ? canViewAttachments(post, userProfile) && (
            <EditorAddExternalAttchments
              onAdd={(files) =>
                attachmentUploaderRef.current?.attachFiles(files)
              }
              dropzone
            />
          )
        : null}
      <EditorExternalAttchmentsList
        defaultAttachments={attachments}
        onUpdate={(newAtt) => setAttachments(newAtt)}
        ref={attachmentUploaderRef}
        resourceId={post.id}
        resourceType='FeatureRequest'
        resource={post}
      />
    </CollapsibleCard>
  )
}
