import { Check, Rewind, Trash, XCircle } from '@phosphor-icons/react'
import React, { useContext, useRef, useState } from 'react'

import type { IPostStatusChangeModalRef } from '@/components/posts/singlePost/PostStatusChangeModal'
import PostStatusChangeModal from '@/components/posts/singlePost/PostStatusChangeModal'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import postStore from '@/stores/PostListStore'
import type { IOrganizationPlan } from '@/types/organization'
import type { IPost, IPostCustomStatus } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function AdminPostModerationActions({ post }: IPropTypes) {
  const { pendingPostsCount, updateContext, organizationPlan, custom_states } =
    useContext(HNContext)
  const t = useTranslations()
  const confirmationTranslations = t(
    'posts.moderation.postModerations.confirmations'
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isModerationEnabled = featureIsEnabled(
    'moderation',
    organizationPlan as IOrganizationPlan
  )
  const statusModalRef = useRef<IPostStatusChangeModalRef>(null)
  const postCloseState: IPostCustomStatus | any = custom_states?.find(
    (state) => state?.slug === 'closed'
  )

  const updatePendingPostCount = () => {
    if (
      Boolean(post.approval_status === 'pending') &&
      pendingPostsCount &&
      pendingPostsCount > 0
    ) {
      const newCount = +pendingPostsCount - 1
      if (updateContext) updateContext({ pendingPostsCount: newCount })
      postStore.counts.waiting_for_approval = newCount
    }
  }

  const updateModerationTabCount = (type: string) => {
    EventEmitter.dispatch('ENTITY_UPDATE', {
      entity: ENTITIES.POST_COUNTS,
      type,
      fromTab:
        post.approval_status === 'pending'
          ? 'waiting_for_approval'
          : 'approved_posts',
      toTab:
        post.approval_status === 'pending'
          ? 'approved_posts'
          : 'waiting_for_approval',
    })
  }

  const handleDelete = () => {
    setIsLoading(true)
    return postStore
      .delete(post.slug)
      .then(() => {
        updateModerationTabCount('delete')
        toaster.success({
          message: t('posts.moderation.postModerations.messages.deleted'),
        })
      })
      .then(() => updatePendingPostCount())
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsLoading(false))
  }

  const handleClose = (data: Partial<IPost>) => {
    postStore.updateSinglePost(post.slug, { ...post, ...data })
    setIsLoading(true)
    toaster.success({
      message: t('posts.moderation.postModerations.messages.closed'),
    })
    setIsLoading(false)
  }

  const handleApproval = (
    approvalStatus: 'approved' | 'pending',
    noNotify: boolean
  ) => {
    if (updateContext) {
      const newCount =
        post.approval_status === 'pending'
          ? +Number(pendingPostsCount) - 1
          : +Number(pendingPostsCount) + 1
      updateContext({
        pendingPostsCount: newCount,
      })
    }
    if (post.approval_status === 'pending') {
      postStore.counts.waiting_for_approval -= 1
      postStore.counts.approved_posts += 1
    } else {
      postStore.counts.waiting_for_approval += 1
      postStore.counts.approved_posts -= 1
    }
    updateModerationTabCount('update')
    toaster.success({
      message: t(`posts.moderation.postModerations.messages.${approvalStatus}`),
    })

    return postStore
      .updatePost(
        post.slug,
        {
          approval_status: approvalStatus,
          do_not_notify: noNotify,
        },
        { ...post, approval_status: approvalStatus }
      )
      .catch((err) => {
        if (pendingPostsCount && updateContext) {
          const newCount =
            post.approval_status === 'pending'
              ? +pendingPostsCount + 1
              : +pendingPostsCount - 1
          updateContext({
            pendingPostsCount: newCount,
          })
        }
        // Update tab count back to original
        EventEmitter.dispatch('ENTITY_UPDATE', {
          entity: ENTITIES.POST_COUNTS,
          actionType: EVENT_ACTION_TYPES.UPDATE,
          fromTab:
            post.approval_status === 'pending'
              ? 'approved_posts'
              : 'waiting_for_approval',
          toTab:
            post.approval_status === 'pending'
              ? 'waiting_for_approval'
              : 'approved_posts',
        })
        postStore.updateSinglePost(post.slug, post)
        toaster.error({ message: err.message })
      })
  }

  const renderDelete = () => {
    if (post.can_delete) {
      return (
        <AlertDialog
          type='danger'
          confirmText={t('buttons.delete')}
          title={confirmationTranslations?.delete?.title}
          description={confirmationTranslations?.delete?.alertText}
          onConfirm={handleDelete}
        >
          <Button
            className={'!border-none !text-red11'}
            disabled={isLoading}
            variant='danger'
            size='xxs'
            isResponsive
            icon={<Trash />}
          >
            {t('buttons.delete')}
          </Button>
        </AlertDialog>
      )
    }
    return false
  }

  const renderClose = () => {
    if (post.can_change_status) {
      return (
        <Button
          disabled={isLoading}
          variant='text'
          size='xxs'
          isResponsive
          icon={<XCircle />}
          onClick={() => statusModalRef.current?.toggle()}
        >
          {t('buttons.close')}
        </Button>
      )
    }
    return false
  }

  const renderApprovalButton = () => {
    const isStatusApproved = Boolean(post.approval_status === 'approved')
    const confirmText = isStatusApproved
      ? t('buttons.moveToPending')
      : t('buttons.approve')
    const title = isStatusApproved
      ? confirmationTranslations?.pending?.title
      : confirmationTranslations?.approve?.title
    const alertText = isStatusApproved
      ? confirmationTranslations?.pending?.alertText
      : confirmationTranslations?.approve?.alertText
    return (
      <AlertDialog
        confirmText={confirmText}
        title={title}
        description={alertText}
        onConfirm={(_value, noNotify) =>
          handleApproval(isStatusApproved ? 'pending' : 'approved', noNotify)
        }
        checkboxLabel={t('common.doNotNotify', { ignorePrefix: true })}
        showCheckbox
        waitForPromise={false}
      >
        <Button
          disabled={
            isLoading ||
            (!isModerationEnabled &&
              Boolean(post.approval_status === 'approved'))
          }
          size='xxs'
          variant='default'
          isResponsive
          icon={isStatusApproved ? <Rewind /> : <Check />}
        >
          {confirmText}
        </Button>
      </AlertDialog>
    )
  }

  return (
    <div className='flex space-x-1'>
      {renderDelete()}
      {renderClose()}
      {renderApprovalButton()}
      <PostStatusChangeModal
        post={post}
        ref={statusModalRef}
        toStatus={postCloseState}
        onUpdate={handleClose}
      />
    </div>
  )
}
