import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'

import PostLink from '@/components/posts/PostLink'
import UserAvatar from '@/components/shared/components/UserAvatar'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { formatDate, relativeDate } from '@/lib/helpers/dateHelpers'
import {
  snakeToCamel,
  templateStringReplace,
} from '@/lib/helpers/stringHelpers'
import { clearNotification } from '@/models/User'
import type {
  INotification,
  INotificationFilters,
  INotificationStates,
} from '@/types/user'

interface IPropTypes {
  notification: INotification
  filters: INotificationFilters
  onClick?: (notification: INotification) => void
}

export default function UserNotificationListOneLineItem(props: IPropTypes) {
  const { notification, filters } = props
  const router = useRouter()
  const t = useTranslations('profile.notifications')
  const [seen, setSeen] = React.useState(notification.seen)
  const { notificationsCount, updateContext } = useContext(HNContext)
  const notificationRelativeTime = relativeDate(
    formatDate(
      new Date(notification.created_at * 1000),
      `yyyy-MM-dd'T'HH:mm:ss`
    )
  )

  const isImportantOrPreferredView = useMemo(() => {
    const notificationType = router.query?.notificationsType?.[0]
    return notificationType === 'important' || notificationType === 'preferred'
  }, [router.query])

  const notificationText = useMemo(() => {
    const { type } = notification
    return t(`notification.${[snakeToCamel(type)]}`)
  }, [notification])

  const handleClick = () => {
    // onClick?.(notification)
    if (!seen) {
      clearNotification(notification.id)
        .then(() => {
          EventEmitter.dispatch('ENTITY_UPDATE', {
            actionType: EVENT_ACTION_TYPES.UPDATE,
            entity: 'NOTIFICATIONS',
            data: {
              data: {
                selectedAll: false,
                selectedIds: [notification.id],
                ignoredIds: [],
                action: 'seen',
                notificationType: isImportantOrPreferredView
                  ? filters.notification_type
                  : (router.query
                      ?.notificationsType?.[0] as INotificationStates) ||
                    'inbox',
                currentTab:
                  (router.query
                    ?.notificationsType?.[0] as INotificationStates) || 'inbox',
              },
            },
          })
          if (updateContext) {
            updateContext({
              notificationsCount: (notificationsCount || 1) - 1,
            })
            setSeen(true)
          }
        })
        .catch(() => {
          console.error('Error clearing notification status')
        })
    }
  }

  const renderNotificationPhrase = () => {
    const featureRequest = notification.feature_request
    return templateStringReplace(notificationText, {
      postName: featureRequest?.title,
      status: notification?.post_current_status,
      mergedPostName: notification?.child_feature_request?.title,
      userName: notification?.user?.name,
    })
  }

  return (
    <div
      className={clsx(
        'flex items-center space-x-2 border-l-2 py-2 pl-1 hover:bg-gray4/30',
        {
          'border-l border-l-2 border-primary': !seen,
          'border-transparent': seen,
        }
      )}
      key={notification.id}
    >
      <PostLink
        post={{ slug: notification?.feature_request?.slug }}
        requiredQueryValues={{
          notification_id: notification?.id,
          notification_type: notification?.status,
          feature_request_id: notification?.feature_request?.id,
        }}
        className='group -mt-1 w-full cursor-pointer overflow-hidden pl-2 pr-1'
      >
        <div className='flex flex-col gap-2 pt-1' onClick={handleClick}>
          <div className='flex items-start gap-1'>
            <p
              className={clsx(
                'flex flex-1 !break-words text-gray12 transition group-hover:!text-gray11',
                seen ? 'font-normal' : 'font-medium'
              )}
            >
              {renderNotificationPhrase()}
            </p>
          </div>

          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-1'>
              <UserAvatar user={notification.user} size='xxs' rounded />
              <span className='text-xxs'>{notification.user.name}</span>
            </div>
            <p className='text-xxs'>{notificationRelativeTime}</p>
          </div>
        </div>
      </PostLink>
    </div>
  )
}
