import { Copy } from '@phosphor-icons/react'
import { useContext } from 'react'

import Button from '@/components/shared/ui/Button'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { copyClipboard } from '@/lib/helpers/appHelpers'
import { getPostURL } from '@/lib/helpers/modules/postHelper'
import type { IOrganizationData } from '@/types/organization'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
}

export default function SinglePostCopyLinkAction({ post }: IPropTypes) {
  const { organization } = useContext(HNContext) as {
    organization: IOrganizationData
  }
  const t = useTranslations()

  return (
    <Tooltip text={t('post.labels.copyLink')} placement='bottom' asChild>
      <Button
        fab
        size='xs'
        className={'group !text-gray12/80 hover:text-gray12'}
        onClick={() =>
          copyClipboard(
            getPostURL(organization?.home_page, post.slug),
            t('post.messages.linkCopied')
          )
        }
        variant='outline'
      >
        <Copy
          weight='fill'
          className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
        />
      </Button>
    </Tooltip>
  )
}
