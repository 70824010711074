import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'

import OrganizationAvatar from '@/components/shared/components/OrganizationAvatar'
import Avatar from '@/components/shared/ui/Avatar'
import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import LineHeader from '@/components/shared/ui/LineHeader'
import Typography from '@/components/shared/ui/Typography'
import { APP_NAME } from '@/config/appConstants'
import { featureOSLogoURL } from '@/config/staticURLs'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { sentry, stateKeyValueChange } from '@/lib/helpers/appHelpers'
import { templateComponentReplace } from '@/lib/helpers/stringHelpers'
import { signUp } from '@/models/Auth'
import type { ISignupData } from '@/types/auth'
import type { IOrganizationData } from '@/types/organization'
import toaster from '@/utils/toast'

import AuthProviders from '../providers/AuthProviders'
import PasswordRuleHintPopover from '../shared/PasswordRuleHintPopover'

interface IPropTypes {
  isModal?: boolean
  showSocialLogins?: boolean
  containerClassName?: string
  onSignInClick?: React.MouseEventHandler<HTMLParagraphElement>
}
export default function SignUp({
  showSocialLogins = true,
  isModal,
  containerClassName,
  onSignInClick,
}: IPropTypes) {
  const t = useTranslations('auth')
  const { organization, auth, subdomain } = useContext(HNContext)

  const [signupData, setSignupData] = useState<ISignupData>({})
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const router = useRouter()

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    const { email, password, password_confirmation } = signupData
    if (!password || !password_confirmation)
      return toaster.error({
        message: t('errors.ERROR_CODE_ENTER_PASSWORD_AND_CONFIRM_PASSWORD'),
      })
    if (loading || !email || !email.length) return null

    setLoading(true)

    return signUp(signupData)
      .then(() => {
        try {
          if (router.query.redirectURL)
            window.location.href = String(router.query.redirectURL)
          else if (subdomain === 'portal') {
            window.location.href = '/new'
          } else if (isModal) {
            router.reload()
          } else {
            window.location.href = '/'
          }
        } catch (err) {
          sentry.exception(
            new Error(`Error while redirecting to the page after signup ${err}`)
          )
          window.location.href = '/'
        }
      })
      .catch((err) => {
        if (isModal) {
          toaster.error({ message: err.message })
        } else {
          setErrorMessage(err.message)
        }
        setLoading(false)
      })
  }

  return (
    <div
      className={clsx(
        showSocialLogins ? 'h-screen w-screen items-center' : '',
        containerClassName,
        'flex flex-col justify-center'
      )}
    >
      <div className={clsx(showSocialLogins ? 'p-8' : '')}>
        <div
          className={clsx(
            'mx-auto flex-col justify-center space-y-6',
            showSocialLogins ? 'md:w-[350px]' : ''
          )}
        >
          {showSocialLogins && (
            <>
              <div className='flex flex-col items-center space-y-2 text-center'>
                {organization ? (
                  <OrganizationAvatar
                    organization={organization as IOrganizationData}
                    size='lg'
                  />
                ) : (
                  <Avatar url={featureOSLogoURL} name={APP_NAME} rounded />
                )}
                <Typography.Title level={3} className='text-2xl font-bold'>
                  {t('signUp.title')}
                </Typography.Title>
              </div>
              <div className='empty:hidden'>
                {errorMessage && (
                  <p className='text-sm text-red8'>{errorMessage}</p>
                )}
              </div>
            </>
          )}
          <div className='grid gap-6'>
            {auth?.email && (
              <form onSubmit={handleSubmit}>
                <div className='grid gap-4'>
                  <div className='grid gap-2'>
                    <Input
                      dataTestId='signup_email_input'
                      id='name'
                      size='sm'
                      label={t('fields.name.label')}
                      placeholder={t('fields.name.placeholder')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        stateKeyValueChange(
                          'name',
                          e.target.value,
                          setSignupData
                        )
                      }
                      type='string'
                      autoCapitalize='none'
                      autoComplete='name'
                    />
                    <Input
                      dataTestId='signup_email_input'
                      id='email'
                      size='sm'
                      label={t('fields.email.label')}
                      placeholder={t('fields.email.placeholder')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        stateKeyValueChange(
                          'email',
                          e.target.value,
                          setSignupData
                        )
                      }
                      type='email'
                      autoCapitalize='none'
                      autoComplete='email'
                    />
                    <div className='flex items-center justify-between'>
                      <Label>{t('fields.password.label')}</Label>
                      <PasswordRuleHintPopover />
                    </div>
                    <Input
                      dataTestId='signup_password_input'
                      size='sm'
                      placeholder={t(
                        'fields.password_confirmation.placeholder'
                      )}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        stateKeyValueChange(
                          'password',
                          e.target.value,
                          setSignupData
                        )
                      }
                      type='password'
                    />
                    <Input
                      dataTestId='signup_confirm_password_input'
                      size='sm'
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        stateKeyValueChange(
                          'password_confirmation',
                          e.target.value,
                          setSignupData
                        )
                      }
                      type='password'
                      placeholder={t(
                        'fields.password_confirmation.placeholder'
                      )}
                      label={t('fields.password_confirmation.label')}
                    />
                  </div>
                  <Button
                    dataTestId='signup_button'
                    size='md'
                    disabled={
                      loading ||
                      !signupData?.password?.length ||
                      !signupData?.password_confirmation?.length
                    }
                  >
                    {loading
                      ? t('buttons.submitting', { ignorePrefix: true })
                      : t('signUp.submitButton')}
                  </Button>
                </div>
              </form>
            )}
            {showSocialLogins && (
              <>
                {auth?.email &&
                  Object.values(auth).filter(Boolean).length !== 1 && (
                    <LineHeader
                      color='lightgray'
                      title='Or continue with'
                      align='center'
                      headerClassName='!bg-gray3 dark:!bg-gray2'
                    />
                  )}
                <AuthProviders />
              </>
            )}
          </div>
          {showSocialLogins && (
            <p className='px-8 text-center text-sm text-gray10'>
              {templateComponentReplace(t(`signUp.alreadyHaveAccount`), {
                signIn: onSignInClick ? (
                  <span
                    onClick={onSignInClick}
                    role='button'
                    className='cursor-pointer underline hover:text-brand'
                  >
                    {t(`labels.signIn`)}
                  </span>
                ) : (
                  <Link
                    className='underline hover:text-brand'
                    href={{
                      pathname: '/auth/signin',
                      query: router.query,
                    }}
                  >
                    {t(`labels.signIn`)}
                  </Link>
                ),
              })}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
