import {
  BILLING_DETAILS_PATH,
  BILLING_INFO,
  CUSTOMER,
  INVOICE_PATH,
  MANAGE_SUBSCRIPTION,
  MIGRATE_TO_PADDLE,
  MIGRATE_TO_STRIPE,
  PLAN_LIMITS_PATH,
  PLAN_UPGRADE_PATH,
  POWER_UPS_INFO_PATH,
  PRORATE_POWER_UPS_PATH,
  PURCHASE_POWER_UPS,
  PURCHASE_VERIFICATION_PATH,
  QUANTITY_POWER_UP_PURCHASE_PATH,
  SUBSCRIPTION,
  SUBSCRIPTION_RESUME_PATH,
  SUBSCRIPTIONS,
  TRIAL_EXTEND_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import type {
  IBillingInfoParams,
  ICreateSubscriptionParams,
  IPaymentSource,
  IPowerUpInfo,
  IPurchasePowerUpsParams,
} from '@/types/billing'
import type { IRequestHeaders } from '@/types/common'

export const getBillingInfo = () => {
  return API.get(BILLING_INFO)
}
export const migrateToStripe = (data: any) => API.post(MIGRATE_TO_STRIPE, data)
export const cancelSubscription = (id: string, source: IPaymentSource) =>
  API.delete(SUBSCRIPTION(id, source))
export const manageSubscription = (source: IPaymentSource) =>
  API.post(MANAGE_SUBSCRIPTION(source))
export const createStripeCustomer = (source: IPaymentSource) =>
  API.post(CUSTOMER(source))
export const migrateToPaddle = (data: any, headers?: IRequestHeaders) =>
  API.post(MIGRATE_TO_PADDLE, data, { headers })
export const createSubscription = (
  data: ICreateSubscriptionParams,
  source: IPaymentSource,
  headers?: IRequestHeaders
) => API.post(SUBSCRIPTIONS(source), data, { headers })
export const upgradeSubscription = (
  data: ICreateSubscriptionParams,
  subscriptionId: string,
  source: IPaymentSource
) => API.put(SUBSCRIPTION(subscriptionId, source), data)

export const extendTrial = (data: { trial_extended: boolean }) => {
  return API.put(TRIAL_EXTEND_PATH, data)
}

export const getPlanAndPowerUps = (
  data: ICreateSubscriptionParams | IPurchasePowerUpsParams
) => {
  return API.post(PRORATE_POWER_UPS_PATH, data)
}

export const purchasePowerUps = (
  data: IPurchasePowerUpsParams,
  headers?: IRequestHeaders
) => {
  return API.post(PURCHASE_POWER_UPS, data, { headers })
}

export const getPowerUps = () => {
  return API.get(PURCHASE_POWER_UPS)
}

export const getInvoices = (data: IBillingInfoParams) =>
  API.get(INVOICE_PATH, data)

export const getPlanLimits = (data: { plan: string }) =>
  API.get(PLAN_LIMITS_PATH, data)

export const upgradePlan = (
  data: ICreateSubscriptionParams,
  source: IPaymentSource,
  subscriptionId: string
) => API.put(PLAN_UPGRADE_PATH(source, subscriptionId), data)

export const getCardDetails = () => API.get(BILLING_DETAILS_PATH)

export const getPowerUpsInfo = (
  data: ICreateSubscriptionParams | IPurchasePowerUpsParams
) => API.get(POWER_UPS_INFO_PATH, data)

export const removePowerUp = (powerUp: IPowerUpInfo) =>
  API.delete(PURCHASE_POWER_UPS, powerUp)

export const purchaseQuantityPowerUp = (powerUp: IPowerUpInfo) =>
  API.put(QUANTITY_POWER_UP_PURCHASE_PATH(powerUp.slug), powerUp)

export const resumeSubscription = () => API.post(SUBSCRIPTION_RESUME_PATH)

export const verifyCheckout = (data: any) =>
  API.get(PURCHASE_VERIFICATION_PATH, data)
