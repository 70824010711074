import React from 'react'

import type { IButtonSize } from '@/components/shared/ui/Button/Button'
import EmptyState from '@/components/shared/ui/EmptyState'
import type { IEmptyStateProps } from '@/components/shared/ui/EmptyState/EmptyState'

import UpgradeButton from './UpgradeButton'

interface IPropTypes extends Pick<IEmptyStateProps, 'icon'> {
  size?: IButtonSize
  description?: string
  message?: string
  title?: string
  featureName?: string
  buttonText?: string
}
export default function UpgradeBlock(props: IPropTypes) {
  return (
    <EmptyState
      icon={props.icon}
      description={props.message}
      title={props.title}
      actionComponent={
        <UpgradeButton {...props} featureName={props?.featureName} />
      }
    />
  )
}
