import {
  AsteriskSimple,
  At,
  BookBookmark,
  Bookmark,
  Gauge,
  GearFine,
  PaintBrush,
} from '@phosphor-icons/react'

import type { INavigationItem } from '@/types/sidebar'

export const knowledgeBaseNavigation: INavigationItem[] = [
  {
    key: 'analytics',
    link: '/admin/kb/analytics',
    anotherHref: ['/admin/kb/analytics'],
    labelTransKey: 'sidebar.items.analytics',
    icon: <Gauge size={16} />,
    availableRoles: ['admin', 'member', 'csm'],
    isExact: true,
  },
  {
    key: 'home',
    link: '/admin/kb',
    anotherHref: ['/admin/kb'],
    labelTransKey: 'sidebar.items.home',
    icon: <BookBookmark size={16} />,
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
  },
  {
    key: 'recentArticles',
    link: '/admin/kb/articles',
    anotherHref: ['/admin/kb/articles'],
    labelTransKey: 'sidebar.items.recentArticles',
    icon: <AsteriskSimple size={16} />,
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
  },
  {
    key: 'assignedArticles',
    link: '/admin/kb/articles/assigned',
    anotherHref: ['/admin/kb/articles/assigned'],
    labelTransKey: 'sidebar.items.assignedToMe',
    icon: <At size={16} />,
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
    countKey: 'assignedArticlesCount',
  },

  {
    key: 'tags',
    link: '/admin/kb/tags',
    anotherHref: ['/admin/kb/tags'],
    labelTransKey: 'sidebar.items.tags',
    icon: <Bookmark size={16} />,
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
  },

  {
    key: 'settings',
    link: '/admin/kb/settings',
    anotherHref: ['/admin/kb/settings'],
    labelTransKey: 'sidebar.items.changelog_settings',
    icon: <GearFine size={16} />,
    availableRoles: ['admin'],
    group: 'settings',
    isExact: true,
  },
  {
    key: 'customizations',
    link: '/admin/kb/customizations',
    anotherHref: ['/admin/kb/customizations'],
    labelTransKey: 'sidebar.items.boards_customization',
    icon: <PaintBrush size={16} />,
    availableRoles: ['admin'],
    group: 'settings',
    isExact: true,
  },
]

export const knowledgeBaseNavigationGroups = {
  settings: {
    label: 'Settings',
    open: true,
  },
}
