import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'

export const canViewAttachments = (post: IPost, user?: IUserProfile) => {
  return (
    user &&
    (user?.is_csm_of_organization ||
      post.can_edit ||
      user?.id === post.submitter?.id)
  )
}

export const canDeletePostAttachment = (post: IPost, user?: IUserProfile) => {
  return (
    user &&
    (user.is_member_of_organization ||
      post.can_edit ||
      user.id === post.submitter?.id)
  )
}
