import { Gift } from '@phosphor-icons/react'
import Script from 'next/script'
import React from 'react'

import Typography from '@/components/shared/ui/Typography'
import { WIDGET_URL } from '@/config/appConstants'
import { useTranslations } from '@/hooks/useTranslations'

export default function AsideWhatsNew() {
  const t = useTranslations('sidebar')
  return (
    <>
      <Script
        id={`hellonextChangelogWidget`}
        src={WIDGET_URL}
        onLoad={() => {
          const widget = new window.HellonextWidget({
            modules: ['changelog'],
            type: 'modal',
            openFrom: 'center',
            theme: 'light',
            accent: '#5551ff',
            selector: '#whats_new_widget',
            token: 'GEdHL0YpM430Gc0Ch-Tr2w',
            showOnlySubmission: false,
            enableIndicator: true,
            changelogFilters: {
              per_page: 5,
            },
          })
          widget.init()
        }}
      />
      <div
        id='whats_new_widget'
        className='group my-0.5 flex w-full cursor-pointer items-center truncate rounded-lg px-3 py-1 text-left text-[13px] font-medium text-gray12 transition-all hover:bg-snow hover:text-gray12 hover:no-underline focus:z-50   dark:hover:bg-gray-dark-hover   '
      >
        <Gift weight='fill' size={16} className='mr-2 text-gray11' />
        <Typography.Text
          className='text-left text-[13px] font-medium !text-gray12 '
          type='default'
        >
          {t('labels.whatsNew')}
        </Typography.Text>
      </div>
    </>
  )
}
