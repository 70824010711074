import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

const pillStyles = cva(
  'inline-flex items-center justify-center font-inherit text-center align-middle outline-none select-none rounded-full flex gap-1',
  {
    variants: {
      size: {
        '0': 'h-[18px] px-1 py-0.5 text-[11px]',
        '1': 'h-[20px] px-1 text-xs',
        '2': 'h-[25px] px-2 text-sm',
      },
      fontfamily: {
        sans: 'font-sans',
        mono: 'font-mono',
      },
      variant: {
        gray: 'bg-gray3 text-gray11 focus:ring-1 focus:ring-gray8',
        red: 'bg-red3 text-red11 focus:ring-1 focus:ring-red8',
        crimson: 'bg-crimson3 text-crimson11 focus:ring-1 focus:ring-crimson8',
        pink: 'bg-pink3 text-pink11 focus:ring-1 focus:ring-pink8',
        purple: 'bg-purple3 text-purple11 focus:ring-1 focus:ring-purple8',
        violet: 'bg-violet3 text-violet11 focus:ring-1 focus:ring-violet8',
        indigo: 'bg-indigo3 text-indigo11 focus:ring-1 focus:ring-indigo8',
        blue: 'bg-blue3 text-blue11 focus:ring-1 focus:ring-blue8',
        cyan: 'bg-cyan3 text-cyan11 focus:ring-1 focus:ring-cyan8',
        teal: 'bg-teal3 text-teal11 focus:ring-1 focus:ring-teal8',
        green: 'bg-green3 text-green11 focus:ring-1 focus:ring-green8',
        lime: 'bg-lime3 text-lime11 focus:ring-1 focus:ring-lime8',
        yellow: 'bg-yellow3 text-yellow11 focus:ring-1 focus:ring-yellow8',
        orange: 'bg-orange3 text-orange11 focus:ring-1 focus:ring-orange8',
        gold: 'bg-gold3 text-gold11 focus:ring-1 focus:ring-gold8',
        bronze: 'bg-bronze3 text-bronze11 focus:ring-1 focus:ring-bronze8',
      },
      interactive: {
        true: 'cursor-pointer hover:bg-opacity-80 active:bg-opacity-90',
      },
    },
    defaultVariants: {
      size: '1',
      variant: 'gray',
      fontfamily: 'sans',
    },
  }
)

export interface IPillProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof pillStyles> {}

export const Pill: React.FC<IPillProps> = ({
  size,
  fontfamily,
  variant,
  interactive,
  children,
}) => {
  return (
    <span className={pillStyles({ size, fontfamily, variant, interactive })}>
      {children}
    </span>
  )
}
