import { Book } from '@phosphor-icons/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import { useTranslations } from '@/hooks/useTranslations'
import clsxm from '@/lib/clsxm'
import { EventEmitter } from '@/lib/eventEmitter'
import { recordEvent } from '@/lib/helpers/appHelpers'
import { getOnboardingSteps } from '@/models/Organization'
import type { IStepType } from '@/types/organization'
import toaster from '@/utils/toast'

export default function AsideOnboard() {
  const organizationOnboardingTranslation = useTranslations(
    'organization.onboarding'
  )

  const [loading, setLoading] = useState(true)
  const [steps, setSteps] = useState<IStepType[]>([])

  const completedCount = useMemo(
    () =>
      steps.reduce((count, step) => (step.completed ? count + 1 : count), 0),
    [steps]
  )

  const completedPercentage = useMemo(() => {
    return Math.round((completedCount / steps.length) * 100)
  }, [completedCount])

  const fetchData = useCallback(() => {
    getOnboardingSteps()
      .then(setSteps)
      .catch((err) =>
        toaster.error({
          message: err.message,
        })
      )
      .finally(() => setLoading(false))
  }, [])

  const handleStepPerformed = useCallback((data?: { step: string }) => {
    if (data?.step) {
      recordEvent('ONBOARDING_COMPLETE', {
        skipSegment: true,
        step: data.step,
      })
      setSteps((prevSteps) => {
        return prevSteps.map((step) => {
          return step.step === data.step
            ? {
                ...step,
                completed: true,
              }
            : step
        })
      })
    }
  }, [])

  useEffect(() => {
    fetchData()
    EventEmitter.subscribe('ONBOARDING_STEP_PERFORMED', handleStepPerformed)

    return () => {
      EventEmitter.unsubscribe('ONBOARDING_STEP_PERFORMED', handleStepPerformed)
    }
  }, [])

  return (
    <div className='flex items-center justify-between border-b-[0.5px] border-b-gray5 p-[9px] transition-colors duration-200 dark:border-gray-dark-border'>
      <UnstyledLink
        href='/admin/onboarding'
        className={clsxm(
          'group relative my-0.5 flex w-full cursor-pointer items-center justify-between truncate rounded-lg bg-snow px-2 py-1 text-left text-[13px] font-medium text-gray12 no-underline transition-colors focus:z-50 dark:bg-gray-dark-hover   '
        )}
        type='button'
      >
        <div className='flex flex-row items-center justify-start gap-2'>
          <Book className='h-4 w-4 text-gray9' />
          <span>{organizationOnboardingTranslation('trigger')}</span>
        </div>
        <span className='text-gray9'>{`(${completedCount}/${steps.length})`}</span>
        {!loading && (
          <div
            className={clsxm(
              'absolute bottom-0 left-0 right-0 h-0.5 bg-fosblue'
            )}
            style={{
              width: `${completedPercentage}%`,
            }}
          ></div>
        )}
      </UnstyledLink>
    </div>
  )
}
