import { useRouter } from 'next/router'
import React from 'react'

interface IPropTypes {
  children: React.ReactNode
}

export default function PageBasedView({ children }: IPropTypes) {
  const router = useRouter()
  const { asPath } = router

  const selectedChild = React.Children.toArray(children).find((child: any) => {
    const path = child.props['data-path']
    const matchParams = child.props['data-match-params']
    const pathsToCheck = Array.isArray(path) ? path : [path]
    const currentPath = !matchParams ? asPath.split('?')[0] : asPath
    return pathsToCheck.includes(currentPath)
  })
  return <>{selectedChild}</> || <></>
}
