import { mergeAttributes, Node } from '@tiptap/core'
import type { Node as ProseMirrorNode, NodeType } from '@tiptap/pm/model'
import { PluginKey } from '@tiptap/pm/state'
import { ReactRenderer } from '@tiptap/react'
import type { SuggestionOptions } from '@tiptap/suggestion'
import Suggestion from '@tiptap/suggestion'
import tippy from 'tippy.js'

import type { ISuggestionOptions } from '@/types/editor'

import MentionList from './MentionList'

export type MentionOptions = {
  HTMLAttributes: Record<string, any>
  renderLabel: (props: {
    options: MentionOptions
    node: ProseMirrorNode
  }) => string
  suggestion: Omit<SuggestionOptions, 'editor'>
}

export const MentionPluginKey = (name: string) => new PluginKey(name)

export const Mention = (config: ISuggestionOptions) =>
  Node.create<MentionOptions>({
    name: config.name,
    addOptions() {
      return {
        HTMLAttributes: {
          class: 'mention',
        },
        renderLabel({
          options,
          node,
        }: {
          options: MentionOptions
          node: ProseMirrorNode
        }) {
          return `${options.suggestion.char}${
            node.attrs.label ?? node.attrs.id
          }`
        },
        suggestion: {
          char: config.char.toString(),
          pluginKey: MentionPluginKey(config.name),
          allowedPrefixes: [' '],
          allowSpaces: true,
          items: async ({ query }) => config.handler(query),
          render: () => {
            let reactRenderer: ReactRenderer
            let popup: any

            return {
              onStart: (props) => {
                reactRenderer = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor,
                })

                if (!props.clientRect) return

                // @ts-ignore
                popup = tippy('body', {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: 'manual',
                  placement: 'bottom-start',
                  popperOptions: { strategy: 'fixed' },
                })
              },

              onUpdate(props) {
                reactRenderer.updateProps(props)

                if (!props.clientRect) {
                  return
                }

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                })
              },

              onKeyDown(props) {
                if (props.event.key === 'Escape') {
                  popup[0].hide()

                  return true
                }

                // @ts-ignore
                return reactRenderer?.ref?.onKeyDown(props)
              },

              onExit() {
                if (popup && popup[0]) {
                  popup[0].destroy()
                  reactRenderer.destroy()
                }
              },
            }
          },
          command: ({ editor, range, props }) => {
            const newRange = range
            // increase range.to by one when the next node is of type "text"
            // and starts with a space character
            const { nodeAfter } = editor.view.state.selection.$to
            const overrideSpace = nodeAfter?.text?.startsWith(' ')

            if (overrideSpace) {
              newRange.to += 1
            }

            editor
              .chain()
              .focus()
              .insertContentAt(newRange, [
                {
                  type: this.name,
                  attrs: props,
                },
                {
                  type: 'text',
                  text: ' ',
                },
              ])
              .run()

            window.getSelection()?.collapseToEnd()
          },
          allow: ({ state, range }) => {
            const $from = state.doc.resolve(range.from)
            const type = state.schema.nodes[this.name]
            const allow = !!$from.parent.type.contentMatch.matchType(
              type as NodeType
            )

            return allow
          },
        },
      }
    },

    group: 'inline',

    inline: true,

    selectable: false,

    atom: true,

    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-id'),
          renderHTML: (attributes) => {
            if (!attributes.id) {
              return {}
            }

            return {
              'data-id': attributes.id,
            }
          },
        },
        href: {
          default: null,
          parseHTML: (element) => element.getAttribute('href'),
          renderHTML: (attributes) => {
            if (!attributes.href) {
              return {}
            }
            return {
              href: attributes.href,
            }
          },
        },
        label: {
          default: null,
          parseHTML: (element) => element.getAttribute('data-label'),
          renderHTML: (attributes) => {
            if (!attributes.label) {
              return {}
            }

            return {
              'data-label': attributes.label,
            }
          },
        },
      }
    },

    parseHTML() {
      return [
        {
          tag: `a[data-mention]`,
        },
        {
          tag: `a[data-type="${this.name}"]`,
        },
      ]
    },

    renderHTML({ node, HTMLAttributes }) {
      return [
        'a',
        mergeAttributes(
          { 'data-type': this.name },
          this.options.HTMLAttributes,
          HTMLAttributes
        ),
        this.options.renderLabel({
          options: this.options,
          node,
        }),
      ]
    },

    renderText({ node }) {
      return this.options.renderLabel({
        options: this.options,
        node,
      })
    },

    addKeyboardShortcuts() {
      return {
        Backspace: () =>
          this.editor.commands.command(({ tr, state }) => {
            let isMention = false
            const { selection } = state
            const { empty, anchor } = selection

            if (!empty) {
              return false
            }

            state.doc.nodesBetween(anchor - 1, anchor, (node, pos): boolean => {
              if (node.type.name === this.name) {
                isMention = true
                tr.insertText(
                  this.options.suggestion.char || '',
                  pos,
                  pos + node.nodeSize
                )

                return false
              }
              return true
            })

            return isMention
          }),
      }
    },

    addProseMirrorPlugins() {
      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion,
        }),
      ]
    },
  })
