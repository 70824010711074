import React, { useState } from 'react'

import Button from '@/components/shared/ui/Button'
import {
  GithubIssueClosedIcon,
  GithubIssueOpenIcon,
} from '@/components/shared/ui/Icons'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import type { IGitlabSearchIssue } from '@/types/integration/gitlab'

interface IPropTypes {
  issues: IGitlabSearchIssue[]
  onSelect: (value: IGitlabSearchIssue) => void
  query: string
}

export default function GitlabIssuesList({
  issues,
  onSelect,
  query,
}: IPropTypes) {
  const t = useTranslations(`post.integrations.gitlab`)
  const [linking, setLinking] = useState(``)

  const handleSelect = (value: IGitlabSearchIssue) => {
    setLinking(value.issue_id.toString())
    onSelect(value)
  }

  if (!issues || !issues.length) {
    if (query) {
      return (
        <p className='border-b border-gray5 p-2 text-xs text-gray9  '>
          {t(`noIssuesFound`)}
        </p>
      )
    }
  }

  return (
    <>
      <ul className='divide-y divide-gray5 overflow-hidden '>
        {issues.map((issue, index) => (
          <li
            className='flex items-center justify-between space-x-1 p-2'
            key={index}
          >
            <div className='flex items-center space-x-1'>
              {issue.state === 'open' ? (
                <GithubIssueOpenIcon fill='green' />
              ) : (
                <GithubIssueClosedIcon fill='#a371f7' />
              )}
              <span className='truncate text-xs'>{issue.title}</span>
            </div>
            <div className='space-x-2'>
              <Typography.Text className='!text-xs font-medium !text-green11'>
                {t(`common.labels.${issue.state}`, {
                  ignorePrefix: true,
                  fallback: () => issue.state,
                })}
              </Typography.Text>
              <Button
                disabled={Boolean(issue.issue_id.toString() === linking)}
                onClick={() => handleSelect(issue)}
                size='xs'
              >
                {issue.issue_id.toString() === linking
                  ? t(`buttons.linking`, { ignorePrefix: true })
                  : t(`buttons.link`, { ignorePrefix: true })}
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}
