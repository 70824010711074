import { LinkBreak } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'

import Badge from '@/components/shared/ui/Badge'
import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'
import { untagJiraIssues } from '@/models/Post'
import type { IPost, IPostJiraIssue } from '@/types/post'

interface IPropTypes {
  post: IPost
  issues: IPostJiraIssue[]
  updateIssues: (data: IPost) => void
}

export default function JiraIssuesList({
  issues,
  post,
  updateIssues,
}: IPropTypes) {
  const t = useTranslations(`post.integrations.jira`)
  const [jiraIssues, setJiraIssues] = useState<IPostJiraIssue[]>(issues)

  const handleRemove = (issue: IPostJiraIssue) => {
    untagJiraIssues(post.slug, issue).then((data: IPost) => {
      setJiraIssues(data.issue_list || [])
      updateIssues(data)
    })
  }

  useEffect(() => {
    setJiraIssues(issues)
  }, [issues])

  if (jiraIssues.length < 1)
    return (
      <p className='mb-0.5 mt-2 text-xs text-gray9 '>
        {t(`linkedJiraIssuesEmpty`)}
      </p>
    )

  return (
    <>
      <p className='mb-0.5 mt-2 text-xs text-gray9 '>{t(`linkedJiraIssues`)}</p>
      <ul className='my-2 divide-y divide-gray5 overflow-hidden rounded border border-gray5 bg-snow transition '>
        {jiraIssues.map((issue, index) => (
          <li
            className='flex items-center justify-between gap-2 p-2'
            key={index}
          >
            <div className='flex gap-2 overflow-hidden'>
              <a href={issue.url} className='truncate text-sm text-gray10 '>
                {issue.issue_id} -{issue.title}
              </a>
              {issue.status && <Badge size='xxs' value={issue.status} />}
            </div>

            <Button
              size='xxs'
              variant='outline'
              onClick={() => handleRemove(issue)}
            >
              <LinkBreak weight='bold' className='h-4 w-4' />
            </Button>
          </li>
        ))}
      </ul>
    </>
  )
}
