import {
  ArrowRight,
  KeyReturn,
  LockSimple,
  Moon,
  Plus,
  Receipt,
  Sun,
  UserPlus,
} from '@phosphor-icons/react'
import {
  GearIcon,
  KeyAsteriskIcon,
  RocketIcon,
  RowsIcon,
  TelescopeIcon,
} from '@primer/octicons-react'
import { Command } from 'cmdk'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import Spinner from '@/components/shared/ui/Loader'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useSearchClient } from '@/context/HNSearchContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import { recordEvent } from '@/lib/helpers/appHelpers'
import { checkAction } from '@/lib/helpers/cmdkHelper'
import {
  adminSideChangelogPath,
  adminSideRoadmapPath,
  adminSideSettingPath,
  adminSideTeamPath,
  billingPath,
} from '@/lib/helpers/pathHelpers'
import { postSearch } from '@/models/Post'
import toaster from '@/utils/toast'

const ICONCLASSNAME = 'text-gray9 '
const DEBOUNCE_TIMER = 500
interface ISuggestionItem {
  key?: string
  label: string
  path?: string
  icon: JSX.Element
  preIcon?: JSX.Element
  shouldShow?: boolean
}

export default function CommandPallet() {
  const t = useTranslations('cmdk')
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const debounceRef = useRef<NodeJS.Timeout>()

  const {
    theme,
    userProfile,
    organizationSetting,
    updateContext,
    organization,
    buckets,
    isAdmin,
  } = useContext(HNContext)
  const { searchClient } = useSearchClient()
  const [searchText, setSearchText] = useState<string>('')
  const [searchEntity, setSearchEntity] = useState<string | null>(null)
  const [suggestions, setSuggestions] = useState<ISuggestionItem[] | any>([])
  const [loading, setLoading] = useState(false)
  const hitsPerPage = 30
  const offset = 0

  const groupedBoards = useMemo(() => {
    const publicBoards = buckets?.filter((b) => !b.private)
    const privateBoards = buckets?.filter((b) => b.private)
    return publicBoards?.concat(privateBoards || []) || []
  }, [buckets])

  const LINKS = [
    {
      key: 'allPosts',
      label: t('sidebar.items.allPosts', { ignorePrefix: true }),
      icon: <ArrowRight className={ICONCLASSNAME} />,
      preIcon: <KeyAsteriskIcon className={ICONCLASSNAME} />,
      shouldShow: userProfile?.is_csm_of_organization,
    },
    {
      key: 'boards',
      label: organizationSetting?.bucket_plural_name,
      icon: <ArrowRight className={ICONCLASSNAME} />,
      preIcon: <RowsIcon className={ICONCLASSNAME} />,
      shouldShow: userProfile?.is_csm_of_organization,
    },
    {
      key: 'roadmap',
      label: organizationSetting?.roadmap_name,
      path: adminSideRoadmapPath(''),
      icon: <KeyReturn className={ICONCLASSNAME} />,
      preIcon: <TelescopeIcon className={ICONCLASSNAME} />,
      shouldShow: userProfile?.is_member_of_organization,
    },
    {
      key: 'changelog',
      label: organizationSetting?.changelog_plural_name,
      path: adminSideChangelogPath(''),
      icon: <KeyReturn className={ICONCLASSNAME} />,
      preIcon: <RocketIcon className={ICONCLASSNAME} />,
      shouldShow: userProfile?.is_member_of_organization,
    },
    {
      key: 'settings',
      label: t('sidebar.items.settings', { ignorePrefix: true }),
      path: adminSideSettingPath(''),
      icon: <KeyReturn className={ICONCLASSNAME} />,
      preIcon: <GearIcon className={ICONCLASSNAME} />,
      shouldShow: userProfile?.is_admin_of_organization,
    },
    {
      key: 'billing',
      label: t('labels.billingInformation'),
      path: billingPath,
      icon: <KeyReturn className={ICONCLASSNAME} />,
      preIcon: <Receipt className={ICONCLASSNAME} />,
      shouldShow: userProfile?.is_admin_of_organization,
    },
    {
      key: 'theme',
      label: theme?.darkMode
        ? t('organization.settings.theme.options.lightMode', {
            ignorePrefix: true,
          })
        : t('organization.settings.theme.options.darkMode', {
            ignorePrefix: true,
          }),
      icon: <KeyReturn className={ICONCLASSNAME} />,
      preIcon: theme?.darkMode ? (
        <Sun className={ICONCLASSNAME} />
      ) : (
        <Moon className={ICONCLASSNAME} />
      ),
      shouldShow: userProfile?.is_admin_of_organization,
    },
    {
      key: 'newPost',
      label: t('labels.createNewPost'),
      icon: <KeyReturn className={ICONCLASSNAME} />,
      preIcon: <Plus className={ICONCLASSNAME} />,
      shouldShow: userProfile?.is_csm_of_organization,
    },
    {
      key: 'invite',
      label: t('sidebar.labels.inviteTeam', { ignorePrefix: true }),
      path: `${adminSideTeamPath('')}?invite=true`,
      icon: <KeyReturn className={ICONCLASSNAME} />,
      preIcon: <UserPlus className={ICONCLASSNAME} />,
      shouldShow: userProfile?.is_admin_of_organization,
    },
  ]

  const toggleOpen = (data: any) => {
    if (data.action !== 'TOGGLE_COMMAND_PALLET' || !isAdmin) return
    setOpen((_open) => !_open)
  }

  const renderSuggestionList = (type: string) => {
    return (item: any) => {
      switch (type) {
        case 'bucket':
          return {
            label: item.display_name,
            icon: <KeyReturn className={ICONCLASSNAME} />,
            path: `/admin/b/${item.name}`,
            preIcon: item?.private ? (
              <LockSimple className={ICONCLASSNAME} />
            ) : (
              <></>
            ),
            shouldShow: true,
          }
        case 'post':
          return {
            label: item.title,
            icon: <KeyReturn className={ICONCLASSNAME} />,
            path: `/admin/p/${item.slug}`,
            preIcon: <></>,
            shouldShow: true,
          }
        default:
          return {}
      }
    }
  }

  const querySearch = (query: string) => {
    if (searchEntity === 'allPosts' || query === 'all_posts') {
      setLoading(true)
      return postSearch(query === 'all_posts' ? '' : query, searchClient, {
        filter: [
          ['approval_status=approved'],
          [`organization_id=${organization?.id}`],
        ],
        sort: ['id:desc'],
        offset,
        limit: hitsPerPage,
      })
        .then((data) => {
          if (data) {
            setSuggestions(data?.map(renderSuggestionList('post')))
          } else setSuggestions([])
        })
        .catch(() =>
          toaster.error({
            message: t('common.oopsSomethingWentWrong', { ignorePrefix: true }),
          })
        )
        .finally(() => setLoading(false))
    }
    return false
  }

  const handleSelect = (command: any) => {
    if (command.path) {
      router.push(command.path)
      return setOpen(false)
    }
    if (command.key === 'allPosts') {
      setSearchEntity(command.key)
      return querySearch('all_posts')
    }
    if (command.key === 'boards') {
      setSearchEntity(command.key)
      return setSuggestions(groupedBoards?.map(renderSuggestionList('bucket')))
    }
    if (command.key !== 'allPosts' || command.key !== 'boards') {
      checkAction(command, { theme, updateContext })
      return setOpen(false)
    }
    return false
  }

  const handleReset = () => {
    if (searchEntity?.length) {
      setSearchEntity(null)
      setSuggestions([])
      setSearchText('')
    } else setOpen(!open)
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      e.preventDefault()
      handleReset()
    }
  }

  useEffect(() => {
    EventEmitter.subscribe('SHORTCUT_TRIGGERED', toggleOpen)
    return () => {
      EventEmitter.unsubscribe('SHORTCUT_TRIGGERED', toggleOpen)
    }
  }, [])

  useEffect(() => {
    if (debounceRef.current) clearTimeout(debounceRef.current)
    debounceRef.current = setTimeout(() => {
      querySearch(searchText)
    }, DEBOUNCE_TIMER)
  }, [searchText])

  useEffect(() => {
    if (!open) {
      setSearchEntity(null)
      setSuggestions([])
      setSearchText('')
    }
    if (open) {
      recordEvent('COMMAND_PALETTE_OPEN', {
        organization_id: organization?.id,
      })
    }
  }, [open])

  const renderCommandItem = (command: any, index: number) => {
    if (!command.shouldShow) return null
    return (
      <Command.Item
        className='cmdk-item'
        key={index}
        onSelect={() => handleSelect(command)}
      >
        <div className='flex grow items-center space-x-2 truncate'>
          {command.preIcon && <span>{command.preIcon}</span>}
          <Typography.Text className='truncate font-medium !text-gray12'>
            {command.label}
          </Typography.Text>
        </div>
        <span>{command.icon}</span>
      </Command.Item>
    )
  }

  return (
    <Command.Dialog
      className='cmdk-root'
      shouldFilter={Boolean(searchEntity !== 'allPosts')}
      open={open}
      onOpenChange={setOpen}
      onKeyDown={handleKeyDown}
    >
      <Command.Input
        placeholder={t('labels.inputPlaceholder')}
        onValueChange={setSearchText}
        value={searchText}
        className='cmdk-input'
      />
      <Command.List className='cmdk-list !scrollbar-hide'>
        {loading ? (
          <Command.Loading>
            <Spinner />
          </Command.Loading>
        ) : (
          <>
            {searchEntity === 'allPosts' &&
              !searchText?.length &&
              !suggestions?.length && (
                <div cmdk-empty=''>{t('labels.noResultsFound')}</div>
              )}
            <Command.Empty className='cmdk-empty'>
              {t('labels.noResultsFound')}
            </Command.Empty>
            {suggestions?.map(renderCommandItem)}
            {!suggestions?.length &&
              !searchEntity &&
              LINKS.filter((l) => l.shouldShow).map(renderCommandItem)}
          </>
        )}
      </Command.List>
    </Command.Dialog>
  )
}
