import { useRouter } from 'next/router'
import React, { useContext } from 'react'

import AdminPostBoardFilter from '@/components/admin/posts/filters/AdminPostBoardFilter'
import PrimaryLink from '@/components/shared/ui/Links/PrimaryLink'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { getPostBucket } from '@/lib/helpers/modules/postHelper'
import { userSideBoardSinglePath } from '@/lib/helpers/pathHelpers'
import { getBasePathPrefix } from '@/lib/helpers/urlHelpers'
import postStore from '@/stores/PostListStore'
import type { IPost, IPostBucket } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function SinglePostBoardChange({ post }: IPropTypes) {
  const t = useTranslations('post')
  const router = useRouter()
  const { embedType, buckets = [] } = useContext(HNContext)
  const bucket = getPostBucket(post, buckets)
  const isRoadmapEmbed = router.pathname === '/embed/roadmap'
  const handleChange = (_key: string, value: ISelectOption) => {
    const SelectedBucket = value && value.length ? value[0] : null
    const updatedPost = {
      ...post,
      bucket: buckets?.find((b) => b.id === SelectedBucket) as IPostBucket,
      bucket_id: SelectedBucket,
    }
    if (!SelectedBucket) return null
    toaster.success({
      message: t('messages.board.success'),
    })
    return postStore
      .updatePost(
        post.slug,
        {
          bucket_id: SelectedBucket,
        },
        updatedPost as IPost
      )
      .catch(toaster.error)
  }

  const renderBoardName = () => {
    if (!bucket) return <></>
    return (
      <PrimaryLink
        href={userSideBoardSinglePath(
          bucket?.name,
          isRoadmapEmbed ? '' : getBasePathPrefix(embedType)
        )}
        target={isRoadmapEmbed ? '_blank' : '_self'}
        data-testid='post_board_change_uneditable'
      >
        <div className='flex items-center space-x-2 text-gray12'>
          <Typography.Text className='font-medium !text-gray12'>
            {bucket?.display_name}
          </Typography.Text>
        </div>
      </PrimaryLink>
    )
  }

  if (!bucket) return <></>
  return (
    <div
      className='grid grid-cols-5 items-center gap-4 px-4'
      data-testid='post_board_change_editable'
    >
      <Typography.Text className='col-span-2 font-medium'>
        {t('labels.board')}
      </Typography.Text>
      <span className='col-span-3'>
        {post.can_change_board ? (
          <AdminPostBoardFilter
            multiple={false}
            filters={{ bucket_id: post?.bucket_id }}
            onChange={handleChange}
            behaviour='select'
            placeholder={t('fields.board.placeholder')}
          />
        ) : (
          renderBoardName()
        )}
      </span>
    </div>
  )
}
