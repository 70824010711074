import { ArrowLeft, Check, FloppyDisk, Tray } from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'
import { broadcastPostMessage } from '@/lib/channel'
import clsxm from '@/lib/clsxm'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { bulkUpdateNotificationAction, updateNotification } from '@/models/User'
import type {
  INotificationBulkActionAPIPayload,
  INotificationStates,
} from '@/types/user'
import toaster from '@/utils/toast'

export default function UserNotificationBanner() {
  const router = useRouter()
  const t = useTranslations()
  const { notification_type, feature_request_id } = router.query
  const notificationId = router.query.notification_id as string
  const [showMarkAllAsDone, setShowMarkAllAsDone] = React.useState(true)
  const [loading, setLoading] = useState(false)

  const handleStatusUpdate = (status: INotificationStates) => {
    const tKey = status === 'done' ? 'movedToDone' : 'movedToSaved'
    updateNotification(notificationId, { status })
      .then(() => {
        broadcastPostMessage('ENTITY_UPDATE', {
          entity: ENTITIES.NOTIFICATIONS,
          actionType: EVENT_ACTION_TYPES.UPDATE,
          data: {
            data: {
              selectedAll: false,
              selectedIds: [Number(notificationId)],
              ignoredIds: [],
              action: 'status',
              notificationType: router.query
                ?.notification_type as INotificationStates,
            },
          },
        })
        EventEmitter.dispatch('ENTITY_UPDATE', {
          entity: ENTITIES.NOTIFICATIONS,
          actionType: EVENT_ACTION_TYPES.UPDATE,
          data: {
            data: {
              selectedAll: false,
              selectedIds: [Number(notificationId)],
              ignoredIds: [],
              action: 'status',
              notificationType: router.query
                ?.notification_type as INotificationStates,
            },
          },
        })
        toaster.success({
          message: t(`profile.notifications.messages.${tKey}.success`),
        })
      })
      .catch((err) => {
        toaster.error({ message: err.message })
      })
  }

  const handleReadAll = () => {
    setLoading(true)
    const bulkData: INotificationBulkActionAPIPayload = {
      select_all: false,
      whitelist_ids: [feature_request_id as string],
      blacklist_ids: [],
      current_status: (notification_type || 'inbox') as INotificationStates,
      seen: true,
      thread_view: true,
    }

    EventEmitter.dispatch('ENTITY_UPDATE', {
      actionType: EVENT_ACTION_TYPES.UPDATE,
      entity: 'NOTIFICATIONS',
      data: {
        data: {
          selectedAll: false,
          selectedIds: [Number(feature_request_id)],
          ignoredIds: [],
          action: 'seen',
          notificationType: router.query
            ?.notification_type as INotificationStates,
        },
      },
    })

    return bulkUpdateNotificationAction('seen', bulkData)
      .then(() => {
        broadcastPostMessage('ENTITY_UPDATE', {
          entity: ENTITIES.NOTIFICATIONS,
          actionType: EVENT_ACTION_TYPES.UPDATE,
          data: {
            data: {
              selectedAll: false,
              selectedIds: [Number(feature_request_id)],
              ignoredIds: [],
              action: 'seen',
              notificationType: router.query
                ?.notification_type as INotificationStates,
            },
          },
        })
        setShowMarkAllAsDone(false)
      })
      .catch(toaster.error)
      .finally(() => setLoading(false))
  }

  if (!notification_type || (!notificationId?.length && !showMarkAllAsDone))
    return <></>
  return (
    <div className='bg-blue3 p-2'>
      <div
        className={clsxm(
          'mx-auto flex max-w-5xl items-center px-2',
          router.pathname !== '/p/[postSlug]'
            ? 'justify-between'
            : 'justify-end'
        )}
      >
        {router.pathname !== '/p/[postSlug]' && (
          <Button
            variant='naked'
            onClick={() => router.back()}
            icon={<ArrowLeft />}
          >
            {t('profile.notifications.actions.backToNotifications')}
          </Button>
        )}
        <div className='flex items-center space-x-1'>
          {showMarkAllAsDone && (
            <Button
              variant='secondary'
              size='xs'
              onClick={() => handleReadAll()}
              loading={loading}
              disabled={loading}
            >
              {t('profile.notifications.actions.markAllAsRead')}
            </Button>
          )}
          {Boolean(notificationId?.length) && (
            <>
              {notification_type !== 'inbox' && (
                <Button
                  variant='secondary'
                  size='xs'
                  icon={<Tray />}
                  onClick={() => handleStatusUpdate('inbox')}
                >
                  {t('buttons.inbox')}
                </Button>
              )}
              {notification_type !== 'saved' && (
                <Button
                  variant='secondary'
                  size='xs'
                  icon={<FloppyDisk />}
                  onClick={() => handleStatusUpdate('saved')}
                >
                  {t('buttons.save')}
                </Button>
              )}
              {notification_type !== 'done' && (
                <Button
                  variant='secondary'
                  size='xs'
                  icon={<Check />}
                  onClick={() => handleStatusUpdate('done')}
                >
                  {t('buttons.done')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
