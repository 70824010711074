import { ArrowsClockwise } from '@phosphor-icons/react'
import React, { useContext, useEffect, useState } from 'react'

import Alert from '@/components/shared/ui/Alert'
import Button from '@/components/shared/ui/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ACTIONS, EventEmitter } from '@/lib/eventEmitter'
import { deleteLocalHashData } from '@/lib/localDB/localStorage'

export default function NewDeploymentAlert() {
  const { isAdmin } = useContext(HNContext)
  const t = useTranslations()
  const [show, setShow] = useState<boolean>(false)
  const [newDeployMessage, setNewDeployMessage] = useState<string | null>(null)

  const handleRefresh = () => {
    deleteLocalHashData()
    window.location.reload()
  }

  const handleNewDeployment = (eventData: any) => {
    if (eventData.action === ACTIONS.SHOW_NEW_DEPLOYMENT_ALERT) {
      setShow(eventData.show)
      setNewDeployMessage(eventData.data.message)
    }
  }

  useEffect(() => {
    EventEmitter.subscribe('CONFIG_PUSH', handleNewDeployment)
    return () => {
      EventEmitter.unsubscribe('CONFIG_PUSH', handleNewDeployment)
    }
  }, [])

  if (!show) return <></>
  return (
    <Alert
      type='warning'
      rounded
      className={isAdmin ? '' : 'mx-auto mt-1 max-w-5xl px-3'}
      message={newDeployMessage || t('common.newDeploymentMessage')}
      rightComponent={
        <Button
          size='xs'
          variant='primary'
          icon={<ArrowsClockwise weight='bold' />}
          onClick={handleRefresh}
        >
          {t('buttons.refresh')}
        </Button>
      }
    />
  )
}
