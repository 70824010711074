import { Binoculars, Warning } from '@phosphor-icons/react'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo } from 'react'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import AdminPostItemRow from '@/components/admin/posts/AdminPostItemRow'
import AvailableIntegrationModal from '@/components/admin/shared/common/AvailableIntegrationModal'
import NewPostButton from '@/components/posts/newPost/NewPostButton'
import UpgradeButton from '@/components/shared/components/module/billing/UpgradeButton'
import EmptyState from '@/components/shared/ui/EmptyState'
import Spinner from '@/components/shared/ui/Loader'
import { PAGINATION_LIMIT } from '@/config/appConstants'
import HNContext from '@/context/HNContext'
import { useSearchClient } from '@/context/HNSearchContext'
import { useDimension } from '@/hooks/useDimension'
import { useTranslations } from '@/hooks/useTranslations'
import type { IEntityUpdateEventData } from '@/lib/eventEmitter'
import { ENTITIES, EventEmitter } from '@/lib/eventEmitter'
import { getPostHighlightFlag } from '@/lib/helpers/appHelpers'
import {
  createItemData,
  objectHasProperty,
  removeKeyFromObject,
} from '@/lib/helpers/dataHelpers'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import {
  bulkUpdatePostCounts,
  checkAndFilterPost,
  postBulkUpdate,
  postCheck,
} from '@/lib/helpers/modules/postHelper'
import type { PostListStore } from '@/stores/PostListStore'
import postStore from '@/stores/PostListStore'
import type { IOrganizationPlan } from '@/types/organization'
import type {
  IBulkUpdateData,
  IPost,
  IPostItemModules,
  IPostListAPIParams,
} from '@/types/post'

import AdminPostBulkSelect from './AdminPostBulkSelect'

interface IPropTypes {
  globalFilters?: IPostListAPIParams
  count?: number
  isModeration?: boolean
  moderationAction?: 'approve' | 'moveToPending'
  newTabOpen?: boolean
  hideModules?: IPostItemModules
  incomingPostStore?: PostListStore
  isAiEnabled?: boolean
  canSelectAll?: boolean
}

const AdminPostList = observer((props: IPropTypes) => {
  const {
    globalFilters = {},
    count,
    isModeration = false,
    moderationAction,
    newTabOpen,
    hideModules = [],
    incomingPostStore,
    canSelectAll = true,
  } = props
  const {
    showPostDescription,
    pendingPostsCount = 0,
    organizationPlan,
    updateContext,
  } = useContext(HNContext) as {
    showPostDescription: boolean
    pendingPostsCount: number
    organizationPlan: IOrganizationPlan
    updateContext: (data: any) => void
  }
  const t = useTranslations()
  const router = useRouter()
  const postListStore = incomingPostStore || postStore
  const { posts, loading, canFetchMore, selection, filters, errorMessage } =
    postListStore
  const dimensions = useDimension(0)
  const showHighlight = getPostHighlightFlag()
  const { searchClient } = useSearchClient()

  const emptyMessage = useMemo(() => {
    const pageFilters = removeKeyFromObject(
      ['page', 'status', 'boardSlug', 'sort'],
      {
        ...filters,
      }
    )
    if (Object.keys(pageFilters).length)
      return t('posts.messages.noPostsFoundForFilter')
    if (isModeration) return t('posts.messages.noPostsFoundForModeration')
    return t('posts.messages.noPostsFound')
  }, [filters, isModeration])

  const handleSelectAll = (select: boolean) => {
    postListStore.selection = {
      selectedIds: [],
      ignoredIds: [],
      selectedAll: select,
    }
  }

  const handleCheck = (event: KeyboardEvent, postId: string) => {
    const newPosts = [...posts]
    if (event.shiftKey) {
      handleSelectAll(false)
    }
    postCheck(newPosts, selection, event, postId, postListStore)
  }

  const handleUpdate = (newPost: IPost) => {
    postListStore.updatePost(newPost.slug, newPost)
  }

  const handlePostsUpdate = (eventData: IEntityUpdateEventData): any => {
    const { actionType, entity, data } = eventData
    if (entity !== ENTITIES.POSTS) return
    const incomingPost = data.data
    if (!incomingPost?.id) return
    postListStore.updateSinglePostWithId(
      incomingPost.id,
      incomingPost,
      actionType
    )
  }

  const handleBulkUpdate = (data: IBulkUpdateData) => {
    if (
      Boolean(!objectHasProperty(data.bulkUpdateData, 'pinned')) &&
      Boolean(!objectHasProperty(data.bulkUpdateData, 'viewed'))
    ) {
      bulkUpdatePostCounts(
        data,
        isModeration,
        moderationAction,
        updateContext,
        pendingPostsCount,
        filters,
        postListStore,
        false // isSearch
      )
    }
    postListStore.posts = postBulkUpdate(posts, data, filters, globalFilters)
  }

  const handleBulkDelete = (data: IBulkUpdateData) => {
    const { feature_request_ids, blacklist_ids, select_all } =
      data.bulkUpdateData
    if (feature_request_ids && feature_request_ids.length) {
      postListStore.posts = posts.filter(
        (post) => !feature_request_ids.includes(post.id)
      )
    }
    if (blacklist_ids && blacklist_ids.length) {
      postListStore.posts = posts.filter((post) =>
        blacklist_ids.includes(post.id)
      )
    }
    if (select_all && !blacklist_ids.length && !feature_request_ids.length) {
      postListStore.posts = []
    }
    bulkUpdatePostCounts(
      data,
      isModeration,
      moderationAction,
      updateContext,
      pendingPostsCount,
      filters,
      postListStore,
      false // isSearch
    )
  }

  const handleEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleSelectAll(false)
    }
  }

  useEffect(() => {
    EventEmitter.subscribe('ENTITY_UPDATE', handlePostsUpdate)
    document.addEventListener('keydown', handleEscape)
    return () => {
      EventEmitter.unsubscribe('ENTITY_UPDATE', handlePostsUpdate)
      document.removeEventListener('keydown', handleEscape)
      postListStore.setGlobalFilters({})
    }
  }, [])

  useEffect(() => {
    if (
      JSON.stringify(globalFilters) !==
      JSON.stringify(postListStore.globalFilters)
    ) {
      handleSelectAll(false)
      postListStore.setSearchClient(searchClient)
      postListStore.setAdminFlag(true)
      postListStore.setGlobalFilters(globalFilters)
      postListStore.canFetchMore = false
      postListStore.updateFilters({
        ...router.query,
        page: 1,
      })
    }
  }, [globalFilters])

  const postList = postListStore.posts
    .filter((post) =>
      checkAndFilterPost(post, { ...filters, ...globalFilters })
    )
    .filter((post, index, self) => {
      try {
        const show =
          index === self.findIndex((pt: IPost) => +pt.id === +post.id)
        return show
      } catch {
        return false
      }
    })

  if (
    isModeration &&
    !featureIsEnabled('moderation', organizationPlan) &&
    !pendingPostsCount
  ) {
    return (
      <EmptyState
        description={t(
          'posts.moderation.postModerations.messages.planRestrictions',
          {
            data: {
              count: pendingPostsCount?.toString() || 0,
            },
          }
        )}
        actionComponent={<UpgradeButton />}
      />
    )
  }

  if (loading && !postList.length)
    return <Spinner isScreen={!postList?.length} />

  if (errorMessage) {
    return <EmptyState icon={Warning} title={errorMessage} />
  }

  if (!postList?.length) {
    return (
      <EmptyState
        title={emptyMessage}
        icon={Binoculars}
        isFullScreen
        actionComponent={
          <div className='flex flex-col items-center justify-center'>
            <NewPostButton
              boardId={
                globalFilters?.bucket_id ? globalFilters?.bucket_id[0] : null
              }
            />
            <AvailableIntegrationModal
              show={Boolean(
                router.query.boardSlug?.length &&
                  !router.query.singleBoardTabs?.includes('moderation')
              )}
            />
          </div>
        }
      />
    )
  }
  const itemData = createItemData({
    posts: postList,
    showHighlight,
    showPostDescription,
    onCheck: handleCheck,
    onUpdate: handleUpdate,
    isModeration,
    newTabOpen,
    hideModules,
    canFetchMore,
  })

  return (
    <>
      <InfiniteLoader
        isItemLoaded={(index: number) => {
          return !canFetchMore || index < postList.length
        }}
        itemCount={postList.length + 1}
        loadMoreItems={() => {
          if (postList.length >= 0 && canFetchMore && !loading) {
            postListStore.handlePageUpdate(1)
          }
        }}
        minimumBatchSize={50}
      >
        {({ onItemsRendered }: { onItemsRendered: any }) => (
          <FixedSizeList
            itemData={itemData}
            itemSize={showPostDescription ? 48 : 38}
            itemCount={postList.length + 1}
            height={dimensions.height - 30}
            width={'100%'}
            onItemsRendered={onItemsRendered}
            overscanCount={PAGINATION_LIMIT.adminPostsList}
          >
            {AdminPostItemRow}
          </FixedSizeList>
        )}
      </InfiniteLoader>

      <AdminPostBulkSelect
        selection={selection}
        onSelectAll={handleSelectAll}
        totalCount={count || posts?.length}
        onComplete={(data) => handleBulkUpdate(data)}
        isModeration={isModeration}
        moderationAction={moderationAction}
        onDelete={handleBulkDelete}
        showSelectAll={canSelectAll}
      />
    </>
  )
})

export default AdminPostList
