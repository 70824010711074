import { CheckCircle, WarningCircle, XCircle } from '@phosphor-icons/react'
import { toast } from 'sonner'

import ErrorString from '@/components/shared/common/ErrorString'
import type { IKeyValueMap } from '@/types/common'

export interface IToastData {
  message: string
  data?: IKeyValueMap
  payload?: IKeyValueMap
}

const toaster = {
  success: (data: IToastData) => {
    toast(
      <>
        <CheckCircle className='h-5 w-5 shrink-0 text-green8' weight='fill' />
        <ErrorString
          dataTestId='success_toast'
          message={data.message}
          data={data?.data || data.payload}
        />
      </>
    )
  },

  info: (data: IToastData) => {
    toast(
      <>
        <WarningCircle className='h-5 w-5 shrink-0 text-blue8' weight='fill' />
        <ErrorString
          dataTestId='info_toast'
          message={data.message}
          data={data?.data || data.payload}
        />
      </>
    )
  },

  error: (data: IToastData) => {
    toast(
      <>
        <XCircle className='h-5 w-5 shrink-0 text-red8' weight='fill' />
        <ErrorString
          dataTestId='error_toast'
          message={data.message}
          data={data?.data}
        />
      </>
    )
  },
}

export default toaster
