import * as Dialog from '@radix-ui/react-dialog'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import type { ISelectInternalRef } from '@/components/shared/ui/Select/SelectTypes'

// Import CVA variants
import {
  selectDialogContentVariants,
  selectDialogOverlayVariants,
} from './select.variants'

interface IPropTypes {
  children: React.ReactNode
  trigger: React.ReactNode
  disabled?: boolean
  onOpenChange: (open: boolean) => void
  dataTestId?: string
}
const SelectDialog = forwardRef(
  (
    { children, trigger, disabled, onOpenChange, dataTestId }: IPropTypes,
    ref: ForwardedRef<ISelectInternalRef>
  ) => {
    const [open, setOpen] = useState(false)

    useImperativeHandle(ref, () => ({
      toggle: () => setOpen(!open),
      close: () => setOpen(false),
      open: () => setOpen(true),
    }))

    useEffect(() => {
      onOpenChange(open)
    }, [open])

    return (
      <Dialog.Root open={open} onOpenChange={setOpen}>
        <Dialog.Trigger
          disabled={disabled}
          className='w-full appearance-none'
          data-testid={dataTestId}
        >
          {trigger}
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className={selectDialogOverlayVariants()} />
          <Dialog.Content
            className={selectDialogContentVariants()}
            onOpenAutoFocus={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            {children}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    )
  }
)

SelectDialog.displayName = 'SelectDialog'
export default SelectDialog
