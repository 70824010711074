import React, { useContext, useMemo } from 'react'

import {
  Progress,
  ProgressIndicator,
} from '@/components/shared/ui/ProgressBar/ProgressBar'
import HNContext from '@/context/HNContext'
import { friendlyNumber } from '@/lib/helpers/stringHelpers'

export default function AsideCreditInfo() {
  const {
    monthly_ai_search_credits_remaining = 0,
    allowed_monthly_ai_search_credits = 0,
    generation_credits_remaining = 0,
    allowed_generation_credits = 0,
    bootstraped,
  } = useContext(HNContext)

  const handleProgressbarColor = (used: number, total: number) => {
    if ((used / total) * 100 >= 80) return 'bg-gradient-to-r from-brand to-red8'
    return ''
  }

  const used_generation_credits =
    useMemo(() => {
      const used = allowed_generation_credits - generation_credits_remaining
      if (used < 0) return 0
      if (used > allowed_generation_credits) return allowed_generation_credits
      return used
    }, [generation_credits_remaining, allowed_generation_credits]) || 0

  const used_ai_search_credits =
    useMemo(() => {
      const used =
        allowed_monthly_ai_search_credits - monthly_ai_search_credits_remaining
      if (used < 0) return 0
      if (used > allowed_monthly_ai_search_credits)
        return allowed_monthly_ai_search_credits
      return used
    }, [
      monthly_ai_search_credits_remaining,
      allowed_monthly_ai_search_credits,
    ]) || 0

  if (!bootstraped) return null

  if (!allowed_generation_credits && !allowed_monthly_ai_search_credits)
    return null
  return (
    <div className='flex flex-col gap-2 rounded-lg border p-2 px-2 dark:border-gray5'>
      {!!allowed_generation_credits && (
        <div className='flex flex-col gap-1'>
          <div className='flex items-center justify-between'>
            <p className='text-sm text-gray11'>AI Credits</p>
            <p className='font-mono text-xxs text-gray10'>
              {friendlyNumber(used_generation_credits)}/
              {friendlyNumber(allowed_generation_credits)}
            </p>
          </div>

          <Progress
            value={(used_generation_credits / allowed_generation_credits) * 100}
            height={5}
          >
            <ProgressIndicator
              height={5}
              color={handleProgressbarColor(
                used_generation_credits,
                allowed_generation_credits
              )}
              value={
                (used_generation_credits / allowed_generation_credits) * 100
              }
            />
          </Progress>
        </div>
      )}

      {!!allowed_monthly_ai_search_credits && (
        <div className='flex flex-col gap-1'>
          <div className='flex items-center justify-between'>
            <p className='text-sm text-gray11'>AI Search Credits</p>
            <p className='font-mono text-xxs text-gray10'>
              {friendlyNumber(used_ai_search_credits)}/
              {friendlyNumber(allowed_monthly_ai_search_credits)}
            </p>
          </div>

          <Progress
            value={
              (used_ai_search_credits / allowed_monthly_ai_search_credits) * 100
            }
            height={5}
          >
            <ProgressIndicator
              height={5}
              color={handleProgressbarColor(
                used_ai_search_credits,
                allowed_monthly_ai_search_credits
              )}
              value={
                (used_ai_search_credits / allowed_monthly_ai_search_credits) *
                100
              }
            />
          </Progress>
        </div>
      )}
    </div>
  )
}
