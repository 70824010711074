import { Check, Rewind } from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import { useContext } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EventEmitter } from '@/lib/eventEmitter'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import postStore from '@/stores/PostListStore'
import type { IOrganizationPlan } from '@/types/organization'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  onClose?: () => void
}

export default function SinglePostModerationActions({ post }: IPropTypes) {
  const {
    organizationSetting,
    pendingPostsCount,
    updateContext,
    organizationPlan,
    isAdmin,
  } = useContext(HNContext)
  const t = useTranslations()
  const router = useRouter()
  const { pathname } = router
  const confirmationTranslations = t(
    'posts.moderation.postModerations.confirmations'
  )
  const isStatusApproved = Boolean(post.approval_status === 'approved')
  const isModerationEnabled = featureIsEnabled(
    'moderation',
    organizationPlan as IOrganizationPlan
  )

  const updateModerationTabCount = (type: string) => {
    EventEmitter.dispatch('ENTITY_UPDATE', {
      entity: ENTITIES.POST_COUNTS,
      type,
      fromTab:
        post.approval_status === 'pending'
          ? 'waiting_for_approval'
          : 'approved_posts',
      toTab:
        post.approval_status === 'pending'
          ? 'approved_posts'
          : 'waiting_for_approval',
    })
  }

  const handleApproval = (approvalStatus: string, noNotify: boolean) => {
    if (updateContext) {
      const newCount =
        post.approval_status === 'pending'
          ? +Number(pendingPostsCount) - 1
          : +Number(pendingPostsCount) + 1
      updateContext({
        pendingPostsCount: newCount,
      })
    }
    if (post.approval_status === 'pending') {
      postStore.counts.waiting_for_approval -= 1
      postStore.counts.approved_posts += 1
    } else {
      postStore.counts.waiting_for_approval += 1
      postStore.counts.approved_posts -= 1
    }
    toaster.success({
      message: t(`posts.moderation.postModerations.messages.${approvalStatus}`),
    })
    if (pathname === '/admin/b/[boardSlug]/[[...singleBoardTabs]]')
      updateModerationTabCount('update')

    return postStore
      .updatePost(
        post.slug,
        {
          approval_status: approvalStatus,
          do_not_notify: noNotify,
        },
        { ...post, approval_status: approvalStatus }
      )
      .catch((err) => {
        if (pendingPostsCount && updateContext) {
          updateContext({
            pendingPostsCount,
          })
        }
        postStore.updateSinglePost(post.slug, post)
        toaster.error({ message: err.message })
      })
  }

  if (!organizationSetting?.post_moderation) return <></>
  if (!post?.can_approve || (!isAdmin && post.approval_status === 'pending'))
    return <></>

  const renderApprovalButton = () => {
    const confirmText = isStatusApproved
      ? t('buttons.moveToPending')
      : t('buttons.approve')
    const title = isStatusApproved
      ? confirmationTranslations.pending.title
      : confirmationTranslations.approve.title
    const alertText = isStatusApproved
      ? confirmationTranslations.pending.alertText
      : confirmationTranslations.approve.alertText
    return (
      <>
        <AlertDialog
          confirmText={confirmText}
          title={title}
          description={alertText}
          onConfirm={(_value, noNotify) =>
            handleApproval(isStatusApproved ? 'pending' : 'approved', noNotify)
          }
          checkboxLabel={t('common.doNotNotify', { ignorePrefix: true })}
          showCheckbox
          waitForPromise={false}
        >
          <div>
            <Tooltip
              show={post.status.value === 'closed'}
              text={t(
                'posts.moderation.postModerations.messages.moderationDisabledText',
                {
                  ignorePrefix: true,
                }
              )}
            >
              <Button
                isResponsive
                icon={
                  isStatusApproved ? (
                    <Rewind
                      weight='fill'
                      className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
                    />
                  ) : (
                    <Check
                      weight='fill'
                      className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
                    />
                  )
                }
                className={'group !text-gray12/80 hover:text-gray12'}
                variant='outline'
                size='xs'
                disabled={post.status.value === 'closed'}
              >
                {confirmText}
              </Button>
            </Tooltip>
          </div>
        </AlertDialog>
      </>
    )
  }

  if (!isModerationEnabled && isStatusApproved) return <></>

  return <>{renderApprovalButton()}</>
}
