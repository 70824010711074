import { CACHE_HASH_PREFIX } from '@/config/appConstants'
import type { IRoadmapItemScoresMap } from '@/types/roadmap'

export const ROADMAP_LIST_CONFIG_DB_KEY = `${CACHE_HASH_PREFIX}ROADMAP_LIST_CONFIG`
export const VALUE_EFFORT_SCORES: IRoadmapItemScoresMap[] = [
  { name: 'value', min: undefined, max: undefined },
  { name: 'effort', min: undefined, max: undefined },
]

export const RICE_SCORES: IRoadmapItemScoresMap[] = [
  { name: 'reach', min: 0, max: undefined },
  { name: 'impact', min: 0.1, max: undefined },
  { name: 'confidence', min: 0, max: 100 },
  { name: 'effort_rice', min: 0, max: undefined },
]
