import { Minus, Plus } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useMemo, useState } from 'react'

interface CounterProps {
  min?: number
  max?: number
  increment?: number
  decrement?: number
  onCountChange: (count: number) => void
  defaultValue?: number
}

export default function Counter({
  min = 1,
  max = 20,
  increment = 1,
  decrement = 1,
  onCountChange,
  defaultValue = 1,
}: CounterProps) {
  const [count, setCount] = useState<number>(defaultValue)

  const isMaxValue = useMemo(() => Boolean(max === count), [count])

  const handleClickSubtract = () => {
    if (count > min) {
      setCount(count - decrement)
      onCountChange(count - decrement)
    }
  }
  const handleClickAdd = () => {
    if (count < max) {
      setCount(count + increment)
      onCountChange(count + increment)
    }
  }
  const handleClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = Number(e.target.value)
    if (inputValue > max) inputValue = max
    if (min !== 0) inputValue = 1
    setCount(inputValue)
    if (inputValue !== null) {
      onCountChange(inputValue)
    }
  }

  return (
    <div className='flex w-[90px] items-center justify-between gap-1 rounded-md border border-gray5/60 bg-gray3 p-0.5  '>
      <button
        type='button'
        className='flex items-center justify-center p-1 text-base font-medium text-brand'
        onClick={handleClickSubtract}
      >
        <Minus
          weight='bold'
          className='h-3 w-3'
          data-testid='counter-minus-btn'
        />
      </button>
      <input
        type='number'
        className='h-6 w-[50%] rounded border border-gray5 bg-snow px-1 text-center font-mono text-sm outline-none focus:ring-0'
        min={min}
        max={max}
        readOnly={false}
        value={count}
        onChange={handleClick}
      />
      <button
        type='button'
        className={clsx(
          'flex items-center justify-center p-1 text-base font-medium text-brand',
          isMaxValue ? 'cursor-not-allowed opacity-50' : ''
        )}
        disabled={isMaxValue}
        onClick={handleClickAdd}
      >
        <Plus
          weight='bold'
          className='h-3 w-3'
          data-testid='counter-plus-btn'
        />
      </button>
    </div>
  )
}
