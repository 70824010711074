import { Plus } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { cloneArray } from '@/lib/helpers/dataHelpers'
import { parseStatusSyncData } from '@/lib/helpers/modules/Integration/syncHelper'
import type { IGithubIntegrationConfig } from '@/types/integration/github'
import type {
  IIntegrationStatusSyncData,
  IStatusSyncRule,
} from '@/types/integration/statusSync'

import AdminIntegrationHellonextSyncRule from '../Shared/AdminIntegrationHellonextSyncRule'
import AdminIntegrationNewSyncRule from '../Shared/AdminIntegrationNewSyncRule'
import AdminIntegrationProviderSyncRule from '../Shared/AdminIntegrationProviderSyncRule'

interface IAdminIntegrationGithubSyncStatusProps {
  configData: IGithubIntegrationConfig
  onRemove: (newSyncRules: IStatusSyncRule[]) => Promise<void>
}
export default function AdminIntegrationGithubSyncStatus({
  configData,
  onRemove,
}: IAdminIntegrationGithubSyncStatusProps) {
  const t = useTranslations(`integrations.github.configure`)
  const [syncRules, setSyncRules] = useState(configData.sync_rules)
  const [showNewRule, setShowNewRule] = useState(false)
  const syncData = parseStatusSyncData(
    configData,
    'github'
  ) as IIntegrationStatusSyncData

  const handleNewSyncRule = (syncRule: IStatusSyncRule) => {
    setSyncRules([...syncRules, syncRule])
    setShowNewRule(false)
  }

  const handleRemoveSyncRule = (index: number) => {
    const newSyncRules = cloneArray(syncRules)
    newSyncRules.splice(index, 1)
    setSyncRules(newSyncRules)
    onRemove(newSyncRules)
    setShowNewRule(false)
  }
  const handleCloseSyncRule = () => {
    setShowNewRule(false)
  }

  useEffect(() => {
    setSyncRules(configData.sync_rules)
  }, [configData])

  return (
    <div className='mt-4 border-t border-gray5 pt-4 '>
      <div className='flex items-center justify-between space-x-2'>
        <div className='flex flex-col'>
          <Typography.Text className='mb-0 !font-medium !text-carbon '>
            {t(`syncRule.title`)}
          </Typography.Text>
          <Typography.Text className='mb-3 mt-1 !text-sm'>
            {t(`syncRule.description`)}
          </Typography.Text>
        </div>
        <span className='flex items-center'>
          <Button
            size='xs'
            icon={<Plus weight='bold' />}
            onClick={() => setShowNewRule(true)}
            variant='outline'
          >
            {t(`syncRule.add`)}
          </Button>
        </span>
      </div>
      <>
        {syncRules.map((syncRule: IStatusSyncRule, index: number) => {
          if (syncRule.source === 'hellonext') {
            return (
              <AdminIntegrationHellonextSyncRule
                key={index}
                provider='github'
                configData={syncData}
                syncRule={syncRule}
                edit={false}
                handleRemove={handleRemoveSyncRule}
                index={index}
                newRule={false}
                useCustomStates={configData.sync_by_custom_state}
              />
            )
          }

          if (syncRule.source === 'github') {
            return (
              <AdminIntegrationProviderSyncRule
                key={index}
                provider='github'
                configData={syncData}
                syncRule={syncRule}
                edit={true}
                handleRemove={handleRemoveSyncRule}
                index={index}
                newRule={false}
                useCustomStates={configData.sync_by_custom_state}
              />
            )
          }

          return <></>
        })}
      </>

      <div className='mt-2 flex'>
        <AdminIntegrationNewSyncRule
          showSyncRule={showNewRule}
          syncRule={configData.default_sync_rule}
          configData={syncData}
          provider='github'
          onAdd={handleNewSyncRule}
          onCancel={handleCloseSyncRule}
          useCustomStates={configData.sync_by_custom_state}
        />
      </div>
    </div>
  )
}
