import type { Node } from '@tiptap/pm/model'
import type { Transaction } from '@tiptap/pm/state'
import { Plugin } from '@tiptap/pm/state'
import type { EditorView } from '@tiptap/pm/view'
/**
 * function for image drag n drop(for tiptap)
 * @see https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521#gistcomment-3744392
 */
export type UploadFn = (image: File) => Promise<string>

const getImagePositions = (id: string, view: EditorView) => {
  const positions: Array<{ node: Node; pos: number }> = []
  view.state.doc.descendants((node: Node, pos: number) => {
    if (node.type.name === 'image' && node.attrs.uploadId === id) {
      positions.push({ node, pos })
    }
  })
  return positions
}

export const insertImageFromData = (
  view: EditorView
): Promise<string | null> => {
  return new Promise((resolve) => {
    const id = Math.random().toString(36).slice(2)
    const node = view.state.schema.nodes.image?.create({
      uploadId: id,
    })
    if (!node) {
      resolve(null)
    } else {
      const transaction = view.state.tr.replaceSelectionWith(node)
      view.dispatch(transaction)
      resolve(id)
    }
  })
}

export const insertImageAfterUpload = (
  image: File,
  view: EditorView,
  uploader: (image: File) => Promise<string>,
  insertedId: string
) => {
  if (uploader && image) {
    uploader(image).then((src) => {
      const nodes = getImagePositions(insertedId, view)
      const transaction: Transaction = view.state.tr.setMeta(
        'addToHistory',
        false
      )
      const imageNode = view.state.schema.nodes.image?.create({
        src,
      })
      if (!imageNode) return
      nodes.forEach(({ pos }: { pos: number }) => {
        transaction.replaceWith(pos, pos + 1, imageNode)
        view.dispatch(transaction)
      })
    })
  }
}

export const uploadImagePlugin = (upload: UploadFn) => {
  return new Plugin({
    props: {
      handleDOMEvents: {
        paste(view, event) {
          const items = Array.from(event.clipboardData?.items || []).filter(
            (item) => item.getAsFile()
          )
          items.forEach((item) => {
            const image = item.getAsFile()
            if (!image) return
            if (item.type.indexOf('image') === 0) {
              event.preventDefault()
              insertImageFromData(view).then((insertedId) => {
                if (!insertedId) return
                insertImageAfterUpload(image, view, upload, insertedId)
              })
            }
          })
          return false
        },
        drop(view, event) {
          const hasFiles = event.dataTransfer?.files?.length

          if (!hasFiles) {
            return false
          }

          const images = Array.from(event!.dataTransfer!.files).filter((file) =>
            /image/i.test(file.type)
          )

          if (images.length === 0) {
            return false
          }

          event.preventDefault()

          const { schema } = view.state

          images.forEach(async (image) => {
            const reader = new FileReader()

            if (upload) {
              insertImageFromData(view).then((insertedId) => {
                if (!insertedId) return
                insertImageAfterUpload(image, view, upload, insertedId)
              })
            } else {
              reader.onload = (readerEvent) => {
                const node = schema.nodes.image?.create({
                  src: readerEvent!.target?.result,
                })
                if (!node) return
                insertImageFromData(view)
              }
              reader.readAsDataURL(image)
            }
          })
          return false
        },
      },
    },
  })
}
