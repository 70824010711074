import { observer } from 'mobx-react'
import React, { useContext, useMemo, useState } from 'react'

import AuthenticatedView from '@/components/auth/shared/AuthenticatedView'
import DatePicker from '@/components/shared/ui/DatePicker'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  convertToUnixTime,
  formatDate,
  isSameDay,
} from '@/lib/helpers/dateHelpers'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost & { post_created_at?: string }
  isRoadmap?: boolean
  onUpdate?: (post: IPost) => void
  borderless?: boolean
  dateFormat?: string
}

const SinglePostETC = observer(
  ({
    post,
    isRoadmap = false,
    onUpdate,
    borderless = false,
    dateFormat = 'dd MMMM, yyyy',
  }: IPropTypes) => {
    const t = useTranslations('post')
    const { userSignedIn } = useContext(HNContext)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const status = useMemo(() => {
      return post?.status?.value
    }, [post?.status])

    const handleRemoveETC = (updatedPost: IPost | any) => {
      if (isSubmitting) return null
      setIsSubmitting(true)
      return postStore
        .removeETC(post.slug, updatedPost)
        .then((newPost) => {
          if (onUpdate) onUpdate(newPost)
          toaster.success({ message: t('messages.etc.remove') })
        })
        .catch((err) => toaster.error({ message: err.message }))
        .finally(() => setIsSubmitting(false))
    }

    const handleETCChange = (selectedDay: Date) => {
      let day = selectedDay
      if (
        post.created_at &&
        selectedDay &&
        isSameDay(selectedDay, new Date(post.created_at.timestamp))
      ) {
        day = new Date(post.created_at.timestamp)
      }
      const updatedPost = {
        ...post,
        etc_date: day?.toString() || null,
        etc_scheduled: !!day,
      }
      if (!day) return handleRemoveETC(updatedPost)

      setIsSubmitting(true)
      return postStore
        .updatePost(
          post.slug,
          {
            etc_date: convertToUnixTime(day),
          },
          updatedPost as any
        )
        .then((newPost) => {
          if (onUpdate) onUpdate(newPost)
          toaster.success({ message: t('messages.etc.success') })
        })
        .catch((err) => toaster.error({ message: err.message }))
        .finally(() => setIsSubmitting(false))
    }

    const etcDate = useMemo(() => {
      if (typeof post.etc_date === 'string') return new Date(post?.etc_date)
      if (post.etc_date?.timestamp) return new Date(post.etc_date?.timestamp)
      return null
    }, [post.etc_date])

    const renderETCEdit = () => {
      if (post?.can_edit_etc || isRoadmap) {
        return (
          <DatePicker
            mode='single'
            size='xs'
            placeholder={t('fields.etc.placeholder')}
            range={{
              from: etcDate || '',
              to: undefined,
            }}
            onDaySelect={(day: Date) => handleETCChange(day)}
            disableFromDate={
              new Date(
                post?.created_at?.timestamp || (post.post_created_at as string)
              )
            }
            numberOfMonths={1}
            showInPopOver
            borderless={borderless}
            dateFormat={dateFormat}
            clearable={Boolean(post?.etc_date?.timestamp || post?.etc_date)}
            disabled={isSubmitting}
          />
        )
      }
      if (etcDate) {
        return (
          <>
            <Typography.Text className='col-span-3 font-medium !text-gray12 dark:!text-gray-light'>
              {formatDate(etcDate, 'yyyy-MM-dd')}
            </Typography.Text>
          </>
        )
      }
      return (
        <p className='mb-0 text-[13px] font-medium text-gray12 transition dark:text-gray-light'>
          {t('fields.etc.notSetYet')}
        </p>
      )
    }

    if (
      (status === 'completed' && !isRoadmap) ||
      (!post.etc_date && !post.can_edit_etc && !isRoadmap)
    )
      return <></>
    return (
      <AuthenticatedView
        shouldShow={
          Boolean(post.can_show_etc && !post.merged && userSignedIn) ||
          isRoadmap
        }
      >
        {isRoadmap ? (
          renderETCEdit()
        ) : (
          <div className='grid grid-cols-5 items-center gap-4 px-4 empty:hidden'>
            <Typography.Text className='col-span-2 font-medium'>
              {t('fields.etc.label')}
            </Typography.Text>
            <span className='col-span-3'>{renderETCEdit()}</span>
          </div>
        )}
      </AuthenticatedView>
    )
  }
)
export default SinglePostETC
