import React from 'react'

import type { IEmbedType, IRootData } from '@/types/organization'
import type { IUserProfile } from '@/types/user'

export interface IHNContext extends IRootData {
  session?: string | null
  widgetKey?: string
  isWidget?: boolean
  isAdmin?: boolean
  updateContext?: (data: { [key in keyof IHNContext]?: any }) => void
  noHeader?: boolean
  embedType?: IEmbedType | null
  assignees?: IUserProfile[]
  country: string
  subdomain: string
  bootstraped?: boolean
}
const HNContext = React.createContext<Partial<IHNContext>>({})
export default HNContext
