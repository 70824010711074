import React, { useContext, useEffect, useMemo, useState } from 'react'

import AdminPostBoardFilter from '@/components/admin/posts/filters/AdminPostBoardFilter'
import AdminPostParentStatusFilter from '@/components/admin/posts/filters/AdminPostParentStatusFilter'
import AdminPostStatusFilter from '@/components/admin/posts/filters/AdminPostStatusFilter'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Label from '@/components/shared/ui/Label'
import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import { THIRD_PARTY_APPS } from '@/config/appConstants'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  convertObjForSelect,
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import type {
  IClickUpConfig,
  IClickUpPushRule,
} from '@/types/integration/click_up'
import type {
  IIntegrationPushRuleKey,
  IIntegrationPushRuleValue,
} from '@/types/integration/github'

interface IAdminIntegrationClickUpPushRuleProps {
  onChange: (newRule: IClickUpPushRule) => void
  pushRule: IClickUpPushRule
  configData: IClickUpConfig
  isSubmitting: boolean
  onSave: () => void
  useCustomStates?: boolean
}

export default function AdminIntegrationClickUpPushRule({
  onChange,
  pushRule,
  configData,
  isSubmitting,
  onSave,
  useCustomStates,
}: IAdminIntegrationClickUpPushRuleProps) {
  const t = useTranslations(`integrations.clickUp`)
  const { organizationSetting, customStatus, custom_states } =
    useContext(HNContext)
  const [autoPushRule, setAutoPushRule] = useState<IClickUpPushRule>(pushRule)
  const [autoPush, setAutoPush] = useState(pushRule.enabled)
  const [workspaces, setWorkspaces] = useState<ISelectOption[]>([])
  const [spaces, setSpaces] = useState<ISelectOption[]>([])
  const [lists, setLists] = useState<ISelectOption[]>([])
  const [folders, setFolders] = useState<ISelectOption[]>([])

  const selectedStatus = useMemo(() => {
    if (!customStatus) return ''
    if (useCustomStates) {
      return custom_states?.find((state) => state.slug === autoPushRule.status)
        ?.name
    }
    const status = Object.keys(customStatus).find((key) => {
      return key === autoPushRule.status
    })

    if (status) return customStatus[status]
    return ''
  }, [customStatus, autoPushRule.status])

  const handleDependencyChange = (
    key: IIntegrationPushRuleKey,
    value: IIntegrationPushRuleValue
  ) => {
    if (key === 'space_id') {
      const newRule = {
        ...autoPushRule,
        [key]: value,
        list_id: null,
        folder_id: null,
      } as IClickUpPushRule
      setAutoPushRule(newRule)
      onChange(newRule)
      const filteredLists = configData.lists.filter(
        (list) => list.space_id === value && list.folder_id === null
      )
      setLists(convertObjForSelect(filteredLists, 'name', 'id'))

      const filteredFolders = configData.folders.filter(
        (folder) => folder.space_id === value
      )
      setFolders(convertObjForSelect(filteredFolders, 'name', 'id'))
    } else if (key === 'folder_id') {
      const newRule = {
        ...autoPushRule,
        [key]: value,
        list_id: null,
      } as IClickUpPushRule
      setAutoPushRule(newRule)
      onChange(newRule)
      const filteredLists = configData.lists.filter(
        (list) => list.folder_id === value
      )
      setLists(convertObjForSelect(filteredLists, 'name', 'id'))
    } else {
      const newRule = {
        ...autoPushRule,
        [key]: value,
      } as IClickUpPushRule
      setAutoPushRule(newRule)
      onChange(newRule)
    }
  }

  const handleSelectChange = (
    data: ISelectOption[],
    key: IIntegrationPushRuleKey,
    value: ISelectOptionGroup
  ) => {
    const selectedValue = getSelectedValueByKey(data, value)

    if (selectedValue && Array.isArray(selectedValue)) {
      handleDependencyChange(key, selectedValue[0]?.value)
    } else {
      handleDependencyChange(key, selectedValue?.value)
    }
  }

  useEffect(() => {
    setAutoPushRule(pushRule)
    setAutoPush(pushRule.enabled)
  }, [pushRule])

  useEffect(() => {
    setWorkspaces(convertObjForSelect(configData.workspaces, `name`, `id`))
    setSpaces(convertObjForSelect(configData.spaces, `name`, `id`))

    const filteredLists = configData.lists.filter(
      (list) =>
        list.space_id === autoPushRule.space_id &&
        (list.folder_id === null || list.folder_id === autoPushRule.folder_id)
    )
    setLists(convertObjForSelect(filteredLists, `name`, `id`))
    const filteredFolders = configData.folders.filter(
      (folder) => folder.space_id === autoPushRule.space_id
    )
    setFolders(convertObjForSelect(filteredFolders, `name`, `id`))
  }, [autoPushRule])

  if (!autoPushRule) return null

  return (
    <div className='mt-4 border-t border-gray5 pt-4 '>
      <div className='flex items-center justify-between'>
        <Label htmlfor={t(`configure.pushRule.title`)}>
          {t(`configure.pushRule.title`)}
        </Label>
        <Checkbox
          isSwitch
          id={t(`configure.pushRule.title`)}
          checked={autoPush}
          onCheckedChange={(checked) => {
            setAutoPush(checked)
            handleDependencyChange('enabled', checked)
          }}
        />
      </div>
      {autoPush && (
        <div className='grid grid-cols-1 gap-4 pb-2 pt-5 md:grid-cols-2'>
          <div className='space-y-1'>
            <Label>{t(`configure.pushRule.when`)}</Label>
            {useCustomStates ? (
              <AdminPostStatusFilter
                behaviour='select'
                filters={{ state: autoPushRule.status }}
                multiple={false}
                clearable
                placeholder={t(`configure.pushRule.statusPlaceholder`)}
                onChange={(_key, value) =>
                  handleDependencyChange('status', value[0])
                }
              />
            ) : (
              <AdminPostParentStatusFilter
                filters={{ status: autoPushRule.status }}
                multiple={false}
                clearable
                placeholder={t(`configure.pushRule.statusPlaceholder`)}
                onChange={(_key, value) =>
                  handleDependencyChange('status', value[0])
                }
              />
            )}
          </div>
          <div className='space-y-1'>
            <Label>
              {t(`configure.pushRule.in`, {
                data: {
                  boards: organizationSetting?.bucket_plural_name.toLowerCase(),
                },
              })}
            </Label>
            <AdminPostBoardFilter
              filters={{ bucket_id: autoPushRule.buckets }}
              multiple
              clearable
              placeholder={t(`configure.pushRule.boardsPlaceholder`, {
                data: {
                  boards: organizationSetting?.bucket_plural_name.toLowerCase(),
                },
              })}
              onChange={(_key, value) =>
                handleDependencyChange('buckets', value)
              }
            />
          </div>
          <div className='space-y-1'>
            <Label>{t(`configure.pushRule.workspaces.label`)}</Label>
            <Select
              options={workspaces}
              onChange={(value) =>
                handleSelectChange(workspaces, 'workspace_id', value)
              }
              value={setSelectedValue(
                workspaces,
                autoPushRule.workspace_id || ''
              )}
              placeholder={t(`configure.pushRule.workspaces.placeholder`)}
            />
          </div>
          <div className='space-y-1'>
            <Label>{t(`configure.pushRule.spaces.label`)}</Label>
            <Select
              options={spaces}
              onChange={(value) =>
                handleSelectChange(spaces, 'space_id', value)
              }
              value={setSelectedValue(spaces, autoPushRule.space_id || '')}
              placeholder={t(`configure.pushRule.spaces.placeholder`)}
            />
          </div>
          <div className='space-y-1'>
            <Label>{t(`configure.pushRule.folders.label`)}</Label>
            <Select
              options={folders}
              onChange={(value) =>
                handleSelectChange(folders, 'folder_id', value)
              }
              value={setSelectedValue(folders, autoPushRule.folder_id || '')}
              placeholder={t(`configure.pushRule.folders.placeholder`)}
            />
          </div>
          <div className='space-y-1'>
            <Label>{t(`configure.pushRule.lists.label`)}</Label>
            <Select
              options={lists}
              onChange={(value) => handleSelectChange(lists, 'list_id', value)}
              value={setSelectedValue(lists, autoPushRule.list_id || '')}
              placeholder={t(`configure.pushRule.lists.placeholder`)}
            />
          </div>
          <div className='space-y-4 md:col-span-2'>
            <Checkbox
              label={t(`integrations.pushRule.pushOnCreate.label`, {
                ignorePrefix: true,
                data: {
                  status: selectedStatus,
                  productName: THIRD_PARTY_APPS.clickup.name,
                },
              })}
              checked={autoPushRule.enable_during_creation}
              onCheckedChange={(value) =>
                handleDependencyChange('enable_during_creation', value)
              }
            />
            <Checkbox
              label={t(`integrations.pushRule.pushOnApprove.label`, {
                ignorePrefix: true,
                data: {
                  status: selectedStatus,
                  productName: THIRD_PARTY_APPS.clickup.name,
                },
              })}
              checked={autoPushRule.enable_during_approval}
              onCheckedChange={(value) =>
                handleDependencyChange('enable_during_approval', value)
              }
            />
          </div>
        </div>
      )}
      <div className='mt-3 flex items-center justify-end'>
        <Button
          size='xs'
          loading={isSubmitting}
          disabled={
            isSubmitting ||
            JSON.stringify(autoPushRule) === JSON.stringify(configData)
          }
          onClick={onSave}
        >
          {t(`buttons.save`, {
            ignorePrefix: true,
          })}
        </Button>
      </div>
    </div>
  )
}
