import React, { useContext, useEffect, useState } from 'react'

import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Label from '@/components/shared/ui/Label'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { capitalize, templateStringReplace } from '@/lib/helpers/stringHelpers'
import { updateIntegrationConfig } from '@/models/Integration'
import type { IBoardDetailsWithId } from '@/types/board'
import type { IDiscordIntegrationConfig } from '@/types/integration/discord'
import type { IOrganizationSetting } from '@/types/organization'
import toaster from '@/utils/toast'

interface IAdminIntegrationDiscordBoardsProps {
  configData: IDiscordIntegrationConfig
  onChange: (data: IDiscordIntegrationConfig) => void
}
export default function AdminIntegrationDiscordBoards({
  configData,
  onChange,
}: IAdminIntegrationDiscordBoardsProps) {
  const t = useTranslations()
  const { organizationSetting, buckets } = useContext(HNContext) as {
    organizationSetting: IOrganizationSetting
    buckets: IBoardDetailsWithId[]
  }
  const translationMapping = `integrations.discord.configure.boards`
  const publicBoards = buckets.filter((b) => !b.private)
  const privateBoards = buckets.filter((b) => b.private)
  const [notificationRule, setNotificationRule] = useState(
    configData.notifications_rules
  )
  const [isUpdating, setIsUpdating] = useState(false)

  const handleUpdateBoards = (boardId: number, checked: boolean) => {
    let data
    if (checked) {
      const boardIds = notificationRule.excluded_buckets.filter(
        (b) => b !== boardId
      )
      data = {
        notification_rules: {
          ...configData.notifications_rules,
          excluded_buckets: boardIds,
        },
      }
    } else {
      const boardIds = notificationRule?.excluded_buckets?.concat(boardId)
      data = {
        notification_rules: {
          ...configData.notifications_rules,
          excluded_buckets: boardIds,
        },
      }
    }
    setIsUpdating(true)
    return updateIntegrationConfig('discord', configData.id.toString(), data)
      .then((config: IDiscordIntegrationConfig) => {
        setNotificationRule(config.notifications_rules)
        onChange(config)
        toaster.success({
          message: capitalize(
            t(`integrations.discord.messages.successfullySaved`, {
              ignorePrefix: true,
            }).toLowerCase()
          ),
        })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsUpdating(false))
  }

  useEffect(() => {
    setNotificationRule(configData.notifications_rules)
  }, [configData])

  return (
    <>
      <div className='flex flex-col'>
        <Typography.Text className='mb-0.5 mt-2 !font-medium !text-gray11 '>
          {organizationSetting.bucket_plural_name}
        </Typography.Text>
        <Typography.Text className='mb-2 text-gray8'>
          {templateStringReplace(t(`${translationMapping}.description`), {
            buckets: organizationSetting.bucket_plural_name,
          })}
        </Typography.Text>
      </div>
      <Typography.Text className='mb-0.5 mt-5 !font-medium !text-gray11 '>
        {templateStringReplace(t(`common.modules.boards.public.title`), {
          boards: organizationSetting.bucket_plural_name,
        })}
      </Typography.Text>
      <ul className='my-2 flex flex-col'>
        {publicBoards.map((bucket) => (
          <li
            className='my-0.5 flex items-center justify-between rounded bg-gray4 p-2'
            key={bucket.id}
          >
            <Label className='truncate' htmlfor={bucket.display_name}>
              {bucket.display_name}
            </Label>
            <Checkbox
              id={bucket.display_name}
              isSwitch
              disabled={isUpdating}
              checked={
                !notificationRule.excluded_buckets?.includes(Number(bucket.id))
              }
              onCheckedChange={(checked) => {
                handleUpdateBoards(Number(bucket.id), checked)
              }}
            />
          </li>
        ))}
      </ul>
      {Boolean(privateBoards?.length) && (
        <>
          <Typography.Text className='mb-0.5 mt-5 !font-medium !text-gray11 '>
            {templateStringReplace(t(`common.modules.boards.private.title`), {
              boards: organizationSetting.bucket_plural_name,
            })}
          </Typography.Text>
          <ul className='my-2 flex flex-col'>
            {privateBoards.map((bucket) => (
              <div
                className='my-0.5 flex items-center justify-between rounded bg-gray4 p-2'
                key={bucket.id}
              >
                <Label className='truncate' htmlfor={bucket.display_name}>
                  {bucket.display_name}
                </Label>
                <Checkbox
                  id={bucket.display_name}
                  isSwitch
                  disabled={isUpdating || !configData.message_channel_id}
                  checked={
                    !notificationRule.excluded_buckets?.includes(
                      Number(bucket.id)
                    )
                  }
                  onCheckedChange={(checked) => {
                    handleUpdateBoards(Number(bucket.id), checked)
                  }}
                />
              </div>
            ))}
          </ul>
        </>
      )}
    </>
  )
}
