import { createContext, useContext } from 'react'

import type { IInteractorRecord } from '@/types/user'

interface IHNInteractorContext extends IInteractorRecord {
  updateContext?: (data: { [key in keyof IHNInteractorContext]?: any }) => void
  close: () => void
}
export const HNInteractorContext = createContext<Partial<IHNInteractorContext>>(
  {}
)

export const useHNUserContext = () => useContext(HNInteractorContext)
