import { X } from '@phosphor-icons/react'
import { GitMergeIcon } from '@primer/octicons-react'
import type { ForwardedRef } from 'react'
import React, { forwardRef, useImperativeHandle, useMemo } from 'react'

import MergePostItem from '@/components/posts/singlePost/mergepost/MergePostItem'
import PostMergeSuggestions from '@/components/posts/singlePost/mergepost/PostMergeSuggestions'
import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import LineHeader from '@/components/shared/ui/LineHeader'
import Tooltip from '@/components/shared/ui/Tooltip'
import { useTranslations } from '@/hooks/useTranslations'
import { bulkMergePosts } from '@/models/Post'
import postStore, { roadmapStores } from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IBulkMergeDialogProps {
  onComplete: (post: IPost) => void
  incomingPost?: IPost
}

export interface IBulkMergeDialogRef {
  openWithPosts: (posts: IPost[], parentPost?: IPost) => void
}
const BulkMergeDialog = forwardRef(
  (
    { onComplete, incomingPost }: IBulkMergeDialogProps,
    ref: ForwardedRef<IBulkMergeDialogRef>
  ) => {
    const t = useTranslations()

    const [merging, setMerging] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [selectedPosts, setSelectedPosts] = React.useState<IPost[]>(
      incomingPost ? [incomingPost] : []
    )
    const [parentPost, setParentPost] = React.useState<IPost | null>(
      incomingPost ?? null
    )

    useImperativeHandle(ref, () => ({
      openWithPosts: (_posts: IPost[], _parentPost?: IPost) => {
        setSelectedPosts(_posts)
        if (_parentPost) {
          setParentPost(_parentPost || null)
        } else {
          const possibleParent = _posts.sort(
            (a, b) => (b.votes_count_number || 0) - (a.votes_count_number || 0)
          )[0]
          if (possibleParent) setParentPost(possibleParent)
        }
        setOpen(true)
      },
    }))

    const childPosts = useMemo(() => {
      return selectedPosts.filter((post) => post.id !== parentPost?.id)
    }, [selectedPosts, parentPost])

    const { disabled, disabledMessage } = useMemo(() => {
      if (selectedPosts.length === 0) {
        return {
          disabled: true,
          disabledMessage: 'Select at least one post to merge',
        }
      }
      if (!parentPost) {
        return {
          disabled: true,
          disabledMessage: 'Select a parent post to merge into',
        }
      }
      return {
        disabled: false,
        disabledMessage: '',
      }
    }, [selectedPosts])

    const handleMerge = () => {
      const childIds = childPosts.map((post) => post.id)
      const slug = parentPost?.slug
      if (!slug || childIds.length === 0) return Promise.resolve()
      setMerging(true)
      return bulkMergePosts(slug, childIds)
        .then(() => {
          toaster.success({
            message: 'Posts are being merged.',
          })
          childPosts.map((post) => {
            postStore.removeSinglePost(post.slug)
            roadmapStores[post.status.value]?.deleteSinglePost(post.slug, post)
            return true
          })

          setOpen(false)
          onComplete(parentPost)
        })
        .catch(toaster.error)
        .finally(() => setMerging(false))
    }

    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent
          size='lg'
          className='flex max-h-[calc(100vh-20px)] min-h-[calc(100vh-20px)] !w-[70%] !max-w-[70%] flex-col !overflow-y-hidden !px-0 !py-0'
        >
          <div className='px-4 pt-4'>
            <DialogTitle>{t('post.mergePost.title')}</DialogTitle>
            <DialogDescription>
              {t('post.mergePost.description')}
            </DialogDescription>
            <DialogClose />
          </div>
          <div className='flex-1 overflow-y-auto px-4'>
            {parentPost && (
              <>
                <MergePostItem post={parentPost} />
                <LineHeader
                  className='py-3'
                  align='center'
                  title='Child Posts'
                />
              </>
            )}
            <div className='grid flex-1 grid-cols-1 divide-x divide-gray6 md:grid-cols-2'>
              <div className='flex h-auto flex-col gap-2 md:pr-5'>
                <PostMergeSuggestions
                  post={parentPost ?? undefined}
                  selectedPosts={selectedPosts}
                  onSelect={(post) => {
                    const isAlreadySelected = selectedPosts.some(
                      (p) => p.id === post.id
                    )
                    if (isAlreadySelected) {
                      toaster.error({
                        message: t('post.mergePost.alreadyAddedToList'),
                      })
                    } else {
                      setSelectedPosts([post, ...selectedPosts!])
                    }
                  }}
                />
              </div>
              <div className='flex h-auto flex-col gap-2 md:pl-5'>
                <p className='text-xs font-bold uppercase tracking-wider text-gray11'>
                  {`${t('post.mergePost.selectedTitle')} (${
                    childPosts.length
                  })`}
                </p>
                {!childPosts.length ? (
                  <EmptyState
                    title={t('post.mergePost.emptyList')}
                    className='items-start'
                    description={t('post.mergePost.emptyListDescription', {
                      data: {
                        count: 30,
                      },
                    })}
                    icon={GitMergeIcon}
                  />
                ) : (
                  childPosts.map((post) => (
                    <MergePostItem
                      key={post.id}
                      post={post}
                      actionComponent={
                        parentPost?.id !== post.id && (
                          <>
                            <Button
                              size='xxs'
                              className={'mr-1'}
                              variant='default'
                              onClick={() => {
                                const isPartOfSelectedPosts =
                                  selectedPosts.some((p) => p.id === post.id)
                                if (!isPartOfSelectedPosts) {
                                  setSelectedPosts((prev) => [...prev, post])
                                }
                                const parentPostIsSelected = selectedPosts.some(
                                  (p) => p.id === parentPost?.id
                                )
                                if (!parentPostIsSelected) {
                                  setSelectedPosts((prev) => [
                                    ...prev,
                                    parentPost!,
                                  ])
                                }
                                setParentPost(post)
                              }}
                            >
                              {t('post.mergePost.setParent')}
                            </Button>
                            <Tooltip text={t('buttons.remove')}>
                              <Button
                                icon={<X />}
                                fab
                                size='xs'
                                variant='danger'
                                onClick={() =>
                                  setSelectedPosts(
                                    selectedPosts.filter(
                                      (p) => p.id !== post.id
                                    )
                                  )
                                }
                              />
                            </Tooltip>
                          </>
                        )
                      }
                    />
                  ))
                )}
              </div>
            </div>
          </div>
          <div className='w-full self-end'>
            <div className='flex w-full justify-between gap-4 border-t border-gray5 bg-gray3 p-4'>
              <Button
                variant={'danger'}
                onClick={() => {
                  setSelectedPosts([])
                }}
              >
                {t('buttons.clear')}
              </Button>
              <div className='flex items-center gap-2'>
                <Button
                  variant={'outline'}
                  onClick={() => {
                    setOpen(false)
                  }}
                >
                  {t('buttons.cancel')}
                </Button>
                {disabled ? (
                  <Tooltip text={disabledMessage}>
                    <Button disabled={disabled}>
                      {t('post.mergePost.mergeButtonText')}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    disabled={merging || childPosts.length < 1}
                    onClick={handleMerge}
                  >
                    {merging
                      ? t('post.mergePost.merging')
                      : t('post.mergePost.mergeButtonText')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
)

BulkMergeDialog.displayName = 'BulkMergeDialog'

export default BulkMergeDialog
