import { Check } from '@phosphor-icons/react'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import Button from '@/components/shared/ui/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
} from '@/components/shared/ui/Dialog/Dialog'
import Input from '@/components/shared/ui/Input'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { stateKeyValueChange } from '@/lib/helpers/appHelpers'
import { forgotPassword } from '@/models/Auth'
import type { IForgotPasswordData } from '@/types/auth'

interface IPropTypes {
  showAsDialog?: boolean
}

export default function ForgetPasswordModal({ showAsDialog }: IPropTypes) {
  const t = useTranslations(`auth`)
  const [openModal, setOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [forgotPasswordData, setForgotPasswordData] =
    useState<IForgotPasswordData>({})
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)

  const submitForm = () => {
    setLoading(true)
    return forgotPassword(forgotPasswordData)
      .then(() => setComplete(true))
      .catch((err) => {
        setErrorMessage(err.message)
      })
      .finally(() => setLoading(false))
  }

  const renderForm = () => (
    <div className='space-y-2'>
      {showAsDialog && (
        <h1 data-testid='forgot_password_title'>{t(`forgotPassword.title`)}</h1>
      )}
      <Typography.Text className='text-sm text-gray8'>
        {t(`forgotPassword.description`)}
      </Typography.Text>
      <div className='space-y-2' id='forgotPassword'>
        {errorMessage && <p className='text-sm text-red8'>{errorMessage}</p>}
        <Input
          dataTestId='forgot_password_email_input'
          type='email'
          required
          placeholder={t(`forgotPassword.emailPlaceholder`)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              submitForm()
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            stateKeyValueChange('email', e.target.value, setForgotPasswordData)
          }
        />
        <div className='mb-3 mr-4 flex'>
          <ReCAPTCHA
            data-testid='forgot_password_captcha'
            id='auth-recaptcha'
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY || ''}
            onChange={(value: string | null) =>
              stateKeyValueChange('captcha', value, setForgotPasswordData)
            }
          />
        </div>
        <Button
          dataTestId='forgot_password_submit_button'
          className='float-right'
          loading={loading}
          type='button'
          onClick={submitForm}
          disabled={
            Number(forgotPasswordData.email?.length) <= 0 ||
            !forgotPasswordData.email?.length ||
            Boolean(errorMessage?.length)
          }
        >
          {t(`forgotPassword.buttonText`)}
        </Button>
      </div>
    </div>
  )

  const renderConfirmation = () => (
    <div data-testid='forgot_password_check_mail' className='space-y-2'>
      <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green4'>
        <Check weight='bold' className='h-6 w-6 text-green9' />
      </div>
      <div className='mb-5 mt-3 text-center sm:mt-5'>
        <h3 className='text-lg font-medium leading-6 text-gray12 '>
          {t(`forgotPassword.confirmation.title`)}
        </h3>
        <div className='mt-2'>
          <p className='text-sm text-gray8'>
            {t(`forgotPassword.confirmation.description`)}
          </p>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {showAsDialog ? (
        <>
          <Dialog
            open={openModal}
            modal={true}
            onClose={() => setOpenModal(false)}
          >
            <DialogContent backdrop>
              <DialogClose />
              {complete ? renderConfirmation() : renderForm()}
            </DialogContent>
          </Dialog>
          <Button
            dataTestId='forgot_password_button'
            size='xs'
            type='button'
            variant='naked'
            onClick={() => setOpenModal(true)}
          >
            {t(`forgotPassword.title`)}
          </Button>
        </>
      ) : (
        <>{complete ? renderConfirmation() : renderForm()}</>
      )}
    </>
  )
}
