import {
  ADMIN_TAG_PATH,
  ADMIN_TAGS_PATH,
  USER_TAGS_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import { buildURL } from '@/lib/helpers/urlHelpers'
import {
  getLocalCacheData,
  removeLocalCacheData,
  setLocalCacheData,
} from '@/lib/localDB/localStorage'
import type { ITagData } from '@/types/organization'
import type { ITagCreateAPIParams, ITagListParams } from '@/types/tag'

export const setLocalTags = (queryParams: ITagListParams, data: ITagData[]) => {
  const { isAdmin = false, ...rest } = queryParams

  const path = isAdmin ? ADMIN_TAGS_PATH : USER_TAGS_PATH
  const url = buildURL(path, rest)
  const payload = {
    // Cache to expire after 5 hours of non usage
    expireAt: new Date().getTime() + 1000 * 60 * 60 * 5,
    tags: data,
  }
  setLocalCacheData(url, payload, 'TAGS')
  return data
}

export const getLocalTags = (queryParams: ITagListParams) => {
  const { isAdmin = false, ...rest } = queryParams
  const path = isAdmin ? ADMIN_TAGS_PATH : USER_TAGS_PATH
  const url = buildURL(path, rest)
  const payload = getLocalCacheData(url, 'TAGS') as {
    tags: ITagData[]
    expireAt: number
  }
  if (
    !payload ||
    !payload.tags ||
    !payload.expireAt ||
    payload.expireAt < Date.now()
  ) {
    removeLocalCacheData(url, 'TAGS')
    return []
  }
  if (payload && payload.expireAt > Date.now()) {
    return Array.isArray(payload.tags) ? payload.tags : []
  }
  return []
}

export const clearLocalTags = (queryParams: ITagListParams) => {
  const { isAdmin = false, ...rest } = queryParams
  const path = isAdmin ? ADMIN_TAGS_PATH : USER_TAGS_PATH
  const url = buildURL(path, rest)
  removeLocalCacheData(url, 'TAGS')
}

export const tagList = (queryParams: ITagListParams) => {
  const { isAdmin = false, ...rest } = queryParams
  return API.get(isAdmin ? ADMIN_TAGS_PATH : USER_TAGS_PATH, rest).then(
    (tags) => {
      setLocalTags(queryParams, tags)
      return tags
    }
  )
}

export const addTag = (tag: ITagCreateAPIParams) =>
  API.post(ADMIN_TAGS_PATH, tag)
export const updateTag = (id: string, tag: ITagCreateAPIParams) =>
  API.put(ADMIN_TAG_PATH(id), tag)
export const deleteTag = (id: string, data: { source_type: string }) =>
  API.delete(ADMIN_TAG_PATH(id), data)
