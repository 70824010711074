import { Smiley } from '@phosphor-icons/react'
import React, { useContext, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownLabel,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import type { IReaction } from '@/config/module/commentConstant'
import { COMMENT_REACTIONS } from '@/config/module/commentConstant'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import { recordEvent } from '@/lib/helpers/appHelpers'
import { commentReaction } from '@/models/Comment'
import type { IComment } from '@/types/comment'
import type { IPost } from '@/types/post'

import CommentReactionItem from './CommentReactionItem'

interface IPropTypes {
  comment: IComment
  post: IPost
}
const CommentReactions = ({ comment, post }: IPropTypes) => {
  const { userProfile } = useContext(HNContext)
  const [loading, setLoading] = useState(false)
  const [reactionsList, setReactionsList] = useState(comment.reactions)

  const t = useTranslations('comments.reactions')

  const handleReaction = (reactionId: string) => {
    if (!comment.can_react && !userProfile) {
      return EventEmitter.dispatch('CONFIG_PUSH', {
        action: 'TOGGLE_AUTH_MODAL',
      })
    }
    try {
      const reaction = comment.reactions.find((r) => r.id === reactionId)
      recordEvent(
        reaction?.reacted
          ? 'COMMENT_REACTION_REMOVED'
          : 'COMMENT_REACTION_ADDED',
        {
          emoji: reaction?.emoji,
        }
      )
    } catch {
      console.error('Reaction Event Error')
    }
    setLoading(true)
    return commentReaction(post.slug, comment.id, {
      reaction_id: reactionId,
    })
      .then((updatedComment: IComment) => {
        if (comment.parent_id === updatedComment.id) {
          const currentChildComment = updatedComment.replies.find(
            (c) => c.id === comment.id
          )
          if (currentChildComment)
            setReactionsList(currentChildComment.reactions)
        } else {
          setReactionsList(updatedComment.reactions)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='flex items-center space-x-1'>
      {reactionsList.map((reaction) => (
        <div
          key={reaction.id}
          className='order-last ml-1 flex flex-wrap items-center gap-1 sm:space-x-1'
        >
          <CommentReactionItem
            reaction={reaction}
            onReaction={handleReaction}
          />
        </div>
      ))}
      {comment.can_react && (
        <Dropdown>
          <DropdownTrigger>
            <Button
              as='span'
              size='xs'
              fab
              variant='naked'
              loading={loading}
              icon={<Smiley className='h-4 w-4' />}
            />
          </DropdownTrigger>
          <DropdownContent align='start'>
            <DropdownLabel>{t('picker.title')}</DropdownLabel>
            <div className='m-2 grid grid-cols-3 gap-1'>
              {COMMENT_REACTIONS.map((reaction: IReaction) => (
                <div
                  key={reaction.id}
                  className='mx-[1px] flex items-center justify-center overflow-hidden rounded-[9px] border border-transparent hover:border-gray5 hover:bg-gray6 '
                >
                  <DropdownItem onSelect={() => handleReaction(reaction.id)}>
                    <span className='flex grow items-center justify-center p-1'>
                      {reaction.emoji}
                    </span>
                  </DropdownItem>
                </div>
              ))}
            </div>
          </DropdownContent>
        </Dropdown>
      )}
    </div>
  )
}

export default CommentReactions
