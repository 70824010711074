import {
  FAVORITE_FILTER_PATH,
  SAVED_FILTER_PATH,
  SINGLE_SAVED_FILTER_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import {
  getIdxDBAllData,
  removeIdxDBData,
  setAllIdxDBData,
  setIdxDBData,
} from '@/lib/localDB/localDB'
import type { INewSavedFilter, ISavedFilter } from '@/types/savedFilters'

// Saved Filters
export const createSavedFilter = (data: INewSavedFilter) =>
  API.post(SAVED_FILTER_PATH, data)

export const getSavedFilters = (): Promise<ISavedFilter[]> =>
  API.get(SAVED_FILTER_PATH).then((savedFilters: ISavedFilter[]) => {
    return setAllIdxDBData('SAVED_FILTERS', 'id', savedFilters, 'id')
  })

export const getSavedFiltersFromLocalDB = (): Promise<ISavedFilter[]> =>
  getIdxDBAllData('SAVED_FILTERS')

export const deleteSavedFilter = (id: string) =>
  API.delete(SINGLE_SAVED_FILTER_PATH(id)).then((data) =>
    removeIdxDBData('SAVED_FILTERS', id, 'id').then(() => data)
  )

export const getSingleSavedFilter = (id: string) =>
  API.get(SINGLE_SAVED_FILTER_PATH(id))

export const updateSavedFilter = (id: string, data: INewSavedFilter) =>
  API.put(SINGLE_SAVED_FILTER_PATH(id), data).then((savedFilter) =>
    setIdxDBData('SAVED_FILTERS', id, savedFilter, 'id')
  )

export const favoriteFilter = (id: string) =>
  API.post(FAVORITE_FILTER_PATH(id)).then((savedFilter) =>
    setIdxDBData('SAVED_FILTERS', id, savedFilter, 'id')
  )

export const unFavoriteFilter = (id: string) =>
  API.delete(FAVORITE_FILTER_PATH(id))
