import React, { useRef, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Spinner from '@/components/shared/ui/Loader'
import { useTranslations } from '@/hooks/useTranslations'
import { getClickUpTask } from '@/models/integration/clickUp'
import { linkPostAddon } from '@/models/Post'
import type { IClickUpSearchTask } from '@/types/integration/click_up'
import type { IPost } from '@/types/post'
import type { IPostAddon, IPostAddonPayload } from '@/types/post/postAddon'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  onLink: (data: IPostAddon) => void
}

export default function LinkClickUpTask({ post, onLink }: IPropTypes) {
  const t = useTranslations(`post.integrations.clickUp`)
  const timer = useRef<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [task, setTask] = useState<IClickUpSearchTask | null>()
  const [error, setError] = useState(null)
  const [linking, setLinking] = useState<boolean>(false)

  const handleSearch = (query: string) => {
    const trimmedQuery = query.replace('#', '')
    if (trimmedQuery.length) {
      setIsLoading(true)
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        return getClickUpTask(trimmedQuery)
          .then((data) => setTask(data))
          .catch((err) => {
            toaster.error({ message: err.message })
            setError(err.message)
            setTask(null)
          })
          .finally(() => setIsLoading(false))
      }, 500)
    } else {
      setTask(null)
    }
  }
  const handleSelect = (clickUpTask: IClickUpSearchTask) => {
    setLinking(true)
    const task_data = {
      reference_id: clickUpTask.id,
      provider: 'click_up',
    } as IPostAddonPayload
    linkPostAddon(post.slug, task_data)
      .then((data) => {
        toaster.success({ message: t(`postLinkedToClickUp`) })
        onLink(data)
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setLinking(false))
  }

  const renderTask = () => {
    if (isLoading) return <Spinner />

    if (task) {
      return (
        <div
          className='my-1 flex items-center justify-between space-x-1 rounded bg-gray4 px-3 py-2 '
          key={task.id}
        >
          <span className='truncate text-xs'>{task.title}</span>
          <Button
            disabled={linking}
            onClick={() => handleSelect(task)}
            size='xs'
          >
            {linking
              ? t(`buttons.linking`, {
                  ignorePrefix: true,
                })
              : t(`buttons.link`, {
                  ignorePrefix: true,
                })}
          </Button>
        </div>
      )
    }

    if (error) {
      return <p className='my-2 text-gray8'>{t(`link.taskEmpty`)}</p>
    }

    return null
  }

  return (
    <div className='mb-4 mt-3 space-y-4'>
      <div>
        <Input
          autoFocus
          placeholder={t(`link.searchPlaceHolder`)}
          onChange={(e) => handleSearch((e.target as HTMLInputElement).value)}
        />
      </div>
      {renderTask()}
    </div>
  )
}
