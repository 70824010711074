import React, { useMemo } from 'react'

import Skeleton from '@/components/shared/ui/Skeleton/Skeleton'

interface IPropTypes {
  count?: number
}
export default function PostCommentLoader({ count = 2 }: IPropTypes) {
  const mockPosts = useMemo(
    () => Array.from({ length: count }, (_, i) => i),
    [count]
  )

  return (
    <div className='flex flex-col space-y-3'>
      {mockPosts.map((p) => (
        <div
          key={p}
          className='flex space-x-3 rounded-lg bg-snow px-2 py-4 dark:bg-gray3 '
        >
          <Skeleton className='h-9 w-9 rounded-full' />
          <div className='w-full'>
            <Skeleton className='mb-2 h-4 w-32 rounded-lg' />
            <div className='flex flex-col gap-2'>
              <Skeleton className='h-4 w-full rounded-lg' />
              <Skeleton className='h-4 w-full rounded-lg' />
            </div>
            <div className='mt-2 flex flex-row items-center space-x-2'>
              <Skeleton className='h-4 w-20 rounded-lg' />
              <Skeleton className='h-4 w-4 rounded-lg' />
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
