import { X } from '@phosphor-icons/react'
import clsx from 'clsx'
import React from 'react'

import AdminPostParentStatusFilter from '@/components/admin/posts/filters/AdminPostParentStatusFilter'
import AdminPostStatusFilter from '@/components/admin/posts/filters/AdminPostStatusFilter'
import AdminIntegrationAsanaProjectsDropDown from '@/components/admin/settings/Integrations/config/Asana/AdminIntegrationAsanaProjectsDropDown'
import AdminIntegrationLinearTeamsDropDown from '@/components/admin/settings/Integrations/config/Linear/AdminIntegrationLinearTeamsDropDown'
import AdminIntegrationCustomFieldsDropDown from '@/components/admin/settings/Integrations/config/Shared/AdminIntegrationCustomFieldsDropDown'
import AdminIntegrationCustomFieldValueDropDown from '@/components/admin/settings/Integrations/config/Shared/AdminIntegrationCustomFieldValueDropDown'
import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'
import { isNullOrUndefined } from '@/lib/helpers/dataHelpers'
import {
  snakeToCamel,
  templateComponentReplace,
} from '@/lib/helpers/stringHelpers'
import type {
  IAsanaIntegrationStatusSyncRule,
  IIntegrationStatusSyncData,
  IStatusSyncRule,
} from '@/types/integration/statusSync'

import AdminIntegrationProviderStatusDropDown from './AdminIntegrationProviderStatusDropDown'
import AdminIntegrationSyncSourceDropdown from './AdminIntegrationSyncSourceDropdown'

interface IAdminIntegrationHellonextSyncRuleProps {
  edit: boolean
  provider: string
  configData: IIntegrationStatusSyncData
  newRule: boolean
  syncRule: IStatusSyncRule
  onChange?: any
  index?: number
  handleRemove?: (index: number) => void
  useCustomStates?: boolean
}

export default function AdminIntegrationHellonextSyncRule({
  edit,
  handleRemove,
  onChange,
  provider,
  configData,
  index,
  newRule,
  syncRule,
  useCustomStates,
}: IAdminIntegrationHellonextSyncRuleProps) {
  const t = useTranslations(`integrations.${snakeToCamel(provider)}`)

  const handleRemoveRule = () => {
    if (handleRemove && !isNullOrUndefined(index)) handleRemove(index as number)
  }

  const renderContent = () => {
    return templateComponentReplace(t(`configure.syncRule.hellonextRule`), {
      source: (
        <span className='mx-1'>
          <AdminIntegrationSyncSourceDropdown
            sourceData={configData.sources}
            provider={provider}
            syncRule={syncRule}
            edit={newRule}
            onChange={onChange}
          />
        </span>
      ),
      triggerStatus: useCustomStates ? (
        <span className='mx-1'>
          <AdminPostStatusFilter
            behaviour='select'
            filters={{ state: syncRule.trigger_status as string }}
            multiple={false}
            placeholder={t(`integrations.statusSync.status.placeholder`, {
              ignorePrefix: true,
            })}
            disabled={!newRule}
            onChange={(_key, value) => {
              onChange('trigger_status', value[0])
            }}
          />
        </span>
      ) : (
        <span className='mx-1'>
          <AdminPostParentStatusFilter
            filters={{ status: syncRule.trigger_status as string }}
            multiple={false}
            placeholder={t(`integrations.statusSync.status.placeholder`, {
              ignorePrefix: true,
            })}
            disabled={!newRule}
            onChange={(_key, value) => {
              onChange('trigger_status', value[0])
            }}
          />
        </span>
      ),
      target: t(`title`),
      status: (
        <span className='mx-1'>
          <AdminIntegrationProviderStatusDropDown
            edit={edit}
            syncRule={syncRule}
            provider={provider}
            statuses={configData.statuses}
            onChange={onChange}
            ruleKey={`status`}
          />
        </span>
      ),
      teams: (
        <span className='mx-1'>
          {configData.teams && (
            <AdminIntegrationLinearTeamsDropDown
              provider={provider}
              teams={configData.teams}
              syncRule={syncRule}
              edit={newRule}
              onChange={onChange}
              ruleKey='team_id'
            />
          )}
        </span>
      ),
      projects: (
        <span className='mx-1'>
          {configData.projects && (
            <AdminIntegrationAsanaProjectsDropDown
              provider={provider}
              projects={configData.projects}
              syncRule={syncRule}
              edit={newRule}
              onChange={onChange}
              ruleKey='project_id'
            />
          )}
        </span>
      ),
      customFields: (
        <span className='mx-1'>
          {configData.projects && (
            <AdminIntegrationCustomFieldsDropDown
              provider={provider}
              projects={configData.projects}
              syncRule={syncRule as IAsanaIntegrationStatusSyncRule}
              edit={newRule}
              onChange={onChange}
              ruleKey='custom_field_id'
            />
          )}
        </span>
      ),
      customFieldData: (
        <span className='mx-1'>
          {configData.projects && (
            <AdminIntegrationCustomFieldValueDropDown
              syncRule={syncRule as IAsanaIntegrationStatusSyncRule}
              provider={provider}
              edit={newRule}
              onChange={onChange}
              projects={configData.projects}
              ruleKey='custom_field_value'
            />
          )}
        </span>
      ),
    })
  }

  return (
    <div
      className={clsx(
        newRule ? '' : 'bg-gray4 px-3 py-2',
        'flex w-[100%] items-center justify-between space-x-2 rounded text-xs text-gray11'
      )}
    >
      <span className='flex flex-wrap items-center text-sm'>
        {renderContent()}
      </span>
      {Boolean(!newRule) && (
        <Button size='xxs' variant='naked' onClick={() => handleRemoveRule()}>
          <X weight='bold' className='h-4 w-4' />
        </Button>
      )}
    </div>
  )
}
