import Dexie from 'dexie'

// import { sentry } from './utils'
export type IModels =
  | 'COMMENTS_DRAFTS'
  | 'POSTS_DRAFTS'
  | 'POSTS'
  | 'CHANGELOG'
  | 'ASSIGNEES'
  | 'ROADMAPS'
  | 'ROADMAP_ITEMS'
  | 'POSTS_IDS'
  | 'SAVED_FILTERS'
  | 'POST_COMMENTS'
  | 'CHANGELOG_IDS'
  | 'POST_ACTIVITIES'
  | 'USER_SEGMENTS'
  | 'RESOURCE_COMMENTS'
  | 'CHAT_MESSAGES'

export const localDB = new Dexie('HELLONEXT')

localDB.version(15).stores({
  COMMENTS_DRAFTS: '&autoSaveKey',
  POSTS_DRAFTS: '++id, &autoSaveKey',
  POSTS: '++id, &slug',
  CHANGELOG: '++id, &slug',
  ASSIGNEES: '++id',
  ROADMAPS: '++id, &slug',
  ROADMAP_ITEMS: '++id, &slug',
  SAVED_FILTERS: '&id',
  POSTS_IDS: '&hash',
  POST_COMMENTS: '&slug',
  CHANGELOG_IDS: '&hash',
  POST_ACTIVITIES: '&slug',
  USER_SEGMENTS: '&id',
  RESOURCE_COMMENTS: '&slug',
  CHAT_MESSAGES: '&id',
})

export const getIdxDBAllData = (model: IModels): Promise<any> => {
  try {
    return localDB
      .table(model)
      .toArray()
      .catch(() => [])
  } catch (err) {
    return Promise.resolve(null)
  }
}

export const getIdxDBData = (
  model: IModels,
  key: string,
  keyField: string = 'slug'
): Promise<any> => {
  if (typeof window === 'undefined') return Promise.resolve(null)
  try {
    if (key) {
      const promise = localDB
        .table(model)
        ?.where(keyField)
        .equals(key)
        .first()
        .then((data) => data)
        .catch(() => null)
      return promise.catch(() => null)
    }
    return Promise.resolve(null)
  } catch (err) {
    return Promise.resolve(null)
  }
}

export const setIdxDBData = <T>(
  model: IModels,
  key: string,
  newData: T,
  keyField: string = 'slug'
): Promise<T> => {
  if (typeof window === 'undefined') return Promise.resolve(newData)
  try {
    return getIdxDBData(model, key, keyField)
      .then((oldData) => {
        const clonedData = oldData ? Dexie.deepClone(oldData) : {}
        const dataToWrite = JSON.parse(
          JSON.stringify({ ...clonedData, ...newData })
        )
        return localDB.table(model).put(dataToWrite)
      })
      .then(() => newData)
      .catch(() => newData)
  } catch (err) {
    return Promise.resolve(newData)
  }
}

export const setAllIdxDBData = <T>(
  model: IModels,
  key: string,
  newData: T[],
  keyField: string = 'slug'
): Promise<T[]> => {
  if (typeof window === 'undefined') return Promise.resolve(newData)
  try {
    const promises = newData.map((data) => {
      return setIdxDBData<T>(model, key, data, keyField)
    })
    return Promise.all(promises).then(() => newData)
  } catch (err) {
    return Promise.resolve(newData)
  }
}

export const removeAllIdxDBData = (model: IModels): Promise<any> => {
  if (typeof window === 'undefined') return Promise.resolve()
  try {
    return localDB.table(model).clear()
  } catch (err) {
    return Promise.resolve()
  }
}

export const removeIdxDBData = (
  model: IModels,
  key: string,
  keyField: string = 'slug'
) => {
  if (typeof window === 'undefined') return Promise.resolve()
  try {
    return localDB
      .table(model)
      ?.where(keyField)
      .equals(key)
      .delete()
      .then(() => null)
      .catch(() => null)
  } catch (err) {
    return Promise.resolve(null)
  }
}

export const clearIdxDBData = () => {
  try {
    return localDB.delete()
  } catch (err) {
    console.error(err)
    return Promise.resolve()
    // sentry.exception(err)
  }
}
