import { ArrowRight } from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useRef, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import type {
  ButtonProps,
  IButtonVariant,
} from '@/components/shared/ui/Button/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { recordEvent, sentry } from '@/lib/helpers/appHelpers'
import { loginWithPopup } from '@/lib/helpers/loginHelper'
import {
  checkAndUpdateContext,
  NON_REMOVABLE_POWER_UPS,
  QUANTITY_POWER_UPS,
} from '@/lib/helpers/powerUpsHelper'
import { snakeToCamel } from '@/lib/helpers/stringHelpers'
import { buildURL } from '@/lib/helpers/urlHelpers'
import { openContactChat } from '@/lib/utils'
import { purchasePowerUps, upgradePlan, verifyCheckout } from '@/models/Billing'
import type {
  IPlanInterval,
  IPlanName,
  IPowerUpInfo,
  IValidationStep,
  IVerifyCheckoutResponse,
} from '@/types/billing'
import type {
  IOrganizationCustomization,
  IOrganizationPlan,
} from '@/types/organization'
import toaster from '@/utils/toast'

const CONTACT_SUPPORT_TEXT = `Hey there, 

I've been trying to upgrade but I’m facing a friction. Can you help?`

interface IPropTypes {
  disabled: boolean
  loading: boolean
  plan?: IPlanName | null
  activePlan?: boolean
  powerUps: IPowerUpInfo[] | null
  subscriptionId?: string | null
  interval: IPlanInterval | undefined
  onClose: () => void
  onUpdate?: () => void
  migrate?: boolean
  variant?: IButtonVariant
  buttonProps?: ButtonProps
}

export const VALIDATION_FAILED = 'VALIDATION_FAILED'

export default function PowerUpPurchaseButton(props: IPropTypes) {
  const {
    disabled,
    loading,
    powerUps: _powerUps,
    plan,
    activePlan,
    subscriptionId,
    interval,
    onClose,
    onUpdate,
    migrate = false,
    variant = 'primary',
    buttonProps,
  } = props
  const t = useTranslations('')
  const {
    organizationPlan,
    updateContext,
    organizationCustomization,
    organization,
  } = useContext(HNContext)
  const router = useRouter()
  const [powerUps, setPowerUps] = useState<IPowerUpInfo[] | null>(_powerUps)
  const [isPurchasing, setIsPurchasing] = useState(false)
  const checkoutId = useRef<string | null>(null)
  const checkoutState = useRef<string | null>(null)
  const validationTrial = useRef<number>(0)
  const [validationStep, setValidationStep] = useState<IValidationStep>('none')

  const checkIsIntegrationsPage = () => {
    if (router.pathname.includes('/admin/settings/[[...settingsModule]]')) {
      EventEmitter.dispatch('ENTITY_UPDATE', {
        actionType: EVENT_ACTION_TYPES.UPDATE,
        entity: 'INTEGRATION_LIST',
      })
    }
  }

  const handleUpdatePurchasedPowerUps = () => {
    checkAndUpdateContext(
      powerUps as IPowerUpInfo[],
      updateContext,
      organizationPlan as IOrganizationPlan,
      organizationCustomization as IOrganizationCustomization
    )
    onClose()
    if (onUpdate) onUpdate()
    EventEmitter.dispatch('ENTITY_UPDATE', {
      entity: ENTITIES.POWER_UPS,
      actionType: EVENT_ACTION_TYPES.UPDATE,
      data: {
        data: powerUps,
      },
    })
    EventEmitter.dispatch('CONFIG_PUSH', {
      entity: ENTITIES.TOGGLE_SUCCESSFUL_PURCHASE_MODAL,
      data: { powerUp: powerUps?.[0] },
    })
    checkIsIntegrationsPage()
  }

  const checkAndUpdateValues = () => {
    if (migrate) {
      window.location.href = buildURL(window.location.href, {
        success_modal: 'true',
        migrate: 'true',
      })
      return true
    }
    if (plan || migrate) {
      window.location.href = buildURL(window.location.href, {
        success_modal: 'true',
      })
      return true
    }
    // Update purchased powerups after successfull purchase
    if (powerUps?.length) return handleUpdatePurchasedPowerUps()
    return false
  }

  const validatePurchase = (): Promise<IVerifyCheckoutResponse> => {
    if (!checkoutId.current || !checkoutState.current) {
      sentry.exception(new Error(`Error:Checkout ID not found `))
      recordEvent('PLAN_PURCHASE', {
        plan,
        interval,
        subscriptionId,
        powerUp: powerUps?.[0] || 'powerUp empty',
      })
      openContactChat(CONTACT_SUPPORT_TEXT)
      return Promise.reject(
        new Error(t('common.checkoutIdNotFound', { ignorePrefix: true }))
      )
    }
    setValidationStep('validating')
    if (validationTrial.current <= 3) {
      return verifyCheckout({
        checkout: checkoutId.current,
        state: checkoutState.current,
      })
        .then(() => {
          // checkAndUpdateValues()
          return Promise.resolve<IVerifyCheckoutResponse>({
            state: `processed`,
          })
        })
        .catch((err) => {
          if (
            err.message === 'ERROR_CODE_SUBSCRIPTION_RESOURCE_NOT_FOUND' ||
            err.message === 'ERROR_CODE_PAYMENT_PROCESSING'
          ) {
            validationTrial.current += 1
            setTimeout(() => validatePurchase(), 2000)
          }
          toaster.error({ message: err.message })
          setValidationStep('failed')
          return Promise.reject()
        })
    }
    setValidationStep('failed')
    return Promise.reject(new Error(`${VALIDATION_FAILED}`))
  }

  const handleLoginWithPopup = (url: string) => {
    return loginWithPopup({ url })
      .then((response) => {
        const { checkout, state, error, plan: _planName }: any = response
        if (error) {
          sentry.exception(new Error(error))
          throw new Error(
            t(`errors.${error}`, {
              ignorePrefix: true,
              data: {
                planName: _planName?.length
                  ? t(`billing.plans.${snakeToCamel(_planName)}.name`, {
                      ignorePrefix: true,
                    })
                  : '',
              },
            })
          )
        }
        if (!checkout && !error?.length) {
          sentry.exception(new Error(`Error:Checkout ID not found `))
          openContactChat(CONTACT_SUPPORT_TEXT)
          recordEvent('PLAN_PURCHASE', {
            plan,
            interval,
            subscriptionId,
            powerUp: powerUps?.[0] || 'powerUp empty',
          })
          throw new Error(
            t('common.checkoutIdNotFound', { ignorePrefix: true })
          )
        } else {
          checkoutId.current = checkout
          checkoutState.current = state
          return validatePurchase()
        }
      })
      .then(() => {
        checkAndUpdateValues()
      })
      .then(() => setIsPurchasing(true))
      .catch((err) => {
        const errorMessage =
          typeof err === 'string'
            ? err
            : err?.message || 'ERROR_CODE_ACTIVE_PLAN_ALREADY_PRESENT'
        sentry.log('Purchase Data', 'Login popup', 'debug', {
          plan,
          powerUps,
          interval,
          subscriptionId,
          error: errorMessage,
        })

        sentry.exception(
          new Error(`Error: while purchasing plan or powerUp ${errorMessage}`)
        )
        setIsPurchasing(false)
        toaster.error({
          message: err.message,
          data: {
            planName: t(
              `billing.plans.${snakeToCamel(
                organizationPlan?.plan || 'freeway'
              )}.name`,
              {
                ignorePrefix: true,
              }
            ),
          },
        })
      })
      .finally(() => {
        setValidationStep('none')
      })
  }

  // TODO: It will be use for future purpose
  // const handleCreateSubscription = () => {
  //   return createSubscription(
  //     {
  //       plan: plan || 'freeway',
  //       interval: interval || 'monthly',
  //       power_ups: powerUps || [],
  //     },
  //     'paddle'
  //   )
  //     .then(({ checkout_url }) => {
  //       return handleLoginWithPopup(checkout_url)
  //     })
  //     .catch((err) => {
  //       toaster.error({ message: err.message })
  //       setIsPurchasing(false)
  //     })
  // }

  const handleUpgradeSubscription = () => {
    if (!plan || !subscriptionId) return null
    setIsPurchasing(true)
    return upgradePlan(
      {
        plan: plan || '',
        interval: interval || 'monthly',
      },
      'paddle',
      subscriptionId
    )
      .then(() => {
        window.location.href = buildURL(window.location.href, {
          success_modal: true,
        })
      })
      .catch((err) => {
        sentry.log('Purchase Data', 'Plan Upgrade', 'debug', {
          plan,
          powerUps,
          interval,
          subscriptionId,
          error: err.message,
        })
        sentry.exception(new Error(`Error while updating plan ${err.message}`))
        toaster.error({ message: err.message })
        setIsPurchasing(false)
      })
  }

  const handlePurchase = () => {
    if (!powerUps?.length) return null
    setIsPurchasing(true)
    const powerUpsData = powerUps?.map((power) => ({
      slug: power.slug,
      quantity: power.quantity,
      source: power.source,
    }))
    return purchasePowerUps({
      power_ups: powerUpsData,
      redirect_url: window.location.href,
    })
      .then(({ checkout_url }) => {
        const powerUp = powerUps?.[0]
        if (checkout_url?.length && powerUp) {
          // if (
          //   organizationPlan?.power_ups[powerUp?.slug]?.purchased &&
          //   Number(organizationPlan?.power_ups[powerUp?.slug]?.purchased) > 0 &&
          //   !ONE_TIME_POWER_UPS.includes(powerUp?.slug as keyof IPowerUpInfo)
          // ) {
          //   return handleUpdatePurchasedPowerUps()
          // }
          return handleLoginWithPopup(checkout_url)
        }
        return handleUpdatePurchasedPowerUps()
      })
      .then(() => setIsPurchasing(false))
      .catch((err) => {
        setIsPurchasing(false)
        sentry.log('Purchase Data', 'Purchase Plan', 'debug', {
          plan,
          powerUps,
          interval,
          subscriptionId,
          error: err.message,
        })
        sentry.exception(
          new Error(`Error while purchasing power ups: ${err?.message}`)
        )
        toaster.error({ message: err.message })
      })
  }

  const getCheckoutURL = () => {
    const powerUp = powerUps?.[0]
    if (migrate) {
      return buildURL(`${organization?.home_page}purchase/checkout`, {
        migrate,
        redirect_url: `${organization?.home_page}purchase/validate`,
      })
    }
    if (plan && !subscriptionId) {
      return buildURL(`${organization?.home_page}purchase/checkout`, {
        plan,
        interval,
        redirect_url: `${organization?.home_page}purchase/validate`,
      })
    }
    return buildURL(`${organization?.home_page}purchase/checkout`, {
      slug: powerUp?.slug,
      interval: powerUp?.interval,
      quantity: powerUp?.quantity,
      redirect_url: `${organization?.home_page}purchase/validate`,
    })
  }

  const handleCheckout = () => {
    if (Boolean(plan && !subscriptionId) || migrate) {
      handleLoginWithPopup(getCheckoutURL())
      return true
    }
    if (
      powerUps?.length &&
      (Boolean(
        QUANTITY_POWER_UPS.includes(
          powerUps?.[0]?.slug as keyof IPowerUpInfo
        ) && Number(powerUps?.[0]?.purchased) === 0
      ) ||
        Boolean(
          !QUANTITY_POWER_UPS.includes(
            powerUps?.[0]?.slug as keyof IPowerUpInfo
          ) && !powerUps?.[0]?.included
        ) ||
        Boolean(
          NON_REMOVABLE_POWER_UPS.includes(
            powerUps?.[0]?.slug as keyof IPowerUpInfo
          )
        ))
    ) {
      handleLoginWithPopup(getCheckoutURL())
      return true
    }
    if (plan) {
      if (activePlan) return handleUpgradeSubscription()
      // return handleCreateSubscription()
    }
    if (powerUps?.length) return handlePurchase()
    return null
  }

  useEffect(() => {
    setPowerUps(_powerUps)
  }, [_powerUps])

  return (
    <Button
      size='xs'
      variant={variant || 'primary'}
      className='float-right'
      onClick={handleCheckout}
      disabled={
        disabled ||
        Boolean(isPurchasing) ||
        loading ||
        validationStep === 'validating'
      }
      loading={isPurchasing || validationStep === 'validating'}
      {...buttonProps}
    >
      {migrate ? (
        <div className='flex items-center'>
          <span>
            {t(`buttons.migrateToPaddle`, {
              ignorePrefix: true,
            })}
          </span>
          <ArrowRight weight='bold' className='ml-1 h-4 w-4' />
        </div>
      ) : (
        t('buttons.purchase', { ignorePrefix: true })
      )}
    </Button>
  )
}
