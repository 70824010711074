import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { HexColorPicker } from 'react-colorful'

import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/ui/Popover/Popover'
import { useTranslations } from '@/hooks/useTranslations'

interface IPropTypes {
  onChange?: (color?: string) => void
  defaultColor?: string
  onSave?: (color: string) => void
  children?: React.ReactNode
  inline?: Boolean
  className?: string
  containerClassName?: string
}
export default function ColorPicker({
  onChange,
  defaultColor,
  onSave,
  children,
  inline,
  className,
  containerClassName,
}: IPropTypes) {
  const [open, setOpen] = useState(false)
  const t = useTranslations()
  const [color, setColor] = useState(defaultColor || '#000')

  useEffect(() => {
    if (onChange && color !== defaultColor) {
      onChange(color)
    }
  }, [color])

  const renderContent = () => {
    return (
      <div className={clsx('space-y-2', containerClassName)}>
        <HexColorPicker
          data-testid='org_brand_color_palette'
          color={defaultColor}
          onChange={setColor}
          className={className}
        />
        <span className='flex items-center space-x-2'>
          <Input
            value={defaultColor}
            borderless
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (setColor) setColor(e.target.value)
            }}
          />
          {onSave && (
            <Button
              size='xs'
              variant='outline'
              onClick={() => {
                if (onSave) onSave(color)
                setOpen(false)
              }}
            >
              {t('buttons.save')}
            </Button>
          )}
        </span>
      </div>
    )
  }

  if (inline) return renderContent()
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className='flex items-center justify-center'>
        {children || (
          <Button
            variant='naked'
            as='span'
            className='h-[17px] w-[17px]'
            fab
            size='xxs'
            icon={<img src={`/assets/images/colors.svg`} alt='color picker' />}
          />
        )}
      </PopoverTrigger>
      <PopoverContent className='!w-[13.5rem] rounded-md !p-[0.475rem]'>
        {renderContent()}
      </PopoverContent>
    </Popover>
  )
}
