import { type VariantProps, cva } from 'class-variance-authority'
import React from 'react'

const titleVariants = cva(['font-semibold tracking-tight text-gray12'], {
  variants: {
    level: {
      1: ['text-3xl'],
      2: ['text-2xl'],
      3: ['text-xl'],
      4: ['text-lg'],
      5: ['text-base font-medium'],
    },
  },
  defaultVariants: {
    level: 1,
  },
})

interface TitleProps extends VariantProps<typeof titleVariants> {
  className?: string
  level?: 1 | 2 | 3 | 4 | 5
  children: React.ReactNode
  style?: React.CSSProperties
}

function Title({ className, level = 1, children, style }: TitleProps) {
  const CustomTag: any = `h${level}`

  return (
    <CustomTag style={style} className={titleVariants({ level, className })}>
      {children}
    </CustomTag>
  )
}

export default Title
