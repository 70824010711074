import React, { useEffect, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import Spinner from '@/components/shared/ui/Loader'
import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import Textarea from '@/components/shared/ui/Textarea'
import { useTranslations } from '@/hooks/useTranslations'
import {
  convertObjForSelect,
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import { capitalize } from '@/lib/helpers/stringHelpers'
import {
  createClickUpTask,
  getClickUpConfig,
} from '@/models/integration/clickUp'
import type {
  IClickUpTask,
  IClickUpTaskConfig,
} from '@/types/integration/click_up'
import type { IPost } from '@/types/post'
import type { IPostAddon } from '@/types/post/postAddon'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  onLink: (data: IPostAddon) => void
}
export default function CreateClickUpTask({ post, onLink }: IPropTypes) {
  const t = useTranslations(`post.integrations.clickUp`)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [clickUpData, setClickUpData] = useState<IClickUpTaskConfig>()
  const [workspaces, setWorkspaces] = useState<ISelectOption[]>([])
  const [spaces, setSpaces] = useState<ISelectOption[]>([])
  const [folders, setFolders] = useState<ISelectOption[]>([])
  const [lists, setLists] = useState<ISelectOption[]>([])
  const [status, setStatus] = useState<ISelectOption[]>([])
  const [clickUpTask, setClickUpTask] = useState<IClickUpTask>({
    feature_request_id: post.id,
    title: post.title,
    description: post.short_description,
    workspace_id: ``,
    folder_id: ``,
    list_id: ``,
    space_id: ``,
    status: '',
  })

  const fetchData = () => {
    setIsLoading(true)
    getClickUpConfig()
      .then((data) => {
        setClickUpData(data)
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsLoading(false))
  }

  const handleDependencyChange = (key: keyof IClickUpTask, value: string) => {
    if (key === `space_id`) {
      setClickUpTask((prevState) => ({
        ...prevState,
        [key]: value || ``,
        folder_id: ``,
        list_id: ``,
      }))
    } else if (key === `folder_id`) {
      setClickUpTask((prevState) => ({
        ...prevState,
        [key]: value || '',
        list_id: ``,
      }))
    } else {
      setClickUpTask((prevState) => ({
        ...prevState,
        [key]: value || '',
      }))
    }
  }

  const handleSelectChange = (
    data: ISelectOption[],
    key: keyof IClickUpTask,
    value: ISelectOptionGroup
  ) => {
    const selectedValue = getSelectedValueByKey(data, value)

    if (selectedValue && Array.isArray(selectedValue)) {
      handleDependencyChange(key, selectedValue[0]?.value)
    } else {
      handleDependencyChange(key, selectedValue?.value)
    }
  }

  const validate = () =>
    !(
      clickUpTask.title &&
      clickUpTask.space_id &&
      clickUpTask.list_id &&
      clickUpTask.workspace_id
    )

  const handleSubmit = () => {
    setIsSubmitting(true)
    const params = clickUpTask
    if (params.status?.length) {
      const selectedStatus = status.find((s) => s.value === params.status)
      params.status = selectedStatus?.status
    }
    return createClickUpTask(params)
      .then((data) => {
        onLink(data)
        toaster.success({ message: t(`clickUpIssueCreated`) })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsSubmitting(false))
  }

  const handleSetStatuses = () => {
    let selectedStatuses: any = []
    if (clickUpTask.list_id) {
      const list = clickUpData?.lists.find((l) => l.id === clickUpTask.list_id)
      selectedStatuses = list?.statuses?.map((s) => ({
        ...s,
        label: capitalize(s.status),
      }))
    }
    if (selectedStatuses?.length)
      return setStatus(convertObjForSelect(selectedStatuses, `status`, `id`))
    return setStatus([])
  }

  useEffect(() => {
    if (!clickUpData) {
      fetchData()
    }
  }, [])

  useEffect(() => {
    if (clickUpData) {
      setClickUpTask((prevState) => ({
        ...prevState,
        workspace_id: clickUpData.config.push_rule.workspace_id || ``,
        folder_id: clickUpData.config.push_rule.folder_id || ``,
        list_id: clickUpData.config.push_rule.list_id || ``,
        space_id: clickUpData.config.push_rule.space_id || ``,
      }))
      setWorkspaces(convertObjForSelect(clickUpData.workspaces, `name`, `id`))
      if (clickUpData.workspaces?.length) {
        // @ts-ignore
        handleDependencyChange(`workspace_id`, clickUpData.workspaces[0].id)
      }
      setSpaces(convertObjForSelect(clickUpData.spaces, `name`, `id`))
      const filteredFolders = clickUpData.folders.filter(
        (folder) => folder.space_id === clickUpData.config.push_rule.space_id
      )
      setFolders(convertObjForSelect(filteredFolders, `name`, `id`))
      const filteredLists = clickUpData.lists.filter(
        (list) =>
          list.space_id === clickUpData.config.push_rule.space_id &&
          (list.folder_id === null ||
            list.folder_id === clickUpData.config.push_rule.folder_id)
      )
      setLists(convertObjForSelect(filteredLists, `name`, `id`))
      handleSetStatuses()
    }
  }, [clickUpData])

  useEffect(() => {
    if (clickUpData) {
      setWorkspaces(convertObjForSelect(clickUpData.workspaces, `name`, `id`))
      setSpaces(convertObjForSelect(clickUpData.spaces, `name`, `id`))
      const filteredFolders = clickUpData.folders.filter(
        (folder) => folder.space_id === clickUpTask.space_id
      )
      setFolders(convertObjForSelect(filteredFolders, `name`, `id`))

      if (clickUpTask.folder_id) {
        const filteredLists = clickUpData.lists.filter(
          (list) => list.folder_id === clickUpTask.folder_id
        )
        setLists(convertObjForSelect(filteredLists, `name`, `id`))
      } else {
        const filteredLists = clickUpData.lists.filter(
          (list) => list.space_id === clickUpTask.space_id
        )
        setLists(convertObjForSelect(filteredLists, `name`, `id`))
      }
      handleSetStatuses()
    }
  }, [clickUpTask])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='mt-3 space-y-4'>
      <div className='w-full'>
        <Input
          autoFocus
          label={t(`form.title`)}
          onChange={(event) =>
            handleDependencyChange(
              `title`,
              (event.target as HTMLInputElement).value
            )
          }
          type='text'
          placeholder={t(`form.title`)}
          defaultValue={clickUpTask.title}
        />
      </div>
      <div className='w-full'>
        <Textarea
          data-testid='click_up_create_form_description'
          label={t(`form.description`)}
          placeholder={t(`form.description`)}
          onChange={(event) =>
            handleDependencyChange(
              'description',
              (event.target as HTMLTextAreaElement).value.trim()
            )
          }
        >
          {clickUpTask.description}
        </Textarea>
      </div>
      <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
        <div className='space-y-1'>
          <Label>{t(`form.workspace.title`)}</Label>
          <Select
            searchable
            options={workspaces}
            value={setSelectedValue(workspaces, clickUpTask.workspace_id)}
            placeholder={t(`form.workspace.placeholder`)}
            onChange={(value) =>
              handleSelectChange(workspaces, `workspace_id`, value)
            }
            disabled={Boolean(workspaces?.length)}
          />
        </div>
        <div className='space-y-1'>
          <Label>{t(`form.space.title`)}</Label>
          <Select
            searchable
            options={spaces}
            value={setSelectedValue(spaces, clickUpTask.space_id)}
            placeholder={t(`form.space.placeholder`)}
            onChange={(value) => handleSelectChange(spaces, `space_id`, value)}
            clearable
          />
        </div>
        <div className='space-y-1'>
          <Label>{t(`form.folder.title`)}</Label>
          <Select
            searchable
            options={folders}
            value={setSelectedValue(folders, clickUpTask.folder_id)}
            placeholder={t(`form.folder.placeholder`)}
            onChange={(value) =>
              handleSelectChange(folders, `folder_id`, value)
            }
            clearable
          />
        </div>
        <div className='space-y-1'>
          <Label>{t(`form.list.title`)}</Label>
          <Select
            searchable
            options={lists}
            value={setSelectedValue(lists, clickUpTask.list_id)}
            placeholder={t(`form.list.placeholder`)}
            onChange={(value) => handleSelectChange(lists, `list_id`, value)}
            clearable
          />
        </div>
        <div className='space-y-1'>
          <Label>{t(`form.status.title`)}</Label>
          <Select
            searchable
            options={status}
            value={setSelectedValue(status, clickUpTask.status)}
            placeholder={t(`form.status.placeholder`)}
            onChange={(value) => handleSelectChange(status, `status`, value)}
            disabled={Boolean(!clickUpTask.list_id?.length)}
            clearable
          />
        </div>
      </div>
      <div className='flex w-full justify-end pt-3'>
        <Button
          size='xs'
          disabled={validate() || isSubmitting}
          onClick={handleSubmit}
        >
          {t(`pushToClickUp`)}
        </Button>
      </div>
    </div>
  )
}
