import { ArrowUpRight, Bell } from '@phosphor-icons/react'
import Link from 'next/link'
import React, { useEffect, useMemo, useState } from 'react'

import InfiniteScroll from '@/components/shared/components/InfiniteLoaders'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import EmptyState from '@/components/shared/ui/EmptyState'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import UserNotificationListOneLineItem from '@/components/users/profile/notifications/UserNotificationListOneLineItem'
import { useTranslations } from '@/hooks/useTranslations'
import { parseNotification } from '@/lib/helpers/notificationHelper'
import { getNotifications } from '@/models/User'
import type {
  INotification,
  INotificationEntity,
  INotificationFilters,
} from '@/types/user'

const BASE_FILTERS: INotificationFilters = {
  page: 1,
  bucket_ids: [],
  tag_ids: [],
  // @ts-ignore
  type: 'all',
  domains: [],
  filter_category: 'anyone',
}
interface IPropTypes {
  onClick?: (notification: INotification) => void
  filters?: Partial<INotificationFilters>
}

export default function AdminNotificationsMini({
  onClick,
  filters: _filters = {},
}: IPropTypes) {
  const [notificationsData, setNotificationsData] = useState<{
    notifications: INotification[]
    feature_requests: INotificationEntity[]
  }>({
    notifications: [],
    feature_requests: [],
  })
  const t = useTranslations()

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [canFetchMore, setCanFetchMore] = useState(false)
  const [filters, setFilters] = useState<INotificationFilters>({
    ...BASE_FILTERS,
    ..._filters,
  })

  const cleanedList = useMemo(() => {
    const { notifications, feature_requests: featureRequests } =
      notificationsData
    return parseNotification(notifications, featureRequests)
  }, [notificationsData])

  const fetchNotifications = () => {
    setLoading(filters.page === 1)
    getNotifications('inbox', filters)
      .then((data) => {
        setCanFetchMore(!(data.notifications.length < 15))
        setNotificationsData((oldData) => {
          if (filters.page === 1) {
            return data
          }
          return {
            notifications: [...oldData.notifications, ...data.notifications],
            feature_requests: [
              ...oldData.feature_requests,
              ...data.feature_requests,
            ],
          }
        })
      })
      .catch((error) => setErrorMessage(error.message))
      .finally(() => setLoading(false))
  }

  const updatePage = (incrementer: number) => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      page: oldFilters.page + incrementer,
    }))
  }

  useEffect(() => {
    fetchNotifications()
  }, [filters])

  const renderContent = () => {
    if (loading)
      return (
        <div className='flex h-full flex-col items-center justify-center'>
          <SpinnerIcon className='my-5 h-5 w-5 animate-spin text-primary' />
        </div>
      )
    if (errorMessage) return <EmptyState title={errorMessage} />
    if (!cleanedList.length) return <EmptyState title='No notifications' />

    return (
      <div className='flex flex-col gap-2 overflow-y-auto pr-2'>
        {cleanedList.map((notification) => (
          <UserNotificationListOneLineItem
            key={notification.id}
            notification={notification}
            filters={BASE_FILTERS}
            onClick={onClick}
          />
        ))}

        <InfiniteScroll
          showIf={!!notificationsData.notifications.length}
          canFetchMore={canFetchMore}
          onIntersect={() => updatePage(1)}
          loaderComponent={
            <div className='flex justify-center p-2'>
              <SpinnerIcon className='my-5 h-5 w-5 animate-spin text-primary' />
            </div>
          }
        />
      </div>
    )
  }

  return (
    <>
      <div className='sticky top-0 z-10 flex w-full justify-between border-b border-gray5 bg-gray3 px-2 py-4 '>
        <div className='flex items-center gap-2'>
          <h4 className='flex items-center gap-1 font-semibold text-gray12'>
            <Bell />
            {t('profile.headerTabs.notifications')}
          </h4>
          <Link
            href='/profile/notifications'
            className='flex items-center gap-1 rounded-lg bg-gray5 px-1 text-xxs text-gray12'
          >
            {t('sidebar.items.viewAll')}
            <ArrowUpRight />
          </Link>
        </div>

        <Checkbox
          checked={filters.seen === false}
          onCheckedChange={(checked) => {
            setFilters((oldFilters) => ({
              ...oldFilters,
              seen: !checked,
            }))
          }}
          label={t('profile.notifications.actions.showUnread')}
        />
      </div>
      {renderContent()}
    </>
  )
}
