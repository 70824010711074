import {
  Columns,
  Eraser,
  GridFour,
  Rows,
  SquareHalf,
  TextAlignCenter,
  TextAlignLeft,
  Trash,
} from '@phosphor-icons/react'
import type { Editor } from '@tiptap/core'
import { BubbleMenu } from '@tiptap/react'
import { useMemo } from 'react'

import Tooltip from '@/components/shared/ui/Tooltip'
import { useTranslations } from '@/hooks/useTranslations'
import type { IMenuPropTypes } from '@/types/editor'

import {
  BUBBLE_ACTIVE_CLASS,
  BUBBLE_BUTTON_CLASS,
  ICON_CLASS,
} from '../editorConstants'

interface ITableMenuBarProps extends Pick<IMenuPropTypes, 'editor'> {}

export default function TableMenuBar({ editor }: ITableMenuBarProps) {
  const t = useTranslations('editor')

  const selectionType = useMemo(() => {
    // @ts-ignore
    if (editor.view.state?.selection?.jsonID !== 'cell') return 'text'
    return 'cell'
  }, [editor])

  const renderDeleteColumnButton = (_editor: any, _selectionType: any) => {
    if (
      _selectionType === 'cell' &&
      _editor.view.state?.selection?.isColSelection() &&
      !_editor.view.state?.selection?.isRowSelection()
    ) {
      return (
        <Tooltip asChild text={t('delete_column')}>
          <button
            type='button'
            onClick={() => _editor.chain().focus().deleteColumn().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <Trash weight='bold' className={ICON_CLASS} color='red' />
          </button>
        </Tooltip>
      )
    }
    return <></>
  }

  const renderDeleteRowButton = (_editor: any, _selectionType: any) => {
    if (
      _selectionType === 'cell' &&
      _editor.view.state.selection.isRowSelection() &&
      !_editor.view.state.selection.isColSelection()
    ) {
      return (
        <Tooltip asChild text={t('delete_row')}>
          <button
            type='button'
            onClick={() => _editor.chain().focus().deleteRow().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <Trash weight='bold' className={ICON_CLASS} color='red' />
          </button>
        </Tooltip>
      )
    }
    return <></>
  }
  const renderDeleteTableButton = (_editor: any, _selectionType: any) => {
    if (
      _selectionType === 'cell' &&
      _editor.view.state.selection.isRowSelection() &&
      _editor.view.state.selection.isRowSelection()
    ) {
      return (
        <Tooltip asChild text={t('delete_table')}>
          <button
            type='button'
            onClick={() => _editor.chain().focus().deleteTable().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <Trash weight='bold' className={ICON_CLASS} color='red' />
          </button>
        </Tooltip>
      )
    }
    return <></>
  }

  const renderMergeCellButton = (_editor: Editor, _selectionType: any) => {
    if (
      _selectionType === 'cell' &&
      _editor.view.state.selection.ranges.length > 1
    ) {
      return (
        <Tooltip asChild text={t('merge_cells')}>
          <button
            type='button'
            onClick={() => _editor.chain().focus().mergeCells().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <GridFour weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
      )
    }
    return <></>
  }

  const renderSplitCellButton = (_editor: Editor, _selectionType: any) => {
    if (
      _selectionType === 'cell' &&
      _editor.view.state.selection.ranges.length === 1
    ) {
      return (
        <Tooltip asChild text={t('split_cells')}>
          <button
            type='button'
            onClick={() => _editor.chain().focus().splitCell().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <SquareHalf weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
      )
    }
    return <></>
  }

  const renderTableActionButtons = (_editor: Editor) => {
    return (
      <>
        <Tooltip asChild text={t('align_left')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            className={
              editor.isActive({ textAlign: 'left' })
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }
          >
            <TextAlignLeft weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('align_center')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            className={
              editor.isActive({ textAlign: 'center' })
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }
          >
            <TextAlignCenter weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('add_column_after')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().addColumnAfter().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <Columns weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('add_row_after')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().addRowAfter().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <Rows weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        {renderDeleteColumnButton(editor, selectionType)}
        {renderDeleteRowButton(editor, selectionType)}
        {renderDeleteTableButton(editor, selectionType)}
        {renderMergeCellButton(editor, selectionType)}
        {renderSplitCellButton(editor, selectionType)}
      </>
    )
  }

  return (
    <BubbleMenu
      className='absolute flex divide-x divide-gray5 overflow-hidden rounded-full border border-gray6 bg-snow px-2 py-0.5 shadow-sm empty:hidden focus:outline-none '
      tippyOptions={{
        popperOptions: { strategy: 'fixed' },
      }}
      editor={editor}
    >
      {editor.isActive('table') && (
        <>
          {renderTableActionButtons(editor)}
          <Tooltip asChild placement='bottom' text={t('clear_formatting')}>
            <button
              type='button'
              className={`${BUBBLE_BUTTON_CLASS}`}
              onClick={() =>
                editor.chain().focus().unsetAllMarks().clearNodes().run()
              }
            >
              <Eraser weight='bold' className={ICON_CLASS} />
            </button>
          </Tooltip>
        </>
      )}
    </BubbleMenu>
  )
}
