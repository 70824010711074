import { Check, Rewind } from '@phosphor-icons/react'
import React, { useContext, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import { bulkUpdate } from '@/models/Post'
import postStore from '@/stores/PostListStore'
import type { IOrganizationPlan } from '@/types/organization'
import toaster from '@/utils/toast'

interface IPropTypes {
  approvalStatus: string
  onSelectAll: () => void
  onActionComplete: (data: any) => void
}

export default function AdminPostModerationMoveToActionButton({
  approvalStatus,
  onSelectAll,
  onActionComplete,
}: IPropTypes) {
  const t = useTranslations('posts.moderation.postModerations')
  const { organizationPlan } = useContext(HNContext)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { selection, filters, globalFilters } = postStore
  const moderationType = approvalStatus === 'approved' ? 'approve' : 'pending'
  const isModerationEnabled = featureIsEnabled(
    'moderation',
    organizationPlan as IOrganizationPlan
  )

  const handleMove = (noNotify: boolean) => {
    setSubmitting(true)
    const payload = {
      approval_status: approvalStatus,
      feature_request_ids: selection.selectedIds,
      blacklist_ids: selection.ignoredIds,
      select_all: selection.selectedAll,
      filters: { ...filters, ...globalFilters },
      do_not_notify: noNotify,
    }
    return bulkUpdate(payload)
      .then((response) => {
        if (onSelectAll) onSelectAll()
        if (onActionComplete)
          onActionComplete({
            bulkUpdateData: {
              ...payload,
            },
            type: 'update',
            response,
          })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setSubmitting(false))
  }

  return (
    <Tooltip
      text={
        approvalStatus === 'approved'
          ? t('buttons.approve', { ignorePrefix: true })
          : t('buttons.moveToPending', { ignorePrefix: true })
      }
    >
      <AlertDialog
        title={t(`confirmations.${moderationType}.title`)}
        confirmText={
          approvalStatus === 'approved'
            ? t('buttons.approve', { ignorePrefix: true })
            : t('buttons.moveToPending', { ignorePrefix: true })
        }
        description={t(`confirmations.${moderationType}.alertText`)}
        onConfirm={(_value, noNotify) => handleMove(noNotify)}
        showCheckbox={approvalStatus === 'approved'}
        checkboxLabel={t('common.doNotNotify', { ignorePrefix: true })}
      >
        <Button
          disabled={
            submitting ||
            Boolean(!isModerationEnabled && approvalStatus === 'pending')
          }
          fab
          variant='primary'
        >
          {approvalStatus === 'approved' ? (
            <Check weight='bold' className='h-4 w-4' />
          ) : (
            <Rewind weight='bold' className='h-4 w-4' />
          )}
        </Button>
      </AlertDialog>
    </Tooltip>
  )
}
