import type { IAsanaConnectData } from '@/components/integrations/IntegrationStatus'
import {
  ADMIN_INTEGRATION_CONFIG_ACTION_PATH,
  ADMIN_INTEGRATION_CONFIG_PATH,
  ADMIN_INTEGRATIONS_PATH,
  ADMIN_JIRA_INFO_PATH,
  ASANA_PROJECT_SECTIONS_PATH,
  GET_JIRA_ISSUE_TYPES,
  GITHUB_REPO_SEARCH,
  GITLAB_ASSIGNEE_SEARCH,
  GITLAB_LABEL_SEARCH,
  GITLAB_MILESTONE_SEARCH,
  GITLAB_PROJECT_SEARCH,
  INTEGRATION_INFO_PATH,
  RULE_AUTHORIZATIONS,
  TP_INTEGRATIONS,
  TP_INTEGRATIONS_WO_PROVIDER_PATH,
  UPDATE_JIRA_CONFIGURATION,
} from '@/config/routes'
import API from '@/lib/API'
import type { IIntegrationsInfo } from '@/types/integration'
import type { IJiraConfigUpdatePayload } from '@/types/integration/jira'

export const getIntegrations = (): Promise<IIntegrationsInfo> => {
  return API.get(ADMIN_INTEGRATIONS_PATH)
}

export const getIntegrationConfig = (provider: string): Promise<any> => {
  return API.get(`${ADMIN_INTEGRATION_CONFIG_PATH(provider)}`)
}

export const updateIntegrationConfig = (
  provider: string,
  id: string,
  data: any
): Promise<any> => {
  return API.put(`${ADMIN_INTEGRATION_CONFIG_ACTION_PATH(provider, id)}`, data)
}

export const disconnectIntegration = (provider: string): Promise<void> => {
  return API.delete(`${ADMIN_INTEGRATION_CONFIG_PATH(provider)}`)
}

export const getJiraConfig = (): Promise<any> => {
  return API.get(`${ADMIN_JIRA_INFO_PATH}`)
}

export const searchGithubRepos = (data: { query: string }) =>
  API.get(GITHUB_REPO_SEARCH, data)

export const searchGitlabProjects = (data: { query: string }) =>
  API.get(GITLAB_PROJECT_SEARCH, data)

export const searchGitlabMilestones = (data: {
  query: string
  project_id: string | number
}) => API.get(GITLAB_MILESTONE_SEARCH, data)

export const searchGitlabLabels = (data: {
  query: string
  project_id: string | number
}) => API.get(GITLAB_LABEL_SEARCH, data)

export const searchGitlabAssignees = (data: {
  query: string
  project_id: string | number
}) => API.get(GITLAB_ASSIGNEE_SEARCH, data)

export const addRuleToConfig = (provider: string, data: any) =>
  API.post(RULE_AUTHORIZATIONS(provider), data)

export const getJiraIssueTypes = (projectId: string) =>
  API.get(GET_JIRA_ISSUE_TYPES, { project_id: projectId })

export const updateJiraConfig = (data: Partial<IJiraConfigUpdatePayload>) =>
  API.post(UPDATE_JIRA_CONFIGURATION, data)

export const getIntegrationInfo = (
  provider: string,
  data: {
    state: string
    code: string
  }
) => API.post(TP_INTEGRATIONS(provider), data)

export const updateAsanaWorkSpace = (data: IAsanaConnectData) =>
  API.put(TP_INTEGRATIONS_WO_PROVIDER_PATH, data)

export const getAsanaProjectSections = (projectId: string) =>
  API.get(ASANA_PROJECT_SECTIONS_PATH, { project_id: projectId })

export const connectGitlab = (data: any) =>
  API.post(INTEGRATION_INFO_PATH, data)
