import { CaretDown, X } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'

import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import Input from '@/components/shared/ui/Input'
import type { InputProps } from '@/components/shared/ui/Input/Input'
import Label from '@/components/shared/ui/Label'
import Popover from '@/components/shared/ui/Popover'
import {
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/ui/Popover/Popover'
import { createTree } from '@/lib/helpers/dataHelpers'
import { findNodesRecursive } from '@/lib/helpers/modules/kbHelpers'
import { getAdminCollections } from '@/models/KnowledgeBase'
import type {
  IKnowledgeBaseCollection,
  IKnowledgeBaseCollectionWithChildren,
} from '@/types/knowledgeBase'
import toaster from '@/utils/toast'

export interface IAdminKBCollectionPickerProps {
  selectedIds?: string[]
  disabledIds?: string[]
  defaultSelectedIds?: string[]
  onSelectedChange?: (ids: string[]) => void
  showSearch?: boolean
  searchInputProps?: InputProps
  multiple?: boolean
  inline?: boolean
  disabled?: boolean
}

export default function AdminKBCollectionPicker({
  selectedIds: _selectedIds = [],
  disabledIds = [],
  defaultSelectedIds,
  onSelectedChange,
  showSearch = true,
  searchInputProps,
  multiple,
  inline,
  disabled,
}: IAdminKBCollectionPickerProps) {
  const [loading, setLoading] = useState(false)
  const [selectedIds, setSelectedIds] = useState<string[]>(
    _selectedIds || defaultSelectedIds || []
  )

  const [collections, setCollections] = useState<IKnowledgeBaseCollection[]>([])

  const [searchQuery, setSearchQuery] = useState('')

  const nodes = useMemo(() => {
    return createTree(collections)
  }, [collections])

  const handleSelect = (collection: IKnowledgeBaseCollection | null) => {
    if (!collection) {
      setSelectedIds([])
      if (onSelectedChange) {
        onSelectedChange([])
      }
    } else {
      const newSelectedIds = [...selectedIds]
      if (!multiple) {
        newSelectedIds.splice(0, newSelectedIds.length)
        newSelectedIds.push(collection.id)
      } else if (multiple) {
        if (newSelectedIds.includes(collection.id)) {
          newSelectedIds.splice(newSelectedIds.indexOf(collection.id), 1)
        } else {
          newSelectedIds.push(collection.id)
        }
      }

      setSelectedIds(newSelectedIds)
      if (onSelectedChange) {
        onSelectedChange(newSelectedIds)
      }
    }
  }

  const filteredNodes = useMemo(() => {
    if (!searchQuery || !searchQuery.length) return nodes
    return nodes.filter((node) => {
      const matches = findNodesRecursive(
        node,
        (nd: IKnowledgeBaseCollectionWithChildren) => {
          return nd.name.toLowerCase().includes(searchQuery.toLowerCase())
        }
      )
      return matches.length
    })
  }, [nodes, searchQuery])

  const fetchCategories = async () => {
    setLoading(true)
    return getAdminCollections({
      no_pagination: true,
    })
      .then(setCollections)
      .catch(toaster.error)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchCategories()
  }, [])

  const renderNode = (node: any, level: number = 0) => {
    return (
      <>
        <label
          className='flex items-center gap-1 py-1'
          htmlFor={node.id}
          style={{
            paddingLeft: `${level * 8}px`,
          }}
        >
          <Checkbox
            onCheckedChange={() => {
              if (selectedIds.includes(node.id)) {
                handleSelect(null)
              } else {
                handleSelect(node)
              }
            }}
            id={node.id}
            disabled={disabledIds?.includes(node.id) || disabled}
            checked={selectedIds
              .map((i) => i.toString())
              .includes(node.id.toString())}
          />
          <div className='flex flex-col'>
            <Label htmlfor={node.id} className='!text-gray12'>
              {node.name}
            </Label>
          </div>
        </label>
        {node.children
          ? node.children?.map((item: any) => renderNode(item, level + 1))
          : null}
      </>
    )
  }

  if (inline) {
    if (loading) {
      return (
        <div className='min-h-44 flex max-h-60 justify-center overflow-y-auto rounded-lg border-2 border-gray4 p-2'>
          <SpinnerIcon className='my-5 h-5 w-5 animate-spin text-primary' />
        </div>
      )
    }
    return (
      <div className='min-h-44 max-h-44 overflow-y-auto rounded-lg border-2 border-gray4 p-2'>
        {filteredNodes.length ? (
          filteredNodes.map((item) => renderNode(item))
        ) : (
          <div className='flex h-full items-center justify-center'>
            No categories found
          </div>
        )}
      </div>
    )
  }

  return (
    <Popover>
      <PopoverTrigger asChild disabled={disabled}>
        <div className='flex !w-full !cursor-pointer items-center justify-between rounded-full border-[0.5px] border-gray6 px-2 py-1 dark:bg-gray-dark '>
          {loading ? (
            <span className='truncate text-xs text-gray11'>Loading...</span>
          ) : (
            <span
              className={clsx('truncate text-xs', {
                'text-gray12': selectedIds.length,
                'text-gray11': !selectedIds.length,
              })}
            >
              {selectedIds.length
                ? selectedIds
                    .map((id) => {
                      const collection = collections.find((c) => c.id === id)
                      return collection?.name
                    })
                    .join(', ')
                : 'Select Collection'}
            </span>
          )}
          {selectedIds.length ? (
            <X
              className='h-4 w-4 text-gray12'
              onClick={() => {
                setSelectedIds([])
                if (onSelectedChange) {
                  onSelectedChange([])
                }
              }}
            />
          ) : (
            <CaretDown weight='bold' className='shrink-0' />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent className='mt-2 !p-0'>
        {showSearch && (
          <Input
            {...searchInputProps}
            placeholder='Search...'
            className='!m-2'
            defaultValue={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value)
            }}
          />
        )}
        <div className='min-h-44 max-h-60 overflow-y-auto p-2'>
          {filteredNodes.map((item) => renderNode(item))}
        </div>
      </PopoverContent>
    </Popover>
  )
}
