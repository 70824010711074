import { Plus } from '@phosphor-icons/react'
import React, { useEffect, useMemo, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { cloneArray } from '@/lib/helpers/dataHelpers'
import { parseStatusSyncData } from '@/lib/helpers/modules/Integration/syncHelper'
import type { IClickUpIntegrationConfig } from '@/types/integration/click_up'
import type {
  IIntegrationStatusSyncData,
  IStatusSyncRule,
} from '@/types/integration/statusSync'

import AdminIntegrationHellonextSyncRule from '../Shared/AdminIntegrationHellonextSyncRule'
import AdminIntegrationNewSyncRule from '../Shared/AdminIntegrationNewSyncRule'
import AdminIntegrationProviderSyncRule from '../Shared/AdminIntegrationProviderSyncRule'

interface IAdminIntegrationClickUpSyncStatusProps {
  configData: IClickUpIntegrationConfig
  onRemove: (newSyncRules: IStatusSyncRule[]) => Promise<void> | null
  onAdd: (newSyncRules: IStatusSyncRule[]) => void
}

export default function AdminIntegrationClickUpSyncStatus({
  configData,
  onRemove,
  onAdd,
}: IAdminIntegrationClickUpSyncStatusProps) {
  const t = useTranslations(`integrations.clickUp.configure`)

  const [syncRules, setSyncRules] = useState(configData.sync_rules)
  const [showNewRule, setShowNewRule] = useState(false)
  const syncData: IIntegrationStatusSyncData = useMemo(() => {
    return parseStatusSyncData(
      configData,
      'click_up'
    ) as IIntegrationStatusSyncData
  }, [configData])

  const handleNewSyncRule = (syncRule: IStatusSyncRule) => {
    setSyncRules([...syncRules, syncRule])
    onAdd([...syncRules, syncRule])
    setShowNewRule(false)
  }

  const handleRemoveSyncRule = (index: number) => {
    const newSyncRules = cloneArray(syncRules)
    newSyncRules.splice(index, 1)
    setSyncRules(newSyncRules)
    onRemove(newSyncRules)
    setShowNewRule(false)
  }

  const handleCloseSyncRule = () => {
    setShowNewRule(false)
  }

  useEffect(() => {
    setSyncRules(configData.sync_rules)
  }, [configData])

  return (
    <div className='mt-4 border-t border-gray5 pt-4 '>
      <div className='flex items-center justify-between space-x-2'>
        <div className='flex flex-col'>
          <Typography.Text className='!text-sm !font-medium !text-carbon '>
            {t(`syncRule.title`)}
          </Typography.Text>
          <Typography.Text className='mb-3 mt-1 text-gray8'>
            {t(`syncRule.description`)}
          </Typography.Text>
        </div>
        <span className='flex items-center gap-2'>
          <Button
            size='xs'
            icon={<Plus weight='bold' />}
            onClick={() => setShowNewRule(true)}
            variant='outline'
          >
            {t(`syncRule.add`)}
          </Button>
        </span>
      </div>
      <>
        {syncRules.map((syncRule: IStatusSyncRule, index: number) => {
          if (syncRule.source === 'hellonext') {
            return (
              <AdminIntegrationHellonextSyncRule
                key={index}
                provider='click_up'
                configData={syncData}
                syncRule={syncRule}
                edit={false}
                handleRemove={handleRemoveSyncRule}
                index={index}
                newRule={false}
                useCustomStates={configData.sync_by_custom_state}
              />
            )
          }

          if (syncRule.source === 'click_up') {
            return (
              <AdminIntegrationProviderSyncRule
                key={index}
                provider='click_up'
                configData={syncData}
                syncRule={syncRule}
                edit={false}
                handleRemove={handleRemoveSyncRule}
                index={index}
                newRule={false}
                useCustomStates={configData.sync_by_custom_state}
              />
            )
          }

          return <></>
        })}
      </>
      <div className='mt-2 flex'>
        <AdminIntegrationNewSyncRule
          showSyncRule={showNewRule}
          syncRule={configData.default_sync_rule}
          configData={syncData}
          provider='click_up'
          onAdd={handleNewSyncRule}
          onCancel={handleCloseSyncRule}
          useCustomStates={configData.sync_by_custom_state}
        />
      </div>
    </div>
  )
}
