import * as React from 'react'

interface IPropTypes {
  size?: number
}

const PauseIcon = (props: IPropTypes) => (
  <svg
    height={props.size || 25}
    width={props.size || 25}
    viewBox='0 0 25 25'
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    {...props}
  >
    <path d='M12.5.391C5.811.391.391 5.811.391 12.5S5.811 24.609 12.5 24.609s12.109-5.42 12.109-12.109S19.189.391 12.5.391zm0 21.875c-5.396 0-9.766-4.37-9.766-9.766s4.37-9.766 9.766-9.766 9.766 4.37 9.766 9.766-4.37 9.766-9.766 9.766zm4.688-13.672v7.813c0 .43-.352.781-.781.781h-2.344a.783.783 0 0 1-.781-.781V8.594c0-.43.352-.781.781-.781h2.344c.43 0 .781.352.781.781zm-5.469 0v7.813c0 .43-.352.781-.781.781H8.594a.783.783 0 0 1-.781-.781V8.594c0-.43.352-.781.781-.781h2.344c.43 0 .781.352.781.781z' />
  </svg>
)
export default PauseIcon
