import { Copy, Plus } from '@phosphor-icons/react'
import { LightBulbIcon } from '@primer/octicons-react'
import { ErrorBoundary } from '@sentry/react'
import Link from 'next/link'
import React, { useContext, useEffect, useRef, useState } from 'react'

import type { AdminKBNewArticleDialogActions } from '@/components/admin/knowledgeBase/article/AdminKBNewArticleDialog'
import AdminKBNewArticleDialog from '@/components/admin/knowledgeBase/article/AdminKBNewArticleDialog'
import CollapsibleCard from '@/components/shared/common/CollapsibleCard/CollapsibleCard'
import Button from '@/components/shared/ui/Button'
import { useHNAdminPost } from '@/context/HNAdminPostContext'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { copyClipboard, recordEvent, sentry } from '@/lib/helpers/appHelpers'
import { articleURL } from '@/lib/helpers/modules/articleHelpers'
import { getMatchingKBArticles } from '@/models/Post'
import type {
  IArticleCustomization,
  IKnowledgeBaseArticle,
} from '@/types/knowledgeBase'
import type { IOrganizationData } from '@/types/organization'

const PostMatchingKBArticles = () => {
  const t = useTranslations()

  const { slug } = useHNAdminPost()
  const { organization, userProfile, article_customization } =
    useContext(HNContext)
  const createArticleDialog = useRef<AdminKBNewArticleDialogActions>(null)

  const [articles, setArticles] = useState<IKnowledgeBaseArticle[]>([])

  const fetchData = () => {
    if (!slug) return null
    return getMatchingKBArticles(slug).then(setArticles).catch(console.error)
  }

  useEffect(() => {
    fetchData()
  }, [])

  if (!articles.length) return null

  return (
    <div className='mt-4'>
      <CollapsibleCard
        title='Articles that might help you'
        icon={<LightBulbIcon className='text-yellow8' />}
        actions={
          userProfile?.is_member_of_organization && (
            <Button
              as='span'
              variant='outline'
              size='xxs'
              icon={<Plus />}
              onClick={() => {
                recordEvent('CREATE_NEW_TRIGGERED', {
                  action: 'NEW_ARTICLE',
                })
                createArticleDialog.current?.openDialog({})
              }}
            >
              Create a new article
            </Button>
          )
        }
      >
        <div className='grid grid-cols-1 gap-2 md:grid-cols-1'>
          {articles.map((article) => (
            <div
              key={article.id}
              className='flex items-center justify-between rounded-lg border border-gray7 px-2 py-2 hover:bg-gray2'
            >
              <Link
                target='_blank'
                href={articleURL(
                  organization as IOrganizationData,
                  article_customization as IArticleCustomization,
                  article.slug,
                  article.locale
                )}
                onClick={() => {
                  recordEvent('MATCHING_KB_ARTICLE_CLICKED', {
                    articleId: article.id,
                    articleSlug: article.slug,
                    articleTitle: article.title,
                    postSlug: slug,
                  })
                }}
              >
                <h3 className='truncate text-sm font-medium'>
                  {article.title}
                </h3>
              </Link>
              <Button
                variant='outline'
                size='xxs'
                icon={<Copy />}
                onClick={() => {
                  copyClipboard(
                    articleURL(
                      organization as IOrganizationData,
                      article_customization as IArticleCustomization,
                      article.slug,
                      article.locale
                    ),
                    t('post.messages.linkCopied')
                  )
                }}
              >
                {t('post.labels.copyLink')}
              </Button>
            </div>
          ))}
        </div>
      </CollapsibleCard>
      <AdminKBNewArticleDialog ref={createArticleDialog} />
    </div>
  )
}

const ErrorWrapper = () => {
  return (
    <ErrorBoundary
      fallback={<></>}
      onError={(err) => {
        sentry.exception(err)
      }}
    >
      <PostMatchingKBArticles />
    </ErrorBoundary>
  )
}

export default ErrorWrapper
