/* eslint-disable react/display-name */
import clsx from 'clsx'
import type { ReactNode } from 'react'
import React from 'react'

import GuestHeader from '@/components/guest/GuestHeader'
import Wrapper from '@/components/shared/ui/Wrapper/Wrapper'

interface IPropTypes {
  children: ReactNode
}

export default function GuestLayout(props: IPropTypes) {
  return (
    <div className='flex h-screen flex-col overflow-y-auto'>
      <GuestHeader />
      <section className='flex-1 bg-gray3 p-4'>
        <div className={clsx('mx-auto p-4', 'max-w-5xl')}>
          <Wrapper>{props.children}</Wrapper>
        </div>
      </section>
    </div>
  )
}
