import React, { useEffect } from 'react'

import UserAttributePill from '@/components/admin/users/attributes/UserAttributePill'
import CreateUserAttributes from '@/components/admin/users/userSingle/UserCustomAttributes/CreateUserAttributes'
import { useHNUserContext } from '@/context/HNInteractorContext'
import { deleteCustomAttribute, listCustomAttributes } from '@/models/User'
import type { IUserAttribute } from '@/types/user'
import toaster from '@/utils/toast'

export default function AdminSingleUserAttributes() {
  const { custom_fields = [], updateContext, id } = useHNUserContext()

  const fetchData = async () => {
    if (!id) return Promise.resolve()

    return listCustomAttributes(id).then((data) => {
      updateContext?.({
        custom_fields: data,
      })
    })
  }

  useEffect(() => {
    fetchData()
  }, [id])

  const removeItem = (keyId: number) => {
    if (!id) return Promise.resolve()

    return deleteCustomAttribute(id, keyId)
      .then(() => {
        updateContext?.({
          custom_fields: custom_fields.filter((item) => item.id !== keyId),
        })
      })
      .catch(toaster.error)
  }

  const renderItem = (item: IUserAttribute) => (
    <UserAttributePill item={item} onRemove={removeItem} />
  )

  return (
    <div className='flex flex-wrap gap-1'>
      {custom_fields.map(renderItem)}
      <CreateUserAttributes />
    </div>
  )
}
