import type { Editor } from '@tiptap/core'
import type { ForwardedRef } from 'react'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogContent,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import { useTranslations } from '@/hooks/useTranslations'
import toaster from '@/utils/toast'

interface IPropTypes {
  editor: Editor
}
const EditorTableDialog = forwardRef(
  (props: IPropTypes, ref: ForwardedRef<any>) => {
    const { editor } = props
    const [open, setOpen] = React.useState(false)
    const t = useTranslations('editor.tables')
    const [formData, setFormData] = useState({
      rows: 3,
      columns: 3,
    })

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const isActiveTable = editor.isActive('table')
      if (isActiveTable) {
        toaster.error({
          message: 'Cannot insert table inside a table',
        })
      } else {
        editor
          .chain()
          .focus()
          .insertTable({
            rows: formData.rows,
            cols: formData.columns,
            withHeaderRow: true,
          })
          .run()
        setOpen(false)
      }
    }

    useImperativeHandle(ref, () => ({
      openDialog: () => {
        setOpen(true)
      },
    }))

    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent>
          <DialogTitle>{t('title')}</DialogTitle>
          <form onSubmit={handleSubmit} className='mt-2 grid grid-cols-2 gap-2'>
            <div className='col-span-1'>
              <Label>{t('rows.title')}</Label>
              <Input
                autoFocus
                placeholder={t('rows.placeholder')}
                value={formData.rows}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    rows: parseInt(e.target.value as string, 10),
                  })
                }
                type='number'
                required
              />
            </div>
            <div className='col-span-1'>
              <Label>{t('columns.title')}</Label>
              <Input
                placeholder={t('columns.placeholder')}
                value={formData.columns}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({
                    ...formData,
                    columns: parseInt(e.target.value as string, 10),
                  })
                }
                type='number'
                required
              />
            </div>
            <div className='mt-4'>
              <Button type='submit'>{t('button')}</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    )
  }
)

EditorTableDialog.displayName = 'EditorTableDialog'
export default EditorTableDialog
