import clsx from 'clsx'
import { NextSeo } from 'next-seo'
import type { ReactElement } from 'react'
import { useContext, useMemo } from 'react'

import HNContext from '@/context/HNContext'

import { EventEmitter } from '../../../lib/eventEmitter'

interface Props {
  tabsComponent?: ReactElement
  leftComponent?: ReactElement | string
  centerComponent?: ReactElement
  rightComponent?: ReactElement
  isHeader?: boolean
  noBgcolor?: boolean
  isGrid?: boolean
}
export default function AdminHeader({
  tabsComponent,
  leftComponent,
  rightComponent,
  centerComponent,
  isHeader = false,
  noBgcolor = false,
  isGrid = false,
}: Props) {
  const { organization, isAdmin } = useContext(HNContext)

  const gridCount = useMemo(() => {
    let count = 0
    if (leftComponent) count += 1
    if (centerComponent) count += 1
    if (rightComponent) count += 1
    return count
  }, [])
  return (
    <header
      className={clsx(
        'sticky top-0 z-[1] flex w-full shrink-0 flex-col',
        isHeader ? 'border-b dark:border-gray-dark-border' : '',
        noBgcolor ? 'bg-transparent' : 'bg-snow dark:bg-[#1c1d1f]'
      )}
    >
      {typeof leftComponent === 'string' && (
        <NextSeo title={`${leftComponent} | ${organization?.name}`} />
      )}
      <div className='mx-auto flex h-[41px] max-h-[41px] w-full max-w-7xl shrink-0 items-center px-2 md:px-4'>
        {isAdmin && (
          <button
            className='peer mr-4'
            onClick={() =>
              EventEmitter.dispatch('SHORTCUT_TRIGGERED', {
                action: 'TOGGLE_SIDEBAR',
              })
            }
          >
            <svg
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              width='18'
              height='18'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M4 6h16M4 12h8m-8 6h16'
              />
            </svg>
          </button>
        )}
        <div
          className={clsx('w-full py-[6px]', {
            grid: isGrid,
            'grid-cols-1': gridCount === 1,
            'grid-cols-2': gridCount === 2,
            'grid-cols-3': gridCount === 3,
            'flex flex-1 items-center justify-between': !isGrid,
          })}
        >
          <div
            id='header-left'
            className='flex min-w-0 flex-1 items-center justify-start space-x-1 py-[2px] pr-4 font-semibold'
          >
            {/* Here left component children */}
            {leftComponent}
          </div>
          {centerComponent && <div>{centerComponent}</div>}
          <div className='flex flex-row items-center justify-end space-x-1 overflow-x-auto py-[2px] scrollbar-hide'>
            {/* Here right component children */}
            {rightComponent}
          </div>
        </div>
      </div>
      {tabsComponent && (
        <div className='border-b border-gray5/60'>
          <div className='mx-auto flex w-full max-w-7xl shrink-0 items-center pt-1.5 md:px-4'>
            {/* Here taps children */}
            {tabsComponent}
          </div>
        </div>
      )}
    </header>
  )
}
