import {
  GITLAB_ISSUES,
  GITLAB_PROJECT_SEARCH,
  INTEGRATION_CONFIG,
} from '@/config/routes'
import API from '@/lib/API'
import type { IGitlabIssue } from '@/types/integration/gitlab'

export const getGitlabConfig = () => API.get(INTEGRATION_CONFIG('gitlab'))
export const searchGitlabIssues = (data: {
  query: string
  project_id: string
  feature_request_id?: string
}) => API.get(GITLAB_ISSUES, data)
export const searchGitlabRepos = (data: { query: string }) =>
  API.get(GITLAB_PROJECT_SEARCH, data)
export const createGitlabIssue = (data: IGitlabIssue) =>
  API.post(GITLAB_ISSUES, data)
