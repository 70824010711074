import { SearchIcon } from '@primer/octicons-react'
import React, { useEffect, useMemo, useState } from 'react'

import RichTextEditor from '@/components/shared/components/editor/RichTextEditor'
import Button from '@/components/shared/ui/Button'
import EmptyState from '@/components/shared/ui/EmptyState'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import Spinner from '@/components/shared/ui/Loader'
import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import { stateKeyValueChange } from '@/lib/helpers/appHelpers'
import {
  convertObjForSelect,
  getSelectedValueByKey,
  setSelectedValue,
  setSelectedValueArray,
} from '@/lib/helpers/dataHelpers'
import { titleize } from '@/lib/helpers/stringHelpers'
import { getAsanaProjectSections } from '@/models/Integration'
import {
  createAsanaTask,
  getAsanaConfigData,
  getAsanaTasks,
} from '@/models/integration/Asana'
import type {
  IAsanaConfig,
  IAsanaCustomFieldSetting,
  IAsanaTask,
  IAsanaTaskPayload,
  IAsanaUser,
} from '@/types/integration/asana'
import type { IIntegrationPushRuleValue } from '@/types/integration/github'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  onCreate: (data: IAsanaTask, action: string) => void
}

export default function CreateAsanaTask({ post, onCreate }: IPropTypes) {
  const t = useTranslations(`post.integrations.asana`)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFetchingSections, setIsFetchingSections] = useState(false)
  const [asanaData, setAsanaData] = useState<IAsanaConfig>()
  const [asanaIssue, setAsanaIssue] = useState<IAsanaTaskPayload>({
    name: post.title,
    notes_html: post.description_html,
    feature_request_id: post.id,
    project_id: null,
    parent_id: null,
    assignee_id: null,
    custom_field_id: null,
    custom_field_value: null,
    section_id: null,
    tag_ids: null,
  })
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [projects, setProjects] = useState<ISelectOption[]>([])
  const [sections, setSections] = useState<ISelectOption[]>([])
  const [parentTasks, setParentTasks] = useState<ISelectOption[]>()
  const [selectedAsanaTask, setSelectedAsanaTask] =
    useState<ISelectOption | null>(null)

  const assignees: ISelectOption[] = useMemo(() => {
    if (!asanaData) return []
    return asanaData?.users.map((assignee: IAsanaUser) => ({
      ...assignee,
      value: assignee.gid,
      label: assignee.name,
    }))
  }, [asanaData])

  const AsanaTags: ISelectOption[] = useMemo(() => {
    if (!asanaData) return []
    return asanaData?.tags.map((tag: any) => ({
      ...tag,
      value: tag.gid,
      label: tag.name,
    }))
  }, [asanaData])

  const selectedProject = useMemo(
    () => projects.find((project) => project.gid === asanaIssue.project_id),
    [asanaIssue.project_id]
  )

  const customFields = useMemo(() => {
    if (!selectedProject) return []
    return selectedProject?.custom_field_settings
      ?.filter(
        (_customField: IAsanaCustomFieldSetting) =>
          _customField.custom_field.resource_subtype === 'enum' ||
          _customField.custom_field.resource_subtype === 'multi_enum'
      )
      .map((data: any) => {
        return {
          ...data.custom_field,
          label: titleize(data.custom_field.name),
          value: data.custom_field.gid,
        }
      })
  }, [asanaIssue.project_id])

  const customFieldValues = useMemo(() => {
    const selectedCustomField = selectedProject?.custom_field_settings?.find(
      (data: any) => data.custom_field.gid === asanaIssue.custom_field_id
    )
    if (!selectedCustomField) return []
    return selectedCustomField?.custom_field?.enum_options?.map((data: any) => {
      return {
        ...data,
        label: titleize(data.name),
        value: data.gid,
      }
    })
  }, [asanaIssue.custom_field_id])

  const isCustomFieldValueIsMultiple = useMemo(() => {
    const selectedCustomField = selectedProject?.custom_field_settings?.find(
      (data: any) => data.custom_field.gid === asanaIssue.custom_field_id
    )
    if (!selectedCustomField) return false
    return selectedCustomField?.custom_field?.resource_subtype === 'multi_enum'
  }, [asanaIssue.custom_field_id])

  const valid = useMemo(() => {
    return !(asanaIssue.name?.length && asanaIssue.project_id)
  }, [asanaIssue])

  const fetchData = () => {
    setIsLoading(true)
    return getAsanaConfigData()
      .then((data) => {
        setErrorMessage(null)
        setAsanaData(data)
        setProjects(
          data.projects.map((project: ISelectOption) => ({
            ...project,
            value: project.gid,
            label: project.name,
          }))
        )
      })
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false))
  }

  const fetchAsanaProjectSections = (projectId: IIntegrationPushRuleValue) => {
    if (!projectId) return null
    setIsFetchingSections(true)
    return getAsanaProjectSections(projectId as string)
      .then((data) => setSections(convertObjForSelect(data, 'name', 'gid')))
      .catch((error) => {
        toaster.error({ message: error.message })
      })
      .finally(() => setIsFetchingSections(false))
  }

  const handleChange = (key: keyof IAsanaTaskPayload, value: string | any) => {
    const newIssue = { ...asanaIssue }
    newIssue[key] = value
    if (key === 'project_id') {
      fetchAsanaProjectSections(value)
    }
    setAsanaIssue(newIssue)
  }

  const handleSelectChange = (
    data: ISelectOption[],
    key: keyof IAsanaTaskPayload,
    value: ISelectOptionGroup
  ) => {
    const selectedValue = getSelectedValueByKey(data, value)
    if (Array.isArray(selectedValue)) {
      if (isCustomFieldValueIsMultiple && key === 'custom_field_value') {
        return handleChange(
          key,
          value?.map((item: ISelectOption) => item.value)
        )
      }
      return handleChange(key, selectedValue[0]?.value)
    }
    return handleChange(key, selectedValue?.value)
  }

  const handleTagsSelectChange = (
    key: keyof IAsanaTaskPayload,
    value: ISelectOptionGroup
  ) => {
    return handleChange(
      key,
      value?.map((item: ISelectOption) => item.value)
    )
  }

  const handleCustomFieldIdChange = (
    data: ISelectOption[],
    key: keyof IAsanaTaskPayload,
    value: ISelectOptionGroup
  ) => {
    const selectedValue = getSelectedValueByKey(data, value)
    setAsanaIssue((prevData) => ({
      ...prevData,
      [key]: Array.isArray(selectedValue)
        ? selectedValue[0]?.value
        : selectedValue?.value,
      custom_field_value: null,
    }))
  }

  const handleSelectAsanaTask = (issue: any) => {
    if (!issue) {
      setSelectedAsanaTask(null)
      return setAsanaIssue((prevData) => ({ ...prevData, parent_id: null }))
    }
    setSelectedAsanaTask(issue)
    return setAsanaIssue((prevData) => ({
      ...prevData,
      parent_id: issue.value,
    }))
  }

  const handleSearch = (queryString: string) => {
    return getAsanaTasks({
      query: queryString,
      feature_request_id: post.id,
      project_id: asanaIssue.project_id as string,
    }).then((data) => {
      const convertedOptionsData = convertObjForSelect(data, `name`, `gid`, '')
      const index = convertedOptionsData?.findIndex(
        (issue) => issue.value === selectedAsanaTask?.value
      )
      const updatedArray = convertedOptionsData
      if (index === -1 && selectedAsanaTask) {
        updatedArray.unshift(selectedAsanaTask)
        setParentTasks(updatedArray)
      } else setParentTasks(updatedArray)
      return updatedArray
    })
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    const updatedAsanaIssue = { ...asanaIssue }
    if (!updatedAsanaIssue.custom_field_id) {
      updatedAsanaIssue.custom_field_value = null
    }
    return createAsanaTask(updatedAsanaIssue)
      .then((data) => {
        toaster.success({ message: t(`asanaIssueCreated`) })
        onCreate(data as IAsanaTask, 'push')
      })
      .catch((err) => toaster.error({ message: err.message }))
      .then(() => setIsSubmitting(false))
  }

  useEffect(() => {
    if (!asanaData) {
      fetchData()
    }
  }, [])

  useEffect(() => {
    if (asanaIssue.project_id) handleSearch('')
  }, [asanaIssue.project_id])

  const renderAsanaTaskSearch = () => {
    return (
      <div className='relative w-full'>
        <Label className='mb-1'>{t('form.parentIssue.title')}</Label>
        <p
          className='pb-2 text-gray10'
          dangerouslySetInnerHTML={{
            __html: t(`createSearchText`),
          }}
        />
        <Select
          placeholder={t(`form.parentIssue.searchPlaceholder`)}
          searchPlaceholder={t(`form.parentIssue.searchPlaceholder`)}
          loading={isFetchingSections}
          value={
            parentTasks
              ? setSelectedValue(parentTasks, asanaIssue.parent_id || '')
              : null
          }
          loadOptions={handleSearch}
          searchable
          multiple={false}
          options={parentTasks}
          onChange={(value) => {
            handleSelectAsanaTask(value)
          }}
          clearable
          placeholderIcon={<SearchIcon />}
          minimumSearchCharacterLength={2}
          emptyMessage={t(`noIssues`)}
          disabled={!asanaIssue.project_id}
        />
      </div>
    )
  }

  if (isLoading) return <Spinner />

  if (errorMessage) return <EmptyState title={errorMessage} />

  if (!asanaData) {
    return (
      <div className='flex h-full flex-col items-center justify-center'>
        <p className='text-center'>{t(`noAsanaData`)}</p>
      </div>
    )
  }

  return (
    <div className='mt-3 space-y-4'>
      <div className='flex items-center justify-between space-x-8'>
        <div className='w-full space-y-1'>
          <Label>{t(`form.project.title`)}</Label>
          <Select
            placeholder={t(`form.project.placeholder`)}
            searchPlaceholder={t(`form.project.searchPlaceholder`)}
            onChange={(value) =>
              handleSelectChange(projects, 'project_id', value)
            }
            value={setSelectedValue(projects, asanaIssue.project_id || '')}
            options={projects}
            searchable
            emptyMessage={t(`form.team.emptyMessage`)}
          />
        </div>
        <div className='w-full space-y-1'>
          <Label>
            {`${t(`form.section.title`)} (${t('common.optional', {
              ignorePrefix: true,
            })})`}
          </Label>
          <Select
            placeholder={t(`form.section.placeholder`)}
            onChange={(value) =>
              handleSelectChange(sections, 'section_id', value)
            }
            value={setSelectedValue(sections, asanaIssue.section_id || '')}
            options={sections}
            loading={isFetchingSections}
            searchPlaceholder={t(`form.section.searchPlaceholder`)}
            searchable
            clearable
            disabled={!asanaIssue.project_id}
            emptyMessage={t(`form.section.emptyMessage`)}
          />
        </div>
      </div>
      {renderAsanaTaskSearch()}
      <div className='w-full'>
        <Input
          autoFocus
          label={t(`form.title.title`)}
          onChange={(event) =>
            handleChange('name', (event.target as HTMLInputElement).value)
          }
          type='text'
          placeholder={t(`form.title.placeholder`)}
          value={asanaIssue.name}
        />
      </div>
      <div className='w-full space-y-1'>
        <Label>{t(`form.description.title`)}</Label>
        <RichTextEditor
          editable={true}
          defaultValue={asanaIssue.notes_html}
          showBubbleMenu={false}
          showFloatingMenu={false}
          editorMenu={false}
          onChange={(value) => {
            stateKeyValueChange('notes_html', value, setAsanaIssue)
          }}
        />
      </div>
      <div className='items-center sm:flex sm:space-x-8'>
        <div className='w-[50%] space-y-1'>
          <Label>{t(`form.assignee.title`)}</Label>
          <Select
            placeholder={t(`form.assignee.placeholder`)}
            onChange={(value) =>
              handleSelectChange(assignees, 'assignee_id', value)
            }
            value={setSelectedValue(assignees, asanaIssue.assignee_id || '')}
            options={assignees}
            searchPlaceholder={t(`form.assignee.searchPlaceholder`)}
            searchable
            clearable
            disabled={!asanaIssue.project_id}
            emptyMessage={t(`form.assignee.emptyMessage`)}
          />
        </div>
        <div className='w-[50%] space-y-1'>
          <Label>{t(`form.tags.title`)}</Label>
          <Select
            placeholder={t(`form.tags.placeholder`)}
            searchPlaceholder={t(`form.tags.searchPlaceholder`)}
            onChange={(value) => handleTagsSelectChange('tag_ids', value)}
            value={null}
            options={AsanaTags}
            searchable
            multiple
            clearable
            selectAll={false}
            disabled={!asanaIssue.project_id}
            emptyMessage={t(`form.tags.emptyMessage`)}
          />
        </div>
      </div>

      <div className='items-center sm:flex sm:space-x-8'>
        <div className='w-[50%] space-y-1'>
          <Label>{t(`form.customField.title`)}</Label>
          <Select
            placeholder={t(`form.customField.placeholder`)}
            searchPlaceholder={t(`form.customField.searchPlaceholder`)}
            onChange={(value) =>
              handleCustomFieldIdChange(customFields, 'custom_field_id', value)
            }
            value={null}
            options={customFields}
            searchable
            clearable
            selectAll={false}
            disabled={!asanaIssue.project_id}
            emptyMessage={t(`form.customField.emptyMessage`)}
          />
        </div>
        <div className='w-[50%] space-y-1'>
          <Label>{t(`form.customFieldValue.title`)}</Label>
          <Select
            placeholder={t(`form.customFieldValue.placeholder`)}
            searchPlaceholder={t(`form.customFieldValue.searchPlaceholder`)}
            onChange={(value) =>
              handleSelectChange(customFieldValues, 'custom_field_value', value)
            }
            multiple={isCustomFieldValueIsMultiple}
            value={
              Array.isArray(asanaIssue.custom_field_value)
                ? setSelectedValueArray(
                    customFieldValues,
                    asanaIssue.custom_field_value || []
                  )
                : setSelectedValue(
                    customFieldValues,
                    asanaIssue.custom_field_value || ''
                  )
            }
            options={customFieldValues}
            searchable
            clearable
            selectAll={false}
            disabled={!asanaIssue.project_id || !asanaIssue.custom_field_id}
            emptyMessage={t(`form.customFieldValue.emptyMessage`)}
          />
        </div>
      </div>
      <div className='flex w-full justify-end pt-3'>
        <Button
          loading={isSubmitting}
          disabled={valid || isSubmitting}
          onClick={handleSubmit}
        >
          {t(`pushToAsana`)}
        </Button>
      </div>
    </div>
  )
}
