import {
  CalendarBlank,
  CheckCircle,
  Info,
  WarningCircle,
  X,
  XCircle,
} from '@phosphor-icons/react'
import { type VariantProps, cva } from 'class-variance-authority'

import ErrorString from '@/components/shared/common/ErrorString'

import Button from '../Button'

const alertVariants = cva(
  'relative w-full flex items-start gap-4 p-4 text-sm',
  {
    variants: {
      type: {
        info: 'bg-blue-50 text-blue-800',
        success: 'bg-green-50 text-green-800',
        warning: 'bg-yellow-50 text-yellow-800',
        error: 'bg-red-50 text-red-800',
        calender: 'bg-blue-50 text-blue-800',
      },
      rounded: {
        true: 'rounded-lg',
        false: '',
      },
    },
    defaultVariants: {
      type: 'info',
      rounded: true,
    },
  }
)

const iconVariants = cva('h-5 w-5', {
  variants: {
    type: {
      info: 'text-blue-500',
      success: 'text-green-500',
      warning: 'text-yellow-500',
      error: 'text-red-500',
      calender: 'text-blue-500',
    },
  },
  defaultVariants: {
    type: 'info',
  },
})

const actionVariants = cva('font-medium hover:opacity-80', {
  variants: {
    type: {
      info: 'text-blue-600',
      success: 'text-green-600',
      warning: 'text-yellow-600',
      error: 'text-red-600',
      calender: 'text-blue-600',
    },
  },
  defaultVariants: {
    type: 'info',
  },
})

interface AlertProps extends VariantProps<typeof alertVariants> {
  message: string | React.ReactNode
  children?: React.ReactNode
  description?: string
  className?: string
  actionText?: string
  onClose?: () => void
  actionLink?: string
  target?: string
  rightComponent?: React.ReactNode
  icon?: React.ReactNode
}

export default function Alert({
  type = 'info',
  message,
  rounded = true,
  description,
  actionText,
  actionLink,
  children,
  target = '_self',
  rightComponent,
  className = '',
  onClose,
  icon,
}: AlertProps) {
  const renderIcon = () => {
    if (icon) return icon
    const iconClass = iconVariants({ type })

    switch (type) {
      case 'success':
        return <CheckCircle weight='bold' className={iconClass} />
      case 'error':
        return <XCircle weight='bold' className={iconClass} />
      case 'warning':
        return <WarningCircle weight='bold' className={iconClass} />
      case 'calender':
        return <CalendarBlank weight='bold' className={iconClass} />
      default:
        return <Info weight='bold' className={iconClass} />
    }
  }

  const renderAction = () => {
    const actionClass = actionVariants({ type })

    if (onClose)
      return (
        <Button fab size='sm' variant='text' onClick={onClose}>
          <X weight='bold' className={actionClass} />
        </Button>
      )
    if (actionText)
      return (
        <span className='text-sm md:ml-6 md:mt-0'>
          <a href={actionLink || '#'} className={actionClass} target={target}>
            {actionText} <span aria-hidden='true'>→</span>
          </a>
        </span>
      )
    if (rightComponent) return <>{rightComponent}</>
    return false
  }

  return (
    <div className={className}>
      <div className={alertVariants({ type, rounded })}>
        <div className='mt-0.5 shrink-0'>{renderIcon()}</div>
        <div className='grow space-y-2'>
          <div className='items-center space-y-2 md:flex md:space-y-0'>
            {message &&
              (typeof message === 'string' ? (
                <h3 className='grow font-semibold'>
                  <ErrorString message={message} />
                </h3>
              ) : (
                message
              ))}
            {renderAction()}
          </div>
          {description && <p className='text-sm opacity-90'>{description}</p>}
          {children}
        </div>
      </div>
    </div>
  )
}
