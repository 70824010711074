import clsx from 'clsx'
import React, { useEffect, useRef } from 'react'

import {
  destroyImagePreviewEvent,
  initializeImagePreviewEvent,
} from '@/lib/helpers/eventListenerHelper'
import { isHTMLContentEmpty } from '@/lib/helpers/stringHelpers'
import type { IEditorRef } from '@/types/editor'

interface PropTypes {
  html: string
  className?: string
  dataTestId?: string
}
export default function HTMLViewer({
  html = '',
  className,
  dataTestId = 'html_viewer',
}: PropTypes) {
  const htmlRef = useRef<HTMLDivElement>(null)
  const editorRef = useRef<IEditorRef>(null)

  useEffect(() => {
    if (htmlRef.current) {
      initializeImagePreviewEvent(htmlRef.current)
    }
    return () => {
      destroyImagePreviewEvent(htmlRef.current)
    }
  }, [htmlRef.current])

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setValue(html || '')
    }
  }, [html])

  if (isHTMLContentEmpty(html)) return null

  return (
    <div data-testid={dataTestId} ref={htmlRef} className={clsx(className)}>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        className='editors py-2 text-[15px]'
      />
    </div>
  )
}
