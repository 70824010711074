import { FileArrowDown, Trash } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useContext, useMemo, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import CircleProgress from '@/components/shared/ui/CircleProgress/CirculeProgress'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { humanizeByte } from '@/lib/helpers/dataHelpers'
import { getFileIcon } from '@/lib/helpers/modules/attachmentHelpers'
import { canDeleteCommentAttachment } from '@/lib/helpers/rules/commentACLRule'
import { canDeletePostAttachment } from '@/lib/helpers/rules/postACLRule'
import { getAttachmentDownloadLink, removeAttachment } from '@/models/Common'
import type { IComment } from '@/types/comment'
import type { IResourceTypes } from '@/types/common'
import type { IAttachment, IOrganizationPlan } from '@/types/organization'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

interface IPropTypes {
  attachment?: IAttachment
  onRemove?: (attachment: IAttachment) => void

  file?: File
  onUpload?: (attachment: IAttachment) => void
  onError?: (file: File) => void
  resourceId?: string
  resourceType?: IResourceTypes
  resource?: IPost | IComment
}

export default function ExternalAttachmentItem({
  attachment,
  onRemove,
  resourceId,
  resourceType,
  resource,
}: IPropTypes) {
  const t = useTranslations()

  const [deleting, setDeleting] = useState(false)
  const [generating, setGenerating] = useState(false)
  const { userProfile, updateContext, organizationPlan } = useContext(HNContext)
  const [highlighted, setHighlighted] = useState(false)

  const canDelete = useMemo(() => {
    if (!resourceId) return true
    if (resourceType === 'FeatureRequest') {
      return canDeletePostAttachment(
        resource as IPost,
        userProfile as IUserProfile
      )
    }

    if (resourceType === 'Comment') {
      return canDeleteCommentAttachment(
        resource as IComment,
        userProfile as IUserProfile
      )
    }
    return false
  }, [resourceType, resource])

  const handleContextUpdate = (newUsedSize: number) => {
    const planData = { ...organizationPlan } as IOrganizationPlan
    const updatedPlanData = {
      organizationPlan: {
        ...planData,
        power_ups: {
          ...planData.power_ups,
          ext_files_storage: {
            ...planData.power_ups.ext_files_storage,
            used:
              (planData.power_ups?.ext_files_storage?.used || 0) + newUsedSize,
          },
        },
      },
    }

    updateContext?.({
      organizationPlan: updatedPlanData.organizationPlan,
    })
  }
  const handleRemove = () => {
    if (!attachment || !attachment.url) return Promise.resolve()
    setDeleting(true)
    return removeAttachment(attachment?.url)
      .then(() => {
        onRemove?.(attachment)
        handleContextUpdate(-attachment.size)
      })
      .catch(toaster.error)
      .finally(() => setDeleting(false))
  }

  const generateAndDownloadFile = () => {
    if (!attachment || !attachment?.url) return
    setGenerating(true)
    getAttachmentDownloadLink(attachment?.url)
      .then(({ url }) => {
        window.open(url, '_blank')
      })
      .catch(toaster.error)
      .finally(() => {
        setGenerating(false)
      })
  }

  const Icon = attachment?.id
    ? getFileIcon(attachment?.type.split('/')[1] || '')
    : getFileIcon(attachment?.type || '')

  if (attachment?.id) {
    return (
      <div className='group flex w-full items-center justify-between gap-2 overflow-hidden rounded-md border-[0.5px] border-gray6 bg-gray3 px-2 py-1 '>
        <div className='flex flex-1 items-center space-x-2 overflow-hidden'>
          <Icon className='my-1 h-4 w-4 shrink-0' />
          <span className='truncate text-xs'>{attachment?.name}</span>
        </div>
        <div className='flex items-center gap-1'>
          <p className='whitespace-nowrap	font-mono text-xxs text-gray11'>
            {humanizeByte(attachment?.bytesUploaded || 0)}/
            {humanizeByte(attachment?.bytesTotal || 0)}
          </p>
          <CircleProgress size={20} percentage={attachment.progress} />
        </div>
      </div>
    )
  }

  if (!attachment) return <></>

  return (
    <div
      key={attachment.url}
      className={clsx(
        'group flex w-full items-center justify-between gap-2 overflow-hidden rounded-md border-[0.5px] bg-gray3 px-2 py-1',
        highlighted ? 'border-gray8' : 'border-gray6'
      )}
    >
      <div className='flex grow items-center space-x-2 overflow-hidden'>
        <Icon className='my-1 h-4 w-4 shrink-0' />
        <span className='truncate text-xs'>{attachment.name}</span>
      </div>
      <div className='flex items-center'>
        <div
          className={clsx(
            'pointer-events-none items-center space-x-1 whitespace-nowrap text-xxs text-gray11 transition duration-200 group-hover:opacity-0',
            !canDelete ? 'translate-x-[17px]' : 'translate-x-12'
          )}
        >
          <span className='uppercase'>{attachment.type}</span>
          <span>/</span>
          <span className='font-mono'>{humanizeByte(attachment.size)}</span>
        </div>
        <div className='flex translate-x-12 space-x-1 opacity-0 transition duration-200 group-hover:translate-x-0 group-hover:opacity-100'>
          <Tooltip text={t('buttons.download')}>
            <Button
              target='_blank'
              type='button'
              variant='outline'
              size='xs'
              fab
              loading={generating}
              disabled={generating}
              icon={<FileArrowDown />}
              onClick={generateAndDownloadFile}
            />
          </Tooltip>
          {canDelete && (
            <Tooltip text={t('buttons.delete')}>
              <AlertDialog
                title={t(
                  'common.extAttachments.actions.delete.alertTexts.title'
                )}
                description={t(
                  'common.extAttachments.actions.delete.alertTexts.description',
                  {
                    data: {
                      fileName: attachment.name,
                    },
                  }
                )}
                waitForPromise
                confirmText={t('buttons.delete')}
                onConfirm={handleRemove}
                type='danger'
                onOpen={() => setHighlighted(true)}
                onClose={() => setHighlighted(false)}
              >
                <Button
                  type='button'
                  variant='outline'
                  as={'span'}
                  size='xs'
                  fab
                  disabled={deleting}
                  loading={deleting}
                  icon={<Trash />}
                />
              </AlertDialog>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  )
}
