import { Crisp } from 'crisp-sdk-web'

import { SUPPORT_EMAIL } from '@/config/appConstants'

export const openContactChat = (message: string = '') => {
  try {
    const isCrispLoaded = Crisp.session.getIdentifier()

    if (isCrispLoaded) {
      // Register the event handler first if there's a message
      if (message) {
        const handleChatOpened = () => {
          // Send the message after the chat is fully opened
          Crisp.message.send('text', message)
          // Clean up the event listener after it's used
          Crisp.chat.offChatOpened()
        }

        // Register the event handler before any chat actions
        Crisp.chat.onChatOpened(handleChatOpened)
      }

      // Show the chat widget
      Crisp.chat.show()

      // Push a custom event that can be used to trigger automated campaigns or bot scenarios
      Crisp.session.pushEvent('contact_initiated', {
        has_message: !!message,
        timestamp: new Date().toISOString(),
      })

      // Open the chat - this will trigger the onChatOpened event
      Crisp.chat.open()
    } else {
      window.open(`mailto:${SUPPORT_EMAIL}`)
    }
  } catch (error) {
    window.open(`mailto:${SUPPORT_EMAIL}`)
  }
}
