import { useEffect, useMemo, useState } from 'react'

interface IPropTypes {
  width: number
  height?: number
  offset?: number
}
const getWidowWidth = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth
  }
  return 1
}

const getWindowHeight = () => {
  if (typeof window !== 'undefined') {
    return window.innerHeight
  }
  return 1
}

export const useMediaQuery = (props: IPropTypes) => {
  const { width, offset = 0 } = props
  const [windowSize, setWindowSize] = useState({
    width: getWidowWidth() + offset,
    height: getWindowHeight() + offset,
  })
  const passed = useMemo(() => {
    if (windowSize?.width === 1) return true
    if (windowSize.width <= width) {
      return false
    }
    return true
  }, [windowSize])

  useEffect(() => {
    function handleResize() {
      if (typeof window !== 'undefined') {
        const changeInHeight =
          Math.abs(
            (window.innerHeight - windowSize.height) / windowSize.height
          ) * 100
        if (changeInHeight > 10) {
          setWindowSize({
            width: getWidowWidth() + offset,
            height: getWindowHeight() + offset,
          })
        }
        window.addEventListener('resize', handleResize)
      }
    }
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return passed
}
