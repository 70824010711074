import React, { useMemo } from 'react'

import { useTranslations } from '@/hooks/useTranslations'
import type { IKeyValueMap } from '@/types/common'

interface IPropTypes {
  message: string | number
  className?: string
  data?: IKeyValueMap
  dataTestId?: string
}

export default function ErrorString({
  message,
  className,
  data = {},
  dataTestId,
}: IPropTypes) {
  const t = useTranslations('errors')
  const errorMessage = useMemo(() => {
    try {
      if (!message)
        return t('messages.something_went_wrong', { ignorePrefix: true })
      if (!message.toString()?.startsWith('ERROR_CODE')) return message
      return message
        .toString()
        .split(' and ')
        .map(
          (msg) =>
            t(msg, {
              fallback: () => null,
              data,
            }) || msg
        )
        .join(', ')
    } catch (err) {
      return t('messages.something_went_wrong', { ignorePrefix: true })
    }
  }, [message])

  return (
    <span
      data-testid={dataTestId}
      className={className}
      dangerouslySetInnerHTML={{
        __html: errorMessage,
      }}
    />
  )
}
