import { ENV } from '@/config/environment'
import { buildURL } from '@/lib/helpers/urlHelpers'
import type {
  IArticleCustomization,
  IKnowledgeBaseArticle,
} from '@/types/knowledgeBase'
import type { IOrganizationData } from '@/types/organization'

export const kbURL = (organization: IOrganizationData) => {
  return `${ENV.PROTOCOL}://${organization.domain}.${ENV.KB_DOMAIN}`
}
export const articleURL = (
  organization: IOrganizationData,
  customization: IArticleCustomization,
  slug: string,
  lang: string,
  params?: {
    preview?: boolean
  }
) => {
  const baseURL =
    customization?.custom_domain && customization?.valid_custom_domain
      ? `${customization.custom_domain}`
      : `${organization.domain}.${ENV.KB_DOMAIN}`
  const articlePath = params?.preview ? 'article-preview' : 'articles'
  return buildURL(
    `${ENV.PROTOCOL}://${baseURL}/${lang}/${articlePath}/${slug}`,
    params || {}
  )
}

export const cleanArticleSearchItem = (item: any): IKnowledgeBaseArticle => {
  return {
    ...item,
  }
}

export const articleAdminURL = (slug: string, lang: string) => {
  return `/admin/kb/a/${slug}/${lang}`
}
export const articleAdminEditURL = (slug: string, lang: string) => {
  return `${articleAdminURL(slug, lang)}/edit`
}
