import React, { useContext } from 'react'

import AdminPostPriorityFilter from '@/components/admin/posts/filters/AdminPostPriorityFilter'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function SinglePostPriorityChange(props: IPropTypes) {
  const { post } = props
  const t = useTranslations('post')
  const { userProfile } = useContext(HNContext)

  const handleChange = (_key: string, value: ISelectOption) => {
    const priority_type = value && value.length ? value[0] : null
    const updatedPost = {
      ...post,
      priority: priority_type,
    }
    if (!priority_type) return null
    postStore.updateSinglePost(post.slug, updatedPost)
    toaster.success({
      message: t('messages.priority.success'),
    })
    return postStore
      .updatePost(post.slug, {
        priority: priority_type,
      })
      .catch((error) => {
        postStore.updateSinglePost(post.slug, post)
        toaster.error({ message: error.message })
      })
  }

  if (!userProfile?.is_member_of_organization) return <></>
  return (
    <div
      className='grid grid-cols-5 items-center gap-4 px-4'
      data-testid='post_board_change_editable'
    >
      <Typography.Text className='col-span-2 font-medium'>
        {t('priority.title')}
      </Typography.Text>
      <span className='col-span-3'>
        <AdminPostPriorityFilter
          multiple={false}
          filters={{ priority: post?.priority }}
          onChange={handleChange}
          behaviour='select'
          placeholder={t('fields.priority.placeholder')}
        />
      </span>
    </div>
  )
}
