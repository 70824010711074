import * as HoverCardPrimitive from '@radix-ui/react-hover-card'
import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

interface IHoverCardContentProps
  extends HoverCardPrimitive.HoverCardContentProps {
  children?: React.ReactNode
  className?: string
}

interface IHoverCardProps extends HoverCardPrimitive.HoverCardProps {
  children?: React.ReactNode
  className?: string
  asChild?: boolean
}

export const HoverCardContent: FC<IHoverCardContentProps> = ({
  children,
  align,
  className,
  ...props
}) => (
  <HoverCardPrimitive.Portal>
    <HoverCardPrimitive.Content
      {...props}
      sideOffset={5}
      alignOffset={5}
      align={align}
      className={clsx(
        'z-10 w-80 rounded-md bg-snow p-3.5 text-xs font-medium leading-[1.1rem] text-gray12 shadow-tooltip will-change-[transform,opacity] dark:bg-gray-dark dark:text-gray-light',
        'animate-[scaleIn_320ms_cubic-bezier(0.16,1,0.3,1)]',
        className
      )}
    >
      {children}
    </HoverCardPrimitive.Content>
  </HoverCardPrimitive.Portal>
)

export const HoverCardTrigger: FC<IHoverCardProps> = ({
  children,
  className,
  asChild = false,
  ...props
}) => (
  <HoverCardPrimitive.Trigger
    className={className}
    asChild={asChild}
    {...props}
  >
    {children}
  </HoverCardPrimitive.Trigger>
)

export const HoverCard: FC<IHoverCardProps> = ({
  children,
  openDelay,
  ...props
}) => (
  <HoverCardPrimitive.Root
    {...props}
    openDelay={openDelay || 0}
    closeDelay={100}
  >
    {children}
  </HoverCardPrimitive.Root>
)

HoverCard.displayName = 'HoverCard'
