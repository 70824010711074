import React, { useContext } from 'react'

import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import HNContext from '@/context/HNContext'
import { userSideModulePath } from '@/lib/helpers/pathHelpers'
import { getBasePathPrefix } from '@/lib/helpers/urlHelpers'
import type { IEmbedType } from '@/types/organization'

export default function CustomHeaderRoadmapButton() {
  const { show_roadmap_tab, default_module, embedType, organizationSetting } =
    useContext(HNContext)
  if (!show_roadmap_tab) return null

  const url = userSideModulePath(
    'roadmap',
    default_module || 'roadmap',
    getBasePathPrefix(embedType as IEmbedType)
  )
  return (
    <UnstyledLink href={url} className=''>
      {organizationSetting?.roadmap_name}
    </UnstyledLink>
  )
}
