import React, { useContext, useEffect, useState } from 'react'

import AdminPostBoardFilter from '@/components/admin/posts/filters/AdminPostBoardFilter'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Label from '@/components/shared/ui/Label'
import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  convertObjForSelect,
  getSelectedValueByKey,
  isSameObj,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import { getAsanaProjectSections } from '@/models/Integration'
import type {
  IAsanaIntegrationConfig,
  IAsanaReceiveRule,
} from '@/types/integration/asana'
import type {
  IIntegrationPushRuleKey,
  IIntegrationPushRuleValue,
} from '@/types/integration/github'
import toaster from '@/utils/toast'

interface IAdminIntegrationAsanaPullRuleProps {
  configData: IAsanaIntegrationConfig
  onChange: (
    key: IIntegrationPushRuleKey,
    value: IIntegrationPushRuleValue
  ) => void
  pullRule: IAsanaReceiveRule
  isSubmitting: boolean
  onSave: (data: IAsanaReceiveRule) => void
}

export default function AdminIntegrationAsanaPullRule({
  configData,
  onChange,
  pullRule,
  isSubmitting,
  onSave,
}: IAdminIntegrationAsanaPullRuleProps) {
  const t = useTranslations(`integrations.asana.configure`)
  const { organizationSetting } = useContext(HNContext)
  const [autoPull, setAutoPull] = useState<boolean>(pullRule.enabled)
  const [autoPullRule, setAutoPullRule] = useState<IAsanaReceiveRule>(pullRule)
  const projects = convertObjForSelect(configData.projects, 'name', 'gid')
  const [sections, setSections] = useState<ISelectOption[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const getProjectSections = (projectId: IIntegrationPushRuleValue) => {
    if (!projectId) return null
    setLoading(true)
    return getAsanaProjectSections(projectId as string)
      .then((data) => setSections(convertObjForSelect(data, 'name', 'gid')))
      .catch((error) => {
        toaster.error({ message: error.message })
      })
      .finally(() => setLoading(false))
  }

  const handleChange = (
    key: IIntegrationPushRuleKey,
    value: IIntegrationPushRuleValue
  ) => {
    const newRule = {
      ...autoPullRule,
      [key]: value,
    } as IAsanaReceiveRule
    if (key === 'project_id' && value) getProjectSections(value)
    onChange(key, value)
    setAutoPullRule(newRule)
  }

  useEffect(() => {
    if (autoPullRule.project_id) getProjectSections(autoPullRule.project_id)
  }, [autoPullRule.project_id])

  return (
    <div className='mt-4 border-t border-gray5 pt-4 '>
      <div className='flex items-start justify-between py-2'>
        <span>
          <Label
            className='mb-1 !text-sm !font-medium text-carbon transition '
            htmlfor={t(`pullRule.title`)}
          >
            {t(`pullRule.title`)}
          </Label>
          <Typography.Text className='block text-gray8'>
            {t(`pullRule.description`)}
          </Typography.Text>
        </span>
        <Checkbox
          id={t(`pullRule.title`)}
          isSwitch
          checked={autoPull}
          onCheckedChange={(checked) => {
            setAutoPull(checked)
            handleChange('enabled', checked)
          }}
        />
      </div>
      {autoPull && (
        <>
          <div className='grid grid-cols-1 items-center gap-4 pb-2 pt-5 sm:grid-cols-2 md:grid-cols-2'>
            <div className='space-y-1'>
              <Label>{t(`pullRule.when`)}</Label>
              <Select
                placeholder={t(`pullRule.project.placeholder`)}
                searchPlaceholder={t(`pullRule.project.searchPlaceholder`)}
                loading={false}
                value={setSelectedValue(
                  projects,
                  autoPullRule.project_id || ''
                )}
                searchable
                size='xs'
                clearable
                options={projects}
                onChange={(value) => {
                  const selectedValue = getSelectedValueByKey(projects, value)
                  if (selectedValue && Array.isArray(selectedValue)) {
                    handleChange('project_id', selectedValue[0]?.value)
                  } else {
                    handleChange('project_id', selectedValue?.value)
                  }
                }}
              />
            </div>
            <div className='space-y-1'>
              <Label>{t(`pullRule.inSection`)}</Label>
              <Select
                placeholder={t(`pullRule.section.placeholder`)}
                searchPlaceholder={t(`pullRule.section.searchPlaceholder`)}
                loading={loading}
                value={setSelectedValue(
                  sections,
                  autoPullRule.section_id || ''
                )}
                searchable
                size='xs'
                clearable
                options={sections}
                onChange={(value) => {
                  const selectedValue = getSelectedValueByKey(sections, value)
                  if (selectedValue && Array.isArray(selectedValue)) {
                    handleChange('section_id', selectedValue[0]?.value)
                  } else {
                    handleChange('section_id', selectedValue?.value)
                  }
                }}
                disabled={!autoPullRule.project_id}
              />
            </div>
            <div className='space-y-1'>
              <Label>
                {t(`pullRule.in`, {
                  data: {
                    boards:
                      organizationSetting?.bucket_plural_name.toLowerCase(),
                  },
                })}
              </Label>
              <AdminPostBoardFilter
                filters={{ bucket_id: autoPullRule.bucket_id }}
                multiple={false}
                clearable
                placeholder={t(`common.boardsPlaceholder`, {
                  data: {
                    boards:
                      organizationSetting?.bucket_plural_name.toLowerCase(),
                  },
                })}
                onChange={(_key, value) => {
                  handleChange(
                    'bucket_id',
                    Array.isArray(value)
                      ? value?.[0]?.toString()
                      : value.toString()
                  )
                }}
              />
            </div>
          </div>
        </>
      )}
      <div className='mt-3 flex items-center justify-end'>
        <Button
          size='xs'
          loading={isSubmitting}
          disabled={
            isSubmitting || isSameObj(autoPullRule, configData.receive_rule)
          }
          onClick={() => onSave(autoPullRule)}
        >
          {t(`buttons.save`, {
            ignorePrefix: true,
          })}
        </Button>
      </div>
    </div>
  )
}
