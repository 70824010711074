import type { AxiosRequestConfig } from 'axios'
import type { Meilisearch, SearchParams } from 'meilisearch'

import {
  ADD_INTERACTOR_LABEL_PATH,
  ADMINS_NOTIFICATION_CONFIG_PATH,
  BLOCK_INTERACTOR_PATH,
  BULK_UPDATE_CUSTOM_ATTRIBUTES_PATH,
  BULK_UPDATE_NOTIFICATION_PATH,
  CLEAR_USER_NOTIFICATIONS_PATH,
  CUSTOM_ATTRIBUTE_USER_PATH,
  CUSTOM_ATTRIBUTES_USER_PATH,
  CUSTOMERS_PATH,
  DELETE_USER_ACCOUNT_INTERNAL_PATH,
  EMAIL_SUBSCRIBE_PATH,
  EMAIL_UNSUBSCRIBE_PATH,
  INTERACTOR_LABELS_PATH,
  LOGOUT_FROM_ALL_SESSIONS_PATH,
  ORGANIZATION_INTERACTOR_PATH,
  ORGANIZATION_INTERACTORS_PATH,
  ORGANIZATION_INTERACTORS_SEARCH_PATH,
  REMOVE_INTERACTOR_LABEL_PATH,
  REMOVE_USER_PROFILE_PICTURE_PATH,
  RESUBCRIBE_EMAILS_WITH_TOKEN_PATH,
  STAR_INTERACTOR_PATH,
  TOGGLE_USER_EMAIL_DIGEST_PATH,
  TOGGLE_USER_THEME_PATH,
  UNBLOCK_INTERACTOR_PATH,
  UNSUBCRIBE_EMAILS_WITH_TOKEN_PATH,
  UNSUBCRIBE_INFO_PATH,
  UPDATE_ADMINS_NOTIFICATION_CONFIG_PATH,
  USER_NOTIFICATION_CONFIG_PATH,
  USER_NOTIFICATION_PATH,
  USER_NOTIFICATION_READ_PATH,
  USER_NOTIFICATIONS_PATH,
  USER_OWNED_ORGANIZATIONS_PATH,
  USER_PASSWORD_UPDATE_PATH,
  USER_PROFILE_PATH,
  USERS_CUSTOM_ATTRIBUTE_PATH,
  USERS_CUSTOM_ATTRIBUTES_PATH,
  USERS_SETTINGS_PATH,
} from '@/config/routes'
import {
  cleanSearchedUser,
  getUserSearchFilter,
} from '@/lib/helpers/modules/userHelper'
import type { IKeyValueMap } from '@/types/common'
import type {
  ICreateCustomAttribute,
  ICustomAttribute,
} from '@/types/customAttributes'
import type {
  IAdminConfigAPIParams,
  IDeleteUserPayload,
  IInteractorRecord,
  INewUserData,
  INotificationActions,
  INotificationBulkActionAPIPayload,
  INotificationFilters,
  INotificationStates,
  INotificationUpdatePayload,
  IPasswordFormData,
  ISearchedInteractorRecord,
  IUserAttribute,
  IUserFilterType,
  IUserProfileExtended,
} from '@/types/user'

import API from '../lib/API'

export const getInteractor = (id: string): Promise<any> => {
  return API.get(ORGANIZATION_INTERACTOR_PATH(id))
}

export const updateInteractor = (
  id: string,
  data: Partial<IInteractorRecord>
): Promise<any> => {
  return API.put(ORGANIZATION_INTERACTOR_PATH(id), data)
}
export const getUserProfile = (): Promise<IUserProfileExtended> => {
  return API.get(USER_PROFILE_PATH).then((data) => ({
    ...data.user,
    login_methods: data.login_methods,
  }))
}
export const unsubscribeEmail = (emailType: string): Promise<void> => {
  return API.post(EMAIL_UNSUBSCRIBE_PATH, { email_type: emailType })
}
export const subscribeEmail = (emailType: string): Promise<void> => {
  return API.delete(EMAIL_SUBSCRIBE_PATH(emailType))
}
export const updatePassword = (
  passwordData: IPasswordFormData
): Promise<any> => {
  return API.post(USER_PASSWORD_UPDATE_PATH, passwordData)
}

export const removeUserProfilePicture = () => {
  return API.post(REMOVE_USER_PROFILE_PICTURE_PATH)
}

export const getNotifications = (
  type: INotificationStates,
  filters: INotificationFilters,
  otherOptions: AxiosRequestConfig = {}
) => API.get(USER_NOTIFICATIONS_PATH(type), filters, otherOptions)

export const updateNotification = (
  id: string,
  data: INotificationUpdatePayload
) => API.put(USER_NOTIFICATION_PATH(id), data)
export const clearNotification = (id: string) =>
  API.post(USER_NOTIFICATION_READ_PATH(id))

export const clearNotifications = (data: { status: INotificationStates }) => {
  return API.post(CLEAR_USER_NOTIFICATIONS_PATH, data)
}
export const bulkUpdateNotificationAction = (
  action: INotificationActions,
  bulkUpdateData: INotificationBulkActionAPIPayload
): Promise<any> => {
  return API.patch(BULK_UPDATE_NOTIFICATION_PATH(action), bulkUpdateData)
}

export const toggleUserTheme = () => {
  return API.post(TOGGLE_USER_THEME_PATH)
}

export const getUserOwnedOrganizations = () => {
  return API.get(USER_OWNED_ORGANIZATIONS_PATH)
}
export const deleteUser = (payload: IDeleteUserPayload) => {
  return API.post(DELETE_USER_ACCOUNT_INTERNAL_PATH, payload)
}

export const logoutFromAllSession = () =>
  API.delete(LOGOUT_FROM_ALL_SESSIONS_PATH)

// Admin Side

export const getInteractors = (
  queryParams: IKeyValueMap,
  client: Meilisearch
) => {
  if (queryParams.query && queryParams.query?.length) {
    const filters = getUserSearchFilter(queryParams)
    return client
      .index('Customer')
      .search(queryParams.query || '', {
        filter: filters,
        limit: queryParams.limit,
        sort: ['blocked:asc'],
        offset: (queryParams.page - 1) * queryParams.limit,
      })
      .then(({ hits, estimatedTotalHits }) => ({
        users: cleanSearchedUser(hits as ISearchedInteractorRecord[]),
        info: {
          total_count: estimatedTotalHits,
        },
      }))
  }
  return API.get(ORGANIZATION_INTERACTORS_PATH, queryParams)
}

export const listCustomers = (queryParams: IUserFilterType) => {
  return API.get(CUSTOMERS_PATH, queryParams)
}

export const addInteractor = (data: INewUserData) =>
  API.post(ORGANIZATION_INTERACTORS_PATH, data)
export const deleteInteractor = (id: string) =>
  API.delete(ORGANIZATION_INTERACTOR_PATH(id))
export const blockInteractor = (id: string) =>
  API.post(BLOCK_INTERACTOR_PATH(id))
export const unblockInteractor = (id: string) =>
  API.post(UNBLOCK_INTERACTOR_PATH(id))
export const starInteractor = (id: string, data: any) =>
  API.post(STAR_INTERACTOR_PATH(id), data)

export const getInteractorLabels = () => API.get(INTERACTOR_LABELS_PATH)

export const addInteractorLabel = (id: string, data: IKeyValueMap) =>
  API.post(ADD_INTERACTOR_LABEL_PATH(id), data)
export const removeInteractorLabel = (id: string, data: { value: string }) =>
  API.post(REMOVE_INTERACTOR_LABEL_PATH(id), data)

export const getEmailUnsubscribeWithToken = (token: string) =>
  API.get(UNSUBCRIBE_INFO_PATH(token))

export const emailUnsubscribeWithToken = (token: string) =>
  API.post(UNSUBCRIBE_EMAILS_WITH_TOKEN_PATH(token))

export const emailUnsubscribeAllWithToken = (token: string) =>
  API.post(UNSUBCRIBE_EMAILS_WITH_TOKEN_PATH(token), {
    disable_all_emails: true,
  })

export const emailResubscribeWithToken = (
  token: string,
  data?: { disable_all_emails?: boolean }
) => API.delete(RESUBCRIBE_EMAILS_WITH_TOKEN_PATH(token), data)

export const getNotificationConfig = () =>
  API.get(USER_NOTIFICATION_CONFIG_PATH)

export const getAdminSideNotificationConfig = () =>
  API.get(ADMINS_NOTIFICATION_CONFIG_PATH)

export const updateAdminNotificationConfig = (
  role: string,
  data: IAdminConfigAPIParams
) => API.put(UPDATE_ADMINS_NOTIFICATION_CONFIG_PATH(role), data)

export const updateUserPreferenceEmailConfig = (data: IAdminConfigAPIParams) =>
  API.put(USER_NOTIFICATION_CONFIG_PATH, data)

export const updateDigestEmailConfig = (
  data: IUserProfileExtended['notification_config']
) => {
  return API.post(TOGGLE_USER_EMAIL_DIGEST_PATH, data)
}

export const userSettings = () => {
  return API.get(USERS_SETTINGS_PATH)
}

export const listAllCustomAttributes = ({
  resource_type,
  resource_id,
}: {
  resource_type: 'user' | 'bucket'
  resource_id?: string
}) => {
  return API.get(USERS_CUSTOM_ATTRIBUTES_PATH, {
    resource_type,
    resource_id,
  }) as Promise<ICustomAttribute[]>
}

export const createCustomAttribute = (data: ICreateCustomAttribute) => {
  return API.post(USERS_CUSTOM_ATTRIBUTES_PATH, data)
}

export const updateCustomAttribute = (
  id: number,
  data: ICreateCustomAttribute
) => {
  return API.put(USERS_CUSTOM_ATTRIBUTE_PATH(id), data)
}

export const removeCustomAttribute = (id: number) => {
  return API.delete(USERS_CUSTOM_ATTRIBUTE_PATH(id))
}

export const searchInteractors = (
  config: SearchParams & { q: string | null }
) => {
  return API.post(ORGANIZATION_INTERACTORS_SEARCH_PATH, config)
}

interface IAddCustomAttributeToUser {
  slug: string
  value: string | number | boolean | null
}
export const addCustomAttributes = (
  userId: string,
  data: IAddCustomAttributeToUser
): Promise<IUserAttribute> => {
  return API.post(CUSTOM_ATTRIBUTES_USER_PATH(userId), data)
}
export const listCustomAttributes = (userId: string) => {
  return API.get(CUSTOM_ATTRIBUTES_USER_PATH(userId))
}
export const deleteCustomAttribute = (userId: string, keyId: number) => {
  return API.delete(CUSTOM_ATTRIBUTE_USER_PATH(userId, keyId))
}

interface IBulkUpdateAttributes {
  config: {
    [key: string]: {
      value: string | number | boolean | null
    }
  }
  user_ids: string[]
}
export const bulkUpdateAttributes = (data: IBulkUpdateAttributes) => {
  return API.post(BULK_UPDATE_CUSTOM_ATTRIBUTES_PATH, data)
}
