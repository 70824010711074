import {
  Code,
  Image as ImageIcon,
  Table as EditorTable,
  VideoCamera,
} from '@phosphor-icons/react'
import { FloatingMenu } from '@tiptap/react'
import React from 'react'

import Tooltip from '@/components/shared/ui/Tooltip'
import { useTranslations } from '@/hooks/useTranslations'
import type { IMenuPropTypes } from '@/types/editor'

import SVG from '../../SVG'
import {
  BUBBLE_ACTIVE_CLASS,
  BUBBLE_BUTTON_CLASS,
  ICON_CLASS,
} from '../editorConstants'

export default function FloatingMenuBar({
  editor,
  enableImageUpload,
  actions,
  editable,
}: IMenuPropTypes) {
  const t = useTranslations('editor')

  if (!editable) return <></>
  return (
    <FloatingMenu
      className='absolute flex divide-x divide-gray5 overflow-hidden rounded-full border border-gray6 bg-snow px-2 py-0.5 shadow-sm focus:outline-none'
      tippyOptions={{
        duration: 100,
        maxWidth: 'none',
        popperOptions: { strategy: 'fixed' },
        placement: 'bottom',
      }}
      editor={editor}
    >
      {enableImageUpload && (
        <Tooltip asChild text={t('image')}>
          <button
            type='button'
            data-testid='image_button'
            className={`${BUBBLE_BUTTON_CLASS}`}
            onClick={() => actions.triggerImageUpload()}
          >
            <ImageIcon weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
      )}
      <Tooltip asChild text={'Youtube'}>
        <button
          type='button'
          data-testid='youtube_embed_button'
          className={`${BUBBLE_BUTTON_CLASS}`}
          onClick={() => actions.openEmbedDialog('youtube')}
        >
          <VideoCamera weight='bold' className={ICON_CLASS} />
        </button>
      </Tooltip>
      <Tooltip text={t('code_block')}>
        <span className='flex items-center px-1'>
          <button
            type='button'
            data-testid='code_block_button'
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            className={
              editor.isActive('codeBlock')
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }
          >
            <Code weight='bold' className={ICON_CLASS} />
          </button>
        </span>
      </Tooltip>
      <Tooltip asChild placement='bottom' text={'Loom'}>
        <button
          type='button'
          data-testid='loom_embed_button'
          className={`${BUBBLE_BUTTON_CLASS}`}
          onClick={() => actions.openEmbedDialog('loom')}
        >
          <SVG
            url={`/assets/images/loom.svg`}
            className='fill-carbon opacity-100 '
          />
        </button>
      </Tooltip>
      <Tooltip asChild text={t('table')}>
        <button
          type='button'
          onClick={() =>
            editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run()
          }
          className={
            editor.isActive('table') ? BUBBLE_ACTIVE_CLASS : BUBBLE_BUTTON_CLASS
          }
        >
          <EditorTable weight='bold' className={ICON_CLASS} />
        </button>
      </Tooltip>
    </FloatingMenu>
  )
}
