import { PAGINATION_LIMIT } from '@/config/appConstants'
import {
  MULTIPLE_ROADMAP_ITEM_REMOVE_PATH,
  MULTIPLE_ROADMAP_ITEMS_PATH,
  MULTIPLE_ROADMAP_SINGLE_PATH,
  MULTIPLE_ROADMAPS_PATH,
  RESOURCE_MEMBER_PATH,
  RESOURCE_MEMBERS_PATH,
  ROADMAP_CUSTOMIZATION_PATH,
  ROADMAP_INFO_PATH,
  ROADMAP_SETTINGS_PATH,
  TRAIL_MODULE_PATH,
  UPDATE_ROADMAP_ITEM_SCORE_PATH,
  UPDATE_VIEW_PREFERENCES,
  USER_MULTIPLE_ROADMAP_ITEMS_PATH,
  USER_MULTIPLE_ROADMAP_SINGLE_PATH,
  USER_MULTIPLE_ROADMAPS_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import { filterRoadmaps } from '@/lib/helpers/modules/roadmapHelper'
import {
  getIdxDBAllData,
  getIdxDBData,
  removeAllIdxDBData,
  removeIdxDBData,
  setIdxDBData,
} from '@/lib/localDB/localDB'
import type { IKeyValueMap } from '@/types/common'
import type {
  IAddRoadmapMember,
  ICreateRoadmapData,
  ICreateRoadmapItem,
  IRoadmap,
  IRoadmapInfo,
  IRoadmapItem,
  IRoadmapListFilters,
  IRoadmapSettings,
} from '@/types/roadmap'
import type { ISettingUpdatePayload } from '@/types/settings'

export const getRoadmapInfo = (): Promise<IRoadmapInfo> => {
  return API.get(ROADMAP_INFO_PATH)
}
export const getRoadmapSettings = (): Promise<IRoadmapSettings> => {
  return API.get(ROADMAP_SETTINGS_PATH)
}

export const updateRoadmapSettings = (
  data: ISettingUpdatePayload
): Promise<IRoadmapSettings> => {
  return API.put(ROADMAP_SETTINGS_PATH, data)
}

export const updateRoadmapCustomization = (data: ISettingUpdatePayload) =>
  API.put(ROADMAP_CUSTOMIZATION_PATH, data)

// Multiple Roadmaps

// LOCAL

// Local DB
export const getRoadmapsFromDB = (
  filters: IRoadmapListFilters
): Promise<IRoadmap[]> => {
  return getIdxDBAllData('ROADMAPS').then((roadmaps) =>
    filterRoadmaps(roadmaps, filters)
  )
}
export const getRoadmapFromDB = (slug: string): Promise<IRoadmap> =>
  getIdxDBData('ROADMAPS', slug)
export const setRoadmapToDB = (slug: string, data: any): Promise<IRoadmap> =>
  setIdxDBData('ROADMAPS', slug, data)
export const removeRoadmapFromDB = (slug: string) =>
  removeIdxDBData('ROADMAPS', slug)

export const removeAllRoadmapsFromDB = () => removeAllIdxDBData('ROADMAPS')

// Local DB For Roadmap Items
export const getRoadmapItemsFromDB = (
  roadmapSlug: string
): Promise<IRoadmapItem[]> =>
  getIdxDBAllData(`ROADMAP_ITEMS`).then((items) =>
    items.filter(
      (item: IRoadmapItem) =>
        item.roadmapSlug?.toString() === roadmapSlug?.toString()
    )
  )

export const getRoadmapItemFromDB = (itemId: string): Promise<IRoadmapItem> =>
  getIdxDBData(`ROADMAP_ITEMS`, itemId)

export const setRoadmapItemToDB = (
  roadmapSlug: string,
  slug: string,
  data: any
): Promise<IRoadmapItem> => {
  return setIdxDBData(`ROADMAP_ITEMS`, slug, { ...data, roadmapSlug })
}

export const removeRoadmapItemsFromDB = (roadmapId: string) =>
  getRoadmapItemsFromDB(roadmapId).then((items) => {
    const promises = items.map((item: IRoadmapItem) =>
      removeIdxDBData(`ROADMAP_ITEMS`, item.slug)
    )
    return Promise.all(promises)
  })

// Create new roadmap
export const createRoadmap = (data: ICreateRoadmapData): Promise<IRoadmap> => {
  return API.post(MULTIPLE_ROADMAPS_PATH, data)
}
export const updateRoadmap = (
  roadmapId: string,
  data: IKeyValueMap
): Promise<IRoadmap> => {
  return API.put(MULTIPLE_ROADMAP_SINGLE_PATH(roadmapId), data).then(
    (roadmap) => setRoadmapToDB(roadmap.slug, roadmap)
  )
}

export const deleteRoadmap = (roadmapId: string) => {
  return API.delete(MULTIPLE_ROADMAP_SINGLE_PATH(roadmapId))
}
export const listRoadmap = (
  {
    userSide,
  }: {
    userSide: boolean
  },
  filters?: IRoadmapListFilters
): Promise<{ roadmaps: IRoadmap[] }> => {
  return API.get(
    userSide ? USER_MULTIPLE_ROADMAPS_PATH : MULTIPLE_ROADMAPS_PATH,
    filters || {}
  ).then((data) => {
    const promises = []
    if (!Object.keys(filters || {}).length) {
      promises.push(removeAllRoadmapsFromDB())
    }
    data.roadmaps.map((roadmap: IRoadmap) =>
      promises.push(setRoadmapToDB(roadmap.slug, roadmap))
    )
    return Promise.all(promises)
      .then(() => data)
      .catch(() => data)
  })
}
export const getSingleRoadmap = (
  roadmapId: string,
  filters: { userSide: boolean }
): Promise<IRoadmap> => {
  const { userSide, ...restFilters } = filters
  const url = userSide
    ? USER_MULTIPLE_ROADMAP_SINGLE_PATH(roadmapId)
    : MULTIPLE_ROADMAP_SINGLE_PATH(roadmapId)
  return API.get(url, restFilters).then((roadmap) =>
    setRoadmapToDB(roadmap.slug, roadmap)
  )
}
export const listRoadmapItems = (
  slug: string,
  filters: IKeyValueMap,
  { userSide }: { userSide: boolean }
): Promise<IRoadmapItem[]> => {
  const url = userSide
    ? USER_MULTIPLE_ROADMAP_ITEMS_PATH(slug)
    : MULTIPLE_ROADMAP_ITEMS_PATH(slug)
  return API.get(url, { ...filters }).then(async (items) => {
    if (items.length < PAGINATION_LIMIT.roadmapItems) {
      await setRoadmapToDB(slug, { lastFetchDate: new Date() })
    }
    await removeRoadmapItemsFromDB(slug)
    const promises = items.map((item: IRoadmapItem) =>
      setRoadmapItemToDB(slug, item.slug, item)
    )
    return Promise.all(promises).then(() => items)
  })
}

export const createRoadmapItem = (
  roadmapId: string,
  data: ICreateRoadmapItem
) => {
  return API.post(MULTIPLE_ROADMAP_ITEMS_PATH(roadmapId), data)
}

export const updateRoadmapItemScore = (data: IKeyValueMap) => {
  return API.post(UPDATE_ROADMAP_ITEM_SCORE_PATH, data)
}
export const deleteRoadmapItem = (slug: string, itemId: string) => {
  return API.delete(MULTIPLE_ROADMAP_ITEM_REMOVE_PATH(slug, itemId)).then(
    () => {
      return removeIdxDBData('ROADMAP_ITEMS', itemId, 'id')
    }
  )
}

export const listMemberToRoadmap = (id: string) => {
  return API.get(RESOURCE_MEMBERS_PATH, {
    resource_id: id,
    resource_type: 'Roadmap',
  })
}

export const addMemberToRoadmap = (data: IAddRoadmapMember) => {
  return API.post(RESOURCE_MEMBERS_PATH, data)
}
export const removeMemberToRoadmap = (id: string, data: IAddRoadmapMember) => {
  return API.delete(RESOURCE_MEMBER_PATH(id), data)
}

export const updateUserViewPreference = (
  roadmapId: string,
  preferences: IKeyValueMap
) => {
  return API.post(UPDATE_VIEW_PREFERENCES, {
    preferences,
    resource_id: roadmapId, // Roadmap id for single and nil for global roadmap list
    resource_type: 'Roadmap', // Roadmap for single and Roadmaps for global roadmap list
  })
}

export const getMultipleRoadmapTrial = (data: { module: string }) =>
  API.post(TRAIL_MODULE_PATH, data)
