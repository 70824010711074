import type { Filter } from 'meilisearch'

import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import {
  getIdxDBData,
  removeIdxDBData,
  setIdxDBData,
} from '@/lib/localDB/localDB'
import type { ICannedResponse } from '@/models/CannedResponse'
import type { IBoardDetails } from '@/types/board'
import type { IComment, ICreateCommentData } from '@/types/comment'
import type { IOrganizationData } from '@/types/organization'
import type { IUserProfile } from '@/types/user'

export const canCommentInternally = (
  user: IUserProfile,
  comment: IComment
): boolean => {
  const isMember = user.is_member_of_organization
  return !comment ? isMember : isMember && comment.internal
}

export const cleanCannedResponseForSelect = (
  cannedResponse: ICannedResponse
): ISelectOption => ({
  label: cannedResponse.name,
  value: cannedResponse.id,
  ...cannedResponse,
})

export const getCommentFromLocalDB = (
  key: string
): Promise<ICreateCommentData | null> =>
  getIdxDBData('COMMENTS_DRAFTS', key, 'autoSaveKey')

export const setCommentToLocalDB = (
  key: string,
  comment: ICreateCommentData & { autoSaveKey: string }
) => {
  return setIdxDBData('COMMENTS_DRAFTS', key, comment, 'autoSaveKey')
}
export const removeCommentFromLocalDB = (key: string) => {
  return removeIdxDBData('COMMENTS_DRAFTS', key, 'autoSaveKey')
}

export const getCommentPostMentionFilters = ({
  userProfile,
  buckets,
  organization,
}: {
  userProfile?: IUserProfile
  buckets: IBoardDetails[]
  organization: IOrganizationData
}) => {
  let facetFilters: Filter = []
  if (!userProfile) {
    facetFilters = ['hidden=false', 'approval_status=approved']
  } else if (userProfile?.is_member_of_organization)
    facetFilters = ['hidden=false', 'approval_status=approved']
  else if (userProfile)
    facetFilters = [
      ['hidden=false', `submitter_id=${userProfile?.id}`],
      'approval_status=approved',
    ]

  return [
    ...facetFilters,
    buckets.map((bucket) => `bucket_id=${bucket?.id}`),
    `organization_id=${organization?.id}`,
    'merged_with_id=0',
  ]
}
