import dynamic from 'next/dynamic'
import React, { useContext, useEffect, useRef, useState } from 'react'

import AdminSingleUserRemove from '@/components/admin/users/userSingle/AdminSingleUserRemove'
import AdminSingleUserStar from '@/components/admin/users/userSingle/AdminSingleUserStar'
import VoteSourceIcon from '@/components/posts/VoteSourceIcon'
import InfiniteScroll from '@/components/shared/components/InfiniteLoaders'
import UserAvatar from '@/components/shared/components/UserAvatar'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import Spinner from '@/components/shared/ui/Loader'
import Typography from '@/components/shared/ui/Typography'
import { PAGINATION_LIMIT } from '@/config/appConstants'
import { DASHBOARD_API_PATH } from '@/config/routes'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { copyClipboard } from '@/lib/helpers/appHelpers'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import { updateUserList } from '@/lib/helpers/modules/postHelper'
import {
  stringifyValue,
  templateStringReplace,
} from '@/lib/helpers/stringHelpers'
import {
  getPostDownvoters,
  getPostSubscribersList,
  getPostUpvoters,
} from '@/models/Post'
import postStore from '@/stores/PostListStore'
import type { IOrganizationPlan } from '@/types/organization'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

const ChartBlock = dynamic(
  () => import('@/components/admin/dashboard/ChartBlock'),
  {
    ssr: false,
  }
)

interface IPropTypes {
  post: IPost
  type: 'upvote' | 'downvote' | 'subscribers'
  open: boolean
  onClose: (open: boolean) => void
  usersData?: IUserProfile[]
  onRemove: (data: IUserProfile) => void
}

export default function SinglePostUserListModal({
  post,
  type,
  open,
  onClose,
  usersData,
  onRemove,
}: IPropTypes) {
  const { userProfile, organizationPlan } = useContext(HNContext)
  const t = useTranslations()
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [users, setUsers] = useState<IUserProfile[]>(usersData || [])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const page = useRef<number>(1)
  const [canFetchMore, setCanFetchMore] = useState(
    usersData ? usersData?.length >= 30 : false
  )
  const votersAnalyticsEnabled = featureIsEnabled(
    'analytics',
    organizationPlan as IOrganizationPlan
  )

  const fetchUsersList = () => {
    setIsLoading(page.current === 1)
    let promise = getPostUpvoters
    if (type === 'downvote') promise = getPostDownvoters
    if (type === 'subscribers') promise = getPostSubscribersList
    return promise(post.slug, {
      page: page.current,
      per_page: PAGINATION_LIMIT.votersList,
    })
      .then((data: IUserProfile[]) => {
        setCanFetchMore(data?.length >= 30)
        setUsers((prev) => (page.current === 1 ? data : [...prev, ...data]))
        const dataToBeUpdate = updateUserList(type, post, data, page.current)
        postStore.updateSinglePost(post.slug, dataToBeUpdate)
      })
      .catch((err) => {
        toaster.error({ message: err.message })
      })
      .finally(() => setIsLoading(false))
  }

  const checkAndUpdatePostSubmittedByUser = (data: IUserProfile) => {
    if (post?.submitter?.id === data.user_id) {
      const newPost = { ...post, submitter: { ...post.submitter, ...data } }
      postStore.updateSinglePost(post.slug, newPost)
    }
  }

  const handleUpdate = (data: IUserProfile) => {
    setUsers((prev) => {
      return prev.map((user) => {
        if (user.id === data.id) {
          return { ...user, ...data }
        }
        return user
      })
    })

    // check and update starred data if user is same
    checkAndUpdatePostSubmittedByUser(data)
  }

  const handleRemove = (data: IUserProfile) => {
    const newUsers = users.filter((user) => user.id !== data.id)
    setUsers(newUsers)
    if (newUsers?.length === 0) setDialogOpen(false)
    onRemove?.(data)
  }

  const updatePage = (incrementor: number) => {
    page.current += incrementor
    return fetchUsersList()
  }

  useEffect(() => {
    return () => {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (usersData?.length) setUsers(usersData)
  }, [usersData])

  useEffect(() => {
    if (open) fetchUsersList()
    setDialogOpen(open)
  }, [open])

  const renderVoters = (user: IUserProfile) => (
    <li
      className='group flex items-center justify-between py-2'
      key={user.id}
      data-testid={`upvote_info_modal_user_${user.id}`}
    >
      <div className='flex items-center justify-start space-x-2'>
        <UserAvatar starred={user.starred} user={user} rounded />
        <div className='flex flex-col'>
          <div>{stringifyValue(user.name)}</div>
          {userProfile && userProfile.is_admin_of_organization && user.email && (
            <span
              onClick={() => copyClipboard(user.email, t('buttons.copied'))}
            >
              <Typography.Text type='secondary' className='cursor-pointer'>
                {stringifyValue(user.email)}
              </Typography.Text>
            </span>
          )}
        </div>
      </div>
      <div className='flex items-center space-x-2'>
        <VoteSourceIcon user={user} />
        <div className='hidden items-center space-x-2 group-hover:flex'>
          <AdminSingleUserStar
            disable={false}
            starred={user.starred}
            user={user}
            onUpdate={handleUpdate}
          />
          {Boolean(
            type === 'upvote' || type === 'downvote' || type === 'subscribers'
          ) &&
            userProfile?.is_admin_of_organization && (
              <AdminSingleUserRemove
                post={post}
                user={user}
                type={type}
                onRemove={handleRemove}
              />
            )}
        </div>
      </div>
    </li>
  )

  const renderGuestvotes = () => {
    const guestVotes =
      type === 'upvote' ? post.guest_upvotes_count : post.guest_downvotes_count
    if (guestVotes > 0) {
      return (
        <p className='pt-3 text-gray10 '>
          {templateStringReplace(t('post.messages.guestVotesText'), {
            count: guestVotes,
          })}
        </p>
      )
    }
    return null
  }

  const renderUsersListLoader = () => {
    if (type === 'subscribers') return <Spinner />
    return (
      <div className='relative h-screen w-full space-y-2 overflow-x-hidden px-2 scrollbar-hide'>
        {Array.from(Array(20), (_e, i) => (
          <div key={i} className='mt-8 animate-pulse'>
            <div className='flex space-x-2'>
              <div className='h-8 w-8 rounded-full bg-gray5 ' />
              <div className='space-y-2 py-1'>
                <div className='h-2.5 w-20 rounded bg-gray5 ' />
                <div className='space-y-2'>
                  <div className='h-2.5 w-40 rounded bg-gray5 ' />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const renderContent = () => {
    if (isLoading && !users?.length) return <>{renderUsersListLoader()}</>
    if (dialogOpen && users?.length) {
      return (
        <>
          {renderGuestvotes()}
          {users.map(renderVoters)}
          <InfiniteScroll
            showIf={!!users.length}
            canFetchMore={canFetchMore}
            onIntersect={() => updatePage(1)}
            count={Number(users?.length)}
          />
        </>
      )
    }
    return null
  }

  if (typeof document === 'undefined') return <></>
  return (
    <Dialog open={dialogOpen} modal={true} onClose={() => onClose(false)}>
      <DialogContent
        title='Upvoters'
        container={document?.getElementById('main-section')}
        size='md'
        onInteractOutside={() => onClose(false)}
      >
        <DialogClose />
        <DialogTitle data-testid={`${type}_info_modal`}>
          {t(`post.labels.userListModalTitle.${type}`)}
        </DialogTitle>
        <div>
          {Boolean(type === 'upvote' || type === 'downvote') &&
            votersAnalyticsEnabled && (
              <ChartBlock
                title={t(`analytics.dashboards.${type}sDistribution.title`)}
                description={t(
                  `analytics.dashboards.${type}sDistribution.description`
                )}
                url={DASHBOARD_API_PATH}
                isXAxisDatetime
                type='area'
                datetimeFormat='yyyy-MM-dd'
                xAxisLabel=''
                yAxisLabel=''
                entity={t('common.modules.votes')}
                filters={{
                  type: 'posts',
                  sub_type: 'group_voted_by',
                  kind: type,
                  feature_request_id: post.id,
                }}
                noWrapper
                chartOptions={{
                  plotOptions: {
                    area: {
                      marker: {
                        enabled: true,
                        symbol: 'diamond',
                      },
                    },
                  },
                }}
              />
            )}
          {renderContent()}
        </div>
      </DialogContent>
    </Dialog>
  )
}
