import {
  create,
  get,
  parseCreationOptionsFromJSON,
  parseRequestOptionsFromJSON,
  supported,
} from '@github/webauthn-json/browser-ponyfill'

import {
  getAuthenticationPasskeyOptions,
  getRegisterPasskeyOptions,
  verifyAuthenticatePassKey,
  verifyAuthInServerToken,
  verifyRegistrationPassKey,
} from '@/models/Auth'

export const checkIfPasskeySupported = (): Promise<boolean> => {
  return Promise.resolve(supported())
}

export const startPasskeyRegistration = async (
  deviceName: string
): Promise<any> => {
  return getRegisterPasskeyOptions()
    .then((data) => parseCreationOptionsFromJSON({ publicKey: data }))
    .then((options) => create(options))
    .catch((err) => {
      if (
        err.message ===
        'At least one credential matches an entry of the excludeCredentials list in the platform attached authenticator.'
      ) {
        throw new Error('ERROR_CODE_PASSKEY_ALREADY_REGISTERED')
      } else if (
        err.message === 'This request has been cancelled by the user.'
      ) {
        throw new Error('ERROR_CODE_PASSKEY_REGISTRATION_CANCELLED')
      } else {
        throw new Error('ERROR_CODE_PASSKEY_REGISTRATION_CANCELLED')
      }
    })
    .then((data) =>
      verifyRegistrationPassKey({
        publicKeyCredential: data.toJSON(),
        name: deviceName,
      })
    )
}

export const startPasskeyAuthentication = async (): Promise<any> => {
  let challenge = ''
  return getAuthenticationPasskeyOptions()
    .then((data) => {
      challenge = data.challenge || ''
      return data
    })
    .then((data) => parseRequestOptionsFromJSON({ publicKey: data }))
    .then(get)
    .catch(() => {
      throw new Error('ERROR_CODE_PASSKEY_SIGNIN_CANCELLED')
    })
    .then((data) =>
      verifyAuthenticatePassKey({
        publicKeyCredential: data.toJSON(),
        challenge,
      })
    )
    .then((data) => verifyAuthInServerToken(data.token).then(() => data))
}
