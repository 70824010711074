import React from 'react'

export default function SinglePostActionsLoader() {
  return (
    <div className='relative h-screen w-full space-y-4 overflow-y-auto overflow-x-hidden px-5 pr-2 scrollbar-hide'>
      <div className='mt-8 animate-pulse'>
        <div className='w-full space-y-2 pb-4 '>
          <div className='h-7 rounded-full bg-gray7 '></div>
          <div className='h-7 rounded-full bg-gray7 '></div>
          <div className='h-7 rounded-full bg-gray7 '></div>
        </div>
        <div className='mt-6 space-y-10'>
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 h-3 rounded bg-gray7 '></div>
            <div className='col-span-3 h-3 rounded bg-gray7 '></div>
          </div>
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 h-3 rounded bg-gray7 '></div>
            <div className='col-span-3 h-3 rounded bg-gray7 '></div>
          </div>
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 h-3 rounded bg-gray7 '></div>
            <div className='col-span-3 h-3 rounded bg-gray7 '></div>
          </div>
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 h-3 rounded bg-gray7 '></div>
            <div className='col-span-3 h-3 rounded bg-gray7 '></div>
          </div>
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 h-3 rounded bg-gray7 '></div>
            <div className='col-span-3 h-3 rounded bg-gray7 '></div>
          </div>
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 h-3 rounded bg-gray7 '></div>
            <div className='col-span-3 h-3 rounded bg-gray7 '></div>
          </div>
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 h-3 rounded bg-gray7 '></div>
            <div className='col-span-3 h-3 rounded bg-gray7 '></div>
          </div>
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 h-3 rounded bg-gray7 '></div>
            <div className='col-span-3 h-3 rounded bg-gray7 '></div>
          </div>
          <div className='grid grid-cols-5 gap-4'>
            <div className='col-span-2 h-3 rounded bg-gray7 '></div>
            <div className='col-span-3 h-3 rounded bg-gray7 '></div>
          </div>
          <div className='grid  gap-4'>
            <div className='h-3 rounded bg-gray7 '></div>
          </div>
          <div className='grid  gap-4'>
            <div className='h-3 rounded bg-gray7 '></div>
          </div>
        </div>
      </div>
    </div>
  )
}
