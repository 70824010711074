import { LinkBreak } from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import AdminIntegrationLinearPushRule from '@/components/admin/settings/Integrations/config/Linear/AdminIntegrationLinearPushRule'
import AdminIntegrationLinearSyncStatus from '@/components/admin/settings/Integrations/config/Linear/AdminIntegrationLinearSyncStatus'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import Spinner from '@/components/shared/ui/Loader'
import { useTranslations } from '@/hooks/useTranslations'
import {
  disconnectIntegration,
  getIntegrationConfig,
  updateIntegrationConfig,
} from '@/models/Integration'
import type {
  IIntegrationPushRuleKey,
  IIntegrationPushRuleValue,
} from '@/types/integration/github'
import type {
  ILinearConfigUpdatePayload,
  ILinearConfigurationProps,
  ILinearIntegrationConfig,
  ILinearPushRule,
} from '@/types/integration/linear'
import type { IStatusSyncRule } from '@/types/integration/statusSync'
import toaster from '@/utils/toast'

export default function LinearConfiguration({
  onDisconnect,
  integration,
}: ILinearConfigurationProps) {
  const t = useTranslations(`integrations.linear`)
  const router = useRouter()
  const { provider, integrated } = router.query as {
    provider: string
    integrated: string
  }
  const [showConfig, setShowConfig] = useState(false)
  const [configData, setConfigData] = useState<ILinearIntegrationConfig>()
  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [autoPushRule, setAutoPushRule] = useState<ILinearPushRule>()
  const [errorMessages, setErrorMessages] = useState<string | null>(null)

  const fetchConfiguration = () => {
    if (!configData) {
      setIsLoading(true)
    } else {
      setIsSyncing(true)
    }
    return getIntegrationConfig('linear')
      .then((data: ILinearIntegrationConfig) => {
        setErrorMessages(null)
        if (configData) {
          setConfigData({
            ...configData,
            ...data,
          })
        } else {
          setConfigData(data)
        }
        setAutoPushRule(data.push_rule)
      })
      .catch((err) => setErrorMessages(err.message))
      .finally(() => {
        setIsLoading(false)
        setIsSyncing(false)
      })
  }

  const handleUpdateConfig = (config: ILinearConfigUpdatePayload) => {
    setIsSubmitting(true)
    return updateIntegrationConfig(
      'linear',
      configData?.id.toString() || '',
      config
    )
      .then((data) => {
        setConfigData(data)
        setAutoPushRule(data.push_rule)
        toaster.success({
          message: t(`messages.successfullySaved`, {
            ignorePrefix: true,
          }),
        })
      })
      .catch((error) => toaster.error({ message: error.message }))
      .finally(() => setIsSubmitting(false))
  }

  const handlePushRuleChange = (
    key: IIntegrationPushRuleKey,
    value: IIntegrationPushRuleValue
  ) => {
    const newRule = {
      ...autoPushRule,
      [key]: value,
    } as ILinearPushRule
    setAutoPushRule(newRule)
  }

  const handleSubmit = (data: ILinearPushRule) => {
    const config = {
      push_rule: data,
    } as ILinearConfigUpdatePayload
    let errorText = ''
    if (!config.push_rule.status) errorText = t('errorMessages.statusMandatory')
    if (!config.push_rule.buckets?.length)
      errorText = t('errorMessages.bucketMandatory')
    if (!config.push_rule.team_id) errorText = t('errorMessages.teamMandatory')
    if (errorText?.length) return toaster.error({ message: errorText })
    return handleUpdateConfig(config)
  }

  const handleAddSyncRule = (newSyncRules: IStatusSyncRule[]) => {
    setConfigData((prevData) => {
      if (prevData) return { ...prevData, sync_rules: newSyncRules }
      return prevData
    })
  }

  const handleRemoveSyncRule = (newSyncRules: IStatusSyncRule[]) => {
    const config = {
      push_rule: autoPushRule,
      rules: newSyncRules,
    } as ILinearConfigUpdatePayload
    return handleUpdateConfig(config)
  }

  const handleDialogClose = () => {
    setShowConfig(false)
    setAutoPushRule(configData?.push_rule)
  }

  const handleToggleCustomStatus = (checked: boolean) => {
    if (!configData) return null
    return updateIntegrationConfig('linear', configData.id.toString(), {
      sync_by_custom_state: checked,
    }).then((updatedSyncData) => {
      setConfigData(updatedSyncData)
    })
  }

  const handleReconnect = () => {
    disconnectIntegration('linear')
      .then(() => {
        window.location.href = integration.connect_path
      })
      .catch((err) => toaster.error(err.message))
  }

  useEffect(() => {
    if (provider === 'linear' && integrated === 'true') {
      setShowConfig(true)
    }
  }, [])

  useEffect(() => {
    if (showConfig) fetchConfiguration()
  }, [showConfig])

  const renderLoadingOrEmpty = () => {
    if (isLoading) return <Spinner />
    return (
      <>
        <EmptyState
          title={
            errorMessages ||
            t('messages.something_went_wrong', { ignorePrefix: true })
          }
        />
        <div className='mb-2 mt-4 flex w-full justify-end space-x-2'>
          <AlertDialog
            type='danger'
            confirmText={t(`buttons.disconnect`, {
              ignorePrefix: true,
            })}
            title={t(`disconnectConfirmationTitle`)}
            description={t(`disconnectConfirmationMessage`)}
            onConfirm={onDisconnect}
          >
            <Button variant='danger' size='xs' disabled={isSubmitting}>
              {t(`buttons.disconnect`, {
                ignorePrefix: true,
              })}
            </Button>
          </AlertDialog>
        </div>
      </>
    )
  }

  const renderContent = () => {
    if (configData?.reconfiguration_needed) {
      return (
        <DialogContent backdrop>
          <DialogClose />
          <EmptyState
            icon={LinkBreak}
            title='Reconnect Linear'
            description={`Linear has updated the scope of their webhooks, which we use for status sync rules. To keep everything running smoothly, please reconnect the integration.
Don't worry — your current configuration is saved and will be restored once you reconnect. 
`}
          >
            <div>
              <Button className='w-full' size='lg' onClick={handleReconnect}>
                Reconnect Linear
              </Button>
            </div>
          </EmptyState>
        </DialogContent>
      )
    }
    return (
      <DialogContent size='lg' backdrop>
        <DialogClose />
        <DialogTitle>
          <div className='mr-1 flex items-center justify-between'>
            {t(`configure.title`)}
            <div className='flex items-center gap-2'>
              {isSyncing && <SpinnerIcon className='h-4 w-4 animate-spin' />}
              {!!configData && (
                <Checkbox
                  isSwitch
                  defaultChecked={configData?.sync_by_custom_state}
                  onCheckedChange={handleToggleCustomStatus}
                  alertTexts={{
                    title: 'Are sure you want to use custom states?',
                    description:
                      'All your sync rule will be used to sync custom statuses.',
                  }}
                  disabledAlertTexts={{
                    title: 'Use parent status for sync?',
                    description:
                      'All your sync rule will be used to sync parent statuses.',
                  }}
                  label='Use custom states'
                />
              )}
            </div>
          </div>
        </DialogTitle>
        {isLoading || !configData || !autoPushRule ? (
          renderLoadingOrEmpty()
        ) : (
          <>
            <AdminIntegrationLinearPushRule
              configData={configData}
              onChange={handlePushRuleChange}
              pushRule={autoPushRule}
              isSubmitting={isSubmitting}
              onSave={(data) => handleSubmit(data)}
              useCustomStates={configData.sync_by_custom_state}
            />
            <AdminIntegrationLinearSyncStatus
              configData={configData}
              onRemove={handleRemoveSyncRule}
              onAdd={handleAddSyncRule}
            />
            <div className='mb-2 mt-4 flex w-full justify-end space-x-2'>
              <AlertDialog
                type='danger'
                confirmText={t(`buttons.disconnect`, {
                  ignorePrefix: true,
                })}
                title={t(`disconnectConfirmationTitle`)}
                description={t(`disconnectConfirmationMessage`)}
                onConfirm={onDisconnect}
              >
                <Button variant='danger' size='xs' disabled={isSubmitting}>
                  {t(`buttons.disconnect`, {
                    ignorePrefix: true,
                  })}
                </Button>
              </AlertDialog>
            </div>
          </>
        )}
      </DialogContent>
    )
  }
  return (
    <Dialog open={showConfig} onClose={() => handleDialogClose()}>
      <DialogTrigger asChild>
        <Button size='xs' onClick={() => setShowConfig(true)}>
          {t('buttons.configure', {
            ignorePrefix: true,
          })}
        </Button>
      </DialogTrigger>
      {renderContent()}
    </Dialog>
  )
}
