import Bold from '@tiptap/extension-bold'
import CharacterCount from '@tiptap/extension-character-count'
import Document from '@tiptap/extension-document'
import HardBreak from '@tiptap/extension-hard-break'
import Italic from '@tiptap/extension-italic'
import EditorLink from '@tiptap/extension-link'
import Paragraph from '@tiptap/extension-paragraph'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'

import { CalloutExtension } from '@/components/shared/components/editor/extentions/callout/Callout'
import EditorExternalVideoExtention from '@/components/shared/components/editor/extentions/EditorExternalVideoExtention'
import { uploadAttachment } from '@/models/Common'
import type { IKeyValueMap } from '@/types/common'
import type { ISuggestionItem } from '@/types/editor'
import type { IOrganizationData } from '@/types/organization'
import type { IToastData } from '@/utils/toast'
import toaster from '@/utils/toast'

const FILE_SIZE_EXTENDED_ORGS = ['remnote', 'syncfit']
export const editorCheckImageSize = (size: number) => size / 1024 / 1024

export const checkAndGetOrgImageFileSize = (
  organization: IOrganizationData
) => {
  if (FILE_SIZE_EXTENDED_ORGS.includes(organization.domain)) return 15
  return 10
}

export const editorFileUpload = (
  file: File,
  organization: IOrganizationData,
  imageParams: IKeyValueMap = {}
): Promise<IToastData | null> => {
  if (!file) return Promise.reject(new Error('Please select a file'))
  if (
    editorCheckImageSize(file.size) >= checkAndGetOrgImageFileSize(organization)
  )
    return Promise.reject(
      new Error(
        `Please upload an image smaller than ${checkAndGetOrgImageFileSize(
          organization
        )}MB`
      )
    )

  const formData = new FormData()
  formData.append('attachment', file)
  formData.append('organization_id', organization.id)

  if (imageParams) {
    Object.keys(imageParams).forEach((key) => {
      formData.append(key, imageParams[key])
    })
  }

  return uploadAttachment(formData)
    .then((response) => response.url)
    .catch((err) => toaster.error({ message: err.message }))
}

export const cleanSuggestionFromSearch = (
  item: IKeyValueMap,
  indexName: string,
  baseURL: string
): ISuggestionItem => {
  if (indexName === 'Customer')
    return {
      id: item.user_id,
      label: item.name,
      avatar: {
        url: item.profile_picture,
      },
      subLabel: item.email,
    }
  return {
    id: item.id,
    label: item.title,
    href: `${baseURL}p/${item.slug}`,
    bucket: item.bucket,
  }
}

export const DEFAULT_EXTENTIONS = [
  StarterKit,
  Underline,
  EditorLink,
  Typography,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TextAlign,
  EditorExternalVideoExtention,
  CalloutExtension,
  CharacterCount,
]

export const TEXT_ONLY_EXTENTIONS = [
  Document,
  Paragraph,
  Text,
  EditorLink,
  Underline,
  Bold,
  Italic,
  HardBreak,
]

/**
 * Move all chldren out of an element, and remove the element.
 */
function unwrap(el: ParentNode) {
  const parent = el.parentNode

  if (!parent) return
  // Move all children to the parent element.
  while (el.firstChild) parent.insertBefore(el.firstChild, el)

  // Remove the empty element.
  parent.removeChild(el)
}
export function fixTipTapContent(html: string) {
  const container = document.createElement('div')
  container.innerHTML = html

  const inlineImages = container.querySelectorAll('p > img')

  inlineImages.forEach((img) => {
    const { parentNode } = img
    if (parentNode) unwrap(parentNode)
  })

  return container.innerHTML
}
