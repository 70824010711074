import * as React from 'react'

import clsxm from '@/lib/clsxm'

import type { UnstyledLinkProps } from './UnstyledLink'
import UnstyledLink from './UnstyledLink'

const PrimaryLink = React.forwardRef<HTMLAnchorElement, UnstyledLinkProps>(
  ({ className, children, ...rest }, ref) => {
    return (
      <UnstyledLink
        ref={ref}
        {...rest}
        className={clsxm(
          'hn-animate-duration inline-flex items-center',
          'text-primary9 font-medium hover:text-primary',
          'focus:outline-none focus-visible:rounded focus-visible:ring focus-visible:ring-primary focus-visible:ring-offset-2',
          className
        )}
      >
        {children}
      </UnstyledLink>
    )
  }
)

PrimaryLink.displayName = 'PrimaryLink'

export default PrimaryLink
