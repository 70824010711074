import * as React from 'react'

const circumference = 10 * 2 * Math.PI
// const percentage = 60

interface IPropTypes {
  size?: number
  percentage?: number
  color?: string
}

const CircleProgress = ({
  size = 10,
  percentage = 0,
  color = '#0061FF',
  ...props
}: IPropTypes) => (
  <svg
    width={size || 25}
    height={size || 25}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <circle
      r={10}
      cx={12}
      cy={12}
      fill='transparent'
      stroke='#8787872e'
      strokeWidth={3}
    />
    <circle
      r={10}
      cx={12}
      cy={12}
      strokeLinecap='round'
      fill='transparent'
      stroke={color}
      strokeWidth={3}
      strokeDasharray={circumference}
      strokeDashoffset={circumference - (percentage / 100) * circumference}
      transform='rotate(-90)'
      transform-origin='center'
    />
  </svg>
)
export default CircleProgress
