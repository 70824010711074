import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import ForgetPasswordModal from '@/components/auth/actions/ForgetPasswordModal'
import TimeCounter from '@/components/shared/common/TimeCounter'
import OrganizationAvatar from '@/components/shared/components/OrganizationAvatar'
import Alert from '@/components/shared/ui/Alert/Alert'
import Avatar from '@/components/shared/ui/Avatar'
import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Typography from '@/components/shared/ui/Typography'
import { APP_NAME, RESEND_OTP_TIMER } from '@/config/appConstants'
import { featureOSLogoURL } from '@/config/staticURLs'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { redirectAfterSign } from '@/lib/helpers/authHelpers'
import {
  checkIfEmailHasPassword,
  sendOTPToEmail,
  signIn,
  signInWithOTP,
} from '@/models/Auth'
import type {
  IOTPEmailRequestResponse,
  ISigninData,
  ISigninResponse,
} from '@/types/auth'
import type { IOrganizationData } from '@/types/organization'
import toaster from '@/utils/toast'

import AuthProviders from '../providers/AuthProviders'

const DEBOUNCE_TIMER: number = 500
interface IPropTypes {
  showSocialLogins?: boolean
  redirectTo?: string
  prefilledEmail?: string
  isModal?: boolean
  showAllProviders?: boolean
  onNavigation?: (page: string) => void
  onSignUpClick?: React.MouseEventHandler<HTMLParagraphElement>
  containerClassName?: string
}
export default function SignIn({
  showSocialLogins = true,
  redirectTo,
  prefilledEmail,
  isModal,
  onNavigation,
  showAllProviders,
  onSignUpClick,
  containerClassName,
}: IPropTypes) {
  const t = useTranslations('auth')
  const { organization, auth } = useContext(HNContext)

  const [signinData, setSigninData] = useState<ISigninData>({
    email: prefilledEmail || '',
    skip_login_method_validation: !!showAllProviders,
  })
  const [userData, setUserData] = useState<IOTPEmailRequestResponse>()
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<null | string>(null)
  const [hasPassword, setHasPassword] = useState<boolean>(true)
  const [fetchingEmailDetails, setFetchingEmailDetails] =
    useState<boolean>(false)
  const [disableOTPButton, setDisableOTPButton] = useState(true)

  const router = useRouter()
  const { fromApp } = router.query as { fromApp?: string }
  const debounceRef = useRef<NodeJS.Timeout>()

  const debounceSearch = (email = '') => {
    setFetchingEmailDetails(true)
    if (debounceRef.current) clearTimeout(debounceRef.current)
    debounceRef.current = setTimeout(
      () =>
        checkIfEmailHasPassword(email)
          .then((response) => {
            setHasPassword(response.has_password)
          })
          .catch(() => {
            setHasPassword(true)
          })
          .finally(() => setFetchingEmailDetails(false)),
      DEBOUNCE_TIMER
    )
  }

  const handleRedirect = (response: ISigninResponse) => {
    return redirectAfterSign(response, router, !!isModal, redirectTo)
  }

  const handleSendOTLLink = () => {
    if (!signinData.email || !signinData.email.length)
      return Promise.reject(new Error('Please enter email'))
    setLoading(true)
    setErrorMessage(null)
    return sendOTPToEmail({ email: signinData.email })
      .then(setUserData)
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setLoading(false))
  }

  const handleOTLLogin = (otpCode: string) => {
    if (!userData) return Promise.reject(new Error('Invalid User'))
    if (!otpCode) return Promise.reject(new Error('Please enter OTP'))
    setLoading(true)
    setErrorMessage(null)
    return signInWithOTP({
      userId: userData.id,
      otpCode,
      fromApp,
    })
      .then(handleRedirect)
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setLoading(false))
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (
    e
  ): Promise<any> => {
    e.preventDefault()
    if (userData) return handleOTLLogin(signinData.otp || '')
    if (!hasPassword && !signinData.password) return handleSendOTLLink()
    setLoading(true)
    setErrorMessage(null)
    return signIn({ ...signinData, from_app: fromApp })
      .then(handleRedirect)
      .catch((err) => {
        setErrorMessage(err.message)
        setLoading(false)
      })
  }

  const handleChange = (key: 'email' | 'password' | 'otp', value: string) => {
    setSigninData((_signinData) => ({
      ..._signinData,
      [key]: value,
    }))
  }

  const validate = useMemo(() => {
    if (hasPassword) {
      return Boolean(signinData.email && signinData.password)
    }

    if (userData) {
      return signinData.otp && signinData.otp.length >= 6
    }

    if (!hasPassword) {
      return signinData.email && signinData.email.length
    }

    return true
  }, [signinData, hasPassword, userData])

  useEffect(() => {
    if (signinData.email === '') {
      setHasPassword(true)
    } else {
      debounceSearch(signinData.email)
    }
  }, [signinData.email])

  const renderForgotPasswordButton = () => {
    if (isModal) {
      return (
        <Button
          dataTestId='forgot_password_button'
          size='xs'
          type='button'
          variant='naked'
          className='!text-gray11'
          onClick={() => {
            if (onNavigation) onNavigation('forgotPassword')
          }}
        >
          {t(`forgotPassword.title`)}
        </Button>
      )
    }
    return <ForgetPasswordModal showAsDialog />
  }

  return (
    <div
      className={clsx(
        showSocialLogins ? 'w-screen items-center pt-10 md:pt-24' : '',
        containerClassName,
        'flex flex-col justify-center'
      )}
    >
      <div className={clsx(showSocialLogins ? 'p-8' : '')}>
        <div
          className={clsx(
            'mx-auto flex-col justify-center space-y-6',
            showSocialLogins ? 'md:w-[350px]' : ''
          )}
        >
          {showSocialLogins && (
            <div className='flex flex-col items-center space-y-2 text-center'>
              {organization ? (
                <OrganizationAvatar
                  organization={organization as IOrganizationData}
                  size='lg'
                />
              ) : (
                <Avatar url={featureOSLogoURL} name={APP_NAME} rounded />
              )}
              <Typography.Title level={3} className='text-2xl font-bold'>
                {t('signIn.title')}
              </Typography.Title>
            </div>
          )}
          <div className='-mb-px empty:hidden'>
            {errorMessage && (
              <Alert rounded type='error' message={errorMessage} />
            )}
          </div>
          <div className='grid gap-6'>
            {!!(auth?.email || showAllProviders) && (
              <form onSubmit={handleSubmit}>
                <div className='grid gap-2 text-left'>
                  {userData ? (
                    <>
                      {/* TODO: Need new input type: numeric */}
                      <Input
                        dataTestId='otp_input'
                        label={t('fields.otp.label')}
                        id='otp'
                        type='text'
                        inputMode='numeric'
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange('otp', e.target.value)
                        }
                        value={signinData.otp || ''}
                        autoFocus
                        placeholder={t('fields.otp.placeholder')}
                      />
                      <div className='mt-2 flex items-center justify-between'>
                        <Button
                          dataTestId='resend-otp-btn'
                          type='button'
                          size='xs'
                          variant='naked'
                          disabled={disableOTPButton}
                          onClick={() => {
                            handleSendOTLLink()
                            setDisableOTPButton(true)
                          }}
                        >
                          {t('labels.resendOTP')}
                        </Button>
                        <TimeCounter
                          durationInSeconds={RESEND_OTP_TIMER}
                          onTimeEnd={() => setDisableOTPButton(false)}
                          show={disableOTPButton}
                        />
                      </div>
                    </>
                  ) : (
                    <div className='flex flex-col gap-3'>
                      <Input
                        label={t('fields.email.label')}
                        defaultValue={signinData.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange('email', e.target.value)
                        }
                        value={signinData.email || ''}
                        type='email'
                        autoComplete='username webauthn'
                        placeholder={t('fields.email.placeholder')}
                        autoFocus
                        dataTestId='signin_email_input'
                        className=''
                      />
                      <Input
                        dataTestId='signin_password_input'
                        label={t('fields.password.label')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange('password', e.target.value)
                        }
                        value={signinData.password || ''}
                        type='password'
                        placeholder={t(
                          !hasPassword
                            ? 'fields.password.optionalPlaceholder'
                            : 'fields.password.placeholder'
                        )}
                      />
                    </div>
                  )}

                  {userData ? (
                    <Button
                      dataTestId='signin-otp-btn'
                      size='md'
                      type='submit'
                      variant='primary'
                      block
                      loading={loading}
                      disabled={loading || !validate}
                      id='signin-otp-btn'
                    >
                      {t('labels.signInWithOTP')}
                    </Button>
                  ) : (
                    <Button
                      dataTestId={
                        hasPassword || signinData.password
                          ? 'signin_button'
                          : 'magic_link_button'
                      }
                      variant='primary'
                      size='md'
                      type='submit'
                      block
                      id='signin-btn'
                      loading={loading}
                      disabled={loading || fetchingEmailDetails || !validate}
                    >
                      {hasPassword || signinData.password
                        ? t('labels.signIn')
                        : t('actions.sendMagicLink')}
                    </Button>
                  )}
                  <div className='flex justify-center'>
                    {renderForgotPasswordButton()}
                  </div>
                </div>
              </form>
            )}
            {showSocialLogins && (
              <>
                {auth?.email &&
                  Object.values(auth).filter(Boolean).length !== 1 && (
                    <div className='pointer-events-none flex h-[42px] w-full flex-[0_0_auto] items-center justify-center'>
                      <div className='visible h-px w-full border-b border-b-[rgba(55,53,47,0.16)]' />
                    </div>
                  )}
                <AuthProviders showAllProviders={showAllProviders} />
              </>
            )}
          </div>
          {showSocialLogins && (
            <p className='px-8 text-center text-sm font-normal text-gray11'>
              {t(`labels.doNotHaveAccount`)}{' '}
              {onSignUpClick ? (
                <span
                  onClick={onSignUpClick}
                  role='button'
                  className='cursor-pointer underline hover:text-brand'
                >
                  {t('labels.signUp')}
                </span>
              ) : (
                <Link
                  className='underline hover:text-brand'
                  href={{
                    pathname: '/auth/signup',
                    query: router.query,
                  }}
                >
                  {t('labels.signUp')}
                </Link>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
