import type { IBulkSelectConfig } from '@/types/common'
import type { ITagData } from '@/types/organization'
import type {
  IAdminNotificationConfig,
  IBulkUpdateParams,
  INotification,
  INotificationActions,
  INotificationBucketConfig,
  INotificationEntity,
  INotificationStates,
  IUserNotificationConfig,
  IUserNotificationEvent,
} from '@/types/user'

export const checkAndFilterNotification = (
  notifications: INotification[],
  filters: any
): INotification[] => {
  const { type } = filters
  if (!type || !type?.length || type[0] === 'all') return notifications
  return notifications.filter((notification) => {
    if (typeof type === 'string') return notification.type === type
    return type.includes(notification.type)
  })
}

export const parseNotification = (
  notifications: INotification[],
  featureRequests: INotificationEntity[]
) => {
  return notifications?.map((notification: INotification) => {
    const featureRequest = featureRequests.find(
      (fr) => fr.id === notification.feature_request_id
    )
    return !featureRequest
      ? { ...notification }
      : {
          ...notification,
          feature_request: featureRequest,
          feature_request_slug: featureRequest.slug,
        }
  })
}

export const convertEventsToObjectOfObject = (
  roleConfiguration: INotificationBucketConfig
) => {
  const { events } = roleConfiguration
  const concattedArray = events?.post?.concat(events?.comment)
  return concattedArray?.reduce((acc, obj) => {
    acc[obj.event as keyof typeof obj] = obj
    return acc
  }, {} as { [key: string]: IUserNotificationEvent })
}

export const getConfiguredEvents = (
  roleConfiguration: INotificationBucketConfig
) => {
  const { events } = roleConfiguration
  const configuredEvents: string[] = []
  return Object.keys(events).reduce((acc, key) => {
    const eventsData = events[key as keyof typeof events]
    if (eventsData?.length) {
      eventsData.forEach((event: IUserNotificationEvent) => {
        if (event.enabled) acc.push(event.event)
      })
    }
    return acc
  }, configuredEvents)
}

export const bulkUpdateNotifications = (
  selection: IBulkSelectConfig,
  threadedView: boolean,
  bulkUpdateParams: IBulkUpdateParams,
  notifications: INotification[],
  action: INotificationActions,
  notificationType: INotificationStates
) => {
  const dataToUpdate: { seen?: boolean; status?: INotificationStates } = {}
  if (action === 'seen') {
    dataToUpdate.seen = bulkUpdateParams.read
  } else if (action === 'status') {
    dataToUpdate.status = bulkUpdateParams.status
  }
  const cloneNotifications = [...notifications]
  let updatedNotifications: INotification[] = cloneNotifications
  const { selectedAll, selectedIds, ignoredIds } = selection

  // Update notifications for threaded view
  if (threadedView) {
    if (dataToUpdate.seen || dataToUpdate.status) {
      if (selectedAll && !ignoredIds.length) {
        updatedNotifications = []
      }
      if (selectedAll && ignoredIds.length) {
        updatedNotifications = cloneNotifications.filter((notification) =>
          ignoredIds.includes(notification.feature_request_id)
        )
      }
      if (!selectedAll && selectedIds.length) {
        updatedNotifications = cloneNotifications.filter(
          (notification) =>
            !selectedIds.includes(notification.feature_request_id)
        )
      }
    }
  }

  // Update notifications for list view
  updatedNotifications = updatedNotifications
    .map((oldNotification) => {
      const { id } = oldNotification
      const shouldUpdate: boolean =
        (selectedAll && !ignoredIds.includes(id)) || selectedIds.includes(id)
      return shouldUpdate
        ? { ...oldNotification, ...dataToUpdate }
        : oldNotification
    })
    .filter((noti) => noti.status === notificationType?.toString())

  return updatedNotifications
}

export const getGlobalAndBoardTags = (
  configData: IUserNotificationConfig | IAdminNotificationConfig,
  selectedBoardConfig: INotificationBucketConfig,
  translation: string
) => {
  let concattedTags = [
    {
      name: translation,
      id: 'untagged',
      source_name: ' ',
    } as ITagData,
  ]
  const globalTags = configData?.tags?.filter((item) => !item.source_id)
  const boardTags = configData?.tags?.filter(
    (item) => item.source_id?.toString() === selectedBoardConfig?.id?.toString()
  )

  concattedTags = [...concattedTags, ...(globalTags || [])]
  concattedTags = [...concattedTags, ...(boardTags || [])]
  return concattedTags
}
