import { Chat, EyeClosed, Stack } from '@phosphor-icons/react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import React, { useContext, useEffect, useState } from 'react'

import DownvoteButton from '@/components/posts/DownvoteButton'
import Status from '@/components/posts/Status'
import UpvoteButton from '@/components/posts/UpvoteButton'
import Badge from '@/components/shared/ui/Badge'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import clsxm from '@/lib/clsxm'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { stateMerge } from '@/lib/helpers/appHelpers'
import { objectHasProperty } from '@/lib/helpers/dataHelpers'
import { pathCleaner, userSidePostSinglePath } from '@/lib/helpers/pathHelpers'
import { getBasePathPrefix } from '@/lib/helpers/urlHelpers'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
  className?: string
  hideVotesSection?: boolean
  hideInfoSection?: boolean
  openInNewTab?: boolean
  showDescriptionPlaceholder?: boolean
  actionComponent?: ReactNode
  disableVotes?: boolean
}

const metaClasses =
  'px-1 py-0.5 rounded-full duration-150 space-x-0.5 whitespace-nowrap text-xs text-gray11 flex items-center justify-start'

export default function MergePostItem({
  post: _post,
  hideVotesSection = false,
  actionComponent = null,
  disableVotes = false,
  showDescriptionPlaceholder = false,
  className,
}: IPropTypes) {
  const t = useTranslations()
  const router = useRouter()
  const [post, setPost] = useState<IPost>(_post)
  const {
    organizationSetting,
    embedType,
    organization,
    userProfile,
    buckets = [],
  } = useContext(HNContext)

  const bucket = buckets.find((bkt) => bkt.id === post.bucket_id)

  const handleUpdate = (newPost: IPost) => {
    stateMerge(newPost, setPost)
  }

  const renderStatus = () => {
    if (
      (userProfile?.is_csm_of_organization ||
        organizationSetting?.show_custom_states) &&
      objectHasProperty(post, 'custom_status')
    )
      return <Status status={post.custom_status} iconOnly />
    if (objectHasProperty(post, 'status'))
      return <Status status={post.status} iconOnly />
    return false
  }

  useEffect(() => {
    setPost(_post)
  }, [_post])

  return (
    <div
      itemProp='itemListElement'
      itemScope
      itemType='https://schema.org/ListItem'
      data-testid='user_post_item'
      className='group'
    >
      <div
        className={clsx(
          'relative -mx-2 flex w-full select-none items-start gap-3 rounded-md bg-snow px-2 py-3 font-medium duration-200 hover:bg-gray2 dark:bg-gray2 dark:hover:bg-gray5',
          className,
          !!actionComponent && 'items-end'
        )}
        data-testid={`user_post_item_${post.id}`}
      >
        <div
          className='relative mt-0 w-full flex-col items-start space-y-1 overflow-hidden'
          data-testid='user_post_item_link'
          onClick={() =>
            EventEmitter.dispatch('CONFIG_UPDATE', {
              actionType: EVENT_ACTION_TYPES.UPDATE,
              entity: ENTITIES.HEADER_BOARD_NAME,
              data: {
                data: { boardId: post.bucket_id },
              },
            })
          }
        >
          <div className='flex items-center justify-between'>
            <UnstyledLink
              target={'_blank'}
              params={{
                widgetKey: router.query?.widgetKey as string,
                JWTToken: router.query?.JWTToken as string,
                b: router.query.boardSlug as string,
              }}
              href={
                embedType === 'changelogWidget'
                  ? pathCleaner(
                      userSidePostSinglePath(
                        post.slug,
                        `${organization?.home_page}`
                      )
                    )
                  : userSidePostSinglePath(
                      post.slug,
                      getBasePathPrefix(embedType)
                    )
              }
            >
              <div className='flex items-center gap-1'>
                {renderStatus()}
                <h3 className='truncate text-base font-[450] text-gray12'>
                  {post.title}
                </h3>
              </div>
            </UnstyledLink>
            {post.hidden && (
              <div className='flex items-start justify-end'>
                <Badge
                  variant='outline'
                  rounded
                  size='xxs'
                  icon={<EyeClosed />}
                  value={t('posts.labels.hidden')}
                />
              </div>
            )}
          </div>
          <p className='line-clamp-2 text-[13px] !font-[400] text-gray11'>
            {post?.short_description}
          </p>
          {Boolean(
            showDescriptionPlaceholder && !post.short_description.length
          ) && (
            <p className='line-clamp-2 text-[13px] !font-[400] italic text-gray11'>
              {t('posts.labels.noDescription')}
            </p>
          )}
          <div className='flex items-center justify-between'>
            <div className='flex-start flex items-center gap-2'>
              {!hideVotesSection && (
                <div
                  className='space-1 flex min-w-[30px] shrink-0 gap-2'
                  data-testid='user_post_item_votes_section'
                >
                  <UpvoteButton
                    size={organizationSetting?.downvotes ? 'sm' : 'sm'}
                    post={post}
                    onUpdate={handleUpdate}
                    disabled={disableVotes}
                  />
                  <DownvoteButton
                    size='sm'
                    post={post}
                    onUpdate={handleUpdate}
                    disabled={disableVotes}
                  />
                </div>
              )}
              <div
                className='flex max-w-[100%] items-center gap-x-1 transition'
                data-testid='user_post_item_post_info'
              >
                <span
                  className={clsxm(
                    metaClasses,
                    'min-w-[10%] border border-gray5 px-2 py-0.5'
                  )}
                >
                  <Stack className='mr-0.5 h-3.5 w-3.5 shrink-0' size={14} />
                  <span
                    className='min-w-[10%] max-w-[100%] truncate font-normal text-gray11'
                    data-testid='user_post_info_bucket_display_name'
                    dangerouslySetInnerHTML={{
                      __html:
                        bucket?.display_name ||
                        post?.bucket?.display_name ||
                        '',
                    }}
                  />
                </span>
                <span
                  className={clsxm(
                    metaClasses,
                    'border border-gray5 px-2 py-0.5'
                  )}
                >
                  <Chat className='mr-0.5 h-3.5 w-3.5' size={14} />
                  <span
                    className='font-normal text-gray11'
                    data-testid='user_post_info_public_comment_count'
                  >
                    {post.comments_count || post.public_comments_count || 0}
                  </span>
                </span>
              </div>
            </div>
            <div className='flex-end flex hidden gap-2 group-hover:block'>
              {actionComponent && (
                <div className='inset-y-0 right-2 flex items-center'>
                  {actionComponent}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
