import { MagicWand, PenNib, Plus } from '@phosphor-icons/react'
import router from 'next/router'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
} from 'react'

import Badge from '@/components/shared/ui/Badge'
import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import Tooltip from '@/components/shared/ui/Tooltip'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import type { IOrganizationPlan } from '@/types/organization'

export interface INewChangelogButtonActions {
  toggleOpen: () => void
}

interface IProps {
  noTrigger?: boolean
}

const NewChangelogButton = forwardRef(
  ({ noTrigger }: IProps, ref: ForwardedRef<INewChangelogButtonActions>) => {
    const t = useTranslations()
    const { organizationSetting, organizationPlan } = useContext(HNContext)
    const [open, setOpen] = React.useState(false)

    const aiChangelogIsEnabled = useMemo(() => {
      return featureIsEnabled(
        'ai_changelog',
        organizationPlan as IOrganizationPlan
      )
    }, [organizationPlan])

    useImperativeHandle(ref, () => ({
      toggleOpen: () => {
        setOpen(!open)
      },
    }))

    return (
      <Dialog open={open} onOpenChange={setOpen}>
        {noTrigger ? null : (
          <DialogTrigger asChild>
            <Button variant='default' icon={<Plus weight='bold' />} size='xs'>
              {t('changelog.texts.addChangelog', {
                data: {
                  name: organizationSetting
                    ? organizationSetting.changelog_singular_name
                    : '',
                },
              })}
            </Button>
          </DialogTrigger>
        )}
        <DialogContent>
          <DialogTitle>
            {t('changelog.texts.addChangelog', {
              data: {
                name: organizationSetting
                  ? organizationSetting.changelog_singular_name
                  : '',
              },
            })}
          </DialogTitle>
          <DialogClose />
          <div className='flex flex-col gap-4 py-3'>
            <div className='flex flex-col gap-3 rounded-lg border border-gray7 p-3 shadow'>
              <div className='flex items-center justify-between'>
                <div className='flex flex-row space-x-2'>
                  <MagicWand size={20} />
                  <Typography.Text className='!text-base !text-carbon '>
                    {t('changelog.generateAI.trigger.title')}
                  </Typography.Text>
                </div>
                <Badge variant='blue' size='xs' value={'New'} />
              </div>

              <Typography.Text>
                {t('changelog.generateAI.trigger.description')}{' '}
              </Typography.Text>
              <div className='self-end'>
                <Tooltip
                  show={!aiChangelogIsEnabled}
                  text={t('changelog.generateAI.generate.planRestrictionText')}
                >
                  <Button
                    variant='primary'
                    size='xs'
                    onClick={() => {
                      router.push('/admin/changelog/generate')
                      return setOpen(false)
                    }}
                    disabled={!aiChangelogIsEnabled}
                  >
                    {t('buttons.getStarted')}
                  </Button>
                </Tooltip>
              </div>
            </div>

            <div className='flex flex-col gap-3 rounded-lg border border-gray7 p-3 shadow'>
              <div className='flex items-center space-x-2'>
                <PenNib size={20} />
                <Typography.Text className='!text-base !text-carbon '>
                  {t('changelog.manualChangelog.trigger.title')}
                </Typography.Text>
              </div>
              <Typography.Text>
                {t('changelog.manualChangelog.trigger.description')}
              </Typography.Text>
              <div className='self-end'>
                <Button
                  variant='primary'
                  size='xs'
                  onClick={() => {
                    router.push('/admin/changelog/new')
                    setOpen(false)
                  }}
                >
                  {t('changelog.texts.addChangelog', {
                    data: {
                      name: organizationSetting?.changelog_singular_name,
                    },
                  })}
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
)

NewChangelogButton.displayName = 'NewChangelogButton'

export default NewChangelogButton
