import React, { useContext } from 'react'

import HNContext from '@/context/HNContext'

interface WrapperProps {
  children?: React.ReactNode
}

export function EmbedNoShowWrapper({ children }: WrapperProps): JSX.Element {
  const { embedType } = useContext(HNContext)

  if (embedType === 'moduleEmbed') return <></>
  return <>{children}</>
}

export function EmbedShowWrapper({ children }: WrapperProps): JSX.Element {
  const { embedType } = useContext(HNContext)

  if (embedType !== 'moduleEmbed') return <></>
  return <div>{children}</div>
}

export function OrgEmbedShowWrapper({ children }: WrapperProps): JSX.Element {
  const { embedType } = useContext(HNContext)

  if (embedType !== 'orgEmbed') return <></>
  return <div>{children}</div>
}

export function OrgEmbedNoShowWrapper({ children }: WrapperProps): JSX.Element {
  const { embedType } = useContext(HNContext)

  if (embedType === 'orgEmbed') return <></>
  return <div>{children}</div>
}
