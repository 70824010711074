export const POST_FILTER_KEYS = [
  'bucket_id',
  'tag_id',
  'status',
  'state',
  'assignee_id',
  'user',
  'date_range',
  'domains',
  'viewed',
  'downvote_range',
  'upvote_range',
  'etc',
  'voted_by',
  'sentiment_type',
]

export const deletedAssigneeOrSubmitter = {
  id: null,
  name: '[Deleted Account]',
  email: 'deleted_account@example.com',
  profile_picture: { url: null },
  confirmed: true,
  starred: false,
  user_labels: [],
}

export const POST_SEARCH_ATTRIBUTES_TO_RETRIVE = [
  'title',
  'description',
  'id',
  'organization_id',
  'slug',
  'bucket_id',
  'submitter_id',
  'hidden',
  'status',
  'approval_status',
  'source',
  'source_info',
  'discarded_at',
  'description_summary',
  'type',
  'public_comments_count',
  'admin_comments_count',
  'internal_comments_count',
  'votes_count',
  'downvotes_count',
  'tags',
  'tag_ids',
  'assignee_id',
  'bucket',
  'submitter',
  'custom_status',
  'created_at',
  'merged_with_id',
  'linked_to_changelog',
  'post_status',
  'sentiment_type',
  'sentiment_score',
  'keywords',
  'numeric_created_at',
  'numeric_etc_date',
  'priority',
]

export const POST_SORT_OPTIONS = [
  { key: 'top', group: 'votes' },
  { key: 'least_upvoted', group: 'votes' },
  { key: 'most_downvoted', group: 'downvotes' },
  { key: 'least_downvoted', group: 'downvotes' },
  { key: 'latest', group: 'creationTime' },
  { key: 'oldest_created_at', group: 'creationTime' },
  { key: 'oldest_completed_at', group: 'completionTime' },
  { key: 'latest_completed_at', group: 'completionTime' },
  { key: 'latest_updated_at', group: 'updationTime' },
  { key: 'oldest_updated_at', group: 'updationTime' },
  { key: 'oldest_etc', group: 'etc' },
  { key: 'latest_etc', group: 'etc' },
  { key: 'status_asc', group: 'status' },
  { key: 'status_desc', group: 'status' },
  { key: 'sentiment_asc', group: 'sentiment' },
  { key: 'sentiment_desc', group: 'sentiment' },
  { key: 'priority_asc', group: 'priority' },
  { key: 'priority_desc', group: 'priority' },
]
