import { MagnifyingGlass } from '@phosphor-icons/react'
import type { ForwardedRef, RefObject } from 'react'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { useSearchClient } from '@/context/HNSearchContext'
import type { ISearchIndex, ISearchResponse } from '@/types/search'

import Input from '../ui/Input'
import type { InputProps } from '../ui/Input/Input'

export interface ISearchInputRef {
  refresh: () => void
}
interface IPropTypes {
  indexes?: ISearchIndex[]
  onSearch?: (results: ISearchResponse[], query?: string) => any
  hitsPerPage?: number
  initialQuery?: string
  offset?: number
  minCharactersToSearch?: number
  inputProps?: InputProps
  className?: string
  page?: number
  searchHandler?: (query: string) => Promise<any>
  clearOnSelect?: boolean
  inputRef?: RefObject<any>
  onSearchState?: (state: boolean) => any
  hideIcon?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  autoFocus?: boolean
  borderless?: boolean
  onInit?: (query: string) => void
}

const DEBOUNCE_TIMER = 500

const SearchInput = forwardRef(
  (props: IPropTypes, ref: ForwardedRef<ISearchInputRef>) => {
    const {
      indexes,
      hitsPerPage,
      offset,
      inputProps,
      minCharactersToSearch = 2,
      initialQuery = '',
      onSearch,
      className,
      page,
      searchHandler,
      clearOnSelect,
      inputRef,
      onSearchState,
      hideIcon = false,
      onChange,
      value,
      autoFocus = false,
      borderless = false,
      onInit,
    } = props
    const { searchClient } = useSearchClient()
    const debounceRef = useRef<NodeJS.Timeout | null>(null)
    const [searchQuery, setSearchQuery] = useState<string>(
      initialQuery || value || ''
    )
    const [loading, setLoading] = useState(false)
    const searchFunc = (query: string): Promise<any>[] => {
      if (!indexes || !indexes.length) return []
      setLoading(true)
      const currentOffset = searchQuery !== query ? 0 : offset
      return indexes.map((index: ISearchIndex): Promise<any> => {
        return searchClient
          .index(index.indexName)
          .search(query, {
            filter: index.filters,
            sort: index.sort || undefined,
            limit: hitsPerPage,
            offset: currentOffset,
            attributesToRetrieve: index.attributesToRetrieve,
          })
          .then(
            (data): ISearchResponse => ({
              ...data,
              hits: data.hits,
              label: index.label,
              indexName: index.indexName,
            })
          )
          .finally(() => setLoading(false))
      })
    }

    const inComponentSearchHandler = (query: string) => {
      if (searchHandler) {
        if (onSearchState) onSearchState(true)
        return searchHandler(query)
          .then((data) => {
            if (!onSearch) return data

            return onSearch([
              {
                hits: data,
                label: 'Search Results',
                indexName: 'search',
                limit: 0,
                offset: 0,
                processingTimeMs: 0,
                query,
                estimatedTotalHits: 0,
              },
            ])
          })
          .finally(() => {
            if (onSearchState) onSearchState(false)
          })
      }
      setSearchQuery(query)
      if (query && query.length >= minCharactersToSearch) {
        const promises = searchFunc(query)
        if (onSearchState) onSearchState(true)
        return Promise.all(promises)
          .then((results) => {
            if (onSearch) onSearch(results, searchQuery)
          })
          .finally(() => {
            if (onSearchState) onSearchState(false)
          })
      }
      return Promise.resolve()
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) onChange(e)
      const query: string = e.target.value.trim()
      onInit?.(query)
      if ((!query || !query.length) && onSearch) {
        setSearchQuery('')
        onSearch([], searchQuery)
      } else {
        if (debounceRef.current) clearTimeout(debounceRef.current)
        debounceRef.current = setTimeout(
          () => inComponentSearchHandler(query),
          DEBOUNCE_TIMER
        )
      }
    }

    const handleClear = () => {
      if (clearOnSelect) {
        setSearchQuery('')
        if (onSearch) onSearch([], searchQuery)
      }
    }

    useImperativeHandle(
      ref,
      (): ISearchInputRef => ({
        refresh: () => {
          inComponentSearchHandler(searchQuery)
        },
      })
    )

    useEffect(() => {
      inComponentSearchHandler(searchQuery)
    }, [page])

    useEffect(() => {
      if (inputRef?.current?.value === '') {
        if (onSearch) onSearch([], searchQuery)
      }
    }, [inputRef?.current?.value])

    return (
      <div className={className}>
        <Input
          borderless={borderless}
          inputRef={inputRef || null}
          loading={loading}
          size={inputProps?.size || 'xs'}
          rounded='md'
          prefixicon={!hideIcon && <MagnifyingGlass weight='bold' />}
          onChange={handleChange}
          clear={clearOnSelect}
          onClear={() => handleClear()}
          autoFocus={autoFocus}
          defaultValue={initialQuery || ''}
          className='h-[auto]'
          {...inputProps}
        />
      </div>
    )
  }
)

SearchInput.displayName = 'SearchInput'
export default SearchInput
