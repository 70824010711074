import { Circle, LockSimple, Plus } from '@phosphor-icons/react'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectPropTypes,
} from '@/components/shared/ui/Select/Select'
import { STATUS_COLORS } from '@/config/module/colorConstant'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  sortArrayOfObjectAlphabatic,
  sortObjectWithoutEmoji,
} from '@/lib/helpers/dataHelpers'
import { getLocalTags, tagList } from '@/models/Tag'
import type { IBoardDetails } from '@/types/board'
import type { IFilters } from '@/types/common'
import type { ITagData } from '@/types/organization'
import type { IPostListAPIParams } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropType {
  onChange?: (key: string, value: any) => void
  filters: IFilters | IPostListAPIParams
  multiple?: boolean
  placeholder?: string
  disabled?: boolean
  clearable?: boolean
  behaviour: string
  showSelectedOptionsCount?: boolean
  creatable?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  selectProps?: Partial<ISelectPropTypes>
  itemFilter?: (item: ITagData) => boolean
  showBoardTags?: boolean
  loadFromCache?: boolean
  renderTrigger?: (props: ISelectPropTypes) => JSX.Element
}

const FILTER_KEY = 'tag_id'

export default function AdminPostTagsFilter({
  onChange,
  filters,
  multiple = true,
  placeholder = '',
  disabled = false,
  clearable = false,
  behaviour = 'filter',
  size = 'xs',
  showSelectedOptionsCount = true,
  creatable = false,
  selectProps = {},
  itemFilter,
  showBoardTags,
  loadFromCache,
  renderTrigger,
}: IPropType) {
  const {
    tags: globalTags,
    isAdmin = false,
    updateContext,
    buckets,
  } = useContext(HNContext)
  const [boardTags, setBoardTags] = useState<ITagData[]>([])
  const [loading, setLoading] = useState(false)
  const t = useTranslations()

  const cleanTags = (_tags: ITagData[]) =>
    _tags?.map((tag) => ({
      ...tag,
      label: tag.name,
      value: tag.id,
      icon: (
        <Circle
          weight='fill'
          className='h-3 w-3'
          color={STATUS_COLORS?.find((c) => c.value === tag.color)?.hex}
        />
      ),
      sourceId: tag.source_id,
      endIcon: tag.private ? (
        <LockSimple className='h-3 w-3 text-gray10' />
      ) : undefined,
      group:
        tag?.source_type === 'bucket'
          ? tag.source_name
          : t('common.labels.globalTags'),
    }))

  const cleanTagsData = (tags: ITagData[]) => {
    if (behaviour === 'filter' && clearable) {
      return sortArrayOfObjectAlphabatic(cleanTags(tags), 'label')
    }
    return sortArrayOfObjectAlphabatic(
      cleanTags(tags?.filter((tg) => tg.id !== 'untagged')),
      'label'
    )
  }
  const globalTagsMemo = useMemo(
    () => cleanTagsData(globalTags || [])?.filter(itemFilter || (() => true)),
    [globalTags, filters?.tag_id]
  )

  const boardTagsMemo = useMemo(
    () =>
      cleanTagsData(boardTags || [])
        ?.filter(itemFilter || (() => true))
        ?.filter((tg: any) =>
          filters?.bucket_id
            ? tg.sourceId?.toString() === filters?.bucket_id.toString()
            : true
        )
        .filter(itemFilter || (() => true)),
    [boardTags, filters?.tag_id, filters?.bucket_id]
  )

  const handleChange = (value: ISelectOption | ISelectOption[] | undefined) => {
    if (!onChange) return null
    if (!value) {
      return onChange(FILTER_KEY, [])
    }
    // @ts-ignore
    if (value?.isNew) return onChange(FILTER_KEY, value)
    const selectedValue = Array.isArray(value) ? value : [value]
    const selectedTag = selectedValue?.map((item) => item.value)
    return onChange(FILTER_KEY, selectedTag)
  }

  const checkAndUpdateBoardTags = (boardsTags: ITagData[]) => {
    if (!buckets) return
    const bucketsData = [...buckets]
    const bucketIndex = bucketsData.findIndex(
      (b) => b.id === filters?.bucket_id
    )
    if (bucketIndex > -1)
      bucketsData[bucketIndex] = {
        ...bucketsData[bucketIndex],
        tags: boardsTags,
      } as IBoardDetails
    updateContext?.({ buckets: bucketsData })
  }

  const fetchBoardTags = () => {
    const queryParams = {
      source_type: 'bucket',
      no_pagination: true,
      source_id: filters?.bucket_id || undefined,
      isAdmin,
    }
    if (loadFromCache) {
      const localTags = getLocalTags(queryParams)
      if (localTags.length) {
        setBoardTags(localTags)
        return Promise.resolve()
      }
    }
    setLoading(true)
    return tagList(queryParams)
      .then((response) => {
        setBoardTags(response)
        checkAndUpdateBoardTags(response)
      })
      .catch((err) => toaster.success({ message: err.message }))
      .finally(() => setLoading(false))
  }

  const value = useMemo(() => {
    if (filters && filters?.tag_id) {
      const filteredTagIds = Array.isArray(filters.tag_id)
        ? filters.tag_id.map((tg) => tg?.toString())
        : [filters.tag_id?.toString()]
      return [...boardTagsMemo, ...globalTagsMemo].filter((tg) => {
        return filteredTagIds.includes(tg?.value?.toString())
      })
    }
    return null
  }, [filters, boardTags])

  useEffect(() => {
    if (filters.bucket_id || showBoardTags) fetchBoardTags()
  }, [filters.bucket_id, showBoardTags])

  return (
    <>
      <Select
        {...selectProps}
        loading={loading}
        size={size}
        disabled={disabled}
        searchable
        value={value}
        onChange={handleChange}
        group
        clearable={clearable}
        options={sortObjectWithoutEmoji(
          [...boardTagsMemo, ...globalTagsMemo],
          'label'
        )}
        multiple={multiple}
        placeholderIcon={behaviour === 'filter' ? <></> : <Plus />}
        placeholder={placeholder.toString()}
        inputProps={{
          placeholder,
        }}
        showSelectedOptionsCount={showSelectedOptionsCount}
        creatable={creatable}
        dataTestId='admin_post_tag_filter'
        renderTrigger={renderTrigger}
      />
    </>
  )
}
