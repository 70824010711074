import { SYNC_SERVER_PATH } from '@/config/routes'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'

export interface ISyncConfig {
  orgDomain: string
  session: string
  channel: 'ResourceSyncChannel' | 'CustomerResourceSyncChannel'
}

const syncModelsType = {
  FeatureRequest: ENTITIES.POSTS,
  Tag: 'tag',
  User: 'user',
  Comment: ENTITIES.COMMENT,
  Roadmap: ENTITIES.ROADMAP,
  RoadmapItem: ENTITIES.ROADMAP_ITEM,
}

export interface ISyncResouceData {
  event: 'updation' | 'deletion' | 'creation' | 'configuration'
  resource_id: string
  resource_data: any
  resource_model: keyof typeof syncModelsType
}

const handleUpdate = (data: ISyncResouceData) => {
  const { resource_data, resource_model } = data
  const type = syncModelsType[resource_model]
  if (!type) return
  EventEmitter.dispatch('ENTITY_UPDATE', {
    entity: type,
    actionType: EVENT_ACTION_TYPES.UPDATE,
    data: {
      data: resource_data,
    },
  })
}

const handleDelete = (data: ISyncResouceData) => {
  try {
    const { resource_id, resource_model } = data
    const type = syncModelsType[resource_model]
    if (type) {
      EventEmitter.dispatch('ENTITY_UPDATE', {
        entity: type,
        actionType: EVENT_ACTION_TYPES.DELETE,
        data: {
          data: { id: resource_id },
        },
      })
    } else {
      console.warn('No model found for', resource_model)
    }
  } catch (err) {
    console.warn('Error while removing data via sync', err)
  }
}

const handleAdd = (data: ISyncResouceData) => {
  const { resource_data, resource_model } = data
  const type = syncModelsType[resource_model]

  if (type) {
    EventEmitter.dispatch('ENTITY_UPDATE', {
      entity: type,
      actionType: EVENT_ACTION_TYPES.ADD,
      data: {
        data: resource_data,
      },
    })
  } else {
    console.warn('No model found for', resource_model)
  }
}

export const syncInit = async (config: ISyncConfig) => {
  return import('actioncable').then((ActionCable) => {
    const headers = {
      'X-Organization': config.orgDomain,
      Authorization: `Bearer ${config.session}`,
      'ngrok-skip-browser-warning': 'true',
    }

    const cable = ActionCable.createConsumer(SYNC_SERVER_PATH)

    cable.connect()
    cable.subscriptions.create(
      {
        channel: config.channel,
        headers,
      },
      {
        connected: () => {},
        received: (data: ISyncResouceData) => {
          if (!data) return
          if (data.event === 'updation') {
            handleUpdate(data)
          }
          if (data.event === 'deletion') {
            handleDelete(data)
          }
          if (data.event === 'creation') {
            handleAdd(data)
          }
          if (data.event === 'configuration') {
            handleAdd(data)
          }
        },
      }
    )
    return cable.disconnect
  })
}
