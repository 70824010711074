import { Copy, Eye, EyeSlash, SpinnerGap, X } from '@phosphor-icons/react'
import { clsx } from 'clsx'
import type { ClipboardEvent, FC, ReactNode, Ref } from 'react'
import React, { useEffect, useState } from 'react'

import { useTranslations } from '@/hooks/useTranslations'
import { copyClipboard } from '@/lib/helpers/appHelpers'

import Button from '../Button'
import Label from '../Label'
import {
  errorTextVariants,
  helperTextVariants,
  iconContainerVariants,
  inputVariants,
  spinnerVariants,
} from './input.variants'

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  borderless?: boolean
  copy?: boolean
  copyTooltipText?: string
  defaultValue?: string | number
  descriptionText?: string
  disabled?: boolean
  readonly?: 'true' | 'false'
  inputRef?: Ref<any> | any
  name?: string
  label?: string
  maxLength?: number
  maxLengthText?: string
  minChartLength?: number
  minLengthText?: string
  prefixHelperText?: string
  prefixicon?: any
  rounded?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  suffixHelperText?: ReactNode
  suffixicon?: any
  type?: HTMLInputElement['type']
  autoComplete?: string
  autoFocus?: boolean
  clear?: boolean
  required?: boolean
  min?: number
  max?: number
  onClear?: () => void
  suffixOnClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  loading?: boolean
  className?: string
  selectToCopy?: boolean
  disableCopyAndPaste?: boolean
  dataTestId?: string
}

const Input: FC<InputProps> = ({
  autoComplete,
  borderless,
  autoFocus,
  copy,
  copyTooltipText,
  defaultValue,
  disabled = false,
  id,
  label,
  maxLength,
  maxLengthText,
  minChartLength = 0,
  minLengthText,
  name,
  onChange,
  placeholder,
  inputRef,
  prefixHelperText,
  prefixicon,
  rounded = 'md',
  size = 'sm',
  suffixHelperText,
  suffixicon,
  type = 'text',
  clear,
  value,
  required,
  onClear,
  loading = false,
  suffixOnClick,
  className,
  disableCopyAndPaste,
  dataTestId,
  ...props
}: InputProps) => {
  const [inputType, setInputType] = useState(type)
  const [characterCount, setCharacterCount] = useState({
    count: defaultValue ? defaultValue.toString().length : 0,
  })
  const t = useTranslations()
  const [inputText, setInputText] = useState(defaultValue?.toString())

  const inputClasses = [
    inputVariants({
      size,
      rounded:
        prefixHelperText || suffixHelperText || (copy && !suffixHelperText)
          ? 'lg'
          : rounded,
      borderless,
      hasPrefix: Boolean(prefixicon),
      hasSuffix: Boolean(suffixicon || type === 'password' || copy),
      hasHelperPrefix: Boolean(prefixHelperText),
      hasHelperSuffix: Boolean(suffixHelperText || copy),
      disabled,
    }),
  ]

  const iconLoaderClasses = [inputVariants({ hasSuffix: true })]
  if (loading)
    iconLoaderClasses.push(
      inputVariants({ hasSuffix: true, hasHelperSuffix: true })
    )

  if (type === 'password' && characterCount.count > 2)
    inputClasses.push(inputVariants({ hasSuffix: true }))

  const copyHandler = (_data: any) => {
    if (inputText || copy) {
      copyClipboard(inputText || '', copyTooltipText || t('buttons.copied'))
    }
  }

  const checkCharacterCount = (input: any) => {
    setCharacterCount(() => {
      if (input) {
        return { count: input.length }
      }
      return { count: 0 }
    })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      Boolean(maxLength && e.target.value?.length <= maxLength) ||
      !maxLength
    ) {
      setInputText(e.target.value)
      if (onChange) onChange(e)
    }
  }

  const handleSuffixOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (suffixOnClick) suffixOnClick(e)
  }

  const handlePasteText = (e: ClipboardEvent<HTMLInputElement>) => {
    if (disableCopyAndPaste) {
      e.preventDefault()
      return false
    }
    return true
  }

  useEffect(() => {
    checkCharacterCount(inputText)
  }, [inputText])

  useEffect(() => {
    setInputType(type)
  }, [type])

  useEffect(() => {
    if (value) setInputText(value.toString())
  }, [value])

  const renderLimits = () => {
    if (maxLength && characterCount.count >= maxLength && maxLengthText) {
      return (
        <span className={errorTextVariants({ type: 'maxLength', disabled })}>
          {maxLengthText}
        </span>
      )
    }
    if (characterCount.count <= minChartLength && minLengthText) {
      return (
        <span className={errorTextVariants({ type: 'minLength', disabled })}>
          {minLengthText}
        </span>
      )
    }
    return false
  }

  const renderShowPassword = () => {
    if (suffixicon && type !== 'password') {
      return (
        <span
          data-testid='ui_input_suffix_icon'
          className={iconContainerVariants({ position: 'suffix' })}
        >
          {suffixicon}
        </span>
      )
    }
    if (type === 'password' && characterCount.count > 2) {
      if (inputType === 'password') {
        return (
          <div
            className={iconContainerVariants({
              position: 'suffix',
              interactive: true,
            })}
          >
            <Button
              dataTestId='ui_input_password_toggle'
              as='span'
              variant='naked'
              size='xs'
              onClick={() => setInputType('text')}
              data-testid='ui_input_password_toggle'
            >
              <EyeSlash weight='bold' className='h-4 w-4' />
            </Button>
          </div>
        )
      }
      if (inputType === 'text') {
        return (
          <div
            className={iconContainerVariants({
              position: 'suffix',
              interactive: true,
            })}
          >
            <Button
              dataTestId='ui_input_password_toggle'
              as='span'
              variant='naked'
              size='xs'
              onClick={() => setInputType('password')}
            >
              <Eye weight='bold' className='h-4 w-4' />
            </Button>
          </div>
        )
      }
    }
    return false
  }

  const handleClearText = () => {
    if (onClear) onClear()
    setInputText('')
  }

  const renderClearIcon = () => {
    if (
      clear &&
      inputType !== 'password' &&
      inputText?.length &&
      inputRef?.current?.value.length
    )
      return (
        <div
          className={iconContainerVariants({
            position: 'suffix',
            interactive: true,
          })}
        >
          {loading ? (
            <SpinnerGap
              className={clsx('h-4 w-4', spinnerVariants({ loading: true }))}
            />
          ) : (
            <Button
              dataTestId='ui_input_clear_btn'
              variant='naked'
              onClick={handleClearText}
              className='hover:text-primary'
            >
              <X weight='bold' className='h-4 w-4' />
            </Button>
          )}
        </div>
      )
    return false
  }

  const renderInput = () => {
    return (
      <div className='relative flex w-full'>
        {prefixicon && (
          <span className={iconContainerVariants({ position: 'prefix' })}>
            {prefixicon}
          </span>
        )}
        <input
          data-testid={dataTestId}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={clsx(inputClasses.join(' '), className)}
          defaultValue={defaultValue}
          disabled={disabled}
          id={id || label}
          name={name}
          onChange={handleChange}
          maxLength={maxLength}
          placeholder={placeholder || ''}
          ref={inputRef}
          type={inputType}
          value={value === null ? '' : value?.toString()}
          required={required}
          onPaste={handlePasteText}
          {...props}
        />
        {copy && !suffixHelperText && type !== 'password' && (
          <span
            className={iconContainerVariants({
              position: 'suffix',
              interactive: true,
            })}
          >
            <button
              data-testid='ui_input_copy'
              className='hover:text-primary'
              onClick={(e) => copyHandler(e)}
            >
              <Copy weight='duotone' className='h-4 w-4' />
            </button>
          </span>
        )}
        {renderShowPassword()}
        {renderClearIcon()}
      </div>
    )
  }

  const renderHelper = () => {
    if (prefixHelperText && prefixHelperText.length && !prefixicon) {
      return (
        <div className='flex w-full'>
          <span
            data-testid='ui_input_prefix'
            className={helperTextVariants({
              position: 'prefix',
              size,
              disabled,
              borderless,
            })}
          >
            {prefixHelperText}
          </span>
          {renderInput()}
        </div>
      )
    }
    if (suffixHelperText && !suffixicon) {
      return (
        <div className='flex w-full'>
          {renderInput()}
          <button
            data-testid='input_suffix_text'
            className={helperTextVariants({
              position: 'suffix',
              size,
              disabled,
              loading,
              borderless,
            })}
            onClick={(e) => handleSuffixOnClick(e)}
          >
            {loading ? (
              <SpinnerGap
                className={clsx('h-4 w-4', spinnerVariants({ loading: true }))}
              />
            ) : (
              <>{suffixHelperText}</>
            )}
          </button>
        </div>
      )
    }
    return false
  }

  return (
    <div className='relative w-full space-y-1'>
      {label && <Label htmlfor={label}>{label}</Label>}
      <div className='relative'>
        {!suffixHelperText && !prefixHelperText && renderInput()}
        {renderHelper()}
      </div>
      {renderLimits()}
    </div>
  )
}

Input.displayName = 'Input'

export default Input
