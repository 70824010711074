import { SquareHalf } from '@phosphor-icons/react'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import SinglePostCustomFields from '@/components/posts/customFields/SinglePostCustomFields'
import PostInsights from '@/components/posts/singlePost/postInsights/PostInsights'
import PostMatchingKBArticles from '@/components/posts/singlePost/postInsights/PostMatchingKBArticles'
import SinglePostAttachments from '@/components/posts/singlePost/SinglePostAttachments'
import SinglePostTATSummary from '@/components/posts/tat/SinglePostTATSummary'
import type { IPropsActionType } from '@/components/shared/common/AsideActions'
import AsideActions from '@/components/shared/common/AsideActions'
import RichTextEditor from '@/components/shared/components/editor/RichTextEditor'
import HTMLViewer from '@/components/shared/components/HTMLViewer'
import SourceIcon from '@/components/shared/components/SourceIcon'
import Alert from '@/components/shared/ui/Alert/Alert'
import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import Typography from '@/components/shared/ui/Typography'
import SinglePostTab from '@/components/users/posts/SinglePostTab'
import { HNAdminPostContext } from '@/context/HNAdminPostContext'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { getNewPostMentions } from '@/lib/helpers/modules/postHelper'
import { articleSuggestionEnaledCheck } from '@/lib/helpers/organizationHelper'
import postStore from '@/stores/PostListStore'
import type { IBoardDetails } from '@/types/board'
import type { IEditorRef } from '@/types/editor'
import type {
  IIntegrations,
  IOrganizationData,
  IOrganizationPlan,
} from '@/types/organization'
import type { INewPost, IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

import PostAddonsList from './PostAddonsList'
import SinglePostActions from './SinglePostActions'

interface IPropTypes {
  post: IPost
  editing: boolean
  closeEdit: () => void
}

const SinglePostDetails = observer(
  ({ post, editing, closeEdit }: IPropTypes) => {
    const actionsRef = useRef<IPropsActionType>(null)
    const t = useTranslations()
    const router = useRouter()
    const {
      userProfile,
      organizationPlan,
      organization,
      buckets,
      embedType,
      integrations,
      isAdmin,
      show_knowledge_base_tab,
    } = useContext(HNContext) as {
      buckets: IBoardDetails[]
      organizationPlan: IOrganizationPlan
      userProfile: IUserProfile
      organization: IOrganizationData
      embedType: string
      integrations: IIntegrations
      isAdmin: boolean
      show_knowledge_base_tab: boolean
    }
    const [postData, setPostData] = useState<INewPost>({
      title: post.title,
      description_html: post.description_html,
    })
    const [submitting, setSubmitting] = useState<boolean>(false)
    const descriptionEditorRef = useRef<IEditorRef>(null)

    const isArticleSuggestionEnaled = useMemo(
      () =>
        articleSuggestionEnaledCheck(
          organizationPlan,
          organization,
          show_knowledge_base_tab
        ),
      [organizationPlan]
    )

    const handleChange = (key: string, value: string) => {
      setPostData((_prevData) => ({ ..._prevData, [key]: value }))
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement> | string) => {
      const payload = { ...postData }
      if (e && typeof e !== 'string') {
        e.preventDefault()
      } else if (e && typeof e === 'string') {
        payload.description_html = e
      }
      if (
        descriptionEditorRef.current &&
        !descriptionEditorRef.current?.isReadyToSubmit()
      ) {
        return toaster.info({
          message: t('messages.imagesUploading', { ignorePrefix: true }),
        })
      }
      setSubmitting(true)
      return postStore
        .edit(post.slug, payload, false)
        .then((data) => {
          const oldSlug = post.slug
          postStore.updateSinglePost(oldSlug, {
            ...data,
            oldSlug: post.oldSlug || post.slug,
          })
          closeEdit()
          toaster.success({ message: t('post.messages.edit.success') })
          if (oldSlug !== data.slug) {
            router.replace(`/admin/p/${data.slug}`, undefined, {
              shallow: true,
            })
          }
        })
        .catch((err) => {
          toaster.error({ message: err.message })
          setSubmitting(false)
        })
    }

    useEffect(() => {
      if (submitting) setSubmitting(false)
    }, [post])

    const renderMergePostAlert = () => {
      if (post && post.merged) {
        return (
          <Alert
            type='info'
            message={t('alerts.margedWith', {
              data: {
                title: `<b>${post?.parent_details?.title}</b>`,
              },
            })}
            actionText={'Go to parent post'}
            actionLink={post?.parent_details?.slug}
            target='_blank'
          />
        )
      }
      return false
    }

    const renderPostDetail = () => {
      if (editing) {
        return (
          <form onSubmit={handleSubmit}>
            <Input
              name='title'
              label='Title'
              borderless={false}
              defaultValue={post.title}
              disabled={submitting}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange('title', e.target.value.trim())
              }
              autoFocus
            />
            <div className='mt-2 space-y-1'>
              <Label>{'Description'}</Label>
              <RichTextEditor
                ref={descriptionEditorRef}
                height='medium'
                disabled={submitting}
                defaultValue={post.description_html}
                onSubmit={handleSubmit}
                onChange={(value) => handleChange('description_html', value)}
                mentionsConfig={[
                  {
                    indexName: 'FeatureRequest',
                    filter: getNewPostMentions(
                      userProfile,
                      buckets,
                      organization
                    ),
                  },
                ]}
                support={{
                  video: true,
                }}
                resource={{
                  type: 'FeatureRequest',
                  id: post.id,
                }}
              />
            </div>
            <div className='flex w-full justify-end gap-2 pt-2'>
              <Button
                size='xs'
                variant='text'
                disabled={submitting}
                onClick={closeEdit}
                type='button'
              >
                {t('buttons.cancel')}
              </Button>
              <Button size='xs' disabled={submitting} type='submit'>
                {t('buttons.update')}
              </Button>
            </div>
          </form>
        )
      }
      return (
        <div className='px-1'>
          <div className='flex items-center space-x-2'>
            <Typography.Title level={3} className='font-medium'>
              {post.title}
            </Typography.Title>{' '}
            {embedType !== 'postWidget' && (
              <span className='block lg:hidden'>
                <Button
                  variant='outline'
                  fab
                  size='xs'
                  className='absolute right-2 lg:right-14'
                  onClick={() => actionsRef?.current?.toggle?.()}
                >
                  <SquareHalf weight='duotone' className='h-4 w-4' />
                </Button>
              </span>
            )}
          </div>
          {post.source && (
            <div className='mt-1 flex items-center space-x-1 pb-3'>
              <SourceIcon
                source={post?.source}
                size={35}
                sourceLetterBoxEmail={
                  post?.source_info?.letterbox_email_address
                }
                sourceInfo={post?.source_info}
                showPill
              />
            </div>
          )}
          {isAdmin && <PostInsights />}
          <HTMLViewer html={post.description_html} />
        </div>
      )
    }

    return (
      <HNAdminPostContext.Provider
        value={{
          ...post,
          updateContext: () => {},
        }}
      >
        <div className='h-full overflow-y-auto'>
          <div className='mx-auto grid max-w-7xl justify-center overflow-x-hidden md:grid-cols-8 lg:grid-cols-12 '>
            <div className='relative mx-auto flex h-full w-full max-w-3xl flex-col gap-4 py-8 md:col-span-8 lg:col-span-8'>
              <div className='flex flex-col gap-4 px-4 '>
                {renderMergePostAlert()}
                {renderPostDetail()}
                <SinglePostCustomFields />
                {isArticleSuggestionEnaled && <PostMatchingKBArticles />}
                <SinglePostAttachments post={post} />
                {integrations?.can_manage_post_addons && (
                  <PostAddonsList post={post} />
                )}
                <SinglePostTATSummary post={post} />
              </div>
              <div>
                <SinglePostTab post={post} />
              </div>
            </div>
            {Boolean(embedType !== 'postWidget') && (
              <AsideActions
                ref={actionsRef}
                className='absolute p-2 px-8 pb-4 lg:static lg:col-span-4 lg:max-w-lg'
              >
                <SinglePostActions
                  post={post}
                  onCloseMenu={() => actionsRef?.current?.toggle?.()}
                />
              </AsideActions>
            )}
          </div>
        </div>
      </HNAdminPostContext.Provider>
    )
  }
)

export default SinglePostDetails
