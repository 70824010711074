import { PushPinSimple } from '@phosphor-icons/react'
import { type VariantProps, cva } from 'class-variance-authority'
import { clsx } from 'clsx'
import React from 'react'

const lineHeaderVariants = cva(
  'flex space-x-1 bg-snow text-xs font-bold uppercase tracking-wider transition dark:bg-[#1c1d1f]',
  {
    variants: {
      color: {
        indigo: 'text-indigo10',
        blue: 'text-blue10',
        pink: 'text-pink9',
        green: 'text-green9',
        red: 'text-red10',
        gray: 'text-gray10',
        default: 'text-primary',
        lightgray: 'text-gray8',
      },
      align: {
        start: 'pr-2',
        center: 'px-2',
        end: 'pl-2',
      },
    },
    defaultVariants: {
      color: 'gray',
      align: 'start',
    },
  }
)

const alignmentVariants = cva('relative flex', {
  variants: {
    align: {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
    },
  },
  defaultVariants: {
    align: 'start',
  },
})

const lineContainerVariants = cva(
  'absolute inset-0 flex items-center rounded',
  {
    variants: {
      align: {
        start: '',
        center: '',
        end: '',
      },
    },
  }
)

const lineVariants = cva('w-full border-t border-gray5 transition', {
  variants: {
    align: {
      start: '',
      center: '',
      end: '',
    },
  },
})

interface LineHeaderProps extends VariantProps<typeof lineHeaderVariants> {
  title?: string
  pinned?: boolean
  className?: string
  headerClassName?: string
  icon?: React.ReactNode
}

export default function LineHeader({
  className,
  headerClassName,
  title,
  color = 'gray',
  pinned,
  align = 'start',
  icon,
}: LineHeaderProps) {
  return (
    <div className={clsx('relative', className)}>
      <div className={lineContainerVariants({ align })} aria-hidden='true'>
        <div className={lineVariants({ align })} />
      </div>
      <div className={alignmentVariants({ align })}>
        <span
          className={clsx(
            lineHeaderVariants({ color, align }),
            headerClassName
          )}
        >
          {icon}
          {pinned && (
            <PushPinSimple className='mr-1 h-[14px] w-[14px]' weight='fill' />
          )}
          <span>{title || 'Hello!'}</span>
        </span>
      </div>
    </div>
  )
}
