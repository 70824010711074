import {
  Archive,
  AsteriskSimple,
  At,
  Bookmark,
  Browser,
  Chats,
  Cube,
  Gauge,
  GearFine,
  List,
  LockSimple,
  Palette,
  RssSimple,
  Tag,
  TextAa,
  Tray,
} from '@phosphor-icons/react'

import AISparkle from '@/components/shared/icons/AISparkle'
import { Pill } from '@/components/shared/ui/Pill/Pill'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import type { IOrganizationPlan } from '@/types/organization'
import type { INavigationItem } from '@/types/sidebar'

export const boardNavigation: INavigationItem[] = [
  {
    key: 'aiInsights',
    link: '/admin/home',
    anotherHref: ['/admin/home'],
    labelTransKey: 'common.insights',
    icon: <AISparkle className='h-4 w-4' />,
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
    customBadge: (
      <div>
        <Pill
          variant={'blue'}
          className='!rounded-lg !px-2 !py-0 font-mono text-xxs '
        >
          AI
        </Pill>
      </div>
    ),
  },
  {
    key: 'allPosts',
    link: '/admin',
    anotherHref: [
      '/admin',
      '/admin/[[...postStatusPage]]',
      '/admin/p/[postSlug]',
    ],
    labelTransKey: 'sidebar.items.allPosts',
    icon: <AsteriskSimple weight='regular' size={16} />,
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
  },
  {
    key: 'assignedToMe',
    link: '/admin/assigned',
    anotherHref: ['/admin/assigned/[[...postStatusPage]]'],
    icon: <At weight='regular' size={16} />,
    labelTransKey: 'sidebar.items.assignedToMe',
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
  },
  {
    key: 'teamInbox',
    link: '/admin/teaminbox',
    anotherHref: ['/admin/teaminbox/[[...postStatusPage]]'],
    labelTransKey: 'sidebar.items.teamInbox',
    icon: <Tray weight='regular' size={16} />,
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
  },
  {
    key: 'saved_filters',
    link: '/admin/filters',
    anotherHref: [
      '/admin/filters',
      '/admin/filters/[[...postStatusPage]]',
      '/admin/filters/[filterId]/[[...postStatusPage]]',
    ],
    labelTransKey: 'sidebar.items.viewAll',
    icon: <Bookmark weight='regular' size={16} />,
    group: 'saved_filters',
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
  },
  {
    key: 'postModeration',
    link: '/admin/moderation/posts',
    anotherHref: ['/admin/moderation/posts/[[...postModerationStatus]]'],
    labelTransKey: 'common.modules.posts',
    icon: <List weight='regular' size={16} />,
    group: 'moderation',
    countKey: 'pendingPostsCount',
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
    shouldShow: ({ organizationSetting }) =>
      organizationSetting?.post_moderation,
  },
  {
    key: 'commentModeration',
    link: '/admin/moderation/comments',
    labelTransKey: 'common.modules.comments',
    anotherHref: ['/admin/moderation/comments/[[...commentModerationTab]]'],
    icon: <Chats weight='regular' size={16} />,
    group: 'moderation',
    countKey: 'pendingCommentsCount',
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
    shouldShow: ({ organizationSetting }) =>
      organizationSetting?.comment_moderation,
  },
  {
    key: 'boardModule',
    link: '/admin/boards',
    labelTransKey: 'sidebar.items.boards',
    anotherHref: [
      '/admin/boards',
      '/admin/b/[boardSlug]/[[...singleBoardTabs]]',
    ],
    icon: <Cube weight='regular' size={16} />,
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
    group: 'boards',
    countKey: 'publicBoardsCount',
  },
  {
    key: 'privateBoardsModule',
    link: '/admin/boards/private-boards',
    labelTransKey: 'sidebar.items.privateBoards',
    anotherHref: ['/admin/boards/private-boards'],
    icon: <LockSimple weight='regular' size={16} />,
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
    group: 'boards',
    countKey: 'privateBoardsCount',
    shouldShow: ({ organizationPlan, privateBucketsCount }) =>
      featureIsEnabled('private_board', organizationPlan as IOrganizationPlan, {
        privateBucketsCount,
      }),
  },
  {
    key: 'archivedBoardModule',
    link: '/admin/boards/archived-boards',
    labelTransKey: 'sidebar.items.archivedBoards',
    anotherHref: ['/admin/boards/archived-boards'],
    icon: <Archive weight='regular' size={16} />,
    availableRoles: ['admin'],
    isExact: true,
    group: 'boards',
  },
  {
    key: 'globalTags',
    link: '/admin/boards/tags',
    labelTransKey: 'sidebar.items.globalTags',
    anotherHref: ['/admin/boards/tags'],
    icon: <Tag weight='regular' size={16} />,
    availableRoles: ['csm', 'admin', 'member'],
    isExact: true,
    group: 'boards',
  },
  {
    key: 'boardSettings',
    link: '/admin/boards/settings',
    labelTransKey: 'sidebar.items.boards_settings',
    anotherHref: ['/admin/boards/settings'],
    icon: <GearFine weight='regular' size={16} />,
    group: 'settings',
    availableRoles: ['admin'],
    isExact: true,
  },
  {
    key: 'customizationSettings',
    link: '/admin/boards/customization',
    labelTransKey: 'sidebar.items.boards_customization',
    anotherHref: ['/admin/boards/customization'],
    icon: <Palette weight='regular' size={16} />,
    group: 'settings',
    availableRoles: ['admin'],
    isExact: true,
  },
  {
    key: 'embedSettings',
    link: '/admin/boards/embeds',
    labelTransKey: 'sidebar.items.boards_embed',
    anotherHref: ['/admin/boards/embeds'],
    icon: <Browser weight='regular' size={16} />,
    group: 'settings',
    availableRoles: ['admin'],
    isExact: true,
  },
  {
    key: 'formCustomizationSettings',
    link: '/admin/boards/form-customization',
    labelTransKey: 'sidebar.items.boards_form_customization',
    anotherHref: ['/admin/boards/form-customization'],
    icon: <TextAa weight='regular' size={16} />,
    group: 'settings',
    availableRoles: ['admin', 'member'],
    isExact: true,
  },

  {
    key: 'recentActivity',
    link: '/admin/insights/activity',
    labelTransKey: 'sidebar.items.recentActivity',
    anotherHref: ['/admin/insights/activity'],
    icon: <RssSimple weight='regular' size={16} />,
    availableRoles: ['admin', 'member'],
    isExact: true,
  },
  {
    key: 'analytics',
    link: '/admin/insights',
    labelTransKey: 'sidebar.items.analytics',
    anotherHref: ['/admin/insights'],
    icon: <Gauge weight='regular' size={16} />,
    availableRoles: ['admin', 'member', 'csm'],
    isExact: true,
  },
]

export const boardNavigationGroups = {
  saved_filters: {
    label: 'Saved Filters',
    open: true,
  },
  moderation: {
    label: 'Moderation',
    open: true,
  },
  settings: {
    label: 'Settings',
    open: true,
  },
  boards: {
    label: 'Boards',
    open: true,
  },
}
