import React, { useEffect, useMemo, useState } from 'react'

import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
} from '@/components/shared/ui/Dialog/Dialog'
import { EventEmitter } from '@/lib/eventEmitter'

interface ImagePreviewerProps {
  url: string
  show: boolean
}

export default function ImagePreviewer() {
  const [url, setUrl] = useState<string | null>(null)
  const [show, setShow] = useState(false)

  const handleNewImagePreview = (data: ImagePreviewerProps) => {
    setShow(data.show)
    setUrl(data.url)
  }

  const fullURL = useMemo(() => {
    if (!url) return url
    if (url?.includes('/thumb-')) return url?.replace('/thumb-', '/')
    return url
  }, [url])

  useEffect(() => {
    EventEmitter.subscribe('OPEN_IMAGE', handleNewImagePreview)
    return () => {
      EventEmitter.unsubscribe('OPEN_IMAGE', handleNewImagePreview)
    }
  }, [])

  if (!fullURL || !url) return <></>
  return (
    <Dialog
      open={show}
      onOpen={() => setShow(true)}
      onClose={() => setShow(false)}
    >
      <DialogContent size='xl'>
        <img
          src={fullURL}
          className='mx-auto max-h-[80vh] w-auto rounded'
          alt={url}
        />
        <div className='absolute right-1 top-1'>
          <DialogClose />
        </div>
      </DialogContent>
    </Dialog>
  )
}
