import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'

import UpvoteButton from '@/components/posts/UpvoteButton'
import AvatarGroup from '@/components/shared/ui/AvatarGroup'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { isNullOrUndefined } from '@/lib/helpers/dataHelpers'
import { checkAndRemoveUser } from '@/lib/helpers/modules/postHelper'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'

import SinglePostUserListModal from '../SinglePostUserListModal'

interface IPropTypes {
  post: IPost
}

const SinglePostUpvoters = observer(({ post }: IPropTypes) => {
  const { userSignedIn, userProfile } = useContext(HNContext)
  const t = useTranslations('')
  const [open, setOpen] = useState(false)
  const [postData, setPostData] = useState<IPost>(post)

  const handleAvatarClick = () => {
    if (userSignedIn && userProfile?.is_member_of_organization) {
      setOpen(true)
    }
  }

  const handleRemoveUser = (removedUser: IUserProfile) => {
    const updatedPost = checkAndRemoveUser('upvote', postData, removedUser)
    setPostData((prev) => ({ ...prev, ...updatedPost }))
    postStore.updateSinglePost(postData.slug, { ...postData, ...updatedPost })
  }

  useEffect(() => setPostData(post), [post])

  return (
    <div
      className='grid h-7 grid-cols-12 items-center gap-4 truncate'
      data-testid='single_post_upvoters'
    >
      <Typography.Text className='col-span-6 truncate font-medium'>
        {t('post.labels.upvoters')}
      </Typography.Text>
      <span className='col-span-6 flex items-center space-x-2'>
        <UpvoteButton post={postData} size='sm' />
        {!isNullOrUndefined(post?.votes_count_number) && (
          <AvatarGroup
            limit={2}
            avatars={post?.upvoters}
            rounded
            handleClick={() => handleAvatarClick()}
            totalCounts={post?.votes_count_number || 0}
            dataTestId='single_post_upvoters_avatar_group'
            extras={
              post.votes_count_number && post.upvoters?.length
                ? Number(post.votes_count_number - 2)
                : 0
            }
          />
        )}
      </span>
      <SinglePostUserListModal
        post={postData}
        open={open}
        type='upvote'
        onClose={() => setOpen(false)}
        onRemove={(data: IUserProfile) => handleRemoveUser(data)}
      />
    </div>
  )
})

export default SinglePostUpvoters
