import Image from 'next/image'
import React, { useMemo } from 'react'

import Tooltip from '@/components/shared/ui/Tooltip'
import { settingsHelpLinks } from '@/config/staticURLs'
import { capitalize } from '@/lib/helpers/stringHelpers'
import type { IUserProfile } from '@/types/user'

interface IPropTypes {
  user: IUserProfile
  size?: number
}
export default function VoteSourceIcon({ user, size }: IPropTypes) {
  const details = useMemo(() => {
    if (!user || !user.source) return {}
    switch (user.source) {
      case 'intercom':
        return {
          icon: 'intercom-blue.svg',
          info_text: capitalize(user.source),
          link: user.source_url,
        }
      case 'zendesk':
        return {
          icon: 'zendesk.svg',
          info_text: capitalize(user.source),
          link: user.source_url,
        }
      case 'salesforce':
        return {
          icon: 'salesforce.svg',
          info_text: capitalize(user.source),
          link: user.source_url,
        }
      default:
        return {
          icon: null,
          info_text: capitalize(user.source as string),
          link: user.source_url,
        }
    }
  }, [user])

  if (!user || !user.source || user.source === 'hellonext') return <></>

  return (
    <Tooltip text={`Via ${details.info_text}`}>
      <a href={user.source_url} target='_blank' rel='noreferrer'>
        {details.icon ? (
          <Image
            src={`${settingsHelpLinks.cdnLogo}${details.icon}`}
            className='h-4 w-4'
            alt={details.info_text}
            height={size || 14}
            width={size || 14}
          />
        ) : (
          <span className='cursor-pointer rounded bg-green4 px-1.5 py-0.5 text-xs font-bold uppercase tracking-wide text-green11 hover:bg-green5 hover:text-green11 hover:no-underline'>
            {details.info_text}
          </span>
        )}
      </a>
    </Tooltip>
  )
}
