import { Info } from '@phosphor-icons/react'
import clsx from 'clsx'
import React from 'react'

import { useTranslations } from '@/hooks/useTranslations'

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '../ui/HoverCard/HoverCard'
import UnstyledLink from '../ui/Links/UnstyledLink'
import Typography from '../ui/Typography'

interface IPropTypes {
  text?: string
  href?: string
  learnMoreText?: string
  side?: 'left' | 'right' | 'top' | 'bottom'
  icon?: React.ReactNode
}

const iconClass =
  'ml-1 w-4 h-4 -mt-0.5 shrink-0 inline-flex text-gray-light/70  cursor-pointer'

export default function HelpIcon({
  text,
  href,
  side,
  learnMoreText,
  icon,
}: IPropTypes) {
  const t = useTranslations()

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        {!text && href ? (
          <UnstyledLink
            data-testid='helpicon'
            href={href}
            target='_blank'
            rel='noopener noreferrer'
          >
            {icon || <Info weight='duotone' className={iconClass} />}
          </UnstyledLink>
        ) : (
          <button type='button' tabIndex={-1}>
            {icon || <Info weight='fill' className={iconClass} />}
          </button>
        )}
      </HoverCardTrigger>
      <HoverCardContent side={side}>
        <div className='flex flex-col'>
          <Typography.Text className='prose break-normal !text-[13px] !text-gray12'>
            {text}
          </Typography.Text>
          {href && (
            <UnstyledLink
              className={clsx('!font-medium text-primary', text ? 'pt-2 ' : '')}
              href={href}
              target='_blank'
              rel='noopener noreferrer'
            >
              {learnMoreText || t('buttons.learnMore')} &rarr;
            </UnstyledLink>
          )}
        </div>
      </HoverCardContent>
    </HoverCard>
  )
}
