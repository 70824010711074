import clsx from 'clsx'

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx('animate-pulse bg-gray5 ', className)} {...props} />
  )
}

export default Skeleton
