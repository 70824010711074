import {
  Chats,
  Database,
  Ear,
  HandHeart,
  Question,
  ShieldCheck,
  ShieldChevron,
  ShieldStar,
} from '@phosphor-icons/react'
import getConfig from 'next/config'
import router from 'next/router'
import React, { useContext } from 'react'

import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownSeparator,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import Typography from '@/components/shared/ui/Typography'
import { APP_NAME, DOMAIN } from '@/config/appConstants'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { relativeDate } from '@/lib/helpers/dateHelpers'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import { redirectToHellonextSupport } from '@/lib/helpers/pathHelpers'
import { openContactChat } from '@/lib/utils'
import type { IOrganizationPlan } from '@/types/organization'

import { clearAllLocalStorage } from '../../../../lib/helpers/localStorageHelper'
import { clearIdxDBData } from '../../../../lib/localDB/localDB'

export default function AsideHelpPopover() {
  const t = useTranslations('sidebar')
  const { session, organization, organizationPlan } = useContext(HNContext)

  const { publicRuntimeConfig } = getConfig()
  const updatedDate = relativeDate(publicRuntimeConfig?.hellonextBuildTime)

  const getRedirectURL = () => {
    if (organization?.custom_domain_verified) {
      return `https://feedback.${DOMAIN}/auth/verifytoken?auth_token=${session}`
    }
    return `https://feedback.${DOMAIN}`
  }

  const handleClearCache = () => {
    clearAllLocalStorage('hn')
    clearIdxDBData().then(() => {
      router.reload()
    })
  }

  return (
    <Dropdown>
      <DropdownTrigger
        className='group my-0.5 flex w-full cursor-pointer items-center truncate rounded-lg !px-2.5 !py-1 text-left text-[13px] font-medium text-gray12 transition-all hover:bg-snow hover:text-gray12 hover:no-underline focus:z-50   dark:hover:bg-gray-dark-hover   '
        asChild={false}
      >
        <>
          <Question weight='fill' size={16} className='mr-2 text-gray11' />
          <Typography.Text
            className='text-left text-[13px] font-medium !text-gray12 '
            type='default'
          >
            {t('labels.helpAndFeedback')}
          </Typography.Text>
        </>
      </DropdownTrigger>
      <DropdownContent>
        <DropdownItem
          itemValue={t('labels.helpFeedback.helpCenter')}
          href={`https://help.${DOMAIN}`}
          target='_blank'
          icon={<Chats weight='fill' size={16} />}
        />
        {featureIsEnabled(
          'chat_support',
          organizationPlan as IOrganizationPlan
        ) ? (
          <DropdownItem
            onSelect={() => openContactChat()}
            icon={<HandHeart weight='fill' size={16} />}
            itemValue={t('labels.helpFeedback.chatSupport')}
          />
        ) : (
          <DropdownItem
            href={redirectToHellonextSupport()}
            icon={<ShieldStar weight='fill' size={16} />}
            target='_blank'
            itemValue={t('labels.helpFeedback.contactSupport')}
          />
        )}
        <DropdownItem
          href={getRedirectURL()}
          icon={<Ear weight='fill' size={16} />}
          target='_blank'
          itemValue={t('labels.helpFeedback.submitFeedback')}
        />
        <DropdownSeparator />
        <DropdownItem
          href={`https://${DOMAIN}/legal/privacy`}
          itemValue={t('labels.helpFeedback.privacyPolicy')}
          target='_blank'
          icon={<ShieldChevron weight='fill' size={16} />}
        />
        <DropdownItem
          href={`https://${DOMAIN}/legal/terms`}
          icon={<ShieldCheck weight='fill' size={16} />}
          target='_blank'
          itemValue={t('labels.helpFeedback.termsAndConditions')}
        />

        <DropdownSeparator />
        <DropdownItem
          itemValue={t('labels.helpFeedback.clearCache')}
          onSelect={handleClearCache}
          icon={<Database weight='fill' size={16} />}
        />

        {publicRuntimeConfig && updatedDate && (
          <>
            <DropdownSeparator />
            <div className='space-y-1 pl-2 pt-2 text-xs text-gray10'>
              {/* TODO i18n */}
              <p>
                {APP_NAME} {publicRuntimeConfig?.hellonextVersion}
              </p>
              <p>Updated {updatedDate}</p>
            </div>
          </>
        )}
      </DropdownContent>
    </Dropdown>
  )
}
