// TODO image click to open

import { EventEmitter } from '../eventEmitter'

export const handleImageClickEvent = (e: any) => {
  if (e?.target?.tagName === 'IMG') {
    const url = e?.target?.getAttribute('src')
    EventEmitter.dispatch('OPEN_IMAGE', { url, show: true })
  }
}

export const initializeImagePreviewEvent = (el: HTMLDivElement | null) => {
  if (el) {
    el.addEventListener('click', handleImageClickEvent)
  }
}

export const destroyImagePreviewEvent = (el: HTMLDivElement | null) => {
  if (el) {
    el.removeEventListener('click', handleImageClickEvent)
  }
}
