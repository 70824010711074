import { friendlyNumber } from '@/lib/helpers/stringHelpers'
import type {
  IRoadmap,
  IRoadmapItem,
  IRoadmapListFilters,
} from '@/types/roadmap'

export const humaizeScore = (score: IRoadmapItem['score']) => {
  return friendlyNumber(
    score?.aggregate ? Math.round((score?.aggregate || 0) * 100) / 100 : 0
  )
}

export const filterRoadmaps = (
  roadmaps: IRoadmap[],
  filters: IRoadmapListFilters
) => {
  let filteredRoadmaps = [...roadmaps]
  if (filters.status) {
    filteredRoadmaps = filteredRoadmaps.filter((roadmap: IRoadmap) =>
      filters.status?.includes(roadmap.status)
    )
  }
  return filteredRoadmaps
}

export const roadmapTransformer = (roadmap: IRoadmap) => ({
  ...roadmap,
  overdue: roadmap.end_date ? new Date(roadmap.end_date) < new Date() : false,
  closedToDue: false,
  percentage: Math.round(roadmap.percentage || 0),
  start_date: roadmap.start_date ? new Date(roadmap.start_date) : undefined,
  end_date: roadmap.end_date ? new Date(roadmap.end_date) : undefined,
})
