import { Pencil } from '@phosphor-icons/react'
import React, { useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Popover from '@/components/shared/ui/Popover'
import {
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/ui/Popover/Popover'
import {
  RICE_SCORES,
  VALUE_EFFORT_SCORES,
} from '@/config/module/roadmapConstants'
import { useTranslations } from '@/hooks/useTranslations'
import { isNullOrUndefined } from '@/lib/helpers/dataHelpers'
import type {
  IRoadmap,
  IRoadmapItem,
  IRoadmapItemScoresMap,
} from '@/types/roadmap'

interface IPropTypes {
  roadmap: {
    score_type: IRoadmap['score_type']
  }
  item: IRoadmapItem
  onUpdate: (data: IRoadmapItem) => void
  onSave: () => Promise<void>
  children?: React.ReactNode
  scoreToFocus?: string
}
export default function RoadmapItemScorePicker({
  roadmap,
  item,
  onUpdate,
  onSave,
  children,
  scoreToFocus,
}: IPropTypes) {
  const [open, setOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const t = useTranslations('roadmap.singleRoadmap')

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setSubmitting(true)
    onSave()
      .then(() => setOpen(false))
      .then(() => setSubmitting(false))
  }

  const renderScoreInput = (scoreItem: IRoadmapItemScoresMap) => (
    <Input
      key={scoreItem.name}
      autoFocus={scoreItem.name === scoreToFocus}
      label={t(`common.labels.scoreFields.${scoreItem.name.split('_')[0]}`, {
        ignorePrefix: true,
      })}
      value={
        (item.score && item.score[scoreItem.name]
          ? item.score[scoreItem.name]
          : 0) || 0
      }
      suffixHelperText={scoreItem.name === 'confidence' ? '%' : ''}
      type='number'
      step='0.01'
      min={scoreItem.min}
      max={scoreItem.max}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const val = parseFloat(e.target.value || '0')
        onUpdate({
          ...item,
          score: {
            ...item.score,
            [scoreItem.name]:
              !isNullOrUndefined(scoreItem.max) && val > (scoreItem.max || 0)
                ? scoreItem.max
                : val,
          },
        })
      }}
    />
  )

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger>
          <>{children || <Button as='span' icon={<Pencil />} fab />}</>
        </PopoverTrigger>
        <PopoverContent>
          <div>
            <form onSubmit={handleSubmit}>
              <div className='space-y-2'>
                {roadmap.score_type === 'value_vs_effort' &&
                  VALUE_EFFORT_SCORES.map(renderScoreInput)}

                {roadmap.score_type === 'rice' &&
                  RICE_SCORES.map(renderScoreInput)}
                <Button loading={submitting} className='w-full'>
                  {t('buttons.save', { ignorePrefix: true })}
                </Button>
              </div>
            </form>
          </div>
        </PopoverContent>
      </Popover>
    </>
  )
}
