import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'

import DownvoteButton from '@/components/posts/DownvoteButton'
import AvatarGroup from '@/components/shared/ui/AvatarGroup'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { isNullOrUndefined } from '@/lib/helpers/dataHelpers'
import { checkAndRemoveUser } from '@/lib/helpers/modules/postHelper'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'

import SinglePostUserListModal from '../SinglePostUserListModal'

interface IPropTypes {
  post: IPost
}

const SinglePostDownvoters = observer(({ post }: IPropTypes) => {
  const t = useTranslations('post')
  const { organizationSetting, userSignedIn, userProfile } =
    useContext(HNContext)
  const [open, setOpen] = useState<boolean>(false)
  const [postData, setPostData] = useState<IPost>(post)

  const handleAvatarClick = () => {
    if (userSignedIn && userProfile?.is_organization_member) {
      setOpen(true)
    }
  }

  const handleRemoveUser = (removedUser: IUserProfile) => {
    const updatedPost = checkAndRemoveUser('downvote', postData, removedUser)
    setPostData((prev) => ({ ...prev, ...updatedPost }))
    postStore.updateSinglePost(postData.slug, { ...postData, ...updatedPost })
  }

  useEffect(() => setPostData(post), [post])

  if (!organizationSetting?.downvotes) return <></>
  return (
    <div
      className='grid h-7 grid-cols-12 items-center gap-4 truncate'
      data-testid='single_post_downvoters'
    >
      <Typography.Text className='col-span-6 truncate font-medium'>
        {t('labels.downvoters')}
      </Typography.Text>
      <span className='col-span-6 flex items-center space-x-2'>
        <DownvoteButton post={postData} size='sm' />
        {!isNullOrUndefined(postData?.downvotes_count_number) && (
          <AvatarGroup
            limit={2}
            avatars={postData?.downvoters}
            rounded
            handleClick={() => handleAvatarClick()}
            totalCounts={postData?.downvotes_count_number || 0}
            dataTestId='single_post_upvoters_avatar_group'
            extras={Math.max(Number((post.downvotes_count_number || 0) - 2), 0)}
          />
        )}
      </span>
      <SinglePostUserListModal
        post={postData}
        open={open}
        type='downvote'
        onClose={() => setOpen(false)}
        onRemove={(data: IUserProfile) => handleRemoveUser(data)}
      />
    </div>
  )
})

export default SinglePostDownvoters
