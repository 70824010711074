import { MeiliSearch } from 'meilisearch'

const getKeys = () => ({
  meilieUrl: process.env.NEXT_PUBLIC_MEILIE_URL || '',
})

export const searchClient = (apiKey: string) =>
  new MeiliSearch({
    host: getKeys().meilieUrl,
    apiKey,
  })
