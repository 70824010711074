import Tooltip from '@/components/shared/ui/Tooltip'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { formatJSONTimestamp, relativeDate } from '@/lib/helpers/dateHelpers'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
}

export default function SinglePostPostedOn({ post }: IPropTypes) {
  const t = useTranslations('post')

  if (!post?.created_at) return null
  return (
    <div className='grid grid-cols-5 items-center gap-4 px-4 empty:hidden'>
      <Typography.Text className='col-span-2 font-medium'>
        {t('labels.PostedOn')}
      </Typography.Text>
      <span className='col-span-3'>
        {/* @ts-ignore */}
        <Tooltip
          text={formatJSONTimestamp(
            post?.created_at?.timestamp || post?.created_at,
            'PPpp'
          )}
        >
          <Typography.Text className='font-medium !text-gray12 dark:!text-gray-light'>
            {/* @ts-ignore */}
            {relativeDate(post?.created_at?.timestamp || post?.created_at)}
          </Typography.Text>
        </Tooltip>
      </span>
    </div>
  )
}
