import type { Editor } from '@tiptap/core'
import { Node, nodeInputRule } from '@tiptap/core'
import type { NodeSelection, Transaction } from '@tiptap/pm/state'
import {
  BubbleMenu,
  mergeAttributes,
  ReactNodeViewRenderer,
} from '@tiptap/react'

import {
  BUBBLE_ACTIVE_CLASS,
  BUBBLE_BUTTON_CLASS,
} from '@/components/shared/components/editor/editorConstants'
import Tooltip from '@/components/shared/ui/Tooltip'

import EditorImageComponent from './EditorImageComponent'
import type { UploadFn } from './uploadImagePlugin'
import { uploadImagePlugin } from './uploadImagePlugin'

interface ImageOptions {
  inline: boolean
  HTMLAttributes: Record<string, any>
  sizes: TImageSize[]
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    image: {
      setImage: (options: {
        src: string
        alt?: string
        title?: string
        'data-size'?: TImageSize
      }) => ReturnType

      setImageSize: (size: TImageSize) => ReturnType
    }
  }
}

type TImageSize = 'full' | 'medium' | 'small' | 'auto'

const IMAGE_INPUT_REGEX = /!\[(.+|:?)\]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/

export const CustomImagePlugin = (uploadFn: UploadFn) => {
  return Node.create<ImageOptions>({
    name: 'image',
    addOptions: () => ({
      inline: false,
      HTMLAttributes: {},
      sizes: ['full', 'medium', 'small', 'auto'],
    }),

    inline() {
      return this.options.inline || false
    },

    group() {
      return this.options.inline ? 'inline' : 'block'
    },

    draggable: true,

    addAttributes() {
      return {
        uploadId: {
          default: null,
        },
        src: {
          default: null,
        },
        alt: {
          default: null,
        },
        title: {
          default: null,
        },
        'data-size': {
          default: undefined,
        },
      }
    },
    parseHTML: () => [
      {
        tag: 'img[src]',
        getAttrs: (dom) => {
          if (typeof dom === 'string') return {}
          const element = dom as HTMLImageElement

          const obj = {
            src: element.getAttribute('src'),
            title: element.getAttribute('title'),
            alt: element.getAttribute('alt'),
            'data-size': element.getAttribute('data-size'),
          }
          return obj
        },
      },
    ],
    renderHTML: ({ HTMLAttributes }) => [
      'img',
      mergeAttributes(HTMLAttributes),
    ],

    addCommands() {
      return {
        setImage:
          (attrs) =>
          ({ state, dispatch }) => {
            const { selection } = state
            const position = selection.$head
              ? selection.$head.pos
              : selection.$to.pos

            const node = this.type.create(attrs)
            const transaction = state.tr.insert(position, node)
            return dispatch?.(transaction)
          },

        setImageSize:
          (size) =>
          ({ tr, dispatch }) => {
            // Check it's a valid size option
            if (!this.options.sizes.includes(size)) {
              return false
            }

            const { selection } = tr as Transaction

            const cNode = (selection as NodeSelection).node
            const options = {
              ...(cNode?.attrs || {}),
              'data-size': size,
            }

            const node = this.type.create(options)

            if (dispatch) {
              tr.replaceRangeWith(selection.from, selection.to, node)
            }
            return false
          },
      }
    },
    addInputRules() {
      return [
        nodeInputRule({
          find: IMAGE_INPUT_REGEX,
          type: this.type,
          getAttributes: (match) => {
            const [, alt, src, title] = match
            return {
              src,
              alt,
              title,
              'data-size': 'auto',
            }
          },
        }),
      ]
    },
    addProseMirrorPlugins() {
      return [uploadImagePlugin(uploadFn)]
    },
    addNodeView() {
      return ReactNodeViewRenderer(EditorImageComponent)
    },
  })
}

interface ImageBubbleMenuProps {
  editor: Editor
}

export const ImageBubbleMenu: React.FC<ImageBubbleMenuProps> = ({ editor }) => {
  const handleSizeUpdate = (size: TImageSize) => {
    editor.chain().focus().setImageSize(size).run()
  }

  return (
    <BubbleMenu
      className='absolute flex divide-x divide-gray5 overflow-hidden rounded-full border border-gray6 bg-snow px-2 py-0.5 shadow-sm focus:outline-none '
      tippyOptions={{
        duration: 100,
        maxWidth: 'none',
        popperOptions: { strategy: 'fixed' },
        placement: 'bottom',
      }}
      shouldShow={() => editor.isActive('image')}
      editor={editor}
    >
      <Tooltip asChild text={'Auto'}>
        <button
          type='button'
          onClick={() => handleSizeUpdate('auto')}
          className={`${
            editor.isActive('bold') ? BUBBLE_ACTIVE_CLASS : BUBBLE_BUTTON_CLASS
          }`}
        >
          <span>Auto</span>
        </button>
      </Tooltip>
      <Tooltip asChild text={'Full width'}>
        <button
          type='button'
          onClick={() => handleSizeUpdate('full')}
          className={`${
            editor.isActive('bold') ? BUBBLE_ACTIVE_CLASS : BUBBLE_BUTTON_CLASS
          }`}
        >
          <span>100%</span>
        </button>
      </Tooltip>
      <Tooltip asChild text={'Medium width'}>
        <button
          type='button'
          onClick={() => handleSizeUpdate('medium')}
          className={`${
            editor.isActive('bold') ? BUBBLE_ACTIVE_CLASS : BUBBLE_BUTTON_CLASS
          }`}
        >
          <span>75%</span>
        </button>
      </Tooltip>
      <Tooltip asChild text={'Small width'}>
        <button
          type='button'
          onClick={() => handleSizeUpdate('small')}
          className={`${
            editor.isActive('bold') ? BUBBLE_ACTIVE_CLASS : BUBBLE_BUTTON_CLASS
          }`}
        >
          <span>50%</span>
        </button>
      </Tooltip>
    </BubbleMenu>
  )
}
