import type { MeiliSearch, Meilisearch } from 'meilisearch'
import { makeAutoObservable, toJS } from 'mobx'

import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import { PAGINATION_LIMIT } from '@/config/appConstants'
import { POST_SEARCH_ATTRIBUTES_TO_RETRIVE } from '@/config/module/postsConstants'
import { SEARCH_INDEXES } from '@/context/HNSearchContext'
import { broadcastPostMessage } from '@/lib/channel'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { sentry } from '@/lib/helpers/appHelpers'
import { timezoneInMinutes } from '@/lib/helpers/dateHelpers'
import {
  cleanAndGetAppliedFilters,
  cleanPostFilters,
  postPrepProcessor,
  updateSearchedPosts,
} from '@/lib/helpers/modules/postHelper'
import {
  dbFilterToSearchFilter,
  dbFilterToSearchSort,
  sortAdminSearchedPosts,
} from '@/lib/helpers/modules/searchHelper'
import { setIdxDBData } from '@/lib/localDB/localDB'
import {
  addTags,
  deletePost,
  editAssignee,
  editPost,
  exportPosts,
  getAdminOptimizedPosts,
  getAdminPost,
  getMergedPosts,
  getPostFromDB,
  getPostIdsFromIndexDB,
  getPosts,
  getPostsCount,
  getVotesOfPosts,
  ratePost,
  readPost,
  removeAssignee,
  removeETCDate,
  removePostFromDB,
  removeTag,
  resetDownVotes,
  resetUpvotes,
  setPostIdsToIndexDB,
  setPostToDB,
  unmergePost,
  unReadPost,
  updateAdminPost,
  updateAdminPostStatus,
  votePost,
} from '@/models/Post'
import type {
  IBulkSelectConfig,
  IKeyValueMap,
  IKeyValuePair,
} from '@/types/common'
import type { ITagData } from '@/types/organization'
import type {
  INewPost,
  IPost,
  IPostListAPIParams,
  IVoteType,
} from '@/types/post'

import { removeKeyFromObject } from '../lib/helpers/dataHelpers'

export class PostListStore {
  controller = new AbortController()

  posts: IPost[] = []

  searchedPosts?: IPost[] = []

  loading: boolean = true

  errorMessage: string | null = null

  postLoading: boolean = true

  searchedPostLoading: boolean = true

  canFetchMore: boolean = false

  filters: IPostListAPIParams = {}

  globalFilters: IPostListAPIParams = {}

  selection: IBulkSelectConfig = {
    selectedIds: [],
    selectedAll: false,
    ignoredIds: [],
  }

  counts: any = {}

  isAdmin: boolean = false

  postNotFound: boolean = false

  searchClient: Meilisearch | null = null

  constructor(globalFilters?: IPostListAPIParams) {
    makeAutoObservable(this)
    if (globalFilters) {
      this.globalFilters = globalFilters
    }
  }

  setSearchClient = (client: MeiliSearch) => {
    this.searchClient = client
  }

  setCanFetchMore(canFetchMore: boolean) {
    this.canFetchMore = canFetchMore
  }

  setAdminFlag(isAdmin: boolean) {
    this.isAdmin = isAdmin
  }

  setGlobalFilters(filters: IPostListAPIParams) {
    this.globalFilters = filters
  }

  resetCounts() {
    this.counts = {}
  }

  updateFilters(
    filters: IPostListAPIParams,
    options?: {
      hardReset?: boolean
      skipReload?: true
    }
  ) {
    this.filters = options?.hardReset
      ? { ...filters, page: 1 }
      : { page: 1, ...this.filters, ...filters }
    if (!options?.skipReload) {
      // this.resetSelection()
      this.listPosts()
    }
  }

  handlePageUpdate = (incrementor: number) => {
    this.updateFilters({ page: (this.filters.page || 1) + incrementor })
  }

  resetSelection() {
    this.selection = {
      selectedIds: [],
      selectedAll: false,
      ignoredIds: [],
    }
  }

  checkPost(checked: boolean, postId: string) {
    const prevSelection = { ...this.selection }
    if (checked) {
      this.selection = {
        ...prevSelection,
        selectedIds: prevSelection.selectedAll
          ? prevSelection.selectedIds
          : [...prevSelection.selectedIds, postId],
        ignoredIds: !prevSelection.selectedAll
          ? prevSelection.ignoredIds
          : prevSelection.ignoredIds.filter((id) => id !== postId),
      }
    } else {
      this.selection = {
        ...prevSelection,
        selectedIds: prevSelection.selectedAll
          ? prevSelection.selectedIds
          : prevSelection.selectedIds.filter((id) => id !== postId),
        ignoredIds: !prevSelection.selectedAll
          ? prevSelection.ignoredIds
          : [...prevSelection.ignoredIds, postId],
      }
    }
  }

  updateTabCount(filter?: IPostListAPIParams) {
    if (this.filters.page !== 1) return
    const shouldRemoveKeys = ['status', 'approval_status']
    const filters = removeKeyFromObject(shouldRemoveKeys, {
      ...this.filters,
      ...this.globalFilters,
      ...filter,
      timezone: timezoneInMinutes(),
    })
    getPostsCount(filters).then((data) => {
      this.counts = data
    })
  }

  updateCounts(counts: any) {
    this.counts = { ...this.counts, ...counts }
  }

  searchPosts(appliedFilters: IKeyValueMap) {
    const { query, sort, ...restFilters } = appliedFilters
    if (!this.searchClient) return Promise.resolve()
    return this.searchClient
      .index<IPost>(SEARCH_INDEXES.FeatureRequest)
      .search<IPost>(query, {
        filter: dbFilterToSearchFilter(restFilters),
        sort: dbFilterToSearchSort(sort),
        attributesToHighlight: ['title', 'description'],
        attributesToRetrieve: POST_SEARCH_ATTRIBUTES_TO_RETRIVE,
        limit: PAGINATION_LIMIT.adminPostsList,
        offset:
          ((this.filters.page || 0) - 1) * PAGINATION_LIMIT.adminPostsList,
      })
      .then(({ hits, estimatedTotalHits }) => {
        const searchHits = sortAdminSearchedPosts(hits, sort)
        if (this.filters.page === 1) {
          this.posts = searchHits
        } else {
          this.posts = [...(this.posts || []), ...searchHits]
        }
        this.loading = false
        this.canFetchMore = estimatedTotalHits > this.posts?.length
        this.counts = {
          ...this.counts,
          search: estimatedTotalHits,
        }
        return hits
      })
  }

  listPosts() {
    this.errorMessage = null
    let appliedFilters = cleanPostFilters({
      ...this.filters,
      ...this.globalFilters,
      per_page: PAGINATION_LIMIT.adminPostsList,
      timezone: timezoneInMinutes(),
    })

    // Remove boardSlug from appliedFilters, post list don't need boardSlug query
    appliedFilters = removeKeyFromObject(['boardSlug'], appliedFilters)

    if (this.loading) {
      this.controller.abort()
      this.controller = new AbortController()
      this.loading = false
    }

    if (appliedFilters.query) {
      return this.searchPosts(appliedFilters)
    }

    if (this.filters.page === 1) {
      getPostIdsFromIndexDB(appliedFilters).then((localPosts) => {
        if (localPosts && localPosts.length) {
          this.posts = localPosts
        } else {
          this.posts = []
        }
      })
    }
    this.loading = true
    let promise
    if (this.isAdmin)
      promise = getAdminOptimizedPosts(appliedFilters, {
        signal: this.controller.signal,
      })
    else
      promise = getPosts(
        {
          ...this.filters,
          ...this.globalFilters,
        },
        {
          signal: this.controller.signal,
        }
      )
    return promise
      .then((newPosts: IPost[]) =>
        Promise.all(newPosts.map((post) => setPostToDB(post.slug, post)))
      )
      .then((newPosts: IPost[]) => newPosts.map(postPrepProcessor))
      .then((newPosts: IPost[]) => {
        if (this.filters.page === 1) {
          return setPostIdsToIndexDB(appliedFilters, newPosts).then(
            () => newPosts
          )
        }
        return newPosts
      })
      .then((newPosts: IPost[]) => {
        this.canFetchMore = newPosts.length >= PAGINATION_LIMIT.adminPostsList
        this.posts =
          this.filters?.page === 1 ? newPosts : [...this.posts, ...newPosts]
        this.loading = false
        if (this.filters.page === 1 && !this.filters.roadmap) {
          if (this.globalFilters.bucket_id?.length === 1) {
            return this.updateTabCount({ show_pending: true })
          }
          return this.updateTabCount()
        }

        return true
      })
      .then(() => {
        const postIds = this.posts.map((post) => post.id)
        const selectionIdsNotInPosts = this.selection.selectedIds.filter(
          (id) => !postIds.includes(id)
        )
        if (selectionIdsNotInPosts.length) {
          this.selection = {
            ...this.selection,
            selectedIds: this.selection.selectedIds.filter(
              (id) => !selectionIdsNotInPosts.includes(id)
            ),
          }
        }
      })
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          this.errorMessage = err.message
          this.loading = false
        }
      })
  }

  updateSinglePost(slug: string, newPost: IPost): IPost {
    const postIndex = this.posts
      .filter((p) => !!p)
      .findIndex((post) => post.slug === slug)

    let updatedPost: IPost | null | undefined = null
    if (postIndex > -1) {
      this.posts[postIndex] = { ...this.posts[postIndex], ...newPost }
      try {
        updatedPost = this.posts[postIndex]
        setIdxDBData('POSTS', newPost.id, updatedPost, 'id')
      } catch (err: any) {
        sentry.exception(
          new Error(`Unable to clone post message object ${err}`)
        )
      }
    } else {
      this.posts.unshift(newPost)
      updatedPost = newPost
      setIdxDBData('POSTS', newPost.id, newPost, 'id')
    }

    if (updatedPost) {
      EventEmitter.dispatch('ENTITY_UPDATE', {
        actionType: EVENT_ACTION_TYPES.UPDATE,
        entity: ENTITIES.POSTS,
        data: {
          id: updatedPost?.id,
          data: updatedPost,
        },
      })
    }

    // Update searchedPosts
    if (this.searchedPosts) {
      const searchedPostIndex = this.searchedPosts.findIndex(
        (post) => post.slug === slug
      )
      if (searchedPostIndex > -1) {
        this.searchedPosts[searchedPostIndex] = {
          ...this.searchedPosts[searchedPostIndex],
          ...newPost,
        }
        updatedPost = this.searchedPosts[searchedPostIndex]
      }
    }
    if (updatedPost) {
      setIdxDBData('POSTS', updatedPost.id, updatedPost, 'id')
      broadcastPostMessage('ENTITY_UPDATE', {
        entity: ENTITIES.POSTS,
        actionType: EVENT_ACTION_TYPES.UPDATE,
        data: {
          id: newPost?.id,
          data: toJS(this.posts[postIndex]),
        },
      })
    }
    return newPost
  }

  removeSinglePost(slug: string) {
    this.posts = this.posts.filter((post) => post.slug !== slug)
    return removePostFromDB(slug)
  }

  exportPosts(_filters?: IPostListAPIParams) {
    const filters = _filters || {
      ...this.filters,
      ...this.globalFilters,
    }
    return exportPosts(filters)
  }

  getPost(slug: string) {
    this.postNotFound = false
    return getPostFromDB(slug)
      .then((postFromDB) => {
        if (postFromDB) {
          this.updateSinglePost(postFromDB.slug, postFromDB)
        }
        return getAdminPost(slug)
      })
      .then((newPost: IPost) =>
        setPostToDB(newPost.slug, { ...newPost, fullData: true })
      )
      .then((newPost: IPost) => postPrepProcessor(newPost))
      .then((newPost: IPost) => this.updateSinglePost(newPost.slug, newPost))
      .catch((err) => {
        if (err.status === 404) this.postNotFound = true
        return this.removeSinglePost(slug).then(() => {
          throw err
        })
      })
  }

  updateSinglePostWithId(id: string, newPost: IPost, type: string = 'update') {
    const postIndex = this.posts.findIndex(
      (post) => post?.id?.toString() === id.toString()
    )
    if (type === EVENT_ACTION_TYPES.UPDATE) {
      const currentPost = this.posts[postIndex]
      const updatedPost = { ...currentPost, ...newPost }
      this.posts[postIndex] = postPrepProcessor(updatedPost)
    }
    if (type === EVENT_ACTION_TYPES.ADD && postIndex === -1)
      this.posts.unshift(postPrepProcessor(newPost))
    if (type === EVENT_ACTION_TYPES.DELETE) this.posts.splice(postIndex, 1)
  }

  deleteSinglePost(slug: string, newPost: IPost): IPost {
    const postIndex = this.posts.findIndex((post) => post.slug === slug)
    removePostFromDB(slug)
    this.posts.splice(postIndex, 1)
    try {
      broadcastPostMessage('ENTITY_UPDATE', {
        entity: ENTITIES.POSTS,
        actionType: EVENT_ACTION_TYPES.DELETE,
        data: {
          id: newPost?.id,
          data: postPrepProcessor(newPost),
        },
      })
    } catch (err: any) {
      console.error('Unable to clone post message object while deleting post')
      // sentry.exception(new Error(`Unable to clone post message object ${err}`))
    }
    return newPost
  }

  appendPost(incomingPost: IPost, noNotify: boolean = false) {
    if (!incomingPost) return
    const postIndex = this.posts.findIndex(
      (_post) => _post?.id?.toString() === incomingPost?.id?.toString()
    )
    if (postIndex < 0) {
      this.posts = [
        { ...incomingPost, ...postPrepProcessor(incomingPost) },
        ...this.posts,
      ]
      if (!noNotify) {
        try {
          broadcastPostMessage('ENTITY_UPDATE', {
            entity: ENTITIES.POSTS,
            actionType: EVENT_ACTION_TYPES.ADD,
            data: {
              id: incomingPost?.id,
              data: toJS(postPrepProcessor(incomingPost)),
            },
          })
        } catch (err: any) {
          sentry.exception(
            new Error(`Unable to clone post message object ${err}`)
          )
        }
      }
    } else {
      this.posts[postIndex] = {
        ...incomingPost,
        ...postPrepProcessor(incomingPost),
      }
    }
  }

  updatePostStatus(slug: string, data: any): Promise<IPost> {
    const currentData = this.dbAndSearchedPosts.find(
      (post) => post.slug === slug
    )

    if (!currentData) {
      return updateAdminPostStatus(slug, data)
    }
    return updateAdminPostStatus(slug, data)
      .then((newData) => {
        return this.updateSinglePost(slug, newData)
      })
      .catch((err) => {
        this.updateSinglePost(slug, currentData)
        throw err
      })
  }

  updatePost(slug: string, data: any, updatedPost?: IPost): Promise<IPost> {
    const currentData = this.dbAndSearchedPosts.find(
      (post) => post.slug === slug
    )
    if (updatedPost) {
      this.updateSinglePost(slug, updatedPost)
    }
    if (!currentData) {
      return updateAdminPost(slug, data)
    }
    return updateAdminPost(slug, data)
      .then((newPost: IPost) => {
        if (!updatedPost) this.updateSinglePost(slug, newPost)
        return newPost
      })
      .catch((err) => {
        this.updateSinglePost(slug, currentData)
        throw err
      })
  }

  updatePriorityRating(slug: string, data: any) {
    return ratePost(slug, data).then((newPost: IPost) =>
      this.updateSinglePost(slug, newPost)
    )
  }

  vote(slug: string, _type: IVoteType, isAdmin: boolean) {
    return votePost(slug, _type, isAdmin).then((newPost: IPost) =>
      this.updateSinglePost(slug, newPost)
    )
  }

  removeETC(slug: string, updatedPost: IPost): Promise<IPost> {
    const currentData = this.dbAndSearchedPosts.find(
      (post) => post.slug === slug
    )
    if (updatedPost) {
      this.updateSinglePost(slug, updatedPost)
    }
    if (!currentData) return removeETCDate(slug)
    return removeETCDate(slug).catch((err) => {
      this.updateSinglePost(slug, currentData)
      throw err
    })
  }

  // Assignee
  updateAssignee(
    slug: string,
    data: any,
    type: string,
    updatedPost?: IPost
  ): Promise<IPost | null> {
    let promise
    const currentData = this.dbAndSearchedPosts.find(
      (post) => post.slug === slug
    )
    if (updatedPost) {
      this.updateSinglePost(slug, updatedPost)
    }
    if (type === 'update') promise = editAssignee(slug, data)
    if (type === 'delete') promise = removeAssignee(slug, data.assignee_id)
    if (!currentData) return promise as Promise<IPost>
    if (promise) {
      return promise.catch((err) => {
        this.updateSinglePost(slug, currentData)
        throw err
      })
    }
    return Promise.resolve(null)
  }

  // Tags
  addTagToPost(
    slug: string,
    tag: IKeyValuePair,
    updatedPost: IPost | null
  ): Promise<IPost> {
    const currentData = this.dbAndSearchedPosts.find(
      (post) => post.slug === slug
    )
    if (updatedPost) {
      this.updateSinglePost(slug, updatedPost)
      this.posts = this.posts.map(postPrepProcessor)
      this.searchedPosts = this.searchedPosts?.map(postPrepProcessor)
    }
    if (!currentData) return addTags(slug, tag)
    return addTags(slug, tag)
      .then((newPost: IPost) => newPost)
      .catch((err) => {
        this.updateSinglePost(slug, currentData)
        throw err
      })
  }

  deleteTag(
    slug: string,
    tag: ISelectOption | ITagData,
    updatedPost: IPost
  ): Promise<IPost> {
    const currentData = this.dbAndSearchedPosts.find(
      (post) => post.slug === slug
    )
    if (updatedPost) {
      this.updateSinglePost(slug, updatedPost)
      this.posts = this.posts.map(postPrepProcessor)
      this.searchedPosts = this.searchedPosts?.map(postPrepProcessor)
    }
    if (!currentData) return removeTag(slug, { tag_id: tag.id })
    return removeTag(slug, { tag_id: tag.id })
      .then((newPost: IPost) => newPost)
      .catch((err) => {
        this.updateSinglePost(slug, currentData)
        throw err
      })
  }

  // Read or Unread
  postReadOrUnread(id: string, read: boolean, slug: string): Promise<IPost> {
    const currentData = this.dbAndSearchedPosts.find(
      (post) => post.slug === slug
    )

    if (currentData) {
      this.updateSinglePost(slug, {
        ...currentData,
        viewed: !currentData.viewed,
      })
    }
    let promise
    if (read) promise = unReadPost(id)
    else promise = readPost(id)
    if (!currentData) return promise as Promise<IPost>
    return promise.catch((err) => {
      this.updateSinglePost(slug, currentData)
      throw err
    })
  }

  // Reset votes
  resetVotes(slug: string, type: string): Promise<IPost> {
    const currentData = this.dbAndSearchedPosts.find(
      (post) => post.slug === slug
    )
    if (currentData) {
      this.updateSinglePost(slug, {
        ...currentData,
        votes_count_number:
          type === 'upvote' ? 0 : currentData.votes_count_number,
        downvotes_count_number:
          type === 'downvote' ? 0 : currentData.downvotes_count_number,
        upvoted: type === 'upvote' ? false : currentData.upvoted,
        downvoted: type === 'downvote' ? false : currentData.downvoted,
        upvoters: type === 'upvote' ? [] : currentData.upvoters,
        downvoters: type === 'downvote' ? [] : currentData.downvoters,
      })
    }
    let promise
    if (type === 'upvote') promise = resetUpvotes(slug)
    else promise = resetDownVotes(slug)
    if (!currentData) return promise as Promise<IPost>
    return promise.catch((err) => {
      this.updateSinglePost(slug, currentData)
      throw err
    })
  }

  // Delete Post
  delete(slug: string) {
    return deletePost(slug).then((newPost: IPost) =>
      this.deleteSinglePost(slug, newPost)
    )
  }

  // Edit Post
  edit(slug: string, data: INewPost, shouldUpdate: boolean = true) {
    return editPost(slug, data).then((newPost: IPost) => {
      if (shouldUpdate) this.updateSinglePost(slug, newPost)
      return newPost
    })
  }

  // Merged posts
  mergedPosts(post: IPost) {
    return getMergedPosts(post?.slug).then((posts: IPost[]) => {
      const updatedPost = { ...post, merged_posts: posts }
      return this.updateSinglePost(post.slug, updatedPost)
    })
  }

  // UnMerge
  unmerge(slug: string, unmergePostId: string) {
    return unmergePost(slug, unmergePostId).then((newPost: IPost) => {
      if (this.isAdmin) {
        this.appendPost(newPost)
      }
      return newPost
    })
  }

  updateSearchedPosts(posts?: IPost[]) {
    if (!posts) {
      this.searchedPosts = undefined
    } else {
      const ids = posts.map((post) => post.id)
      getVotesOfPosts(ids).then((votes) => {
        const updatedPosts = updateSearchedPosts(posts, votes)
        this.searchedPosts = updatedPosts?.map(postPrepProcessor) || undefined
      })
    }
  }

  get appliedFilters() {
    return cleanAndGetAppliedFilters(this.filters)
  }

  get dbAndSearchedPosts() {
    return [...(this.searchedPosts || []), ...this.posts]
  }
}

const postStore = new PostListStore()
export const userPostStore = new PostListStore()
export const roadmapStores: { [key: string]: PostListStore } = {
  closed: new PostListStore({ status: ['closed'] }),
  completed: new PostListStore({ status: ['completed'] }),
  in_progress: new PostListStore({ status: ['in_progress'] }),
  planned: new PostListStore({ status: ['planned'] }),
  under_review: new PostListStore({ status: ['under_review'] }),
}

export default postStore
