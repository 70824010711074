import React, { useContext, useMemo } from 'react'

import Button from '@/components/shared/ui/Button'
import type { ButtonProps } from '@/components/shared/ui/Button/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getOpenIDInitiatePath,
  getSSOLoginPath,
} from '@/lib/helpers/authHelpers'
import { loginWithPopup } from '@/lib/helpers/loginHelper'
import toaster from '@/utils/toast'

interface IPropTypes extends ButtonProps {
  onlyButtons?: boolean
  btnText?: string
}
export default function SSOSigninButton(props: IPropTypes) {
  const { onlyButtons, btnText, ...rest } = props
  const t = useTranslations()
  const { organization, organizationCustomization, open_id_redirect } =
    useContext(HNContext)

  const handleLogin = (url: string) => {
    return loginWithPopup({ url })
      .then(() => window.location.reload())
      .catch(toaster.error)
  }

  const buttonText = useMemo(() => {
    if (btnText) return btnText
    if (organizationCustomization?.sso_login_button_text) {
      return organizationCustomization.sso_login_button_text
    }
    return onlyButtons
      ? t('auth.labels.signInWithSSO')
      : t('auth.labels.continueWithSSO')
  }, [organizationCustomization?.sso_login_button_text])

  if (
    organization &&
    (organizationCustomization?.sso_redirect_enabled ||
      organizationCustomization?.open_id_enabled)
  ) {
    return (
      <Button
        dataTestId='sso_signin_button'
        onClick={() => {
          handleLogin(
            organizationCustomization.sso_redirect_enabled
              ? getSSOLoginPath({
                  domain: organization.domain,
                  redirect: window.location.href,
                  sso_redirect_url: organizationCustomization.sso_redirect_url,
                })
              : getOpenIDInitiatePath({
                  domain: organization.domain,
                  redirect: window.location.href,
                  open_id_connect_redirect_url:
                    organizationCustomization.open_id_redirect_url,
                })
          )
        }}
        block
        {...rest}
      >
        {buttonText}
      </Button>
    )
  }

  if (organization && open_id_redirect?.enabled) {
    return (
      <Button
        dataTestId='sso_open_id_button'
        onClick={() => {
          handleLogin(
            getOpenIDInitiatePath({
              domain: organization.domain,
              redirect: window.location.href,
              open_id_connect_redirect_url:
                open_id_redirect?.redirect_url || '',
            })
          )
        }}
        block
        {...rest}
      >
        {buttonText}
      </Button>
    )
  }

  return null
}
