import * as Popover from '@radix-ui/react-popover'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import type { ISelectInternalRef } from '@/components/shared/ui/Select/SelectTypes'

// Import CVA variant
import { selectPopoverContentVariants } from './select.variants'

interface IPropTypes {
  children: React.ReactNode
  trigger: React.ReactNode
  disabled?: boolean
  searchable?: boolean
  side?: 'top' | 'right' | 'bottom' | 'left'
  onOpenChange: (open: boolean) => void
  dataTestId?: string
}

const SelectPopover = forwardRef(
  (
    {
      children,
      trigger,
      disabled,
      side = 'bottom',
      onOpenChange,
      dataTestId,
    }: IPropTypes,
    ref: ForwardedRef<ISelectInternalRef>
  ) => {
    const [open, setOpen] = useState(false)

    useImperativeHandle(ref, () => ({
      toggle: () => setOpen(!open),
      close: () => setOpen(false),
      open: () => setOpen(true),
    }))

    useEffect(() => {
      onOpenChange(open)
    }, [open])

    return (
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger
          disabled={disabled}
          data-testid={dataTestId}
          className='w-full appearance-none rounded-md outline-[0.5px] outline-primary'
        >
          {trigger}
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            onEscapeKeyDown={() => setOpen(false)}
            className={selectPopoverContentVariants()}
            side={side}
            align='start'
            collisionPadding={8}
            onWheel={(e) => {
              const isModalOpen = document.querySelector('[data-modal]')

              if (!isModalOpen) return
              e.stopPropagation()
              const isScrollingDown = e.deltaY > 0
              if (isScrollingDown) {
                e.currentTarget.dispatchEvent(
                  new KeyboardEvent('keydown', { key: 'ArrowDown' })
                )
              } else {
                e.currentTarget.dispatchEvent(
                  new KeyboardEvent('keydown', { key: 'ArrowUp' })
                )
              }
            }}
          >
            {children}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    )
  }
)
SelectPopover.displayName = 'SelectPopover'

export default SelectPopover
