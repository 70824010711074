import AsanaConfiguration from '@/components/admin/settings/Integrations/config/AsanaConfiguration'
import AzureConfiguration from '@/components/admin/settings/Integrations/config/AzureConfiguration'
import ClickUpConfiguration from '@/components/admin/settings/Integrations/config/ClickUpConfiguration'
import DiscordConfiguration from '@/components/admin/settings/Integrations/config/DiscordConfiguration'
import GithubConfiguration from '@/components/admin/settings/Integrations/config/GithubConfiguration'
import GitlabConfiguration from '@/components/admin/settings/Integrations/config/Gitlab/GitlabConfiguration'
import JiraConfiguration from '@/components/admin/settings/Integrations/config/JiraConfiguration'
import LinearConfiguration from '@/components/admin/settings/Integrations/config/LinearConfiguration'
import SalesforceConfiguration from '@/components/admin/settings/Integrations/config/SalesforceConfiguration'
import SlackConfiguration from '@/components/admin/settings/Integrations/config/SlackConfiguration'
import type {
  IAdminAnalyticsIntegrationMapping,
  IAdminChatWidgetIntegrationMapping,
  IAdminIntegrationMapping,
  IAdminLinkIntegrationMapping,
  IAdminPatreonIntegrationMapping,
  IAdminWithSubdomainIntegrationMapping,
  IChatWidgetPlatformInfo,
} from '@/types/integration'
import type { IAdminAsanaIntegrationMapping } from '@/types/integration/asana'
import type { IAdminAzureAdIntegrationMapping } from '@/types/integration/azure_ad'
import type { IAdminClickUpIntegrationMapping } from '@/types/integration/click_up'
import type { IAdminDiscordIntegrationMapping } from '@/types/integration/discord'
import type { IAdminGithubIntegrationMapping } from '@/types/integration/github'
import type { IAdminGitlabIntegrationMapping } from '@/types/integration/gitlab'
import type { IAdminJiraIntegrationMapping } from '@/types/integration/jira'
import type { IAdminLinearIntegrationMapping } from '@/types/integration/linear'
import type { IAdminSalesforceIntegrationMapping } from '@/types/integration/salesforce'
import type { IAdminSlackIntegrationMapping } from '@/types/integration/slack'

import {
  integrationHelpLinks,
  jiraMarketplaceURL,
  settingsHelpLinks,
} from '../staticURLs'

const CHAT_WIDGET_PROVIDERS: IChatWidgetPlatformInfo[] = [
  {
    label: 'Intercom',
    placeholder: 'eg: tcode001',
    value: 'intercom_app_id',
  },
  {
    label: 'Crisp',
    placeholder: 'eg: 000c3e30-74ec-4dca-89f9-001d0f3123fd',
    value: 'crisp_chat_app_id',
  },
  {
    label: 'HubSpot',
    placeholder: 'eg: js-eu1.hs-scripts.com/12345678',
    value: 'hub_spot_app_id',
  },
  {
    label: 'Tidio',
    placeholder: 'eg: pwbfonncky13ersdkwlzdw6gacjytv3i',
    value: 'tidio_app_id',
  },
  {
    label: 'FreshChat',
    placeholder: 'eg: 42390975/120853',
    value: 'freshchat_app_id',
  },

  {
    label: 'Drift',
    placeholder: 'eg: nw3nmkcqcgsy',
    value: 'drift_app_id',
  },

  {
    label: 'Belco',
    placeholder: 'eg: RTYBn8yPH5bhvusCa',
    value: 'belco_app_id',
  },
  {
    label: 'Trengo',
    placeholder: 'eg: KQAhfLIRTXZ7AKrt',
    value: 'trengo_app_id',
  },
  {
    label: 'Helpscout',
    placeholder: 'eg: 863hd86w-74ec-4dca-98g5-001d0g6723dd',
    value: 'helpscout_app_id',
  },
]

const SLACK_EVENTS: string[] = [
  'post_created',
  'post_status_updated',
  'upvote_created',
  'downvote_created',
  'comment_created',
  'vote_milestone_reached',
]

const DISCORD_EVENTS: string[] = [
  'post_created',
  'post_status_updated',
  'upvote_created',
  'downvote_created',
  'comment_created',
  'vote_milestone_reached',
]

export const INTEGRATION_MAPPING: {
  jira: IAdminJiraIntegrationMapping
  click_up: IAdminClickUpIntegrationMapping
  azure_ad: IAdminAzureAdIntegrationMapping
  fullstory_analytics: IAdminAnalyticsIntegrationMapping
  segment_analytics: IAdminAnalyticsIntegrationMapping
  google_analytics: IAdminAnalyticsIntegrationMapping
  heap_analytics: IAdminAnalyticsIntegrationMapping
  plausible_analytics: IAdminAnalyticsIntegrationMapping
  fathom_analytics: IAdminAnalyticsIntegrationMapping
  patreon: IAdminPatreonIntegrationMapping
  chat_widget: IAdminChatWidgetIntegrationMapping
  intercom: IAdminIntegrationMapping
  linear: IAdminLinearIntegrationMapping
  slack: IAdminSlackIntegrationMapping
  discord: IAdminDiscordIntegrationMapping
  zendesk: IAdminWithSubdomainIntegrationMapping
  zapier: IAdminLinkIntegrationMapping
  github: IAdminGithubIntegrationMapping
  salesforce: IAdminSalesforceIntegrationMapping
  asana: IAdminAsanaIntegrationMapping
  gitlab: IAdminGitlabIntegrationMapping
} = {
  jira: {
    configDialogContent: JiraConfiguration,
    helpDocLink: integrationHelpLinks.integrationJira || '',
    logo: `${settingsHelpLinks.cdnLogo}jira.svg`,
    type: 'configure',
    pushFromOptions: ['manual', 'automatic'],
    connectPath: jiraMarketplaceURL,
  },
  azure_ad: {
    configDialogContent: AzureConfiguration,
    helpDocLink: integrationHelpLinks.integrationAzure || '',
    logo: `${settingsHelpLinks.cdnLogo}azure.svg`,
    type: 'configure',
  },
  click_up: {
    configDialogContent: ClickUpConfiguration,
    helpDocLink: integrationHelpLinks.integrationClickUp || '',
    logo: `${settingsHelpLinks.cdnLogo}click_up.svg`,
    type: 'configure',
    showConnectedInfo: true,
  },
  fullstory_analytics: {
    logo: `${settingsHelpLinks.cdnLogo}fullstory.svg`,
    key: 'fullstory_app_id',
    helpDocLink: integrationHelpLinks.integrationFullStory || '',
  },
  segment_analytics: {
    logo: `${settingsHelpLinks.cdnLogo}segment.svg`,
    key: 'segment_id',
    helpDocLink: integrationHelpLinks.integrationSegment || '',
  },
  google_analytics: {
    logo: `${settingsHelpLinks.cdnLogo}google-analytics.svg`,
    key: 'google_analytics_app_id',
  },
  heap_analytics: {
    logo: `${settingsHelpLinks.cdnLogo}heap.svg`,
    key: 'heap_app_id',
  },
  plausible_analytics: {
    logo: `${settingsHelpLinks.cdnLogo}plausible-analytics.png`,
    key: 'plausible_domain',
    helpDocLink: integrationHelpLinks.integrationPlausible || '',
  },
  fathom_analytics: {
    logo: `${settingsHelpLinks.cdnLogo}fathom-analytics.png`,
    key: 'fathom_site_id',
    helpDocLink: integrationHelpLinks.integrationFathom || '',
  },
  patreon: {
    helpDocLink: integrationHelpLinks.integrationPatreon || '',
    logo: `${settingsHelpLinks.cdnLogo}patreon.svg`,
    key: 'patreon_username',
  },
  chat_widget: {
    helpDocLink: integrationHelpLinks.integrationChat || '',
    logo: `${settingsHelpLinks.cdnLogo}chat.svg`,
    widgets: CHAT_WIDGET_PROVIDERS,
  },
  intercom: {
    helpDocLink: integrationHelpLinks.integrationIntercom || '',
    logo: `${settingsHelpLinks.cdnLogo}intercom-blue.svg`,
  },
  linear: {
    configDialogContent: LinearConfiguration,
    helpDocLink: integrationHelpLinks.integrationLinear || '',
    logo: `${settingsHelpLinks.cdnLogo}linear.svg`,
    type: 'configure',
    showConnectedInfo: true,
  },
  slack: {
    configDialogContent: SlackConfiguration,
    helpDocLink: integrationHelpLinks.integrationSlack || '',
    logo: `${settingsHelpLinks.cdnLogo}slack.svg`,
    type: 'configure',
    events: SLACK_EVENTS,
    showConnectedInfo: true,
  },
  discord: {
    configDialogContent: DiscordConfiguration,
    helpDocLink: integrationHelpLinks.integrationDiscord || '',
    logo: `${settingsHelpLinks.cdnLogo}discord.svg`,
    type: 'configure',
    events: DISCORD_EVENTS,
    showConnectedInfo: true,
  },
  zendesk: {
    helpDocLink: integrationHelpLinks.integrationZendesk || '',
    logo: `${settingsHelpLinks.cdnLogo}zendesk.svg`,
    subdomainSuffix: '.zendesk.com',
  },
  zapier: {
    helpDocLink: integrationHelpLinks.integrationZapier || '',
    logo: `${settingsHelpLinks.cdnLogo}zapier.svg`,
    type: 'link',
  },
  github: {
    configDialogContent: GithubConfiguration,
    helpDocLink: integrationHelpLinks.integrationGithub || '',
    logo: `${settingsHelpLinks.cdnLogo}github.svg`,
    type: 'configure',
    showConnectedInfo: true,
  },
  salesforce: {
    configDialogContent: SalesforceConfiguration,
    helpDocLink: integrationHelpLinks.integrationSalesforce || '',
    logo: `${settingsHelpLinks.cdnLogo}salesforce.svg`,
    type: 'configure',
    subdomainSuffix: '.my.salesforce.com',
    isBeta: false,
    onlyOnRequest: false,
  },
  asana: {
    configDialogContent: AsanaConfiguration,
    helpDocLink: integrationHelpLinks.integrationAsana || '',
    logo: `${settingsHelpLinks.cdnLogo}asana.svg`,
    type: 'configure',
    showConnectedInfo: true,
  },
  gitlab: {
    helpDocLink: integrationHelpLinks.integrationGitlab || '',
    logo: `${settingsHelpLinks.cdnLogo}gitlab.svg`,
    type: 'configure',
    configDialogContent: GitlabConfiguration,
  },
}
