import { settingsHelpLinks } from '@/config/staticURLs'
import { humanizeByte } from '@/lib/helpers/dataHelpers'
import type { IUsageFeatureInfo } from '@/types/billing'

export const UNLIMITED_CAP = 10000

// Board Usage Features In Old Plans
export const boardUsageFeatures: IUsageFeatureInfo[] = [
  {
    feature: 'board',
    slug: 'boards',
    hideInNewPlans: true,
  },
  {
    feature: 'board',
    slug: 'posts',
  },
]

// Bucket Usage Features In New Plans
export const bucketUsageFeatures: IUsageFeatureInfo[] = [
  {
    feature: 'board',
    slug: 'buckets',
    translationKey: 'boards',
    hideInOldPlans: true,
  },
]

// Team Usage Features In Old Plans
export const teamUsageFeatures: IUsageFeatureInfo[] = [
  {
    feature: 'team',
    slug: 'admins',
    hideInNewPlans: true,
  },
  {
    feature: 'team',
    slug: 'members',
    hideInNewPlans: true,
  },
  {
    feature: 'team',
    slug: 'csm',
    hideInNewPlans: true,
  },
]

// Seat Usage Features In New Plans
export const seatUsageFeatures: IUsageFeatureInfo[] = [
  {
    feature: 'team',
    slug: 'collaborators',
    hideInOldPlans: true,
  },
]

export const changelogUsageFeatures: IUsageFeatureInfo[] = [
  {
    feature: 'changelog',
    slug: 'changelog_mails',
  },
]

export const paymentHistoryTableMapping = [
  {
    valueKey: 'payment_date',
    column: 'paymentDate',
  },
  {
    valueKey: 'id',
    column: 'invoice',
  },
  {
    valueKey: 'status',
    column: 'status',
  },
  {
    valueKey: 'amount',
    column: 'amount',
  },
]

export const usersUsageFeatures: IUsageFeatureInfo[] = [
  {
    feature: 'users',
    slug: 'user_segmentations',
  },
]

export const roadmapUsageFeatures: IUsageFeatureInfo[] = [
  {
    feature: 'roadmaps',
    slug: 'roadmaps',
  },
]

export const allUsageFeatures: IUsageFeatureInfo[] = [
  ...boardUsageFeatures,
  ...bucketUsageFeatures,
  ...teamUsageFeatures,
  ...seatUsageFeatures,
  ...changelogUsageFeatures,
  ...usersUsageFeatures,
  ...roadmapUsageFeatures,
  {
    feature: 'ai',
    slug: 'ai_credits',
  },
  {
    feature: 'board',
    slug: 'ext_files_storage',
    cantBeUnlimited: true,
    valueParser: (value: number | string) => humanizeByte(value as number),
  },
  {
    feature: 'kb',
    slug: 'articles',
  },
]

export const powerUpLinks = {
  moderation: settingsHelpLinks.moderation,
  custom_domain: settingsHelpLinks.customDomain,
  whitelist_domain_config: settingsHelpLinks.whiteListDomainConfig,
  letterbox: settingsHelpLinks.letterBox,
  sso: settingsHelpLinks.ssoHelp,
  admins: settingsHelpLinks.userRole,
  members: settingsHelpLinks.userRole,
  csm: settingsHelpLinks.userRole,
  multiple_roadmap: settingsHelpLinks.multipleRoadmaps,
}

export const onTimePowerUps = ['ai_credits']

export const PLAN_MAP: {
  [key: string]: {
    label: string
  }
} = {
  free: { label: 'Trial' },
  basic: {
    label: 'Take Flight',
  },
  pro: {
    label: 'Fly High',
  },
  runway: {
    label: 'Run Way',
  },
  freeway: {
    label: 'Free Way',
  },
  starter: {
    label: 'Starter',
  },
  growth: {
    label: 'Growth',
  },
  business: {
    label: 'Business',
  },
}
