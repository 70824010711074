import { CHAT_LIST_PATH, CHAT_MESSAGES_PATH, CHAT_PATH } from '@/config/routes'
import API from '@/lib/API'
import type { IChatMessage } from '@/types/chat'
import type { IRecentChat } from '@/types/organization'

export type ISearchableIndexes =
  | 'FeatureRequest'
  | 'Changelog'
  | 'Article'
  | 'ResourceFaq'

export const sendChatMessage = async (
  message: string,
  data: {
    searchable_resources: ISearchableIndexes[]
    chat_id?: string | null
  }
) => {
  return API.post(CHAT_PATH, {
    query: message,
    ...data,
  })
}

export const getChats = async (): Promise<IRecentChat[]> => {
  return API.get(CHAT_LIST_PATH)
}

export const getChatMessages = async (
  chatId: string
): Promise<{ id: string; messages: IChatMessage[] }> => {
  return API.get(CHAT_MESSAGES_PATH(chatId))
}
