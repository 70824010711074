import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'

import AdminPostTagsFilter from '@/components/admin/posts/filters/AdminPostTagsFilter'
import AuthenticatedView from '@/components/auth/shared/AuthenticatedView'
import Badge from '@/components/shared/ui/Badge'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { getPostBucket } from '@/lib/helpers/modules/postHelper'
import postStore from '@/stores/PostListStore'
import type { ITagData } from '@/types/organization'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function SinglePostTagsAction({ post }: IPropTypes) {
  const t = useTranslations('post')
  const router = useRouter()
  const { updateContext, tags, embedType, buckets = [] } = useContext(HNContext)
  const currentPostTags = useMemo(() => post.tags, [post?.tags])
  const bucket = useMemo(() => getPostBucket(post, buckets), [buckets])

  const getSelectedTag = (tagId: string) => {
    let selectedTag = tags?.find((postTag) => postTag.id === tagId) as ITagData
    if (!selectedTag || !Object?.keys(selectedTag)?.length) {
      selectedTag = bucket?.tags?.find(
        (postTag) => postTag.id === tagId
      ) as ITagData
    }
    return selectedTag
  }

  const handleChange = (_key: string, data: any) => {
    const tagId = data?.length ? data[0] : null
    const updatedPost = {
      ...post,
      tags: [...currentPostTags, getSelectedTag(tagId)],
    }

    toaster.success({ message: t('messages.tags.success') })
    return postStore
      .addTagToPost(
        post.slug,
        data.isNew ? { ...data, __isNew__: data.isNew } : { value: tagId },
        data.isNew ? null : updatedPost
      )
      .then((newPost: IPost) => {
        if (data.isNew && tags) {
          postStore.updateSinglePost(newPost.slug, newPost)
          if (updateContext)
            updateContext({ tags: [...tags, newPost.tags.at(-1)] })
        }
      })
      .catch(toaster.error)
  }

  const handleRemoveTag = (tag: ITagData) => {
    if (!tag) return null
    const updatedPost = {
      ...post,
      tags: post.tags.filter((_t) => _t.id !== tag.id) as ITagData[],
    }

    toaster.success({ message: t('messages.tags.remove') })
    return postStore.deleteTag(post.slug, tag, updatedPost).catch(toaster.error)
  }

  const handleTagFilter = (tag: ITagData) => {
    if (embedType === 'orgEmbed') {
      router.push({
        pathname: `/embed/home`,
        query: { tag_id: tag.id },
      })
    }
    if (embedType === 'moduleEmbed' && bucket) {
      router.push({
        pathname: `/embed/b/${bucket?.name}`,
        query: { tag_id: tag.id },
      })
    } else {
      router.push({
        pathname: `/b`,
        query: { tag_id: tag.id },
      })
    }
  }

  const renderTags = (tag: ITagData) => {
    if (!tag || !tag.name) return <></>
    return (
      <Badge
        data={tag}
        key={tag?.id}
        value={tag?.name}
        showDot
        dotColor={tag?.color}
        variant='outline'
        rounded
        size='xxs'
        close={post.can_tag}
        onClose={(data) => handleRemoveTag(data)}
        onClick={() => handleTagFilter(tag)}
      />
    )
  }

  const renderTagSelect = () => {
    if (post.can_tag) {
      return (
        <AdminPostTagsFilter
          multiple={false}
          filters={{
            bucket_id: post?.bucket_id,
            tag_id: post?.tags?.map((tag: ITagData) => tag?.id.toString()),
          }}
          onChange={handleChange}
          placeholder={t('fields.tags.placeholder')}
          behaviour='select'
          showSelectedOptionsCount={false}
          creatable
        />
      )
    }
    return <></>
  }

  return (
    <div className='grid grid-cols-5 items-start gap-4 px-4 empty:hidden'>
      <AuthenticatedView
        shouldShow={Boolean(post.tags && post.tags.length) || post.can_tag}
      >
        <Typography.Text className='col-span-2 font-medium'>
          {t('fields.tags.label')}
        </Typography.Text>
        <span className='col-span-3 space-y-1'>
          {post?.tags?.length ? (
            <span className='flex w-full flex-wrap gap-0.5'>
              {post?.tags?.map(renderTags)}
            </span>
          ) : (
            <></>
          )}
          {renderTagSelect()}
        </span>
      </AuthenticatedView>
    </div>
  )
}
