import { Lock } from '@phosphor-icons/react'
import React, { useContext, useMemo } from 'react'

import AutoComplete from '@/components/shared/ui/AutoComplete'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IBoardDetails } from '@/types/board'
import type { INewPost } from '@/types/post'

interface IPropTypes {
  board?: IBoardDetails
  onUpdate: (key: keyof INewPost, value: string) => void
  newPostData: INewPost
}
export default function NewPostPostOnBehalf({
  board,
  onUpdate,
  newPostData,
}: IPropTypes) {
  const t = useTranslations('posts')

  const { userProfile, organization } = useContext(HNContext)
  const postOnBehalf = useMemo<boolean>(
    () => Boolean(newPostData.name || newPostData.email),
    [newPostData]
  )

  if (!(userProfile && userProfile.is_csm_of_organization)) return <></>

  const renderInput = () => {
    if (board && (board.private || board.read_only)) {
      return (
        <div data-testid='post-on-behalf-input' className='mt-1'>
          <AutoComplete
            size='md'
            autoFocus={false}
            displayKey='name'
            descriptionKey={'email'}
            iconURLKey={'profile_picture'}
            showAvatar
            value={newPostData.email}
            inputProps={{
              dataTestId: 'post-on-behalf-email',
            }}
            indexes={[
              {
                indexName: 'Customer',
                label: 'Customer',
                filters: [
                  [
                    `member_organization_ids=${organization?.id}`,
                    `bucket_members=${board.id}`,
                  ],
                ],
              },
            ]}
            placeholder={t('newPost.postOnBehalf.searchUser.placeholder')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onUpdate('email', e.target.value)
            }
            onSelect={(user) => onUpdate('email', user.email)}
          />
        </div>
      )
    }
    return (
      <div
        data-testid='post-on-behalf-input'
        className='mt-1 grid grid-cols-2 gap-4'
      >
        <Input
          size='md'
          dataTestId='post-on-behalf-email'
          id='post-on-behalf-input'
          type='email'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onUpdate('email', e.target.value)
          }
          placeholder={t('newPost.postOnBehalf.email.placeholder')}
          required={postOnBehalf}
        />
        <Input
          size='md'
          id='post-on-behalf-name'
          dataTestId='post-on-behalf-name'
          placeholder={t('newPost.postOnBehalf.name.placeholder')}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onUpdate('name', e.target.value.trim())
          }
        />
      </div>
    )
  }

  return (
    <div>
      <div className='flex items-center space-x-1'>
        <Label htmlfor='post-on-behalf-input'>
          {t('newPost.postOnBehalf.title')}
        </Label>
        <Tooltip
          placement='top'
          text={t('newPost.newPostHelper.behalfHelperText')}
        >
          <Lock
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            className='text-gray9 '
          />
        </Tooltip>
      </div>
      <div className='space-y-1.5'>{renderInput()}</div>
    </div>
  )
}
