/* eslint-disable no-underscore-dangle */
import { Check, Lock, PushPinSimple } from '@phosphor-icons/react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { memo, useContext, useMemo } from 'react'
import { areEqual } from 'react-window'

import DownvoteButton from '@/components/posts/DownvoteButton'
import PostInlinePriorityChange from '@/components/posts/PostInlinePriorityChange'
import PostInlineStatusChange from '@/components/posts/PostInlineStatusChange'
import UpvoteButton from '@/components/posts/UpvoteButton'
import SourceIcon from '@/components/shared/components/SourceIcon'
import UserAvatar from '@/components/shared/components/UserAvatar'
import Badge from '@/components/shared/ui/Badge'
import { Pill } from '@/components/shared/ui/Pill/Pill'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { stopPropagation } from '@/lib/helpers/appHelpers'
import { objectHasProperty } from '@/lib/helpers/dataHelpers'
import { formatJSONTimestamp } from '@/lib/helpers/dateHelpers'
import {
  adminSideSingleBoardsPath,
  userSideBoardSinglePath,
} from '@/lib/helpers/pathHelpers'
import { sanitizeHTMLText } from '@/lib/helpers/stringHelpers'
import postStore from '@/stores/PostListStore'
import type { ITagData } from '@/types/organization'
import type { IPost, IPostItemModules } from '@/types/post'

import PostLink from '../../posts/PostLink'
import ETCDateNotifier from '../shared/common/ETCDateNotifier'
import AdminPostModerationActions from './moderation/AdminPostModerationActions'

interface IPropTypes {
  post: IPost
  onUpdate: (post: IPost) => void
  isModeration?: boolean
  newTabOpen?: boolean
  hideModules: IPostItemModules
  highlight?: boolean
}

const AdminPostItem = (props: IPropTypes) => {
  const {
    post,
    isModeration = false,
    newTabOpen = false,
    hideModules = [],
    highlight = false,
  } = props

  const router = useRouter()

  const t = useTranslations()

  const { showPostDescription, isAdmin } = useContext(HNContext)

  const { bucket } = post

  const tags = useMemo(
    () => post.topTags || post.tags,
    [post.tags, post.topTags]
  )

  const hasAnsweredComment = useMemo(() => post.has_solution, [post])

  const handleTagFilter = (tagData: ITagData) => {
    if (!tagData || !tagData.id) return
    router.push({
      pathname: router.pathname,
      query: { tag_id: tagData.id, ...router.query },
    })
    postStore.updateFilters(
      {
        page: 1,
        sort: postStore.filters.sort,
        tag_id: [tagData.id],
      },
      { hardReset: true }
    )
  }

  const handleBoardClick = () => {
    if (!bucket) return false
    if (isAdmin) {
      router.push(adminSideSingleBoardsPath(bucket.name))
      return true
    }
    router.push(userSideBoardSinglePath(bucket?.name))
    return true
  }

  const { dateText } = useMemo(() => {
    return {
      dateText:
        post.createdTimeText ||
        formatJSONTimestamp(
          post?.created_at?.timestamp ?? post?.created_at,
          'MMM dd'
        ),
      dateTooltipText:
        post.createdTimeTooltipText ||
        formatJSONTimestamp(
          post?.created_at?.timestamp ?? post?.created_at,
          'ppp'
        ),
    }
  }, [post?.created_at, post?.created_at])

  return (
    <>
      <div className='flex w-full shrink-0 select-none items-center'>
        <div className='flex w-full shrink-0 cursor-pointer items-center justify-between space-x-2 overflow-x-hidden text-sm sm:pr-4'>
          <PostInlinePriorityChange post={post} />
          <div className='flex items-center space-x-1'>
            <UpvoteButton
              post={post}
              size='sm'
              onUpdate={(newPost) =>
                postStore.updateSinglePost(post.slug, { ...newPost })
              }
            />
            <DownvoteButton
              post={post}
              size='sm'
              onUpdate={(newPost) =>
                postStore.updateSinglePost(post.slug, { ...newPost })
              }
            />
          </div>

          <div className='flex' onClick={(e) => stopPropagation(e)}>
            <PostInlineStatusChange post={post} />
          </div>
          <PostLink
            post={post}
            newTabOpen={newTabOpen}
            className='fos-postitem-name flex	 w-full flex-col overflow-hidden py-2'
          >
            <>
              {post._formatted?.title ? (
                <p
                  className={clsx(
                    'truncate whitespace-pre  text-left text-gray12 ',
                    showPostDescription ? '!text-[0.75rem] !font-medium' : '',
                    highlight ? '' : ''
                  )}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTMLText(post._formatted?.title) || '',
                  }}
                />
              ) : (
                <p
                  className={clsx(
                    'flex truncate whitespace-pre  text-left text-gray12 ',
                    showPostDescription ? '!text-[0.75rem] !font-medium' : '',
                    highlight ? '' : ''
                  )}
                >
                  {post?.title}
                </p>
              )}
              {showPostDescription &&
                (post._formatted?.description ? (
                  <p
                    className='truncate !text-[12px] text-gray9'
                    dangerouslySetInnerHTML={{
                      __html:
                        sanitizeHTMLText(post._formatted?.description) || '',
                    }}
                  />
                ) : (
                  <p className='truncate !text-[12px] text-gray9'>
                    {post?.short_description || post?.description}
                  </p>
                ))}
            </>
          </PostLink>
          <div className='hidden min-w-min items-center space-x-0.5 md:flex lg:shrink-0'>
            <div className='flex items-center -space-x-4 hover:space-x-0.5 hover:duration-200'>
              {tags?.map((tg) => (
                <Badge
                  key={tg?.id}
                  value={tg?.name}
                  showDot
                  dotColor={tg?.color}
                  variant='outline'
                  size='xxs'
                  rounded
                  onClick={() => handleTagFilter(tg)}
                />
              ))}
              {!!post.moreTagsCount && (
                <Badge
                  rounded
                  variant='outline'
                  size='xxs'
                  value={`+${post.moreTagsCount}`}
                />
              )}
            </div>
            <div
              className={` ${
                hasAnsweredComment ? 'flex' : 'hidden'
              } w-fit items-center justify-center`}
            >
              <Tooltip text={t('post.isAnswered')}>
                <Check
                  size={10}
                  weight='bold'
                  className={`z-10 ml-0.5 rounded-full bg-green-600 p-0.5 text-white`}
                />
              </Tooltip>
            </div>
            <Pill
              onClick={handleBoardClick}
              className='flex items-center gap-1'
            >
              {bucket?.private && <Lock weight='bold' />}
              <span
                dangerouslySetInnerHTML={{
                  __html: bucket?.display_name || '',
                }}
              />
            </Pill>
          </div>
          {post?.etc_date && (
            <div className='hidden md:block'>
              <ETCDateNotifier etc={post?.etc_date} status={post.status} />
            </div>
          )}
          <SourceIcon
            source={post?.source}
            size={35}
            sourceLetterBoxEmail={post?.source_info?.letterbox_email_address}
            sourceInfo={post?.source_info}
          />
          <div className='hidden shrink-0 text-xs font-medium text-gray-light md:block'>
            {/* <Tooltip text={dateTooltipText} asChild> */}
            {dateText}
            {/* </Tooltip> */}
          </div>
          {!hideModules.includes('assignee') && !!post.assignee?.id && (
            <div
              className='!mr-1 hidden border-r border-gray5 pr-2 md:flex'
              data-testid='post_assignee'
            >
              <UserAvatar
                user={post.assignee}
                rounded
                size='xs'
                showProfileCard
                profileCardLabel={t('common.labels.assignedTo')}
              />
            </div>
          )}
          {!hideModules.includes('submitter') && (
            <div className='flex shrink-0 items-center'>
              <UserAvatar
                user={post?.submitter}
                rounded
                size='xs'
                showProfileCard
                allowUserInfoTrigger
                profileCardLabel={t('common.labels.submittedBy')}
              />
            </div>
          )}
          {!!(objectHasProperty(post, 'pinned') && post.pinned) && (
            <PushPinSimple
              className='flex h-3 w-3 shrink-0 text-primary dark:text-primary-light'
              weight='fill'
            />
          )}
          {isModeration && <AdminPostModerationActions post={post} />}
        </div>
      </div>
    </>
  )
}

export default memo(AdminPostItem, areEqual)
