import { Smiley, SmileyMeh, ThumbsUp } from '@phosphor-icons/react'
import React from 'react'

import Badge from '@/components/shared/ui/Badge'
import { useTranslations } from '@/hooks/useTranslations'
import type { IPostInsights } from '@/types/post'

interface IPropTypes {
  sentiment: IPostInsights['sentiment_type']
}
export default function PostInsightsSentiment({ sentiment }: IPropTypes) {
  const t = useTranslations('post')
  return (
    <div>
      {sentiment === 'positive' && (
        <Badge
          variant={'green'}
          icon={<ThumbsUp weight='fill' className='mr-1' />}
          value={t(`sentiment.${sentiment}`)}
          rounded
          className='border !border-green7'
        />
      )}
      {sentiment === 'negative' && (
        <Badge
          variant={'red'}
          icon={<SmileyMeh weight='fill' className='mr-1' />}
          value={t(`sentiment.${sentiment}`)}
          rounded
          className='border !border-red3'
        />
      )}
      {sentiment === 'neutral' && (
        <Badge
          variant={'yellow'}
          icon={<Smiley weight='fill' className='mr-1' />}
          value={t(`sentiment.${sentiment}`)}
          rounded
          className='border !border-yellow3'
        />
      )}
    </div>
  )
}
