import React, { useEffect, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'

import UnstyledLink from '../ui/Links/UnstyledLink'

interface IPropTypes {
  description?: string
}

export default function UpgradePlanModal({ description = '' }: IPropTypes) {
  const t = useTranslations('common.upgradeButton')
  const descriptionText = description || t('description')
  const [show, setShow] = useState(false)

  const handleShow = (data: { show: boolean }) => {
    setShow(data?.show)
  }

  useEffect(() => {
    EventEmitter.subscribe('OPEN_UPGRADE_MODAL', handleShow)
    return () => {
      EventEmitter.unsubscribe('OPEN_UPGRADE_MODAL', handleShow)
    }
  }, [])

  return (
    <Dialog
      open={show}
      onOpen={() => setShow(true)}
      onClose={() => setShow(false)}
    >
      <DialogContent>
        <DialogTitle>{t('title')}</DialogTitle>
        <DialogDescription>{descriptionText}</DialogDescription>
        <div className='mt-4 flex justify-end'>
          <UnstyledLink
            href='/admin/settings/billing'
            className='hover:no-underline focus:outline-none'
          >
            <Button onClick={() => setShow(false)}>
              {t('buttons.goToBilling', { ignorePrefix: true })}
            </Button>
          </UnstyledLink>
        </div>
      </DialogContent>
    </Dialog>
  )
}
