import { CheckCircle } from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'

import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
} from '@/components/shared/ui/Dialog/Dialog'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Typography from '@/components/shared/ui/Typography'
import { powerUpLinks } from '@/config/billingConstants'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IEntityUpdateEventData } from '@/lib/eventEmitter'
import { ENTITIES, EventEmitter } from '@/lib/eventEmitter'
import { QUANTITY_POWER_UPS } from '@/lib/helpers/powerUpsHelper'
import {
  snakeToCamel,
  templateComponentReplace,
} from '@/lib/helpers/stringHelpers'
import { openContactChat } from '@/lib/utils'
import type { IOrganizationPlan } from '@/types/organization'

export default function PowerUpPurchaseSuccessModal() {
  const t = useTranslations('billing')
  const { organizationPlan } = useContext(HNContext) as {
    organizationPlan: IOrganizationPlan
  }
  const router = useRouter()
  const [openDialog, setOpenDialog] = useState(false)
  const [configData, setConfigData] = useState<any>(null)

  const handleOpenDialog = (eventData: IEntityUpdateEventData) => {
    const { entity, data } = eventData
    if (entity !== ENTITIES.TOGGLE_SUCCESSFUL_PURCHASE_MODAL) return
    setConfigData(data)
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    router.replace(`${window.location.pathname}`, undefined, { shallow: true })
    setOpenDialog(false)
  }

  const getPurchasedDescription = () => {
    if (!configData) return null
    if (configData.migrated) {
      return t('purchaseSuccessModal.migratedDescription')
    }
    if (configData?.plan) {
      return templateComponentReplace(
        t('purchaseSuccessModal.planDescription', {
          data: {
            planName: t(`plans.${configData?.plan}.name`),
          },
        }),
        {
          chatSupport: (
            <Typography.Text
              className='cursor-pointer !text-primary'
              onClick={() => openContactChat()}
            >
              {t('purchaseSuccessModal.contactOurTeam')}
            </Typography.Text>
          ),
        }
      )
    }
    if (!QUANTITY_POWER_UPS.includes(configData?.powerUp?.slug)) {
      let helpArticleComponent: any = <></>
      const isHelpArticleAvailable = Boolean(
        // @ts-ignore
        powerUpLinks?.[configData?.powerUp?.slug]?.length
      )
      if (isHelpArticleAvailable) {
        helpArticleComponent = templateComponentReplace(
          t('purchaseSuccessModal.helpArticleSentence'),
          {
            helpArticle: (
              <UnstyledLink
                className='text-primary'
                target={'_blank'}
                // @ts-ignore
                href={powerUpLinks[configData?.powerUp?.slug] || ''}
              >
                {t('purchaseSuccessModal.helpArticle')}
              </UnstyledLink>
            ),
          }
        )
      }
      return (
        <>
          {t('purchaseSuccessModal.powerUpDescription', {
            data: {
              powerUp: t(
                `powerUps.${snakeToCamel(configData?.powerUp?.slug)}.title`
              ),
            },
          })}
          {helpArticleComponent}
        </>
      )
    }
    return templateComponentReplace(
      t(
        configData?.powerUp?.slug === 'boards'
          ? 'purchaseSuccessModal.quantityBoardsPowerUpDescription'
          : 'purchaseSuccessModal.quantityPowerUpDescription',
        {
          data: {
            powerUp: t(
              `powerUps.${snakeToCamel(configData?.powerUp?.slug)}.title`
            ),
            purchaseCount: configData?.powerUp?.quantity,
          },
        }
      ),
      {
        chatSupport: (
          <Typography.Text
            className='cursor-pointer !text-primary'
            onClick={() => openContactChat()}
          >
            {t('purchaseSuccessModal.contactOurTeam')}
          </Typography.Text>
        ),
      }
    )
  }

  useEffect(() => {
    EventEmitter.subscribe('CONFIG_PUSH', handleOpenDialog)

    return () => {
      EventEmitter.unsubscribe('CONFIG_PUSH', handleOpenDialog)
    }
  }, [])

  useEffect(() => {
    if (router.asPath.includes('success_modal=true')) {
      if (router.asPath.includes('migrate=true'))
        setConfigData({
          migrated: true,
        })
      else
        setConfigData({
          plan:
            organizationPlan?.slug === 'free'
              ? 'freeway'
              : snakeToCamel(
                  organizationPlan?.plan?.replace(' ', '_')?.toLowerCase()
                ),
        })
      setOpenDialog(true)
    }
  }, [router])

  return (
    <Dialog open={openDialog} onClose={() => handleDialogClose()}>
      <DialogContent size='xs' className='overflow-hidden'>
        <div className='absolute left-0 top-0 z-[2] h-1/2 w-full rounded-t-[0.74rem] bg-[#ffffff03] bg-gradient-to-t from-snow backdrop-blur-[60px] '></div>
        <span className='absolute left-[162px] top-[-50px] h-[100px] w-[195px] rotate-[-38.85deg] bg-[#00ff6680]'></span>
        <span className='absolute left-0 top-[-50px] h-[120px] w-[120px] bg-[#0057ff80]'></span>
        <DialogClose />
        <div className='mb-4 mt-10 flex flex-col items-center space-y-2 text-center'>
          <CheckCircle className='z-[2] h-16 w-16 text-green8' />
          <Typography.Title className='z-[2]' level={4}>
            {configData?.migrated
              ? t('purchaseSuccessModal.migrateTitle')
              : t('purchaseSuccessModal.title')}
          </Typography.Title>
          <Typography.Text className='z-[1] !text-gray9 '>
            {getPurchasedDescription()}
          </Typography.Text>
        </div>
      </DialogContent>
    </Dialog>
  )
}
