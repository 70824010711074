import type { ReactNode } from 'react'
import React, { useEffect, useRef, useState } from 'react'

import clsxm from '@/lib/clsxm'
import { getSVG } from '@/models/Common'

interface IPropTypes {
  url: string
  children?: ReactNode
  className?: string
}
export default function SVG({ url, children, className = '' }: IPropTypes) {
  const [loading, setLoading] = useState(true)
  const [svg, setSvg] = useState<string>('')
  const imageURL = useRef<string>(
    url || `${window.location.origin}/images/empty/post.svg`
  )

  const fetchImage = () =>
    getSVG(imageURL.current)
      .then((data) => setSvg(data))
      .catch(() => {
        setSvg('<span></span>')
        setLoading(false)
      })
      .then(() => setLoading(false))

  useEffect(() => {
    fetchImage()
    return () => {
      setSvg('')
      setLoading(false)
    }
  }, [])

  if (loading) {
    return <div />
  }

  if (children) {
    return (
      <div
        className={clsxm(
          className,
          'mx-auto flex h-96 max-w-lg flex-col items-center justify-center px-4 py-6 text-center'
        )}
      >
        <div
          className='mx-auto -mb-3 flex w-1/3 items-center justify-center text-center'
          dangerouslySetInnerHTML={{ __html: svg }}
        />
        <>{children}</>
      </div>
    )
  }
  return <div className={className} dangerouslySetInnerHTML={{ __html: svg }} />
}
