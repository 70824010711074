import { Export } from '@phosphor-icons/react'
import React, { useContext } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { recordEvent } from '@/lib/helpers/appHelpers'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import { exportPostVoters } from '@/models/Post'
import type { IOrganizationPlan } from '@/types/organization'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function AdminExportPostVotersButton(props: IPropTypes) {
  const { post } = props
  const { userProfile, organizationPlan } = useContext(HNContext)
  const t = useTranslations('posts.actions.exportVoters')

  const handleExport = () => {
    recordEvent('POST_VOTERS_EXPORTED', {
      skipSegment: true,
    })
    return exportPostVoters(post.slug)
      .then(() =>
        toaster.success({
          message: t('exportSuccess'),
        })
      )
      .catch(toaster.error)
  }

  if (
    featureIsEnabled('export_posts', organizationPlan as IOrganizationPlan) &&
    userProfile?.is_admin_of_organization
  )
    return (
      <AlertDialog
        onConfirm={handleExport}
        title={t('alertTexts.title')}
        description={t('alertTexts.description')}
      >
        <Button variant='outline' icon={<Export />} size='xxs'>
          {t('alertTexts.btnText')}
        </Button>
      </AlertDialog>
    )
  return null
}
