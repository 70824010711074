import * as React from 'react'

interface IPropTypes {
  size?: number
}
const CompletedIcon = (props: IPropTypes) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height={props.size || 25}
    width={props.size || 25}
    fill='currentColor'
    viewBox='0 0 25 25'
    {...props}
  >
    <circle
      r={11}
      cx={12.5}
      cy={12.5}
      fill='transparent'
      stroke='currentColor'
      strokeWidth={3}
    />
    <polyline
      points='7 12 10 16.5 18 10.5'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2.5}
    />
  </svg>
)

export default CompletedIcon
