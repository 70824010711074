import { PlusCircle } from '@phosphor-icons/react'
import type { RefObject } from 'react'
import React, { useMemo, useState } from 'react'

import Avatar from '@/components/shared/ui/Avatar'
import type { InputProps } from '@/components/shared/ui/Input/Input'
import { useTranslations } from '@/hooks/useTranslations'
import { getPropWithDotKey } from '@/lib/helpers/dataHelpers'
import type { ISearchIndex, ISearchResponse } from '@/types/search'

import SearchInput from '../../components/SearchInput'
import Typography from '../Typography'

interface IAutoCompletePropType {
  placeholder?: string
  indexes?: ISearchIndex[]
  descriptionKey?: string
  onSelect: (data: any) => void
  clearOnSelected?: boolean
  displayKey: string
  searchHandler?: (query: string) => Promise<any>
  hideIcon?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  inputRef?: RefObject<any>
  autoFocus?: boolean
  onCreate?: (query: string) => void
  showStatus?: boolean
  borderless?: boolean
  inputProps?: InputProps
  showAvatar?: boolean
  iconURLKey?: string
  createNewLabel?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export default function AutoComplete({
  placeholder,
  indexes,
  descriptionKey,
  onSelect,
  clearOnSelected = false,
  displayKey,
  searchHandler,
  hideIcon,
  onChange,
  inputRef,
  value,
  autoFocus = true,
  onCreate,
  showStatus = false,
  borderless = false,
  inputProps,
  showAvatar = false,
  iconURLKey,
  createNewLabel,
  size = 'sm',
}: IAutoCompletePropType) {
  const t = useTranslations('common.labels')

  const [searchedData, setSearchedData] = useState<ISearchResponse[]>([])
  const [focused, setFocused] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSelect = (selectedData: ISearchResponse) => {
    if (clearOnSelected) {
      setSearchedData([])
    }
    onSelect(selectedData)
    return setFocused(false)
  }

  const renderList = (data: any) => {
    const description = descriptionKey
      ? getPropWithDotKey(data, descriptionKey)
      : undefined
    // TODO: will use this for future purpose
    // const iconURL = iconURLKey ? getPropWithDotKey(data, iconURLKey) : undefined
    return (
      <div
        onClick={() => handleSelect(data)}
        data-testid={`autocomplete-list-item-${data?.id}`}
        className='flex cursor-pointer items-start space-x-2 bg-snow p-3 text-left text-gray10 transition hover:bg-gray4 focus:bg-gray5    '
      >
        {!!(showAvatar && iconURLKey) && (
          <Avatar rounded url={data[iconURLKey]} name={data[displayKey]} />
        )}
        <div className='flex flex-col'>
          {displayKey && (
            <Typography.Text
              className='font-medium !text-gray10 '
              dangerouslySetInnerHTML={{ __html: data[displayKey] }}
            />
          )}
          {description && <Typography.Text>{description}</Typography.Text>}
        </div>
      </div>
    )
  }
  const flattenedHits = useMemo(() => {
    return searchedData.map((data) => data.hits).flat()
  }, [searchedData])

  const query = useMemo(() => {
    return searchedData.length ? searchedData[0]?.query : ''
  }, [searchedData])

  return (
    <div className='relative'>
      <SearchInput
        indexes={indexes}
        inputProps={{
          placeholder,
          size,
          value,
          onClick: () => setFocused(true),
          onFocus: () => setFocused(true),
          onBlur: (e) => {
            e.preventDefault()
            setTimeout(() => setFocused(false), 200)
          },
          ...inputProps,
        }}
        onSearch={setSearchedData}
        searchHandler={searchHandler}
        hideIcon={hideIcon}
        onChange={onChange}
        inputRef={inputRef}
        autoFocus={autoFocus}
        onSearchState={setLoading}
        borderless={borderless}
      />
      {focused && (
        <div className='absolute z-[200] mt-0.5 w-full overflow-hidden rounded-lg border border-gray5 bg-snow shadow-lg empty:border-none  '>
          {searchedData.map((collection) => {
            const { hits } = collection
            return (
              <>
                {!!hits?.length && !loading && (
                  <div className='flex max-h-64 flex-col divide-y divide-gray5 overflow-scroll overflow-x-hidden '>
                    {hits?.map(renderList)}
                  </div>
                )}
              </>
            )
          })}

          {showStatus && loading && (
            <div className='text-lef space-y-3 p-2 transition focus:bg-gray5 '>
              <Typography.Text>{t('loaders.searching')}</Typography.Text>
            </div>
          )}
          {showStatus && !loading && !flattenedHits.length && !!query?.length && (
            <div className='space-y-3 p-2 text-left transition focus:bg-gray5  '>
              <Typography.Text className='font-medium !text-gray10 '>
                {t('noData')}
              </Typography.Text>
            </div>
          )}
          {onCreate && !loading && (
            <div
              className='flex cursor-pointer items-center space-x-1 border-gray5 bg-snow p-3 text-left text-gray10 transition hover:bg-gray4 focus:bg-gray5     '
              onClick={() => onCreate(query || '')}
            >
              <PlusCircle className='h-4 w-4 shrink-0' />
              <Typography.Text className='font-medium !text-gray11 '>
                {createNewLabel || t('createNew')}
              </Typography.Text>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
