import { Plus } from '@phosphor-icons/react'
import React, { useContext, useMemo } from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectPropTypes,
} from '@/components/shared/ui/Select/Select'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { sortArrayOfObjects } from '@/lib/helpers/dataHelpers'
import type { IBoardDetails } from '@/types/board'
import type { IFilters } from '@/types/common'
import type { IPostListAPIParams } from '@/types/post'

export interface IAdminPostBoardFilterPropType {
  onChange?: (key: string, value: any) => void
  filters: IFilters | IPostListAPIParams
  multiple?: boolean
  placeholder?: string
  disabled?: boolean
  globalFilters?: IFilters
  itemFilter?: (item: IBoardDetails) => boolean
  behaviour?: string
  clearable?: boolean
  noneOption?: boolean
  showSelectedOptionsCount?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  selectProps?: Partial<ISelectPropTypes>
  hidePlaceholder?: boolean
}
const FILTER_KEY = 'bucket_id'

export default function AdminPostBoardFilter({
  onChange,
  filters,
  multiple = false,
  placeholder = '',
  disabled = false,
  itemFilter,
  behaviour = 'filter',
  size = 'xs',
  clearable = false,
  noneOption = false,
  showSelectedOptionsCount = true,
  selectProps = {},
  hidePlaceholder,
}: IAdminPostBoardFilterPropType) {
  const t = useTranslations()
  const { buckets = [] } = useContext(HNContext)

  const boardOptions = useMemo(() => {
    const options = buckets
      .filter(itemFilter || (() => true))
      .sort((a, b) => {
        if (a.private && !b.private) return 1
        if (!a.private && b.private) return -1
        return 0
      })
      .map((bucket: IBoardDetails) => ({
        value: bucket.id,
        label: bucket.display_name,
        private: bucket.private,
        group: bucket.private
          ? t('common.modules.boards.private.title')
          : t('common.modules.boards.public.title'),
      }))

    if (noneOption) {
      options.unshift({
        value: 'none',
        label: t(`common.filters.options.none`),
        private: false,
        group: '',
      })
    }
    return options
  }, [buckets])

  const handleChange = (value: ISelectOption | ISelectOption[] | undefined) => {
    if (!onChange) return
    if (!value) {
      onChange(FILTER_KEY, [])
      return
    }
    const selectedValue = Array.isArray(value) ? value : [value]
    const boardIds = selectedValue.map((item) => item.value)
    onChange(FILTER_KEY, boardIds)
  }

  const value = useMemo(() => {
    if (filters && filters.bucket_id) {
      const filteredBucketIds = Array.isArray(filters.bucket_id)
        ? filters.bucket_id
        : [filters.bucket_id]

      return sortArrayOfObjects(boardOptions, 'group').filter((b) =>
        filteredBucketIds
          .map((id: string) => id.toString())
          .includes(String(b.value?.toString()))
      )
    }
    return null
  }, [filters, boardOptions])

  return (
    <>
      <Select
        {...selectProps}
        dataTestId='admin_post_board_filter'
        variant='pilled'
        size={size}
        searchable
        avoidSort
        disabled={disabled}
        value={value}
        onChange={handleChange}
        options={boardOptions}
        multiple={multiple}
        clearable={clearable}
        placeholder={placeholder.toString()}
        placeholderIcon={
          behaviour === 'filter' || hidePlaceholder ? <></> : <Plus />
        }
        group
        inputProps={{
          placeholder,
        }}
        showSelectedOptionsCount={showSelectedOptionsCount}
      />
    </>
  )
}
