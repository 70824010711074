import type { IJiraSyncRule } from '@/types/integration/jira'
import type { IIntegrationStatusSyncRule } from '@/types/integration/statusSync'

export const convertJiraSyncRuleToIntegrationSyncRule = (
  rule: IJiraSyncRule
): IIntegrationStatusSyncRule => {
  return {
    status: rule.hn_status,
    trigger_status: rule.jira_status,
    source: 'jira',
    when: rule.qualifier,
    target: 'hellonext',
    notify_customers: rule.notify,
    sync_while_create: rule.sync_while_create,
  } as IIntegrationStatusSyncRule
}

export const convertIntegrationSyncRuleToJiraSyncRule = (
  rule: IIntegrationStatusSyncRule
): IJiraSyncRule => {
  return {
    qualifier: rule.when,
    jira_status: rule.trigger_status,
    hn_status: rule.status,
    notify: rule.notify_customers,
    sync_while_create: rule.sync_while_create,
  }
}
