import { Info } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useContext, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { isUserMember } from '@/lib/helpers/modules/userHelper'
import { updateComment } from '@/models/Comment'
import type { IComment } from '@/types/comment'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  comment: IComment
  post: IPost
  children?: React.ReactNode
  onUpdate?: (comment: IComment) => void
  onDelete?: (comment: IComment) => void
  className?: string
}
export default function CommentModerationBanner({
  comment,
  post,
  onUpdate,
  onDelete,
  children,
  className,
}: IPropTypes) {
  const t = useTranslations('comments')
  const { userProfile } = useContext(HNContext)
  const [loading, setLoading] = useState(false)

  const handleUpdate = (status: 'approved' | 'rejected') => {
    setLoading(true)
    updateComment(
      post.slug,
      comment.id,
      { approval_status: status },
      isUserMember(userProfile)
    )
      .then(status === 'approved' ? onUpdate : onDelete)
      .catch((err) => {
        toaster.error({ message: err.message })
      })
      .finally(() => setLoading(false))
  }

  if (comment.approval_status !== 'pending')
    return <div className={className}>{children}</div>

  return (
    <div
      data-testid='comment_approval_banner'
      className={clsx(
        'mb-4 w-full bg-gray2 p-2 font-medium text-gray12 transition',
        className
      )}
    >
      <div className='rounded-md border border-yellow5 bg-yellow3 p-2'>
        <div className='flex flex-col items-start justify-between space-y-2 sm:flex-row sm:items-center sm:space-x-1 sm:space-y-0'>
          <div className='flex shrink-0 items-center gap-1'>
            <Info
              weight='bold'
              className='flex h-5 w-5 shrink-0 text-yellow10'
            />
            <span>{t('approvalBanner.message')}</span>
          </div>
          {comment.can_approve && (
            <div className='flex w-full space-x-1 sm:w-auto'>
              <Button
                size='xs'
                onClick={() => handleUpdate('approved')}
                disabled={loading}
                variant='success'
                className='whitespace-nowrap'
              >
                {t('buttons.approve', { ignorePrefix: true })}
              </Button>
              <AlertDialog
                type='danger'
                confirmText={t('buttons.confirm', { ignorePrefix: true })}
                title={t('approvalBanner.alertMessage.title')}
                description={t('approvalBanner.alertMessage.description')}
                onConfirm={() => handleUpdate('rejected')}
              >
                <Button
                  variant='secondary'
                  size='xs'
                  disabled={loading}
                  className='whitespace-nowrap'
                >
                  {t('buttons.reject', { ignorePrefix: true })}
                </Button>
              </AlertDialog>
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  )
}
