import { Trash } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import AdminPostParentStatusFilter from '@/components/admin/posts/filters/AdminPostParentStatusFilter'
import AdminPostStatusFilter from '@/components/admin/posts/filters/AdminPostStatusFilter'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import { Select } from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import {
  convertIntegrationSyncRuleToJiraSyncRule,
  convertJiraSyncRuleToIntegrationSyncRule,
} from '@/lib/helpers/modules/Integration/jiraHelper'
import { templateComponentReplace } from '@/lib/helpers/stringHelpers'
import type { IIntegrationStatusSyncRuleKey } from '@/types/integration/github'
import type { IJiraPushRule, IJiraSyncRule } from '@/types/integration/jira'
import type { IIntegrationStatusSyncRule } from '@/types/integration/statusSync'

import AdminIntegrationSyncNotifyDropdown from '../Shared/AdminIntegrationSyncNotifyDropdown'
import AdminIntegrationSyncWhenDropdown from '../Shared/AdminIntegrationSyncWhenDropdown'

interface IAdminIntegrationJiraSyncRuleProps {
  syncRule: IJiraSyncRule
  pushRule: IJiraPushRule
  onChange?: (syncRule: IJiraSyncRule) => void
  newRule?: boolean
  index?: number
  handleRemove?: (index: number) => void
  useCustomStates?: boolean
}
export default function AdminIntegrationJiraSyncRule({
  syncRule,
  pushRule,
  newRule = false,
  index,
  handleRemove,
  onChange,
  useCustomStates,
}: IAdminIntegrationJiraSyncRuleProps) {
  const t = useTranslations()
  const [newSyncRule, setNewSyncRule] = useState<IIntegrationStatusSyncRule>(
    convertJiraSyncRuleToIntegrationSyncRule(syncRule)
  )
  const [jiraStatus, setJiraStatus] = useState<ISelectOption[]>([])

  const handleChange = (
    key: IIntegrationStatusSyncRuleKey | string,
    value: string | boolean
  ) => {
    const updatedSyncRule = {
      ...newSyncRule,
      [key]: value,
    } as IIntegrationStatusSyncRule
    setNewSyncRule(updatedSyncRule)
    if (onChange) {
      onChange(convertIntegrationSyncRuleToJiraSyncRule(updatedSyncRule))
    }
  }

  const handleSelectChange = (
    data: ISelectOption[],
    key: IIntegrationStatusSyncRuleKey,
    value: ISelectOptionGroup
  ) => {
    const selectedValue = getSelectedValueByKey(data, value)

    if (selectedValue && Array.isArray(selectedValue)) {
      handleChange(key, selectedValue[0]?.value)
    } else {
      handleChange(key, selectedValue?.value)
    }
  }

  const handleRemoveRule = () => {
    if (handleRemove && index !== undefined) handleRemove(index)
  }

  const renderContent = () => {
    return templateComponentReplace(
      t(`integrations.jira.configure.statusSync.jiraRule`),
      {
        when: (
          <span className='mx-1'>
            <AdminIntegrationSyncWhenDropdown
              syncRule={newSyncRule}
              edit={newRule}
              onChange={handleChange}
              whenData={[]}
            />
          </span>
        ),
        source: t(`integrations.jira.title`),
        triggerStatus: (
          <span className='mx-1'>
            <Select
              size='xs'
              options={jiraStatus}
              placeholder={t(
                `integrations.statusSync.notifyOptions.placeholder`
              )}
              onChange={(value) =>
                handleSelectChange(jiraStatus, 'trigger_status', value)
              }
              value={setSelectedValue(jiraStatus, newSyncRule.trigger_status)}
              disabled={!newRule}
            />
          </span>
        ),
        target: t(`common.appName`),
        status: useCustomStates ? (
          <span className='mx-1'>
            <AdminPostStatusFilter
              behaviour='select'
              filters={{ state: newSyncRule.status }}
              multiple={false}
              disabled={!newRule}
              placeholder={t(`integrations.statusSync.status.placeholder`)}
              onChange={(_key, value) => {
                handleChange('status', Array.isArray(value) ? value[0] : value)
              }}
            />
          </span>
        ) : (
          <span className='mx-1'>
            <AdminPostParentStatusFilter
              filters={{ status: newSyncRule.status }}
              multiple={false}
              disabled={!newRule}
              placeholder={t(`integrations.statusSync.status.placeholder`)}
              onChange={(_key, value) => {
                handleChange('status', Array.isArray(value) ? value[0] : value)
              }}
            />
          </span>
        ),
        notify: (
          <span className='mx-1'>
            <AdminIntegrationSyncNotifyDropdown
              syncRule={newSyncRule}
              edit={newRule}
              onChange={handleChange}
              notifyData={[]}
            />
          </span>
        ),
      }
    )
  }

  useEffect(() => {
    const usedStatuses = pushRule.rules
      .map((r) => r.jira_status)
      .filter((s) => s !== syncRule.jira_status)
    const finalStatus = pushRule.jira_status_list.filter(
      (s) => !usedStatuses.includes(s.value)
    )
    setJiraStatus(finalStatus)
  }, [pushRule])

  return (
    <div
      className={clsx('flex flex-col gap-1', newRule ? '' : 'bg-gray2 p-3 ')}
    >
      <div className='flex w-full items-center justify-between space-x-2 rounded text-xs'>
        <span className='flex flex-wrap items-center space-y-1 text-sm'>
          {renderContent()}
        </span>
        {!newRule && (
          <span className='flex items-center justify-end'>
            <Button
              fab
              size='xxs'
              danger
              variant='naked'
              onClick={() => handleRemoveRule()}
            >
              <Trash weight='bold' className='h-3.5 w-3.5' />
            </Button>
          </span>
        )}
      </div>
      <Checkbox
        label={t(`integrations.jira.configure.statusSync.triggerOnCreate`)}
        disabled={!newRule}
        defaultChecked={newSyncRule.sync_while_create}
        onCheckedChange={(checked) =>
          handleChange('sync_while_create', checked)
        }
      />
    </div>
  )
}
