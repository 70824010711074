import { Paperclip, WarningCircle } from '@phosphor-icons/react'
import React, { useContext, useRef } from 'react'

import ImageDropzone from '@/components/shared/components/ImageDropzone'
import Button from '@/components/shared/ui/Button'
import type {
  ButtonProps,
  IButtonSize,
} from '@/components/shared/ui/Button/Button'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { humanizeByte } from '@/lib/helpers/dataHelpers'

interface IPropTypes {
  dropzone?: boolean
  onAdd: (files: File[]) => void
  buttonSize?: IButtonSize
  buttonProps?: ButtonProps
}

export default function EditorAddExternalAttchments({
  onAdd,
  dropzone,
  buttonSize,
  buttonProps = {},
}: IPropTypes) {
  const t = useTranslations()
  const { organizationPlan, userProfile } = useContext(HNContext)

  const inputRef = useRef<HTMLInputElement>(null)

  const planExceeded =
    (organizationPlan?.power_ups.ext_files_storage?.used || 0) >=
    (organizationPlan?.power_ups.ext_files_storage?.limit || 0)

  const perFileLimit =
    organizationPlan?.power_ups.ext_files_storage?.per_file_size_limit ||
    500 * 1024 * 1024

  if (planExceeded && !userProfile?.is_csm_of_organization) return <></>

  const tootipMessage = planExceeded
    ? t('common.extAttachments.uploadArea.planExceededMessage', {
        data: {
          limit: `${humanizeByte(
            organizationPlan?.power_ups.ext_files_storage?.limit || 0
          )}`,
        },
      })
    : t('common.extAttachments.uploadArea.buttonTooltip', {
        data: {
          size: humanizeByte(perFileLimit),
        },
      })

  if (dropzone) {
    return (
      <ImageDropzone
        disabled={planExceeded}
        onDrop={(files) => {
          onAdd(files as File[])
          return Promise.resolve()
        }}
        title={
          planExceeded
            ? tootipMessage
            : t('common.extAttachments.uploadArea.description')
        }
        message={
          planExceeded
            ? ''
            : t('common.extAttachments.uploadArea.buttonTooltip', {
                data: {
                  size: humanizeByte(perFileLimit),
                },
              })
        }
        accept='*'
        icon={
          planExceeded ? (
            <WarningCircle className='mx-auto h-10 w-10 text-gray7' />
          ) : (
            <Paperclip className='mx-auto h-10 w-10 text-gray7' />
          )
        }
        multiple
      />
    )
  }

  return (
    <>
      <input
        className='hidden w-full cursor-pointer'
        ref={inputRef}
        type='file'
        onChange={(e) => {
          const files: File[] = Array.from(e.target.files || [])
          onAdd(files)
          e.target.value = ''
        }}
        multiple
      />
      <Tooltip text={tootipMessage} asChild>
        <Button
          fab
          type='button'
          className='font-semibold'
          disabled={planExceeded}
          variant='secondary'
          size={buttonSize}
          icon={<Paperclip className='h-3.5 w-3.5' />}
          onClick={() => inputRef.current?.click()}
          {...buttonProps}
        />
      </Tooltip>
    </>
  )
}
