import React, { useContext } from 'react'

interface IHasAnswer {
  hasAnswer: boolean
}

export const CommentAnswered = React.createContext<IHasAnswer | undefined>(
  undefined
)

export const useCommentAnswered = () => {
  const context = useContext(CommentAnswered)

  if (context === undefined) {
    throw new Error(
      'useCommentAnswered must be used within a CommentAnswered.Provider'
    )
  }

  return context
}
