import {
  AsteriskSimple,
  Gauge,
  GearSix,
  Kanban,
  Tag,
  UsersThree,
} from '@phosphor-icons/react'

export const changelogNavigation = [
  {
    key: 'allChangelog',
    link: '/admin/changelog',
    labelTransKey: 'sidebar.items.changelog_all',
    anotherHref: [
      '/admin/changelog',
      '/admin/changelog/published',
      '/admin/changelog/draft',
      '/admin/changelog/scheduled',
    ],
    countKey: 'allChangelogCount',
    icon: <AsteriskSimple size={16} />,
    availableRoles: ['admin', 'member', 'csm'],
    isExact: true,
  },
  {
    key: 'analytics',
    link: '/admin/changelog/analytics',
    labelTransKey: 'sidebar.items.analytics',
    anotherHref: ['/admin/changelog/analytics'],
    icon: <Gauge size={16} />,
    availableRoles: ['admin', 'member', 'csm'],
    isExact: true,
  },
  {
    key: 'kanbanChangelog',
    link: '/admin/changelog/kanban',
    labelTransKey: 'sidebar.items.kanban',
    anotherHref: ['/admin/changelog/kanban'],
    icon: <Kanban size={16} />,
    availableRoles: ['admin', 'member'],
    isExact: true,
  },
  {
    key: 'changelogLabels',
    link: '/admin/changelog/labels',
    labelTransKey: 'sidebar.items.changelog_labels',
    anotherHref: ['/admin/changelog/labels'],
    icon: <Tag size={16} />,
    availableRoles: ['admin', 'member'],
    isExact: true,
    group: 'manage',
  },
  {
    key: 'changelogSubscribers',
    link: '/admin/changelog/subscribers',
    labelTransKey: 'sidebar.items.changelog_subscribers',
    anotherHref: ['/admin/changelog/subscribers'],
    icon: <UsersThree size={16} />,
    availableRoles: ['admin'],
    isExact: true,
    group: 'manage',
  },
  {
    key: 'changelogSettings',
    link: '/admin/changelog/settings',
    labelTransKey: 'sidebar.items.changelog_settings',
    anotherHref: ['/admin/changelog/settings'],
    icon: <GearSix size={16} />,
    availableRoles: ['admin'],
    isExact: true,
    group: 'settings',
  },
]

export const changelogNavigationGroups = {
  settings: {
    label: 'Settings',
    open: true,
  },
  manage: {
    label: 'Manage',
    open: true,
  },
}
