import React, { useState } from 'react'

import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import { addInteractorLabel } from '@/models/User'
import type { IInteractorRecord } from '@/types/user'
import toaster from '@/utils/toast'

import AdminUserTagsFilter from '../filters/AdminUserTagsFilter'

interface IPropTypes {
  user: IInteractorRecord
  onUpdate: (user: IInteractorRecord) => void
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export default function AdminAddUserLabel({
  user,
  onUpdate,
  size,
}: IPropTypes) {
  const t = useTranslations('users.actions.addLabel')
  const [loading, setLoading] = useState(false)

  const handleSelect = (
    _key: string,
    _value: string[],
    selectedItems?: ISelectOption[]
  ) => {
    if (!selectedItems?.length) return
    setLoading(true)
    addInteractorLabel(user.id, { ...selectedItems[0] })
      .then(onUpdate)
      .catch(toaster.error)
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className='flex items-center gap-2'>
      <AdminUserTagsFilter
        disabled={loading}
        behaviour='select'
        filters={{ tag_id: user.interactor_labels.map((l) => l.id) }}
        onChange={handleSelect}
        placeholder={t('placeholder')}
        size={size}
      />
    </div>
  )
}
