import type { LinkProps } from 'next/link'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'

import clsxm from '@/lib/clsxm'

import { buildURL } from '../../../../lib/helpers/urlHelpers'

export type UnstyledLinkProps = {
  href: LinkProps['href']
  params?: { [key: string]: string | number | undefined }
  children: React.ReactNode
  openNewTab?: boolean
  className?: string
  as?: string
  nextLinkProps?: Omit<LinkProps, 'href'>
} & React.ComponentPropsWithRef<'a'>

const UnstyledLink: React.FC<UnstyledLinkProps> = React.forwardRef(
  ({ children, href, params, as, className, nextLinkProps, ...rest }, ref) => {
    const router = useRouter()
    const { hide_boards, no_header } = router.query

    const getHref = () => {
      const newParams: {
        no_header?: string
        hide_boards?: string
      } = {}
      if (typeof href === 'string') {
        if (no_header) newParams.no_header = 'true'
        if (hide_boards) newParams.hide_boards = 'true'
        return buildURL(href, newParams)
      }
      return href
    }
    const isInternalLink =
      (href &&
        typeof href === 'string' &&
        (href.startsWith('/') || href.startsWith('?')) &&
        !href.startsWith('//')) ||
      typeof href !== 'string'

    if (isInternalLink) {
      return (
        <Link
          {...rest}
          href={
            params && Object.keys(params)
              ? buildURL(getHref(), params)
              : getHref()
          }
          {...nextLinkProps}
          as={as}
          ref={ref}
          className={className}
        >
          {children}
        </Link>
      )
    }

    return (
      <a
        ref={ref}
        rel='noopener noreferrer'
        href={href}
        {...rest}
        className={clsxm('cursor-newtab', className)}
      >
        {children}
      </a>
    )
  }
)

UnstyledLink.displayName = 'UnstyledLink'
export default UnstyledLink
