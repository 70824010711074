import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import ExternalAttachmentItem from '@/components/shared/components/attachments/ExternalAttachmentItem'
import { getUppy } from '@/config/uploader/uppy.config'
import HNContext from '@/context/HNContext'
import type { IComment } from '@/types/comment'
import type { IResourceTypes } from '@/types/common'
import type { IAttachment } from '@/types/organization'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  defaultAttachments?: IAttachment[]
  attachments?: IAttachment[]
  onUpdate?: (attachments: IAttachment[]) => void
  resourceId?: string
  resourceType?: IResourceTypes
  resource?: IPost | IComment
  onRemove?: (attachment: IAttachment) => void
}

export interface IEditorExternalAttchmentsListProps {
  attachFiles: (files: File[]) => void
  getAttachments: () => IAttachment[]
  resetAttachments: () => void
  getInProgressFiles: () => IAttachment[]
}
const EditorExternalAttchmentsList = forwardRef(
  (
    {
      defaultAttachments,
      attachments: _attachments,
      onUpdate,
      resource,
      resourceId,
      resourceType,
      onRemove,
    }: IPropTypes,
    ref: ForwardedRef<IEditorExternalAttchmentsListProps>
  ) => {
    const { organizationPlan } = useContext(HNContext)

    const perFileLimit =
      organizationPlan?.power_ups.ext_files_storage?.per_file_size_limit || 100

    const [attachments, setAttachments] = useState(
      defaultAttachments || _attachments || []
    )

    const uppy = useMemo(() => {
      return getUppy({
        kind: 'external_files',
        resource_type: resourceType,
        resource_id: resourceId,
        restrictions: {
          allowedFileTypes: null,
          maxFileSize: perFileLimit,
          maxNumberOfFiles: 10,
          minFileSize: 0,
          maxTotalFileSize: null,
          minNumberOfFiles: 0,
          requiredMetaFields: [],
        },
        onAdd: ({ file }) => {
          setAttachments((attchmnts) => [...attchmnts, file])
        },
        onComplete: ({ success, error, attachment: _attachment, fileId }) => {
          if (!success) {
            toaster.error({
              message: error || "Couldn't upload the file",
            })
            setAttachments((attchmnts) =>
              attchmnts.filter((a) => a.id !== fileId)
            )
          } else if (!fileId || !_attachment) {
            toaster.error({
              message: 'Attachment not found',
            })
          } else {
            setAttachments((attchmnts) =>
              attchmnts.map((a) =>
                a.id === fileId
                  ? {
                      ..._attachment,
                      id: undefined,
                    }
                  : a
              )
            )
          }
        },
        onProgress: ({ percentage, bytesUploaded, bytesTotal, fileId }) => {
          setAttachments((attchmnts) =>
            attchmnts.map((a) =>
              a.id === fileId
                ? {
                    ...a,
                    progress: percentage,
                    bytesUploaded: Number(bytesUploaded),
                    bytesTotal: Number(bytesTotal),
                  }
                : a
            )
          )
        },
      })
    }, [])

    const handleNewFiles = (files: File[]) => {
      for (let idx = 0; idx < files.length; idx += 1) {
        const file = files[idx]
        if (file) {
          try {
            uppy.addFile(file)
            uppy.upload()
          } catch (e: any) {
            toaster.error({
              message: e.message,
            })
          }
        }
      }
    }

    const handleNewAttachment = (attachment: IAttachment) => {
      setAttachments((attchmnts) =>
        [...attchmnts].map((a) => {
          if (a.id === attachment.id) {
            return {
              ...attachment,
              id: undefined,
            }
          }
          return a
        })
      )
    }

    useImperativeHandle(
      ref,
      (): IEditorExternalAttchmentsListProps => ({
        attachFiles: (files: File[]) => {
          handleNewFiles(files)
        },
        getAttachments: () => attachments.filter((a) => a.url),
        resetAttachments: () => {
          setAttachments([])
        },
        getInProgressFiles: () => attachments.filter((a) => a.id),
      })
    )

    const handleRemove = (attachment: IAttachment) => {
      setAttachments(attachments.filter((a) => a.url !== attachment.url))
      onRemove?.(attachment)
    }

    useEffect(() => {
      onUpdate?.(attachments)
    }, [attachments])

    useEffect(() => {
      if (_attachments) setAttachments(_attachments)
    }, [_attachments])

    return (
      <div className='grid grid-cols-1 gap-2 empty:hidden md:grid-cols-2'>
        {attachments.map((attachment, i) => (
          <ExternalAttachmentItem
            key={i}
            attachment={attachment}
            onRemove={handleRemove}
            onUpload={handleNewAttachment}
            resourceId={resourceId}
            resourceType={resourceType}
            resource={resource}
          />
        ))}
      </div>
    )
  }
)

EditorExternalAttchmentsList.displayName = 'EditorExternalAttchmentsList'
export default EditorExternalAttchmentsList
