import type { IKeyValueMap } from 'mobx'

import { APP_PATHS } from '@/config/appPaths'
import { RedirectError } from '@/lib/customErrors/RedirectError'
import { sentry } from '@/lib/helpers/appHelpers'
import {
  billingPath,
  expiredPath,
  getSignInOrSSOPath,
  newKBPath,
  signinPath,
  userSideChangelogHomePath,
  userSideRoadmapPath,
} from '@/lib/helpers/pathHelpers'
import { buildURL, encodeURL, getHost, joinURL } from '@/lib/helpers/urlHelpers'
import { getAppToken } from '@/models/Auth'
import type { IRequestHeaders } from '@/types/common'
import type { IOrganizationData, IRootData } from '@/types/organization'
import type { IUserProfile } from '@/types/user'

const isInHomePaths = (pathname: string) =>
  pathname.toString().includes('/embed/home') || pathname.toString() === '/'

const isInManualAuthPaths = (pathname: string) =>
  pathname.toString().includes('/mauth')

const isNewOrgPath = (pathname: string) => pathname === '/new'

const isInAuthPaths = (pathname: string) =>
  pathname.toString().includes('/auth') ||
  pathname.toString().includes('/mauth') ||
  pathname.toString().includes('/users') ||
  pathname.toString().includes('/redirects') ||
  pathname.toString().includes('/invitations')

const isAuthRedirectPaths = (pathname: string) =>
  pathname.toString().startsWith('/user/redirect/')

const isInInvitationPaths = (pathname: string) =>
  pathname.toString().includes('/invitations') ||
  pathname.toString().includes('/invite')

const isInErrorPaths = (pathname: string) =>
  pathname.toString().includes('/404') ||
  pathname.toString().includes('/500') ||
  pathname.toString().includes('/403')

const isInDashboardPaths = (pathname: string) =>
  pathname.toString().includes('/admin')

const isInChangelogPaths = (pathname: string) =>
  pathname.toString().includes('/changelog') ||
  pathname.toString().includes('/embed/c') ||
  pathname.toString().includes('/embed/home/changelog')

const isInRoadmapPaths = (pathname: string) =>
  pathname.toString().includes('/roadmap') ||
  pathname.toString().includes('/embed/roadmap') ||
  pathname.toString().includes('/embed/roadmap/b')

const isInBoardOrPostPaths = (pathname: string) =>
  pathname.toString().includes('/boards') ||
  pathname.toString().includes('/b') ||
  pathname.toString().includes('/b/[boardSlug]/p') ||
  pathname.toString().includes('/p/[postSlug]') ||
  pathname.toString().includes('/embed/b') ||
  pathname.toString().includes('/embed/p') ||
  pathname.toString().includes('/embed/home/b')

const isInBillingPaths = (pathname: string) =>
  pathname.toString().includes(billingPath) ||
  pathname.toString().includes(expiredPath)

const isCheckoutPaths = (pathname: string) =>
  pathname.toString().includes('/purchase/checkout') ||
  pathname.toString().includes('/purchase/validate')

const isKBPath = (pathname: string) => pathname.toString().startsWith('/kb')

export class UserRedirect {
  private signInPath: string | null

  private noAccessPath: string | null

  private organization: IOrganizationData

  private userProfile: IUserProfile

  private query: IKeyValueMap

  private pathname: string

  private rootData: IRootData

  private subdomain: string

  private fullPath: string

  private currentHost: string

  private headers: IRequestHeaders

  constructor(props: {
    pathname: string
    rootData: IRootData
    subdomain: string
    fullPath: string
    currentHost: string
    query: IKeyValueMap
    headers: IRequestHeaders
  }) {
    this.pathname = props.pathname
    this.rootData = props.rootData
    this.subdomain = props.subdomain
    this.fullPath = props.fullPath
    this.headers = props.headers
    this.currentHost =
      process.env.VERCEL_ENV === 'production'
        ? `https://${props.currentHost}`
        : props.currentHost

    this.organization = this.rootData.organization
    this.userProfile = this.rootData.userProfile
    this.query = props.query
    sentry.log(`UserRedirect pathname: ${props.pathname}`)
    sentry.log(`UserRedirect subdomain: ${props.subdomain}`)
    sentry.log(`UserRedirect fullPath: ${props.fullPath}`)
    sentry.log(`UserRedirect currentHost: ${props.currentHost}`)
    sentry.log(
      `UserRedirect FullSignInPath: ${joinURL(this.currentHost, this.fullPath)}`
    )

    this.noAccessPath = '/403'
    this.signInPath = this.rootData.organizationCustomization
      ? getSignInOrSSOPath(
          this.rootData.organizationCustomization,
          this.organization?.domain,
          joinURL(this.currentHost, this.fullPath)
        )
      : signinPath
    sentry.log(`UserRedirect signInPath: ${this.signInPath}`)
  }

  public isNewOrgPath = () => isNewOrgPath(this.pathname)

  public isInAuthPaths = () => isInAuthPaths(this.pathname)

  public isAuthRedirectPaths = () => isAuthRedirectPaths(this.pathname)

  public isInErrorPaths = () => isInErrorPaths(this.pathname)

  public isInChangelogPaths = () => isInChangelogPaths(this.pathname)

  public isInRoadmapPaths = () => isInRoadmapPaths(this.pathname)

  public isInBoardOrPostPaths = () => isInBoardOrPostPaths(this.pathname)

  public isInDashboardPaths = () => isInDashboardPaths(this.pathname)

  public isInKnowledgeBasePaths = () => isKBPath(this.pathname)

  public isInBillingPaths = () => isInBillingPaths(this.fullPath)

  public isInHomePaths = () => isInHomePaths(this.pathname)

  public isInInvitationPaths = () => isInInvitationPaths(this.pathname)

  public isInManualAuthPaths = () => isInManualAuthPaths(this.pathname)

  public isCurrentUserIsInteractor = () => this.userProfile?.is_interactor

  public isCurrentUserIsCSM = () => this.userProfile?.is_csm_of_organization

  public isCurrentUserIsAdmin = () => this.userProfile?.is_admin_of_organization

  public isWidgetPath = () => this.pathname.includes('/widget')

  public isCheckoutPaths = () => isCheckoutPaths(this.pathname)

  public isProfilePath = () => this.pathname.includes('/profile')

  public handleModuleRedirect = () => {
    const { show_changelog_tab, show_knowledge_base_tab, show_roadmap_tab } =
      this.rootData
    // Check if the changelog is public
    if (show_changelog_tab) {
      sentry.log('changelog module is public')
      if (!this.isInChangelogPaths() && this.isInHomePaths()) {
        sentry.log('not in changelog paths')
        return userSideChangelogHomePath(null)
      }
      if (this.isInChangelogPaths()) {
        sentry.log('in changelog paths')
        return true
      }
    }
    if (show_roadmap_tab) {
      sentry.log('roadmap module is public')
      if (!this.isInRoadmapPaths() && this.isInHomePaths()) {
        sentry.log('not in roadmap paths')
        return userSideRoadmapPath('')
      }
      if (this.isInRoadmapPaths()) {
        sentry.log('in roadmap paths')
        return true
      }
    }

    // Check if the kb is public
    if (show_knowledge_base_tab) {
      sentry.log('kb module is public')
      if (!this.isInKnowledgeBasePaths() && this.isInHomePaths()) {
        sentry.log('not in kb paths')
        return newKBPath(this.organization.domain)
      }

      if (this.isInKnowledgeBasePaths()) {
        sentry.log('in changelog paths')
        return true
      }
    }
    return null
  }

  public kbPathCheck = () => {
    if (isKBPath(this.pathname)) {
      const redirectUrl = newKBPath(this.organization.domain)
      return Promise.reject(new RedirectError(redirectUrl, 'kbPathCheck'))
    }
    return Promise.resolve()
  }

  public customDomainRedirectCheck = () => {
    if (process.env.NODE_ENV === 'development') {
      return Promise.resolve()
    }
    if (this.pathname.includes('/widget')) {
      sentry.log('customDomainRedirectCheck - it is a widget path so skipping')
      return Promise.resolve()
    }
    sentry.log('customDomainRedirectCheck')
    if (!this.organization.custom_domain_verified) {
      sentry.log('custom domain not enabled')
      return Promise.resolve()
    }
    sentry.log('custom domain enabled')
    const customDomainHost = getHost(this.organization.home_page)
    sentry.log('customDomainHost', customDomainHost)

    if (customDomainHost === this.subdomain) {
      sentry.log('customDomainHost === this.subdomain')
      return Promise.resolve()
    }

    sentry.log('customDomainHost !== this.subdomain')
    const redirectUrl = joinURL(this.organization.home_page, this.fullPath)
    sentry.log('redirectUrl', redirectUrl)
    return Promise.reject(
      new RedirectError(redirectUrl, 'customDomainRedirectCheck')
    )
  }

  public errorPathRedirectCheck = () => {
    sentry.log('errorPathRedirectCheck')
    if (!this.isInErrorPaths()) {
      sentry.log('not in error paths')
      return Promise.resolve()
    }
    sentry.log('in error paths')
    sentry.log('do not redirect and throw error to avoid other checks')

    return Promise.reject(new RedirectError(null, 'errorPathRedirectCheck'))
  }

  public appRedirectCheck = async () => {
    if (!this.query.fromApp || !this.query.redirectURL) return Promise.resolve()
    if (!this.isInAuthPaths()) return Promise.resolve()
    const response = await getAppToken('kb', this.headers)

    if (response.kb_auth_token) {
      const redirectPath = buildURL(this.query.redirectURL, {
        kbAuthToken: response.kb_auth_token,
        state: this.query.state,
      })
      return Promise.reject(new RedirectError(redirectPath, 'appRedirectCheck'))
    }
    return Promise.resolve()
  }

  public authPathRedirectCheck = () => {
    sentry.log('authPathRedirectCheck')
    if (this.isInManualAuthPaths()) {
      sentry.log('in manual auth paths')
      return Promise.resolve()
    }
    sentry.log('not in manual auth paths')

    if (this.isInAuthPaths() && !this.isInDashboardPaths()) {
      sentry.log('in auth paths')
      if (this.rootData.userSignedIn && !this.isInInvitationPaths()) {
        sentry.log('user signed in')
        const { redirectURL, fromApp, state } = this.query
        if (fromApp && this.isAuthRedirectPaths()) {
          const redirectPath = buildURL(
            APP_PATHS.AUTH_REDIRECT_PATH('knowledge-base'),
            {
              redirectURL,
              fromApp,
              state,
            }
          )
          return Promise.reject(
            new RedirectError(redirectPath, 'authPathRedirectCheck')
          )
        }
        const redirectPath =
          redirectURL && redirectURL.startsWith('/') ? redirectURL : '/'
        return Promise.reject(
          new RedirectError(redirectPath, 'authPathRedirectCheck')
        )
      }
      sentry.log('user not signed in')

      return Promise.reject(new RedirectError(null, 'authPathRedirectCheck'))
    }
    if (!this.rootData.userSignedIn && this.isProfilePath()) {
      sentry.log('user not signed in and is profile path')
      return Promise.reject(new RedirectError('/', 'authPathRedirectCheck'))
    }
    sentry.log('not in auth paths')

    return Promise.resolve()
  }

  public expiredOrganizationRedirectCheck = () => {
    sentry.log('expiredOrganizationRedirectCheck')

    const { organizationPlan } = this.rootData

    if (!organizationPlan.trial_expired) {
      sentry.log('trial not expired')
      return Promise.resolve()
    }

    sentry.log('organization expired')
    if (!this.isInBillingPaths()) {
      sentry.log('not in billing paths')
      if (this.isCurrentUserIsAdmin() && !this.isCheckoutPaths()) {
        sentry.log('current user is admin')
        return Promise.reject(
          new RedirectError(billingPath, 'expiredOrganizationRedirectCheck')
        )
      }
      sentry.log('current user is not admin')
      if (this.isCurrentUserIsCSM() && this.isInDashboardPaths()) {
        sentry.log('current user is CSM and in dashboard paths')
        return Promise.reject(
          new RedirectError('/', 'expiredOrganizationRedirectCheck')
        )
      }
    }

    sentry.log('current user is not admin or csm')
    return Promise.resolve()
  }

  public privateOrganizationRedirectCheck = () => {
    sentry.log('privateOrganizationRedirectCheck')
    const { organizationSetting, userSignedIn } = this.rootData

    if (!organizationSetting.organization_privacy) {
      sentry.log('organization is public')
      return Promise.resolve()
    }
    sentry.log('organization is private')

    // Check for the public modules if the user is not signed in
    if (!userSignedIn) {
      sentry.log('user not signed in')
      const redirectUrl = this.handleModuleRedirect()
      if (typeof redirectUrl === 'string') {
        sentry.log('redirecting to module')
        return Promise.reject(
          new RedirectError(redirectUrl, 'privateOrganizationRedirectCheck')
        )
      }
      if (redirectUrl) {
        return Promise.resolve()
      }
    }

    sentry.log('no module is public')
    if (userSignedIn) {
      sentry.log('user is signed in')
      if (this.isCurrentUserIsInteractor()) {
        sentry.log('user is interactor')
        return Promise.resolve()
      }
      sentry.log('user is not interactor')
      sentry.log('redirecting to 404')

      const redirectUrl = this.handleModuleRedirect()
      if (typeof redirectUrl === 'string') {
        sentry.log('redirecting to module')
        return Promise.reject(
          new RedirectError(redirectUrl, 'privateOrganizationRedirectCheck')
        )
      }
      if (redirectUrl) {
        return Promise.resolve()
      }

      return Promise.reject(
        new RedirectError(this.noAccessPath, 'privateOrganizationRedirectCheck')
      )
    }
    sentry.log('user is not signed in')

    if (!this.isInAuthPaths()) {
      sentry.log('not in auth paths')
      sentry.log('redirecting to signin')

      return Promise.reject(
        new RedirectError(this.signInPath, 'privateOrganizationRedirectCheck')
      )
    }
    sentry.log('in auth paths')

    return Promise.resolve()
  }

  public publicOrganizationRedirectCheck = () => {
    sentry.log('publicOrganizationRedirectCheck')
    const { userSignedIn } = this.rootData
    if (userSignedIn) {
      sentry.log('userSignedIn')
      if (this.isInDashboardPaths()) {
        sentry.log('in dashboard')
        if (this.isCurrentUserIsCSM()) {
          sentry.log('isCurrentUserIsCSM')
          return Promise.resolve()
        }
        sentry.log('not isCurrentUserIsCSM')
        sentry.log('redirect to 404')

        return Promise.reject(
          new RedirectError(
            this.noAccessPath,
            'publicOrganizationRedirectCheck'
          )
        )
      }
      sentry.log('not in dashboard')

      return Promise.resolve()
    }

    sentry.log('not userSignedIn')
    if (this.isInDashboardPaths()) {
      sentry.log('in dashboard')
      sentry.log('redirect to 404')

      return Promise.reject(
        new RedirectError(this.noAccessPath, 'publicOrganizationRedirectCheck')
      )
    }

    sentry.log('not in dashboard')
    return Promise.resolve()
  }

  public changelogRedirectCheck = () => {
    sentry.log('changelogRedirectCheck')
    if (this.isInDashboardPaths()) {
      sentry.log('in dashboard')
      return Promise.resolve()
    }

    if (this.isInChangelogPaths()) {
      sentry.log('in changelog path')
      const { organizationSetting } = this.rootData

      if (
        organizationSetting.changelog_privacy ||
        organizationSetting.changelog_partial_privacy
      ) {
        sentry.log('changelog is private || partial private')
        if (this.rootData.show_changelog_tab) {
          sentry.log('show changelog tab')
          return Promise.resolve()
        }
        sentry.log('not show changelog tab')
        sentry.log('redirect to 404')

        return Promise.reject(
          new RedirectError(this.noAccessPath, 'changelogRedirectCheck')
        )
      }
    }

    return Promise.resolve()
  }

  public roadmapRedirectCheck = () => {
    sentry.log('roadmapRedirectCheck')
    if (this.isInDashboardPaths()) {
      sentry.log('in dashboard')
      return Promise.resolve()
    }

    if (this.isInRoadmapPaths()) {
      sentry.log('in roadmap path')
      const { show_roadmap_tab } = this.rootData
      if (show_roadmap_tab) {
        sentry.log('show roadmap tab is true')
        return Promise.resolve()
      }
      sentry.log('show roadmap tab is false')
      sentry.log('sending to 404')

      return Promise.reject(
        new RedirectError(this.noAccessPath, 'roadmapRedirectCheck')
      )
    }
    sentry.log('not in roadmap path')

    return Promise.resolve()
  }

  public boardRedirectCheck = () => {
    sentry.log('boardRedirectCheck')
    if (this.isInDashboardPaths()) {
      sentry.log('in dashboard')
      return Promise.resolve()
    }

    if (this.isInBoardOrPostPaths()) {
      sentry.log('in board path')
      const { show_boards_tab } = this.rootData

      if (show_boards_tab) {
        sentry.log('show boards tab is true')
        return Promise.resolve()
      }
      sentry.log('show boards tab is false')
      sentry.log('sending to 404')

      return Promise.reject(
        new RedirectError(this.noAccessPath, 'boardRedirectCheck')
      )
    }
    sentry.log('not in board path')

    return Promise.resolve()
  }

  public knowledgeBaseRedirectCheck = () => {
    sentry.log('knowledgeBaseRedirectCheck')
    if (this.isInDashboardPaths()) {
      sentry.log('in dashboard')
      return Promise.resolve()
    }

    if (this.isInKnowledgeBasePaths()) {
      sentry.log('in Knowledge Base path')
      const { show_knowledge_base_tab } = this.rootData
      if (show_knowledge_base_tab) {
        sentry.log('show Knowledge Base tab is true')
        return Promise.resolve()
      }

      sentry.log('show Knowledge Base tab is false')
      sentry.log('sending to 404')

      return Promise.reject(
        new RedirectError(this.noAccessPath, 'knowledgeBaseRedirectCheck')
      )
    }
    sentry.log('not in Knowledge Base path')

    return Promise.resolve()
  }

  public redirectCheck = () => {
    if (this.pathname.startsWith('/api')) return null

    if (this.organization) {
      return this.customDomainRedirectCheck()
        .then(this.kbPathCheck)
        .then(this.errorPathRedirectCheck)
        .then(this.appRedirectCheck)
        .then(this.authPathRedirectCheck)
        .then(this.expiredOrganizationRedirectCheck)
        .then(this.privateOrganizationRedirectCheck)
        .then(this.publicOrganizationRedirectCheck)
        .then(this.changelogRedirectCheck)
        .then(this.roadmapRedirectCheck)
        .then(this.boardRedirectCheck)
        .then(this.knowledgeBaseRedirectCheck)
        .catch((err) => {
          sentry.log('redirectCheck', `${err.message}`, 'debug')
          sentry.log('redirectCheck', `fcnName ${err.fcnName}`, 'debug')
          sentry.log('redirectCheck', `redirectUrl ${err.redirectUrl}`, 'debug')

          if (err.redirectUrl && err.redirectUrl?.includes('redirect')) {
            return err.redirectUrl
          }
          const redirectURL = err.redirectUrl
            ? buildURL(err.redirectUrl, {
                redirectURL:
                  err.redirectUrl === this.signInPath
                    ? encodeURL(this.fullPath)
                    : undefined,
              })
            : null
          return redirectURL
        })
    }

    if (this.subdomain !== 'portal') {
      return process.env.NEXT_PUBLIC_HOSTED_URL
    }

    if (
      !this.rootData.userSignedIn &&
      !this.isInAuthPaths() &&
      !this.isWidgetPath() &&
      !this.isNewOrgPath()
    ) {
      return signinPath
    }
    if (this.rootData.userSignedIn && this.isInAuthPaths()) {
      return process.env.NEXT_PUBLIC_HOSTED_URL
    }
    return null
  }
}
