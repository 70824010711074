import { ErrorBoundary } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import React, { useContext, useEffect, useState } from 'react'

import AdminMigrateToPaddleModal from '@/components/admin/organization/settings/billing/AdminMigrateToPaddleModal'
import PowerUpPurchaseModal from '@/components/admin/powerups/PowerUpPurchaseModal'
import PowerUpPurchaseSuccessModal from '@/components/admin/powerups/PowerUpPurchaseSuccessModal'
import PowerUpSeatsRemoveModal from '@/components/admin/powerups/PowerUpSeatsRemoveModal'
import AdminSidebar from '@/components/admin/shared/AdminSidebar'
import AdminSingleUserPage from '@/components/admin/users/userSingle/AdminSingleUserPage'
import AdminPageTitle from '@/components/shared/common/AdminPageTitle'
import SupportChat from '@/components/users/shared/integration_snippets/SupportChat'
import { queryClient } from '@/config/queryClient'
import { HNAsideContext } from '@/context/HNAsideContext'
import HNContext from '@/context/HNContext'
import { useSearchClient } from '@/context/HNSearchContext'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { billingPath } from '@/lib/helpers/pathHelpers'
import changelogStore from '@/stores/ChangelogStore'
import knowledgebaseStore from '@/stores/KnowledgebaseStore'
import postStore from '@/stores/PostListStore'
import type { IPowerUpInfo } from '@/types/billing'

import { EVENT_ACTION_TYPES, EventEmitter } from '../lib/eventEmitter'

const NewPostButton = dynamic(
  () => import('@/components/posts/newPost/NewPostButton'),
  { ssr: false }
)
const AdminSideAnnouncementModal = dynamic(
  () => import('@/components/admin/shared/common/AdminSideAnnouncementModal'),
  { ssr: false }
)

interface IPropTypes {
  children: ReactNode
}

export default function AdminSideLayout(props: IPropTypes) {
  const isAsideShow = useMediaQuery({ width: 767 })
  const [open, setOpen] = useState(isAsideShow)
  const { organizationPlan, organization } = useContext(HNContext)
  const { searchClient } = useSearchClient()
  const router = useRouter()
  const { asPath, pathname } = router

  const handleAsideToggle = (data: any) => {
    if (data.action !== 'TOGGLE_SIDEBAR') return
    setOpen(!open)
  }

  const checkIfBillingPath = () => {
    return pathname === '/admin/settings/[[...billing]]'
  }

  const checkIfCheckoutPath = () => {
    return (
      pathname === '/purchase/checkout' || pathname === '/purchase/validate'
    )
  }

  const handleUpdatePowerUps = (powerUp: IPowerUpInfo) => {
    EventEmitter.dispatch('ENTITY_UPDATE', {
      entity: 'POWER_UPS',
      actionType: EVENT_ACTION_TYPES.UPDATE,
      data: { data: [powerUp] },
      updateKey: 'removed',
    })
  }

  useEffect(() => {
    EventEmitter.subscribe('SHORTCUT_TRIGGERED', handleAsideToggle)
    if (organization?.id) {
      postStore.setSearchClient(searchClient)
      changelogStore.setSearchClient(searchClient)
      knowledgebaseStore.setSearchClient(searchClient)
    }
    return () => {
      EventEmitter.unsubscribe('SHORTCUT_TRIGGERED', handleAsideToggle)
    }
  }, [open])

  useEffect(() => {
    if (
      organizationPlan?.trial_expired &&
      checkIfBillingPath() &&
      !checkIfCheckoutPath()
    ) {
      router.replace(billingPath)
    }
  }, [organizationPlan, asPath])

  useEffect(() => {
    setOpen(isAsideShow)
  }, [isAsideShow])

  return (
    <QueryClientProvider client={queryClient}>
      <HNAsideContext.Provider
        value={{
          open,
          toggleOpen: (status?: boolean) => {
            if (status === undefined) setOpen(!open)
            else setOpen(!open)
          },
        }}
      >
        <div className='fixed flex h-full w-full overflow-hidden'>
          <AdminPageTitle />
          <AdminSidebar />
          <div
            id='main-section'
            data-aside={open}
            className={clsx(
              'relative flex w-full flex-col overflow-hidden',
              open ? 'aside-open' : 'aside-close'
            )}
          >
            {props.children}
          </div>
          {open && (
            <div
              onClick={() => setOpen(false)}
              className='peer:transition fixed left-0 top-0 z-[2] h-screen w-screen bg-gray12/30 opacity-70 duration-200 peer-focus:opacity-100 md:hidden'
            />
          )}
          <NewPostButton shortcutTriggered />
          <PowerUpPurchaseModal />
          <AdminMigrateToPaddleModal />
          <PowerUpSeatsRemoveModal onUpdate={handleUpdatePowerUps} />
          <PowerUpPurchaseSuccessModal />
          <AdminSingleUserPage />
          <ErrorBoundary fallback={<></>}>
            <AdminSideAnnouncementModal />
          </ErrorBoundary>
          <SupportChat />
          {/* <AdminDebugDataBar /> */}
        </div>
      </HNAsideContext.Provider>
    </QueryClientProvider>
  )
}
