import { Info } from '@phosphor-icons/react'
import { useContext, useEffect, useState } from 'react'

import Status from '@/components/posts/Status'
import Alert from '@/components/shared/ui/Alert'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import {
  Progress,
  ProgressIndicator,
} from '@/components/shared/ui/ProgressBar/ProgressBar'
import Tooltip from '@/components/shared/ui/Tooltip'
import Typography from '@/components/shared/ui/Typography'
import { settingsHelpLinks } from '@/config/staticURLs'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  formatJSONTimestamp,
  humanizeDuration,
} from '@/lib/helpers/dateHelpers'
import { transformPostTatSummary } from '@/lib/helpers/modules/postHelper'
import { getSinglePostTATSummary } from '@/models/Post'
import postStore from '@/stores/PostListStore'
import type { IPost, ITatSummary, ITatSummaryData } from '@/types/post'

interface IPropTypes {
  post: IPost
}

export default function SinglePostTATSummary({ post }: IPropTypes) {
  const t = useTranslations()
  const { organizationCustomization } = useContext(HNContext)
  const [tatSummary, setTatSummary] = useState<ITatSummary | undefined | null>()
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const fetchData = () => {
    setLoading(true)
    getSinglePostTATSummary(post.slug)
      .then((data: ITatSummary) => {
        if (data) {
          setTatSummary(transformPostTatSummary(data))
          postStore.updateSinglePost(post.slug, {
            ...post,
            tat_summary: transformPostTatSummary(data),
          })
        }
      })
      .catch((err) => {
        setErrorMessage(err.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getCurrentStatusTooltipText = () => {
    if (!tatSummary) return ''
    if (tatSummary?.end_date) {
      return `${post.custom_status.title} ${t('common.on', {
        ignorePrefix: true,
      }).toLowerCase()} ${formatJSONTimestamp(tatSummary?.end_date, 'MMM dd')}`
    }
    return `${t('post.labels.currentlyOn')} ${post.custom_status.title}`
  }

  useEffect(() => {
    if (organizationCustomization?.show_tat_summary) fetchData()
  }, [])

  useEffect(() => {
    if (post.tat_summary) {
      setTatSummary(transformPostTatSummary(post.tat_summary))
    }
  }, [post])

  const renderDate = (item: ITatSummaryData) => {
    return (
      <div className='space-y-1'>
        <p className=''>
          <span
            style={{ color: item.custom_status_info.color }}
            className={'font-semibold'}
          >{`${item.custom_status_info.name}: `}</span>
          <span className='text-gray10 '>
            {`${formatJSONTimestamp(
              item.start_date,
              'MMM dd'
            )} - ${formatJSONTimestamp(item?.end_date, 'MMM dd')}`}
          </span>
        </p>
        <p>
          <span className='font-semibold text-gray11 '>
            {t('post.labels.duration')}:{' '}
          </span>
          <span className='text-gray10 '>
            {humanizeDuration(item.totalSeconds)}
          </span>
        </p>
      </div>
    )
  }

  const renderProgressIndicator = (item: ITatSummaryData, index: number) => {
    if (!tatSummary) return <></>
    return (
      <ProgressIndicator
        showTooltip
        tooltipText={renderDate(item)}
        key={index}
        color={item?.custom_status_info.color}
        value={(item.totalSeconds / tatSummary.totalSeconds) * 100}
      />
    )
  }

  if (!organizationCustomization?.show_tat_summary) return <></>
  if (loading && !tatSummary) return <></>
  if (errorMessage)
    return <Alert message={errorMessage} type='error' rounded={true} />
  if (!tatSummary || !tatSummary.data) return <></>

  return (
    <>
      <hr className='my-2 border-gray5' />
      <div className='flex items-center justify-between px-1'>
        <div className='flex items-center space-x-1'>
          <Typography.Text>{t('post.labels.tatSummaryTitle')}</Typography.Text>
          <UnstyledLink
            href={settingsHelpLinks.tatSummary || ''}
            target='_blank'
          >
            <Info
              weight='bold'
              className='h-4 w-4 text-gray9 hover:text-gray11 '
            />
          </UnstyledLink>
        </div>
        <span className='font-medium text-gray11'>{`${formatJSONTimestamp(
          tatSummary.start_date,
          'MMM dd'
        )} - ${
          tatSummary?.end_date
            ? formatJSONTimestamp(tatSummary?.end_date, 'MMM dd')
            : t('common.filters.date.dropdown.today')
        }`}</span>
      </div>
      <div className='flex items-center justify-center space-x-2 px-1'>
        <Progress max={tatSummary.totalSeconds}>
          {tatSummary.data.map(renderProgressIndicator)}
        </Progress>
        <Tooltip text={getCurrentStatusTooltipText()}>
          <Status status={post?.custom_status} iconOnly />
        </Tooltip>
      </div>
    </>
  )
}
