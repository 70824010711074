import clsx from 'clsx'
import type { ReactNode } from 'react'
import React, { useEffect, useState } from 'react'

interface IPropTypes {
  children: ReactNode
  isScroll?: boolean
  ref?: any
}
export default function MainContainer({
  children,
  isScroll: _isScroll,
}: IPropTypes) {
  const [isScroll, setIsScroll] = useState(_isScroll)

  useEffect(() => {
    setIsScroll(_isScroll)
  }, [_isScroll])

  return (
    <main
      id='main-container'
      className={clsx(
        'w-full flex-1',
        isScroll ? 'overflow-y-auto' : 'overflow-hidden'
      )}
    >
      {children}
    </main>
  )
}
