import type { IKeyValueMap } from 'mobx'

import type {
  IBulkActions,
  IBulkUpdatePayload,
} from '@/components/admin/posts/AdminPostBulkSelect'
import { POST_FILTER_KEYS } from '@/config/module/postsConstants'
import { ENTITIES, EventEmitter } from '@/lib/eventEmitter'
import {
  checkObjectOfArrayHasValuesMoreThanOne,
  isNullOrUndefined,
  minusExcept,
  objectHasProperty,
  removeKeyFromObject,
  removeNullUndefinedEmptyArray,
} from '@/lib/helpers/dataHelpers'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import { ADMIN_POST_FILTERS_KEY } from '@/lib/helpers/localStorageHelper'
import { slugify } from '@/lib/helpers/stringHelpers'
import { getIdxDBAllData, setIdxDBData } from '@/lib/localDB/localDB'
import type { PostListStore } from '@/stores/PostListStore'
import type { IBoardDetails } from '@/types/board'
import type { IBulkSelectConfig } from '@/types/common'
import type {
  ICustomState,
  IIntegrations,
  IOrganizationData,
  IOrganizationPlan,
} from '@/types/organization'
import type {
  IBulkUpdateData,
  INewPost,
  IPost,
  IPostListAPIParams,
  ITatSummary,
  ITatSummaryData,
  IVoteDataMap,
} from '@/types/post'
import type { IRoadmapItem } from '@/types/roadmap'
import type { INewSavedFilter, ISavedFilter } from '@/types/savedFilters'
import type { IUserProfile } from '@/types/user'

import {
  differenceBetweenDates,
  differenceBetweenDatesInSec,
} from '../dateHelpers'

// Keys used for post filters
const postFilterKeys = [
  'bucket_id',
  'user',
  'tag_id',
  'assignee_id',
  'submitter_id',
  'state',
  'status',
  'voted_by',
  'date_range',
  'sort',
  'domains',
  'viewed',
  'upvote_range',
  'downvote_range',
  'etc',
  'hide_closed',
]

// Post Status Criteria
const postStatusCriteria = [
  'under_review',
  'planned',
  'in_progress',
  'completed',
]

export const getPostURL = (baseURL: string, postSlug: string) =>
  `${baseURL}p/${postSlug}`

export const doesBoardHasSubmissionTemplate = (board?: IBoardDetails) => {
  if (!board) return false
  return (
    !!board.submission_template &&
    !!board.submission_template.length &&
    board.submission_template !== '<p></p>'
  )
}

export const defaultBoardInNewPost = (data: {
  buckets: IBoardDetails[]
  newPostData: INewPost
  globalSubmissionForm: any
  autoSelect: boolean
  boardId?: string
  userProfile?: IUserProfile
}): IBoardDetails | undefined => {
  const {
    buckets,
    newPostData,
    globalSubmissionForm,
    autoSelect,
    boardId,
    userProfile,
  } = data
  if (boardId) {
    const boardFromID = buckets?.find(
      (b) => b.id?.toString() === boardId.toString()
    )
    if (boardFromID) return boardFromID
  }
  if (newPostData.bucket_id) {
    const boardFromCurrentSelection = buckets?.find(
      (b) => b.id?.toString() === newPostData.bucket_id?.toString()
    )
    if (boardFromCurrentSelection) return boardFromCurrentSelection
  }
  if (!autoSelect && !boardId) {
    return { ...globalSubmissionForm }
  }
  if (userProfile?.is_admin_of_organization) {
    return buckets?.find((b) => b.default)
  }
  const writableBoard = buckets?.filter((b) => Boolean(!b?.read_only))
  if (writableBoard?.length) return writableBoard[0]
  return undefined
}

export const postPrepProcessor = (post: IPost) => {
  return {
    ...post,
    topTags: post?.tags?.slice(0, 3),
    totalTags: post?.tags?.length,
    moreTagsCount: post?.tags?.length > 3 ? (post?.tags || []).length - 3 : 0,
  }
}

export const checkAndFilterPost = (post: IPost, criteria: any = {}) => {
  let newPost = { ...post }
  if (!newPost.assignee)
    // @ts-ignore
    newPost = { ...newPost, assignee: { id: 'unassigned' } }
  if (
    criteria.status &&
    criteria.status.length &&
    !(criteria?.status && criteria?.status?.includes(newPost?.status?.value))
  )
    return false
  if (
    criteria.state &&
    criteria.state.length &&
    !(criteria.state && criteria.state.includes(newPost?.custom_status?.value))
  )
    return false

  if (criteria.assignee_id) {
    const assigneeIds = (
      Array.isArray(criteria.assignee_id)
        ? criteria.assignee_id
        : [criteria.assignee_id]
    ).map((a: any) => a.toString())
    if (assigneeIds.length) {
      return assigneeIds.includes(newPost?.assignee?.id.toString())
    }
    return false
  }
  if (
    criteria.approval_status?.length &&
    !(
      criteria.approval_status &&
      newPost.approval_status &&
      criteria.approval_status === newPost.approval_status
    )
  ) {
    return false
  }

  if (criteria.bucket_id) {
    const bucketIds = (
      Array.isArray(criteria.bucket_id)
        ? criteria.bucket_id
        : [criteria.bucket_id]
    ).map((b: any) => b.toString())
    if (bucketIds.length) {
      return bucketIds.includes(newPost?.bucket_id?.toString())
    }
    return false
  }

  if (criteria.user) {
    const userIds = (
      Array.isArray(criteria.user) ? criteria.user : [criteria.user]
    ).map((u: any) => u.toString())
    if (userIds.length) {
      return userIds.includes(newPost?.submitter?.id?.toString())
    }
    return false
  }
  if (
    // This check for All posts Page
    (!criteria.show_hidden && newPost.hidden) ||
    // This Check for Hidden and Moderation post
    (criteria.hidden && criteria.show_hidden && !newPost.hidden)
  ) {
    return false
  }
  return true
}

export const postBulkUpdate = (
  posts: IPost[],
  data: IBulkUpdateData,
  filters: any,
  globalFilters: any
) => {
  const { feature_request_ids, blacklist_ids, select_all, tag_id } =
    data.bulkUpdateData
  const { response } = data
  let postsToBeUpdatedIds = feature_request_ids
  if (select_all) {
    postsToBeUpdatedIds = [...posts]
      .filter((p) => !blacklist_ids.includes(p.id))
      .map((p) => p.id)
  }

  return posts
    .map((p) => {
      if (!postsToBeUpdatedIds.includes(p.id)) return p
      const isTagUntagged = Boolean(tag_id && tag_id === 'untagged')
      const newTags = response?.tag ? [...p.tags, response.tag] : p.tags
      const updatedPost = {
        ...p,
        ...response,
        ...postPrepProcessor({
          ...response,
          tags: isTagUntagged ? [] : newTags,
        } as IPost),
        status: response?.custom_status
          ? { ...response?.custom_status }
          : { ...p.status },
        tags: isTagUntagged ? [] : newTags,
        bucket_id: response?.bucket?.id || p.bucket_id,
      }
      setIdxDBData('POSTS', p.id, updatedPost, 'id')
      return updatedPost
    })
    .filter((p: any) => checkAndFilterPost(p, { ...filters, ...globalFilters }))
}

export const cleanSavedFilter = (savedFilter: INewSavedFilter) => {
  const filters = { ...savedFilter.filters }
  // eslint-disable-next-line no-restricted-syntax
  Object.keys(filters).map((key) => {
    if (Object.hasOwnProperty.call(filters, key)) {
      // @ts-ignore
      if (!POST_FILTER_KEYS.includes(key) || !filters[key]?.length)
        // @ts-ignore
        delete filters[key]
    }
    return filters
  })
  return {
    ...savedFilter,
    filters,
  }
}

export const getNewPostMentions = (
  userProfile: IUserProfile,
  buckets: IBoardDetails[],
  organization: IOrganizationData
) => {
  const bucketIds = buckets?.map((bucket) => `bucket_id=${bucket?.id}`)
  let facetFilters = []
  let numericFilters = [bucketIds, `organization_id=${organization?.id}`]
  if (userProfile && userProfile.is_csm_of_organization)
    facetFilters = [
      [
        'approval_status=approved',
        `submitter_id=${userProfile && userProfile.id}`,
      ],
    ]
  else if (userProfile) {
    facetFilters = [
      [
        'hidden=false',
        'approval_status=approved',
        `submitter_id=${userProfile && userProfile.id}`,
      ],
    ]
  } else {
    facetFilters = [['hidden=false', 'approval_status=approved']]
    numericFilters = [
      bucketIds,
      `organization_id=${organization?.id}`,
      'merged_with_id=0',
    ]
  }
  return [...facetFilters, ...numericFilters]
}
export const classifyETCPriority = (etcTimestamp: Date) =>
  differenceBetweenDates(new Date(), new Date(etcTimestamp))

// Update Counts For Tabs
export const updateTabCounts = (counts: any, data: IBulkUpdatePayload) => {
  const newCounts = { ...counts }
  const {
    actionType: type,
    fromTab,
    toTab,
    blacklist_ids,
    select_all,
    post,
  } = data
  if (!type || !fromTab) return newCounts
  if (type === 'update') {
    if (
      Boolean(toTab === 'hidden' || toTab === 'closed') &&
      postStatusCriteria.includes(fromTab)
    )
      newCounts.all -= 1
    if (
      Boolean(fromTab === 'hidden' || fromTab === 'closed') &&
      toTab &&
      postStatusCriteria.includes(toTab)
    )
      newCounts.all += 1
    if (fromTab === toTab && toTab) newCounts[toTab] = counts[toTab]
    if (fromTab !== toTab && toTab) {
      newCounts[fromTab] -= 1
      newCounts[toTab] += 1
    }
    if (
      select_all &&
      toTab &&
      Boolean(blacklist_ids && Boolean(blacklist_ids.length === 0))
    ) {
      newCounts[toTab] = newCounts[fromTab] + newCounts[toTab]
      newCounts[fromTab] = 0
    }
    if (select_all && blacklist_ids.length > 0 && toTab) {
      const oldCount = +newCounts[toTab] + newCounts[fromTab]
      newCounts[toTab] = +oldCount - blacklist_ids.length
      newCounts[fromTab] = blacklist_ids
    }
  }
  if (type === 'delete') {
    newCounts[fromTab] -= 1
    if (select_all && Boolean(blacklist_ids.length === 0))
      newCounts[fromTab] = 0
    if (select_all && blacklist_ids.length > 0) {
      newCounts[fromTab] = blacklist_ids
    }
    if (
      postStatusCriteria.includes(fromTab) &&
      (post?.approval_status === 'approved' || 'pending') &&
      !post?.hidden
    )
      newCounts.all -= 1
  }
  if (type === 'add') {
    newCounts[fromTab] += 1
    if (postStatusCriteria.includes(fromTab)) newCounts.all += 1
  }
  return newCounts
}

export const postToRoadmapItem = (post: IPost): IRoadmapItem => {
  return {
    ...post,
    laneId: post.status.value,
    post_created_at:
      post?.created_at?.timestamp?.toString() ?? post?.created_at?.toString(),
    etc_date: post?.etc_date?.timestamp ?? post?.etc_date,
    resource_id: post.id,
    user: post?.submitter,
  }
}

export const transformPostTatSummary = (
  tatSummary: ITatSummary | undefined | null
) => {
  if (!tatSummary) return null
  const cleanedTATSummary = { ...tatSummary }
  if (!cleanedTATSummary || !Object.keys(cleanedTATSummary).length) return null
  cleanedTATSummary.data = cleanedTATSummary.data
    .filter((item: ITatSummaryData) => item.end_date)
    .map((record: ITatSummaryData) => {
      // eslint-disable-next-line no-param-reassign
      record.totalSeconds = differenceBetweenDatesInSec(
        new Date(record?.start_date),
        new Date(record?.end_date)
      )
      return record
    })

  cleanedTATSummary.totalSeconds = cleanedTATSummary.data.reduce(
    (acc: any, record: ITatSummaryData) => acc + record.totalSeconds,
    0
  )
  return cleanedTATSummary
}

export const cleanAndGetAppliedFilters = (postFilter: IPostListAPIParams) => {
  const filters = removeNullUndefinedEmptyArray({ ...postFilter })

  return minusExcept(
    { ...filters },
    {
      sort: '',
      page: 1,
      postStatusPage: '',
    }
  )
}

export const updateUserList = (
  type: string,
  post: IPost,
  users: IUserProfile[],
  page: number
) => {
  let dataToBeUpdate: any = {}
  if (type === 'upvote') {
    dataToBeUpdate = {
      upvoters: page === 1 ? post.upvoters : [...post.upvoters, ...users],
    }
  }
  if (type === 'downvote') {
    dataToBeUpdate = {
      downvoters: page === 1 ? post.downvoters : [...post.downvoters, ...users],
    }
  }
  if (type === 'subscribers') {
    dataToBeUpdate = {
      subscribers:
        page === 1 ? post.subscribers : [...post.subscribers, ...users],
    }
  }
  return dataToBeUpdate
}

export const checkAndRemoveUser = (
  type: 'upvote' | 'downvote' | 'subscribers',
  post: IPost,
  user: IUserProfile
) => {
  let dataToBeUpdate: any = {}
  if (type === 'upvote') {
    dataToBeUpdate = {
      upvoters: post.upvoters.filter((u) => +u.id !== +Number(user.user_id)),
      votes_count_number: post.votes_count_number
        ? (post.votes_count_number || 0) - 1
        : post.votes_count_number,
      upvoted: false,
    }
  }
  if (type === 'downvote') {
    dataToBeUpdate = {
      downvoters: post.downvoters.filter(
        (u) => +u.id !== +Number(user.user_id)
      ),
      downvotes_count_number: post.downvotes_count_number
        ? (post.downvotes_count_number || 0) - 1
        : post.downvotes_count_number,
      downvoted: false,
    }
  }
  if (type === 'subscribers') {
    dataToBeUpdate = {
      subscribers: post.subscribers.filter((u) => +u.id !== +Number(user.id)),
      subscribers_count: post.subscribers_count - 1,
    }
  }
  return dataToBeUpdate
}

export const getPostBucket = (post: IPost, buckets: IBoardDetails[]) => {
  if (post.bucket) {
    return {
      ...post.bucket,
      ...buckets.find((bucket) => bucket.id === post.bucket_id),
    }
  }
  return buckets.find((bucket) => bucket.id === post.bucket_id)
}

export const getPostFiltersCacheKey = (pathName: string, query: any) => {
  let cacheKey = pathName
  Object.keys(query).forEach((key) => {
    if (pathName.includes(key)) {
      cacheKey = cacheKey.replace(`[${key}]`, query[key])
    }
  })
  return ADMIN_POST_FILTERS_KEY(slugify(cacheKey, 300, '-'))

  // if (pathName === '/admin/b/[boardSlug]/[[...singleBoardTabs]]')
  //   return ADMIN_SINGLE_BOARD_POST_FILTERS_KEY()
  // return ADMIN_POST_FILTERS_KEY(
  //   pathName.replace('/[[...postStatusPage]]', '').replaceAll('/', '-')
  // )
}

export const getBulkPostUpdateTranslationKey = (actions: IBulkActions) => {
  let translationKey = 'posts.bulkSelect.'
  if (actions?.state?.length) {
    translationKey = 'posts.bulkSelect.statusAlertDescription'
  }
  if (actions?.assignee_id?.length) {
    translationKey = 'posts.bulkSelect.assigneeAlertDescription'
  }
  if (actions?.tag_id?.length) {
    translationKey = 'posts.bulkSelect.tagAlertDescription'
  }
  if (actions?.bucket_id?.length) {
    translationKey = 'posts.bulkSelect.bucketAlertDescription'
  }
  if (checkObjectOfArrayHasValuesMoreThanOne(actions) > 1) {
    translationKey = 'posts.bulkSelect.alertDescription'
  }
  return translationKey
}

export const getBulkPostUpdateContent = (
  actions: IBulkActions,
  translation: any
) => {
  let content = ''
  Object.keys(actions).map((key: string, index: number) => {
    const action = (actions as any)[key]
    const actionsLength = Number(Object.keys(actions)?.length) - 1
    if (action?.length) {
      switch (key) {
        case 'bucket_id':
          content +=
            index === actionsLength
              ? ` & ${translation.labels.board} `
              : `, ${translation.labels.board}`
          break
        case 'state':
          content +=
            index === actionsLength
              ? ` & ${translation.labels.status}`
              : `, ${translation.labels.status}`
          break
        case 'assignee_id':
          content +=
            index === actionsLength
              ? ` & ${translation.labels.assignee} `
              : `, ${translation.labels.assignee}`
          break
        case 'tag_id':
          content +=
            index === actionsLength
              ? ` & ${translation.labels.tags}`
              : `, ${translation.labels.tags}`
          break
        default:
          break
      }
    }
    return true
  })
  return content.substring(1)
}

export const countOfMoreFilters = (filters: IKeyValueMap) => {
  const moreFilters = [
    'voted_by',
    'sentiment_type',
    'keywords',
    'source',
    'priority',
    'submitter_segment_ids',
    'voter_segment_ids',
  ]
  let count = 0

  Object.keys(filters).map((key) => {
    if (moreFilters.includes(key) && filters[key]?.length) {
      count += 1
    }
    return true
  })
  return count
}

export const updateSearchedPosts = (
  posts: IPost[],
  votesData: IVoteDataMap
) => {
  return posts.map((post) => ({
    ...post,
    ...(votesData[post.id.toString()] || {}),
    votes_count_number: parseInt(post.votes_count.toString(), 10),
    downvotes_count_number: parseInt(post.downvotes_count.toString(), 10),
    loadingVote: false,
  }))
}

export const getPostFromLocalWithIDs = (postIds: string[]) => {
  return getIdxDBAllData('POSTS').then((_posts: IPost[]) => {
    if (!_posts?.length) return {}
    const votesData: IVoteDataMap = {}
    _posts
      .filter((p) => postIds.includes(p.id.toString()))
      .map((p) => ({
        ...p,
        feature_request_id: p.id,
      }))
      .map((item) => {
        votesData[item.id.toString()] = item
        return true
      })
    return votesData
  })
}

export const sortSearchedPosts = (searchedPosts: IPost[], filters: any) => {
  let resultedPosts = [...searchedPosts]
  if (objectHasProperty(filters, 'status')) {
    resultedPosts = resultedPosts.filter((post) =>
      checkAndFilterPost(post, filters)
    )
  } else {
    resultedPosts = resultedPosts
      .sort(
        filters.sort === 'top'
          ? (a: IPost, b: IPost) =>
              parseInt(b.votes_count, 10) - parseInt(a.votes_count, 10)
          : undefined
      )
      .map((sPost: IPost) => ({
        ...sPost,
        short_description: sPost.text_description ?? sPost.description,
      })) as IPost[]
  }
  return resultedPosts
}

export const sortDBPosts = (dbPosts: IPost[], filters: any) => {
  let resultedPosts = [...dbPosts]
  if (objectHasProperty(filters, 'status')) {
    resultedPosts = resultedPosts.filter((post) =>
      checkAndFilterPost(post, filters)
    )
  } else {
    resultedPosts = resultedPosts.sort(
      filters.sort === 'top'
        ? (a: IPost, b: IPost) =>
            (b.votes_count_number || 0) - (a.votes_count_number || 0)
        : (a: IPost, b: IPost) => parseInt(b.id, 10) - parseInt(a.id, 10)
    )
  }
  return resultedPosts
}

export const handleNewPostErrors = (
  str: string,
  userProfile?: IUserProfile
) => {
  if (
    str === 'ERROR_CODE_POSTS_LIMIT_REACHED' &&
    userProfile?.is_admin_of_organization
  ) {
    return 'ERROR_CODE_POSTS_LIMIT_REACHED_ADMIN'
  }
  return str
}

export const postCheck = (
  posts: IPost[],
  selection: IBulkSelectConfig,
  event: KeyboardEvent,
  postId: string,
  postStore: PostListStore
) => {
  const { selectedIds, ignoredIds, selectedAll } = selection
  if (selectedAll) {
    if (ignoredIds.includes(postId)) {
      return postStore.checkPost(true, postId)
    }
    return postStore.checkPost(false, postId)
  }
  if (selectedIds.includes(postId)) {
    return postStore.checkPost(false, postId)
  }
  if (event.shiftKey) {
    const lastSelectedPost = selectedIds[selectedIds.length - 1] || '0'
    const postIds = posts.map((post) => post.id)
    const startIndex = postIds.indexOf(lastSelectedPost)
    const lastIndex = postIds.indexOf(postId)
    const selectedPostIds = postIds.map((id, index) => {
      if (index >= startIndex && index <= lastIndex) {
        return id
      }
      if (index <= startIndex && index >= lastIndex) {
        return id
      }
      return null
    })
    const cleanedSelectedPostIds = selectedPostIds.filter(Boolean)
    cleanedSelectedPostIds?.map((id) => {
      return postStore.checkPost(true, id as string)
    })
    return true
  }
  return postStore.checkPost(true, postId)
}

const updatePendingPostCountInContext = (
  post: IPost,
  bulkData: IBulkUpdatePayload,
  type: string,
  _posts: string[],
  updateContext: any,
  pendingPostsCount: number
) => {
  const postIsApproved = post?.approval_status === 'approved'
  let newPendingPostsCount = pendingPostsCount
  const postIsApprovedInBulkData = bulkData?.approval_status === 'approved'
  if (!postIsApproved && Boolean(postIsApprovedInBulkData || type === 'delete'))
    newPendingPostsCount -= _posts.length
  if (postIsApproved && Boolean(!postIsApprovedInBulkData || type === 'delete'))
    newPendingPostsCount += _posts.length

  if (updateContext) updateContext({ pendingPostsCount: newPendingPostsCount })
}

export const checkAndUpdateCount = (
  isModeration: boolean,
  bulkData: any,
  postsArray: IPost[],
  _posts: string[],
  type: string,
  updateContext: any,
  pendingPostsCount: number
) => {
  if (postsArray && postsArray.length) {
    _posts?.map((postId) => {
      const post = postsArray.find((p) => p.id === postId)
      const postIsApproved = post?.approval_status === 'approved'
      let fromTab
      let toTab

      if (!post) return null
      if (!isModeration) {
        fromTab = post?.hidden ? 'hidden' : post?.status?.value
        toTab = post?.hidden ? post?.status?.value : bulkData?.status
      } else {
        fromTab = postIsApproved ? 'approved_posts' : 'waiting_for_approval'
        toTab = postIsApproved ? 'waiting_for_approval' : 'approved_posts'
        updatePendingPostCountInContext(
          post,
          bulkData,
          type,
          _posts,
          updateContext,
          pendingPostsCount
        )
      }
      EventEmitter.dispatch('ENTITY_UPDATE', {
        entity: ENTITIES.POST_COUNTS,
        type,
        fromTab,
        toTab,
        post,
      })
      return true
    })
  }
}

export const bulkUpdatePostCounts = (
  data: any,
  isModeration: boolean,
  moderationAction: 'approve' | 'moveToPending' | undefined,
  updateContext: any,
  pendingPostsCount: number,
  filters: IPostListAPIParams,
  postStore: PostListStore,
  isSearchedPosts: boolean
) => {
  const { bulkUpdateData, type } = data

  if (isModeration && (bulkUpdateData.approval_status || type === 'delete')) {
    const { waiting_for_approval, approved_posts } = postStore.counts
    const currentTabCount =
      moderationAction === 'approve' ? waiting_for_approval : approved_posts

    const postsImpacted = bulkUpdateData?.select_all
      ? currentTabCount - (bulkUpdateData?.blacklist_ids?.length || 0)
      : bulkUpdateData?.feature_request_ids?.length || 0

    if (bulkUpdateData.approval_status === 'approved' && type === 'update') {
      updateContext?.({
        pendingPostsCount: pendingPostsCount - postsImpacted,
      })
      postStore.updateCounts({
        waiting_for_approval: waiting_for_approval - postsImpacted,
        approved_posts: approved_posts + postsImpacted,
      })
    } else if (
      bulkUpdateData.approval_status === 'pending' &&
      type === 'update'
    ) {
      updateContext?.({
        pendingPostsCount: pendingPostsCount + postsImpacted,
      })
      postStore.updateCounts({
        waiting_for_approval: waiting_for_approval + postsImpacted,
        approved_posts: approved_posts - postsImpacted,
      })
    } else if (type === 'delete') {
      if (moderationAction === 'approve') {
        updateContext?.({
          pendingPostsCount: pendingPostsCount - postsImpacted,
        })
        postStore.updateCounts({
          waiting_for_approval: waiting_for_approval - postsImpacted,
        })
      } else {
        postStore.updateCounts({
          approved_posts: approved_posts - postsImpacted,
        })
      }
    }

    return true
  }

  if (
    Boolean(!filters.hidden && typeof bulkUpdateData.status !== 'undefined') ||
    Boolean(objectHasProperty(bulkUpdateData, 'approval_status'))
  ) {
    if (!bulkUpdateData.select_all) {
      checkAndUpdateCount(
        isModeration,
        bulkUpdateData,
        isSearchedPosts ? postStore.searchedPosts || [] : postStore.posts,
        bulkUpdateData?.feature_request_ids,
        type,
        updateContext,
        pendingPostsCount
      )
    } else if (
      bulkUpdateData.select_all &&
      bulkUpdateData?.blacklist_ids?.length
    ) {
      checkAndUpdateCount(
        isModeration,
        bulkUpdateData,
        isSearchedPosts ? postStore.searchedPosts || [] : postStore.posts,
        (isSearchedPosts ? postStore.searchedPosts || [] : postStore.posts)
          .filter((p) => !bulkUpdateData?.blacklist_ids.includes(p.id))
          .map((p) => p.id),
        type,
        updateContext,
        pendingPostsCount
      )
    } else {
      checkAndUpdateCount(
        isModeration,
        bulkUpdateData,
        isSearchedPosts ? postStore.searchedPosts || [] : postStore.posts,
        (isSearchedPosts ? postStore.searchedPosts || [] : postStore.posts).map(
          (p) => p.id
        ),
        type,
        updateContext,
        pendingPostsCount
      )
    }
  }
  return true
}

export const canShowIntegrationBlock = (integrations: IIntegrations) => {
  return (
    integrations?.can_manage_asana ||
    integrations?.can_manage_click_up ||
    integrations?.can_manage_github ||
    integrations?.can_manage_linear ||
    integrations?.can_manage_gitlab
  )
}

export const cleanPostFilters = (filters: any) => {
  return removeKeyFromObject(
    ['postStatusPage', 'postModerationStatus'],
    filters
  )
}

export const checkStatusKeyAndUpdateAsState = (
  customStates: ICustomState[],
  savedFilters: ISavedFilter
) => {
  const { filters } = savedFilters
  const { status } = filters
  const newFilters = { ...filters }
  if (status?.length) {
    const statusData = [...status]
    const updatedStateData = statusData.map((state) => {
      const stateValue = customStates.find(
        (s) => s.status.toString() === state.toString()
      )?.status
      return stateValue
    })
    newFilters.state = updatedStateData
    delete newFilters.status
  }
  return {
    ...savedFilters,
    filters: newFilters,
  }
}

export const checkQueryHasAnyFilterKeys = (query: any) => {
  let hasFilter = false
  postFilterKeys.map((key) => {
    if (!isNullOrUndefined(query[key])) hasFilter = true
    return true
  })
  return hasFilter
}

export const checkUserCanAccessAI = (
  userProfile: IUserProfile,
  organizationPlan: IOrganizationPlan
) => {
  try {
    const allowedInPlan = featureIsEnabled('ai', organizationPlan, {})
    if (!allowedInPlan) return false
    if (!userProfile?.is_csm_of_organization) return true
    return true
  } catch {
    return false
  }
}

export const hasAICredits = (organizationPlan: IOrganizationPlan) => {
  return Number(organizationPlan?.power_ups.ai_credits?.limit) > 0
}
