import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'

import CreateAsanaTask from '@/components/posts/singlePost/postActions/integrations/asana/CreateAsanaTask'
import LinkAsanaTask from '@/components/posts/singlePost/postActions/integrations/asana/LinkAsanaTask'
import PushToButton from '@/components/posts/singlePost/postActions/integrations/PushToButton'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import Tabs from '@/components/shared/ui/Tabs'
import {
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/shared/ui/Tabs/Tabs'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import postStore from '@/stores/PostListStore'
import type { IAsanaTask } from '@/types/integration/asana'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
}

const PushToAsana = observer(({ post }: IPropTypes) => {
  const t = useTranslations(`post.integrations.asana`)
  const { integrations } = useContext(HNContext)
  const [show, setShow] = useState(false)
  const [canCreate, setCanCreate] = useState(post.can_push_to_asana)

  const handleCreate = (issue: IAsanaTask, action: string) => {
    if (action === `push`) setCanCreate(false)
    EventEmitter.dispatch('NEW_POST_ADDON', {
      provider: 'asana',
      addon: issue,
      action,
    })
    setShow(false)
    if (!post.can_push_to_asana && action === 'push') {
      postStore.updateSinglePost(post.slug, {
        ...post,
        can_push_to_asana: true,
      })
    }
  }

  useEffect(() => setCanCreate(post.can_push_to_asana), [post])

  if (!integrations?.can_manage_asana) return <></>
  return (
    <Dialog open={show}>
      <DialogTrigger asChild>
        <PushToButton
          onClick={() => setShow(true)}
          provider={`asana`}
          pushText={t(`pushToAsana`)}
          linkText={t(`linkAsana`)}
          canCreate={Boolean(canCreate)}
          onChangeCanCreate={(value: boolean) => setCanCreate(value)}
        />
      </DialogTrigger>
      <DialogContent size='lg' backdrop>
        <DialogTitle>{t(`title`)}</DialogTitle>
        <DialogDescription>{t(`description`)}</DialogDescription>
        <Tabs defaultValue={canCreate ? 'create' : 'link'}>
          <TabsList>
            {canCreate && (
              <TabsTrigger value='create'>
                <span>{t('create')}</span>
              </TabsTrigger>
            )}
            <TabsTrigger value='link'>
              <span>{t('link')}</span>
            </TabsTrigger>
          </TabsList>
          {canCreate && (
            <TabsContent value='create'>
              <CreateAsanaTask post={post} onCreate={handleCreate} />
            </TabsContent>
          )}
          <TabsContent value='link'>
            <LinkAsanaTask post={post} onLink={handleCreate} />
          </TabsContent>
        </Tabs>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
})

export default PushToAsana
