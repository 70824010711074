import { Chat, CheckCircle, Stack } from '@phosphor-icons/react'
import React, { useContext } from 'react'

import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import clsxm from '@/lib/clsxm'
import { objectHasProperty } from '@/lib/helpers/dataHelpers'
import type { IPost } from '@/types/post'

import type { IStatusProps } from './Status'
import Status from './Status'

interface IPropTypes {
  post: IPost
  className?: string
  statusProps?: Partial<IStatusProps>
}
const metaClasses =
  'px-1 py-0.5 rounded-full duration-150 space-x-0.5 whitespace-nowrap text-xs text-gray11 flex items-center justify-start'

export default function UserPostInfo({ post, statusProps = {} }: IPropTypes) {
  const t = useTranslations()
  const {
    userProfile,
    buckets = [],
    organizationSetting,
  } = useContext(HNContext)

  const bucket = buckets.find((bkt) => bkt.id === post.bucket_id)

  const renderStatus = () => {
    if (
      (userProfile?.is_csm_of_organization ||
        organizationSetting?.show_custom_states) &&
      objectHasProperty(post, 'custom_status')
    )
      return <Status status={post.custom_status} {...statusProps} />
    if (objectHasProperty(post, 'status'))
      return <Status status={post.status} {...statusProps} />
    return false
  }

  return (
    <div
      className='flex max-w-[100%] items-center gap-x-1 transition'
      data-testid='user_post_item_post_info'
    >
      <span className={clsxm(metaClasses, 'border border-gray5 px-2 py-0.5')}>
        {renderStatus()}
      </span>
      <span
        className={clsxm(
          metaClasses,
          'min-w-[10%] border border-gray5 px-2 py-0.5'
        )}
      >
        <Stack className='mr-0.5 h-3.5 w-3.5 shrink-0' size={14} />
        <span
          className='min-w-[10%] max-w-[100%] truncate font-normal text-gray11'
          data-testid='user_post_info_bucket_display_name'
          dangerouslySetInnerHTML={{
            __html: bucket?.display_name || post?.bucket?.display_name || '',
          }}
        />
      </span>
      <span className={clsxm(metaClasses, 'border border-gray5 px-2 py-0.5')}>
        <Chat className='mr-0.5 h-3.5 w-3.5' size={14} />
        <span
          className='font-normal text-gray11'
          data-testid='user_post_info_public_comment_count'
        >
          {post.comments_count || post.public_comments_count || 0}
        </span>
      </span>
      {Boolean(
        objectHasProperty(post, 'internal_comments_count') &&
          userProfile?.is_csm_of_organization
      ) && (
        <>
          <span
            className={clsxm(
              metaClasses,
              'border border-yellow9 px-2 py-0.5 dark:border-yellow8'
            )}
          >
            <Chat
              className='mr-0.5 h-3.5 w-3.5 text-yellow9 dark:text-yellow8'
              size={14}
            />
            <span
              className='font-semibold text-yellow9/60 dark:text-yellow8/60 '
              data-testid='user_post_info_internal_comment_count'
            >
              {post.internal_comments_count}
            </span>
          </span>
        </>
      )}
      {post.has_solution && (
        <span
          className={clsxm(
            metaClasses,
            'border border-green8 bg-green8 px-2 py-0.5'
          )}
        >
          <CheckCircle
            weight='fill'
            className='mr-0.5 h-3.5 w-3.5 text-snow dark:text-carbon'
            size={14}
          />
          <span
            className='font-normal text-snow dark:text-carbon'
            data-testid='user_post_info_public_comment_count'
          >
            {t('common.labels.answered')}
          </span>
        </span>
      )}
    </div>
  )
}
