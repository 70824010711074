import clsx from 'clsx'
import type { ReactNode } from 'react'
import React from 'react'

import Label from '@/components/shared/ui/Label'

interface ITeactareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  placeholder?: string
  children?: ReactNode
  disabled?: boolean
  // defaultValue?: ReactNode
  readOnly?: boolean
  id?: string
  label?: string
  onChange?: (event: any) => void
  containerClassName?: string
  inputRef?: React.RefObject<HTMLTextAreaElement>
}

export default function Textarea({
  placeholder,
  // defaultValue,
  id,
  children,
  disabled,
  readOnly = false,
  label,
  className,
  containerClassName,
  inputRef,
  ...rest
}: ITeactareaProps) {
  return (
    <div className={clsx('w-full', containerClassName)}>
      {label && (
        <Label className='mb-1' htmlfor={label || id}>
          {label}
        </Label>
      )}
      <textarea
        ref={inputRef}
        disabled={disabled}
        placeholder={placeholder}
        className={clsx(
          'relative block w-full rounded-lg border border-gray5 text-[14px] text-gray12 transition-colors  duration-200 placeholder:text-gray7 hover:border-brand focus:border-primary focus:ring-primary dark:border-gray-dark-border dark:bg-gray-dark  dark:hover:border-primary',
          className
        )}
        id={label || id}
        readOnly={readOnly}
        {...rest}
      >
        {children}
      </textarea>
    </div>
  )
}
