import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import Avatar from '@/components/shared/ui/Avatar'
import { stringifyValue } from '@/lib/helpers/stringHelpers'
import type { ISuggestionItem } from '@/types/editor'

import Badge from '../../../../ui/Badge'

interface IPropTypes {
  items: ISuggestionItem[]
  command: (item: any) => void
}
const MentionList = forwardRef((props: IPropTypes, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  const selectItem = (index: number) => {
    const item = props.items[index]

    if (item) {
      props.command({ id: item.id, label: item.label, href: item.href })
    }
  }

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    )
  }

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = () => {
    selectItem(selectedIndex)
  }

  useEffect(() => setSelectedIndex(0), [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }) => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        enterHandler()
        return true
      }

      return false
    },
  }))

  if (!props.items?.length) return <></>
  return (
    <div className='pointer-events-auto max-h-[450px] w-80 max-w-xs divide-y divide-gray5 overflow-y-auto overflow-x-hidden rounded border border-gray5 bg-snow shadow-sm focus:outline-none    '>
      {props.items.length ? (
        props.items.map((item, index) => (
          <button
            className={`flex w-full appearance-none items-center space-x-2 px-2.5 py-2 text-left text-sm font-medium hover:bg-gray4  
            ${index === selectedIndex ? 'bg-gray4 ' : ''}
            `}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.avatar && (
              <Avatar
                rounded
                size='sm'
                url={item.avatar?.url}
                name={stringifyValue(item.label)}
              />
            )}
            <div className='flex flex-col space-y-0.5 text-xs'>
              <div className='flex flex-col space-y-2'>
                <span className='line-clamp-2'>{item.label}</span>
                {item.bucket && (
                  <span>
                    <Badge value={item.bucket.display_name} size='xxs' />
                  </span>
                )}
              </div>
              {item.subLabel && (
                <span className='truncate text-gray8'>{item.subLabel}</span>
              )}
            </div>
          </button>
        ))
      ) : (
        <></>
      )}
    </div>
  )
})

MentionList.displayName = 'MentionList'
export default MentionList
