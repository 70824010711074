import { MagnifyingGlass, Plus } from '@phosphor-icons/react'
import clsx from 'clsx'
import type { ForwardedRef } from 'react'
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'

import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import Input from '@/components/shared/ui/Input'
import { Pill } from '@/components/shared/ui/Pill/Pill'
import Popover from '@/components/shared/ui/Popover'
import {
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/ui/Popover/Popover'
import { createJiraInsight, searchJiraIssues } from '@/models/Post'
import type { IComment } from '@/types/comment'
import type { IPost, IPostJiraIssue } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  comment?: IComment
  children: React.ReactNode
}

export interface IPushJiraInsightsActions {
  toggle: () => void
}

interface IJiraItemProps {
  issue: IPostJiraIssue
  onSelect?: (issue: IPostJiraIssue) => void
  className?: string
}

interface IDotRangeSelectorProps {
  total: number
  value?: number
  onSelect?: (index: number) => void
}

interface JiraPayload {
  title: string
  description: string
  value: number
  labels: string[]
  resource_type: 'Comment' | 'FeatureRequest'
  resource_id: number | string
  issue_id: number | string
  project_id: number | string
  impact: number
}

const DotRangeSelector = ({
  total,
  value: _value,
  onSelect,
}: IDotRangeSelectorProps) => {
  const items = Array.from({ length: total }, (_, i) => i + 1)
  const [highlighted, setHighlighted] = React.useState<number | null>(null)

  return (
    <div className='flex items-center space-x-2'>
      {items.map((item) => (
        <div
          role='button'
          key={item}
          onClick={() => onSelect?.(item)}
          className={clsx(
            'flex h-6 w-6 items-center justify-center rounded bg-primary/30 p-2 transition',
            {
              '!bg-primary text-snow': (highlighted || _value || -1) >= item,
            }
          )}
          onMouseEnter={() => setHighlighted(item)}
          onMouseLeave={() => setHighlighted(null)}
        >
          <p className='m-0'>{item}</p>
        </div>
      ))}
    </div>
  )
}

const JiraItem = ({ issue, onSelect, className }: IJiraItemProps) => {
  return (
    <li
      className={clsx(
        'flex items-center justify-between rounded p-2 hover:bg-gray4',
        className
      )}
    >
      <div className='flex flex-col gap-1'>
        <p className='text-md font-semibold'>{issue.title}</p>
        <div className='flex items-center gap-2'>
          <Pill variant='gray' className='px-2'>
            {issue.issue_id}
          </Pill>
          <p className='text-sm text-gray11'>{issue.status}</p>
        </div>
      </div>
      {onSelect && (
        <Button variant='outline' size='xs' onClick={() => onSelect?.(issue)}>
          Choose
        </Button>
      )}
    </li>
  )
}

const PushJiraInsights = forwardRef(
  (
    { post, comment, children }: IPropTypes,
    ref: ForwardedRef<IPushJiraInsightsActions>
  ) => {
    const [open, setOpen] = React.useState(false)
    const [jiraIdeas, setJiraIdeas] = React.useState<IPostJiraIssue[]>([])
    const timerRef = React.useRef<NodeJS.Timeout>()
    const [searching, setSearching] = React.useState(false)
    const [selectedIdea, setSelectedIdea] =
      React.useState<IPostJiraIssue | null>(null)
    const [formData, setFormData] = React.useState<Partial<JiraPayload>>({})
    const labelInputRef = React.useRef<HTMLInputElement>(null)
    const [creating, setCreating] = React.useState(false)

    const handleClose = () => {
      setSelectedIdea(null)
      setFormData({})
    }

    const handleLabelAdd = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const newLabelValue = labelInputRef.current?.value
      if (!newLabelValue) return
      setFormData({
        ...formData,
        labels: [...(formData.labels || []), newLabelValue],
      })
      labelInputRef.current!.value = ''
    }
    const handleSearch = (query: string) => {
      if (timerRef.current) clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
        setSearching(true)
        searchJiraIssues(post.slug, { query, only_ideas: true })
          .then((data) => data.issue_list)
          .then(setJiraIdeas)
          .catch(console.error)
          .finally(() => {
            setSearching(false)
          })
      }, 500)
    }

    const handleSelect = (idea: IPostJiraIssue) => setSelectedIdea(idea)

    const handleSubmit = () => {
      const payload: JiraPayload = {
        title: post.title,
        description: post.description,
        value: formData.value || 1,
        labels: formData.labels || [],
        resource_type: comment ? 'Comment' : 'FeatureRequest',
        resource_id: comment ? comment.id : post.id,
        issue_id: selectedIdea?.issue_unique_id || 0,
        project_id: selectedIdea?.project_id || 0,
        impact: formData.value || 0,
      }
      if (!payload.impact || payload.impact < 1 || payload.impact > 5)
        return toaster.error({ message: 'Please select an impact value' })
      setCreating(true)
      return createJiraInsight(post.slug, payload)
        .then(() => {
          setOpen(false)
          toaster.success({
            message: 'The insight has been created successfully',
          })
        })
        .catch(console.error)
        .finally(() => {
          setCreating(false)
        })
    }

    useImperativeHandle(ref, () => ({
      toggle: () => {
        setOpen(!open)
      },
    }))

    useEffect(() => {
      handleSearch('')
    }, [])

    const renderItems = () => {
      if (searching && !jiraIdeas)
        return <SpinnerIcon className='h-6 w-6 animate-spin' />
      if (!jiraIdeas.length)
        return (
          <div className='py-4'>
            <EmptyState
              icon={MagnifyingGlass}
              title={'No Ideas found'}
              description={
                'No ideas were found in Jira. Please try a different search query.'
              }
            />
          </div>
        )

      return (
        <ul className='flex flex-col gap-2'>
          {jiraIdeas.map((issue) => (
            <JiraItem
              key={issue.issue_id}
              issue={issue}
              onSelect={handleSelect}
            />
          ))}
        </ul>
      )
    }
    return (
      <Dialog
        open={open}
        onOpenChange={(status) => {
          setOpen(!!status)
          if (!status) return handleClose()
          return true
        }}
      >
        <DialogTrigger asChild>{children}</DialogTrigger>
        <DialogContent size='md'>
          <DialogTitle>Create Jira Insights</DialogTitle>
          <DialogDescription className='!mt-0'>
            Search for ideas in Jira and choose one to create an insight.
          </DialogDescription>
          <DialogClose />
          {selectedIdea ? (
            <div className='flex flex-col'>
              <JiraItem
                issue={selectedIdea}
                className='mt-4 rounded-lg border'
              />
              <div className='my-4 flex flex-col gap-2 divide-y divide-gray7 '>
                <div className='flex flex-col justify-between gap-2 py-2 pt-0'>
                  <p>Impact</p>
                  <p>
                    <DotRangeSelector
                      total={5}
                      value={formData.value}
                      onSelect={(val) => {
                        setFormData({ ...formData, value: val })
                      }}
                    />
                  </p>
                </div>
                <div className='flex flex-col justify-between gap-2 py-2'>
                  <p>Labels</p>
                  <div className='flex-1'>
                    <div className='flex flex-wrap items-center gap-1'>
                      <Popover>
                        <PopoverTrigger>
                          <Button
                            fab
                            icon={<Plus />}
                            size='xxs'
                            className='p-1'
                            rounded='full'
                            as='span'
                            variant='outline'
                          />
                        </PopoverTrigger>
                        <PopoverContent>
                          <form
                            className='flex items-center gap-2'
                            onSubmit={handleLabelAdd}
                          >
                            <Input
                              size='xs'
                              placeholder='Add a label'
                              inputRef={labelInputRef}
                            />
                            <Button size='xs' type='submit' variant='outline'>
                              Add
                            </Button>
                          </form>
                        </PopoverContent>
                      </Popover>
                      {formData.labels?.map((tag) => (
                        <Pill variant={'gray'} key={tag} className='px-2'>
                          {tag}
                        </Pill>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-between'>
                <Button
                  variant='outline'
                  onClick={() => {
                    setSelectedIdea(null)
                  }}
                >
                  Back
                </Button>
                <Button
                  size='sm'
                  variant='primary'
                  onClick={handleSubmit}
                  loading={creating}
                  disabled={creating}
                >
                  Create Insight
                </Button>
              </div>
            </div>
          ) : (
            <div className='mt-4'>
              <Input
                label='Search'
                placeholder='Search for Ideas in Jira'
                onChange={(e) => {
                  handleSearch(e.target.value)
                }}
                suffixicon={
                  searching ? (
                    <SpinnerIcon className='h-4 w-4 animate-spin' />
                  ) : (
                    <MagnifyingGlass />
                  )
                }
              />
              <div className='mt-4'>{renderItems()}</div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    )
  }
)

PushJiraInsights.displayName = 'PushJiraInsights'

export default PushJiraInsights
