export const STATUS_COLORS = [
  {
    value: 'default',
    hex: '#4353FF',
  },
  {
    value: 'gray',
    hex: '#71717A',
  },
  {
    value: 'red',
    hex: '#EF4444',
  },
  {
    value: 'yellow',
    hex: '#EAB308',
  },
  {
    value: 'green',
    hex: '#22C55E',
  },
  {
    value: 'cyan',
    hex: '#06B6D4',
  },
  {
    value: 'teal',
    hex: '#14B8A6',
  },
  {
    value: 'blue',
    hex: '#3B82F6',
  },
  {
    value: 'indigo',
    hex: '#6366F1',
  },
  {
    value: 'purple',
    hex: '#A855F7',
  },
  {
    value: 'rose',
    hex: '#EC4899',
  },
  {
    value: 'violet',
    hex: '#5d6acf',
  },
  {
    value: 'orange',
    hex: '#dc6e26',
  },
]

export const SENTIMENT_ANALYSIS_COLORS = [
  { value: 'positive', hex: '#46a758' },
  { value: 'negative', hex: '#dc3d43' },
  { value: 'neutral', hex: '#f5d90a' },
  { value: 'undetected', hex: '#71717A' },
]
