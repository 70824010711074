import RoadmapStatusPicker from '@/components/roadmap/multipleRoadmap/shared/RoadmapStatusPicker'
import IconPopover from '@/components/shared/components/IconPopover'
import { snakeToCamel } from '@/lib/helpers/stringHelpers'
import type {
  IActivityItem,
  IListResourceActivitiesResponse,
  IResourceActivityItem,
} from '@/types/activity'
import type { IDateTime } from '@/types/common'
import type { IRoadmap } from '@/types/roadmap'

import { formatJSONTimestamp } from '../dateHelpers'

export const transformActivity = (activity: IActivityItem) => {
  const newAttributes: {
    [key: string]: any
  } = {}
  switch (activity.activity_type) {
    case 'feature_request_updated':
      newAttributes.title = activity.feature_request.title
      break
    default:
      break
  }

  return {
    ...activity,
    ...newAttributes,
    dateLabel: formatJSONTimestamp(
      (activity.created_at as IDateTime).timestamp || activity.created_at,
      'PP'
    ),
  }
}

export const getActivityTypeLabel = (activity: IActivityItem) => {
  if (activity.activity_info.setting === 'archived') {
    return activity.activity_info.value ? 'archived' : 'unArchived'
  }
  if (activity.activity_info.setting === 'ai_assistance_enabled') {
    return 'aiEnabled'
  }
  return activity.activity_info.setting
}

export const activityValueResolver = (
  activity: IActivityItem,
  valueName: string,
  value: any
) => {
  const type = activity.activity || activity.activity_type
  if (
    type === 'roadmap_updated' ||
    type === 'feature_request_score_updated' ||
    type === 'changelog_updated'
  ) {
    switch (valueName) {
      case 'score_type':
        return `common.labels.scoreTypes.${snakeToCamel(value)}`
      case 'is_public':
        return value ? '{common.privacy.public}' : '{common.privacy.private}'
      case 'end_date':
      case 'start_date':
        return formatJSONTimestamp(value)
      case 'icon':
        return <IconPopover icon={value} size='xxs' variant='naked' readonly />
      case 'status':
        return (
          <RoadmapStatusPicker
            roadmap={{ status: value } as IRoadmap}
            showName
            readonly
          />
        )
      default:
        if (type === 'feature_request_score_updated') return value || '0'
        return value
    }
  } else if (type === 'organization_setting_activity') {
    switch (valueName) {
      case 'roadmap_type':
        return `roadmap.settings.roadmapType.options.${snakeToCamel(value)}`
      default:
        return value
    }
  }
  return value
}
export const activityFieldResolver = (
  activity: IActivityItem,
  fieldName: string
) => {
  const type = activity.activity || activity.activity_type
  if (type === 'roadmap_updated') {
    switch (fieldName) {
      case 'score_type':
        return `common.labels.scoreType`
      case 'is_public':
        return `common.privacy.title`
      case 'title':
      case 'description':
        return `common.labels.fields.${snakeToCamel(fieldName)}`
      case 'end_date':
      case 'start_date':
      case 'score':
        return `common.labels.fields.${snakeToCamel(fieldName)}`
      default:
        return fieldName
    }
  } else if (type === 'feature_request_score_updated') {
    return `roadmap.singleRoadmap.table.${snakeToCamel(fieldName)}`
  } else if (type === 'organization_setting_activity') {
    switch (fieldName) {
      case 'roadmap_type':
        return `common.labels.roadmapType`
      default:
        return fieldName
    }
  } else if (type === 'internal_resource_updated') {
    switch (fieldName) {
      case 'sentiment_type':
        return `post.sentiment.title`
      default:
        return fieldName
    }
  }
  return fieldName
}

export const activityEnhancer = (
  activity: IResourceActivityItem,
  sourceRecords: IListResourceActivitiesResponse['associative_records']
) => {
  const updatedActivity = { ...activity }
  const fromValue = activity.activity_info.from_value
  const toValue = activity.activity_info.to_value
  let finalValue: any[] = []
  const updatedColumn = activity.activity_info.updated_column

  if (Array.isArray(fromValue) && Array.isArray(toValue)) {
    const addedValue = toValue.filter((value) => !fromValue.includes(value))
    if (addedValue.length) {
      updatedActivity.action_type = 'updateAdded'
      updatedActivity.activity_info.valueArray = addedValue
      finalValue = addedValue
    }
    const removedValue = fromValue.filter((value) => !toValue.includes(value))
    if (removedValue.length) {
      updatedActivity.action_type = 'updateRemoved'
      updatedActivity.activity_info.valueArray = removedValue
      finalValue = removedValue
    }

    if (updatedColumn === 'tag_ids') {
      updatedActivity.column_kind = 'tags'
      updatedActivity.activity_info.tags = sourceRecords?.tag_ids?.filter(
        (tag) => finalValue.includes(tag.id)
      )
      updatedActivity.action_type = 'updateTags'
    }
    if (updatedColumn === 'author_ids') {
      updatedActivity.column_kind = 'authors'
      updatedActivity.activity_info.authors = sourceRecords?.author_ids?.filter(
        (author) => finalValue.includes(author.id)
      )
      updatedActivity.action_type = 'updateAuthors'
    }
    if (updatedColumn === 'assignee_ids') {
      updatedActivity.column_kind = 'assignee'
      updatedActivity.activity_info.assignee =
        sourceRecords?.assignee_ids?.filter((assignee) =>
          finalValue.includes(assignee.id)
        )
      updatedActivity.action_type = 'updateAssignee'
    }
    if (updatedColumn === 'category_id') {
      updatedActivity.column_kind = 'category'
      updatedActivity.activity_info.category = sourceRecords?.category_id?.find(
        (category) => finalValue.includes(category.id)
      )

      updatedActivity.action_type = 'updateCategory'
    }
  } else if (updatedColumn === 'category_id' && typeof toValue === 'number') {
    updatedActivity.column_kind = 'category'
    updatedActivity.activity_info.category = sourceRecords?.category_id?.find(
      (category) => toValue === category.id
    )
    updatedActivity.action_type = 'updateCategory'
  } else if (updatedColumn === 'privacy') {
    updatedActivity.column_kind = 'privacy'
  } else if (updatedColumn === 'status') {
    updatedActivity.column_kind = 'status'
  }
  return updatedActivity
}
