import React, { useMemo } from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
  ISelectPropTypes,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import type { IStatusSyncRule } from '@/types/integration/statusSync'

interface IAdminIntegrationAsanaProjectsDropDownProps {
  syncRule: IStatusSyncRule
  provider: string
  edit: boolean
  ruleKey: string
  onChange: (key: string, value: string) => void
  projects: ISelectOption[]
  size?: ISelectPropTypes['size']
}

export default function AdminIntegrationAsanaProjectsDropDown({
  syncRule,
  provider,
  edit,
  onChange,
  projects,
  ruleKey,
}: IAdminIntegrationAsanaProjectsDropDownProps) {
  const t = useTranslations(`integrations.${provider}`)
  const parseProjects = useMemo(
    () =>
      projects.map((project) => {
        return {
          ...project,
          label: project.name,
          value: project.gid || project.id,
        }
      }),
    [projects]
  )

  const handleChange = (value: ISelectOptionGroup) => {
    const selectedValue = getSelectedValueByKey(parseProjects || [], value)
    if (selectedValue && Array.isArray(selectedValue)) {
      onChange(ruleKey, selectedValue[0]?.value)
    } else {
      onChange(ruleKey, selectedValue?.value)
    }
  }

  return (
    <Select
      placeholder={t(`configure.pushRule.project.placeholder`)}
      searchPlaceholder={t(`configure.pushRule.project.searchPlaceholder`)}
      loading={false}
      value={setSelectedValue(parseProjects, syncRule.project_id || '')}
      searchable
      size='xs'
      options={parseProjects}
      onChange={handleChange}
      disabled={!edit}
    />
  )
}
