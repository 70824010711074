import type { AxiosRequestConfig } from 'axios'

import {
  ADMIN_SINGLE_BOARD_IMPORT_MEMBERS_PATH,
  ADMIN_SINGLE_BOARD_IMPORT_POSTS_PATH,
  ATTACHMENT_UPLOAD_PATH,
  EMOJI_LIST_PATH,
  GET_UPLOAD_PARAMS,
  HN_AI_COMPLETION_ENDPOINT,
  IMPORT_CUSTOMERS_MEMBERS_PATH,
  IMPORT_TEAM_MEMBERS_PATH,
  ORGANIZATION_DOMAINS_PATH,
  PROCESS_COMPLETED_EXTERNAL_ATTACHMENTS_UPLOAD,
  PROCESS_COMPLETED_INLINE_UPLOAD,
  UPLOAD_EDITOR_ATTACHMENT_DOWNLOAD_PATH,
  UPLOAD_EDITOR_ATTACHMENT_MULTIPLE_PATH,
  UPLOAD_EDITOR_ATTACHMENT_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import type { IEmojiListItem, IResourceTypes } from '@/types/common'
import type { IAttachment } from '@/types/organization'

export const uploadFile = (
  file: File,
  path: string,
  method: 'POST' | 'PUT',
  fileName: string
) => {
  const formData = new FormData()
  formData.append(fileName, file)
  if (method === 'POST') {
    return API.postWithFiles(path, formData)
  }
  return API.putWithFiles(path, formData)
}
export const updateTextField = (
  value: any,
  url: string,
  method: 'POST' | 'PUT'
) => {
  if (method === 'POST') {
    return API.post(url, value)
  }
  return API.put(url, value)
}

export const generateAttachmentUploadParams = (data: {
  file_name: string
  extension: string
  is_editor_attachment?: boolean
}): Promise<{
  url: string
  fields: Record<string, never>
  headers: Record<string, string | never>
  method: 'PUT'
}> => API.post(GET_UPLOAD_PARAMS, data)

interface IUploadParams {
  kind: string
  resource_id?: string | number
  resource_type?: IResourceTypes
  file_path: string
  size: number
  original_filename: string
}
export const processCompletedUpload = (
  data: IUploadParams
): Promise<{
  url: string
}> => {
  const url =
    data.kind === 'inline_videos'
      ? PROCESS_COMPLETED_INLINE_UPLOAD
      : PROCESS_COMPLETED_EXTERNAL_ATTACHMENTS_UPLOAD
  return API.post(url, data)
}

export const uploadAttachment = (formData: FormData) =>
  API.postWithFiles(UPLOAD_EDITOR_ATTACHMENT_PATH, formData)
export const removeAttachment = (url: string) =>
  API.delete(UPLOAD_EDITOR_ATTACHMENT_PATH, { attachment_url: url })

export const uploadGlobalAttachment = (formData: FormData) =>
  API.postWithFiles(ATTACHMENT_UPLOAD_PATH, formData)

export const uploadAttachments = (
  formData: FormData,
  options: AxiosRequestConfig = {}
): Promise<IAttachment[]> => {
  return API.postWithFiles(
    UPLOAD_EDITOR_ATTACHMENT_MULTIPLE_PATH,
    formData,
    options
  )
}
export const getAttachments = (
  id: string,
  type: IResourceTypes
): Promise<IAttachment[]> => {
  return API.get(UPLOAD_EDITOR_ATTACHMENT_PATH, {
    resource_id: id,
    resource_type: type,
  })
}

export const getAttachmentDownloadLink = (url: string) =>
  API.get(UPLOAD_EDITOR_ATTACHMENT_DOWNLOAD_PATH, { attachment_url: url })
export const removeAttachments = (attachments: string[]) =>
  API.delete(UPLOAD_EDITOR_ATTACHMENT_PATH, { attachments })

export const getSVG = (url: string) => API.get(url)

export const importData = (
  data: FormData,
  options: {
    type: 'board_posts' | 'board_members' | 'bulk_members' | 'bulk_customers'
    boardSlug?: string
  }
) => {
  let url = ''
  switch (options.type) {
    case 'board_posts': {
      if (!options.boardSlug)
        return Promise.reject(new Error('No bucket slug provided'))
      url = ADMIN_SINGLE_BOARD_IMPORT_POSTS_PATH(options.boardSlug)
      break
    }
    case 'board_members': {
      if (!options.boardSlug)
        return Promise.reject(new Error('No bucket slug provided'))
      url = ADMIN_SINGLE_BOARD_IMPORT_MEMBERS_PATH(options.boardSlug)
      break
    }
    case 'bulk_members': {
      url = IMPORT_TEAM_MEMBERS_PATH
      break
    }
    case 'bulk_customers': {
      url = IMPORT_CUSTOMERS_MEMBERS_PATH
      break
    }
    default: {
      break
    }
  }
  return API.postWithFiles(url, data)
}

export const getEmojiList = (): Promise<IEmojiListItem[]> =>
  API.get(EMOJI_LIST_PATH)

export const doAIcompletion = (
  text: string,
  resource?: { type: IResourceTypes; action: string }
) => {
  return API.post(HN_AI_COMPLETION_ENDPOINT, {
    text,
    resource,
  })
}

export const getOrganizationDomain = (query: string) =>
  API.get(ORGANIZATION_DOMAINS_PATH, { query })
