import React from 'react'

import clsxm from '@/lib/clsxm'

interface WrapperProps {
  className?: string
  children?: React.ReactNode
}

function Wrapper({ children, className }: WrapperProps): JSX.Element {
  return (
    <>
      <div
        id='main-section'
        className={clsxm('relative h-full w-full antialiased', className)}
      >
        {children}
      </div>
    </>
  )
}
export default Wrapper
