import { Bell } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useContext } from 'react'

import AdminNotificationsMini from '@/components/admin/shared/notifications/AdminNotificationsMini'
import Popover from '@/components/shared/ui/Popover'
import {
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/ui/Popover/Popover'
import HNContext from '@/context/HNContext'

export default function AdminNotificationsPopover() {
  const [open, setOpen] = React.useState(false)
  const { notificationsCount } = useContext(HNContext)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <div
          className={clsx(
            'hover:text-foreground relative my-1 flex h-[32px] w-[32px] cursor-pointer items-center justify-center gap-2 rounded-lg opacity-70 hover:bg-elementHighlight hover:opacity-100',
            { 'border-transparent': !open },
            { 'border-primary bg-gray5 text-primary': open }
          )}
        >
          <Bell size={16} />
          {!!notificationsCount && (
            <span className='absolute right-2 top-2 h-2 w-2 rounded-full bg-stateDanger' />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent
        side='right'
        className='!max-h-[600px] !min-h-[600px] !w-60 !min-w-[500px] !p-0'
      >
        <div>
          <AdminNotificationsMini
            onClick={() => {
              setOpen(false)
            }}
          />
        </div>
      </PopoverContent>
    </Popover>
  )
}
