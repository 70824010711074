import { WarningCircle } from '@phosphor-icons/react'
import clsx from 'clsx'
import type { ReactNode } from 'react'
import React, { useEffect, useState } from 'react'

import Spinner from '@/components/shared/ui/Loader'
import { useTranslations } from '@/hooks/useTranslations'
import { isPromise } from '@/lib/helpers/appHelpers'

import Dropzone from '../ui/Dropzone/Dropzone'
import { AddImageIcon } from '../ui/Icons'

interface IPropTypes {
  onDrop: (files: File[] | null) => Promise<any> | null
  message?: string
  icon?: ReactNode
  title?: string
  image?: File | string
  noBorder?: boolean
  accept?: string
  multiple?: boolean
  disabled?: boolean
  id?: string
}
export default function ImageDropzone(props: IPropTypes) {
  const {
    noBorder = false,
    title,
    icon,
    onDrop,
    accept = 'image/*',
    multiple,
    disabled = false,
    id = 'Dropzone-upload',
  } = props
  const t = useTranslations()
  const [imageURL, setImageURL] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const handleOnDrop = (files: File[]) => {
    const promise = onDrop(files)
    if (promise && isPromise(promise)) {
      setLoading(true)
      promise.finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    if (!props.image) {
      setImageURL(null)
    } else if (typeof props.image === 'string') {
      setImageURL(props.image)
    } else {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () => setImageURL(reader.result?.toString() || null),
        false
      )
      reader.readAsDataURL(props.image)
    }
  }, [props.image])

  return (
    <Dropzone {...props} onDrop={handleOnDrop} disabled={disabled}>
      <label
        htmlFor={id}
        className={clsx(
          'flex w-full cursor-pointer justify-center rounded-lg border-dashed border-gray6 transition focus-within:outline-none focus-within:ring-0 focus-within:ring-primary hover:border-gray7',
          { 'border-2 px-6 pb-8 pt-6 ': !noBorder }
        )}
      >
        {imageURL ? (
          <img src={imageURL} alt='Image' />
        ) : (
          <div className='space-y-1 text-center'>
            {loading ? (
              <Spinner />
            ) : (
              <>
                {icon ||
                  (!disabled ? (
                    <AddImageIcon className='mx-auto h-10 w-10 text-gray7' />
                  ) : (
                    <WarningCircle size={32} />
                  ))}
              </>
            )}

            <div className='flex justify-center text-sm text-gray9'>
              <span className='relative text-center font-medium text-primary'>
                <span>
                  {title || t('buttons.uploadImage', { ignorePrefix: true })}
                </span>
                <input
                  disabled={disabled}
                  id={id}
                  name='file-upload'
                  type='file'
                  className='sr-only'
                  accept={accept}
                  multiple={multiple}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const files: File[] = []
                    if (e.target.files) {
                      for (let i = 0; i < e.target.files.length; i += 1) {
                        if (e.target.files[i]?.type !== '') {
                          files.push(e.target.files[i] as File)
                        }
                      }
                    }
                    props.onDrop(files || null)
                    e.target.value = ''
                  }}
                />
              </span>
            </div>
            <p className='text-xs text-gray8'>{props.message}</p>
          </div>
        )}
      </label>
    </Dropzone>
  )
}
