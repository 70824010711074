import type { ReactNode } from 'react'
import React from 'react'

import Wrapper from '@/components/shared/ui/Wrapper/Wrapper'

interface IPropTypes {
  children: ReactNode
}

export default function ErrorLayout(props: IPropTypes) {
  return (
    <Wrapper>
      <>{props.children}</>
    </Wrapper>
  )
}
