import clsx from 'clsx'
import Link from 'next/link'
import React from 'react'

import type { IPostInsightsKeyword } from '@/types/post'

interface IPropTypes {
  keywords: IPostInsightsKeyword[]
}

const PILL_CLASS =
  'text-xs relative inline-flex items-center cursor-default  border border-gray6 bg-snow px-2 py-0.5 text-sm font-medium text-gray11 hover:bg-gray3 focus:z-10 focus:border-indigo8 focus:outline-none focus:ring-1 focus:ring-indigo8'

const KeywordItem = ({ keyword }: { keyword: IPostInsightsKeyword }) => {
  const total = keyword.total_count
  // const overallSentiment = useMemo(() => {
  //   return total ? Math.round((keyword.positive_count / total) * 100) : 0
  // }, [keyword.positive_count, keyword.negative_count])

  // return <Badge value={keyword.name} variant='outline' rounded data={'j'} />
  return (
    <Link
      className='isolate inline-flex items-center rounded-md'
      key={keyword.name}
      href={{
        pathname: '/admin',
        query: {
          keywords: [keyword.slug],
        },
      }}
      target='_blank'
    >
      <div
        className={clsx(PILL_CLASS, '-ml-px whitespace-nowrap rounded-l-md')}
      >
        {keyword.name}
      </div>
      <div
        className={clsx(
          PILL_CLASS,
          '-ml-px cursor-pointer whitespace-nowrap rounded-r-md bg-gray3'
        )}
      >
        {total}
      </div>
    </Link>
  )
}

export default function PostInsightKeywords({ keywords }: IPropTypes) {
  return (
    <div className='flex flex-wrap gap-1'>
      {keywords.map((keyword) => (
        <KeywordItem keyword={keyword} key={keyword.name} />
      ))}
    </div>
  )
}
