import Mousetrap from 'mousetrap'
import React, { useEffect } from 'react'

import { EventEmitter } from '@/lib/eventEmitter'

const SHORTCUTS = [
  {
    key: 'c',
    action: () =>
      EventEmitter.dispatch('SHORTCUT_TRIGGERED', { action: 'CREATE' }),
  },
  {
    key: 'e',
    action: () =>
      EventEmitter.dispatch('SHORTCUT_TRIGGERED', { action: 'EDIT' }),
  },
  {
    key: '/',
    action: () =>
      EventEmitter.dispatch('SHORTCUT_TRIGGERED', { action: 'SEARCH_FOCUS' }),
  },
  {
    key: 'esc',
    action: () =>
      EventEmitter.dispatch('SHORTCUT_TRIGGERED', { action: 'ESCAPE' }),
  },
  {
    key: ['[', ']'],
    action: () =>
      EventEmitter.dispatch('SHORTCUT_TRIGGERED', { action: 'TOGGLE_SIDEBAR' }),
  },
  {
    key: ['command+k', 'ctrl+k'],
    action: () =>
      EventEmitter.dispatch('SHORTCUT_TRIGGERED', {
        action: 'TOGGLE_COMMAND_PALLET',
      }),
  },
]

export default function KeyboardShortcutsEvents() {
  useEffect(() => {
    SHORTCUTS.map((shortcut) => {
      return Mousetrap.bind(shortcut.key, () => {
        shortcut.action()
        return false
      })
    })

    return () => {
      Mousetrap.reset()
    }
  }, [])

  return <></>
}
