import { LinkBreak } from '@phosphor-icons/react'
import React, { useContext, useEffect, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Badge from '@/components/shared/ui/Badge'
import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import NextImage from '@/components/shared/ui/Image/NextImage'
import Spinner from '@/components/shared/ui/Loader'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { getIntegrationInfo } from '@/lib/helpers/modules/integrationHelper'
import { pluralize, showAsCurrency } from '@/lib/helpers/stringHelpers'
import { getPostAddons, removePostAddon } from '@/models/Post'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import type { IPostOpportunityAddon } from '@/types/post/postAddon'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}
const OpportunityAddons = ({ post }: IPropTypes) => {
  const t = useTranslations('post.postAddons.salesforce')
  const { integrations } = useContext(HNContext)
  const integrationMapping = getIntegrationInfo('salesforce')
  const [show, toggleShow] = useState(false)
  const [opportunityAddons, setOpportunityAddons] = useState<
    IPostOpportunityAddon[]
  >([])

  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)

  const updatePostData = () => {
    postStore.updateSinglePost(post.slug, {
      ...post,
      opportunity_count: opportunityAddons.length,
      // add the total amount of all opportunities
      opportunity_amount: opportunityAddons.reduce(
        (a: number, b: IPostOpportunityAddon) => a + b.amount,
        0
      ),
    })
  }

  const fetchOpportunityAddons = () => {
    if (opportunityAddons.length > 0) {
      setIsSyncing(true)
    } else {
      setIsLoading(true)
    }
    return getPostAddons(post.slug, { provider: 'salesforce' })
      .then(setOpportunityAddons)
      .catch(toaster.error)
      .finally(() => {
        setIsSyncing(false)
        setIsLoading(false)
      })
  }

  const getAddonStatus = (addon: IPostOpportunityAddon) => {
    if (addon.closed) return t('opportunity.status.closed').toUpperCase()
    return t('opportunity.status.open').toUpperCase()
  }

  const handleRemoveAddon = (addon: IPostOpportunityAddon) => {
    return removePostAddon(post.slug, addon.id.toString())
      .then(() => {
        setOpportunityAddons(opportunityAddons.filter((o) => o.id !== addon.id))
      })
      .catch(toaster.error)
  }

  const renderOpportunities = (opportunity: IPostOpportunityAddon) => {
    if (opportunityAddons.length === 0) {
      return (
        <p className='flex items-center justify-center py-6'>
          {t(`opportunity.empty`)}
        </p>
      )
    }

    return (
      <tr className='border-y border-gray5 p-2 pt-1' key={opportunity.id}>
        <td className='p-2'>
          <div className='flex items-center space-x-2'>
            <span>{opportunity.name}</span>
            <span>
              <a
                href={opportunity.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                <NextImage
                  src={integrationMapping!.logo}
                  className='mt-0.5 flex shrink-0 transition'
                  alt='salesforce'
                  height='20'
                  width='20'
                />
              </a>
            </span>
          </div>
        </td>
        <td className='p-2'>
          <span>{opportunity.account_name}</span>
        </td>
        <td className='p-2'>
          <span>{showAsCurrency(opportunity.amount)}</span>
        </td>
        <td className='p-2'>
          {opportunity.won || !opportunity.closed ? (
            <Badge value={getAddonStatus(opportunity)} variant='green' />
          ) : (
            <Badge value={getAddonStatus(opportunity)} variant='red' />
          )}
        </td>
        <td className='p-2'>
          <AlertDialog
            type='danger'
            title={t(`opportunity.unlink.title`)}
            description={t(`opportunity.unlink.description`)}
            confirmText={t('buttons.unlink', {
              ignorePrefix: true,
            })}
            onConfirm={() => handleRemoveAddon(opportunity)}
          >
            <Button size='xxs' variant='outline'>
              <LinkBreak weight='bold' className='h-4 w-4' />
            </Button>
          </AlertDialog>
        </td>
      </tr>
    )
  }

  useEffect(() => {
    if (integrations?.can_manage_salesforce) fetchOpportunityAddons()
  }, [])

  useEffect(() => {
    if (show) fetchOpportunityAddons()
  }, [show])

  useEffect(() => {
    updatePostData()
  }, [opportunityAddons])

  if (!integrations?.can_manage_salesforce) {
    return <></>
  }

  return (
    <>
      <div className='col-span-5'>
        <Dialog
          open={show}
          onOpen={() => toggleShow(true)}
          onClose={() => toggleShow(false)}
        >
          <DialogTrigger asChild>
            <div className='flex w-full cursor-pointer items-center justify-between space-x-1 rounded bg-gray4 p-2 font-medium text-gray10 transition duration-200 hover:text-gray12   '>
              <span className='space-x-1'>
                <span className=''>{post.opportunity_count}</span>
                <span className='mr-2 truncate'>
                  {pluralize(
                    post.opportunity_count,
                    t('opportunity.title'),
                    t('opportunity.title_plural')
                  )}
                </span>
              </span>
              <span className='flex shrink-0'>
                {showAsCurrency(post.opportunity_amount)}
              </span>
            </div>
          </DialogTrigger>
          <DialogContent size='xl'>
            <DialogTitle>
              <div className='mr-8 flex items-center space-x-2'>
                {t(`opportunity.title_plural`)} (
                {showAsCurrency(post.opportunity_amount)})
                {isSyncing && <SpinnerIcon className='h-4 w-4 animate-spin' />}
              </div>
            </DialogTitle>
            {isLoading ? (
              <Spinner />
            ) : (
              <table className='w-full border-separate rounded-md border border-gray5 '>
                <thead className='rounded-md border-gray5 bg-gray3 p-2 pt-1 font-medium  '>
                  <td className='p-2'>
                    <p className=''>{t(`opportunity.fields.name`)}</p>
                  </td>
                  <td className='p-2'>
                    <p className=''>{t(`opportunity.fields.account`)}</p>
                  </td>
                  <td className='p-2'>
                    <p className=''>{t(`opportunity.fields.amount`)}</p>
                  </td>
                  <td className='p-2'>
                    <p className=''>{t(`opportunity.fields.status`)}</p>
                  </td>
                  <td className='p-2'></td>
                </thead>
                <tbody className=''>
                  {opportunityAddons.length > 0 ? (
                    opportunityAddons.map(renderOpportunities)
                  ) : (
                    <tr className='border-y border-gray5 p-2 pt-1'>
                      <td className='p-2' colSpan={5}>
                        <Typography.Text className='flex items-center justify-center py-2'>
                          {t(`opportunity.empty`)}
                        </Typography.Text>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}

export default OpportunityAddons
