import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import Button from '@/components/shared/ui/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import Label from '@/components/shared/ui/Label'
import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IProps {
  post: IPost
}

const options = [
  { value: 'Specs Finalized', label: 'Specs Finalized' },
  { value: 'Paused', label: 'Paused' },
  { value: 'Resolved', label: 'Resolved' },
  { value: 'Off-topic', label: 'Off-topic' },
  { value: 'Spam', label: 'Spam' },
]

export interface ISinglePostReadOnlyActions {
  toggleOpen: () => void
  unlockPost: () => void
}
const SinglePostReadOnlyActions = forwardRef(
  ({ post }: IProps, ref: ForwardedRef<ISinglePostReadOnlyActions>) => {
    const t = useTranslations()
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const formData = useRef<{ reason: string }>({
      reason: '',
    })

    const { userProfile } = useContext(HNContext)

    const handleLockPost = () => {
      if (!post) return null
      setLoading(true)
      return postStore
        .updatePost(
          post.slug,
          {
            read_only: !post.read_only,
            read_only_reason: formData.current.reason,
          },
          {
            ...post,
            read_only: !post.read_only,
            read_only_reason: formData.current.reason,
          }
        )
        .then(() => {
          setOpen(false)
          if (post.read_only) {
            toaster.success({ message: t('post.lockPost.unlockSuccess') })
          } else {
            toaster.success({ message: t('post.lockPost.success') })
          }
        })
        .catch(toaster.error)
        .finally(() => {
          setLoading(false)
        })
    }

    useImperativeHandle(ref, () => ({
      toggleOpen: () => setOpen(!open),
      unlockPost: () => {
        handleLockPost()
      },
    }))

    if (!userProfile?.is_member_of_organization) return null
    return (
      <>
        <Dialog onOpenChange={setOpen} open={open}>
          <DialogContent>
            <DialogClose />
            <DialogTitle>{t('post.lockPost.title')}</DialogTitle>
            <DialogDescription>
              {t('post.lockPost.description')}
            </DialogDescription>
            <div className='flex flex-col gap-4 py-4'>
              <div>
                <Label>{t('post.lockPost.fields.reason.label')}</Label>
                <Select
                  options={options}
                  placeholder={t('post.lockPost.fields.reason.placeholder')}
                  onChange={(selectd) => {
                    formData.current.reason = (selectd as ISelectOption).value
                  }}
                />
              </div>
              <Button
                onClick={handleLockPost}
                block
                size='sm'
                disabled={loading}
              >
                {t('post.labels.lockPost')}
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    )
  }
)

SinglePostReadOnlyActions.displayName = 'SinglePostReadOnlyActions'

export default SinglePostReadOnlyActions
