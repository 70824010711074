import Head from 'next/head'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'

import { DOMAIN } from '@/config/appConstants'
import type { IRootData } from '@/types/organization'
import { AppConfig } from '@/utils/AppConfig'

type IMetaProps = IRootData & {
  title?: string
  description?: string
  favicon?: string
  image?: string
  orgName?: string
}

const Meta = (props: IMetaProps) => {
  const router = useRouter()
  const isPrivate =
    props.organizationSetting?.search_privacy ||
    props.organizationSetting?.organization_privacy
  const title =
    router.asPath === '/'
      ? props.organization?.home_page_title || props.title
      : props.title || AppConfig.site_name

  return (
    <>
      <Head>
        <link rel='manifest' href='/manifest.json' />
        <meta charSet='UTF-8' key='charset' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1'
        />
        {/* Language Tags Start */}
        <meta
          httpEquiv='content-language'
          content={props.organizationSetting?.locale || 'en'}
        />

        {props?.organization && (
          <link
            rel='sitemap'
            type='application/xml'
            title='Sitemap'
            href={`https://app.${DOMAIN}/${props?.organization?.domain}/sitemap.xml`}
          />
        )}
        <meta
          name='google-site-verification'
          content='XfIAYGJWQtvzwgv73_Vu3tDPWHHr-23EJW6ZQHjzm3A'
        />
        <meta name='twitter:card' content='summary_large_image' />
        <link
          rel='apple-touch-icon'
          href={props.favicon || `${router.basePath}/apple-touch-icon.png`}
          key='apple'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href={props.favicon || `${router.basePath}/favicon-32x32.png`}
          key='icon32'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href={props.favicon || `${router.basePath}/favicon-16x16.png`}
          key='icon16'
        />
        <link
          rel='icon'
          href={props.favicon || `${router.basePath}/favicon.ico`}
          key='favicon'
        />
        {/* Privacy Tags Start */}
        <meta
          name='robots'
          content={isPrivate ? 'noindex,nofollow' : 'index,follow'}
        />
        {/* Privacy Tags End */}
      </Head>
      <DefaultSeo
        title={title}
        description={props.description}
        norobots={isPrivate}
        openGraph={{
          title,
          description: props.description,
          url: router.asPath,
          locale: AppConfig.locale,
          site_name: props.orgName || AppConfig.site_name,
          images: props.image ? [{ url: props.image }] : [],
        }}
      />
    </>
  )
}

export { Meta }
