import {
  addQuarters,
  differenceInDays,
  differenceInSeconds,
  format,
  formatDistance,
  fromUnixTime,
  getQuarter,
  getTime,
  getUnixTime,
  getYear,
  parse,
  parseISO,
  startOfQuarter,
  subDays,
} from 'date-fns'
import parseJSON from 'date-fns/parseJSON'
import setDefaultOptions from 'date-fns/setDefaultOptions'

import type { IKeyValueMap } from '@/types/common'

export const relativeDate = (timestamp: Date | string) =>
  formatDistance(new Date(parseISO(timestamp?.toString())), new Date(), {
    addSuffix: true,
  })

export const parseJSONTimestamp = (dateStr: string | Date) => {
  return parseJSON(dateStr)
}
export const parseStringTimestamp = (dateStr: string, formatStr: string) =>
  parse(dateStr, formatStr, new Date())

export const formatJSONTimestamp = (
  dateStr: string | Date,
  formatString: string = 'PP'
) =>
  dateStr ? format(parseJSONTimestamp(new Date(dateStr)), formatString) : ''

export const formatDate = (dateStr: Date, formatString: string = 'PP') =>
  dateStr ? format(dateStr, formatString) : ''

export const parseDate = (dateStr: string, formatString: string = 'PP') => {
  return parse(dateStr, formatString, new Date())
}
export const formatDateFromInteger = (
  date: number,
  formatString: string = 'PP'
) => (date ? format(date * 1000, formatString) : '')

export const timezoneInMinutes = () => new Date().getTimezoneOffset()

export const differenceBetweenDates = (d1: Date, d2: Date) =>
  differenceInDays(d2, d1)
export const differenceBetweenDatesInSec = (d1: Date, d2: Date) =>
  differenceInSeconds(d2, d1)

export const convertToUnixTime = (date: Date) => {
  if (typeof date === 'string') {
    return getUnixTime(new Date(date))
  }
  return getUnixTime(date)
}
export const parseUnixTimeToDate = (date: number) => fromUnixTime(date)
export const getTimeValue = (date: Date) => getTime(date)

export const setDateOptions = (data: IKeyValueMap) => {
  return setDefaultOptions(data)
}

export const checkIfDateIsInFuture = (date: any) => {
  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  return date ? differenceInDays(parseJSON(date), currentDate) > 0 : false
}

export const addDaysToDate = (date: Date, days: number) => {
  const newDate = new Date(new Date(date).setHours(0, 0, 0, 0))
  newDate.setDate(newDate.getDate() + days)
  return newDate
}

export const getISODayValue = (date: Date | number) => {
  return format(date, 'i')
}
export const humanizeDuration = (seconds: any) => {
  // Format distance to humanize the duration of max two levels
  return formatDistance(0, seconds * 1000, { includeSeconds: true })
}

export const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

export const dayOfYear = (date: Date) => {
  const j1 = new Date(date).setMonth(0, 0)
  return Math.round((date.getTime() - j1) / 8.64e7)
}

// Start of the date
export const startOfDay = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}

export const processDateRange = (range: any) => {
  const newRange: IKeyValueMap = {
    from: undefined,
    to: undefined,
  }
  if (range?.from) {
    newRange.from = new Date(range.from)
  }
  if (range?.to) {
    newRange.to = new Date(range.to)
  }
  return newRange
}

export const getNextMonthWithSpecificDate = (date: number) => {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth() + 1, date)
}

export const subtractDays = (date: Date, days: number) => subDays(date, days)

export const isValidDate = (date: Date) => {
  try {
    return date instanceof Date && date.getTime() > 0
  } catch {
    return false
  }
}

export const geneterateQuaterlyArray = (numerOfQuaters: number = 4) => {
  const quarterArr = []
  const currentDate = new Date()

  // Get the current quarter and year
  const currentQuarter = getQuarter(currentDate)
  const currentYear = getYear(currentDate)

  // Add the current quarter and year to the array
  quarterArr.push({
    value: `${currentQuarter}-${currentYear}`,
    label: `Q${currentQuarter} - ${currentYear}`,
  })

  // Loop through the next three quarters and add them to the array
  for (let i = 1; i <= numerOfQuaters - 1; i += 1) {
    const nextQuarter = addQuarters(startOfQuarter(currentDate), i)
    const nextQuarterNum = getQuarter(nextQuarter)
    const nextYear = getYear(nextQuarter)
    quarterArr.push({
      value: `${nextQuarterNum}-${nextYear}`,
      label: `Q${nextQuarterNum} - ${nextYear}`,
    })
  }
  return quarterArr
}

export const generateMonthlyArray = (numerOfMonths: number = 12) => {
  const monthArr = []
  const currentDate = new Date()

  // Get the current quarter and year
  const currentMonth = formatDate(currentDate, 'M')
  const currentYear = getYear(currentDate)
  const currentMonthName = format(currentDate, 'MMM')

  // Add the current quarter and year to the array
  monthArr.push({
    value: `${currentMonth}-${currentYear}`,
    label: `${currentMonthName}-${currentYear}`,
  })

  // Loop through the next three quarters and add them to the array
  for (let i = 1; i <= numerOfMonths - 1; i += 1) {
    const nextMonth = new Date(currentDate)
    nextMonth.setMonth(currentDate.getMonth() + i)
    const nextMonthNum = formatDate(nextMonth, 'M')
    const nextYear = getYear(nextMonth)

    const nextMonthName = format(nextMonth, 'MMM')
    monthArr.push({
      value: `${nextMonthNum}-${nextYear}`,
      label: `${nextMonthName}-${nextYear}`,
    })
  }
  return monthArr
}

export const intialDateRange = [
  formatDate(addDaysToDate(new Date(), -30), 'yyyy-MM-dd'),
  formatDate(new Date(), 'yyyy-MM-dd'),
].join(',')

export const isDate = (date: any) => {
  try {
    if (typeof date === 'string') {
      return !Number.isNaN(new Date(date).getTime())
    }
    return !Number.isNaN(date.getTime())
  } catch {
    return false
  }
}
