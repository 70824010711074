import { ArrowRight, CheckCircle } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'

import AdminIntegrationClickupListSelector from '@/components/admin/settings/Integrations/config/ClickUp/AdminIntegrationClickupListSelector'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Spinner from '@/components/shared/ui/Loader'
import Select from '@/components/shared/ui/Select'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getCustomFields,
  resetClickupCustomFields,
  updateClickupCustomFields,
} from '@/models/integration/clickUp'
import type {
  IClickUpConfig,
  IClickUpCustomField,
  IClickUpIntegrationConfig,
} from '@/types/integration/click_up'
import toaster from '@/utils/toast'

interface IPropTypes {
  configData: IClickUpIntegrationConfig
}

export default function AdminIntegrationClickUpCustomFieldsConfig({
  configData: _configData,
}: IPropTypes) {
  const t = useTranslations('integrations.clickUp.configure.customFields')

  const [configData, setConfigData] = useState<IClickUpConfig>(
    _configData.config
  )
  const { custom_fields_config, custom_field_rules } = configData
  const [selectedList, setSelectedList] = useState<string | null>(null)
  const [listFields, setListFields] = useState<IClickUpCustomField[]>([])
  const [fieldsLoading, setFieldsLoading] = useState(false)
  const [fieldsMap, setFieldsMap] = useState<{ [key: string]: string }>({})
  const [updating, setUpdating] = useState(false)
  const [resetting, setResetting] = useState(false)
  const [duplicatedFields, setDuplicatedFields] = useState<string[]>([])

  const fieldsToMap = useMemo(() => {
    return Object.keys(custom_fields_config).map((key) => ({
      label: custom_fields_config[key]?.label,
      key,
      matchable_type: custom_fields_config[key]?.matchable_type || [],
    }))
  }, [custom_fields_config])

  const currentListRules = useMemo(() => {
    if (!selectedList) return []
    return custom_field_rules[selectedList] || []
  }, [custom_field_rules, selectedList])

  const cleanedListFields = useMemo(() => {
    return listFields.map((field) => ({
      label: field.name,
      value: field.id,
      ...field,
    }))
  }, [listFields])

  const handleSubmit = () => {
    if (!selectedList) return Promise.resolve()
    setDuplicatedFields([])
    const fieldsMapConfig: {
      [key: string]: string[]
    } = {}

    Object.keys(fieldsMap).forEach((key) => {
      const value = fieldsMap[key]
      if (value && key && key !== 'undefined' && value !== 'undefined') {
        fieldsMapConfig[value] = fieldsMapConfig[value] || []
        fieldsMapConfig[value]?.push(key)
      }
    })

    const dFields = Object.values(fieldsMapConfig).filter((f) => f.length > 1)

    if (dFields.length > 0) {
      setDuplicatedFields(dFields.flat())
      toaster.error({ message: t('message.duplicateField') })
      return Promise.resolve()
    }

    const payload = {
      list_id: selectedList,
      config_data: Object.keys(fieldsMap)
        .filter((key) => !!fieldsMap[key])
        .map((key) => ({
          custom_field_id: fieldsMap[key] as string,
          mapped_value: key,
          custom_field_type: listFields.find((f) => f.id === fieldsMap[key])
            ?.type as string,
        }))
        .filter(
          (f) =>
            f.custom_field_id &&
            f.mapped_value &&
            f.mapped_value !== 'undefined'
        ),
    }

    setUpdating(true)
    return updateClickupCustomFields(payload)
      .then(() => {
        toaster.success({ message: t('message.reseted') })
      })
      .catch(toaster.error)
      .finally(() => {
        setUpdating(false)
      })
  }

  const handleReset = () => {
    if (!selectedList) return Promise.resolve()
    setResetting(true)
    setDuplicatedFields([])
    return resetClickupCustomFields(selectedList)
      .then(() => {
        setConfigData((prev) => ({
          ...prev,
          custom_field_rules: {
            ...prev.custom_field_rules,
            [selectedList]: [],
          },
        }))
        toaster.success({ message: t('message.reseted') })
        setSelectedList(null)
      })
      .catch(toaster.error)
      .finally(() => {
        setResetting(false)
      })
  }

  const fetchData = async () => {
    if (!selectedList) return
    setFieldsMap({})
    setFieldsLoading(true)
    getCustomFields(selectedList)
      .then((data) => {
        setListFields(data)
        setFieldsMap({})
        data.map((field) => {
          const selectedValue = currentListRules.find(
            (r) => r.custom_field_id === field.id
          )
          setFieldsMap((prev) => ({
            ...prev,
            [selectedValue?.mapped_value as string]: field.id,
          }))
          return null
        })
      })
      .catch(toaster.error)
      .finally(() => {
        setFieldsLoading(false)
      })
  }

  useEffect(() => {
    fetchData()
  }, [selectedList])

  const renderFieldItem = (field: typeof fieldsToMap[0]) => {
    const selectedValue = cleanedListFields.filter(
      (f) => f.id === fieldsMap[field.key]
    )
    return (
      <div
        className='grid grid-cols-3 items-center gap-2 rounded-md border border-gray6 bg-gray2 p-2'
        key={field.key}
      >
        <div className='flex items-center gap-1 justify-self-start'>
          {selectedValue && selectedValue.length > 0 ? (
            <CheckCircle weight={'bold'} size={18} className='text-green10' />
          ) : (
            <CheckCircle weight={'bold'} size={18} className='text-gray8' />
          )}
          <p>{field.label}</p>
        </div>
        <div className='justify-self-center'>
          <ArrowRight size={20} />
        </div>
        <div className='justify-self-end'>
          <Select
            className={clsx({
              'border-2 border-red10': duplicatedFields.includes(field.key),
            })}
            emptyMessage={`No ${field.matchable_type} fields found`}
            disabled={!selectedList && !fieldsLoading}
            value={selectedValue}
            clearable
            options={cleanedListFields.filter((f) =>
              field.matchable_type.includes(f.type)
            )}
            onChange={(value) => {
              const firstValue = Array.isArray(value) ? value[0] : value
              setFieldsMap((prev) => ({
                ...prev,
                [field.key]: firstValue?.value,
              }))
            }}
            placeholder={t('labels.selectField')}
          />
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className='flex items-center justify-between py-2'>
        <div>
          <Typography.Text className='!text-sm !font-medium !text-carbon '>
            {t('title')}
          </Typography.Text>
          <br />
          <Typography.Text className='mb-3 mt-1 text-gray8'>
            {t('description')}
          </Typography.Text>
        </div>

        <div>
          {/* <Select
            placeholder={t('labels.selectList')}
            value={configData.lists
              .filter((l) => l.id === selectedList)
              .map((list) => ({
                label: list.name,
                value: list.id,
              }))}
            options={configData.lists.map((list) => ({
              label: list.name,
              value: list.id,
            }))}
            onChange={(value) => {
              const firstValue = Array.isArray(value) ? value[0] : value
              if (firstValue) setSelectedList(firstValue.value)
            }}
          /> */}
          <AdminIntegrationClickupListSelector
            config={configData}
            value={configData.lists
              .filter((l) => l.id === selectedList)
              .map((list) => ({
                label: list.name,
                value: list.id,
              }))}
            onChange={(value) => {
              const firstValue = Array.isArray(value) ? value[0] : value
              if (firstValue) setSelectedList(firstValue.value)
            }}
          />
        </div>
      </div>
      <div className='relative'>
        {!selectedList && (
          <div className='absolute z-10 flex h-full w-full flex-col items-center justify-center gap-2 bg-gray3'>
            <Typography.Text>{t('message.selectList')}</Typography.Text>
          </div>
        )}
        {fieldsLoading && (
          <div className='absolute z-10 flex h-full w-full flex-col items-center justify-center gap-1 bg-gray3'>
            <Spinner />
            <Typography.Text>{t('message.fetchingFields')}</Typography.Text>
          </div>
        )}
        <div
          className={clsx('-mx-4 flex flex-col gap-2 rounded-md p-4', {
            'opacity-50': !selectedList || fieldsLoading,
          })}
        >
          <div className='grid grid-cols-2 gap-x-4 gap-y-2'>
            {fieldsToMap.map(renderFieldItem)}
          </div>
          <div className='mt-4 flex items-center justify-end gap-2'>
            <AlertDialog
              title={t('reset.title')}
              description={t('reset.description')}
              onConfirm={handleReset}
              type='danger'
            >
              <Button
                variant='danger'
                size='sm'
                loading={resetting}
                disabled={resetting}
              >
                {t('buttons.reset', { ignorePrefix: true })}
              </Button>
            </AlertDialog>
            <Button
              variant='primary'
              size='sm'
              onClick={handleSubmit}
              loading={updating}
              disabled={updating}
            >
              {t('buttons.save', { ignorePrefix: true })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
