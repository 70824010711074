import {
  ArrowLeft,
  GitPullRequest,
  PencilSimpleLine,
} from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import { useContext, useEffect, useRef } from 'react'

import type { IBulkMergeDialogRef } from '@/components/admin/posts/merge/BulkMergeDialog'
import BulkMergeDialog from '@/components/admin/posts/merge/BulkMergeDialog'
import AdminHeader from '@/components/admin/shared/AdminHeader'
import SinglePostAIAnalyzeButton from '@/components/posts/singlePost/postActions/SinglePostAIAnalyzeButton'
import PageTitle from '@/components/shared/common/PageTitle'
import AIWand from '@/components/shared/icons/AIWand'
import Button from '@/components/shared/ui/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { pushQueryToMatchRoute } from '@/lib/helpers/pathHelpers'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'

import SinglePostCopyLinkAction from './postActions/SinglePostCopyLinkAction'
import SinglePostHideToggle from './postActions/SinglePostHideToggle'
import SinglePostManageOptions from './postActions/SinglePostManageOptions'
import SinglePostModerationActions from './postActions/SinglePostModerationActions'
import SinglePostPinOption from './postActions/SinglePostPinOption'
import SinglePostViewAction from './postActions/SinglePostViewAction'
import SinglePostViewToggle from './postActions/SinglePostViewedToggle'

interface IPropTypes {
  post: IPost
  onClose?: () => void
  onEdit: () => void
}

export default function AdminSinglePostHeader(props: IPropTypes) {
  const { post, onClose, onEdit } = props
  const t = useTranslations()
  const router = useRouter()
  const { userProfile, isAdmin } = useContext(HNContext)
  const bulkMergeDialogRef = useRef<IBulkMergeDialogRef>(null)

  const handleClosePost = () => {
    if (onClose) onClose()
  }

  useEffect(() => {
    if (router.query.edit === 'true') onEdit()
  }, [router.query.postSlug])

  const handleMergeCompletion = (response: IPost) => {
    if (router.pathname !== '/roadmap') {
      router.replace(
        `${pushQueryToMatchRoute(response?.slug, router.query)}`,
        isAdmin ? `/admin/p/${response.slug}` : `/p/${response.slug}`,
        {
          shallow: true,
        }
      )
    }
    if (router.pathname === '/roadmap') {
      router.replace(
        `${pushQueryToMatchRoute(response?.slug, router.query)}`,
        `/p/${response.slug}`,
        {
          shallow: true,
        }
      )
    }

    postStore.updateSinglePost(response.slug, response)
  }

  return (
    <>
      <PageTitle title={post.title} />
      <AdminHeader
        isHeader
        leftComponent={
          <Button
            fab
            variant='outline'
            onClick={handleClosePost}
            icon={<ArrowLeft weight='bold' />}
          />
        }
        rightComponent={
          <>
            {!!(
              post.sentiment_type === 'unprocessed' &&
              userProfile?.is_member_of_organization &&
              isAdmin
            ) && (
              <SinglePostAIAnalyzeButton
                post={post}
                buttonProps={{
                  variant: 'outline',
                  children: 'AI Process',
                  icon: <AIWand className='h-3.5 w-3.5' />,
                }}
              />
            )}

            {post?.can_merge && (
              <>
                <Button
                  size='xs'
                  isResponsive
                  variant='outline'
                  className={'group !text-gray12/80 hover:text-gray12'}
                  onClick={() => bulkMergeDialogRef.current?.openWithPosts([])}
                  icon={
                    <GitPullRequest
                      weight='fill'
                      className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
                    />
                  }
                >
                  {t('post.mergePost.mergeButtonText')}
                </Button>
                <BulkMergeDialog
                  incomingPost={post}
                  onComplete={handleMergeCompletion}
                  ref={bulkMergeDialogRef}
                />
              </>
            )}
            <SinglePostPinOption post={post} />
            {post.can_edit && (
              <Button
                variant='outline'
                isResponsive
                size='xs'
                className={'group !text-gray12/80 hover:text-gray12'}
                icon={
                  <PencilSimpleLine
                    className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
                    weight='fill'
                  />
                }
                onClick={onEdit}
              >
                {t('post.labels.editPost')}
              </Button>
            )}
            <SinglePostModerationActions post={post} />
            <SinglePostHideToggle post={post} />
            <SinglePostViewToggle post={post} />
            <SinglePostCopyLinkAction post={post} />
            <SinglePostViewAction post={post} />
            <SinglePostManageOptions
              post={post}
              onEdit={onEdit}
              onClose={onClose}
            />
          </>
        }
      />
    </>
  )
}
