import React, { useContext, useEffect, useState } from 'react'

import AuthenticatedView from '@/components/auth/shared/AuthenticatedView'
import Accordion from '@/components/shared/ui/Accordion'
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/shared/ui/Accordion/Accordion'
import AvatarGroup from '@/components/shared/ui/AvatarGroup'
import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { cleanUserForSegment, recordEvent } from '@/lib/helpers/appHelpers'
import { getIndexInArray } from '@/lib/helpers/dataHelpers'
import { checkAndRemoveUser } from '@/lib/helpers/modules/postHelper'
import { adminSubscribeUserAction } from '@/models/Post'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

import SinglePostUserListModal from '../SinglePostUserListModal'

interface IPropTypes {
  post: IPost
}

export default function SinglePostSubscribeAction({ post }: IPropTypes) {
  const t = useTranslations()
  const { userSignedIn, userProfile } = useContext(HNContext)
  const [email, setEmail] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [postData, setPostData] = useState<IPost>(post)

  const handleAvatarClick = () => {
    if (userSignedIn && userProfile?.is_organization_member) {
      setOpen(true)
    }
  }

  const handleSubscribe: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault()
    if (!email?.length) return false
    const idx = getIndexInArray(email, 'email', postData.subscribers)
    if (idx >= 0)
      return toaster.success({
        message: t('post.messages.subscribeUser.alreadySubscribed'),
      })
    setIsSubmitting(true)
    recordEvent('POST_ADMIN_SUBSCRIBE', {
      post_id: postData?.id,
      post_slug: postData?.slug,
      post_title: postData?.title,
      email,
      user: cleanUserForSegment(userProfile),
    })
    return adminSubscribeUserAction(postData.slug, { email })
      .then((response) => {
        const dataToBeUpdate = {
          subscribers_count: postData.subscribers_count + 1,
          subscribers: [...postData.subscribers, response],
        }
        postStore.updateSinglePost(postData.slug, dataToBeUpdate as IPost)
        toaster.success({
          message: t('post.messages.subscribeUser.subscribed'),
        })
        setEmail('')
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsSubmitting(false))
  }

  const handleRemoveUser = (removedUser: IUserProfile) => {
    const updatedPost = checkAndRemoveUser('subscribers', postData, removedUser)
    setPostData((prev) => ({ ...prev, ...updatedPost }))
    postStore.updateSinglePost(postData.slug, { ...postData, ...updatedPost })
  }

  useEffect(() => setPostData(post), [post])

  return (
    <AuthenticatedView
      shouldShow={Boolean(postData.can_subscribe_user && userSignedIn)}
      accessableRoles={['admin', 'member', 'csm']}
    >
      <div className='border-t border-gray5 px-4 py-3'>
        <Accordion type='multiple'>
          <AccordionItem value={t('post.fields.subscribeUser.label')}>
            <AccordionTrigger className='!py-0'>
              <Typography.Text className='font-medium'>
                {t('post.fields.subscribeUser.label')}
              </Typography.Text>
            </AccordionTrigger>
            <AccordionContent>
              <div className='flex flex-col'>
                <Typography.Text className='!text-sm !text-gray11'>
                  {t('post.fields.subscribeUser.description')}
                </Typography.Text>
              </div>
              <form
                onSubmit={handleSubscribe}
                className='mt-2 flex flex-col items-end justify-end gap-2'
              >
                <Input
                  value={email}
                  rounded='md'
                  size='sm'
                  type='email'
                  placeholder={t('post.fields.subscribeUser.placeholder')}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value)
                  }
                />
                <Button
                  size='xs'
                  disabled={isSubmitting}
                  loading={isSubmitting}
                >
                  {t('buttons.subscribe')}
                </Button>
              </form>
              {Boolean(postData.subscribers?.length) && (
                <div className='mt-3 flex items-center justify-between rounded bg-gray4 py-2.5 transition'>
                  <p className='font-medium text-gray11'>
                    {t('post.fields.subscribeUser.subscribers')}
                  </p>
                  <AvatarGroup
                    limit={4}
                    avatars={postData?.subscribers}
                    rounded
                    handleClick={() => handleAvatarClick()}
                    totalCounts={postData?.subscribers_count || 0}
                    extras={Number(
                      postData.subscribers_count -
                        Number(postData.subscribers?.length)
                    )}
                  />
                  <SinglePostUserListModal
                    post={postData}
                    type='subscribers'
                    open={open}
                    onClose={() => setOpen(false)}
                    onRemove={(data: IUserProfile) => handleRemoveUser(data)}
                  />
                </div>
              )}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </AuthenticatedView>
  )
}
