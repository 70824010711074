import React from 'react'

import type { IPostInsights } from '@/types/post'

interface IPropTypes {
  summary: IPostInsights['ai_summary']
}
export default function PostInsightSummary({ summary }: IPropTypes) {
  return (
    <div
      className='prose-sm prose w-full max-w-full dark:prose-invert'
      dangerouslySetInnerHTML={{
        __html: summary,
      }}
    />
  )
}
