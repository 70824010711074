import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cva } from 'class-variance-authority'
import type { FC } from 'react'
import React from 'react'

import clsxm from '@/lib/clsxm'

const tabsListVariants = cva('flex space-x-3 border-b border-gray6 py-0')

interface TabsPropstype extends TabsPrimitive.TabsProps {
  children?: React.ReactNode
  defaultValue?: string
  activationMode?: 'automatic' | 'manual'
  orientation?: 'horizontal' | 'vertical'
}

interface TabsContentProps {
  value: string
  children?: React.ReactNode
  className?: string
}

interface TabsTriggerProps extends React.HTMLAttributes<HTMLButtonElement> {
  value: string
  children?: React.ReactNode
  icon?: React.ReactNode
  className?: string
  'data-state'?: 'active' | 'default'
}

export const TabsContent = ({ value, ...props }: TabsContentProps) => (
  <TabsPrimitive.Content
    {...props}
    value={value}
    className='mt-2 w-full pt-2 outline-none focus-visible:outline-none'
  />
)

export const TabsTrigger = ({
  value,
  icon,
  children,
  ...props
}: TabsTriggerProps) => (
  <TabsPrimitive.Trigger
    {...props}
    value={value}
    className={clsxm(
      '-my-px flex items-center space-x-1 border-b border-transparent p-1 text-sm font-medium text-gray11 transition duration-200 hover:border-fosblue hover:text-fosblue focus-visible:outline-none [&[data-state=active]]:border-gray12 [&[data-state=active]]:text-gray12',
      props.className
    )}
  >
    {icon}
    <span>{children}</span>
  </TabsPrimitive.Trigger>
)

export const TabsList = ({ ...props }: TabsPropstype) => (
  <TabsPrimitive.List
    {...props}
    className={clsxm(tabsListVariants(), props.className)}
  />
)

export const Tabs: FC<TabsPropstype> = ({
  defaultValue,
  activationMode = 'automatic',
  orientation,
  className,
  ...props
}) => (
  <TabsPrimitive.Root
    {...props}
    defaultValue={defaultValue}
    activationMode={activationMode}
    orientation={orientation}
    className={clsxm('m-1', className)}
  />
)

Tabs.displayName = 'Tabs'
export default Tabs
