import { ArrowRight } from '@phosphor-icons/react'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import CannedResponseDropdown from '@/components/posts/comments/CannedResponseDropdown'
import Status from '@/components/posts/Status'
import { userMentionFilters } from '@/components/shared/components/editor/editorConstants'
import RichTextEditor from '@/components/shared/components/editor/RichTextEditor'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { getCommentPostMentionFilters } from '@/lib/helpers/modules/commentHelper'
import postStore from '@/stores/PostListStore'
import type { IEditorRef, ISuggestionItem } from '@/types/editor'
import type { IPost, IPostCustomStatus } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  toStatus: IPostCustomStatus
  onUpdate?: (post: IPost) => void
}

export interface IPostStatusChangeModalRef {
  toggle: () => void
}

const PostStatusChangeModal = forwardRef(
  (props: IPropTypes, ref: ForwardedRef<IPostStatusChangeModalRef>) => {
    const t = useTranslations('post')
    const {
      userProfile,
      organizationPlan,
      organization,
      buckets = [],
    } = useContext(HNContext)
    const { post, toStatus, onUpdate } = props
    const [statusValue, setStatusValue] = useState<IPostCustomStatus>(toStatus)
    const [show, setShow] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [skipNotification, setSkipNotification] = useState(false)
    const editorRef = useRef<IEditorRef>(null)
    const [statusComment, setStatusComment] = useState<string | null>(null)
    const [selectedCannedResponse, setSelectedCannedResponse] =
      useState<ISelectOption>()
    const handleDialogClose = () => {
      setShow(false)
      setStatusValue(toStatus)
      setStatusComment(null)
      setDisabled(false)
      setSkipNotification(false)
    }

    const handleUpdatePostBeforeApiCall = (
      previousStatus: IPostCustomStatus,
      newStatus: IPostCustomStatus
    ) => {
      postStore.updateSinglePost(post.slug, {
        ...post,
        custom_status: {
          ...statusValue,
          value: newStatus.slug as keyof IPostCustomStatus,
        },
      })
      if (onUpdate)
        onUpdate({
          ...post,
          custom_status: {
            ...statusValue,
            value: newStatus.slug as keyof IPostCustomStatus,
          },
        })
      handleDialogClose()
      if (!post.hidden && post.approval_status === 'approved') {
        EventEmitter.dispatch('ENTITY_UPDATE', {
          entity: ENTITIES.POST_COUNTS,
          actionType: EVENT_ACTION_TYPES.UPDATE,
          fromTab: previousStatus.value,
          toTab: newStatus.slug,
        })
      }
    }

    const handleRevertPostUpdate = (
      previousStatus: IPostCustomStatus,
      newStatus: IPostCustomStatus
    ) => {
      postStore.updateSinglePost(post.slug, {
        ...post,
        custom_status: {
          ...previousStatus,
        },
      })
      handleDialogClose()
      if (!post.hidden && post.approval_status === 'approved') {
        EventEmitter.dispatch('ENTITY_UPDATE', {
          entity: ENTITIES.POST_COUNTS,
          actionType: EVENT_ACTION_TYPES.UPDATE,
          fromTab: newStatus.slug,
          toTab: previousStatus.value,
        })
      }
    }

    const handleCannedResponse = (
      selected: ISelectOptionGroup,
      previous?: ISelectOptionGroup
    ) => {
      const selectedValue = (selected as ISelectOption)?.content || ''
      const previousValue = (previous as ISelectOption)?.content
      editorRef.current?.replaceOrInsertValue(selectedValue, previousValue)
      setSelectedCannedResponse(selected as ISelectOption)
    }

    const handleStatusChange = () => {
      if (!statusValue) return null
      if (editorRef.current && !editorRef.current?.isReadyToSubmit()) {
        return toaster.info({
          message: t('messages.imagesUploading', { ignorePrefix: true }),
        })
      }
      // current post status
      const previousStatus = post.custom_status
      setDisabled(true)
      handleUpdatePostBeforeApiCall(previousStatus, statusValue)
      toaster.success({
        message: t('messages.status.success'),
      })
      const statusData = {
        status: statusValue?.value || statusValue?.slug,
        do_not_notify: skipNotification,
        comment_html: statusComment?.length ? statusComment : null,
      }
      return postStore
        .updatePostStatus(post.slug, statusData)
        .then((response) => {
          if (onUpdate) onUpdate(response)
        })
        .catch((err) => {
          toaster.error({ message: err.message })
          handleRevertPostUpdate(previousStatus, statusValue)
        })
        .finally(() => setDisabled(false))
    }

    useImperativeHandle(ref, () => ({
      toggle: () => setShow(!show),
    }))

    useEffect(() => {
      setStatusValue(toStatus)
    }, [toStatus])

    const renderAlertDescription = () => {
      return (
        <>
          <p>{t('statusChangeNoteAlertTexts.description')}</p>
          <div className='flex space-x-2 pt-2'>
            <Status status={post.custom_status} />
            <ArrowRight weight='bold' className='ml-1 h-4 w-4' />
            <Status status={statusValue} />
          </div>
        </>
      )
    }

    if (!show) return null

    const renderAlertDialog = () => (
      <Dialog
        open={show}
        onOpen={() => setShow(true)}
        onClose={handleDialogClose}
      >
        <DialogContent backdrop size='sm'>
          <DialogTitle>{t('statusChangeNoteAlertTexts.title')}</DialogTitle>
          <DialogDescription>{renderAlertDescription()}</DialogDescription>
          {!disabled && <DialogClose />}
          <div
            className='mt-4 space-y-4'
            data-testid='post_status_change_modal'
          >
            <RichTextEditor
              height='small'
              ref={editorRef}
              placeholder={t('statusChangeNoteAlertTexts.editorPlaceholder')}
              defaultValue=''
              onChange={(value) => setStatusComment(value)}
              disabled={disabled}
              showBubbleMenu={false}
              resource={{
                type: 'Comment',
              }}
              mentionsConfig={
                organization
                  ? [
                      {
                        indexName: 'Customer',
                        filter: userMentionFilters.publicComment(
                          organization.id,
                          post
                        ),
                        dataCleaner: (value: ISuggestionItem) => ({
                          ...value,
                          subLabel: userProfile?.is_member_of_organization
                            ? value.subLabel
                            : undefined,
                        }),
                      },
                      {
                        indexName: 'FeatureRequest',
                        filter: getCommentPostMentionFilters({
                          userProfile,
                          buckets,
                          organization,
                        }),
                      },
                    ]
                  : undefined
              }
            />
            {userProfile?.is_csm_of_organization &&
              organizationPlan?.allow_basic_features && (
                <CannedResponseDropdown
                  board={post.bucket}
                  onSelect={handleCannedResponse}
                  selectedResponse={selectedCannedResponse}
                />
              )}

            <div className='flex items-center justify-between'>
              <Checkbox
                checked={skipNotification}
                data-testid='post_status_change_modal_do_not_notify'
                label={t('statusChangeNoteAlertTexts.checkboxLabel')}
                onCheckedChange={() => setSkipNotification(!skipNotification)}
                disabled={disabled}
              />
              <Button
                onClick={handleStatusChange}
                type='button'
                disabled={disabled}
                dataTestId='post_status_change_modal_submit_button'
              >
                {t('buttons.update', { ignorePrefix: true })}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    )

    return <>{renderAlertDialog()}</>
  }
)

PostStatusChangeModal.displayName = 'PostStatusChangeModal'
export default PostStatusChangeModal
