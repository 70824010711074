import React, { useContext, useEffect, useState } from 'react'

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import HNContext from '@/context/HNContext'
import { billingPath } from '@/lib/helpers/pathHelpers'

import { useTranslations } from '../../../hooks/useTranslations'
import { EventEmitter } from '../../../lib/eventEmitter'
import Button from '../ui/Button'
import UnstyledLink from '../ui/Links/UnstyledLink'

export default function PlanRestrictionsDialog() {
  const [show, setShow] = useState(false)
  const t = useTranslations('common')
  const { isWidget } = useContext(HNContext)

  const handleConfigPush = (data: any) => {
    if (data.action === 'OPEN_PLAN_RESTRICTION_DIALOG') {
      setShow(true)
    }
  }
  useEffect(() => {
    if (isWidget) {
      return () => {}
    }
    EventEmitter.subscribe('CONFIG_PUSH', handleConfigPush)
    return () => {
      EventEmitter.unsubscribe('CONFIG_PUSH', handleConfigPush)
    }
  }, [])

  if (isWidget) {
    return null
  }

  return (
    <Dialog
      open={show}
      onOpen={() => setShow(true)}
      onClose={() => setShow(false)}
    >
      <DialogContent>
        <DialogClose />
        <DialogTitle>{t('planRestrictionDialog.title')}</DialogTitle>
        <DialogDescription>
          <div
            className='prose-a:text-primary prose-a:no-underline prose-a:hover:underline'
            dangerouslySetInnerHTML={{
              __html: t('planRestrictionDialog.description'),
            }}
          />
        </DialogDescription>
        <div className='mt-4 flex justify-end'>
          <UnstyledLink
            href={billingPath}
            className='hover:no-underline focus:outline-none'
          >
            <Button onClick={() => setShow(false)}>
              {t('buttons.goToBilling', { ignorePrefix: true })}
            </Button>
          </UnstyledLink>
        </div>
      </DialogContent>
    </Dialog>
  )
}
