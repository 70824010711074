import { cva } from 'class-variance-authority'

export const inputVariants = cva(
  [
    'block w-full border-[0.5px] border-gray5 bg-snow font-medium text-gray12',
    'caret-fosblue shadow-form outline-none ring-0 transition-colors duration-200',
    'placeholder:text-gray10 hover:border-gray7 focus:border-gray7',
    'focus:!outline-none focus:!ring-transparent focus-visible:outline-none',
    'dark:bg-gray-dark',
  ],
  {
    variants: {
      size: {
        xxs: 'px-2 py-0.5 text-xs',
        xs: 'px-2.5 py-1 text-xs',
        sm: 'px-2.5 py-1 text-[13px]',
        md: 'px-4 py-1.5 text-sm',
        lg: 'px-4 py-3 text-base',
        xl: 'px-5 py-3 text-base',
      },
      rounded: {
        xs: 'rounded-sm',
        sm: 'rounded',
        md: 'rounded-[8px]',
        lg: 'rounded-lg',
        xl: 'rounded-xl',
        full: 'rounded-full',
      },
      borderless: {
        true: 'border-transparent shadow-none hover:border-transparent hover:outline-none hover:ring-transparent focus:!border-transparent',
        false: '',
      },
      hasPrefix: {
        true: 'pl-10',
        false: '',
      },
      hasSuffix: {
        true: 'pr-10',
        false: '',
      },
      hasHelperPrefix: {
        true: 'rounded-l-none rounded-r',
        false: '',
      },
      hasHelperSuffix: {
        true: 'rounded-l rounded-r-none',
        false: '',
      },
      disabled: {
        true: [
          'cursor-not-allowed opacity-40 hover:!border-gray6',
          '!bg-gray6',
        ],
        false: '',
      },
    },
    defaultVariants: {
      size: 'sm',
      rounded: 'md',
      borderless: false,
      hasPrefix: false,
      hasSuffix: false,
      hasHelperPrefix: false,
      hasHelperSuffix: false,
      disabled: false,
    },
  }
)

export const iconContainerVariants = cva(
  'absolute inset-y-0 flex items-center',
  {
    variants: {
      position: {
        prefix: 'left-0 pl-3',
        suffix: 'right-0 pr-3',
      },
      interactive: {
        true: 'cursor-pointer hover:text-primary',
        false: 'pointer-events-none',
      },
    },
    defaultVariants: {
      position: 'prefix',
      interactive: false,
    },
  }
)

export const helperTextVariants = cva(
  [
    'inline-flex items-center bg-snow px-1.5',
    'text-sm text-gray11 transition-colors duration-200',
    'border-[0.5px] border-gray5',
  ],
  {
    variants: {
      position: {
        prefix: ['rounded-l-md', 'border-r-0'],
        suffix: ['rounded-r-md', 'border-l-0'],
      },
      size: {
        xxs: 'text-xs py-0.5',
        xs: 'text-xs py-1',
        sm: 'text-[13px] py-1',
        md: 'text-sm py-1.5',
        lg: 'text-base py-3',
        xl: 'text-base py-3',
      },
      disabled: {
        true: ['opacity-40 hover:!border-gray6', 'bg-gray2'],
        false: '',
      },
      loading: {
        true: ['border-gray5', 'shadow-none', 'bg-transparent'],
        false: '',
      },
      borderless: {
        true: 'border-transparent shadow-none',
        false: '',
      },
    },
    defaultVariants: {
      position: 'prefix',
      size: 'sm',
      disabled: false,
      loading: false,
      borderless: false,
    },
  }
)

export const spinnerVariants = cva('', {
  variants: {
    loading: {
      true: [
        'animate-spin',
        'stroke-0',
        '[animation-name:spin]',
        '[animation-duration:1500ms]',
        '[animation-iteration-count:infinite]',
        '[animation-timing-function:linear]',
        '[transform:rotate(3deg)]',
        '[transform:rotate(0.3rad)]',
        '[transform:rotate(3grad)]',
        '[transform:rotate(0.03turn)]',
        '[transform-origin:center_center]',
      ],
      false: '',
    },
    centered: {
      true: 'absolute top-1/2 left-1/2 right-1/2 bottom-1/2 -translate-x-1/2 -translate-y-1/2 m-auto',
      false: '',
    },
  },
  defaultVariants: {
    loading: true,
    centered: false,
  },
})

export const errorTextVariants = cva(
  ['absolute right-0 mt-2 text-xs text-red8', 'z-10'],
  {
    variants: {
      type: {
        maxLength: 'text-red8',
        minLength: 'text-red8',
      },
      disabled: {
        true: 'opacity-40',
        false: '',
      },
    },
    defaultVariants: {
      type: 'maxLength',
      disabled: false,
    },
  }
)
