import type { NextRouter } from 'next/router'

import { APP_PATHS } from '@/config/appPaths'
import { buildURL, isExternalURL } from '@/lib/helpers/urlHelpers'
import type {
  IOpenIdConnectRedirectData,
  IOrganizationSSORedirectData,
  ISigninResponse,
} from '@/types/auth'
import type { IInvitation } from '@/types/invitations'

export const getSSOLoginPath = (data: IOrganizationSSORedirectData) => {
  const { domain, redirect, sso_redirect_url } = data
  return buildURL(sso_redirect_url, {
    domain,
    redirect,
  })
}

export const getOpenIDInitiatePath = (data: IOpenIdConnectRedirectData) => {
  const { domain, redirect, open_id_connect_redirect_url } = data
  return buildURL(open_id_connect_redirect_url, {
    domain,
    redirect,
  })
}

export const destinationLinkForInvitation = (invitation: IInvitation) => {
  try {
    switch (invitation.type) {
      case 'bucket_member':
        return `/b/${invitation.bucket.slug}`
      case 'team_member':
        return '/admin'
      case 'customer':
      default:
        return '/'
    }
  } catch (e) {
    return '/'
  }
}

export const redirectAfterSign = (
  response: ISigninResponse,
  router: NextRouter,
  isModal: boolean,
  redirectTo?: string
) => {
  if (router.query.fromApp && response.kb_auth_token) {
    window.location.href = buildURL(router.query.redirectURL as string, {
      kbAuthToken: response.kb_auth_token,
    })
  } else if (redirectTo) {
    if (isExternalURL(redirectTo)) {
      window.location.href = '/'
    } else {
      window.location.href = redirectTo
    }
  } else if (router.query.redirectURL)
    if (isExternalURL(String(router.query.redirectURL))) {
      window.location.href = '/'
    } else {
      window.location.href = String(router.query.redirectURL)
    }
  else if (!router.pathname.includes('/embed') && response.last_interacted)
    window.location.href = response.last_interacted
  else if (isModal) {
    router.reload()
  } else {
    window.location.href = '/'
  }
}

export const redirectAfterSocialLogin = (
  _response: ISigninResponse,
  router: NextRouter,
  isModal: boolean,
  redirectTo?: string
) => {
  if (router.query.fromApp) {
    window.location.href = buildURL(APP_PATHS.AUTH_REDIRECT_PATH('kb'), {
      ...router.query,
    })
  } else if (redirectTo) {
    if (isExternalURL(redirectTo)) {
      window.location.href = '/'
    } else {
      window.location.href = redirectTo
    }
  } else if (router.query.redirectURL) {
    if (isExternalURL(String(router.query.redirectURL))) {
      window.location.href = '/'
    } else {
      window.location.href = String(router.query.redirectURL)
    }
  } else if (isModal) {
    router.reload()
  } else if (router.pathname === '/new') {
    window.location.href = '/new'
  } else {
    window.location.href = '/'
  }
}
