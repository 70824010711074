import { Image as ImageIcon, Link, Upload } from '@phosphor-icons/react'
import React from 'react'

import { DEFAULT_BUTTON_CLASS } from '@/components/shared/components/editor/editorConstants'
import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import Tooltip from '@/components/shared/ui/Tooltip'
import { useTranslations } from '@/hooks/useTranslations'
import type { IEditorActions } from '@/types/editor'

interface IPropTypes {
  actions: IEditorActions
  children?: React.ReactNode
}

export default function ImageUploadButton({ actions, children }: IPropTypes) {
  const t = useTranslations('editor')
  return (
    <Dropdown>
      <DropdownTrigger asChild>
        {children || (
          <span>
            <Tooltip
              asChild
              disableHoverableContent
              placement='bottom'
              text={t('image')}
            >
              <button type='button' className={DEFAULT_BUTTON_CLASS}>
                <ImageIcon weight='bold' className='h-4 w-4' />
              </button>
            </Tooltip>
          </span>
        )}
      </DropdownTrigger>
      <DropdownContent>
        <DropdownItem
          onSelect={() => {
            actions.triggerImageUpload()
          }}
          itemValue={t('uploadImg')}
          icon={<Upload />}
        />
        <DropdownItem
          onSelect={() => actions.openImageEmbedDialog()}
          itemValue={t('embedImg')}
          icon={<Link />}
        />
      </DropdownContent>
    </Dropdown>
  )
}
