import type { Filter } from 'meilisearch'

import type { IKeyValueMap } from '@/types/common'
import type { ITeamMember, ITeamMemberRole } from '@/types/team'
import type {
  ISearchedInteractorRecord,
  IUserInteractor,
  IUserProfile,
  IUserRole,
} from '@/types/user'

import {
  convertToUnixTime,
  formatDate,
  isDate,
  parseUnixTimeToDate,
} from '../dateHelpers'

export const getUserRole = (
  user?: IUserProfile | IUserInteractor
): IUserRole => {
  if (!user) return 'unauthorizedUser'
  if (user.is_admin_of_organization) return 'admin'
  if (user.is_member_of_organization) return 'member'
  if (user.is_csm_of_organization) return 'csm'
  if (user.is_interactor) return 'user'
  return 'user'
}
export const getTeamMemberRole = (user: ITeamMember): ITeamMemberRole => {
  if (user?.is_admin_of_organization) return 'admin'
  if (user?.is_member_of_organization) return 'member'
  if (user?.is_csm_of_organization) return 'csm'
  return 'user'
}

export const checkIsUser = (user: IUserProfile | undefined) => {
  return Boolean(user && !user?.is_csm_of_organization)
}

export const isUserMember = (user?: IUserProfile): boolean => {
  if (!user) return false
  return user.is_csm_of_organization
}

// Admin Side

export const getUserSearchFilter = (queryParams: IKeyValueMap) => {
  const filters: Filter = []
  const keysToSkip = ['page', 'limit', 'query']
  const keyFeildMap: { [key: string]: string } = {
    domains: 'email_domain',
    tag_id: 'label_ids',
  }
  Object.keys(queryParams).map((key): boolean => {
    let currentKey = key
    if (keysToSkip.includes(key)) return true
    if (keyFeildMap[key]) {
      currentKey = keyFeildMap[key] as string
    }

    if (Array.isArray(queryParams[key])) {
      const subFilter: string[] = []
      queryParams[key].map((value: String) =>
        subFilter.push(`${currentKey}=${value}`)
      )
      filters.push(subFilter)
    } else if (key === 'created_at') {
      const dates = queryParams[key]
        .split(',')
        .map((d: Date) => convertToUnixTime(new Date(d)))
      filters.push(`${currentKey}>=${dates[0]}`)
      filters.push(`${currentKey}<=${dates[1]}`)
    } else if (!['segment_id'].includes(key)) {
      filters.push(`${currentKey}=${queryParams[key]}`)
    }
    return true
  })
  return filters
}

export const cleanSearchedUser = (users: ISearchedInteractorRecord[]) => {
  return users.map((user) => ({
    blocked: user.blocked,
    post_count: user.post_count,
    votes_count: user.votes_count,
    comments_count: user.comments_count,
    created_at: {
      timestamp: parseUnixTimeToDate(user.created_at).toISOString(),
    },
    role: user.role,
    starred: user.starred,
    interactor_labels: [],
    ...user.profile,
    profile_picture: {
      url: user.profile.profile_picture,
    },
  }))
}

export const segmentFilterValueHumanize = (
  value: string | number | boolean | Date | string[]
) => {
  if (Array.isArray(value)) return value.join(', ')
  if (typeof value === 'boolean') return value ? 'True' : 'False'
  if (isDate(value)) return formatDate(new Date(value), 'PP')
  return value.toString()
}
