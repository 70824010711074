import {
  ArticleNyTimes,
  Calendar,
  CheckSquare,
  NumberSquareOne,
  Textbox,
} from '@phosphor-icons/react'

export const USER_ATTRIBUTE_FIELD_TYPES = [
  {
    label: 'Short Text',
    value: 'short_text',
    formText: 'Text Input',
    typeIcon: Textbox,
  },
  {
    label: 'Long Text',
    value: 'long_text',
    formText: 'Text Area',
    typeIcon: ArticleNyTimes,
  },
  {
    label: 'Number',
    value: 'integer',
    formText: 'Number Input',
    typeIcon: NumberSquareOne,
  },
  {
    label: 'Boolean',
    value: 'boolean',
    formText: 'Checkbox',
    typeIcon: CheckSquare,
  },
  {
    label: 'Date',
    value: 'date',
    formText: 'Date Input',
    typeIcon: Calendar,
  },
]

export const USER_ATTRIBUTE_FIELD_TYPES_ICONS_MAP =
  USER_ATTRIBUTE_FIELD_TYPES.reduce((acc, type) => {
    acc[type.value] = type.typeIcon
    return acc
  }, {} as Record<string, any>)

export const USER_ATTRIBUTE_FIELD_TYPE_MAP = USER_ATTRIBUTE_FIELD_TYPES.reduce(
  (acc, type) => {
    acc[type.value] = type.label
    return acc
  },
  {} as Record<string, string>
)

export const USER_ATTRIBUTE_FIELD_TYPE_ICONS =
  USER_ATTRIBUTE_FIELD_TYPES.reduce((acc, type) => {
    acc[type.value] = type.typeIcon
    return acc
  }, {} as Record<string, any>)

export type IUserSegmentFields =
  | 'integer'
  | 'short_text'
  | 'long_text'
  | 'boolean'
  | 'date'
  | 'array'

const stringOperators = [
  { label: 'Extact Match', value: 'exact_match' },
  { label: 'Contains', value: 'contains' },
  { label: 'Not Contains', value: 'not_contains' },
]

export const USER_SEGMENT_OPERATORS: {
  [key in IUserSegmentFields]: { label: string; value: string }[]
} = {
  integer: [
    { label: 'Equal To', value: 'equal_to' },
    { label: 'Greater Than', value: 'greater_than' },
    { label: 'Greater Than or Equal To', value: 'greater_than_or_equal_to' },
    { label: 'Less Than', value: 'less_than' },
    { label: 'Less Than or Equal To', value: 'less_than_or_equal_to' },
  ],
  short_text: stringOperators,
  long_text: stringOperators,
  boolean: [{ label: 'Equal To', value: 'equal_to' }],
  date: [
    { label: 'Equal To', value: 'equal_to' },
    { label: 'Greater Than', value: 'greater_than' },
    { label: 'Greater Than or Equal To', value: 'greater_than_or_equal_to' },
    { label: 'Less Than', value: 'less_than' },
    { label: 'Less Than or Equal To', value: 'less_than_or_equal_to' },
  ],
  array: [
    { label: 'Includes', value: 'includes' },
    { label: 'Excludes', value: 'excludes' },
  ],
}

export const OPERATOR_SYMBOL_MAP = {
  exact_match: '=',
  contains: 'contains',
  not_contains: 'not contains',
  equal_to: '=',
  greater_than: '>',
  greater_than_or_equal_to: '>=',
  less_than: '<',
  less_than_or_equal_to: '<=',
}

export const POWERUP_ROLES = {
  admins: 'admin',
  members: 'member',
  csm: 'csm',
  collaborators: 'collaborators',
}
