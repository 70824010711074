import {
  AddressBook,
  Airplane,
  Airplay,
  Alarm,
  Alien,
  AndroidLogo,
  Aperture,
  AppleLogo,
  AppWindow,
  Archive,
  ArchiveBox,
  Armchair,
  Article,
  Baby,
  Backpack,
  Bag,
  Balloon,
  Bank,
  Basketball,
  BatteryCharging,
  Bell,
  Binoculars,
  Bird,
  Boat,
  Book,
  Bookmarks,
  Books,
  Browsers,
  BugBeetle,
  Buildings,
  Bus,
  Cactus,
  Cake,
  Calculator,
  Camera,
  Car,
  Cat,
  Chalkboard,
  Coffee,
  Cookie,
  Cpu,
  Crown,
  Database,
  DesktopTower,
  DiamondsFour,
  Dog,
  EggCrack,
  Factory,
  Flower,
  GameController,
  Gift,
  GraduationCap,
  Hamburger,
  HandSoap,
  Heartbeat,
  IdentificationBadge,
  Jeep,
  Key,
  Lightbulb,
  LightningSlash,
} from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useState } from 'react'

import ColorPicker from '@/components/shared/components/ColorPicker'
import Button from '@/components/shared/ui/Button'
import type { IButtonSize } from '@/components/shared/ui/Button/Button'
import Popover from '@/components/shared/ui/Popover'
import {
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/ui/Popover/Popover'
import { STATUS_COLORS } from '@/config/module/colorConstant'
import type { IColor, IKeyValueMap } from '@/types/common'

const ICON_MAP = [
  { name: 'AddressBook', Icon: AddressBook },
  { name: 'Airplane', Icon: Airplane },
  { name: 'Airplay', Icon: Airplay },
  { name: 'Alarm ', Icon: Alarm },
  { name: 'Alien', Icon: Alien },
  { name: 'AndroidLogo', Icon: AndroidLogo },
  { name: 'AppWindow', Icon: AppWindow },
  { name: 'Aperture', Icon: Aperture },
  { name: 'AppleLogo', Icon: AppleLogo },
  { name: 'Archive', Icon: Archive },
  { name: 'ArchiveBox', Icon: ArchiveBox },
  { name: 'Armchair', Icon: Armchair },
  { name: 'Article', Icon: Article },
  { name: 'Baby', Icon: Baby },
  { name: 'Backpack', Icon: Backpack },
  { name: 'Bag', Icon: Bag },
  { name: 'Balloon', Icon: Balloon },
  { name: 'Bank', Icon: Bank },
  { name: 'Basketball', Icon: Basketball },
  { name: 'BatteryCharging', Icon: BatteryCharging },
  { name: 'Bell', Icon: Bell },
  { name: 'Binoculars', Icon: Binoculars },
  { name: 'Bird', Icon: Bird },
  { name: 'Boat', Icon: Boat },
  { name: 'Book', Icon: Book },
  { name: 'Books', Icon: Books },
  { name: 'Bookmarks', Icon: Bookmarks },
  { name: 'Browsers', Icon: Browsers },
  { name: 'Bus', Icon: Bus },
  { name: 'Buildings', Icon: Buildings },
  { name: 'BugBeetle', Icon: BugBeetle },
  { name: 'Cactus', Icon: Cactus },
  { name: 'Cake', Icon: Cake },
  { name: 'Calculator', Icon: Calculator },
  { name: 'Camera', Icon: Camera },
  { name: 'Car', Icon: Car },
  { name: 'Cat', Icon: Cat },
  { name: 'Chalkboard', Icon: Chalkboard },
  { name: 'Coffee', Icon: Coffee },
  { name: 'Cookie', Icon: Cookie },
  { name: 'Cpu', Icon: Cpu },
  { name: 'Crown', Icon: Crown },
  { name: 'Database', Icon: Database },
  { name: 'DesktopTower', Icon: DesktopTower },
  { name: 'DiamondsFour', Icon: DiamondsFour },
  { name: 'Dog', Icon: Dog },
  { name: 'EggCrack', Icon: EggCrack },
  { name: 'Factory', Icon: Factory },
  { name: 'Flower', Icon: Flower },
  { name: 'GameController', Icon: GameController },
  { name: 'Gift', Icon: Gift },
  { name: 'GraduationCap', Icon: GraduationCap },
  { name: 'Hamburger', Icon: Hamburger },
  { name: 'HandSoap', Icon: HandSoap },
  { name: 'Heartbeat', Icon: Heartbeat },
  { name: 'Jeep', Icon: Jeep },
  { name: 'IdentificationBadge', Icon: IdentificationBadge },
  { name: 'Key', Icon: Key },
  { name: 'Lightbulb', Icon: Lightbulb },
  { name: 'LightningSlash', Icon: LightningSlash },
]
interface IPropTypes {
  icon?: {
    color?: string
    icon?: string
  }
  variant?:
    | 'outline'
    | 'link'
    | 'text'
    | 'naked'
    | 'warning'
    | 'success'
    | 'danger'
    | 'dashed'
  onChange?: ({ color, icon }: { color: string; icon: string }) => void
  size?: IButtonSize
  readonly?: boolean
}
export default function IconPopover({
  icon = {},
  onChange,
  size,
  readonly = false,
  variant = 'outline',
}: IPropTypes) {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    icon: icon.icon || 'Airplane',
    color: icon?.color || '#4353ff',
  })

  const handleUpdate = (data: IKeyValueMap) => {
    setFormData({ ...formData, ...data })
    onChange?.({ ...formData, ...data })
    if (data.icon) {
      setOpen(false)
    }
  }

  const renderRadio = (color: IColor, index: number) => {
    return (
      <input
        id={color.value}
        key={index}
        type='radio'
        name='color'
        checked={formData.color === color.hex}
        style={{
          backgroundColor: STATUS_COLORS?.find((c) => c.value === color.value)
            ?.hex,
        }}
        className={clsx(
          'radio-check mt-1 h-4 w-4 cursor-pointer border-transparent focus:ring-primary dark:focus:ring-offset-gray11',
          `bg-${STATUS_COLORS?.find((c) => c.value === color.value)?.value}-500`
        )}
        value={color.value}
        onChange={() => handleUpdate({ color: color.hex })}
      />
    )
  }

  // useEffect(() => {
  //   setFormData({ ...formData, ...icon })
  // }, [icon])

  const SelectedIcon =
    ICON_MAP.find((i) => i.name === formData.icon)?.Icon || Airplane

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger
          disabled={readonly}
          className={clsx(
            'inline-flex items-center align-middle',
            readonly ? 'pointer-events-none' : ''
          )}
        >
          <Button
            as='span'
            size={size}
            fab
            rounded='md'
            variant={variant}
            icon={
              <SelectedIcon
                weight='fill'
                className='h-[1.125rem] w-[1.125rem]'
                color={formData.color}
              />
            }
          />
        </PopoverTrigger>
        <PopoverContent className='space-y-2 !p-2 sm:!min-w-[350px]'>
          <div className='flex flex-wrap gap-[6.7px] border-b border-gray5 px-2 pb-1 '>
            {STATUS_COLORS.map(renderRadio)}
            <div className='mt-1'>
              <ColorPicker
                onSave={(color: string) => {
                  handleUpdate({ color })
                  setOpen(false)
                }}
              />
            </div>
          </div>
          <div className='mx-auto flex flex-wrap gap-[5px] px-2'>
            {ICON_MAP.map((ic) => (
              <Button
                key={ic.name}
                fab
                variant='naked'
                onClick={() => handleUpdate({ icon: ic.name })}
              >
                <ic.Icon
                  weight='fill'
                  color={formData.color}
                  className='h-4 w-4 cursor-pointer'
                />
              </Button>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </>
  )
}
