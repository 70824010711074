import clsx from 'clsx'
import React from 'react'

import { STATUS_COLORS_MAP } from '@/config/appConstants'

import ClosedIcon from './statusIcons/ClosedIcon'
import CompletedIcon from './statusIcons/CompletedIcon'
import InProgressIcon from './statusIcons/InProgressIcon'
import PlannedIcon from './statusIcons/PlannedIcon'
import ReviewIcon from './statusIcons/ReviewIcon'

interface IStatusIcon {
  [name: string]: any
}
const statusIcons: IStatusIcon = {
  under_review: ReviewIcon,
  planned: PlannedIcon,
  in_progress: InProgressIcon,
  completed: CompletedIcon,
  closed: ClosedIcon,
}
export interface IStatus {
  value: string
  title?: string
  label?: string
  status?: string
  name?: string
  color?: string
  percentage?: number
  slug?: string
  icon?: string
}
export interface IStatusProps {
  status: IStatus
  textOnly?: boolean
  iconOnly?: boolean
  size?: number
}

const StatusIcon = ({
  status,
  size,
}: Pick<IStatusProps, 'status' | 'size'>) => {
  if (status?.icon) {
    return (
      <div
        style={{
          background: status?.color || '#000',
          mask: `url(${status?.icon}) no-repeat center / contain`,
          WebkitMask: `url(${status?.icon}) no-repeat center / contain`,
          height: size,
          width: size,
        }}
      />
    )
  }

  const Icon = statusIcons[status?.status || status?.value] || InProgressIcon
  return (
    <Icon
      size={size}
      className={clsx(
        `h-[${size}px] w-[${size}px] mr-0.5 shrink-0`,
        status?.color || ''
      )}
      weight='bold'
      color={status?.color}
      percentage={status?.percentage || ''}
    />
  )
}
export default function Status({
  status,
  textOnly = false,
  iconOnly = false,
  size = 14,
}: IStatusProps) {
  if (iconOnly) {
    return <StatusIcon status={status} size={size} />
  }
  if (textOnly)
    return (
      <span style={{ color: status?.color }} className={clsx('transition')}>
        {status?.title || status?.label || status?.name}
      </span>
    )
  const colorClass = STATUS_COLORS_MAP[status?.value]

  return (
    <span
      data-testid={`post_${status?.value}_status`}
      style={{ color: status?.color }}
      className={clsx(
        'flex min-w-[12%] max-w-[100%] items-center justify-start space-x-0.5 whitespace-nowrap text-xs font-semibold',
        colorClass?.text
      )}
    >
      <StatusIcon status={status} size={size} />
      <span className={clsx('truncate')}>
        {status?.title || status?.label || status?.name}
      </span>
    </span>
  )
}
