import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

export interface LabelProps extends React.HTMLAttributes<HTMLLabelElement> {
  htmlfor?: string
  children?: React.ReactNode
  className?: string
}

const Label: FC<LabelProps> = ({
  htmlfor,
  children,
  className,
}: LabelProps) => {
  return (
    <label
      className={clsx(
        'block cursor-pointer text-[12px] font-medium text-gray12/80',
        className
      )}
      htmlFor={htmlfor}
    >
      {children}
    </label>
  )
}

Label.displayName = 'Label'

export default Label
