import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'

import Button from '@/components/shared/ui/Button'
import { PassKeys } from '@/components/shared/ui/Icons'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { redirectAfterSign } from '@/lib/helpers/authHelpers'
import {
  checkIfPasskeySupported,
  startPasskeyAuthentication,
} from '@/lib/helpers/passkey.helpers'
import type { ISigninResponse } from '@/types/auth'
import toaster from '@/utils/toast'

interface IProps {
  isModal: boolean
  onlyButtons?: boolean
}

export default function PasskeyButton({ isModal, onlyButtons }: IProps) {
  const t = useTranslations()
  const [show, setShow] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const { organizationCustomization, auth } = useContext(HNContext)

  const router = useRouter()

  const handleRedirect = (response: ISigninResponse) =>
    redirectAfterSign(response, router, !!isModal, window.location.href)

  const handleSignin = () => {
    setLoading(true)
    return startPasskeyAuthentication()
      .then(handleRedirect)
      .catch((err) => {
        setLoading(false)
        if (err.message.includes('ERROR_CODE'))
          toaster.error({ message: err.message })
        else {
          toaster.error({ message: 'ERROR_CODE_CONTACT_SUPPORT' })
        }
      })
  }

  useEffect(() => {
    checkIfPasskeySupported()
      .then(setShow)
      .catch(() => setShow(false))
  }, [])

  if (
    !show ||
    router.pathname === '/auth/signup' ||
    organizationCustomization?.user_profile_preferences?.hide_passkey_section ||
    !auth?.passkey
  )
    return null

  return (
    <Button
      block
      size={onlyButtons ? 'xs' : 'md'}
      type='button'
      variant='outline'
      onClick={handleSignin}
      disabled={loading}
      loading={loading}
      icon={<PassKeys size='10' className='text-carbon' />}
    >
      {onlyButtons ? undefined : t('auth.labels.signInWithPasskey')}
    </Button>
  )
}
