import type { Node } from '@tiptap/pm/model'
import { NodeViewWrapper } from '@tiptap/react'
import clsx from 'clsx'
import React from 'react'

import { SpinnerIcon } from '@/components/shared/ui/Icons'

interface IPropTypes {
  node: Node
}

export default function EditorVideoUploader(props: IPropTypes) {
  return (
    <NodeViewWrapper className='image-component p-1'>
      {props.node.attrs.uploadId ? (
        <div
          className='flex h-[400px] w-full flex-col items-center justify-center rounded-lg border border-gray7 bg-gray1'
          data-upload-id={props.node.attrs.uploadId}
        >
          <SpinnerIcon className='h-4 w-4 animate-spin' />
          <span className='pt-3 font-semibold'>
            Uploading <span className='video-upload-progress hidden'>(0%)</span>
            ...
          </span>
          <span className='video-upload-overall hidden'></span>
        </div>
      ) : (
        <video
          className={clsx('imgborder w-auto hover:cursor-zoom-in md:h-auto', {
            'max-h-[60vh]':
              !props.node.attrs['data-size'] ||
              props.node.attrs['data-size'] === 'auto',
          })}
          src={props.node.attrs.src}
          data-size={props.node.attrs['data-size']}
          controls={true}
        />
      )}
    </NodeViewWrapper>
  )
}
