import clsx from 'clsx'
import React from 'react'

import Tooltip from '@/components/shared/ui/Tooltip'
import { useTranslations } from '@/hooks/useTranslations'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
}

export default function AdminPostItemSentimentIcon(props: IPropTypes) {
  const { post } = props
  const t = useTranslations('post.sentiment')

  const getSentimentBorderColor = () => {
    if (post.sentiment_type === 'positive') {
      if (post.sentiment_score === 4) return 'bg-grass5'
      return 'bg-grass9'
    }
    if (post.sentiment_type === 'negative') {
      if (post.sentiment_score === 1) return 'bg-red10'
      return 'bg-red10'
    }
    if (post.sentiment_type === 'neutral') return 'bg-yellow9'
    if (post.sentiment_type === 'processing') return 'bg-gray7'
    return 'bg-gray9'
  }

  if (post.sentiment_type === 'unprocessed' || !post.sentiment_type)
    return <div className='ml-0.5' />
  return (
    <Tooltip
      text={t(post.sentiment_type ?? 'undetected')}
      asChild
      wrapperClass='l-0 relative '
    >
      <div
        className={clsx('h-9 w-0.5 opacity-80', getSentimentBorderColor())}
      />
    </Tooltip>
  )
}
