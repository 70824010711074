import { ErrorBoundary } from '@sentry/react'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import EmptyState from '@/components/shared/ui/EmptyState'
import { useTranslations } from '@/hooks/useTranslations'
import { groupToArray } from '@/lib/helpers/dataHelpers'
import { getRecentActivities, listActivities } from '@/models/Organization'
import {
  getPostActivities,
  getPostActivitiesFromDB,
  setPostActivitiesToIdxDB,
} from '@/models/Post'
import type { IActivityItem } from '@/types/activity'
import type { IGroupedItem, IResourceTypes } from '@/types/common'
import type { IActivitiesListAPIParams } from '@/types/organization'
import type { IPost } from '@/types/post'

import InfiniteScroll from '../shared/components/InfiniteLoaders'
import Spinner from '../shared/ui/Loader'
import ActivityItem from './ActivityItem'

interface IPropTypes {
  post?: IPost
  userIds?: string[]
  stickyHeader?: boolean
  color?: boolean
  mini?: boolean
  resource?: {
    id?: string | number
    resource: IResourceTypes
  }
}
export default function RecentActivities({
  post,
  userIds,
  color = false,
  stickyHeader = false,
  mini = false,
  resource,
}: IPropTypes) {
  const t = useTranslations()
  const [activities, setActivities] = useState<IActivityItem[]>([])
  const [filters, setFilters] = useState<IActivitiesListAPIParams>({ page: 1 })
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState(null)
  const [canFetchMore, setCanFetchMore] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(false)

  const storePostActivities = (data: IActivityItem[]) => {
    if (!post) return
    setPostActivitiesToIdxDB(post, data)
  }

  const fetchData = () => {
    if (filters.page === 1) setLoading(true)
    let promise: Promise<any>

    if (post) {
      promise = getPostActivities(post.slug, filters)
    } else if (userIds) {
      promise = getRecentActivities({ ...filters, user_ids: userIds })
    } else if (resource) {
      promise = listActivities({ ...resource, page: filters.page })
    } else {
      promise = getRecentActivities(filters)
    }

    if (post && filters.page === 1) {
      getPostActivitiesFromDB(post.slug).then((data) => {
        if (data?.length) {
          setActivities(data)
          setLoading(false)
        }
      })
    }
    promise
      .then((data) => {
        setCanFetchMore(data.length >= 30)
        setActivities((oldActivities) =>
          filters.page === 1 ? data : [...oldActivities, ...data]
        )
        storePostActivities(data)
      })
      .catch((err) => {
        setErrorMessage(err.message)
      })
      .finally(() => {
        setLoading(false)
        setIsPageLoading(false)
      })
  }

  const handlePageUpdate = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: (prevFilters?.page || 1) + 1,
    }))
  }

  useEffect(() => {
    setIsPageLoading(true)
    fetchData()
  }, [filters])

  const renderActivity = (activity: IActivityItem, i: number) => {
    return (
      <ErrorBoundary fallback={<></>}>
        <ActivityItem
          mini={mini}
          activity={activity}
          resource={resource}
          key={i}
        />
      </ErrorBoundary>
    )
  }

  const renderActivitySection = (activitySection: IGroupedItem) => {
    return (
      <div className='py-2' key={activitySection.label}>
        <div
          className={clsx(
            'text-md border-b border-gray6 px-2 py-2',
            !stickyHeader ? '' : 'sticky top-0 z-[1]',
            color ? 'bg-snow dark:bg-gray3' : ''
          )}
        >
          <span>{activitySection.label}</span>
        </div>
        <ul className=''>{activitySection.items.map(renderActivity)}</ul>
      </div>
    )
  }

  if (loading) return <Spinner isScreen={!mini} />
  if (errorMessage) return <div>{errorMessage}</div>
  if (!activities.length)
    return <EmptyState description={t('messages.noActivities')} />

  return (
    <div className='mx-auto max-w-7xl p-2 pb-8 md:px-4'>
      {groupToArray(activities, 'dateLabel').map(renderActivitySection)}
      <InfiniteScroll
        showIf={Boolean(activities.length)}
        canFetchMore={canFetchMore}
        onIntersect={handlePageUpdate}
        threshold={0.5}
        isButton
        pageLoading={isPageLoading}
      />
    </div>
  )
}
