import {
  Code,
  Columns,
  Eraser,
  GridFour,
  Link,
  LinkBreak,
  ListBullets,
  ListNumbers,
  PaintBucket,
  Quotes,
  Rows,
  SquareHalf,
  TextAlignCenter,
  TextAlignLeft,
  TextBolder,
  TextHOne,
  TextHTwo,
  TextItalic,
  TextStrikethrough,
  TextUnderline,
  Trash,
} from '@phosphor-icons/react'
import type { Editor } from '@tiptap/core'
import { BubbleMenu } from '@tiptap/react'
import clsx from 'clsx'

import Tooltip from '@/components/shared/ui/Tooltip'
import { useTranslations } from '@/hooks/useTranslations'
import type { IMenuPropTypes } from '@/types/editor'

import {
  BUBBLE_ACTIVE_CLASS,
  BUBBLE_BUTTON_CLASS,
  BUTTON_CLASS,
  ICON_CLASS,
} from '../editorConstants'

export default function BubbleMenuBar({
  editor,
  actions,
  extendedBubbleMenu,
  editable,
}: IMenuPropTypes) {
  const t = useTranslations('editor')

  const getSelectionType = (_editor: any) => {
    if (_editor.view.state.selection?.jsonID !== 'cell') return 'text'
    return 'cell'
  }

  const renderDeleteColumnButton = (_editor: any, selectionType: any) => {
    if (
      selectionType === 'cell' &&
      _editor.view.state.selection?.isColSelection?.() &&
      !_editor.view.state.selection?.isRowSelection?.()
    ) {
      return (
        <Tooltip asChild text={t('delete_column')}>
          <button
            type='button'
            onClick={() => _editor.chain().focus().deleteColumn().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <Trash weight='bold' className={ICON_CLASS} color='red' />
          </button>
        </Tooltip>
      )
    }
    return <></>
  }

  const renderDeleteRowButton = (_editor: any, selectionType: any) => {
    if (
      selectionType === 'cell' &&
      _editor.view.state.selection.isRowSelection() &&
      !_editor.view.state.selection.isColSelection()
    ) {
      return (
        <Tooltip asChild text={t('delete_row')}>
          <button
            type='button'
            onClick={() => _editor.chain().focus().deleteRow().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <Trash weight='bold' className={ICON_CLASS} color='red' />
          </button>
        </Tooltip>
      )
    }
    return <></>
  }
  const renderDeleteTableButton = (_editor: any, selectionType: any) => {
    if (
      selectionType === 'cell' &&
      _editor.view.state.selection.isRowSelection() &&
      _editor.view.state.selection.isRowSelection()
    ) {
      return (
        <Tooltip asChild text={t('delete_table')}>
          <button
            type='button'
            onClick={() => _editor.chain().focus().deleteTable().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <Trash weight='bold' className={ICON_CLASS} color='red' />
          </button>
        </Tooltip>
      )
    }
    return <></>
  }

  const renderMergeCellButton = (_editor: Editor, selectionType: any) => {
    if (
      selectionType === 'cell' &&
      _editor.view.state.selection.ranges.length > 1
    ) {
      return (
        <Tooltip asChild text={t('merge_cells')}>
          <button
            type='button'
            onClick={() => _editor.chain().focus().mergeCells().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <GridFour weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
      )
    }
    return <></>
  }

  const renderSplitCellButton = (_editor: Editor, selectionType: any) => {
    if (
      selectionType === 'cell' &&
      _editor.view.state.selection.ranges.length === 1
    ) {
      return (
        <Tooltip asChild text={t('split_cells')}>
          <button
            type='button'
            onClick={() => _editor.chain().focus().splitCell().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <SquareHalf weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
      )
    }
    return <></>
  }

  const renderPaintButton = (_editor: Editor, selectionType: any) => {
    if (
      selectionType === 'cell' &&
      _editor.view.state.selection.ranges.length === 1
    ) {
      return (
        <Tooltip asChild text={t('fill_paint')}>
          <button
            type='button'
            className={BUBBLE_BUTTON_CLASS}
            onClick={() => _editor.chain().focus().toggleHeaderCell().run()}
          >
            <PaintBucket weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
      )
    }
    return (
      <Tooltip asChild text={t('fill_paint')}>
        <button
          type='button'
          className={BUBBLE_BUTTON_CLASS}
          onClick={() => _editor.chain().focus().toggleHeaderCell().run()}
        >
          <PaintBucket weight='bold' className={ICON_CLASS} />
        </button>
      </Tooltip>
    )
  }

  const renderTableActionButtons = (_editor: Editor) => {
    const selectionType = getSelectionType(_editor)
    return (
      <>
        <Tooltip asChild text={t('align_left')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            className={
              editor.isActive({ textAlign: 'left' })
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }
          >
            <TextAlignLeft weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('align_center')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            className={
              editor.isActive({ textAlign: 'center' })
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }
          >
            <TextAlignCenter weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('add_column_after')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().addColumnAfter().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <Columns weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('add_row_after')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().addRowAfter().run()}
            className={BUBBLE_BUTTON_CLASS}
          >
            <Rows weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        {renderDeleteColumnButton(editor, selectionType)}
        {renderDeleteRowButton(editor, selectionType)}
        {renderDeleteTableButton(editor, selectionType)}
        {renderMergeCellButton(editor, selectionType)}
        {renderSplitCellButton(editor, selectionType)}
        {renderPaintButton(editor, selectionType)}
      </>
    )
  }

  const renderExtendedMenu = () => {
    return (
      <>
        <Tooltip asChild text={t('code_block')}>
          <button
            type='button'
            data-testid='code_block_button'
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            className={
              editor.isActive('codeBlock')
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }
          >
            <Code weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        {editor.isActive('link') && (
          <Tooltip asChild text={t('link_break')}>
            <button
              type='button'
              className={`${BUTTON_CLASS}`}
              onClick={() => editor.chain().focus().unsetLink().run()}
            >
              <LinkBreak weight='bold' className={ICON_CLASS} />
            </button>
          </Tooltip>
        )}
        <Tooltip asChild text={t('list')}>
          <button
            type='button'
            data-testid='bullet_list_button'
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={`${
              editor.isActive('bulletList')
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }`}
          >
            <ListBullets weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('list_numbers')}>
          <button
            type='button'
            data-testid='ordered_list_button'
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={`${
              editor.isActive('orderedList')
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }`}
          >
            <ListNumbers weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('align_left')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().setTextAlign('left').run()}
            className={
              editor.isActive({ textAlign: 'left' })
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }
          >
            <TextAlignLeft weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('align_center')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().setTextAlign('center').run()}
            className={
              editor.isActive({ textAlign: 'center' })
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }
          >
            <TextAlignCenter weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('quotes')}>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            className={
              editor.isActive('blockquote')
                ? BUBBLE_ACTIVE_CLASS
                : BUBBLE_BUTTON_CLASS
            }
          >
            <Quotes weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
      </>
    )
  }

  if (!editable) return <></>
  return (
    <BubbleMenu
      className={clsx(
        'absolute flex divide-x divide-gray5 overflow-hidden rounded-full border border-gray6 bg-snow px-2 py-0.5 shadow-sm focus:outline-none ',
        {
          hidden:
            editor?.isActive('image') ||
            editor?.isActive('video') ||
            editor?.isActive('table'),
        }
      )}
      tippyOptions={{
        duration: 100,
        maxWidth: 'none',
        popperOptions: { strategy: 'fixed' },
        placement: 'bottom',
      }}
      editor={editor}
    >
      {!editor.isActive('image') &&
        !editor.isActive('video') &&
        !editor.isActive('table') && (
          <>
            <Tooltip asChild text={t('bold')}>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={`${
                  editor.isActive('bold')
                    ? BUBBLE_ACTIVE_CLASS
                    : BUBBLE_BUTTON_CLASS
                }`}
              >
                <TextBolder weight='bold' className={ICON_CLASS} />
              </button>
            </Tooltip>
            <Tooltip asChild text={t('italic')}>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={`${
                  editor.isActive('italic')
                    ? BUBBLE_ACTIVE_CLASS
                    : BUBBLE_BUTTON_CLASS
                }`}
              >
                <TextItalic weight='bold' className={ICON_CLASS} />
              </button>
            </Tooltip>
            <Tooltip asChild text={t('underline')}>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                className={`${
                  editor.isActive('underline')
                    ? BUBBLE_ACTIVE_CLASS
                    : BUBBLE_BUTTON_CLASS
                }`}
              >
                <TextUnderline weight='bold' className={ICON_CLASS} />
              </button>
            </Tooltip>
            <Tooltip asChild text={t('h1')}>
              <button
                type='button'
                data-testid='h1_button'
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 1 }).run()
                }
                className={
                  editor.isActive('heading', { level: 1 })
                    ? BUBBLE_ACTIVE_CLASS
                    : BUBBLE_BUTTON_CLASS
                }
              >
                <TextHOne weight='bold' className={ICON_CLASS} />
              </button>
            </Tooltip>
            <Tooltip asChild text={t('h2')}>
              <button
                type='button'
                data-testid='h2_button'
                onClick={() =>
                  editor.chain().focus().toggleHeading({ level: 2 }).run()
                }
                className={
                  editor.isActive('heading', { level: 2 })
                    ? BUBBLE_ACTIVE_CLASS
                    : BUBBLE_BUTTON_CLASS
                }
              >
                <TextHTwo weight='bold' className={ICON_CLASS} />
              </button>
            </Tooltip>
            <Tooltip asChild text={t('strike_through')}>
              <button
                type='button'
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={`${
                  editor.isActive('strike')
                    ? BUBBLE_ACTIVE_CLASS
                    : BUBBLE_BUTTON_CLASS
                }`}
              >
                <TextStrikethrough weight='bold' className={ICON_CLASS} />
              </button>
            </Tooltip>
            <Tooltip asChild text={t('link')}>
              <button
                type='button'
                className={`${BUBBLE_BUTTON_CLASS}`}
                onClick={() => actions.openLinkDialog()}
              >
                <Link weight='bold' className={ICON_CLASS} />
              </button>
            </Tooltip>
            {extendedBubbleMenu && renderExtendedMenu()}
            {editor.isActive('table') && renderTableActionButtons(editor)}
            <Tooltip asChild placement='bottom' text={t('clear_formatting')}>
              <button
                type='button'
                className={`${BUBBLE_BUTTON_CLASS}`}
                onClick={() =>
                  editor.chain().focus().unsetAllMarks().clearNodes().run()
                }
              >
                <Eraser weight='bold' className={ICON_CLASS} />
              </button>
            </Tooltip>
          </>
        )}
    </BubbleMenu>
  )
}
