import {
  ColumnsPlusLeft,
  ColumnsPlusRight,
  Image as ImageIcon,
  RowsPlusBottom,
  RowsPlusTop,
  Table,
} from '@phosphor-icons/react'
import type { Editor } from '@tiptap/core'
import React from 'react'

import { DEFAULT_BUTTON_CLASS } from '@/components/shared/components/editor/editorConstants'
import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import Tooltip from '@/components/shared/ui/Tooltip'
import { useTranslations } from '@/hooks/useTranslations'
import type { IEditorActions } from '@/types/editor'

interface IPropTypes {
  editor: Editor
  actions: IEditorActions
  children?: React.ReactNode
}

export default function TableButton({ actions, children, editor }: IPropTypes) {
  const t = useTranslations('editor')
  return (
    <Dropdown>
      <DropdownTrigger asChild>
        {children || (
          <span>
            <Tooltip
              asChild
              disableHoverableContent
              placement='bottom'
              text={t('image')}
            >
              <button type='button' className={DEFAULT_BUTTON_CLASS}>
                <ImageIcon weight='bold' className='h-4 w-4' />
              </button>
            </Tooltip>
          </span>
        )}
      </DropdownTrigger>
      <DropdownContent>
        <DropdownItem
          onSelect={() => {
            actions.openTableCreation()
          }}
          itemValue={t('tables.insertTable')}
          icon={<Table />}
        />
        <DropdownItem
          onSelect={() => {
            editor.chain().focus().addColumnBefore().run()
          }}
          disabled={!editor.isActive('table')}
          itemValue={t('tables.insertColumnBefore')}
          icon={<ColumnsPlusLeft />}
        />
        <DropdownItem
          onSelect={() => {
            editor.chain().focus().addColumnAfter().run()
          }}
          disabled={!editor.isActive('table')}
          itemValue={t('tables.insertColumnAfter')}
          icon={<ColumnsPlusRight />}
        />
        <DropdownItem
          onSelect={() => {
            editor.chain().focus().addRowBefore().run()
          }}
          disabled={!editor.isActive('table')}
          itemValue={t('tables.insertRowBefore')}
          icon={<RowsPlusTop />}
        />
        <DropdownItem
          onSelect={() => {
            editor.chain().focus().addRowAfter().run()
          }}
          disabled={!editor.isActive('table')}
          itemValue={t('tables.insertRowAfter')}
          icon={<RowsPlusBottom />}
        />
      </DropdownContent>
    </Dropdown>
  )
}
