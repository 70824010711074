import clsx from 'clsx'

const ArrowIcon = (props: any) => (
  <svg viewBox='0 0 16 16' fill='none' {...props}>
    <path
      fill='currentColor'
      d='M7.28033 3.21967C6.98744 2.92678 6.51256 2.92678 6.21967 3.21967C5.92678 3.51256 5.92678 3.98744 6.21967 4.28033L7.28033 3.21967ZM11 8L11.5303 8.53033C11.8232 8.23744 11.8232 7.76256 11.5303 7.46967L11 8ZM6.21967 11.7197C5.92678 12.0126 5.92678 12.4874 6.21967 12.7803C6.51256 13.0732 6.98744 13.0732 7.28033 12.7803L6.21967 11.7197ZM6.21967 4.28033L10.4697 8.53033L11.5303 7.46967L7.28033 3.21967L6.21967 4.28033ZM10.4697 7.46967L6.21967 11.7197L7.28033 12.7803L11.5303 8.53033L10.4697 7.46967Z'
    />
    <path
      d='M1.75 8H11'
      stroke='currentColor'
      strokeWidth='1.5'
      strokeDasharray='10'
      strokeDashoffset='10'
      strokeLinecap='round'
      className='transition-[stroke-dashoffset] group-hover:[stroke-dashoffset:20]'
    />
  </svg>
)

const MicrosoftIcon = (props: any) => (
  <svg viewBox='0 0 21 21' {...props}>
    <rect x='1' y='1' width='9' height='9' fill='#f25022' />
    <rect x='1' y='11' width='9' height='9' fill='#00a4ef' />
    <rect x='11' y='1' width='9' height='9' fill='#7fba00' />
    <rect x='11' y='11' width='9' height='9' fill='#ffb900' />
  </svg>
)

const AddImageIcon = (props: any) => (
  <svg stroke='currentColor' fill='none' viewBox='0 0 48 48' {...props}>
    <path
      d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

const HamBurgerIcon = (props: any) => (
  <svg fill='none' viewBox='0 0 24 24' stroke='currentColor' {...props}>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={2}
      d='M4 6h16M4 12h8m-8 6h16'
    />
  </svg>
)

const SpinnerIcon = (props: any) => (
  <svg
    viewBox='3 3 18 18'
    {...props}
    className={clsx(['animate-spin text-primary', props.className])}
  >
    <path
      fill='currentColor'
      className='opacity-20'
      d='M12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5ZM3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12Z'
    ></path>
    <path
      fill='currentColor'
      d='M16.9497 7.05015C14.2161 4.31648 9.78392 4.31648 7.05025 7.05015C6.65973 7.44067 6.02656 7.44067 5.63604 7.05015C5.24551 6.65962 5.24551 6.02646 5.63604 5.63593C9.15076 2.12121 14.8492 2.12121 18.364 5.63593C18.7545 6.02646 18.7545 6.65962 18.364 7.05015C17.9734 7.44067 17.3403 7.44067 16.9497 7.05015Z'
    ></path>
  </svg>
)

const JiraIcon = (props: any) => (
  <svg viewBox='0 0 74 76' fill='none' {...props}>
    <path
      d='M72.4 35.76L39.8 3.16 36.64 0 12.1 24.54.88 35.76a3 3 0 000 4.24L23.3 62.42l13.34 13.34 24.54-24.54.38-.38L72.4 40a3 3 0 000-4.24zM36.64 49.08l-11.2-11.2 11.2-11.2 11.2 11.2-11.2 11.2z'
      fill='#2684FF'
    />
    <path
      d='M36.64 26.68A18.86 18.86 0 0136.56.09l-24.51 24.5 13.34 13.34 11.25-11.25z'
      fill='#2684FF'
    />
    <path
      d='M47.87 37.85L36.64 49.08a18.86 18.86 0 010 26.68L61.2 51.19 47.87 37.85z'
      fill='#2684FF'
    />
  </svg>
)

const GoogleIcon = (props: any) => (
  <svg viewBox='0 0 20 20' {...props}>
    <g>
      <path
        d='M19.9996 10.2297C19.9996 9.54995 19.9434 8.8665 19.8234 8.19775H10.2002V12.0486H15.711C15.4823 13.2905 14.7475 14.3892 13.6716 15.0873V17.586H16.9593C18.89 15.8443 19.9996 13.2722 19.9996 10.2297Z'
        fill='#4285F4'
      ></path>
      <path
        d='M10.2002 20.0003C12.9518 20.0003 15.2723 19.1147 16.963 17.5862L13.6753 15.0875C12.7606 15.6975 11.5797 16.0429 10.2039 16.0429C7.54224 16.0429 5.28544 14.2828 4.4757 11.9165H1.08301V14.4923C2.81497 17.8691 6.34261 20.0003 10.2002 20.0003Z'
        fill='#34A853'
      ></path>
      <path
        d='M4.47227 11.9163C4.04491 10.6743 4.04491 9.32947 4.47227 8.0875V5.51172H1.08333C-0.363715 8.33737 -0.363715 11.6664 1.08333 14.4921L4.47227 11.9163Z'
        fill='#FBBC04'
      ></path>
      <path
        d='M10.2002 3.95756C11.6547 3.93552 13.0605 4.47198 14.1139 5.45674L17.0268 2.60169C15.1824 0.904099 12.7344 -0.0292099 10.2002 0.000185607C6.34261 0.000185607 2.81497 2.13136 1.08301 5.51185L4.47195 8.08764C5.27795 5.71762 7.53849 3.95756 10.2002 3.95756Z'
        fill='#EA4335'
      ></path>
    </g>
  </svg>
)

const PassKeys = (props: any) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 16 16'
    width='16'
    height='16'
    {...props}
  >
    <path
      d='M2.743 4.757a3.757 3.757 0 1 1 5.851 3.119 5.991 5.991 0 0 1 2.15 1.383c.17.17.257.405.258.646.003.598.001 1.197 0 1.795L11 12.778v.721a.5.5 0 0 1-.5.5H1.221a.749.749 0 0 1-.714-.784 6.004 6.004 0 0 1 3.899-5.339 3.754 3.754 0 0 1-1.663-3.119Z'
      fill='currentColor'
    ></path>
    <path
      d='M15.75 6.875c0 .874-.448 1.643-1.127 2.09a.265.265 0 0 0-.123.22v.59c0 .067-.026.13-.073.177l-.356.356a.125.125 0 0 0 0 .177l.356.356c.047.047.073.11.073.176v.231c0 .067-.026.13-.073.177l-.356.356a.125.125 0 0 0 0 .177l.356.356c.047.047.073.11.073.177v.287a.247.247 0 0 1-.065.168l-.8.88a.52.52 0 0 1-.77 0l-.8-.88a.247.247 0 0 1-.065-.168V9.185a.264.264 0 0 0-.123-.22 2.5 2.5 0 1 1 3.873-2.09ZM14 6.5a.75.75 0 1 0-1.5 0 .75.75 0 0 0 1.5 0Z'
      fill='currentColor'
    ></path>
  </svg>
)

const GitHubIcon = (props: any) => (
  <svg {...props} aria-hidden='true' fill='currentColor' viewBox='0 0 20 20'>
    <path
      fillRule='evenodd'
      d='M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z'
      clipRule='evenodd'
    />
  </svg>
)

const AppleIcon = (props: any) => (
  <svg viewBox='0 0 22.773 22.773' fill='currentColor' {...props}>
    <g>
      <path
        d='M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573
      c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z'
      />
      <path
        d='M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334
      c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0
      c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019
      c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464
      c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648
      c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z'
      />
    </g>
  </svg>
)

const HelloNextIcon = (props: any) => (
  <svg
    width='150'
    height='12'
    viewBox='0 0 605 50'
    fill='currentColor'
    {...props}
  >
    <path d='M37.3358 49.1826V26.8392H4.52972V49.1826H0V0.817441H4.52972V22.6839H37.3358V0.817441H41.8655V49.1826H37.3358Z' />
    <path d='M74.7982 49.1826V0.817441H111.928V4.97275H79.3279V21.3896H99.2312V25.545H79.3279V44.9591H114.673V49.1826H74.7982Z' />
    <path d='M179.823 44.9591V49.1826H142.693V0.817441H147.223V44.9591H179.823Z' />
    <path d='M244.702 44.9591V49.1826H207.572V0.817441H212.101V44.9591H244.702Z' />
    <path d='M292.404 50C285.312 50 279.386 47.5931 274.628 42.7793C269.915 37.9201 267.559 31.9936 267.559 25C267.559 17.8701 269.938 11.921 274.696 7.15259C279.455 2.3842 285.357 0 292.404 0C299.541 0 305.467 2.4069 310.179 7.22071C314.892 12.0345 317.248 17.9609 317.248 25C317.248 32.0391 314.846 37.9655 310.042 42.7793C305.284 47.5931 299.404 50 292.404 50ZM277.922 39.8501C281.72 43.8011 286.547 45.7766 292.404 45.7766C298.26 45.7766 303.087 43.7784 306.885 39.782C310.683 35.7402 312.581 30.8129 312.581 25C312.581 19.0963 310.66 14.1689 306.816 10.218C303.019 6.22162 298.214 4.22344 292.404 4.22344C286.501 4.22344 281.651 6.24432 277.854 10.2861C274.102 14.2825 272.226 19.1871 272.226 25C272.226 30.9037 274.125 35.8538 277.922 39.8501Z' />
    <path d='M389.935 0.817441V49.5232H386.846L351.775 6.13079V49.1826H347.383V0.817441H352.942L385.611 41.4169V0.817441H389.935Z' />
    <path d='M422.851 49.1826V0.817441H459.981V4.97275H427.381V21.3896H447.284V25.545H427.381V44.9591H462.726V49.1826H422.851Z' />
    <path d='M529.592 49.1826L510.237 27.7929L491.089 49.1826H485.53L507.492 24.6594L485.942 0.817441H491.913L510.375 21.3896L528.837 0.817441H534.396L513.189 24.5232L535.288 49.1826H529.592Z' />
    <path d='M557.85 4.97275V0.817441H605V4.97275H583.724V49.1826H579.194V4.97275H557.85Z' />
  </svg>
)

// TODO Check props type here
const GithubIssueOpenIcon = (props: any) => (
  <svg
    height='16'
    viewBox='0 0 16 16'
    version='1.1'
    width='16'
    aria-hidden='true'
    {...props}
  >
    <path d='M8 9.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3z'></path>
    <path
      fillRule='evenodd'
      d='M8 0a8 8 0 100 16A8 8 0 008 0zM1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0z'
    ></path>
  </svg>
)

const GithubIssueClosedIcon = (props: any) => (
  <svg
    aria-hidden='true'
    height='16'
    viewBox='0 0 16 16'
    version='1.1'
    width='16'
    data-view-component='true'
    {...props}
  >
    <path d='M11.28 6.78a.75.75 0 00-1.06-1.06L7.25 8.69 5.78 7.22a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l3.5-3.5z'></path>
    <path
      fillRule='evenodd'
      d='M16 8A8 8 0 110 8a8 8 0 0116 0zm-1.5 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z'
    ></path>
  </svg>
)

export {
  AddImageIcon,
  AppleIcon,
  ArrowIcon,
  GitHubIcon,
  GithubIssueClosedIcon,
  GithubIssueOpenIcon,
  GoogleIcon,
  HamBurgerIcon,
  HelloNextIcon,
  JiraIcon,
  MicrosoftIcon,
  PassKeys,
  SpinnerIcon,
}
