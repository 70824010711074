import type { Node } from '@tiptap/pm/model'
import type { Transaction } from '@tiptap/pm/state'
import { Plugin } from '@tiptap/pm/state'
import type { EditorView } from '@tiptap/pm/view'

import type { IUppyHandlerFunctionParams } from '@/config/uploader/uppy.config'
import { humanizeByte } from '@/lib/helpers/dataHelpers'
import toaster from '@/utils/toast'
/**
 * function for video drag n drop(for tiptap)
 * @see https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521#gistcomment-3744392
 */
export type UploadFn = (video: IUppyHandlerFunctionParams) => Promise<string>

const getVideoPositions = (id: string, view: EditorView) => {
  const positions: Array<{ node: Node; pos: number }> = []
  view.state.doc.descendants((node: Node, pos: number) => {
    if (node.type.name === 'video' && node.attrs.uploadId === id) {
      positions.push({ node, pos })
    }
  })
  return positions
}

export const insertVideoFromData = (
  view: EditorView
): Promise<string | null> => {
  return new Promise((resolve) => {
    const id = Math.random().toString(36).slice(2)
    const node = view.state.schema.nodes.video?.create({
      uploadId: id,
    })
    if (!node) resolve(null)
    else {
      const transaction = view.state.tr.replaceSelectionWith(node)
      view.dispatch(transaction)
      resolve(id)
    }
  })
}

export const insertVideoAfterUpload = (
  video: File,
  view: EditorView,
  uploader: (params: IUppyHandlerFunctionParams) => void,
  insertedId: string
) => {
  if (uploader && video) {
    uploader({
      file: video,
      kind: 'inline_videos',
      autoProceed: true,
      onComplete: ({ success, url, error }) => {
        if (success) {
          const nodes = getVideoPositions(insertedId, view)
          const transaction: Transaction = view.state.tr.setMeta(
            'addToHistory',
            false
          )
          const videoNode = view.state.schema.nodes.video?.create({
            src: url,
          })
          if (!videoNode) return
          nodes.forEach(({ pos }: { pos: number }) => {
            transaction.replaceWith(pos, pos + 1, videoNode)
            view.dispatch(transaction)
          })
        } else {
          // Remove the video node if the upload fails
          const nodes = getVideoPositions(insertedId, view)
          const transaction: Transaction = view.state.tr.setMeta(
            'addToHistory',
            false
          )
          nodes.forEach(({ pos }: { pos: number }) => {
            transaction.delete(pos, pos + 1)
            view.dispatch(transaction)
            toaster.error({
              message: error || 'ERROR_CODE_UNABLE_UPLOAD_VIDEO',
            })
          })
        }
      },
      onProgress: ({ percentage, bytesUploaded, bytesTotal }) => {
        const editorVideoUploadEl = document.querySelector(
          `[data-upload-id="${insertedId}"]`
        )
        if (editorVideoUploadEl) {
          const progressEl = editorVideoUploadEl.querySelector(
            '.video-upload-progress'
          )
          const overallEl = editorVideoUploadEl.querySelector(
            '.video-upload-overall'
          )
          if (progressEl) {
            progressEl.classList.remove('hidden')
            progressEl.textContent = `(${percentage}%)`
          }
          if (overallEl) {
            overallEl.classList.remove('hidden')
            overallEl.textContent = `${humanizeByte(
              bytesUploaded || 0
            )} / ${humanizeByte(bytesTotal || 0)}`
          }
        }
      },
    })
  }
}

export const uploadVideoPlugin = (upload: UploadFn) => {
  return new Plugin({
    props: {
      handleDOMEvents: {
        paste(view, event) {
          const items = Array.from(event.clipboardData?.items || []).filter(
            (item) => item.getAsFile()
          )
          items.forEach((item) => {
            const video = item.getAsFile()
            if (!video) return
            if (item.type.indexOf('video') === 0) {
              event.preventDefault()
              insertVideoFromData(view).then((insertedId) => {
                if (!insertedId) return
                insertVideoAfterUpload(video, view, upload, insertedId)
              })
            }
          })
          return false
        },
        drop(view, event) {
          const hasFiles = event.dataTransfer?.files?.length

          if (!hasFiles) {
            return false
          }

          const videos = Array.from(event!.dataTransfer!.files).filter((file) =>
            /video/i.test(file.type)
          )

          if (videos.length === 0) {
            return false
          }

          event.preventDefault()

          videos.forEach(async (video) => {
            if (upload) {
              insertVideoFromData(view).then((insertedId) => {
                if (!insertedId) return
                insertVideoAfterUpload(video, view, upload, insertedId)
              })
            }
          })
          return false
        },
      },
    },
  })
}
