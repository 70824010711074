import React, { useContext, useEffect } from 'react'

import PushJiraInsights from '@/components/posts/singlePost/postActions/integrations/jira/PushJiraInsights'
import Accordion from '@/components/shared/ui/Accordion'
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/shared/ui/Accordion/Accordion'
import AutoComplete from '@/components/shared/ui/AutoComplete'
import Button from '@/components/shared/ui/Button'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { searchJiraIssues, tagJiraIssues } from '@/models/Post'
import type { IPost, IPostJiraIssue } from '@/types/post'

import CreateJiraIssue from './CreateJiraIssue'
import JiraIssuesList from './JiraIssuesList'

interface IPropTypes {
  post: IPost
}
export default function PushToJira({ post }: IPropTypes) {
  const t = useTranslations(`post.integrations.jira`)
  const { integrations } = useContext(HNContext)
  const jiraInfo = post.jira_info
  const [jiraIssues, setJiraIssues] = React.useState<IPostJiraIssue[]>(
    post.issue_list || []
  )

  useEffect(() => {
    setJiraIssues(post.issue_list || [])
  }, [post.issue_list])

  const updateIssues = (data: IPost) => {
    setJiraIssues(data.issue_list || [])
  }

  const handleSearch = (query: string) =>
    searchJiraIssues(post.slug, { query }).then((data) => data.issue_list)

  const handleSelect = (suggestion: IPostJiraIssue) => {
    tagJiraIssues(post.slug, suggestion).then((data: IPost) => {
      setJiraIssues(data.issue_list || [])
    })
  }

  if (!integrations?.can_manage_jira || !jiraInfo) return null

  return (
    <div className='border-t border-gray5 px-4 py-3'>
      <Accordion type='single' collapsible>
        <AccordionItem value={t(`title`)}>
          <AccordionTrigger className='!py-0'>
            <Typography.Text className='font-medium'>
              {t(`title`)}
            </Typography.Text>
          </AccordionTrigger>
          <AccordionContent data-state='open'>
            <div className='col-span-5 py-2'>
              <div className='mb-2 flex items-center justify-end gap-2'>
                <CreateJiraIssue
                  post={post}
                  jiraInfo={jiraInfo}
                  onCreate={updateIssues}
                />
                {post?.jira_info?.push_insight_to_jira && (
                  <PushJiraInsights post={post}>
                    <Button variant='outline' size='xs'>
                      Create Jira Insights
                    </Button>
                  </PushJiraInsights>
                )}
              </div>
              <AutoComplete
                placeholder={t(`addToJiraInputPlaceholder`)}
                onSelect={handleSelect}
                displayKey='title'
                searchHandler={handleSearch}
                autoFocus={false}
                showStatus
              />
              <JiraIssuesList
                issues={jiraIssues}
                post={post}
                updateIssues={updateIssues}
              />
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
