import {
  ADMIN_MEMBERS_PATH,
  ADMIN_TEAM_MEMBERS_PATH,
  IMPORT_TEAM_MEMBERS_PATH,
  INVITE_TEAM_MEMBER_PATH,
  TEAM_MEMBER_PATH,
} from '@/config/routes'
import {
  getIdxDBAllData,
  removeIdxDBData,
  setIdxDBData,
} from '@/lib/localDB/localDB'
import type { IListAPIParams, IRequestHeaders } from '@/types/common'
import type { INewTeamMember, ITeamMember, ITeamMemberRole } from '@/types/team'
import type { IUserProfile } from '@/types/user'

import API from '../lib/API'

export const listTeamMembers = (
  queryParams: IListAPIParams
): Promise<ITeamMember[]> => API.get(ADMIN_TEAM_MEMBERS_PATH, queryParams)

export const membersList = (): Promise<ITeamMember[]> =>
  API.get(ADMIN_MEMBERS_PATH)
export const changeRole = (id: string, data: { role: ITeamMemberRole }) =>
  API.put(TEAM_MEMBER_PATH(id), data)
export const removeMember = (id: string) => API.delete(TEAM_MEMBER_PATH(id))
export const addTeamMember = (data: INewTeamMember): Promise<ITeamMember> =>
  API.post(INVITE_TEAM_MEMBER_PATH, data)
export const archiveMember = (id: string, data: { archived: boolean }) =>
  API.put(TEAM_MEMBER_PATH(id), data)

// LocalDB
export const getLocalAssignees = () => getIdxDBAllData('ASSIGNEES')

export const getLocalAssignee = (id: string) =>
  getIdxDBAllData('ASSIGNEES').then((assignees) =>
    assignees.filter((assignee: IUserProfile) => assignee.id === id)
  )

export const addLocalAssignee = (assignee: IUserProfile) => {
  return setIdxDBData('ASSIGNEES', assignee.id, assignee, 'id')
}
export const removeLocalAssignee = (assignee: IUserProfile) => {
  return removeIdxDBData('ASSIGNEES', assignee.id, 'id')
}

export const bulkImportTeam = (
  data: INewTeamMember[],
  headers: IRequestHeaders
) => {
  return API.post(
    IMPORT_TEAM_MEMBERS_PATH,
    { users: data },
    {
      headers,
    }
  )
}
