import { createContext, useContext } from 'react'

interface IHNAsideContextProps {
  open: boolean
  toggleOpen: (status?: boolean) => void
}
// React Contexts
export const HNAsideContext = createContext<IHNAsideContextProps | null>(null)

export const useHNAsideContext = () =>
  useContext(HNAsideContext) as IHNAsideContextProps
