import { type VariantProps, cva } from 'class-variance-authority'
import React from 'react'

const textVariants = cva(['text-sm text-gray12'], {
  variants: {
    type: {
      default: [],
      secondary: ['text-secondary dark:text-secondary'],
      success: ['text-green8'],
      warning: ['text-yellow8'],
      danger: ['text-red8'],
    },
    disabled: {
      true: ['text-gray6 cursor-not-allowed'],
    },
    faded: {
      true: ['opacity-75'],
    },
    underline: {
      true: ['underline'],
    },
    strikethrough: {
      true: ['line-through'],
    },
    small: {
      true: ['text-xs'],
    },
  },
  defaultVariants: {
    type: 'default',
  },
})

interface TextProps
  extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'type'>,
    VariantProps<typeof textVariants> {
  className?: string
  children?: React.ReactNode
  style?: React.CSSProperties
  mark?: boolean
  code?: boolean
  keyboard?: boolean
  strong?: boolean
  semibold?: string
}

function Text({
  className,
  children,
  style,
  type,
  faded,
  disabled,
  mark,
  code,
  keyboard,
  underline,
  strikethrough,
  strong,
  semibold,
  small,
  ...rest
}: TextProps) {
  const classes = textVariants({
    type,
    disabled,
    faded,
    underline,
    strikethrough,
    small,
    className,
  })

  if (code)
    return (
      <code
        style={style}
        className={`rounded bg-gray-100 px-1.5 py-0.5 font-mono dark:bg-gray-800 ${classes}`}
      >
        {children}
      </code>
    )
  if (mark)
    return (
      <mark
        style={style}
        className={`bg-yellow-100 dark:bg-yellow-800/30 ${classes}`}
      >
        {children}
      </mark>
    )
  if (keyboard)
    return (
      <kbd
        style={style}
        className={`rounded border border-gray-200 bg-gray-100 px-1.5 py-0.5 font-mono dark:border-gray-700 dark:bg-gray-800 ${classes}`}
      >
        {children}
      </kbd>
    )
  if (strong)
    return (
      <strong
        style={style}
        className={semibold ? 'font-semibold' : `font-bold ${classes}`}
      >
        {children}
      </strong>
    )
  return (
    <span style={style} className={classes} {...rest}>
      {children}
    </span>
  )
}

export default Text
