import { ArrowsOutSimple, CheckCircle, PushPin } from '@phosphor-icons/react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import CommentModerationBanner from '@/components/posts/comments/CommentModerationBanner'
import Status from '@/components/posts/Status'
import EditorExternalAttchmentsList from '@/components/shared/components/attachments/EditorExternalAttchmentsList'
import HTMLViewer from '@/components/shared/components/HTMLViewer'
import UserAvatar from '@/components/shared/components/UserAvatar'
import Alert from '@/components/shared/ui/Alert'
import Badge from '@/components/shared/ui/Badge'
import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import PrimaryLink from '@/components/shared/ui/Links/PrimaryLink'
import Tooltip from '@/components/shared/ui/Tooltip'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { sortArrayOfObjectsDesc } from '@/lib/helpers/dataHelpers'
import { formatJSONTimestamp, relativeDate } from '@/lib/helpers/dateHelpers'
import { isHTMLContentEmpty } from '@/lib/helpers/stringHelpers'
import type { IComment } from '@/types/comment'
import type { IAttachment } from '@/types/organization'
import type { IPost } from '@/types/post'

import CommentAction from './CommentAction'
import CommentReactions from './CommentReactions'

// Dynamic Import Comment Form
const CommentForm = dynamic(() => import('./CommentForm'), {
  ssr: false,
})

const COMMENT_MAX_HEIGHT = 480

interface IPropTypes {
  comment: IComment
  post: IPost
  onDelete: (comment: IComment) => void
  onUpdate?: (comment: IComment) => void
  parentComment?: IComment
  onAnswerSuccess: (comment: IComment) => void
}
export default function CommentItem({
  comment: _comment,
  post,
  onDelete,
  onUpdate,
  parentComment,
  onAnswerSuccess,
}: IPropTypes) {
  const { userProfile } = useContext(HNContext)

  const t = useTranslations()
  const [edit, setEdit] = useState(false)
  const [comment, setComment] = useState(_comment)
  const [showNewReply, setShowNewReply] = useState(false)
  const [isMinimized, setIsMinimized] = useState(false)
  const [shouldMinimize, setShouldMinimize] = useState(false)
  const commentElRef = useRef(null)
  const [folded, setFolded] = useState(false)

  const replies = useMemo(
    () => sortArrayOfObjectsDesc(comment.replies || [], 'id'),
    [comment.replies]
  )

  const isReply = useMemo(() => !!parentComment, [parentComment])

  const toggleEdit = () => setEdit(!edit)

  const handleUpdate = (newComment: IComment) => {
    setComment(newComment)
    if (onUpdate) onUpdate(newComment)
    setEdit(false)
  }

  const handleAttachmentRemove = (attachment: IAttachment) => {
    const newAttachments = comment.external_files.filter(
      (file) => file.url !== attachment.url
    )
    setComment({
      ...comment,
      external_files: newAttachments,
    })
    if (!newAttachments.length && isHTMLContentEmpty(comment.comment)) {
      onDelete(comment)
    }
  }

  useEffect(() => {
    setComment(_comment)
  }, [_comment])

  useEffect(() => {
    if (
      commentElRef.current &&
      (commentElRef.current as HTMLDivElement).offsetHeight > COMMENT_MAX_HEIGHT
    ) {
      setIsMinimized(true)
      setShouldMinimize(true)
    }
  }, [])

  const renderReplyButton = () => {
    if (
      (!comment.can_approve && comment.approval_status === 'pending') ||
      !post.allowPublicComment
    )
      return <></>
    if (showNewReply) {
      return comment.approval_status === 'approved' ? (
        <CommentForm
          post={post}
          autoSaveKey={`REPLY_COMMENT_DRAFT_${comment.id}`}
          parentComment={parentComment || comment}
          onToggleForm={setShowNewReply}
          onlyInternal={comment.internal}
          onNewComment={(newComment) => {
            handleUpdate(newComment)
            setShowNewReply(false)
          }} // Replying API returns full parent comment, so we replace the parent data.
        />
      ) : (
        <Alert
          message={t('comments.commentItem.labels.approveToReply')}
          type='warning'
        />
      )
    }
    return (
      <div className='border-t border-gray5 p-2 px-4'>
        <Input
          size='xs'
          placeholder={t('buttons.reply')}
          onClick={() => setShowNewReply(!showNewReply)}
        />
      </div>
    )
  }

  const renderStatusMessage = () => {
    if (!comment.feature_request_status) return <></>
    return (
      <span className='flex space-x-1'>
        <span>{t('comments.commentItem.labels.statusUpdate')}</span>
        <Status status={comment.feature_request_status} />
      </span>
    )
  }

  const renderMergedCommentContent = () => {
    if (comment?.merged) {
      return (
        <>
          <span> · </span>
          <PrimaryLink
            href={comment?.feature_request_url}
            target='_blank'
            rel='noreferrer'
            color='primary'
          >
            {t('comments.commentItem.labels.mergedComment')}
          </PrimaryLink>
        </>
      )
    }
    return <></>
  }

  return (
    <CommentModerationBanner
      comment={comment}
      post={post}
      onUpdate={onUpdate}
      onDelete={onDelete}
      className={clsx(!isReply ? 'rounded-md border border-gray5' : '', {
        '!bg-gradient-to-b !from-yellow2 !via-yellow1 !to-transparent':
          comment.approval_status === 'pending',
      })}
    >
      <div
        key={comment.id}
        className={`${clsx(
          'overflow-y-hidden',
          comment.internal ? 'my-0.5' : ''
        )} group/parent relative -mx-[1px]`}
      >
        {/* pinned tag */}
        {!!(!comment.is_solution && comment.pinned) && (
          <div className='flex items-center rounded-t-md bg-gradient-to-r from-blue3 to-blue10 px-4 py-2 text-[10px] font-bold uppercase tracking-wider text-primary'>
            <PushPin weight='fill' className='mr-1' />
            {t('comments.commentItem.labels.pinned')}
          </div>
        )}
        <div
          className={clsx(
            'flex w-full items-start px-4 py-2',
            comment.is_solution
              ? 'border border-green10 bg-green2'
              : 'rounded-md border-transparent'
          )}
        >
          {/* User Avatar */}
          <div className='relative items-center'>
            <UserAvatar
              showBadge
              rounded
              user={comment.commenter}
              showProfileCard
              starred={comment?.commenter?.starred}
              allowUserInfoTrigger
            />
          </div>

          <div className='ml-2 mt-1 w-[calc(100%-40px)]'>
            {/* comment name and that line details */}
            <div className='flex items-center justify-between'>
              <div className='flex items-center space-x-2'>
                <Tooltip
                  show={comment.show_tooltip}
                  text={t('comments.commentItem.labels.commenterNameTooltip')}
                >
                  <Typography.Text className='font-semibold !text-gray12 '>
                    {comment.commenter.name}
                  </Typography.Text>
                </Tooltip>

                {renderStatusMessage()}

                {comment.internal && (
                  <>
                    <Badge
                      size='xxs'
                      rounded
                      value={t('comments.commentItem.labels.internal')}
                      variant='yellow'
                    />
                    <span className='text-gray11'>•</span>
                  </>
                )}

                <Tooltip text={formatJSONTimestamp(comment.timestamp, 'PPpp')}>
                  <Typography.Text className='!text-xs !text-gray11'>
                    {relativeDate(comment.timestamp)}
                  </Typography.Text>
                </Tooltip>

                {!!(comment.hidden && userProfile?.is_csm_of_organization) && (
                  <Tooltip text={t('comments.messages.hiddenTooltip')}>
                    <Badge
                      size='xxs'
                      rounded
                      value={t('common.labels.hidden')}
                      variant='gray'
                    />
                  </Tooltip>
                )}
                {comment.source && (
                  <>
                    <span className='text-gray-light'>•</span>
                    <Typography.Text className='!text-xs'>
                      {`${t('common.via', { ignorePrefix: true })} ${
                        comment.source
                      }`}
                    </Typography.Text>
                  </>
                )}
                {renderMergedCommentContent()}
              </div>

              <div className='flex items-center gap-2'>
                {!comment.feature_request_status && comment.is_solution && (
                  <div
                    className={clsx(
                      'flex items-center justify-start space-x-0.5 whitespace-nowrap rounded-full border border-green8 bg-green8 px-1.5 py-0.5 text-xs text-gray11 duration-150'
                    )}
                  >
                    <CheckCircle
                      weight='fill'
                      className='mr-0.5 h-3.5 w-3.5 text-snow dark:text-carbon'
                      size={14}
                    />
                    <span
                      className='font-normal text-snow dark:text-carbon'
                      data-testid='user_post_info_public_comment_count'
                    >
                      {t('comments.commentItem.actions.marked_as_asnwer')}
                    </span>
                  </div>
                )}
                {!edit && !comment.feature_request_status && (
                  <CommentAction
                    post={post}
                    comment={comment}
                    onEdit={toggleEdit}
                    onUpdate={handleUpdate}
                    onDelete={onDelete}
                    onAnswerSuccess={onAnswerSuccess}
                  />
                )}
              </div>
            </div>

            {/* Expand button when replied are hide */}
            {folded ? (
              <p
                className='-mx-1 my-1 inline-flex cursor-pointer items-center gap-1 rounded px-1 text-xs text-gray9 hover:bg-gray5'
                onClick={() => {
                  setFolded(false)
                }}
              >
                <ArrowsOutSimple /> {t('buttons.expand')}
              </p>
            ) : (
              <div>
                {/* edit comment form and comment based on edit status */}
                {edit ? (
                  <CommentForm
                    autoSaveKey={`COMMENT_DRAFT_${comment.id}`}
                    post={post}
                    comment={comment}
                    onToggleForm={setEdit}
                    onUpdate={(newComment) => {
                      // If its reply, will call parent's on update
                      if (parentComment) {
                        if (onUpdate) onUpdate(newComment)
                      } else {
                        // If its not reply, update the current comment
                        handleUpdate(newComment)
                      }
                      setEdit(false)
                    }}
                  />
                ) : (
                  <div className='relative'>
                    <div
                      ref={commentElRef}
                      className={
                        isMinimized ? 'max-h-[30rem] overflow-hidden' : ''
                      }
                    >
                      <HTMLViewer html={comment.comment} />
                    </div>

                    <div className='flex items-center justify-center'>
                      {isMinimized && shouldMinimize && (
                        <>
                          <div className='absolute inset-x-0 bottom-7 h-12 w-full bg-gradient-to-t from-snow' />
                          <Button
                            className='mt-2'
                            size='xxs'
                            variant='secondary'
                            onClick={() => setIsMinimized(false)}
                          >
                            {t('buttons.read_more', { ignorePrefix: true })}
                          </Button>
                        </>
                      )}
                      {!isMinimized && shouldMinimize && (
                        <>
                          <Button
                            className='mt-2'
                            size='xxs'
                            variant='secondary'
                            onClick={() => setIsMinimized(true)}
                          >
                            {t('buttons.read_less', { ignorePrefix: true })}
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {!!comment.external_files.length && (
                  <div className='mb-4 mt-2'>
                    <EditorExternalAttchmentsList
                      attachments={comment.external_files}
                      resource={comment}
                      resourceType='Comment'
                      resourceId={comment.id}
                      onRemove={handleAttachmentRemove}
                    />
                  </div>
                )}

                {/* Reactions, reply and more options */}
                {!edit && !comment.feature_request_status && (
                  <div>
                    <div className='flex items-center sm:space-y-0'>
                      <CommentReactions comment={comment} post={post} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {/* replies render */}
      </div>
      {!folded && !!replies?.length && (
        <div className='mt-2 bg-gray2'>
          {replies.map((reply: IComment) => (
            <CommentItem
              key={reply.id}
              comment={reply}
              post={post}
              onDelete={handleUpdate}
              parentComment={comment}
              onUpdate={handleUpdate}
              onAnswerSuccess={onAnswerSuccess}
            />
          ))}
        </div>
      )}
      {!isReply && renderReplyButton()}
    </CommentModerationBanner>
  )
}
