import { CircleWavyCheck } from '@phosphor-icons/react'
import React, { useContext, useState } from 'react'

import Avatar from '@/components/shared/ui/Avatar'
import Badge from '@/components/shared/ui/Badge'
import HoverCard from '@/components/shared/ui/HoverCard'
import {
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/shared/ui/HoverCard/HoverCard'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { copyClipboard } from '@/lib/helpers/appHelpers'
import { friendlyNumber, pluralize } from '@/lib/helpers/stringHelpers'
import { getInteractor } from '@/models/User'
import type {
  IUserInteractor,
  IUserLabel,
  IUserProfileWithoutStar,
} from '@/types/user'

interface ProfileHoverProps {
  children?: React.ReactNode
  user: IUserProfileWithoutStar
  profileCardLabel?: string
}

const badgeClass =
  'flex items-center justify-center px-1 py-1 space-x-0.5 bg-gray4  rounded'

export default function ProfileHoverWrapper({
  children,
  user: _user,
  profileCardLabel,
}: ProfileHoverProps) {
  const t = useTranslations('users.profile')
  const [user, setUser] = useState<IUserInteractor>()
  const [loading, setLoading] = useState(true)
  const { userProfile } = useContext(HNContext)

  const fetchUserData = () =>
    getInteractor(_user.id)
      .then((data) => setUser(data))
      // .catch((err) =>
      //   toast.error({
      //     message: err.message,
      //   })
      // )
      .finally(() => setLoading(false))

  const handleShow = (open: boolean) => {
    if (open && !user) {
      fetchUserData()
    }
  }

  const handleCopyEmail = () => {
    if (user?.guest) return null
    return copyClipboard(
      user?.email || '',
      t('common.copied', { ignorePrefix: true })
    )
  }

  const renderRoleText = (userData: IUserInteractor) => {
    if (userData.role === 'admin') return t('admin')
    if (userData.role === 'member') return t('member')
    if (userData.role === 'csm') return t('csm')
    return t('user')
  }

  const renderBadge = (label: IUserLabel) => {
    return (
      <Badge
        className='m-0.5'
        key={label.id}
        value={label.title}
        close={false}
      />
    )
  }

  const renderProfilecard = () => {
    return (
      <>
        <div className='grid w-full grid-cols-6 gap-5 pb-3'>
          <Avatar
            url={user?.profile_picture?.url}
            name={user?.name}
            size='md'
            rounded
          />
          <div className='col-span-5 flex w-full flex-col space-y-1'>
            <div className='flex items-center'>
              <h3 className='mb-0 mr-1 truncate text-sm text-gray12 '>
                {user?.name}
              </h3>
              {user?.is_admin_of_organization && (
                <CircleWavyCheck
                  weight='fill'
                  className='h-4 w-4 text-green8'
                />
              )}
            </div>
            <span className='inline w-full space-x-1 truncate text-sm text-gray9 '>
              <span>{user && renderRoleText(user)}</span>
              {userProfile?.is_admin_of_organization && user && (
                <>
                  <span className='text-gray6'>•</span>
                  <span
                    className='cursor-pointer'
                    onClick={() => handleCopyEmail()}
                  >
                    {user.guest
                      ? t('common.invalidEmail', { ignorePrefix: true })
                      : user?.email}
                  </span>
                </>
              )}
            </span>
            <span className='mb-0 inline w-full text-sm text-gray9 '>
              {user?.interactor_labels?.map(renderBadge)}
            </span>
          </div>
        </div>
        <div className='flex flex-wrap text-xs font-medium text-gray11 '>
          <Badge
            className='m-0.5'
            value={user && pluralize(user?.votes_count, t('vote'), t('votes'))}
            icon={<span>{friendlyNumber(user?.votes_count || 0)}</span>}
          />
          <Badge
            className='m-0.5'
            value={user && pluralize(user?.post_count, t('post'), t('posts'))}
            icon={<span>{friendlyNumber(user?.post_count || 0)}</span>}
          />
          <Badge
            className='m-0.5'
            value={
              user &&
              pluralize(user?.comments_count, t('comment'), t('comments'))
            }
            icon={<span>{user?.comments_count}</span>}
          />
        </div>
      </>
    )
  }

  const renderContent = () => {
    if (loading)
      return (
        <>
          <div className='grid w-full grid-cols-6 gap-5 pb-3'>
            <div className='h-9 w-9 animate-pulse rounded-full bg-gray5 ' />
            <div className='col-span-5 flex w-full flex-col space-y-1'>
              <div className='h-4 w-full animate-pulse rounded bg-gray5 ' />
              <div className='h-4 w-2/3 animate-pulse rounded bg-gray5 ' />
            </div>
          </div>
          <div className='grid h-4 animate-pulse grid-cols-3 gap-1'>
            <div className={badgeClass} />
            <div className={badgeClass} />
            <div className={badgeClass} />
          </div>
        </>
      )
    if (!user)
      return (
        <p className='px-3 py-2'>
          <div className='grid w-full grid-cols-6 gap-5 pb-3'>
            <Avatar
              url={_user?.profile_picture?.url}
              name={_user?.name}
              size='md'
              rounded
            />
            <div className='col-span-5 flex w-full flex-col space-y-1'>
              <div className='flex items-center'>
                <h3 className='mb-0 mr-1 truncate text-sm text-gray12 '>
                  {_user?.name}
                </h3>
              </div>
              <span className='inline w-full space-x-1 truncate text-sm text-gray9 '>
                {userProfile?.is_admin_of_organization && _user && (
                  <>
                    <span className='text-gray6'>•</span>
                    <span
                      className='cursor-pointer'
                      onClick={() => handleCopyEmail()}
                    >
                      {!_user.email
                        ? t('common.invalidEmail', { ignorePrefix: true })
                        : _user?.email}
                    </span>
                  </>
                )}
              </span>
            </div>
          </div>
          {t('users.userDeletedContent', { ignorePrefix: true })}
        </p>
      )
    return renderProfilecard()
  }

  return (
    <HoverCard
      onOpenChange={(open) => handleShow(open)}
      openDelay={400}
      closeDelay={0}
    >
      <HoverCardTrigger>{children}</HoverCardTrigger>
      <HoverCardContent align='end' className='!p-0'>
        <div className='flex flex-col gap-2 overflow-hidden rounded-lg '>
          {!!profileCardLabel && (
            <p className='w-full bg-gray6 py-1 text-center text-xs font-medium text-gray12'>
              {profileCardLabel}
            </p>
          )}
          <div className='p-2'>{renderContent()}</div>
        </div>
      </HoverCardContent>
    </HoverCard>
  )
}
