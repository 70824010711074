import dynamic from 'next/dynamic'
import React, { createContext, useContext, useEffect, useState } from 'react'

// Import the PerformanceHUD component with no SSR
const PerformanceHUD = dynamic(
  () =>
    import('./PerformanceHUD').then((mod) => ({ default: mod.PerformanceHUD })),
  { ssr: false }
)

// Create context for the HUD
interface PerformanceHUDContextType {
  isVisible: boolean
  toggleHUD: () => void
  showHUD: () => void
  hideHUD: () => void
  setVisible: (visible: boolean) => void
}

const PerformanceHUDContext = createContext<PerformanceHUDContextType>({
  isVisible: false,
  toggleHUD: () => {},
  showHUD: () => {},
  hideHUD: () => {},
  setVisible: () => {},
})

// Hook to use the HUD context
export const usePerformanceHUD = () => useContext(PerformanceHUDContext)

interface PerformanceHUDProviderProps {
  children: React.ReactNode
  initialVisible?: boolean
  initialPosition?: { x: number; y: number }
  initialOpacity?: number
}

export const PerformanceHUDProvider: React.FC<PerformanceHUDProviderProps> = ({
  children,
  initialVisible = false,
  initialPosition = { x: 20, y: 20 },
  initialOpacity = 0.8,
}) => {
  const [isVisible, setIsVisible] = useState(initialVisible)

  // Check for keyboard shortcut to toggle HUD (Ctrl+Shift+P)
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.shiftKey && e.key === 'P') {
        e.preventDefault()
        setIsVisible((prev) => !prev)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [])

  // Check for URL parameter to enable HUD
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('debug') || urlParams.has('hud')) {
        setIsVisible(true)
      }
    }
  }, [])

  const toggleHUD = () => setIsVisible((prev) => !prev)
  const showHUD = () => setIsVisible(true)
  const hideHUD = () => setIsVisible(false)
  const setVisible = (visible: boolean) => setIsVisible(visible)

  return (
    <PerformanceHUDContext.Provider
      value={{ isVisible, toggleHUD, showHUD, hideHUD, setVisible }}
    >
      {children}
      {isVisible && (
        <PerformanceHUD
          initialPosition={initialPosition}
          initialOpacity={initialOpacity}
          visible={isVisible}
          setVisible={setVisible}
        />
      )}
    </PerformanceHUDContext.Provider>
  )
}

export default PerformanceHUDProvider
