import { Trash } from '@phosphor-icons/react'
import clsx from 'clsx'
import React from 'react'

import AdminPostParentStatusFilter from '@/components/admin/posts/filters/AdminPostParentStatusFilter'
import AdminPostStatusFilter from '@/components/admin/posts/filters/AdminPostStatusFilter'
import AdminIntegrationAsanaProjectsDropDown from '@/components/admin/settings/Integrations/config/Asana/AdminIntegrationAsanaProjectsDropDown'
import AdminIntegrationLinearTeamsDropDown from '@/components/admin/settings/Integrations/config/Linear/AdminIntegrationLinearTeamsDropDown'
import AdminIntegrationCustomFieldsDropDown from '@/components/admin/settings/Integrations/config/Shared/AdminIntegrationCustomFieldsDropDown'
import AdminIntegrationCustomFieldValueDropDown from '@/components/admin/settings/Integrations/config/Shared/AdminIntegrationCustomFieldValueDropDown'
import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'
import {
  snakeToCamel,
  templateComponentReplace,
} from '@/lib/helpers/stringHelpers'
import type {
  IAsanaIntegrationStatusSyncRule,
  IIntegrationStatusSyncData,
  IStatusSyncRule,
} from '@/types/integration/statusSync'

import AdminIntegrationProviderStatusDropDown from './AdminIntegrationProviderStatusDropDown'
import AdminIntegrationSyncNotifyDropdown from './AdminIntegrationSyncNotifyDropdown'
import AdminIntegrationSyncSourceDropdown from './AdminIntegrationSyncSourceDropdown'
import AdminIntegrationSyncWhenDropdown from './AdminIntegrationSyncWhenDropdown'

interface IAdminIntegrationProviderSyncRuleProps {
  syncRule: IStatusSyncRule
  edit: boolean
  provider: string
  configData: IIntegrationStatusSyncData
  newRule: boolean
  onChange?: any
  index?: number
  handleRemove?: (index: number) => void
  useCustomStates?: boolean
}

export default function AdminIntegrationProviderSyncRule({
  handleRemove,
  onChange,
  provider,
  configData,
  index,
  newRule,
  syncRule,
  useCustomStates,
}: IAdminIntegrationProviderSyncRuleProps) {
  const t = useTranslations(`integrations.${snakeToCamel(provider)}`)

  const handleRemoveRule = () => {
    if (handleRemove && index !== undefined) handleRemove(index)
  }

  const selectSingularOrPlural = () => {
    if (syncRule.when === 'all')
      return t(`configure.syncRule.providerRulePlural`)
    return t(`configure.syncRule.providerRuleSingular`)
  }

  const renderContent = () => {
    return templateComponentReplace(selectSingularOrPlural(), {
      when: (
        <span className='mx-1'>
          <AdminIntegrationSyncWhenDropdown
            whenData={configData.when_data}
            syncRule={syncRule}
            edit={newRule}
            onChange={onChange}
          />
        </span>
      ),
      source: (
        <span className='mx-1'>
          <AdminIntegrationSyncSourceDropdown
            sourceData={configData.sources}
            provider={provider}
            syncRule={syncRule}
            edit={newRule}
            onChange={onChange}
          />
        </span>
      ),
      provider:
        syncRule.when === 'any'
          ? t(`configure.syncRule.${snakeToCamel(provider)}.changedToSingular`)
          : t(`configure.syncRule.${snakeToCamel(provider)}.changedToPlural`),
      status: useCustomStates ? (
        <span className='mx-1'>
          <AdminPostStatusFilter
            behaviour='select'
            filters={{ state: syncRule.status as string }}
            multiple={false}
            placeholder={t(`integrations.statusSync.status.placeholder`, {
              ignorePrefix: true,
            })}
            disabled={!newRule}
            onChange={(_key, value) => onChange('status', value[0])}
          />
        </span>
      ) : (
        <span className='mx-1'>
          <AdminPostParentStatusFilter
            filters={{ status: syncRule.status as string }}
            multiple={false}
            placeholder={t(`integrations.statusSync.status.placeholder`, {
              ignorePrefix: true,
            })}
            disabled={!newRule}
            onChange={(_key, value) => onChange('status', value[0])}
          />
        </span>
      ),
      target: t(`common.appName`, { ignorePrefix: true }),
      triggerStatus: (
        <span className='mx-1'>
          <AdminIntegrationProviderStatusDropDown
            syncRule={syncRule}
            provider={provider}
            onChange={onChange}
            statuses={configData.statuses}
            edit={newRule}
            ruleKey={`trigger_status`}
            size='xs'
          />
        </span>
      ),
      notify: (
        <span className='mx-1'>
          <AdminIntegrationSyncNotifyDropdown
            notifyData={configData.notify_customers}
            syncRule={syncRule}
            edit={newRule}
            onChange={onChange}
          />
        </span>
      ),
      teams: (
        <span className='mx-1'>
          {configData.teams && (
            <AdminIntegrationLinearTeamsDropDown
              provider={provider}
              teams={configData.teams}
              syncRule={syncRule}
              edit={newRule}
              onChange={onChange}
              ruleKey='team_id'
            />
          )}
        </span>
      ),
      projects: (
        <span className='mx-1'>
          {configData.projects && (
            <AdminIntegrationAsanaProjectsDropDown
              provider={provider}
              projects={configData.projects}
              syncRule={syncRule}
              edit={newRule}
              onChange={onChange}
              ruleKey='project_id'
            />
          )}
        </span>
      ),
      customFields: (
        <span className='mx-1'>
          {configData.projects && (
            <AdminIntegrationCustomFieldsDropDown
              provider={provider}
              projects={configData.projects}
              syncRule={syncRule as IAsanaIntegrationStatusSyncRule}
              edit={newRule}
              onChange={onChange}
              ruleKey='custom_field_id'
            />
          )}
        </span>
      ),
      customFieldData: (
        <span className='mx-1'>
          {configData.projects && (
            <AdminIntegrationCustomFieldValueDropDown
              syncRule={syncRule as IAsanaIntegrationStatusSyncRule}
              provider={provider}
              edit={newRule}
              onChange={onChange}
              projects={configData.projects}
              ruleKey='custom_field_value'
            />
          )}
        </span>
      ),
    })
  }

  return (
    <>
      <div
        className={clsx(
          newRule ? '' : 'bg-gray2 p-3 ',
          'flex items-center justify-between rounded py-2 text-xs'
        )}
      >
        <span className='flex flex-wrap items-center space-y-1 text-sm'>
          {renderContent()}
        </span>
        {!newRule && (
          <Button
            size='xxs'
            danger
            variant='naked'
            onClick={() => handleRemoveRule()}
          >
            <Trash weight='bold' className='h-3.5 w-3.5' />
          </Button>
        )}
      </div>
    </>
  )
}
