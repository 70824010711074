import type { IComment } from '@/types/comment'
import type { IUserProfile } from '@/types/user'

export const canDeleteCommentAttachment = (
  comment: IComment,
  user: IUserProfile
) => {
  return (
    user &&
    (user.is_member_of_organization ||
      comment.can_delete ||
      user.id === comment.commenter.id)
  )
}
