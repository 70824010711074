import React, { useContext, useEffect, useRef, useState } from 'react'

import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Label from '@/components/shared/ui/Label'
import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import { getJiraIssueTypes } from '@/models/Integration'
import type { IJiraConfig } from '@/types/integration'
import type {
  IIntegrationPushRuleKey,
  IIntegrationPushRuleValue,
} from '@/types/integration/github'
import type {
  IAdminJiraIntegrationMapping,
  IJiraPushRule,
} from '@/types/integration/jira'
import type { IOrganizationSetting } from '@/types/organization'
import toaster from '@/utils/toast'

interface IAdminIntegrationJiraPushRuleProps {
  integrationMapping?: IAdminJiraIntegrationMapping
  configData: IJiraConfig
  onPushRuleChange: (newPushRule: IJiraPushRule) => void
  jiraIssueTypesData: ISelectOption[]
  jiraStatuses: ISelectOption[]
}
export default function AdminIntegrationJiraPushRule({
  configData,
  integrationMapping,
  onPushRuleChange,
  jiraIssueTypesData,
  jiraStatuses,
}: IAdminIntegrationJiraPushRuleProps) {
  const t = useTranslations('integrations.jira.configure')
  const { organizationSetting } = useContext(HNContext) as {
    organizationSetting: IOrganizationSetting
  }
  const [pushRule, setPushRule] = useState<IJiraPushRule>(configData.jira_info)
  const [pushToJira, setPushToJira] = useState(Boolean(pushRule.push_to_jira))
  const [isFetching, setIsFetching] = useState(false)
  const [jiraProject, setJiraProject] = useState(pushRule.project_id || '')
  const [jiraIssueTypes, setJiraIssueTypes] = useState<ISelectOption[]>([])
  const [jiraStatusList, setJiraStatusList] = useState<ISelectOption[]>([])
  const [jiraBoards, setJiraBoards] = useState<ISelectOption[]>([])
  const pushFromOptions = integrationMapping?.pushFromOptions.map((option) => ({
    value: option === 'automatic',
    label: t(`pushFromJira.values.${option}`),
  }))
  const pushRuleRef = useRef<IJiraPushRule>(pushRule)

  const getJiraIssueTypesByProject = (projectId: string) => {
    setIsFetching(true)
    getJiraIssueTypes(projectId)
      .then((data) => {
        setJiraIssueTypes(data.issuetypes)
        setJiraStatusList(data.jira_status_list)
      })
      .catch((error) => {
        toaster.error({ message: error.message })
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const handleDependencyChange = (
    key: IIntegrationPushRuleKey,
    value: IIntegrationPushRuleValue
  ) => {
    const currentPushRule = { ...pushRuleRef.current }
    const newPushRule = {
      ...currentPushRule,
      [key]: value,
    } as IJiraPushRule
    setPushRule(newPushRule)
    onPushRuleChange(newPushRule)

    if (key === 'project_id') {
      setPushRule({
        ...currentPushRule,
        issuetype_name: '',
      })
      setJiraProject(value as string)
      getJiraIssueTypesByProject(value as string)
    }
    pushRuleRef.current = newPushRule
  }

  const handleSelectChange = (
    data: ISelectOption[],
    key: string,
    value: ISelectOptionGroup
  ) => {
    const selectedValue = getSelectedValueByKey(data, value)
    if (Array.isArray(selectedValue)) {
      handleDependencyChange(key, selectedValue[0]?.value)
    } else {
      handleDependencyChange(key, selectedValue?.value)
    }
  }

  useEffect(() => {
    setJiraIssueTypes(jiraIssueTypesData)
    setJiraStatusList(jiraStatuses)
  }, [])

  useEffect(() => {
    const timer = setTimeout(
      () => setPushToJira(Boolean(pushRule.push_to_jira)),
      500
    )
    return () => {
      clearTimeout(timer)
    }
  }, [pushRule])

  useEffect(() => {
    handleDependencyChange('jira_status_list', jiraStatusList)
  }, [jiraStatusList])

  useEffect(() => {
    setJiraBoards(configData.boards)
  }, [configData])
  if (!configData || !integrationMapping || !pushFromOptions) return null

  return (
    <>
      <div className='flex items-center justify-between py-2'>
        <Typography.Text className='!font-medium !text-gray11 '>
          {t(`pushToJira.title`)}
        </Typography.Text>
        <Checkbox
          isSwitch
          checked={pushToJira}
          alertTexts={{
            title: pushToJira
              ? t(`pushToJira.cancellationTitle`)
              : t(`pushToJira.confirmationTitle`),
            description: pushToJira
              ? t(`pushToJira.cancellationDescription`)
              : t(`pushToJira.confirmationDescription`),
          }}
          onCheckedChange={(checked) => {
            handleDependencyChange('push_to_jira', checked)
          }}
        />
      </div>
      <div className='grid grid-cols-1 gap-6 py-2 md:grid-cols-2'>
        <div>
          <Label>{t(`pushToJira.projects.title`)}</Label>
          <Select
            options={configData.projects}
            placeholder={t(`pushToJira.projects.placeholder`)}
            onChange={(value) => {
              handleSelectChange(configData.projects, 'project_id', value)
            }}
            value={setSelectedValue(configData?.projects, jiraProject)}
          />
          <Typography.Text className='!text-[11px] !text-gray9 '>
            {t(`pushToJira.projects.description`)}
          </Typography.Text>
        </div>
        <div>
          <Label>{t(`pushToJira.issueType.title`)}</Label>
          <Select
            loading={isFetching}
            disabled={isFetching}
            options={jiraIssueTypes}
            placeholder={t(`pushToJira.issueType.placeholder`)}
            onChange={(value) => {
              handleSelectChange(jiraIssueTypes, 'issuetype_name', value)
              const selectedValue = Array.isArray(value) ? value[0] : value
              handleDependencyChange('issuetype_level', selectedValue?.level)
            }}
            value={setSelectedValue(
              jiraIssueTypes,
              pushRule.issuetype_name || ''
            )}
          />
          <Typography.Text className='!text-[11px] !text-gray9 '>
            {t(`pushToJira.issueType.description`)}
          </Typography.Text>
        </div>
      </div>
      <div className='my-5 h-px w-full border-t border-gray5 ' />
      <div className='flex items-center justify-between space-x-2 py-2'>
        <Typography.Text className='mb-0 !font-medium !text-gray11 '>
          {t(`pushFromJira.title`)}
        </Typography.Text>
        <span className='min-w-[180px]'>
          <Select
            options={pushFromOptions}
            placeholder={t(`pushFromJira.placeholder`)}
            onChange={(value) => {
              handleSelectChange(pushFromOptions, 'push_from_jira', value)
            }}
            value={setSelectedValue(
              pushFromOptions,
              pushRule.push_from_jira?.toString() || ''
            )}
          />
        </span>
      </div>
      <div className='grid grid-cols-1 gap-6 py-2 md:grid-cols-2'>
        <div>
          <Label>
            {t(`pushFromJira.board.title`, {
              data: {
                board: organizationSetting.bucket_singular_name,
              },
            })}
          </Label>
          <Select
            options={jiraBoards}
            placeholder={t(`pushFromJira.board.placeholder`, {
              data: {
                board: organizationSetting.bucket_singular_name,
              },
            })}
            value={setSelectedValue(jiraBoards, pushRule.board_id)}
            onChange={(value) => {
              handleSelectChange(jiraBoards, 'board_id', value)
            }}
          />
          <Typography.Text className='!text-[11px] !text-gray9 '>
            {t(`pushFromJira.board.description`, {
              data: {
                board: organizationSetting.bucket_singular_name,
              },
            })}
          </Typography.Text>
        </div>
      </div>
    </>
  )
}
