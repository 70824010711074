import { Plus, X } from '@phosphor-icons/react'
import clsx from 'clsx'
import React from 'react'

import Badge from '@/components/shared/ui/Badge'
import Button from '@/components/shared/ui/Button/Button'
import Counter from '@/components/shared/ui/Counter'
import Tooltip from '@/components/shared/ui/Tooltip'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { convertSnakeCaseToCamelCase } from '@/lib/helpers/dataHelpers'
import { QUANTITY_POWER_UPS } from '@/lib/helpers/powerUpsHelper'
import type { IPowerUpInfo, IProratedPlan } from '@/types/billing'

interface IPropTypes {
  powerUp: IPowerUpInfo | IProratedPlan | any
  onRemove?: (data: IPowerUpInfo | IProratedPlan | any) => void
  onSelect?: (data: IPowerUpInfo | IProratedPlan | any) => void
  onUpdate?: (data: IPowerUpInfo | IProratedPlan | any) => void
  showAddAndRemoveButton?: boolean
  planIncluded?: boolean
  multiplierCleaner?: (multiplier: number) => number | string
}

export default function PowerUpItem(props: IPropTypes) {
  const {
    powerUp,
    onRemove,
    onSelect,
    onUpdate,
    showAddAndRemoveButton,
    planIncluded,
    multiplierCleaner = (multiplier) => multiplier,
  } = props
  // const { organizationPlan } = useContext(HNContext)
  const t = useTranslations('billing')
  const isQuantityPowerUp = QUANTITY_POWER_UPS.includes(powerUp.slug)
  // TODO: for future use
  // const purchasedQuantity =
  //   organizationPlan?.power_ups?.[powerUp?.slug]?.purchased

  return (
    <div className='group flex items-center justify-between space-x-4 border-b border-gray5 py-2 last:border-none '>
      <div className='flex flex-col truncate'>
        <div className='flex items-center space-x-2'>
          <Typography.Text
            className={clsx(
              'font-medium !text-gray12 ',
              powerUp?.plan ? '!text-base font-semibold' : ''
            )}
          >
            {powerUp?.plan
              ? t(`plans.${convertSnakeCaseToCamelCase(powerUp.plan)}.name`)
              : t(
                  `powerUps.${convertSnakeCaseToCamelCase(powerUp.slug)}.title`
                )}
          </Typography.Text>
          {planIncluded && (
            <Badge size='xxs' variant='green' value={'Included with plan'} />
          )}
        </div>
        <Typography.Text className='line-clamp-2 whitespace-pre-wrap !text-gray9 '>
          {!powerUp?.plan
            ? t(
                `powerUps.${convertSnakeCaseToCamelCase(
                  powerUp.slug
                )}.description`
              )
            : ''}
        </Typography.Text>
      </div>
      <div className='relative flex items-center space-x-2 whitespace-nowrap transition duration-200'>
        {isQuantityPowerUp && (
          <>
            <Counter
              onCountChange={(e) => {
                onUpdate?.({
                  ...powerUp,
                  quantity: Number(e) === 0 ? 1 : Number(e),
                  total_prorate_amount: Number(e) * powerUp.amount,
                })
              }}
              // limit difference for when you upgrade to plan
              defaultValue={powerUp.limitDifference || 1}
              min={powerUp.canMakeZero ? 0 : powerUp.limitDifference}
              max={10000}
            />
            {powerUp.multiplier && (
              <Typography.Text className='text-sm !text-gray9 '>
                {`* ${multiplierCleaner(powerUp.multiplier)}`}
              </Typography.Text>
            )}
          </>
        )}
        {Boolean(!planIncluded && powerUp?.amount) && (
          <Typography.Text
            className={clsx('min-w-[3rem] text-right font-mono !text-gray12 ')}
          >
            {`$${powerUp.total_prorate_amount || powerUp?.amount} `}
          </Typography.Text>
        )}
        {onRemove && showAddAndRemoveButton && (
          <Tooltip
            placement='right'
            text={t('buttons.remove', { ignorePrefix: true })}
          >
            <Button
              size='xs'
              fab
              onClick={() => onRemove(powerUp)}
              variant='outline'
              icon={<X weight='bold' className='h-3 w-3 shrink-0' />}
            />
          </Tooltip>
        )}
        {onSelect && showAddAndRemoveButton && (
          <Tooltip
            placement='right'
            text={t('buttons.add', { ignorePrefix: true })}
          >
            <Button
              size='xs'
              fab
              onClick={() => onSelect(powerUp)}
              variant='outline'
              icon={<Plus weight='bold' className='h-3 w-3 shrink-0' />}
            />
          </Tooltip>
        )}
      </div>
    </div>
  )
}
