import { ArrowRight, HandArrowUp } from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import AdminKBCollectionPicker from '@/components/admin/knowledgeBase/category/AdminKBCollectionPicker'
import UpgradeBlock from '@/components/shared/components/module/billing/UpgradeBlock'
import Button from '@/components/shared/ui/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import { LANGUAGES } from '@/config/appConstants'
import { APP_PATHS } from '@/config/appPaths'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import { hasFeatureLimit } from '@/lib/helpers/planHelpers'
import { slugify } from '@/lib/helpers/stringHelpers'
import { createArticle } from '@/models/KnowledgeBase'
import type { IKnowledgeBaseArticle } from '@/types/knowledgeBase'
import toaster from '@/utils/toast'

interface IAdminKBNewArticleDialogProps {
  parentArticle?: IKnowledgeBaseArticle
}
export interface AdminKBNewArticleDialogActions {
  openDialog: (data: Partial<IKnowledgeBaseArticle>) => void
}

const AdminKBNewArticleDialog = forwardRef(
  (
    { parentArticle }: IAdminKBNewArticleDialogProps,
    ref: ForwardedRef<AdminKBNewArticleDialogActions>
  ) => {
    const { article_customization, organizationPlan } = useContext(HNContext)

    const t = useTranslations()
    const router = useRouter()
    const { lang } = router.query as { lang: string }
    const [open, setOpen] = useState(false)
    const [creating, setCreating] = useState(false)
    const defaultLocale = LANGUAGES.find(
      (l) =>
        l.value === (lang || article_customization?.default_language || 'en')
    )
    const [formData, setFormData] = useState<Partial<IKnowledgeBaseArticle>>({
      title: '',
      locale: defaultLocale?.value,
      collection_id: parentArticle?.collection?.id,
      privacy: 'public',
    })
    const [preferedLocale, setPreferedLocale] = useState<string | null>()
    const [generatedSlug, setGeneratedSlug] = useState<string | null>()

    const hasLimit = useMemo(
      () => hasFeatureLimit('articles', organizationPlan),
      [organizationPlan]
    )

    const handleShortcut = (eventData: any) => {
      if (eventData.action === 'CREATE_ARTICLE') {
        setOpen(true)
      }
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setCreating(true)
      return createArticle({
        ...formData,
        original_id: parentArticle?.id || undefined,
      })
        .then((createdArticle) => {
          router.push(
            APP_PATHS.DASHBOARD_ARTICLE_EDIT_PATH(
              createdArticle.slug,
              createdArticle.locale
            )
          )
          setFormData({
            title: '',
            locale: defaultLocale?.value,
            collection_id: parentArticle?.collection?.id,
          })
          setOpen(false)
          setCreating(false)
        })
        .catch((err) => {
          toaster.error(err)
          setCreating(false)
        })
    }

    useEffect(() => {
      EventEmitter.subscribe('SHORTCUT_TRIGGERED', handleShortcut)
      return () => {
        EventEmitter.unsubscribe('SHORTCUT_TRIGGERED', handleShortcut)
      }
    }, [])

    useEffect(() => {
      setGeneratedSlug(slugify(formData.title || '', 300, '-'))
    }, [formData.title])

    useImperativeHandle(ref, () => ({
      openDialog: (props: Partial<IKnowledgeBaseArticle>) => {
        setOpen(true)
        if (props.locale) {
          setFormData((prev) => ({ ...prev, locale: props.locale }))
          setPreferedLocale(props.locale)
        }
      },
    }))

    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent backdrop className='!overflow-y-hidden'>
          <DialogClose />
          <DialogTitle>Create new article</DialogTitle>
          {hasLimit ? (
            <form onSubmit={handleSubmit} className='flex flex-col gap-2'>
              <div className='space-y-1'>
                <Label>Title</Label>
                <Input
                  onChange={(e) => {
                    setFormData({ ...formData, title: e.target.value })
                  }}
                  id='title'
                  name='title'
                  placeholder='How to do this...'
                  defaultValue={formData.title}
                />
              </div>
              <div className='space-y-1'>
                <Label>Slug (The URL Path)</Label>
                <Input
                  value={formData.slug || generatedSlug || ''}
                  id='slug'
                  name='slug'
                  placeholder='how-to-do-this'
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      slug: slugify(e.target.value, 200, '-'),
                    })
                  }}
                />
              </div>
              <div className='space-y-1'>
                <Label>Language</Label>
                <Select
                  options={LANGUAGES}
                  defaultValue={defaultLocale ? [defaultLocale] : undefined}
                  value={LANGUAGES.filter((l) => l.value === formData.locale)}
                  disabled={!!preferedLocale}
                  className='!rounded-lg'
                  placeholder='Select language'
                  onChange={(selected) => {
                    const langValue = selected as ISelectOption
                    setFormData({ ...formData, locale: langValue.value })
                  }}
                  side='bottom'
                />
              </div>
              {!parentArticle && (
                <div className='space-y-1'>
                  <Label>{t('common.modules.collection')}</Label>

                  <AdminKBCollectionPicker
                    selectedIds={
                      formData.collection_id ? [formData.collection_id] : []
                    }
                    disabled={!!parentArticle}
                    showSearch={false}
                    onSelectedChange={(c) => {
                      const firstId = c[0]
                      setFormData({
                        ...formData,
                        collection_id: firstId ?? undefined,
                      })
                    }}
                    inline
                  />
                </div>
              )}
              {!!parentArticle && (
                <div>
                  <p>
                    Creating as a translation of{' '}
                    <strong className='font-semibold'>
                      {parentArticle.title}
                    </strong>
                  </p>
                </div>
              )}
              <div className='flex items-center justify-end gap-2'>
                {/* <Button type='button' size='xs' onClick={handleAutoTranslate}>
                Auto Translate
              </Button> */}
                <Button
                  type='submit'
                  size='xs'
                  disabled={creating}
                  iconRight={<ArrowRight />}
                >
                  {t('buttons.create')}
                </Button>
              </div>
            </form>
          ) : (
            <UpgradeBlock
              icon={HandArrowUp}
              message={t('knowledgebase.messages.articleExceededLimit')}
              buttonText={t('buttons.upgrade')}
            />
          )}
        </DialogContent>
      </Dialog>
    )
  }
)

AdminKBNewArticleDialog.displayName = 'AdminKBNewArticleDialog'

export default AdminKBNewArticleDialog
