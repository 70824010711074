import { QueryClientProvider } from '@tanstack/react-query'
import type { ReactNode } from 'react'
import React, { useContext, useEffect } from 'react'

import PlanExpiredAlert from '@/components/admin/organization/settings/billing/PlanExpiredAlert'
import AccountConfirmationAlert from '@/components/shared/components/userAccount/AccountConfirmationAlert'
import Wrapper from '@/components/shared/ui/Wrapper/Wrapper'
import CustomUserSideHeader from '@/components/users/shared/custom/CustomUserSideHeader'
import PageViewAnalytics from '@/components/users/shared/PageViewAnalytics'
import PoweredByButton from '@/components/users/shared/PoweredByButton'
import UserSideHeader from '@/components/users/shared/UserSideHeader'
import { queryClient } from '@/config/queryClient'
import HNContext from '@/context/HNContext'

interface IPropTypes {
  children: ReactNode
}

export default function UserSideLayout(props: IPropTypes) {
  const { organizationCustomization } = useContext(HNContext)

  useEffect(() => {
    if (typeof document !== undefined) {
      document.body.classList.add('bg-snow')
      document.body.classList.add('dark:bg-gray2')
    }
  }, [])

  return (
    <div className='bg-snow dark:bg-gray2'>
      <Wrapper>
        {organizationCustomization?.home_page_config?.showCustomHeader ? (
          <CustomUserSideHeader />
        ) : (
          <UserSideHeader />
        )}
        <PlanExpiredAlert />
        <AccountConfirmationAlert />
        <QueryClientProvider client={queryClient}>
          {props.children}
        </QueryClientProvider>
        <PoweredByButton />
        <PageViewAnalytics />
      </Wrapper>
    </div>
  )
}
