import type { AxiosRequestConfig } from 'axios'

import {
  ADMIN_AI_ARTICLE_PATH,
  ADMIN_COLLECTION_ARTICLES_PATH,
  ADMIN_COLLECTION_CREATE_PATH,
  ADMIN_COLLECTION_PATH,
  ADMIN_COLLECTIONS_PATH,
  ADMIN_KNOWLEDGE_BASE_REGENERATE_WIDGET_KEY,
  ADMIN_KNOWLEDGE_BASE_SETTINGS,
  ADMIN_KNOWLEDGE_BASE_SUMMARIZATION,
  ADMIN_TAG_PATH,
  ARTICLE_ASSIGNEE_PATH,
  ARTICLE_AUTHOR_PATH,
  ARTICLE_PUBLISH_PATH,
  ARTICLE_REMOVE_ASSIGNEE_PATH,
  ARTICLE_REMOVE_AUTHOR_PATH,
  ARTICLE_REMOVE_TAG_PATH,
  ARTICLE_TAG_PATH,
  ARTICLE_TRANSLATION_PATH,
  CREATE_ARTICLE_PATH,
  GET_REQUEST_MODULE_PATH,
  IMPORT_FROM_ZENDESK_PATH,
  MEMBER_ARTICLE_PATH,
  MEMBER_ARTICLES_PATH,
  REMOVE_TAG_IMAGE_PATH,
  REQUEST_MODULE_PATH,
  UPDATE_KB_CUSTOMIZATION_PATH,
  USER_ARTICLE_PATH,
  USER_ARTICLE_WITH_KEY,
  USER_ARTICLES_PATH,
  USER_COLLECTION_PATH,
  USER_COLLECTIONS_PATH,
  VERIFY_KB_CUSTOM_DOMAIN,
} from '@/config/routes'
import API from '@/lib/API'
import { jsonToFormData } from '@/lib/helpers/dataHelpers'
import type { IAiChangelogParams } from '@/types/changelog'
import type {
  IKeyValueMap,
  IListAPIParams,
  IRequestHeaders,
} from '@/types/common'
import type {
  IArticleCustomization,
  IArticleListRequest,
  ICollectionListParams,
  ICreateCategory,
  IKnowledgeBaseArticle,
  IKnowledgeBaseArticleListParams,
  IKnowledgeBaseCollection,
  IKnowledgeBaseCustomization,
  IKnowledgeBaseParentCollection,
  IKnowledgeBaseSettings,
} from '@/types/knowledgeBase'

export const getCollection = (
  slug: string,
  headers: IRequestHeaders
): Promise<IKnowledgeBaseCollection> => {
  return API.get(USER_COLLECTION_PATH(slug), {}, { headers })
}

export const getCollections = (
  params: ICollectionListParams,
  headers: IRequestHeaders = {}
): Promise<IKnowledgeBaseCollection[]> => {
  return API.get(
    USER_COLLECTIONS_PATH,
    { ...params },
    {
      headers,
    }
  )
}

export const getArticles = (
  params: IArticleListRequest,
  headers: IRequestHeaders = {}
): Promise<IKnowledgeBaseArticle[]> => {
  return API.get(
    USER_ARTICLES_PATH,
    {
      ...params,
      source_type: 'knowledge_base',
    },
    {
      headers,
    }
  )
}
export const getArticle = (
  slug: string,
  params: IKeyValueMap = {},
  headers: IRequestHeaders = {}
): Promise<IKnowledgeBaseArticle> => {
  return API.get(USER_ARTICLE_PATH(slug), params, { headers })
}

// Admin
export const getKnowledgeBaseSettings = (): Promise<IKnowledgeBaseSettings> => {
  return API.get(ADMIN_KNOWLEDGE_BASE_SETTINGS)
}
export const updateKnowledgeBaseSettings = (
  params: IKeyValueMap,
  headers: IRequestHeaders = {}
): Promise<IArticleCustomization> => {
  return API.post(ADMIN_KNOWLEDGE_BASE_SETTINGS, params, {
    headers,
  })
}

export const getKnowledgeBaseCustomizations =
  (): Promise<IKnowledgeBaseCustomization> => {
    return API.get(UPDATE_KB_CUSTOMIZATION_PATH)
  }
export const updateKnowledgeBaseCustomizations = (
  params: IKeyValueMap
): Promise<IArticleCustomization> => {
  return API.post(UPDATE_KB_CUSTOMIZATION_PATH, params)
}

export const getRecentArticles = (
  data: IKnowledgeBaseArticleListParams,
  otherOptions: AxiosRequestConfig = {}
): Promise<IKnowledgeBaseArticle[]> => {
  return API.get(MEMBER_ARTICLES_PATH, data, otherOptions)
}

export const getMemberCategory = (
  slug: string
): Promise<IKnowledgeBaseCollection> => {
  return API.get(ADMIN_TAG_PATH(slug), { source_type: 'knowledge_base' })
}
export const getAdminCollections = (
  data: IListAPIParams
): Promise<IKnowledgeBaseCollection[]> => API.get(ADMIN_COLLECTIONS_PATH, data)

export const getAdminCollectionArticlesList = (
  data: IListAPIParams
): Promise<{
  collections: IKnowledgeBaseCollection[]
  articles: IKnowledgeBaseArticle[]
  current_collection?: IKnowledgeBaseParentCollection
}> => API.get(ADMIN_COLLECTION_ARTICLES_PATH, data)

export const createCollection = (
  data: ICreateCategory
): Promise<IKnowledgeBaseCollection> =>
  API.post(ADMIN_COLLECTION_CREATE_PATH, data)

export const updateCollection = (
  id: string,
  data: Partial<IKnowledgeBaseCollection>
): Promise<IKnowledgeBaseCollection> => API.put(ADMIN_COLLECTION_PATH(id), data)

export const deleteCollection = (id: string) =>
  API.delete(ADMIN_COLLECTION_PATH(id))

export const getMemberArticle = (
  slug: string,
  lang: string
): Promise<IKnowledgeBaseArticle> =>
  API.get(MEMBER_ARTICLE_PATH(slug), { lang })

export const createArticle = (data: object): Promise<IKnowledgeBaseArticle> =>
  API.post(CREATE_ARTICLE_PATH, data)

export const updateArticle = (
  slug: string,
  article: Partial<IKnowledgeBaseArticle>,
  lang: string
) =>
  API.put(MEMBER_ARTICLE_PATH(slug), {
    ...article,
    lang,
  })

export const updateArticleSlug = (
  slug: string,
  article: { article_slug: string },
  lang: string
) =>
  API.put(MEMBER_ARTICLE_PATH(slug), {
    ...article,
    lang,
  })

export const publishArticle = (slug: string, article: object, lang: string) =>
  API.post(ARTICLE_PUBLISH_PATH(slug), { ...article, lang })

export const addArticleAuthor = (
  slug: string,
  authorId: number | string,
  lang: string
) => API.post(ARTICLE_AUTHOR_PATH(slug), { user_id: authorId, lang })

export const removeArticleAuthor = (
  slug: string,
  authorId: number | string,
  lang: string
) => API.delete(ARTICLE_REMOVE_AUTHOR_PATH(slug, authorId), { lang })

export const addArticleAssignee = (
  slug: string,
  assigneeId: number | string,
  lang: string
) => API.post(ARTICLE_ASSIGNEE_PATH(slug), { user_id: assigneeId, lang })

export const removeArticleAssignee = (
  slug: string,
  assigneeId: number | string,
  lang: string
) =>
  API.delete(ARTICLE_REMOVE_ASSIGNEE_PATH(slug, assigneeId), {
    lang,
  })

export const addArticleTag = (
  slug: string,
  data: {
    value: string | number
    lang: string
    __isNew__?: boolean
  }
) => API.post(ARTICLE_TAG_PATH(slug), data)

export const removeArticleTag = (
  slug: string,
  tagId: number | string,
  lang: string
) =>
  API.delete(ARTICLE_REMOVE_TAG_PATH(slug, tagId), {
    lang,
  })

export const deleteArticle = (slug: string, lang: string) =>
  API.delete(MEMBER_ARTICLE_PATH(slug), { lang })

export const deleteCollectionImage = (id: string) =>
  API.post(REMOVE_TAG_IMAGE_PATH(id), { source_type: 'knowledge_base' })

export const getKnowledgeBaseRequest = (module: string) =>
  API.get(GET_REQUEST_MODULE_PATH(module))

export const requestKnowledgebase = (data: object) =>
  API.post(REQUEST_MODULE_PATH, data)

export const generateKnowledgeBaseWidgetKey = () =>
  API.post(ADMIN_KNOWLEDGE_BASE_REGENERATE_WIDGET_KEY)

export const browseUserArticle = (
  slug: string
): Promise<IKnowledgeBaseArticle> => API.get(USER_ARTICLE_WITH_KEY(slug))

export const translateAndCreateArticle = ({
  slug,
  locale,
  lang,
}: {
  slug: string
  locale: string
  lang: string
}) => {
  return API.post(
    ARTICLE_TRANSLATION_PATH(slug),
    {
      locale,
    },
    {
      params: {
        lang,
      },
    }
  )
}

export const updateKBCustomDomain = (data: { custom_domain: string }) =>
  API.post(UPDATE_KB_CUSTOMIZATION_PATH, data)

export const verifyKBCustomDomain = () => API.post(VERIFY_KB_CUSTOM_DOMAIN)

export const generateAISummary = (slug: string, lang: string) => {
  return API.post(ADMIN_KNOWLEDGE_BASE_SUMMARIZATION(slug), { lang })
}

interface IZendeskImportParams {
  import_resource: 'zendesk'
  create_as_draft: boolean
}
export const importFromZendesk = (params: IZendeskImportParams) => {
  return API.post(IMPORT_FROM_ZENDESK_PATH, params)
}

export const getAIGeneratedArticle = (data: IAiChangelogParams) => {
  return API.post(ADMIN_AI_ARTICLE_PATH, data)
}

export const updateArticleCoverImage = (
  slug: string,
  file: File,
  lang: string
) => {
  const formData = jsonToFormData({
    cover_image: file,
    lang,
  })
  return API.putWithFiles(MEMBER_ARTICLE_PATH(slug), formData)
}

export const resetArticleCoverImage = (slug: string, lang: string) =>
  API.post(MEMBER_ARTICLE_PATH(slug), { cover_image: null, lang })
