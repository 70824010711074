import React, { useContext } from 'react'

import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IPost, IPriorityRating } from '@/types/post'

interface IPropTypes {
  post: IPost
}

export default function SinglePosPriorityProgress({ post }: IPropTypes) {
  const { priority_rating_summary } = post
  const t = useTranslations('post')
  const { organizationSetting } = useContext(HNContext)

  const renderRating = (rating: IPriorityRating, index: number) => (
    <div
      className='flex flex-col items-center font-medium text-gray9 '
      key={index}
    >
      <p className='text-lg font-normal'>{rating.value}</p>
      <Typography.Text>{`${t(
        `priorityRatingSummary.${rating.key}`
      )}`}</Typography.Text>
    </div>
  )

  if (!organizationSetting?.priority_rating) return <></>
  if (!priority_rating_summary) return <></>

  return (
    <div className='space-y-2 px-4'>
      <Typography.Text className='col-span-2 font-medium'>
        {t('labels.rating')}
      </Typography.Text>
      <div className='grid grid-cols-3'>
        {priority_rating_summary
          .filter((r) => r.key !== 'total')
          .map(renderRating)}
      </div>
    </div>
  )
}
