import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'

import HNContext from '@/context/HNContext'
import FourNotFour from '@/pages/404'
import type { IPost } from '@/types/post'

import { EventEmitter } from '../../../lib/eventEmitter'
import AdminSinglePostHeader from './AdminSinglePostHeader'
import SinglePostDetails from './SinglePostDetails'

interface IPropTypes {
  post?: IPost
  onClose?: () => void
  isModal?: boolean
}
const SinglePostModal = observer(({ post, onClose }: IPropTypes) => {
  const [editing, setEditing] = useState<boolean>(false)
  const { userProfile } = useContext(HNContext)

  const closeEdit = () => setEditing(false)

  const handleToggleEdit = () => setEditing(!editing)

  const handleShortcut = ({ action }: { action: string }) => {
    if (action === 'ESCAPE') {
      const modelEl = document.querySelector(
        '[role="dialog"]'
      ) as HTMLElement | null
      if (!modelEl || modelEl.dataset.state !== 'open') {
        onClose?.()
      }
    }
    if (action === 'EDIT') {
      if (userProfile?.is_csm_of_organization) setEditing(true)
    }
  }

  useEffect(() => {
    EventEmitter.subscribe('SHORTCUT_TRIGGERED', handleShortcut)
    return () => {
      EventEmitter.unsubscribe('SHORTCUT_TRIGGERED', handleShortcut)
    }
  }, [])

  if (!post)
    return (
      <div className='absolute z-[100] mx-auto h-full w-full bg-snow dark:bg-[#1c1d1f]'>
        <FourNotFour />
      </div>
    )

  return (
    <>
      <div
        className={
          'singlepostwidth fixed inset-x-0 z-[4] h-screen min-h-screen bg-snow dark:bg-gray1 md:left-[270px]'
        }
      >
        <AdminSinglePostHeader
          post={post}
          onClose={onClose}
          onEdit={handleToggleEdit}
        />

        <SinglePostDetails
          post={post}
          editing={editing}
          closeEdit={closeEdit}
        />
      </div>
    </>
  )
})

export default SinglePostModal
