import { MagicWand, Smiley, SmileyWink, Sparkle } from '@phosphor-icons/react'
import React, { useContext, useState } from 'react'

import { DEFAULT_BUTTON_CLASS } from '@/components/shared/components/editor/editorConstants'
import Badge from '@/components/shared/ui/Badge'
import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import Spinner from '@/components/shared/ui/Loader'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { recordEvent } from '@/lib/helpers/appHelpers'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'

interface IPropTypes {
  onClick: (action: string) => Promise<void>
}

export default function EnhanceButton({ onClick }: IPropTypes) {
  const { userProfile, organizationPlan } = useContext(HNContext)
  const [loading, setLoading] = useState(false)

  const isEnabled =
    !!organizationPlan && featureIsEnabled('ai', organizationPlan, {})

  const handleClick = (action: string) => {
    setLoading(true)
    recordEvent('AI_ENHANCE', { action, skipSegment: true })
    onClick(action).finally(() => {
      setLoading(false)
    })
  }

  if (!isEnabled) return <></>
  if (!userProfile?.is_member_of_organization) return <></>

  return (
    <Dropdown>
      <DropdownTrigger asChild>
        <span>
          <Tooltip
            asChild
            disableHoverableContent
            placement='bottom'
            text={
              <span className='flex items-center gap-1'>
                Enhance
                <Badge
                  className='ml-auto flex rounded bg-gray5 px-1.5 py-1 font-mono text-xxs font-semibold lowercase leading-none text-gray10 transition  '
                  size='xxs'
                  value='β'
                />
              </span>
            }
          >
            <button type='button' className={`${DEFAULT_BUTTON_CLASS}`}>
              {loading ? (
                <Spinner height='10' size={'15'} />
              ) : (
                <Sparkle weight='bold' />
              )}
            </button>
          </Tooltip>
        </span>
      </DropdownTrigger>
      <DropdownContent>
        <DropdownItem
          onSelect={() => {
            handleClick('enhnaceMessage')
          }}
          itemValue='Enhance'
          icon={<MagicWand />}
        />
        <DropdownItem
          onSelect={() => {
            handleClick('softTone')
          }}
          itemValue='More Friendly'
          icon={<SmileyWink />}
        />
        <DropdownItem
          onSelect={() => {
            handleClick('professionalTone')
          }}
          itemValue='More Professional'
          icon={<Smiley />}
        />
      </DropdownContent>
    </Dropdown>
  )
}
