import type { IOrganizationPlan } from '@/types/organization'

export const hasFeatureLimit = (feature: string, plan?: IOrganizationPlan) => {
  const planDetaills = plan?.power_ups?.[feature]
  if (!planDetaills) return false

  const { limit, used } = planDetaills
  // return limit && used && limit > used
  return limit !== undefined && used !== undefined && limit > used
}
