import React, { useEffect, useState } from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectPropTypes,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import { titleize } from '@/lib/helpers/stringHelpers'
import type { IIntegrationStatusSyncRuleKey } from '@/types/integration/github'
import type { IStatusSyncRule } from '@/types/integration/statusSync'

interface IAdminIntegrationProviderStatusDropDownProps {
  syncRule: IStatusSyncRule
  provider: string
  edit: boolean
  ruleKey: IIntegrationStatusSyncRuleKey
  onChange: (key: string, value: string) => void
  statuses: ISelectOption[]
  size?: ISelectPropTypes['size']
}
export default function AdminIntegrationProviderStatusDropDown({
  provider,
  syncRule,
  edit,
  ruleKey,
  onChange,
  statuses,
  size = 'xs',
}: IAdminIntegrationProviderStatusDropDownProps) {
  const t = useTranslations(`integrations.${provider}`)

  const formatStatuses = (data: ISelectOption[]) =>
    data.map((status) => {
      return {
        ...status,
        label: titleize(status.label),
      }
    })

  const [states, setStates] = useState(formatStatuses(statuses))

  useEffect(() => {
    if (provider === 'linear' && syncRule?.team_id) {
      setStates(
        statuses.filter((status) => status.team_id === syncRule.team_id)
      )
    }
  }, [syncRule])

  return (
    <Select
      options={states}
      placeholder={t(`integrations.statusSync.status.placeholder`, {
        ignorePrefix: true,
      })}
      value={setSelectedValue(states, (syncRule[ruleKey] || '') as string)}
      onChange={(value) => {
        const selectedValue = getSelectedValueByKey(states, value)
        if (selectedValue && Array.isArray(selectedValue)) {
          onChange(ruleKey, selectedValue[0]?.value)
        } else {
          onChange(ruleKey, selectedValue?.value)
        }
      }}
      disabled={!edit || (provider === 'linear' && !syncRule.team_id)}
      size={size}
    />
  )
}
