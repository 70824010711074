import { Node } from '@tiptap/core'
import type { Node as ProseMirrorNode } from '@tiptap/pm/model'
import { ReactRenderer } from '@tiptap/react'
import type { SuggestionOptions } from '@tiptap/suggestion'
import Suggestion from '@tiptap/suggestion'
import tippy from 'tippy.js'

import CommandList from './CommandList'
import { commands } from './commands'

declare module '@tiptap/core' {
  interface Commands {
    commands: {
      triggerPicker: () => void
      openTableCreation: () => void
      triggerVideoUpload: () => void
    }
  }
}

export type MentionOptions = {
  HTMLAttributes: Record<string, any>
  triggerPicker: () => void
  openTableCreation: () => void
  triggerVideoUpload: () => void
  renderLabel: (props: {
    options: MentionOptions
    node: ProseMirrorNode
  }) => string
  suggestion: Omit<SuggestionOptions, 'editor'>
}

const SlashCommands = Node.create<MentionOptions>({
  name: 'slash-commands',
  addOptions() {
    return {
      pluginKey: 'slashCommands',
      HTMLAttributes: {
        class: 'slash-commands',
      },
      triggerPicker: () => {},
      openLibraryDialog: () => {},
      openTableCreation: () => {},
      triggerVideoUpload: () => {},
      renderLabel: ({ node }) => node.attrs.label,
      suggestion: {
        char: '/',
        startOfLine: false,
        command: ({ editor, range, props }) => {
          props.command({ editor, range, props })
        },
        items({ query }) {
          return commands.filter(
            (item) =>
              item.title.toLowerCase().includes(query.toLowerCase()) ||
              item.description.toLowerCase().includes(query.toLowerCase())
          )
        },
        render: () => {
          let reactRenderer: ReactRenderer
          let popup: any

          return {
            onStart: (props) => {
              reactRenderer = new ReactRenderer(CommandList, {
                props,
                editor: props.editor,
              })

              if (!props.clientRect) return

              // @ts-ignore
              popup = tippy('body', {
                getReferenceClientRect: props.clientRect,
                appendTo: () => document.body,
                content: reactRenderer.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
                popperOptions: { strategy: 'fixed' },
              })
            },

            onUpdate(props) {
              reactRenderer.updateProps(props)

              if (!props.clientRect) {
                return
              }

              popup[0].setProps({
                getReferenceClientRect: props.clientRect,
              })
            },

            onKeyDown(props) {
              if (props.event.key === 'Escape') {
                popup[0].hide()

                return true
              }

              // @ts-ignore
              return reactRenderer?.ref?.onKeyDown(props)
            },

            onExit() {
              if (popup && popup[0]) {
                popup[0].destroy()
                reactRenderer.destroy()
              }
            },
          }
        },
      },
    }
  },

  addCommands() {
    return {
      triggerPicker: () => () => {
        return this.options.triggerPicker()
      },
      openTableCreation: () => () => {
        return this.options.openTableCreation()
      },
      triggerVideoUpload: () => () => {
        return this.options.triggerVideoUpload()
      },
    }
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})

export default SlashCommands
