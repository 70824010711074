import React, { useContext } from 'react'

import HNContext from '@/context/HNContext'
import { getUserRole } from '@/lib/helpers/modules/userHelper'

interface IPropTypes {
  shouldShow: boolean
  children: any
  accessableRoles?: ('admin' | 'csm' | 'member' | 'user' | 'unauthorizedUser')[]
}

export default function AuthenticatedView({
  shouldShow,
  children,
  accessableRoles,
}: IPropTypes) {
  const { userProfile } = useContext(HNContext)
  if (
    accessableRoles?.length &&
    shouldShow &&
    accessableRoles.includes(getUserRole(userProfile))
  )
    return children
  if (shouldShow) return children
  return <></>
}
