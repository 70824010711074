import memoize from 'memoize-one'

import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import type { IGroupedItem, IKeyValueMap } from '@/types/common'

export const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined
}

const clone = (dataType: any, data: any) => Object.assign(dataType, data)
export const groupBy = (
  arr: Array<any>,
  key: string
): { [key: string]: object[] } =>
  arr.reduce((_previousValue: any, a: any) => {
    const currentKey = a[key]
    const previousValue = { ..._previousValue }
    previousValue[currentKey] = previousValue[currentKey] || []

    previousValue[currentKey].push(a)
    return previousValue
  }, Object.create(null))

export const groupToArray = (
  arr: Array<any>,
  groupKey: string
): IGroupedItem[] => {
  const groupMap = groupBy(arr, groupKey)
  const keys = Object.keys(groupMap)
  if (!keys.length) return []

  return keys.map((key: string) => ({
    label: key,
    items: groupMap[key] || [],
  }))
}

export const objectHasProperty = (source: any, prop: string) =>
  Object.prototype.hasOwnProperty.call(source, prop)

// get object property with dot notation
export const getPropWithDotKey = (obj: any, prop: string) => {
  const parts = prop.split('.')
  let current = obj
  let i
  for (i = 0; i < parts.length; i += 1) {
    const part = parts[i]
    if (!part) return undefined
    if (objectHasProperty(current, part)) {
      current = current[part]
    } else {
      return undefined
    }
  }
  return current
}

export const arrayToObject = (
  arr: any[],
  keyIndex: string,
  valueIndex: string
): IKeyValueMap => {
  const obj: IKeyValueMap = {}
  for (let i = 0; i < arr.length; i += 1) {
    const item = arr[i]
    const key: string = item[keyIndex]
    obj[key] = item[valueIndex]
  }
  return obj
}

export const getBoolean = (value: any): boolean => {
  return Boolean(value)
}

export const sortArrayOfObjects = (array: any[], key: string) => {
  return [...array].sort((a, b) => {
    const x = a[key]
    const y = b[key]
    if (x > y) return -1
    if (x < y) return 1
    return 0
  })
}

export const sortArrayOfObjectsMultiple = (
  array: any[],
  checkers: string[]
) => {
  return array.sort((a, b) => {
    return checkers.reduce((result, checker) => {
      if (result !== 0) return result
      const x = a[checker]
      const y = b[checker]
      if (x > y) return -1
      if (x < y) return 1
      return 0
    }, 0)
  })
}

export const getUniqueKeys = (arr: any) => [
  // @ts-ignore
  ...new Set(arr.map((item) => item.group)),
]

export const getUniqueValues = (arr: any) => [
  // @ts-ignore
  ...new Set(arr),
]

export const getIndexInArray = (value: any, key: string, array: any) => {
  for (let i = 0; i < array.length; i += 1) {
    const element = array[i]
    if (element[key] === value) {
      return i
    }
  }
  return -1
}

export const getItemIndexinObjectArray = (
  obj: IKeyValueMap,
  key: string,
  array: any
) => {
  return array.findIndex((item: IKeyValueMap) => item[key] === obj[key])
}
export const removeKeyFromObject = (k: string[] = [], o: any = {}) => {
  const clonedObject: IKeyValueMap = {}
  Object.keys(o).forEach((key) => {
    if (!k.includes(key) && !isNullOrUndefined(o[key])) {
      clonedObject[key] = o[key]
    }
  })
  return clonedObject
}

export const minusExcept = (set1: IKeyValueMap, set2: IKeyValueMap) => {
  const set1Clone = { ...set1 }
  const set2Clone = { ...set2 }
  const finalSet: IKeyValueMap = {}
  Object.keys(set1Clone).forEach((key) => {
    if (!objectHasProperty(set2Clone, key) && set1Clone[key]) {
      finalSet[key] = set1Clone[key]
    }
  })
  return { ...finalSet }
}

export const isSameObj = (
  previousObj: IKeyValueMap,
  currentObj: IKeyValueMap
) => {
  const previousObjectString = JSON.stringify(previousObj)
  const currentObjectString = JSON.stringify(currentObj)
  return previousObjectString === currentObjectString
}

export const removeCommon = (first: string[], second: string[]) => {
  const spreaded = [...first, ...second]
  return spreaded.filter((el) => {
    return !(first.includes(el) && second.includes(el))
  })
}

export const isEqualObj = (previousObj: any, currentObj: any) => {
  const comparableKeys = Object.keys(currentObj)
  const newPreviousObject = {}
  comparableKeys.map((key) => {
    if (objectHasProperty(previousObj, key)) {
      // @ts-ignore
      newPreviousObject[key] = previousObj[key]
    }
    return true
  })
  const previousObjectString = JSON.stringify(newPreviousObject)
  const currentObjectString = JSON.stringify(currentObj)
  return previousObjectString === currentObjectString
}

export const getSelectedValueByKey = (
  arr: ISelectOption[],
  value: ISelectOptionGroup,
  key = 'value'
) => {
  if (Array.isArray(value)) {
    return arr.filter((item) => value.includes(item[key]))
  }

  return arr.find(
    (item) => (item[key]?.toString() || '') === (value?.value?.toString() || '')
  )
}

export const setSelectedValue = (
  arr: ISelectOption[],
  value: string | number | boolean
) => {
  return (
    arr.filter((e) => {
      return (e.value?.toString() || '') === (value?.toString() || '')
    }) || []
  )
}

export const setSelectedValueArray = (
  arr: ISelectOption[],
  value: string[]
) => {
  return (
    arr.filter((e: ISelectOption) => {
      return value.includes(e.value?.toString() || '')
    }) || []
  )
}

export const convertObjForSelect = (
  arr: any[],
  labelKey: string,
  valueKey: string,
  prefixKey?: string
) =>
  arr.map((a) => ({
    label: prefixKey ? `${a[prefixKey]}  ${a[labelKey]}` : a[labelKey],
    value: a[valueKey],
    ...a,
  }))

export const cloneArray = (source: any[]) => clone([], source)

export const jsonToFormData = (json: any) => {
  const formData = new FormData()
  Object.keys(json).forEach((key) => {
    if (json[key] !== null && json[key] !== undefined) {
      formData.append(key, json[key])
    }
  })
  return formData
}

export const checkObjectIsEmpty = (obj: any) =>
  Boolean(obj && Object?.keys(obj)?.length)
export const removeDuplicates = (myArray: any[]) =>
  myArray.filter((v, i, a) => a.indexOf(v) === i)

export const createHashFromString = (url: string) => {
  let hash = 0
  for (let i = 0; i < url.length; i += 1) {
    hash = hash * 32 - hash + url.charCodeAt(i)
  }
  return hash
}

export const customSort = (arr: any[], sortKey: string, sortList: any[]) => {
  return arr.sort((a, b) => {
    return sortList.indexOf(a[sortKey]) - sortList.indexOf(b[sortKey])
  })
}

export const orderArrayOfObjects = (array: Array<any>, key: string) => {
  return array?.sort((a: any, b: any) => {
    const x = a[key]
    const y = b[key]
    if (x > y) return -1
    if (x < y) return 1
    return 0
  })
}

export const convertStringArrayToSelectOptions = (arr: string[]) => {
  return arr.map((item) => ({
    label: item,
    value: item,
  }))
}

export const removeNullOrUndefinedKeys = (obj: any) => {
  const newObj = { ...obj }
  Object.keys(newObj).forEach((key) => {
    if (
      newObj[key] === null ||
      newObj[key] === undefined ||
      newObj[key] === '' ||
      newObj[key] === 'null' ||
      newObj[key] === 'undefined'
    ) {
      delete newObj[key]
    }
  })
  return newObj
}

export const checkObjectHasKeys = (obj: any, keyToBeCheck: string[]) => {
  return Object.keys(obj).some((key) => keyToBeCheck.includes(key))
}

export const sortArrayOfObjectAlphabatic = <T>(arr: T[], key: string): T[] => {
  return arr.sort((a: any, b: any) => {
    if (a[key] < b[key]) return -1
    if (a[key] > b[key]) return 1
    return 0
  })
}

export const createItemData = memoize((data) => data)

export const addRootIndex = (data?: any[]) => {
  if (!data) return undefined
  return data.map((item, index) => ({ ...item, rootIndex: index }))
}

export const makeArrayIfNotArray = (data: any) => {
  if (!data) return undefined
  if (Array.isArray(data)) return data
  return [data]
}
export const isSameArray = (arr1: any[], arr2: any[]) => {
  if (arr1.length !== arr2.length) return false
  return arr1.every((value, index) => value === arr2[index])
}

export const isSameObjectFullCheck = (obj1: any, obj2: any) => {
  if (obj1 === obj2) return true
  if (obj1 == null || obj2 == null) return false
  if (Object.keys(obj1).length !== Object.keys(obj2).length) return false
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj1) {
    if (obj1[key] !== obj2[key]) return false
  }
  return true
}

export const findAndUpdateKeysInObject = (
  obj: any,
  keys: string[],
  newValues: any
) => {
  const newObj = { ...obj }
  keys.forEach((key, index) => {
    const keyArray = key.split('.')
    if (keyArray.length === 1) {
      newObj[key] = newValues[index]
    } else {
      // @ts-ignore
      newObj[keyArray[0]][keyArray[1]] = newValues[index]
    }
  })
  return newObj
}
export const convertSnakeCaseToCamelCase = (value: string) => {
  if (!value?.length) return ''
  return value.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

export const sortArrayOfObjectsDesc = (array: any[], key: string) => {
  return array.sort((a, b) => (a[key] > b[key] ? 1 : -1))
}

export const sortArrayOfObjectsAsc = (array: any[], key: string) => {
  return array.sort((a, b) => (a[key] < b[key] ? 1 : -1))
}

export const removeDuplicatesInArray = (arr: any[], key: string) => {
  const uniqueArray = arr.map((e) => e[key])
  return arr.filter((item, index) => uniqueArray.indexOf(item[key]) === index)
}

// Remove null or undefined or empty array from object
export const removeNullUndefinedEmptyArray = (obj: any) => {
  const newObj = { ...obj }
  Object.keys(newObj).forEach((key) => {
    if (
      newObj[key] === null ||
      newObj[key] === undefined ||
      newObj[key] === '' ||
      newObj[key] === 'null' ||
      newObj[key] === 'undefined' ||
      (Array.isArray(newObj[key]) && newObj[key].length === 0)
    ) {
      delete newObj[key]
    }
  })
  return newObj
}

export const convertObjectsOfObjectsToArrayOfObjects = (data: any) => {
  const convertedData: any[] = []
  Object.keys(data).map((key) => {
    const singleItem = { ...data[key], slug: key }
    return convertedData.push(singleItem)
  })
  return convertedData
}

export const sortObjectWithoutEmoji = (list: any[], sortKey: string) => {
  const emojiRegex = /[^a-zA-Z0-9]/g
  return list.sort((a, b) => {
    if (!a[sortKey] || !b[sortKey]) return 0
    const aName = a[sortKey].replace(emojiRegex, '').toLowerCase()
    const bName = b[sortKey].replace(emojiRegex, '').toLowerCase()
    if (aName < bName) return -1
    if (aName > bName) return 1
    return 0
  })
}

export const humanizeByte = (_bytes: number) => {
  let bytes = _bytes
  const thresh = 1024
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }
  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  do {
    bytes /= thresh
    u += 1
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return `${bytes.toFixed()} ${units[u]}`
}

export const addCommasToNumber = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const checkIsFloatValue = (value: number) => {
  if (
    typeof value === 'number' &&
    !Number.isNaN(value) &&
    !Number.isInteger(value)
  ) {
    return true
  }
  return false
}

export const deleteKeysFromObject = (obj: any, keys: string[]) => {
  const newObj = { ...obj }
  keys.forEach((key) => {
    delete newObj[key]
  })
  return newObj
}

export const compareArraysAndRemoveDuplicates = (
  arr1: any[],
  arr2: any[],
  key: string
) => {
  const ids = arr1.map((data) => data[key])
  return arr2.filter((data) => !ids.includes(data[key]))
}

export const checkObjectOfArrayHasKeys = (obj: any, keyToBeCheck: string[]) => {
  return Object.keys(obj).some((key) => {
    return obj[key].some((item: any) => keyToBeCheck.includes(item))
  })
}

export const checkObjectOfArrayHasValuesMoreThanOne = (obj: any) => {
  let count = 0
  Object.keys(obj).map((key) => {
    if (obj[key]?.length) count += 1
    return true
  })
  return count
}

export const groupAnArrayByKey = (
  array: any[],
  key: string,
  suffixKey?: string
) => {
  return array.reduce((acc, obj) => {
    const keyValue = suffixKey
      ? `${obj[key]?.toString() || ''}${suffixKey}`
      : `${obj[key]?.toString() || ''}`
    if (!acc[keyValue]) {
      acc[keyValue] = []
    }
    acc[keyValue].push(obj)
    return acc
  }, {})
}

export const arrayOfStringsHasSameValues = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  arr1.sort()
  arr2.sort()

  return JSON.stringify(arr1) === JSON.stringify(arr2)
}

export const replaceKeyInObject = (
  obj: any,
  oldKey: string,
  newKey: string
) => {
  const newObj = { ...obj }
  if (!Object.prototype.hasOwnProperty.call(newObj, oldKey)) return newObj
  newObj[newKey] = newObj[oldKey]
  delete newObj[oldKey]
  return newObj
}

export const removeKeysFromUrlString = (urlString: string, keys: string[]) => {
  const url = new URL(urlString)
  keys.forEach((key) => {
    url.searchParams.delete(key)
  })
  return url.toString()
}

export type TreeNode<T, Children extends TreeNode<T, Children>[] = []> = T & {
  children: Children
}
export const createTree = <
  T extends { parent_id?: string | null; children?: T[]; id: string }
>(
  items: T[],
  parentId: string | null = null
): TreeNode<T>[] => {
  const result: TreeNode<T>[] = []
  const itemsByParentId = items.filter(
    (item) => item.parent_id?.toString() === parentId?.toString()
  )

  // eslint-disable-next-line no-restricted-syntax
  for (const item of itemsByParentId) {
    const children = createTree(items, item.id)
    if (children.length) {
      item.children = children
    }
    result.push(item as TreeNode<T>)
  }
  return result
}

export const stringfyAndParse = (data: any) => {
  return JSON.parse(JSON.stringify(data))
}
export const isNumber = (value: any) => {
  return !Number.isNaN(value) && typeof value === 'number'
}

export const fileListToFileArray = (fileList: FileList): File[] => {
  const files = Array.from(fileList).filter((f) => f.type !== '')
  return files
}

export const doesFilesContainsFolder = (fileList: FileList) => {
  return Array.from(fileList).some((file) => file.type === '')
}

export function arrayMove<T>(array: T[], from: number, to: number): T[] {
  const newArray = array.slice()
  newArray.splice(
    to < 0 ? newArray.length + to : to,
    0,
    // @ts-ignore
    newArray.splice(from, 1)[0]
  )

  return newArray
}
