import { ChatCircle, UserPlus } from '@phosphor-icons/react'
import { Star } from '@phosphor-icons/react/dist/ssr'
import { NorthStarIcon } from '@primer/octicons-react'
import clsx from 'clsx'
import Link from 'next/link'
import router, { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import AsideCreditInfo from '@/components/admin/shared/asidenav/AsideCreditInfo'
import AsideHeader from '@/components/admin/shared/asidenav/AsideHeader'
import AsideHelpPopover from '@/components/admin/shared/asidenav/AsideHelpPopover'
import AsideOnboard from '@/components/admin/shared/asidenav/AsideOnboard'
import AsideWhatsNew from '@/components/admin/shared/asidenav/AsideWhatsNew'
import AsideNewDropdown from '@/components/admin/shared/AsideNewDropdown'
import AuthenticatedView from '@/components/auth/shared/AuthenticatedView'
import Avatar from '@/components/shared/ui/Avatar'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Sidebar from '@/components/shared/ui/Sidebar/Sidebar'
import Typography from '@/components/shared/ui/Typography'
import { APP_PATHS } from '@/config/appPaths'
import { allApps } from '@/config/sidebar/adminSidebarNavigations'
import { useHNAsideContext } from '@/context/HNAsideContext'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { parsePlanState } from '@/lib/helpers/billingHelpers'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import { getUserRole } from '@/lib/helpers/modules/userHelper'
import { adminSideTeamPath, billingPath } from '@/lib/helpers/pathHelpers'
import { getChats } from '@/models/Chat'
import {
  getSavedFilters,
  getSavedFiltersFromLocalDB,
} from '@/models/SavedFilter'
import { listUserSegments } from '@/models/UserSegment'
import type {
  IOrganizationCustomization,
  IOrganizationData,
  IOrganizationPlan,
  IOrganizationSetting,
} from '@/types/organization'
import type { ISavedFilter } from '@/types/savedFilters'
import type { INavigationItem } from '@/types/sidebar'
import type { IUserProfile } from '@/types/user'

const NON_BOARD_PATHS = [
  '/admin/people',
  '/admin/r',
  '/admin/changelog',
  '/admin/kb',
]

export default function AdminSidebar() {
  const { pathname, asPath, query } = useRouter()
  const {
    userProfile,
    organizationPlan,
    organization,
    unpublishedChangelogCount = 0,
    publishedChangelogCount = 0,
    scheduledChangelogCount = 0,
    pendingCommentsCount = 0,
    pendingPostsCount = 0,
    organizationSetting,
    assignedArticlesCount = 0,
    publishedArticlesCount = 0,
    unpublishedArticlesCount = 0,
    organizationCustomization,
    buckets = [],
    is_kal_enabled,
    privateBucketsCount,
    customers_count_info,
    segments = [],
    recentChats = [],
    updateContext,
  } = useContext(HNContext)
  const { open } = useHNAsideContext()
  const planInfo = parsePlanState(organizationPlan)
  const [savedFilters, setSavedFilters] = useState<ISavedFilter[]>([])
  const initialFetch = useRef({
    savedFilters: false,
    userSegments: false,
    chats: false,
  })
  const t = useTranslations()
  const isActiveSavedFilter = (item: ISavedFilter) => {
    return item.id?.toString() === router.query.filterId
  }

  const isActiveApp = (app: typeof allApps[0]) => {
    if (app.key === 'boards') {
      return !!app.items
        ?.map((item) => item.anotherHref)
        .flat()
        .includes(pathname)
    }

    return (
      !!pathname.startsWith(app.href) || !!app.anotherHrefs?.includes(pathname)
    )
  }

  const isActiveNav = (item: INavigationItem) => {
    const currentBucket = buckets.find(
      (bucket) => bucket.name === query.boardSlug
    )
    if (item.key === 'privateBoardsModule') {
      if (currentBucket)
        return currentBucket.private && !currentBucket?.archived
    } else if (item.key === 'boardModule') {
      if (currentBucket)
        return !currentBucket.private && !currentBucket?.archived
    } else if (item.key === 'archivedBoardModule') {
      return !!currentBucket?.archived
    }
    return (
      pathname === item.link ||
      item.anotherHref?.includes(pathname) ||
      item.link === asPath ||
      false
    )
  }

  const getTeamInviteQuery = () => {
    if (organizationPlan?.trial_expired) {
      return null
    }
    return { invite: true }
  }

  const counts = useMemo(
    () => ({
      unpublishedChangelogCount,
      publishedChangelogCount,
      pendingCommentsCount,
      pendingPostsCount,
      scheduledChangelogCount,
      allChangelogCount:
        unpublishedChangelogCount +
        publishedChangelogCount +
        scheduledChangelogCount,
      allBoardsCount: buckets?.length || 0,
      publicBoardsCount: buckets?.filter((b) => !b.private).length || 0,
      privateBoardsCount: buckets?.filter((b) => b.private).length || 0,
      assignedArticlesCount,
      publishedArticlesCount,
      unpublishedArticlesCount,
      starredCustomersCount: customers_count_info?.starred || 0,
      allCustomersCount: customers_count_info?.overall || 0,
    }),
    [
      unpublishedChangelogCount,
      publishedChangelogCount,
      scheduledChangelogCount,
      assignedArticlesCount,
      publishedArticlesCount,
      unpublishedArticlesCount,
      customers_count_info,
    ]
  )

  const apps = useMemo(() => {
    return allApps
      .filter((app) =>
        app.availableRoles?.includes(getUserRole(userProfile) as any)
      )
      .map((app) => {
        return {
          ...app,
          name: t(app.labelKey),
          isActive: isActiveApp(app),
          useSideLink:
            typeof app.useSideLink === 'function'
              ? app.useSideLink({
                  org: organization as IOrganizationData,
                })
              : app.useSideLink,
        }
      })
  }, [allApps, pathname])

  const activeApp = useMemo(() => apps.find((app) => app.isActive), [apps])

  const { items, groups } = useMemo(() => {
    if (!activeApp) return { items: [], groups: {} }

    if (!pathname) return { items: [], groups: {} }

    const favFilters: INavigationItem[] =
      activeApp.key === 'boards'
        ? savedFilters
            .filter((filter) => filter.favorite)
            .map((filter) => ({
              ...filter,
              isActive: isActiveSavedFilter(filter),
              wrapper: Link,
              labelTransKey: filter.name,
              icon: <Star weight='fill' size={16} />,
              key: filter.id,
              link: `/admin/filters/${filter.id}`,
              isExact: true,
              group: 'saved_filters',
              availableRoles: ['admin', 'member', 'csm'],
            }))
        : []

    const aiChatItems: INavigationItem[] =
      activeApp.key === 'chat'
        ? recentChats
            .map((chat) => ({
              key: chat.id,
              labelTransKey: chat.preview_text,
              icon: (
                <ChatCircle
                  weight='fill'
                  size={16}
                  className='min-h-[1rem] min-w-[1rem]'
                />
              ),
              isExact: true,
              group: 'recent_chats',
              link: `/admin/ai-chat/${chat.id}`,
              availableRoles: ['admin', 'member', 'csm'],
            }))
            .slice(0, 5)
        : []

    const userSegmentsItems: INavigationItem[] =
      activeApp.key === 'people'
        ? (segments || [])
            .filter((segment) => segment.favorite)
            .map((segment) => ({
              key: `${segment.id}_segment`,
              labelTransKey: segment.name,
              icon: <Star weight='fill' size={16} />,
              isExact: true,
              label: segment.name,
              isActive:
                pathname === `/admin/people/user-segments/${segment.id}`,
              wrapper: Link,
              group: 'user_segments',
              link: `/admin/people/user-segments/${segment.id}`,
              availableRoles: ['admin'],
            }))
        : []

    return {
      items: [
        ...(activeApp.items || []),
        ...favFilters,
        ...userSegmentsItems,
        ...aiChatItems,
      ],
      groups: activeApp.groups || {},
    }
  }, [pathname, savedFilters, segments, recentChats, activeApp])

  const filteredItems = useMemo(() => {
    return (items as INavigationItem[])
      .filter((item: INavigationItem) =>
        item.availableRoles.includes(getUserRole(userProfile) as any)
      )
      .filter((item: INavigationItem) => {
        if (item.shouldShow) {
          return item.shouldShow({
            organizationSetting,
            organizationPlan,
            userProfile,
            organization,
            isKalEnabled: !!is_kal_enabled,
            organizationCustomization,
            privateBucketsCount,
          } as {
            organizationSetting: IOrganizationSetting
            userProfile: IUserProfile
            organizationPlan: IOrganizationPlan
            organization: IOrganizationData
            isKalEnabled: boolean
            organizationCustomization: IOrganizationCustomization
            privateBucketsCount: number
          })
        }
        return true
      })
      .map((item) => ({
        ...item,
        label: t(item.labelTransKey),
        isActive: isActiveNav(item),
        wrapper: Link,
        count: item.countKey
          ? counts[item.countKey as keyof typeof counts]
          : item.countKey,
      }))
  }, [userProfile, pathname, asPath, counts, items])

  const handleFilterUpdate = (eventData: any) => {
    const { actionType, entity, data } = eventData
    if (entity !== 'SAVED_FILTER') return
    const incomingSavedFilter = data
    if (actionType === EVENT_ACTION_TYPES.ADD) {
      setSavedFilters((prev) => [incomingSavedFilter, ...prev])
    }
    if (actionType === EVENT_ACTION_TYPES.UPDATE) {
      setSavedFilters((prev) =>
        prev.map((savedFilter) => {
          if (savedFilter.id === incomingSavedFilter.id) {
            return incomingSavedFilter
          }
          return savedFilter
        })
      )
    }
    if (actionType === EVENT_ACTION_TYPES.DELETE) {
      setSavedFilters((prev) =>
        prev.filter((savedFilter) => savedFilter.id !== incomingSavedFilter.id)
      )
    }
  }

  const fetchSavedFilters = () => {
    if (NON_BOARD_PATHS.some((path) => pathname.startsWith(path))) return
    if (initialFetch.current.savedFilters) return
    getSavedFiltersFromLocalDB()
      .then((savedFiltersFromLocal) => {
        if (savedFiltersFromLocal.length) {
          setSavedFilters(savedFiltersFromLocal)
        }
      })
      .then(getSavedFilters)

      .then((response) => {
        setSavedFilters(response.filter((fil: ISavedFilter) => fil.favorite))
        initialFetch.current.savedFilters = true
      })
      .catch(() => {})
  }

  const fetchUserSegments = () => {
    if (initialFetch.current.userSegments) return Promise.resolve([])
    if (
      pathname.startsWith('/admin/people') &&
      !segments?.length &&
      featureIsEnabled(
        'user_segmentations',
        organizationPlan as IOrganizationPlan
      )
    ) {
      return listUserSegments()
        .then((seg) => {
          updateContext?.({ segments: seg })
          initialFetch.current.userSegments = true
        })
        .catch(() => {})
    }
    return Promise.resolve([])
  }

  const fetchChats = () => {
    const isAIChatEnable = featureIsEnabled(
      'ai_chat',
      organizationPlan as IOrganizationPlan
    )
    if (initialFetch.current.chats) return Promise.resolve([])
    if (
      pathname.startsWith('/admin/ai-chat') &&
      !recentChats.length &&
      isAIChatEnable
    ) {
      return getChats()
        .then((chats) => {
          updateContext?.({ recentChats: chats })
          initialFetch.current.chats = true
        })
        .catch(() => {})
    }
    return Promise.resolve([])
  }

  useEffect(() => {
    EventEmitter.subscribe('ENTITY_UPDATE', handleFilterUpdate)
    return () => {
      EventEmitter.unsubscribe('ENTITY_UPDATE', handleFilterUpdate)
    }
  }, [])

  useEffect(() => {
    fetchSavedFilters()
    fetchUserSegments()
    fetchChats()
  }, [pathname])

  return (
    <Sidebar
      appHeader={
        <div className='my-2'>
          <Link href={APP_PATHS.DASHBOARD_HOME}>
            <Avatar
              url={organization?.avatar.thumb_url}
              name={organization?.name}
              rounded
              size='xs'
            />
          </Link>
        </div>
      }
      actions={null}
      sidebarItems={filteredItems}
      sidebarGroups={groups}
      apps={apps.filter((app) => app.position !== 'bottom')}
      footerApps={apps.filter((app) => app.position === 'bottom')}
      open={open}
      header={
        <>
          <AsideHeader title={activeApp?.name} link={activeApp?.useSideLink} />
          {userProfile?.is_admin_of_organization &&
          !organization?.onboarding_completed &&
          !organizationPlan?.trial_expired ? (
            <AsideOnboard />
          ) : null}
          <AsideNewDropdown />
        </>
      }
      footer={
        <div className='px-2.5 py-1'>
          <AsideCreditInfo />
          <AsideWhatsNew />
          {userProfile?.is_admin_of_organization && (
            <UnstyledLink
              className='group my-0.5 flex w-full cursor-pointer items-center truncate rounded-lg p-1 text-left text-[13px] font-medium text-gray12 transition-all hover:bg-gray3 hover:text-gray12 hover:no-underline focus:z-50   dark:hover:bg-gray-dark-hover   '
              // @ts-ignore
              href={{
                pathname: organizationPlan?.trial_expired
                  ? billingPath
                  : adminSideTeamPath(),
                query: getTeamInviteQuery(),
              }}
            >
              <div className='flex w-full items-center px-2'>
                <UserPlus weight='bold' className='mr-2 h-4 w-4 text-gray11' />
                <Typography.Text
                  className='w-full text-left text-[13px] font-medium !text-gray12 '
                  type='default'
                >
                  {t('sidebar.labels.inviteTeam')}
                </Typography.Text>
              </div>
            </UnstyledLink>
          )}
          <AsideHelpPopover />
          <AuthenticatedView shouldShow={planInfo.inTrial}>
            <UnstyledLink
              className={clsx(
                'group my-0.5 flex w-full cursor-pointer items-center truncate rounded-lg px-3 py-1 text-left text-[13px] font-medium text-gray11 transition-all hover:bg-gray3 hover:text-gray12 hover:no-underline focus:z-50 focus:bg-gray4 focus:text-gray12 focus:outline-none dark:bg-gray-dark-hover  dark:hover:bg-gray-dark-hover ',
                organizationPlan?.trial_expired ? 'text-red10' : ''
              )}
              href={billingPath}
            >
              <div className='flex items-center space-x-1'>
                <NorthStarIcon />
                <span className={clsx('truncate !text-gray11 ')}>
                  {organizationPlan?.days_left}
                </span>
              </div>
            </UnstyledLink>
          </AuthenticatedView>
        </div>
      }
    />
  )
}
