import React from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import { setSelectedValue } from '@/lib/helpers/dataHelpers'
import type { IStatusSyncRule } from '@/types/integration/statusSync'

interface IAdminIntegrationSyncWhenDropdownProps {
  whenData: ISelectOption[]
  syncRule: IStatusSyncRule
  edit: boolean
  onChange: (key: string, value: string) => void
}

export default function AdminIntegrationSyncWhenDropdown({
  whenData,
  syncRule,
  edit,
  onChange,
}: IAdminIntegrationSyncWhenDropdownProps) {
  const t = useTranslations(`integrations`)

  const handleChange = (when: ISelectOptionGroup) => {
    if (Array.isArray(when)) {
      const value = when[0]?.value
      onChange('when', value)
    } else {
      const value = when?.value
      onChange('when', value)
    }
  }

  const whenOptions =
    whenData.length > 0
      ? whenData
      : [
          { label: t(`statusSync.quantityOptions.all`), value: 'all' },
          { label: t(`statusSync.quantityOptions.any`), value: 'any' },
        ]

  return (
    <Select
      size='xs'
      options={whenOptions}
      placeholder={t(`statusSync.whenOptions.placeholder`)}
      disabled={!edit}
      defaultValue={setSelectedValue(whenOptions, syncRule.when)}
      onChange={handleChange}
    />
  )
}
