import { observer } from 'mobx-react'
import React from 'react'

import AdminPostAssigneeFilter from '@/components/admin/posts/filters/AdminPostAssigneeFilter'
import UserAvatar from '@/components/shared/components/UserAvatar'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

const SinglePostAssigneeChange = observer(({ post }: IPropTypes) => {
  const t = useTranslations('post')
  const handleChange = (
    _key: string,
    value: ISelectOption,
    type: string,
    assigness: IUserProfile[]
  ) => {
    const postAssignee = value?.length ? value[0] : null

    const updatedPost = !value?.length
      ? { ...post, assignee: undefined }
      : {
          ...post,
          assignee: {
            ...assigness.find((a) => a.id === postAssignee),
            is_interactor: true,
          },
        }
    if (!value?.length) {
      toaster.success({
        message: t('messages.assignee.remove'),
      })
      return postStore
        .updateAssignee(
          post.slug,
          { assignee_id: post?.assignee?.id },
          type,
          updatedPost as IPost
        )
        .catch(toaster.error)
    }
    if (!postAssignee) return null
    toaster.success({
      message: t('messages.assignee.success'),
    })
    return postStore
      .updateAssignee(
        post.slug,
        { assignee_id: postAssignee },
        type,
        updatedPost as IPost
      )
      .catch(toaster.error)
  }

  if (post.can_assign) {
    return (
      <div
        className='grid grid-cols-5 items-center gap-4 px-4'
        data-testid='post_assignee_change_editable'
      >
        <Typography.Text className='col-span-2 font-medium'>
          {t('fields.assignee.label')}
        </Typography.Text>
        <div className='col-span-3'>
          <AdminPostAssigneeFilter
            filters={{
              assignee_id: post?.assignee?.id,
            }}
            multiple={false}
            onChange={(
              key: string,
              value: ISelectOption,
              selectedAssignees: IUserProfile[]
            ) =>
              handleChange(
                key,
                value,
                value?.length ? 'update' : 'delete',
                selectedAssignees
              )
            }
            placeholder={t('fields.assignee.placeholder')}
            behaviour='select'
            clearable
          />
        </div>
      </div>
    )
  }
  if (post.assignee) {
    return (
      <div
        className='grid grid-cols-5 items-center gap-4 px-4'
        data-testid='post_assignee_change_uneditable'
      >
        <Typography.Text className='col-span-2 font-medium'>
          {t('fields.assignee.label')}
        </Typography.Text>
        <span className='col-span-3 flex items-center space-x-1'>
          <UserAvatar user={post.assignee} size='xs' rounded showProfileCard />
          <Typography.Text
            data-testid='post_assignee_name'
            className='font-medium !text-gray12 transition dark:!text-gray-light'
          >
            {post.assignee.name}
          </Typography.Text>
        </span>
      </div>
    )
  }
  return <></>
})

export default SinglePostAssigneeChange
