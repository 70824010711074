type ConditonalWrapperProps = {
  children: React.ReactElement | React.ReactElement[]
  condition: boolean
  wrapper: (children: ConditonalWrapperProps['children']) => JSX.Element
  falseWrapper?: (children: ConditonalWrapperProps['children']) => JSX.Element
}
const ConditonalWrapper: React.FC<ConditonalWrapperProps> = ({
  condition,
  wrapper,
  children,
  falseWrapper = (child: ConditonalWrapperProps['children']) => <>{child}</>,
}) => (condition ? wrapper(children) : falseWrapper(children))

export default ConditonalWrapper
