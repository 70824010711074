import type { CookieSerializeOptions } from 'cookie'
import cookie from 'cookie'
import type { IncomingMessage } from 'http'
import type { NextApiRequest } from 'next'

import { checkLocalStorageSupport } from '@/lib/helpers/localStorageHelper'

import {
  getDomain,
  getHostFromRequest,
  getSubdomain,
} from './helpers/appHelpers'

export const getCookie = (
  req: NextApiRequest | IncomingMessage,
  name: string
) => {
  const cookieData = cookie.parse(req.headers.cookie || '')
  if (cookieData && cookieData[name]) {
    return cookieData[name]
  }
  return null
}

export const serializeCookie = (
  name: string,
  value: string,
  req: NextApiRequest | IncomingMessage,
  options: CookieSerializeOptions = {}
) => {
  let { origin } = req.headers
  const host = getHostFromRequest(req)
  origin = origin || `https://${host}`
  const domain = origin ? getDomain(origin) : null
  const { maindomain } = domain ? getSubdomain(domain) : { maindomain: null }
  return cookie.serialize(name, String(value), {
    httpOnly: true,
    maxAge: 60 * 60 * 24 * 7,
    sameSite: process.env.NODE_ENV === 'production' ? 'none' : 'lax',
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    domain: maindomain ? `.${maindomain}` : undefined,
    ...options,
  })
}

export const updateCookieFlag = (flag: string) => {
  if (checkLocalStorageSupport()) {
    return localStorage.setItem('hn_cookie_hidden', flag || 'true')
  }
  return true
}

export const getCookieFlag = () => {
  if (checkLocalStorageSupport()) {
    return localStorage.getItem('hn_cookie_hidden') || 'true'
  }
  return true
}

interface CookieAllFlag {
  analytics: string
  functionality: string
}
export const updateAllCookieFlag = (flags: CookieAllFlag) => {
  if (checkLocalStorageSupport()) {
    return localStorage.setItem('hn_cookie_flags', JSON.stringify(flags))
  }
  return true
}

export const getAllCookieFlag = (): Partial<CookieAllFlag> => {
  try {
    if (checkLocalStorageSupport()) {
      const flags = localStorage.getItem('hn_cookie_flags')
      return flags ? JSON.parse(flags) : null
    }
    return {}
  } catch {
    return {}
  }
}
