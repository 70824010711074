import { GearSix, Kanban, Signpost } from '@phosphor-icons/react'

import type { INavigationItem } from '@/types/sidebar'

export const roadmapNavigation: INavigationItem[] = [
  {
    key: 'defaultRoadmapModule',
    link: '/admin/r',
    labelTransKey: 'sidebar.items.roadmap',
    anotherHref: ['/admin/r', '/admin/r/[[...roadmapTab]]'],
    icon: <Signpost size={16} />,
    availableRoles: ['admin', 'member'],
    isExact: true,
  },
  {
    key: 'multipleRoadmapModule',
    link: '/admin/r/multiple',
    labelTransKey: 'sidebar.items.roadmap_multiple',
    anotherHref: ['/admin/r/multiple', '/admin/r/multiple/[[...roadmapTab]]'],
    icon: <Kanban size={16} />,
    availableRoles: ['admin', 'member'],
    // shouldShow: ({ organizationPlan }) =>
    // featureIsEnabled('multiple_roadmap', organizationPlan),
    isExact: true,
  },
  {
    key: 'roadmapModuleSettings',
    link: '/admin/r/settings',
    labelTransKey: 'sidebar.items.roadmap_settings',
    anotherHref: ['/admin/r/settings'],
    icon: <GearSix size={16} />,
    availableRoles: ['admin'],
    isExact: true,
  },
]

export const roadmapNavigationGroups = {}
