import { SearchIcon } from '@primer/octicons-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import RichTextEditor from '@/components/shared/components/editor/RichTextEditor'
import Button from '@/components/shared/ui/Button'
import EmptyState from '@/components/shared/ui/EmptyState'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import Spinner from '@/components/shared/ui/Loader'
import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import { stateKeyValueChange } from '@/lib/helpers/appHelpers'
import {
  convertObjForSelect,
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import {
  createLinearIssue,
  getLinearIssues,
  getLinearStates,
  getLinearTeamData,
  searchTeamProjects,
  searchTeamUsers,
} from '@/models/integration/Linear'
import type {
  ILinearIssue,
  ILinearIssuePayload,
  ILinearLabels,
  ILinearProject,
  ILinearPushConfig,
  ILinearStates,
} from '@/types/integration/linear'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  onLink: (data: ILinearIssue, action: string) => void
}

export default function CreateLinearIssue({ post, onLink }: IPropTypes) {
  const t = useTranslations(`post.integrations.linear`)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [linearData, setLinearData] = useState<ILinearPushConfig>()
  const [linearIssue, setLinearIssue] = useState<ILinearIssuePayload>({
    title: post.title,
    description: post.description_html,
    feature_request_id: post.id,
    team: null,
    user: null,
    labels: null,
    state: null,
    parent_id: null,
  })
  const [linearProjects, setLinearProjects] = useState<ISelectOption[]>([])
  const [linearAssignees, setLinearAssignees] = useState<ISelectOption[]>([])
  const [linearTeams, setLinearTeams] = useState<ISelectOption[]>([])
  const [linearStates, setLinearStates] = useState<ISelectOption[]>([])
  const [linearLabels, setLinearLabels] = useState<ISelectOption[]>([])
  const debounceRef = useRef<NodeJS.Timeout>()
  const [selectedLinearIssue, setSelectedLinearIssue] =
    useState<ISelectOption | null>(null)
  const [parentIssues, setParentIssues] = useState<ISelectOption[]>()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const validate = useMemo(() => {
    return !(linearIssue.title && linearIssue.title.length && linearIssue.team)
  }, [linearIssue])

  const getLinearSubLevelData = (
    labelsData: ILinearLabels[],
    value: string | any
  ) => {
    const convertedData = convertObjForSelect(
      labelsData?.filter((l) => l.team_id === value || l.team_id === null) ||
        [],
      'name',
      'id'
    )
    convertedData.map((data) => {
      if (data.children?.length) {
        const childrenData = convertObjForSelect(
          data.children || [],
          'name',
          'id'
        )
        childrenData.forEach((child) => {
          convertedData.push({
            ...child,
            label: `${child.label} (${data.label})`,
            groupId: data.id,
          })
        })
      }
      return data
    })
    return convertedData.filter((data) => !data.children?.length)
  }

  const removeAndUpdatedLabelSelectedFromGroup = (
    selectedValues: ISelectOption[]
  ) => {
    const currentSelectedValue = selectedValues[selectedValues.length - 1]
    if (currentSelectedValue?.groupId) {
      selectedValues.pop()
      const currentSelectedValueParentIndex = selectedValues.findIndex(
        (value) => value.groupId === currentSelectedValue.groupId
      )
      if (currentSelectedValueParentIndex > -1) {
        selectedValues.splice(currentSelectedValueParentIndex, 1)
      }
      selectedValues.push(currentSelectedValue)
    }
    return selectedValues
  }

  const handleChange = (
    key: keyof ILinearIssuePayload,
    value: string | any
  ) => {
    const newIssue = { ...linearIssue }

    if (key === 'team') {
      setLinearProjects(
        convertObjForSelect(
          linearData?.projects.filter((p) => p.team_ids.includes(value)) || [],
          `name`,
          `id`
        )
      )
      const users = linearData?.users.filter((user) => user.team_id === value)
      const states = linearData?.states?.filter((s) => s.team_id === value)
      setLinearAssignees(convertObjForSelect(users || [], 'name', 'id'))
      setLinearLabels(getLinearSubLevelData(linearData?.labels || [], value))
      setLinearStates(
        convertObjForSelect(
          linearData?.states?.filter((s) => s.team_id === value) || [],
          'name',
          'id'
        )
      )
      const linearProject = linearData?.projects.find(
        (p) => p.id === linearIssue.project
      )
      newIssue.team = value
      newIssue.project = linearProject?.team_ids.includes(value)
        ? linearIssue.project
        : null
      newIssue.user = users
        ?.map((user) => user.id)
        ?.includes(newIssue.user || '')
        ? newIssue.user
        : null
      newIssue.state = states
        ?.map((state) => state.id)
        ?.includes(newIssue.state || '')
        ? newIssue.state
        : null
      setLinearIssue(newIssue)
    }

    if (key === `project`) {
      const users = linearData?.users.filter(
        (user) => user.team_id === linearIssue.team
      )
      setLinearAssignees(convertObjForSelect(users as any[], 'name', 'id'))
      newIssue.project = value
      newIssue.user = users
        ?.map((user) => user.id)
        ?.includes(newIssue.user || '')
        ? newIssue.user
        : null
      setLinearIssue(newIssue)
    }
    if (key === 'labels') {
      const labels = removeAndUpdatedLabelSelectedFromGroup(value)
      setLinearIssue({
        ...newIssue,
        [key]: labels.map((data: ISelectOption) => data.id) as string[],
      })
    } else {
      newIssue[key] = value
      setLinearIssue(newIssue)
    }
  }

  const handleSelectChange = (
    data: ISelectOption[],
    key: keyof ILinearIssuePayload,
    value: ISelectOptionGroup
  ) => {
    const selectedValue = getSelectedValueByKey(data, value)
    if (key === 'labels') return handleChange(key, value)
    if (Array.isArray(selectedValue)) {
      return handleChange(key, selectedValue[0]?.value)
    }
    return handleChange(key, selectedValue?.value)
  }

  const handleSubmit = () => {
    setIsSubmitting(true)
    createLinearIssue(linearIssue)
      .then((data) => {
        toaster.success({ message: t(`linearIssueCreated`) })
        onLink(data as ILinearIssue, 'push')
      })
      .catch((err) => toaster.error({ message: err.message }))
      .then(() => setIsSubmitting(false))
  }

  const filterProjects = (query: string) => {
    if (query) {
      if (debounceRef.current) clearTimeout(debounceRef.current)
      debounceRef.current = setTimeout(() => {
        return searchTeamProjects({
          team_id: linearIssue.team as string,
          query,
        }).then((data) => {
          return convertObjForSelect(data, `name`, `id`)
        })
      }, 500)
    }
    return Promise.resolve(linearProjects)
  }

  const filterUsers = (query: string) => {
    if (query) {
      if (debounceRef.current) clearTimeout(debounceRef.current)
      debounceRef.current = setTimeout(() => {
        return searchTeamUsers({
          team_id: linearIssue.team as string,
          query,
        }).then((data) => {
          return convertObjForSelect(data, `name`, `id`)
        })
      }, 500)
    }
    return Promise.resolve(linearAssignees)
  }

  const updateLinearDataBasedOnDefaultTeam = (data: ILinearPushConfig) => {
    const convertedTeamsData = convertObjForSelect(data.teams, `name`, `id`)
    const defaultSelectedTeam = convertedTeamsData?.find(
      (team: ISelectOption) => team.id === data.default_team_id
    )
    if (defaultSelectedTeam)
      handleSelectChange(convertedTeamsData, 'team', defaultSelectedTeam)
    setLinearProjects(
      convertObjForSelect(
        data?.projects.filter((p: ILinearProject) =>
          p.team_ids.includes(data.default_team_id)
        ) || [],
        `name`,
        `id`
      )
    )
    setLinearAssignees(
      convertObjForSelect(
        data?.users.filter((user) => user.team_id === data.default_team_id) ||
          [],
        'name',
        'id'
      )
    )
    setLinearLabels(getLinearSubLevelData(data?.labels, data.default_team_id))
    setLinearStates(
      convertObjForSelect(
        data?.states?.filter((s) => s.team_id === data.default_team_id) || [],
        'name',
        'id'
      )
    )
  }

  const fetchLinearStates = () => {
    return getLinearStates()
      .then((data: ILinearStates[]) => {
        setLinearData(
          (_prevData) =>
            ({ ..._prevData, states: data || [] } as ILinearPushConfig)
        )
      })
      .catch((err) => setErrorMessage(err.message))
  }

  const fetchData = () => {
    setIsLoading(true)
    return getLinearTeamData()
      .then((data) => {
        fetchLinearStates()
        setErrorMessage(null)
        setLinearData(data)
        setLinearTeams(convertObjForSelect(data.teams, `name`, `id`))
        if (data.default_team_id && data.teams)
          updateLinearDataBasedOnDefaultTeam(data)
      })
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setIsLoading(false))
  }

  const handleSearch = (queryString: string) => {
    return getLinearIssues({
      query: queryString,
      feature_request_id: post.id,
      team_id: linearIssue.team as string,
    }).then((data) => {
      const convertedOptionsData = convertObjForSelect(
        data,
        `title`,
        `issue_id`,
        'number'
      )
      const index = convertedOptionsData?.findIndex(
        (issue) => issue.value === selectedLinearIssue?.value
      )
      const updatedArray = convertedOptionsData
      if (index === -1 && selectedLinearIssue) {
        updatedArray.unshift(selectedLinearIssue)
        setParentIssues(updatedArray)
      } else setParentIssues(updatedArray)
      return updatedArray
    })
  }

  const handleSelectLinearIssue = (issue: any) => {
    if (!issue) {
      setSelectedLinearIssue(null)
      return setLinearIssue((prevData) => ({ ...prevData, parent_id: null }))
    }
    setSelectedLinearIssue(issue)
    return setLinearIssue((prevData) => ({
      ...prevData,
      parent_id: issue.issue_id,
    }))
  }

  useEffect(() => {
    if (!linearData) {
      fetchData()
    }
  }, [])

  useEffect(() => {
    if (linearData?.states?.length) {
      updateLinearDataBasedOnDefaultTeam(linearData)
    }
  }, [linearData?.states])

  const renderLinearIssueSearch = () => {
    return (
      <div className='relative w-full'>
        <Label className='mb-1'>{t('form.parentIssue.title')}</Label>
        <p
          className='pb-2 text-gray10'
          dangerouslySetInnerHTML={{
            __html: t(`createSearchText`),
          }}
        />
        <Select
          placeholder={t(`form.parentIssue.searchPlaceholder`)}
          searchPlaceholder={t(`form.parentIssue.searchPlaceholder`)}
          loading={false}
          value={
            parentIssues
              ? setSelectedValue(parentIssues, linearIssue.parent_id || '')
              : null
          }
          loadOptions={handleSearch}
          searchable
          multiple={false}
          options={parentIssues}
          onChange={(value) => {
            handleSelectLinearIssue(value)
          }}
          clearable
          placeholderIcon={<SearchIcon />}
          minimumSearchCharacterLength={2}
          emptyMessage={t(`noIssues`)}
        />
      </div>
    )
  }

  if (isLoading) return <Spinner />

  if (errorMessage) return <EmptyState title={errorMessage} />

  if (!linearData) {
    return (
      <div className='flex h-full flex-col items-center justify-center'>
        <p className='text-center'>{t(`noLinearData`)}</p>
      </div>
    )
  }

  return (
    <div className='mt-3 space-y-4'>
      <div className='flex items-center justify-between space-x-8'>
        <div className='w-full space-y-1'>
          <Label>{t(`form.team.title`)}</Label>
          <Select
            placeholder={t(`form.team.placeholder`)}
            searchPlaceholder={t(`form.team.searchPlaceholder`)}
            onChange={(value) => handleSelectChange(linearTeams, 'team', value)}
            value={setSelectedValue(linearTeams, linearIssue.team || '')}
            options={linearTeams}
            searchable
            emptyMessage={t(`form.team.emptyMessage`)}
          />
        </div>
        <div className='w-full space-y-1'>
          <Label>{`${t(`form.project.title`)} (${t('common.optional', {
            ignorePrefix: true,
          })})`}</Label>
          <Select
            placeholder={t(`form.project.placeholder`)}
            onChange={(value) =>
              handleSelectChange(linearProjects, 'project', value)
            }
            value={setSelectedValue(linearProjects, linearIssue.project || '')}
            options={linearProjects}
            searchPlaceholder={t(`form.project.searchPlaceholder`)}
            loadOptions={filterProjects}
            searchable
            clearable
            disabled={!linearIssue.team}
            emptyMessage={t(`form.project.emptyMessage`)}
          />
        </div>
      </div>
      {renderLinearIssueSearch()}
      <div className='w-full'>
        <Input
          autoFocus
          label={t(`form.title.title`)}
          onChange={(event) =>
            handleChange('title', (event.target as HTMLInputElement).value)
          }
          type='text'
          placeholder={t(`form.title.placeholder`)}
          value={linearIssue.title}
        />
      </div>
      <div className='w-full space-y-1'>
        <Label>{t(`form.description.title`)}</Label>
        <RichTextEditor
          editable={true}
          defaultValue={linearIssue.description}
          showBubbleMenu={false}
          showFloatingMenu={false}
          editorMenu={false}
          onChange={(value) => {
            stateKeyValueChange('description', value, setLinearIssue)
          }}
        />
      </div>
      <div className='items-center sm:flex sm:space-x-8'>
        <div className='w-[50%] space-y-1'>
          <Label>{t(`form.assignee.title`)}</Label>
          <Select
            placeholder={t(`form.assignee.placeholder`)}
            onChange={(value) =>
              handleSelectChange(linearAssignees, 'user', value)
            }
            value={setSelectedValue(linearAssignees, linearIssue.user || '')}
            options={linearAssignees}
            loadOptions={filterUsers}
            searchPlaceholder={t(`form.assignee.searchPlaceholder`)}
            searchable
            clearable
            disabled={!linearIssue.team}
            emptyMessage={t(`form.assignee.emptyMessage`)}
          />
        </div>
        <div className='w-[50%] space-y-1'>
          <Label>{t(`form.labels.title`)}</Label>
          <Select
            placeholder={t(`form.labels.placeholder`)}
            searchPlaceholder={t(`form.labels.searchPlaceholder`)}
            onChange={(value) =>
              handleSelectChange(linearLabels, 'labels', value)
            }
            value={null}
            options={linearLabels}
            searchable
            multiple
            clearable
            selectAll={false}
            disabled={!linearIssue.team}
            emptyMessage={t(`form.labels.emptyMessage`)}
          />
        </div>
        <div className='w-[50%] space-y-1'>
          <Label>{t(`form.state.title`)}</Label>
          <Select
            placeholder={t(`form.state.placeholder`)}
            searchPlaceholder={t(`form.state.searchPlaceholder`)}
            onChange={(value) =>
              handleSelectChange(linearStates, 'state', value)
            }
            value={setSelectedValue(linearStates, linearIssue?.state || '')}
            options={linearStates}
            searchable
            clearable
            disabled={!linearIssue.team}
            emptyMessage={t(`form.state.emptyMessage`)}
          />
        </div>
      </div>
      <div className='flex w-full justify-end pt-3'>
        <Button
          loading={isSubmitting}
          disabled={validate || isSubmitting}
          onClick={handleSubmit}
        >
          {t(`pushToLinear`)}
        </Button>
      </div>
    </div>
  )
}
