import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { encodeURLPath } from '@/lib/helpers/stringHelpers'
import { getURLPath } from '@/lib/helpers/urlHelpers'

const NavLink = (props: any) => {
  const {
    children,
    activeClassName = '',
    className = '',
    href = '',
    isExact = false,
    ignoreParams = false,
    isActive = false,
    ...rest
  } = props
  const router = useRouter()
  let link

  const activeClass = `${className} ${activeClassName}`

  const getActiveClass = () => {
    const asPath = ignoreParams ? getURLPath(router.asPath) : router.asPath
    if (isActive) return activeClass
    if (isExact) {
      if (asPath === href) {
        return activeClass
      }
      if (asPath === encodeURLPath(href)) {
        return activeClass
      }

      return className
    }
    return asPath?.includes(href) ? activeClass : className
  }

  useEffect(() => {
    link = { ...router.query, ...href }
  }, [href])

  return (
    <Link href={link || href} prefetch={false} {...rest} legacyBehavior>
      <a {...rest} className={getActiveClass()}>
        {children}
      </a>
    </Link>
  )
}

NavLink.displayName = 'NavLink'
export default NavLink
