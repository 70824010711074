import { Crisp } from 'crisp-sdk-web'
import { useRouter } from 'next/router'
import { memo, useContext, useEffect, useMemo } from 'react'

import { PLAN_MAP } from '@/config/billingConstants'
import HNContext from '@/context/HNContext'
import {
  convertToUnixTime,
  parseJSONTimestamp,
} from '@/lib/helpers/dateHelpers'
import { getUserRole } from '@/lib/helpers/modules/userHelper'
import type { IOrganizationPlan } from '@/types/organization'

const ALLOWED_PLANS = [
  'free',
  'basic',
  'pro',
  'enterprise',
  'starter',
  'business',
]

export interface ICripsMessage {
  type: string
  from: string
  origin: string
  content: string
  fingerprint: number
  user: ICripsMessageUser
  timestamp: number
}

export interface ICripsMessageUser {
  nickname: string
  user_id: string
}

function SupportChat() {
  const { userProfile, organizationPlan, organization, country } =
    useContext(HNContext)
  const router = useRouter()

  const { userEmail, domain, plan } = useMemo(() => {
    if (!userProfile || !organization)
      return { userEmail: '', domain: '', plan: '' }

    return {
      userId: userProfile.id,
      userEmail: userProfile.email,
      domain: organization.name,
      plan: (organizationPlan as IOrganizationPlan).plan,
    }
  }, [userProfile, organization, organizationPlan])

  const isActiveTrial = organizationPlan?.slug === 'free'

  const joinedDate = useMemo(() => {
    if (!userProfile || !userProfile.joined_at) return null
    try {
      return convertToUnixTime(
        parseJSONTimestamp(userProfile.joined_at?.timestamp)
      )
    } catch {
      return null
    }
  }, [userProfile])

  const shouldShow =
    !router.pathname.includes('/embed') &&
    !router.pathname.includes('/postwidget') &&
    !router.pathname.includes('/submission_only') &&
    !router.pathname.includes('/changelogwidget') &&
    !router.pathname.includes('/submission_form_with_key') &&
    userProfile &&
    userProfile.is_csm_of_organization &&
    country !== 'IN' &&
    ALLOWED_PLANS.includes(organizationPlan?.slug as keyof IOrganizationPlan) &&
    process.env.NODE_ENV === 'production'

  const hideLauncher = useMemo(() => {
    return (
      router.pathname.includes('/admin') ||
      (organizationPlan?.slug === 'freeway' && isActiveTrial)
    )
  }, [])

  useEffect(() => {
    if (shouldShow) {
      try {
        // TODO: Move the key to the .env file
        Crisp.configure('1cefa8a0-87c1-431d-a976-3af1c201fbb3', {
          lockFullview: false,
        })
        if (userEmail) {
          Crisp.user.setEmail(userEmail)
        }
        if (userProfile.name) {
          Crisp.user.setNickname(userProfile.name)
        }
        if (userProfile) {
          Crisp.session.setData({
            domain,
            plan,
            joined_at: joinedDate,
            role: getUserRole(userProfile),
            organizationPlan: organizationPlan
              ? PLAN_MAP?.[organizationPlan?.slug]?.label
              : '',
            organizationId: organization?.id,
            HomePage: organization?.home_page,
            'Manage-User': userProfile
              ? `https://sa.skcript.com/dashboard/users/${userProfile?.id}`
              : undefined,
            'Manage-Organization': organization
              ? `https://sa.skcript.com/dashboard/organizations/${organization?.id}`
              : undefined,
          })
          if (organization) {
            Crisp.user.setCompany(organization?.name, {
              ...organization,
              url: organization.home_page,
            })
            Crisp.session.setSegments(
              [
                `plan:${organizationPlan?.slug}`,
                `role:${getUserRole(userProfile)}`,
              ],
              true
            )
          }
        }

        if (hideLauncher) {
          Crisp.chat.hide()
          // Add a new event listener for the Crisp event
          Crisp.message.onMessageReceived((message: ICripsMessage) => {
            if (
              message.type === 'text' &&
              message.from === 'operator' &&
              !Crisp.chat.isVisible()
            ) {
              Crisp.chat.show()
            }
          })
        }
      } catch (error) {
        console.error('Error initializing Crisp', error)
      }
    }
  }, [])

  return <></>
}

export default memo(SupportChat)
