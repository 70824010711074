import { FileAudio, FileDoc, FileImage, FileVideo } from '@phosphor-icons/react'

const IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg', 'webp', 'gif', 'bmp', 'svg']
const VIDEO_EXTENSIONS = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv', 'webm']
const AUDIO_EXTENSIONS = ['mp3', 'wav', 'wma', 'ogg', 'flac', 'aac']
const DOCUMENT_EXTENSIONS = [
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'txt',
]

export const getFileIcon = (fileExt: string) => {
  const ext = fileExt.toLowerCase()
  if (DOCUMENT_EXTENSIONS.includes(ext)) return FileDoc
  if (VIDEO_EXTENSIONS.includes(ext)) return FileVideo
  if (AUDIO_EXTENSIONS.includes(ext)) return FileAudio
  if (IMAGE_EXTENSIONS.includes(ext)) return FileImage
  return FileDoc
}
