import { CaretRight } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import { EventEmitter } from '@/lib/eventEmitter'
import { getIntegrationInfo } from '@/lib/helpers/modules/integrationHelper'
import type { IIntegrationPlatforms } from '@/types/integration'
import type { IPostAddonEvent } from '@/types/post/postAddon'

interface IPropTypes {
  onClick: () => void
  provider: IIntegrationPlatforms
  pushText: string
  linkText: string
  canCreate: boolean
  onChangeCanCreate: (value: boolean) => void
}
export default function PushToButton({
  onClick,
  provider,
  pushText,
  linkText,
  canCreate,
  onChangeCanCreate,
}: IPropTypes) {
  const integrationMapping = getIntegrationInfo(provider)
  const [canPush, setCanPush] = useState(canCreate)

  const handleResetPush = (data: IPostAddonEvent) => {
    if (data.provider === provider) {
      if (data.action === `push`) {
        setCanPush(false)
        onChangeCanCreate(false)
      } else if (data.action === `unlink`) {
        if (data.addon.action === `push`) {
          setCanPush(true)
          onChangeCanCreate(true)
        }
      }
    }
  }
  useEffect(() => {
    EventEmitter.subscribe('RESET_ADDON_PROVIDER_PUSH', handleResetPush)
    return () => {
      EventEmitter.unsubscribe('RESET_ADDON_PROVIDER_PUSH', handleResetPush)
    }
  }, [])

  useEffect(() => setCanPush(canCreate), [canCreate])

  return (
    <Button
      icon={
        <img
          src={integrationMapping?.logo || ``}
          className={clsx(
            'flex shrink-0 transition',
            provider === 'github' ? 'dark:invert' : ''
          )}
          alt='Push to an integration'
          height='12'
          width='12'
        />
      }
      iconRight={
        <CaretRight className='ease-bounce translate-x-0 opacity-100 transition duration-200 sm:group-hover:translate-x-1 sm:group-hover:opacity-100' />
      }
      onClick={onClick}
      variant='outline'
      size='xs'
      className={'group'}
    >
      {canPush ? pushText : linkText}
    </Button>
  )
}
