import clsx from 'clsx'
import { useMemo } from 'react'

import type { ISidebarNavItem } from './types'

interface IPropTypes {
  item: ISidebarNavItem
}

const SidebarItem = ({ item }: IPropTypes) => {
  const title = useMemo(() => {
    return item.label
  }, [item.label])

  const Component = item.wrapper ? item.wrapper : 'a'

  return (
    <Component
      className={clsx(
        'group flex w-full cursor-pointer items-center truncate rounded-lg px-2 py-1.5 text-left text-[13px] transition-colors hover:bg-elementHighlight hover:no-underline focus:z-50',
        {
          'text-foreground bg-elementHighlight': item.isActive,
        }
      )}
      href={`${item.link}`}
    >
      <div className='flex w-full items-center justify-between overflow-hidden'>
        <div className='flex items-center gap-2 overflow-hidden fill-gray12/90 !text-gray12/90'>
          {item.icon}
          <p className='truncate font-[500]'>{title}</p>
        </div>
        {item.count !== undefined && (
          <div className='self-end'>
            <span className='text-xs text-typeTertiary'>{item.count}</span>
          </div>
        )}
        {item.customBadge ?? null}
      </div>
    </Component>
  )
}

SidebarItem.displayName = 'SidebarItem'
export default SidebarItem
