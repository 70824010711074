import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { type VariantProps, cva } from 'class-variance-authority'
import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

import clsxm from '@/lib/clsxm'

import UnstyledLink from '../Links/UnstyledLink'

const dropdownContentVariants = cva(
  'relative min-w-[8rem] overflow-hidden rounded-md border bg-white dark:bg-gray-800 dark:border-gray-700 p-1 shadow-md animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
  {
    variants: {
      variant: {
        default: '',
        destructive:
          'text-red-600 dark:text-red-400 focus:text-red-600 dark:focus:text-red-400',
      },
      zIndex: {
        default: 'z-50',
        popover: 'z-[51]', // Higher than default dropdown z-index
      },
    },
    defaultVariants: {
      variant: 'default',
      zIndex: 'default',
    },
  }
)

const dropdownItemVariants = cva(
  'relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none transition-colors focus:bg-gray-100 dark:focus:bg-gray-700 focus:text-gray-900 dark:focus:text-gray-100 dark:text-gray-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
  {
    variants: {
      variant: {
        default: '',
        destructive:
          'text-red-600 dark:text-red-400 focus:text-red-600 dark:focus:text-red-400 focus:bg-red-50 dark:focus:bg-red-900/10',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

interface DropdownPropType
  extends DropdownMenuPrimitive.DropdownMenuContentProps,
    VariantProps<typeof dropdownContentVariants> {
  children?: React.ReactNode
  arrow?: boolean
  align?: 'start' | 'center' | 'end'
  asChild?: boolean
  className?: string
  open?: boolean
  onOpenChange?: (status: boolean) => void
  disabled?: boolean
  dataTestId?: string
  modal?: boolean
  forceMount?: true | undefined
  zIndex?: 'default' | 'popover'
}

export const DropdownContent = ({
  children,
  arrow,
  align = 'center',
  dataTestId = 'dropdown_content',
  className = '',
  variant,
  zIndex,
  forceMount,
  ...props
}: DropdownPropType) => (
  <DropdownMenuPrimitive.Portal forceMount={forceMount}>
    <DropdownMenuPrimitive.Content
      data-testid={dataTestId}
      className={clsxm(dropdownContentVariants({ variant, zIndex }), className)}
      sideOffset={10}
      align={align}
      forceMount={forceMount}
      {...props}
    >
      {children}
      {arrow && (
        <DropdownMenuPrimitive.Arrow className='fill-white dark:fill-gray-800' />
      )}
    </DropdownMenuPrimitive.Content>
  </DropdownMenuPrimitive.Portal>
)

export const DropdownSubContent = ({
  children,
  variant,
  zIndex,
  forceMount,
}: DropdownPropType) => (
  <DropdownMenuPrimitive.Portal forceMount={forceMount}>
    <DropdownMenuPrimitive.SubContent
      sideOffset={2}
      alignOffset={5}
      className={dropdownContentVariants({ variant, zIndex })}
      forceMount={forceMount}
    >
      {children}
    </DropdownMenuPrimitive.SubContent>
  </DropdownMenuPrimitive.Portal>
)

export const DropdownGroup = (asChild: boolean) => (
  <DropdownMenuPrimitive.Group asChild={asChild} />
)

export const DropdownTrigger = ({
  children,
  asChild = false,
  className,
  disabled,
  ...props
}: DropdownMenuPrimitive.DropdownMenuTriggerProps) => {
  return (
    <DropdownMenuPrimitive.Trigger
      asChild={asChild}
      className={clsx('outline-none', className)}
      disabled={disabled}
      {...props}
    >
      {children}
    </DropdownMenuPrimitive.Trigger>
  )
}

export const DropdownSub = ({ ...props }: DropdownPropType) => (
  <DropdownMenuPrimitive.Sub {...props} />
)

export const DropdownLabel = ({ ...props }: DropdownPropType) => (
  <DropdownMenuPrimitive.Label
    {...props}
    className={clsxm(
      'px-2 py-1.5 text-sm font-semibold dark:text-gray-100',
      props.className
    )}
  />
)

export const DropdownSeparator = ({ ...props }: DropdownPropType) => (
  <DropdownMenuPrimitive.Separator
    {...props}
    className='my-1 h-px bg-gray-200 dark:bg-gray-700'
  />
)

interface DropdownItemPropType
  extends VariantProps<typeof dropdownItemVariants> {
  children?: React.ReactNode
  disabled?: boolean
  icon?: React.ReactNode
  itemValue?: React.ReactNode
  href?: string
  RightSlot?: React.ReactNode
  onSelect?: (e: any) => any
  target?: '_blank' | '_self' | '_parent' | '_top'
  dataTestId?: string
  className?: string
  textClassName?: string
}

export const DropdownItem = ({
  children,
  disabled,
  icon,
  itemValue,
  variant,
  href,
  RightSlot,
  onSelect,
  dataTestId = 'dropdown_item',
  className = '',
  textClassName,
  ...props
}: DropdownItemPropType) => (
  <DropdownMenuPrimitive.Item
    data-testid={dataTestId}
    className={clsxm(dropdownItemVariants({ variant }), className)}
    disabled={disabled}
    onSelect={onSelect}
    {...props}
  >
    {!children && !href && (
      <div className='flex w-full items-center justify-between'>
        <div className={clsx('flex items-center', textClassName)}>
          {icon && (
            <span className='mr-2 flex h-4 w-4 shrink-0 items-center'>
              {icon}
            </span>
          )}
          <span className='truncate'>{itemValue}</span>
        </div>
        <span className='shrink-0'>{RightSlot}</span>
      </div>
    )}
    {!children && href && (
      <UnstyledLink
        href={href}
        target={props.target}
        className='flex w-full items-center justify-between'
        {...props}
        onClick={(_e) => {
          // Don't call onSelect when href is provided to avoid double actions
          // onSelect?.(e)
        }}
      >
        <div className={clsx('flex items-center', textClassName)}>
          {icon && (
            <span className='mr-2 flex h-4 w-4 shrink-0 items-center'>
              {icon}
            </span>
          )}
          <span className='truncate'>{itemValue}</span>
        </div>
        {RightSlot}
      </UnstyledLink>
    )}
    {Boolean(children) && children}
  </DropdownMenuPrimitive.Item>
)

export const Dropdown: FC<DropdownPropType> = ({ modal = false, ...props }) => (
  <DropdownMenuPrimitive.Root modal={modal} {...props} />
)

Dropdown.displayName = 'Dropdown'
export default Dropdown
