import type { IPowerUpInfo } from '@/types/billing'
import type {
  IOrganizationCustomization,
  IOrganizationPlan,
} from '@/types/organization'

export const POWER_UPS = [
  'moderation',
  'knowledge_base',
  'analytics',
  'custom_domain',
  'whitelist_domain_config',
  'letterbox',
  'sso',
  'github',
  'intercom',
  'jira',
  // 'salesforce',
  'slack',
  'zendesk',
  'zapier',
  'linear',
  'admins',
  'members',
  'csm',
  'multiple_roadmap',
  'advanced_notification',
  'ai_credits',
]

// After the new plan is released, we will remove the powerUp from the list
export const REMOVED_POWER_UPS = [
  'whitelist_domain_config',
  'letterbox',
  'advanced_notification',
]

export const QUANTITY_POWER_UPS = [
  'boards',
  'admins',
  'members',
  'csm',
  'ai_credits',
  'ext_files_storage',
  'buckets',
  'collaborators',
]

export const MODULE_POWER_UPS = [
  'moderation',
  'analytics',
  'custom_domain',
  'whitelist_domain_config',
  'letterbox',
  'sso',
  'knowledgeBase',
]

export const ONE_TIME_POWER_UPS = ['ai_credits', 'ext_files_storage']
export const BULK_QUANTITY_POWER_UPS = ['ai_credits']

export const TRIAL_MODULES = ['multiple_roadmap']

export const NON_REMOVABLE_POWER_UPS = ['ai_credits']

export const checkAndUpdateContext = (
  purchasedPowerUps: IPowerUpInfo[],
  updateContext: any,
  organizationPlan: IOrganizationPlan,
  organizationCustomization?: IOrganizationCustomization
) => {
  const organizationPowerUps = organizationPlan.power_ups || {}
  purchasedPowerUps.map((powerUp) => {
    if (QUANTITY_POWER_UPS.includes(powerUp.slug)) {
      return updateContext({
        organizationPlan: {
          ...organizationPlan,
          power_ups: {
            ...organizationPowerUps,
            [powerUp.slug]: {
              ...organizationPowerUps[powerUp.slug],
              ...powerUp,
              limit:
                Number(organizationPowerUps[powerUp.slug]?.limit) +
                Number(
                  Number(powerUp?.multiplier || 1) * Number(powerUp?.quantity)
                ),
              purchased:
                Number(organizationPowerUps[powerUp.slug]?.purchased) +
                Number(
                  Number(powerUp?.multiplier || 1) * Number(powerUp?.quantity)
                ),
            },
          },
        },
      })
    }

    return updateContext({
      organizationPlan: {
        ...organizationPlan,
        allow_multiple_roadmaps: Boolean(powerUp.slug === 'multiple_roadmap'),
        power_ups: { ...organizationPowerUps, [powerUp.slug]: powerUp },
      },
      organizationCustomization: {
        ...organizationCustomization,
        roadmap_type:
          powerUp.slug === 'multiple_roadmap'
            ? 'custom'
            : organizationCustomization?.roadmap_type,
      },
    })
  })
}

export const checkQuantityLimitIsExceeded = (
  planPowerUp: IPowerUpInfo,
  orgPowerUp: IPowerUpInfo
) => {
  if (Number(planPowerUp?.limit) >= Number(orgPowerUp?.limit)) return null
  return {
    ...planPowerUp,
    limitDifference: Number(orgPowerUp?.limit) - Number(planPowerUp?.limit),
    canMakeZero: Number(orgPowerUp?.used) < Number(orgPowerUp?.limit),
  }
}

export const checkQuantityPowerUpsExceededForPlan = (
  planPowerUps: IPowerUpInfo[],
  organizationPlan: IOrganizationPlan
) => {
  return planPowerUps
    .filter((power) => QUANTITY_POWER_UPS.includes(power.slug))
    .map((powerUp) =>
      checkQuantityLimitIsExceeded(
        powerUp,
        // @ts-ignore
        organizationPlan?.power_ups[powerUp?.slug]
      )
    )
    .filter((value) => value !== null)
}

export const checkAndRemovePowerUpsInContext = (
  powerUp: IPowerUpInfo,
  updateContext: any,
  organizationPlan: IOrganizationPlan
) => {
  const powerUps = organizationPlan.power_ups || {}
  if (QUANTITY_POWER_UPS.includes(powerUp.slug)) {
    // Update quantity powerUp in context
    powerUps[powerUp.slug] = { ...powerUps[powerUp.slug], ...powerUp }
  } else {
    // Remove powerUp in context
    delete powerUps[powerUp.slug]
  }
  updateContext?.({
    organizationPlan: { ...organizationPlan, power_ups: powerUps },
  })
}

export const getUserPowerUpRole = (role: string) => {
  let userRole = 'user'
  if (role === 'admin') userRole = 'admins'
  if (role === 'member') userRole = 'members'
  if (role === 'csm') userRole = 'csm'
  if (role === 'collaborators') userRole = 'collaborators'
  return userRole
}

const getToUsagePowerUp = (to: string) => {
  if (to === 'boards') return 'boards'
  if (to === 'ai_credits') return 'ai_credits'
  return getUserPowerUpRole(to)
}

export const checkAndUpdateUsage = (
  organizationPlan: IOrganizationPlan,
  updateContext: any,
  from: string | null,
  to: string,
  type: 'add' | 'update' | 'delete',
  keyToBeUpdate: 'limit' | 'used',
  quantity?: number
) => {
  const powerUps = organizationPlan?.power_ups
  const fromUsage = from && getUserPowerUpRole(from)
  const toUsage = getToUsagePowerUp(to)

  // To update powerUp limit
  if (keyToBeUpdate === 'limit') {
    // @ts-ignore
    powerUps[toUsage] = {
      ...powerUps[toUsage],
      limit: Number(powerUps[toUsage]?.limit) + (quantity || 1),
    }
  }

  // To update used key in quantity powerUp
  if (keyToBeUpdate === 'used') {
    if (type === 'add') {
      // @ts-ignore
      powerUps[toUsage] = {
        ...powerUps[toUsage],
        used: Number(powerUps[toUsage]?.used) + (quantity || 1),
      }
    }
    if (type === 'update' && fromUsage) {
      // @ts-ignore
      powerUps[fromUsage] = {
        ...powerUps[fromUsage],
        used: Number(powerUps[fromUsage]?.used) - (quantity || 1),
      }
      // @ts-ignore
      powerUps[toUsage] = {
        ...powerUps[toUsage],
        used: Number(powerUps[toUsage]?.used) + (quantity || 1),
      }
    }
    if (type === 'delete') {
      // @ts-ignore
      powerUps[toUsage] = {
        ...powerUps[toUsage],
        used: Number(powerUps[toUsage]?.used) - 1,
        limit:
          Number(powerUps[toUsage]?.grace_count) > 0
            ? Number(powerUps[toUsage]?.limit) - 1
            : Number(powerUps[toUsage]?.limit),
        grace_count:
          Number(powerUps[toUsage]?.grace_count) > 0
            ? Number(powerUps[toUsage]?.grace_count) - 1
            : Number(powerUps[toUsage]?.grace_count),
      }
    }
  }
  updateContext({
    organizationPlan: { ...organizationPlan, power_ups: powerUps },
  })
}

export const checkPowerUpLimitIsExceeded = (
  powerUp: string,
  organizationPlan: IOrganizationPlan
) => {
  return (
    (organizationPlan.power_ups?.[powerUp]?.limit || 0) <=
    (organizationPlan.power_ups?.[powerUp]?.used || 0)
  )
}
