import React, { useMemo } from 'react'

import ConditonalWrapper from '@/components/shared/components/ConditonalWrapper'
import Image from '@/components/shared/ui/Image/NextImage'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Tooltip from '@/components/shared/ui/Tooltip/Tooltip'
import { settingsHelpLinks } from '@/config/staticURLs'

import Badge from '../ui/Badge'

interface PropType {
  source: string | null | undefined
  size?: number
  showPill?: boolean
  sourceLetterBoxEmail?: string
  sourceInfo?: {
    intercom_conversation_id?: string
    intercom_conversation_url?: string
    url?: string
  }
}

export default function SourceIcon(props: PropType) {
  const {
    sourceInfo,
    source = null,
    size,
    showPill = false,
    sourceLetterBoxEmail,
  } = props

  const capitalize = (sourceText: string) => {
    if (!sourceText) return false
    return (sourceText[0]?.toUpperCase() || '') + sourceText.slice(1)
  }

  const sourceData = useMemo(() => {
    switch (source) {
      case 'widget': {
        return {
          icon: 'widget.svg',
          info_text: `via ${capitalize(source)}`,
          type: source,
          link: sourceInfo?.url,
          tooltip_text:
            sourceInfo && sourceInfo.url
              ? `${sourceInfo.url}`
              : `${capitalize(source)}`,
        }
      }
      case 'letterbox': {
        return {
          icon: 'paperplane.svg',
          info_text: `via ${capitalize(source)}`,
          type: source,
          tooltip_text: sourceLetterBoxEmail
            ? `${capitalize(source)} ${sourceLetterBoxEmail}`
            : `${capitalize(source)}`,
        }
      }
      case 'slack': {
        return {
          icon: 'slack.svg',
          info_text: `via ${capitalize(source)}`,
          type: source,
        }
      }
      case 'zendesk': {
        return {
          icon: 'zendesk.svg',
          info_text: `via ${capitalize(source)}`,
          type: source,
          link: sourceInfo?.url,
        }
      }
      case 'jira': {
        return {
          icon: 'jira.svg',
          info_text: `via ${capitalize(source)}`,
          type: source,
        }
      }
      case 'intercom': {
        return {
          icon: 'intercom-blue.svg',
          info_text: `via ${capitalize(source)}`,
          type: source,
          link: sourceInfo?.intercom_conversation_url,
        }
      }
      case 'salesforce': {
        return {
          icon: 'salesforce.svg',
          info_text: `via ${capitalize(source)}`,
          type: source,
          link: sourceInfo?.url,
        }
      }
      case 'discord': {
        return {
          icon: 'discord.svg',
          info_text: `via ${capitalize(source)}`,
          type: source,
          link: sourceInfo?.url,
        }
      }
      case 'asana': {
        return {
          icon: 'asana.svg',
          info_text: `via ${capitalize(source)}`,
          type: source,
          link: sourceInfo?.url,
        }
      }
      case 'csv_import': {
        return {
          icon: 'csv.svg',
          info_text: `via CSV Import`,
          type: source,
          link: sourceInfo?.url,
        }
      }
      default: {
        return { icon: null, info_text: '', type: null }
      }
    }
  }, [source])

  const tooltipText = sourceData.tooltip_text || sourceData.info_text

  const renderBadge = () => {
    if (sourceData?.link)
      return (
        <Tooltip text={tooltipText} asChild>
          <UnstyledLink
            target='_blank'
            href={(sourceData?.link as string) || '#'}
          >
            <Badge
              variant='gray'
              imageLink={`${settingsHelpLinks.cdnLogo}${sourceData.icon}`}
              value={sourceData.info_text}
              suffixIcon='↗'
            />
          </UnstyledLink>
        </Tooltip>
      )
    return (
      <Tooltip text={tooltipText} asChild>
        <Badge
          variant='gray'
          imageLink={`${settingsHelpLinks.cdnLogo}${sourceData.icon}`}
          value={sourceData.info_text}
        />
      </Tooltip>
    )
  }

  if (!sourceData.type) return <></>
  return (
    <span className='flex items-center space-x-1'>
      {showPill ? (
        renderBadge()
      ) : (
        <Tooltip
          customtext={
            <span className='text-left'>
              <p>via {capitalize(sourceData.type)}</p>
              <p>{sourceLetterBoxEmail}</p>
            </span>
          }
          asChild
        >
          <ConditonalWrapper
            wrapper={(children) => (
              <UnstyledLink target='_blank' href={sourceData.link as string}>
                {children}
              </UnstyledLink>
            )}
            condition={!!sourceData.link}
          >
            <Image
              src={`${settingsHelpLinks.cdnLogo}${sourceData.icon}`}
              className='h-4 w-4 shrink-0'
              alt={sourceData.info_text}
              height={size || 14}
              width={size || 14}
            />
          </ConditonalWrapper>
        </Tooltip>
      )}
    </span>
  )
}
