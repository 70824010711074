import {
  ClockCounterClockwise,
  EyeSlash,
  GearSix,
  Lock,
  LockOpen,
  PaperPlaneTilt,
  Pencil,
  ProhibitInset,
  Trash,
} from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import { useContext, useRef, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownSeparator,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { checkRoadmapPath } from '@/lib/helpers/pathHelpers'
import postStore, { roadmapStores } from '@/stores/PostListStore'
import type { IOrganizationSetting } from '@/types/organization'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

import type { ISinglePostReadOnlyActions } from './SinglePostReadOnlyActions'
import SinglePostReadOnlyActions from './SinglePostReadOnlyActions'

interface IPropTypes {
  post: IPost
  aside?: boolean
  onEdit: () => void
  onClose?: () => void
}

export default function SinglePostManageOptions({
  post,
  aside = false,
  onEdit,
  onClose,
}: IPropTypes) {
  const t = useTranslations()
  const router = useRouter()
  const {
    organizationSetting,
    pendingPostsCount,
    updateContext,
    isAdmin,
    default_module,
    userProfile,
  } = useContext(HNContext) as {
    organizationSetting: IOrganizationSetting
    pendingPostsCount: number
    updateContext: (data: any) => void
    isAdmin: boolean
    default_module: string
    userProfile?: IUserProfile
  }
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  const [openResetUpvoteDialog, setOpenResetUpvoteDialog] =
    useState<boolean>(false)
  const [openResetDownvoteDialog, setOpenResetDownvoteDialog] =
    useState<boolean>(false)
  const [disableCommentsDropdown, setDisableCommentsDropdown] =
    useState<boolean>(false)
  const [openHidePostAlertDialog, setOpenHidePostAlertDialog] = useState(false)
  const readOnlyOptionsRef = useRef<ISinglePostReadOnlyActions>(null)

  const handlePostHidden = () => {
    EventEmitter.dispatch('ENTITY_UPDATE', {
      entity: ENTITIES.POST_COUNTS,
      actionType: EVENT_ACTION_TYPES.UPDATE,
      fromTab: !post.hidden ? post.status.value : 'hidden',
      toTab: !post.hidden ? 'hidden' : post.status.value,
    })
    toaster.success({
      message: post.hidden
        ? t('post.messages.hidden.unhide')
        : t('post.messages.hidden.hide'),
    })

    return postStore
      .updatePost(
        post.slug,
        { hidden: !post.hidden },
        { ...post, hidden: !post.hidden }
      )
      .catch((err) => {
        EventEmitter.dispatch('ENTITY_UPDATE', {
          entity: ENTITIES.POST_COUNTS,
          actionType: EVENT_ACTION_TYPES.UPDATE,
          fromTab: 'hidden',
          toTab: post.status.value,
        })
        toaster.error({ message: err.message })
      })
  }

  const handleDisableComments = async () => {
    toaster.success({
      message: post.disable_comments
        ? t('post.messages.disableComments.enabled')
        : t('post.messages.disableComments.disabled'),
    })
    return postStore
      .updatePost(
        post.slug,
        { disable_comments: !post.disable_comments },
        { ...post, disable_comments: !post.disable_comments }
      )
      .catch(toaster.error)
      .finally(() => setDisableCommentsDropdown(false))
  }

  const handleResetVotes = (type: string) => {
    if (!post) return
    if (type === 'upvote')
      toaster.success({ message: 'Reset post upvotes successfully' })
    if (type === 'downvote')
      toaster.success({ message: 'Reset post downvotes successfully' })

    postStore.resetVotes(post.slug, type).catch(toaster.error)
  }

  const handleDelete = () => {
    if (!post) return null
    return postStore
      .delete(post.slug)
      .then(() => {
        toaster.success({ message: t('post.messages.deletePost.success') })
        if (post.approval_status === 'pending') {
          postStore.counts.waiting_for_approval -= 1
        }
        EventEmitter.dispatch('ENTITY_UPDATE', {
          entity: ENTITIES.POST_COUNTS,
          actionType: EVENT_ACTION_TYPES.DELETE,
          fromTab:
            post.approval_status === 'pending'
              ? 'waiting_for_approval'
              : 'approved_posts',
          toTab:
            post.approval_status === 'pending'
              ? 'approved_posts'
              : 'waiting_for_approval',
        })
        EventEmitter.dispatch('ENTITY_UPDATE', {
          entity: ENTITIES.POST_COUNTS,
          actionType: EVENT_ACTION_TYPES.DELETE,
          fromTab: post.hidden ? 'hidden' : post.status.value,
          post,
        })

        if (
          post.approval_status === 'pending' &&
          post.status.value !== 'closed'
        ) {
          const newCount = +pendingPostsCount - 1
          if (updateContext) updateContext({ pendingPostsCount: newCount })
        }
        if (checkRoadmapPath(router) || default_module === 'roadmap') {
          roadmapStores[post.status.value]?.deleteSinglePost(post.slug, post)
        }
        if (onClose) onClose()
      })
      .catch((err) => toaster.error({ message: err.message }))
  }

  if (!post.can_edit) return null
  if (post.read_only && !userProfile?.is_csm_of_organization) return null

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button
            isResponsive
            as='span'
            size='xs'
            variant='outline'
            icon={
              <GearSix
                className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
                weight='fill'
              />
            }
            className={'group !text-gray12/80 hover:text-gray12'}
          >
            {aside ? '' : t('post.labels.manageOptions')}
          </Button>
        </DropdownTrigger>
        <DropdownContent align='end'>
          {!isAdmin && post.can_manage && (
            <>
              <DropdownItem
                href={`/admin/p/${post.slug}`}
                icon={
                  <GearSix
                    className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
                    weight='fill'
                  />
                }
                target={'_blank'}
                itemValue={t('post.labels.openInAdmin')}
              />
              <DropdownSeparator />
            </>
          )}
          {post.can_edit && !isAdmin && (
            <DropdownItem
              onSelect={() => onEdit()}
              icon={<Pencil className='h-4 w-4' weight='fill' />}
              itemValue={t('post.labels.editPost')}
            />
          )}
          {userProfile?.is_member_of_organization && (
            <DropdownItem
              onSelect={() => {
                if (post.read_only) readOnlyOptionsRef.current?.unlockPost()
                else readOnlyOptionsRef.current?.toggleOpen()
              }}
              icon={
                post.read_only ? (
                  <LockOpen className='h-4 w-4' weight='fill' />
                ) : (
                  <Lock className='h-4 w-4' weight='fill' />
                )
              }
              itemValue={
                post.read_only
                  ? t('post.labels.unlockPost')
                  : t('post.labels.lockPost')
              }
            />
          )}

          {post?.can_hide && (
            <DropdownItem
              onSelect={() => setOpenHidePostAlertDialog(true)}
              icon={<EyeSlash className='h-4 w-4' weight='fill' />}
              itemValue={
                post.hidden ? t('post.labels.unhide') : t('post.labels.hide')
              }
            />
          )}
          {Boolean(post.can_disable_comments) && (
            <DropdownItem
              onSelect={() => setDisableCommentsDropdown(true)}
              icon={<ProhibitInset className='h-4 w-4' weight='fill' />}
              itemValue={
                post.disable_comments
                  ? t('post.labels.enableComments')
                  : t('post.labels.disableComments')
              }
            />
          )}
          {post.can_email_submitter && (
            <DropdownItem
              href={`mailto:${post.submitter.email}`}
              target='_blank'
              icon={<PaperPlaneTilt className='h-4 w-4' weight='fill' />}
              itemValue={t('post.labels.emailSubmitter')}
            />
          )}
          {post.can_reset && (
            <>
              <DropdownItem
                icon={
                  <ClockCounterClockwise className='h-4 w-4' weight='fill' />
                }
                itemValue={t('post.resetVotes.upvote.title')}
                onSelect={() => setOpenResetUpvoteDialog(true)}
              />
              {organizationSetting?.downvotes && (
                <DropdownItem
                  icon={
                    <ClockCounterClockwise className='h-4 w-4' weight='fill' />
                  }
                  onSelect={() => setOpenResetDownvoteDialog(true)}
                  itemValue={t('post.resetVotes.downvote.title')}
                />
              )}
            </>
          )}
          {post.can_delete && (
            <>
              <DropdownSeparator />
              <DropdownItem
                variant='destructive'
                data-testid='menu_delete_post_button'
                icon={<Trash className='h-4 w-4' weight='fill' />}
                onSelect={() => setOpenDeleteDialog(true)}
                itemValue={t('post.labels.deletePost')}
              />
            </>
          )}
        </DropdownContent>
        <AlertDialog
          open={disableCommentsDropdown}
          onOpen={() => setDisableCommentsDropdown(true)}
          onClose={() => setDisableCommentsDropdown(false)}
          type={post.disable_comments ? 'info' : 'danger'}
          confirmText={
            post.disable_comments ? t('buttons.enable') : t('buttons.disable')
          }
          title={t('post.disableCommentsAlertTexts.title')}
          description={
            post.disable_comments
              ? t('post.disableCommentsAlertTexts.enableComments')
              : t('post.disableCommentsAlertTexts.disableComments')
          }
          onConfirm={() => handleDisableComments()}
          waitForPromise={false}
        />
        <AlertDialog
          type='danger'
          open={openResetUpvoteDialog}
          onClose={() => setOpenResetUpvoteDialog(false)}
          confirmText={t('post.resetVotes.upvote.title')}
          title={t('post.resetVotes.upvote.alertTexts.title')}
          description={t('post.resetVotes.upvote.alertTexts.description')}
          onConfirm={() => handleResetVotes('upvote')}
          onCancel={() => setOpenResetUpvoteDialog(false)}
          waitForPromise={false}
        />
        <AlertDialog
          type='danger'
          open={openResetDownvoteDialog}
          onClose={() => setOpenResetDownvoteDialog(false)}
          confirmText={t('post.resetVotes.downvote.title')}
          title={t('post.resetVotes.downvote.alertTexts.title')}
          description={t('post.resetVotes.downvote.alertTexts.description')}
          onConfirm={() => handleResetVotes('downvote')}
          onCancel={() => setOpenResetDownvoteDialog(false)}
          waitForPromise={false}
        />
        <AlertDialog
          type='danger'
          open={openDeleteDialog}
          onOpen={() => setOpenDeleteDialog(true)}
          onClose={() => setOpenDeleteDialog(false)}
          confirmText={t('buttons.delete')}
          cancelText={t('buttons.cancel')}
          title={t('post.deletePost.alertTexts.title')}
          description={t('post.deletePost.alertTexts.description', {
            data: {
              postName: `<b>${post?.title}</b>`,
            },
          })}
          onConfirm={() => handleDelete()}
          onCancel={() => setOpenDeleteDialog(false)}
        />
        <AlertDialog
          type='danger'
          open={openHidePostAlertDialog}
          onOpen={() => setOpenHidePostAlertDialog(true)}
          onClose={() => setOpenHidePostAlertDialog(false)}
          confirmText={post.hidden ? t('buttons.unhide') : t('buttons.hide')}
          cancelText={t('buttons.cancel')}
          title={
            post.hidden
              ? t('post.unHidePost.alertTexts.title')
              : t('post.hidePost.alertTexts.title')
          }
          description={
            post.hidden
              ? t('post.unHidePost.alertTexts.description')
              : t('post.hidePost.alertTexts.description')
          }
          onConfirm={() => handlePostHidden()}
          onCancel={() => setOpenHidePostAlertDialog(false)}
          waitForPromise={false}
        />
      </Dropdown>
      <SinglePostReadOnlyActions post={post} ref={readOnlyOptionsRef} />
    </>
  )
}
