import { ArrowSquareOut } from '@phosphor-icons/react'
import { useContext } from 'react'

import Button from '@/components/shared/ui/Button'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { getPostURL } from '@/lib/helpers/modules/postHelper'
import type { IOrganizationData } from '@/types/organization'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
}

export default function SinglePostViewAction({ post }: IPropTypes) {
  const t = useTranslations()
  const { organization } = useContext(HNContext) as {
    organization: IOrganizationData
  }
  return (
    <Tooltip text={t('post.labels.viewPost')} placement='bottom' asChild>
      <Button
        fab
        size='xs'
        target='_blank'
        as='a'
        href={getPostURL(organization.home_page, post.slug)}
        variant='outline'
        className={'group'}
      >
        <ArrowSquareOut
          weight='fill'
          className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12 md:h-4 md:w-4'
        />
      </Button>
    </Tooltip>
  )
}
