import { useEffect, useState } from 'react'

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(true)

  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ]

  const updateFlag = () => {
    if (typeof navigator === 'undefined' || typeof window === 'undefined')
      return

    const matched = toMatch.some((toMatchItem) =>
      navigator.userAgent.match(toMatchItem)
    )
    setIsMobile(matched)

    window.addEventListener('resize', updateFlag)
  }

  useEffect(() => {
    updateFlag()
    return () => window.removeEventListener('resize', updateFlag)
  }, [])

  return isMobile
}
