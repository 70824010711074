import { useEffect, useMemo, useState } from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import { getInteractorLabels } from '@/models/User'
import type { IFilters } from '@/types/common'
import type { IUserLabel } from '@/types/user'

interface IPropTypes {
  onChange?: (
    key: string,
    value: string[],
    selectedValues?: ISelectOption[]
  ) => void
  filters: IFilters
  placeholder?: string
  disabled?: boolean
  behaviour?: 'select' | 'filter'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export default function AdminUserTagsFilter({
  onChange,
  filters,
  placeholder,
  disabled = false,
  behaviour = 'filter',
  size = 'sm',
}: IPropTypes) {
  const t = useTranslations('filters')
  const [loading, setLoading] = useState(false)
  const [tags, setTags] = useState<IUserLabel[]>([])

  const tagsOptions = useMemo(
    () =>
      tags
        ?.map((tag) => ({ label: tag.title, value: tag.id }))
        .filter((ft: ISelectOption) => {
          if (behaviour === 'filter') {
            return true
          }
          return !filters?.user_tag_id?.includes(ft.value)
        }),
    [tags]
  )

  const handleChange = (value: ISelectOptionGroup) => {
    if (!value) return
    if (!onChange) return

    const selectedValue = Array.isArray(value) ? value : [value]
    const tagId = selectedValue?.map((item: ISelectOption) => item.value)
    onChange('user_tag_id', tagId, selectedValue)
  }

  const getValue = () => {
    if (filters && filters.user_tag_id) {
      const filteredTags = Array.isArray(filters.user_tag_id)
        ? filters.user_tag_id
        : [filters.user_tag_id]
      return tagsOptions.filter((b) =>
        filteredTags
          ?.map((id: string) => id.toString())
          .includes(b.value.toString())
      )
    }
    return null
  }

  const fetchData = () => {
    setLoading(true)
    getInteractorLabels()
      .then(setTags)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Select
      size={size}
      placeholder={placeholder || t('tags.placeholder')}
      multiple={behaviour === 'filter'}
      clearable
      options={tagsOptions}
      onChange={handleChange}
      value={
        behaviour === 'filter'
          ? getValue()
          : [
              {
                label: placeholder || t('tags.placeholder'),
                value: 0,
              },
            ]
      }
      loading={loading}
      disabled={disabled}
      creatable={behaviour === 'select'}
    />
  )
}
