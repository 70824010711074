import type { IEmbedType } from '@/types/organization'

import { removeNullOrUndefinedKeys } from './dataHelpers'

export const buildURL = (baseURL: string, params: any) => {
  const cleanedParam = removeNullOrUndefinedKeys(params)
  const keys = Object.keys(cleanedParam)
  if (keys.length === 0) {
    return baseURL
  }
  const paramsString = keys.map((key) => {
    if (Array.isArray(cleanedParam[key])) {
      return cleanedParam[key].map((item: any) => `${key}=${item}`).join('&')
    }
    return `${key}=${cleanedParam[key]}`
  })
  let baseURLWithPrefix = baseURL
  if (baseURL.includes('?')) {
    baseURLWithPrefix = `${baseURL}&`
  } else {
    baseURLWithPrefix = `${baseURL}?`
  }

  return `${baseURLWithPrefix}${paramsString.join('&')}`
}

export const encodeURL = (url: string) => {
  return encodeURIComponent(url)
}

export const decodeURl = (url: string) => {
  return decodeURIComponent(url)
}
// get url host
export const getHost = (url: string) => {
  const { host } = new URL(url)
  return host
}

export const updateUniqueQuery = (data: string[]) => {
  const newData: any = []
  data.map((item: number | string) => {
    if (!newData.includes(item.toString())) return newData.push(item.toString())
    return newData
  })
  return newData
}

export const getURLPath = (url: string) => {
  const path = url.split('?')[0]
  return path
}

// get query params from url typescript
export const getQueryParams = (url: string) => {
  const params = new URLSearchParams(url)
  return params
}

export const removeHashFromURL = (url: string) => {
  const newURL = url.split('#')[0] || url
  return newURL
}

export const getHashFromURL = (url: string) => {
  const hash = url.split('#')[1]
  return hash
}
export const getBasePathPrefix = (embedType?: IEmbedType | null) => {
  if (!embedType) return undefined
  if (embedType === 'moduleEmbed') return '/embed'
  if (embedType === 'orgEmbed') return '/embed/home'
  if (embedType === 'postWidget') return '/widget/post'
  if (embedType === 'changelogWidget') return '/widget'
  return undefined
}

// join url and paths
export const joinURL = (url: string, ...paths: string[]) => {
  const path = paths.join('/')
  const fullURL =
    url.startsWith('https://') || url.startsWith('http://')
      ? url
      : `https://${url}`

  const urlWithPrefix = new URL(fullURL)
  urlWithPrefix.pathname = path
  return urlWithPrefix.toString()
}

// check if the domain is valid url
export const validHostname = (hostname: string) => {
  const regex =
    /^((?:(?:(?:\w[\\.\-\\+]?)*)\w)+)((?:(?:(?:\w[\\.\-\\+]?){0,62})\w)+)\.(\w{2,6})$/
  return regex.test(hostname)
}

export const removeDuplicateSlash = (url: string) => {
  return url.replace(/([^:]\/)\/+/g, '$1')
}

export const isExternalURL = (url: string) => {
  return url.startsWith('http')
}
