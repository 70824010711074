import {
  Cardholder,
  GearFine,
  Palette,
  PlugCharging,
  PuzzlePiece,
  SlidersHorizontal,
  UsersThree,
  WebhooksLogo,
} from '@phosphor-icons/react'

import type { INavigationItem } from '@/types/sidebar'

export const settingsNavigation: INavigationItem[] = [
  {
    key: 'orgSettings',
    link: '/admin/settings',
    labelTransKey: 'organization.settings.tabTitle',
    anotherHref: ['/admin/settings'],
    icon: <GearFine size={16} />,
    availableRoles: ['admin'],
    isExact: true,
  },
  {
    key: 'advanced',
    link: '/admin/settings/advanced',
    labelTransKey: 'organization.advanced.tabTitle',
    anotherHref: ['/admin/settings/advanced'],
    icon: <SlidersHorizontal size={16} />,
    availableRoles: ['admin'],
    isExact: true,
  },
  {
    key: 'integrations',
    link: '/admin/settings/integrations',
    labelTransKey: 'integrations.title',
    anotherHref: ['/admin/settings/integrations'],
    icon: <PlugCharging size={16} />,
    availableRoles: ['admin'],
    isExact: true,
  },

  {
    key: 'webhooks',
    link: '/admin/settings/webhooks',
    labelTransKey: 'organization.webhooks.tabTitle',
    anotherHref: ['/admin/settings/webhooks'],
    icon: <WebhooksLogo size={16} />,
    availableRoles: ['admin'],
    isExact: true,
  },
  {
    key: 'widgetPlayground',
    link: '/admin/settings/widget-playground',
    labelTransKey: 'organization.widgetPlayground.tabTitle',
    anotherHref: ['/admin/settings/widget-playground'],
    icon: <PuzzlePiece size={16} />,
    availableRoles: ['admin'],
    isExact: true,
  },
  {
    key: 'billing',
    link: '/admin/settings/billing',
    labelTransKey: 'billing.title',
    anotherHref: ['/admin/settings/billing/[[...billing]]'],
    icon: <Cardholder size={16} />,
    availableRoles: ['admin'],
    isExact: true,
  },
  {
    key: 'myTeam',
    link: '/admin/settings/team',
    labelTransKey: 'sidebar.items.team',
    anotherHref: ['/admin/settings/team/[[...role]]'],
    icon: <UsersThree size={16} />,
    availableRoles: ['admin'],
    isExact: true,
  },
  {
    key: 'customization',
    link: '/admin/settings/customization',
    labelTransKey: 'sidebar.items.boards_customization',
    icon: <Palette weight='regular' size={16} />,
    anotherHref: ['/admin/settings/customization'],
    availableRoles: ['admin'],
    group: 'enterprise',

    shouldShow: ({ organizationCustomization }) => {
      return !!Object.keys(organizationCustomization?.home_page_config || {})
        .length
    },
    isExact: true,
  },
]

export const settingsNavigationGroups = {
  organization: {
    label: 'Organization',
    open: true,
  },
  enterprise: {
    label: 'Enterprise',
    open: true,
  },
}
