import React, { useMemo } from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectPropTypes,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import { titleize } from '@/lib/helpers/stringHelpers'
import type { IAsanaIntegrationStatusSyncRule } from '@/types/integration/statusSync'

interface IAdminIntegrationCustomFieldValuesDropDownProps {
  syncRule: IAsanaIntegrationStatusSyncRule
  provider: string
  edit: boolean
  ruleKey: string
  onChange: (key: string, value: string) => void
  projects: ISelectOption[]
  size?: ISelectPropTypes['size']
}

export default function AdminIntegrationCustomFieldValueDropDown({
  syncRule,
  provider,
  edit,
  onChange,
  projects,
  ruleKey,
}: IAdminIntegrationCustomFieldValuesDropDownProps) {
  const t = useTranslations(`integrations.${provider}`)
  const selectedProject = useMemo(
    () => projects.find((project) => project.gid === syncRule.project_id),
    [projects, syncRule.project_id]
  )

  const selectedCustomField = useMemo(() => {
    if (!selectedProject) return null
    const selectedFieldId =
      syncRule.custom_field_id ||
      syncRule.trigger_status.custom_field_id ||
      syncRule.status?.custom_field_id
    return selectedProject?.custom_field_settings?.find(
      (data: any) => data.custom_field.gid === selectedFieldId
    )
  }, [selectedProject, syncRule])

  const parseCustomFieldValues = useMemo(() => {
    if (!selectedCustomField) return []
    return selectedCustomField?.custom_field?.enum_options?.map((data: any) => {
      return {
        ...data,
        label: titleize(data.name),
        value: data.gid,
      }
    })
  }, [selectedCustomField, syncRule])

  const getSelectedValue = () => {
    if (typeof syncRule.trigger_status === 'object') {
      return syncRule.trigger_status?.custom_field_value
    }
    if (typeof syncRule.status === 'object') {
      return syncRule.status?.custom_field_value
    }
    return ''
  }

  return (
    <Select
      placeholder={t(`configure.syncRule.customFieldValue.placeholder`)}
      searchPlaceholder={t(
        `configure.syncRule.customFieldValue.searchPlaceholder`
      )}
      loading={false}
      value={setSelectedValue(parseCustomFieldValues, getSelectedValue())}
      searchable
      size='xs'
      options={parseCustomFieldValues}
      onChange={(value) => {
        const selectedValue = getSelectedValueByKey(
          parseCustomFieldValues || [],
          value
        )
        if (selectedValue && Array.isArray(selectedValue)) {
          onChange(ruleKey, selectedValue[0]?.value)
        } else {
          onChange(ruleKey, selectedValue?.value)
        }
      }}
      disabled={!edit || !syncRule.custom_field_id}
    />
  )
}
