import {
  CLICK_UP_CUSTOM_FIELDS,
  CLICK_UP_TASK,
  CLICK_UP_TASKS,
  INTEGRATION_CONFIG,
} from '@/config/routes'
import type {
  IClickUpCustomField,
  IClickUpTask,
} from '@/types/integration/click_up'

import API from '../../lib/API'

export const getClickUpConfig = () => API.get(INTEGRATION_CONFIG('click_up'))
export const getClickUpTask = (id: string) => API.get(CLICK_UP_TASK(id))
export const createClickUpTask = (data: IClickUpTask) =>
  API.post(CLICK_UP_TASKS, data)

export const getCustomFields = (
  listId: string
): Promise<IClickUpCustomField[]> =>
  API.get(CLICK_UP_CUSTOM_FIELDS, { list_id: listId })

export const updateClickupCustomFields = (configData: {
  list_id: string
  config_data: {
    custom_field_id: string
    mapped_value: string
  }[]
}): Promise<IClickUpCustomField[]> =>
  API.post(CLICK_UP_CUSTOM_FIELDS, configData)

export const resetClickupCustomFields = (
  listId: string
): Promise<IClickUpCustomField[]> =>
  API.delete(CLICK_UP_CUSTOM_FIELDS, { list_id: listId })
