import React, { Component } from 'react'

import type { ISlashCommandItem } from './commands'

export const updateScrollView = (
  _container: HTMLElement,
  item: HTMLElement
) => {
  const container = _container
  const containerHeight = container.offsetHeight
  const itemHeight = item ? item.offsetHeight : 0

  const top = item.offsetTop
  const bottom = top + itemHeight

  if (top < container.scrollTop) {
    container.scrollTop -= container.scrollTop - top + 5
  } else if (bottom > containerHeight + container.scrollTop) {
    container.scrollTop += bottom - containerHeight - container.scrollTop + 5
  }
}

interface IPropTypes {
  items: ISlashCommandItem[]
  command: (item: ISlashCommandItem) => void
}
class CommandList extends Component<IPropTypes> {
  state = {
    selectedIndex: 0,
  }

  commandListContainer: null | HTMLDivElement = null

  commandListContainerRef = (element: HTMLDivElement) => {
    this.commandListContainer = element
  }

  componentDidUpdate(oldProps: IPropTypes) {
    if (this.props.items !== oldProps.items) {
      this.setState({
        selectedIndex: 0,
      })
    }

    if (!this.commandListContainer) return null

    const container = this.commandListContainer as HTMLDivElement
    const item = container?.children?.[this.state.selectedIndex] as HTMLElement
    if (item && container) updateScrollView(container as HTMLElement, item)
    return null
  }

  onKeyDown({ event }: { event: KeyboardEvent }) {
    if (event.key === 'ArrowUp') {
      this.upHandler()
      return true
    }

    if (event.key === 'ArrowDown') {
      this.downHandler()
      return true
    }

    if (event.key === 'Enter') {
      this.enterHandler()
      return true
    }

    return false
  }

  upHandler() {
    this.setState({
      selectedIndex:
        (this.state.selectedIndex + this.props.items.length - 1) %
        this.props.items.length,
    })
  }

  downHandler() {
    this.setState({
      selectedIndex: (this.state.selectedIndex + 1) % this.props.items.length,
    })
  }

  enterHandler() {
    this.selectItem(this.state.selectedIndex)
  }

  selectItem(index: number) {
    const item = this.props.items[index]

    if (item) {
      this.props.command(item)
    }
  }

  render() {
    const { items } = this.props
    return (
      <div
        className='z-50 h-auto max-h-[330px] w-72 overflow-y-auto rounded-md border border-gray5 bg-gray1 px-1 py-2 shadow-md transition-all'
        ref={this.commandListContainerRef}
      >
        {items.length ? (
          items.map((item, index) => {
            return (
              <div
                key={item.title}
                data-selected={Boolean(this.state.selectedIndex === index)}
                className='flex cursor-pointer flex-row items-center justify-start gap-2 rounded p-[0.25rem] hover:bg-gray3 data-[selected=true]:bg-gray3'
                onClick={() => this.selectItem(index)}
              >
                <span className='bg-gray-3 flex h-8 w-8 items-center justify-center rounded border border-gray5'>
                  {item.icon}
                </span>
                <div className='flex flex-col items-stretch justify-start gap-1'>
                  <span className='text-xs font-medium leading-none'>
                    {item.title}
                  </span>
                  <span className='text-xs leading-none opacity-50'>
                    {item.description}
                  </span>
                </div>
              </div>
            )
          })
        ) : (
          <div className='flex flex-col items-center justify-center gap-2'>
            <span className='py-2 text-xs font-medium leading-none'>
              No commands found
            </span>
          </div>
        )}
      </div>
    )
  }
}

export default CommandList
