import {
  USER_SEGMENT_FAVORITE_PATH,
  USER_SEGMENT_PATH,
  USER_SEGMENTS_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import {
  getIdxDBAllData,
  setAllIdxDBData,
  setIdxDBData,
} from '@/lib/localDB/localDB'
import type {
  ICreateSegment,
  ISegment,
  IUpdateSegment,
} from '@/types/userSegments'

export const listUserSegments = (params: { starred?: boolean } = {}) => {
  return API.get(USER_SEGMENTS_PATH, params).then((segments: ISegment[]) => {
    return setAllIdxDBData('USER_SEGMENTS', 'id', segments, 'id')
  })
}
export const showUserSegment = (id: number | string) => {
  return API.get(USER_SEGMENT_PATH(id))
}

export const createUserSegment = (data: ICreateSegment) => {
  return API.post(USER_SEGMENTS_PATH, data).then((segment) =>
    setIdxDBData('USER_SEGMENTS', segment.id, segment, 'id')
  )
}
export const editSegment = (segmentId: number, data: IUpdateSegment) => {
  return API.patch(USER_SEGMENT_PATH(segmentId), data).then((segment) =>
    setIdxDBData('USER_SEGMENTS', segmentId.toString(), segment, 'id')
  )
}

export const favoriteSegment = (segmentId: number) => {
  return API.post(USER_SEGMENT_FAVORITE_PATH(segmentId)).then((segment) =>
    setIdxDBData('USER_SEGMENTS', segmentId.toString(), segment, 'id')
  )
}

export const unfavoriteSegment = (segmentId: number) => {
  return API.delete(USER_SEGMENT_FAVORITE_PATH(segmentId)).then((segment) =>
    setIdxDBData('USER_SEGMENTS', segmentId.toString(), segment, 'id')
  )
}

export const listUserSegmentsFromLocalDB = (): Promise<ISegment[]> =>
  getIdxDBAllData('USER_SEGMENTS')

export const deleteSegment = (id: number): Promise<ISegment> => {
  return API.delete(USER_SEGMENT_PATH(id)).then((data) =>
    setIdxDBData('USER_SEGMENTS', id.toString(), null, 'id').then(() => data)
  )
}
