import { Sparkle } from '@phosphor-icons/react'
import React, { useState } from 'react'

import { useTranslations } from '@/hooks/useTranslations'
import postStore from '@/stores/PostListStore'
import type { IPost, IPriorityRating } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function SinglePostPriorityRating({ post }: IPropTypes) {
  const t = useTranslations('post')
  const [isPriorityRating, setIsPriorityRating] = useState<boolean>(false)
  const priorityRatingSummary: IPriorityRating[] = Object.keys(
    t('priorityRatingSummary')
  ).map((key) => ({
    key,
    label: t(`priorityRatingSummary.${key}`),
  }))

  const handleRating = (priority: string) => {
    setIsPriorityRating(true)
    postStore
      .updatePriorityRating(post.slug, { priority })
      .then(() => {
        if (priority !== 'ignored') {
          toaster.success({ message: t('messages.priorityRating.success') })
        }
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsPriorityRating(false))
  }

  const renderRating = (rating: IPriorityRating, index: number) => {
    if (rating.key.toString().toLowerCase().trim() !== 'total') {
      return (
        <button
          type='button'
          key={index}
          disabled={isPriorityRating}
          onClick={() => handleRating(rating.key)}
          className='h-full w-full rounded-lg border border-gray5 bg-gray1 px-2 py-1 duration-200 hover:border-primary hover:bg-primary/10'
        >
          <p className='mb-0 text-sm text-gray12'>{rating.label}</p>
        </button>
      )
    }
    return false
  }

  return (
    <div className='rounded-md bg-primary/5 p-5'>
      <div className='relative flex items-center gap-1'>
        <Sparkle className='h-3.5 w-3.5 text-yellow8' weight='fill' />
        <p className='text-sm'>{t('labels.priorityRatingQuestion')}</p>
      </div>
      <div className='flex flex-col items-center gap-2 pt-3 md:flex-row'>
        {priorityRatingSummary.map(renderRating)}
      </div>
    </div>
  )
}
