import type { IPost } from '@/types/post'

export const DEFAULT_BUTTON_CLASS =
  'transition rounded p-1 flex text-gray9  hover:bg-gray4  items-center focus:bg-gray5  focus:outline-none disabled:opacity-50 disabled:pointer-events-none'
export const BUTTON_CLASS = `${DEFAULT_BUTTON_CLASS} bg-snow hover:bg-gray4 text-gray9    hover:text-gray9`
export const ACTIVE_CLASS = `${DEFAULT_BUTTON_CLASS}  text-gray9 bg-gray5 `

export const BUBBLE_BUTTON_DEFAULT_CLASS =
  'transition rounded p-1 focus:bg-gray5 mx-0.5 focus:outline-none disabled:opacity-50 disabled:pointer-events-none'

export const ICON_CLASS = 'h-4 w-4'
export const BUBBLE_BUTTON_CLASS = `${BUBBLE_BUTTON_DEFAULT_CLASS} bg-snow mx-0.5 hover:bg-gray4 text-gray9  hover:text-gray9`
export const BUBBLE_ACTIVE_CLASS = `${BUBBLE_BUTTON_DEFAULT_CLASS}  text-gray9 bg-gray5 `

export const YOUTUBE_VALIDATION_EXP =
  /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/
export const YOUTUBE_CONVERSION_EXP =
  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/

export const LOOM_VIDEO_VALIDATION_EXP =
  /^(http(s)?:\/\/)?((w){3}.)?loom?(\.com)?\/.+/
export const LOOM_VIDEO_CONVERSION_EXP = /^.*(loom.com\/share\/)([^#&?]*).*/

export const userMentionFilters = {
  privateComment: (orgId: string) => [[`member_organization_ids=${orgId}`]],
  publicComment: (orgId: string, post: IPost) => [
    [`bucket_members=${post.bucket_id}`, `organization_ids=${orgId}`],
  ],
}
export const postMentionFilters = {
  privateComment: (orgId: string) => [`member_organization_ids=${orgId}`],
  publicComment: (orgId: string) => [`organization_ids=${orgId}`],
}
