import type { NextRouter } from 'next/router'

import { DOMAIN, KB_DOMAIN } from '@/config/appConstants'
import type {
  IEmbedType,
  IOrganizationCustomization,
} from '@/types/organization'

import { buildURL, getBasePathPrefix, removeDuplicateSlash } from './urlHelpers'

// Auth
export const signinPath = '/auth/signin'
export const signupPath = '/auth/signup'
export const billingPath = '/admin/settings/billing'
export const expiredPath = '/admin/expired'
export const organizationEmbedPath = `/embed/home`
export const authRedirectingPath = '/user/redirecting'

// Admin
export const adminPath = '/admin'
export const embedPath = '/embed'
export const embedHomePath = '/embed/home'
export const postWidgetPath = '/postwidget'
export const submissionOnlyPath = '/submission_only'
export const changelogWidgetPath = '/changelogwidget'
export const submissionFormWithKey = '/submission_form_with_key'

// Error Paths
export const notFoundPath = '/404'
export const serverErrorPath = '/500'

export const pathCleaner = (path: string) => {
  // remove double slashes but not in https://
  return path.replace(/([^:]\/)\/+/g, '$1')
}

// User Side Module Paths
export const userSideModulePath = (
  module: string,
  defaultModule: string,
  embedPrefix?: string
) => {
  let path = ''
  switch (module) {
    case 'roadmap':
      path = defaultModule === 'roadmap' ? '/' : `/roadmap`
      if (embedPrefix?.length) path = `${embedPrefix}${path}`
      return path
    case 'changelog':
      path = defaultModule === 'changelog' ? '/' : `/changelog`
      if (embedPrefix?.length) path = `${embedPrefix}${path}`
      return path
    case 'knowledgeBase':
      path = defaultModule === 'knowledgeBase' ? '/' : `/kb`
      if (embedPrefix?.length) path = `${embedPrefix}${path}`
      return path
    case 'boards':
    default:
      path = defaultModule === 'feedback' ? '/' : `/b`
      if (embedPrefix?.length) path = `${embedPrefix}${path}`
      return path
  }
}

export const adminSidePostPath = (slug: string) => {
  return `/admin/p/${slug}`
}

export const userSidePostSinglePath = (slug: string, baseUrl?: string) => {
  return pathCleaner(`${baseUrl || ''}/p/${slug}`)
}

// Changelog
export const userSideChangelogSinglePath = (slug: string, baseUrl?: string) => {
  return removeDuplicateSlash(`${baseUrl || ''}/changelog/${slug}`)
}

export const userChangelogPath = (baseURL?: string) => {
  return removeDuplicateSlash(`${baseURL || ''}/changelog`)
}

export const userSideChangelogHomePath = (
  embedType: IEmbedType | null | undefined
) => {
  if (embedType === 'moduleEmbed') return `${getBasePathPrefix(embedType)}/c`
  if (embedType === 'orgEmbed')
    return `${getBasePathPrefix(embedType)}/changelog`
  if (embedType === 'changelogWidget')
    return `${getBasePathPrefix(embedType)}/changelog`
  return '/changelog'
}

export const userSideEmbedChangelogSinglePath = (
  slug: string,
  baseUrl?: string
) => {
  return `${baseUrl || ''}/c/${slug}`
}
export const userSideKBCategorySinglePath = (slug: string) =>
  `/kb/categories/${slug}`
export const userSideKBArticleSinglePath = (slug: string) =>
  `/kb/article/${slug}`
export const userSideBoardSinglePath = (slug: string, embedPrefix?: string) => {
  if (embedPrefix?.length) return `${embedPrefix}/b/${slug}`
  return `/b/${slug}`
}
export const userSideChangelogEmbedPath = `/embed/c`
// Org Settings
export const adminSideSettingPath = (module: string) =>
  !module.length ? `/admin/settings` : `/admin/settings/${module}`
export const adminSideGlobalBoardsPath = (module: string) =>
  module?.length ? `/admin/boards/${module}` : `/admin/boards`
export const adminSideSingleBoardsPath = (name: string) => `/admin/b/${name}`
export const adminSideSingleBoardModulePath = (name: string, module: string) =>
  `/admin/b/${name}/${module}`

// Admin Billing
export const adminSideBillingPath = (module: string) =>
  !module?.length
    ? `/admin/settings/billing`
    : `/admin/settings/billing/${module}`

// Admin Changelog
export const adminSideChangelogPath = (module: string) =>
  !module?.length ? `/admin/changelog` : `/admin/changelog/${module}`
export const adminSideSingleChangelogPath = (module: string) =>
  `/admin/c/${module}`

// Admin Roadmap
export const userSideRoadmapPath = (
  boardSlug: string,
  embedPrefix?: string
) => {
  if (embedPrefix)
    return !boardSlug.length
      ? `${embedPrefix}/roadmap`
      : `${embedPrefix}/roadmap/b/${boardSlug}`
  return !boardSlug.length ? `/roadmap` : `/roadmap/b/${boardSlug}`
}
export const adminSideRoadmapPath = (module: string) =>
  !module.length ? `/admin/r` : `/admin/r/${module}`
export const adminSideMultipleRoadmapPath = (module: string) =>
  !module.length ? `/admin/r/multiple` : `/admin/r/multiple/${module}`
export const userSideRoadmapSinglePath = (module: string) =>
  !module.length ? `/r` : `/r/${module}`

export const userSideRoadmapEmbedPath = (slug?: string) => {
  return slug ? `/embed/roadmap/r/${slug}` : `/embed/roadmap`
}
// Profile
export const userProfilePath = (module: string) => `/profile/${module}`
export const userNotificationsPath = (module?: string) =>
  module
    ? `${userProfilePath('notifications')}/${module}`
    : userProfilePath('notifications')

// Admin Posts
export const adminSideModerationPath = (module: string, state?: string) => {
  return state
    ? `/admin/moderation/${module}/${state}`
    : `/admin/moderation/${module}`
}

// Saved Filter
export const adminSideSingleSavedFilterPath = (id: string) =>
  `/admin/filters/${id}`

// Admin team
export const adminSideTeamPath = (module?: string) =>
  !module ? `/admin/settings/team` : `/admin/settings/team/${module}`

// Knowledge Base
export const userSideKnowledgeBasePath = (defaultModule: string) => {
  return defaultModule === 'knowledgeBase' ? '/' : `/kb`
}
export const adminSideKnowledgeBasePath = (module: string) =>
  !module.length ? `/admin/kb` : `/admin/kb/${module}`

export const adminSideKnowledgeCategoryPath = (slug: string) =>
  `/admin/kb/categories/${slug}`
export const adminSideKnowledgeBaseArticlePath = (slug: string) =>
  `/admin/kb/article/${slug}`

// Board

export const boardInviteLink = (
  homepage: string,
  name: string,
  key: string
) => {
  return `${homepage}b/${name}/invite/${key}`
}

// SSO Redirect Url
export const getSignInOrSSOPath = (
  organizationCustomization: IOrganizationCustomization,
  domain: string,
  redirectUrl: string
) => {
  if (organizationCustomization.sso_redirect_enabled) {
    return buildURL(organizationCustomization.sso_redirect_url, {
      domain,
      redirect: redirectUrl,
    })
  }

  if (organizationCustomization.open_id_enabled) {
    return buildURL(organizationCustomization.open_id_redirect_url, {
      domain,
      redirect: redirectUrl,
    })
  }

  return signinPath
}

// Custom Roadmap
export const customRoadmapAdminPath = (id: string, module?: string) => {
  return module ? `/admin/r/roadmap/${id}/${module}` : `/admin/r/roadmap/${id}`
}
export const customRoadmapUserPath = (
  id: string,
  module?: string,
  embedPrefix?: string
) => {
  const baseURL = module ? `/r/${id}/${module}` : `/r/${id}`
  return embedPrefix ? `${embedPrefix}${baseURL}` : baseURL
}

const KEYS_TO_PUSH = [
  'name',
  'id',
  'boardSlug',
  'filterId',
  'roadmapSlug',
  'notification_id',
  'notification_type',
  'feature_request_id',
  'bucket_ids',
  'tag_ids',
  'domains',
  'states',
  'query',
]
export const MANDATORY_KEYS_TO_PUSH = [
  'postModerationStatus',
  'postStatusPage',
  'singleBoardTabs',
  'commentModerationTab',
  'roadmapTab',
  'notificationsType',
]

export const pushQueryToMatchRoute = (postSlug: string, routerQuery: any) => {
  const keys = [`postSlug=${postSlug}`]
  Object.keys(routerQuery).forEach((key) => {
    if (KEYS_TO_PUSH.includes(key)) keys.push(`${key}=${routerQuery[key]}`)
  })

  return `?${[
    ...keys,
    ...MANDATORY_KEYS_TO_PUSH.map((key) => `${key}=''`),
  ].join('&')}`
}

export const redirectToHellonextSupport = () => {
  return `https://feedback.${DOMAIN}/b/Support`
}

export const generateHomePageUrl = (subDomain: string, params?: string) => {
  let url = `https://${subDomain}.${DOMAIN}`
  if (params?.length) url = `${url}/${params}`
  return url
}

export const checkRoadmapPath = (router: NextRouter) => {
  if (
    router.pathname === '/roadmap' ||
    router.pathname === '/roadmap/b/[boardSlug]' ||
    router.pathname === '/embed/roadmap' ||
    router.pathname === '/embed/roadmap/b/[boardSlug]' ||
    router.pathname === '/embed/home/roadmap' ||
    router.pathname === '/embed/home/roadmap/b/[boardSlug]'
  )
    return true
  return false
}

export const userSegmentPath = (segmentId: number) =>
  `/admin/people/user-segments/${segmentId}`
export const newKBPath = (organizationDomain: string) =>
  `https://${organizationDomain}.${KB_DOMAIN}`
