import { Plus } from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import AdminPostAssigneeFilter from '@/components/admin/posts/filters/AdminPostAssigneeFilter'
import AdminPostBoardFilter from '@/components/admin/posts/filters/AdminPostBoardFilter'
import AdminPostStatusFilter from '@/components/admin/posts/filters/AdminPostStatusFilter'
import AdminPostTagsFilter from '@/components/admin/posts/filters/AdminPostTagsFilter'
import AuthenticatedView from '@/components/auth/shared/AuthenticatedView'
import NewPostCustomFieldsForm from '@/components/posts/newPost/NewPostCustomFieldsForm'
import NewPostSuggestionsDialog from '@/components/posts/newPost/NewPostSuggestionsDialog'
import EditorAddExternalAttchments from '@/components/shared/components/attachments/EditorAddExternalAttchments'
import type { IEditorExternalAttchmentsListProps } from '@/components/shared/components/attachments/EditorExternalAttchmentsList'
import EditorExternalAttchmentsList from '@/components/shared/components/attachments/EditorExternalAttchmentsList'
import ConditonalWrapper from '@/components/shared/components/ConditonalWrapper'
import RichTextEditor from '@/components/shared/components/editor/RichTextEditor'
import FieldError from '@/components/shared/components/FieldBlock'
import SearchInput from '@/components/shared/components/SearchInput'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import DatePicker from '@/components/shared/ui/DatePicker'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Tooltip from '@/components/shared/ui/Tooltip'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import clsxm from '@/lib/clsxm'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import {
  cleanUserForSegment,
  recordEvent,
  stateKeyValueChange,
} from '@/lib/helpers/appHelpers'
import {
  getOpenIDInitiatePath,
  getSSOLoginPath,
} from '@/lib/helpers/authHelpers'
import { removeKeyFromObject } from '@/lib/helpers/dataHelpers'
import {
  convertToUnixTime,
  parseUnixTimeToDate,
} from '@/lib/helpers/dateHelpers'
import {
  defaultBoardInNewPost,
  doesBoardHasSubmissionTemplate,
  getNewPostMentions,
  handleNewPostErrors,
} from '@/lib/helpers/modules/postHelper'
import { userSidePostListSearch } from '@/lib/helpers/modules/searchHelper'
import { adminSideSingleBoardModulePath } from '@/lib/helpers/pathHelpers'
import {
  convertMarkdownLinksToText,
  isHTMLContentEmpty,
  templateStringReplace,
} from '@/lib/helpers/stringHelpers'
import { createPost } from '@/models/Post'
import postStore, { roadmapStores } from '@/stores/PostListStore'
import type { IBoardDetails } from '@/types/board'
import type { IEditorRef } from '@/types/editor'
import type { IOrganizationData } from '@/types/organization'
import type { INewPost, IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

import NewPostPostOnBehalf from './NewPostPostOnBehalf'

export interface INewPostButtonProps {
  boardId?: string
  children?: React.ReactNode
  onCreate?: (post: IPost) => void
  shortcutTriggered?: boolean
  isWidget?: boolean
  noTrigger?: boolean
  additionalFields?: {
    assignee?: boolean
    etc?: boolean
    status?: boolean
  }
  defaultData?: INewPost
  testId?: string
}

export interface INewPostModalActions {
  toggle: () => void
  setData: (data: Partial<INewPost>) => void
  setDescritpion: (description: string) => void
}

const NewPostButton = forwardRef(
  (
    {
      children,
      boardId,
      onCreate,
      shortcutTriggered,
      isWidget,
      noTrigger,
      additionalFields,
      defaultData = {},
      testId = 'new-post-button',
    }: INewPostButtonProps,
    ref: ForwardedRef<INewPostModalActions>
  ) => {
    const t = useTranslations('posts')
    const router = useRouter()
    const recaptchaRef = useRef<ReCAPTCHA>(null)
    const {
      organizationSetting,
      organizationPlan,
      global_submission_form,
      buckets = [],
      userProfile,
      organization,
      organizationCustomization,
      userSignedIn,
      pendingPostsCount,
      updateContext,
      custom_states,
      isAdmin,
      buckets_meta,
    } = useContext(HNContext)
    const [suggestions, setSuggestions] = useState<IPost[]>([])

    const defaultBucket = defaultBoardInNewPost({
      buckets,
      newPostData: {},
      globalSubmissionForm: removeKeyFromObject(['id'], global_submission_form),
      autoSelect: !!organizationSetting?.auto_select_bucket,
      boardId,
      userProfile,
    })
    const [errors, setErrors] = useState<Record<string, string>>({})

    const descriptionEditorRef = useRef<IEditorRef>(null)
    const [open, setOpen] = useState(false)
    const [createAnother, setCreateAnother] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [newPostData, setNewPostData] = useState<INewPost>({
      ...defaultData,
      ...{
        bucket_id: boardId || defaultBucket?.id,
        description_html: defaultBucket?.submission_template || '',
      },
    })
    const [addAsCustomer, setAddAsCustomer] = useState(false)

    const titleRef = useRef<any>(null)

    const selectedBoardRef = useRef<IBoardDetails>()

    const attachmentUploaderRef =
      useRef<IEditorExternalAttchmentsListProps>(null)

    const isProfilePage = router.pathname === '/profile'

    const activeBoard = useMemo<IBoardDetails | undefined>(() => {
      return defaultBoardInNewPost({
        buckets,
        newPostData,
        globalSubmissionForm: removeKeyFromObject(
          ['id'],
          global_submission_form
        ),
        autoSelect: !!organizationSetting?.auto_select_bucket,
        boardId,
        userProfile,
      })
    }, [boardId, buckets, newPostData.bucket_id, organizationSetting])
    const isGuest = useMemo<boolean>(
      () => Boolean(!userSignedIn && organizationSetting?.guest_submission),
      []
    )

    const previousActiveBoard = useRef<IBoardDetails | undefined>(activeBoard)

    const isBoardEmbed = useMemo<boolean>(
      () =>
        !!router.query?.boardSlug &&
        !!router.pathname.startsWith('/embed/b') &&
        !!activeBoard,
      [router.pathname, router.query?.boardSlug]
    )

    const setTemplateToEditor = (value?: string | null) => {
      descriptionEditorRef.current?.setValue(value || '')
    }

    const handleChange = (key: keyof INewPost, value: any) => {
      if (key === 'state_id') {
        const state = custom_states?.find((s) => s.slug === value)
        if (state) {
          return stateKeyValueChange(key, state.id, setNewPostData)
        }
      }
      return stateKeyValueChange(key, value, setNewPostData)
    }

    const handleConfirmBoardChange = () => {
      if (!selectedBoardRef.current) return
      handleChange('bucket_id', selectedBoardRef.current.id)
      setTemplateToEditor(selectedBoardRef.current?.submission_template)
    }

    const handleBoardChange = (selectedBoardId: string) => {
      const description = descriptionEditorRef.current?.getValue()
      const board = buckets.find((b) => b.id === selectedBoardId)
      const isBoardHasTemplate = doesBoardHasSubmissionTemplate(board)
      selectedBoardRef.current = board
      if (board?.private) setAddAsCustomer(false)
      if (
        board &&
        isBoardHasTemplate &&
        description &&
        !isHTMLContentEmpty(description)
      ) {
        setShowAlert(true)
      } else {
        handleChange('bucket_id', selectedBoardId)
        if (!isHTMLContentEmpty(board?.submission_template)) {
          setTemplateToEditor(board?.submission_template)
        }
      }
    }

    const resetFields = () => {
      setNewPostData({
        ...newPostData,
        title: '',
        description_html: activeBoard?.submission_template || '',
        tag_ids: [],
      })
      if (titleRef.current) {
        titleRef.current.value = ''
        titleRef.current.focus()
      }
      if (descriptionEditorRef.current && activeBoard?.submission_template) {
        descriptionEditorRef.current.setValue(activeBoard?.submission_template)
      } else {
        descriptionEditorRef.current?.setValue('')
      }
    }

    const handleClose = () => {
      recordEvent('NEW_POST_MODAL_CLOSED', { createAnother, skipSegment: true })
      setNewPostData({
        ...newPostData,
        title: '',
        description_html: activeBoard?.submission_template || '',
        tag_ids: [],
        email: '',
        name: '',
      })
      setAddAsCustomer(false)
      return setOpen(false)
    }

    const postOnBehalf = useMemo<boolean>(
      () => !!userProfile?.id && Boolean(newPostData.email),
      [newPostData]
    )

    const getPostOnBehalfErrorCode = () => {
      if (postOnBehalf && newPostData.email?.length) {
        if (userProfile?.is_admin_of_organization)
          return 'ERROR_CODE_USER_NOT_RECOGNIZED_AS_CUSTOMER'
        return 'ERROR_CODE_USER_NOT_RECOGNIZED_AS_CUSTOMER_BY_MANAGER'
      }
      return null
    }

    const getCustomFieldsErrorCodes = () => {
      if (!newPostData.bucket_id) return null

      const bucketMeta = buckets_meta?.find(
        (b) => b.bucket_id.toString() === newPostData.bucket_id?.toString()
      )

      if (!bucketMeta || !bucketMeta?.custom_fields.length) return null

      const customFields = bucketMeta?.custom_fields
        .map((field) => {
          const customField = newPostData.config?.[field.slug]
          if (field.mandatory && !customField?.value) {
            return field.slug
          }
          return null
        })
        .filter((field) => field !== null)

      if (!customFields.length) return null
      return customFields.reduce((acc, field) => {
        acc[field as string] = t('messages.fieldErrors.mandatory', {
          data: {
            field: convertMarkdownLinksToText(
              bucketMeta?.custom_fields.find((f) => f.slug === field)?.title ||
                field ||
                ''
            ),
          },
          ignorePrefix: true,
        })
        return acc
      }, {} as Record<string, string>)
    }

    const handleSubmit = () => {
      setErrors({})
      const customFieldsErrors = getCustomFieldsErrorCodes()
      if (customFieldsErrors) {
        return setErrors(customFieldsErrors)
      }
      const currentDescription = descriptionEditorRef.current?.getValue()
      if (!newPostData.title || !newPostData.title.length) {
        return setErrors({
          title: t('newPost.messages.titleMissing', {
            data: { title: activeBoard?.form_title },
          }),
        })
      }
      if (!newPostData.bucket_id) {
        return setErrors({
          bucket_id: templateStringReplace(t('newPost.messages.boardMissing'), {
            board: organizationSetting?.bucket_singular_name,
          }),
        })
      }
      if (!userProfile && (!newPostData.email || !newPostData.email?.length)) {
        return setErrors({
          email: t('newPost.messages.emailMissing'),
        })
      }
      if (
        activeBoard?.description_required &&
        isHTMLContentEmpty(currentDescription)
      ) {
        return setErrors({
          description_html: t('newPost.messages.descriptionMissing', {
            data: { field: activeBoard?.form_details },
          }),
        })
      }
      if (
        descriptionEditorRef.current &&
        !descriptionEditorRef.current?.isReadyToSubmit()
      ) {
        return toaster.info({
          message: t('messages.imagesUploading', { ignorePrefix: true }),
        })
      }
      if (attachmentUploaderRef.current?.getInProgressFiles().length) {
        return toaster.error({
          message: t('newPost.messages.uploadInProgress'),
        })
      }

      const postOnBehalfErrorCode = getPostOnBehalfErrorCode()
      setSubmitting(true)
      return createPost({
        ...newPostData,
        post_on_behalf: postOnBehalf,
        isAdmin: userProfile?.is_csm_of_organization,
        add_as_customer: addAsCustomer,
        description_html: currentDescription || '',
        external_files: attachmentUploaderRef.current
          ?.getAttachments()
          .map((a) => a.url as string),
      })
        .then((response) => {
          setAddAsCustomer(false)
          EventEmitter.dispatch('ONBOARDING_STEP_PERFORMED', {
            step: 'create_first_post',
          })
          recordEvent('NEW_POST_SUBMITTED', {
            createAnother,
            post_id: response.id,
            post_slug: response.slug,
            post_title: response.title,
            user: cleanUserForSegment(userProfile),
            postedOnBehalf: newPostData.email
              ? {
                  email: newPostData.email,
                  name: newPostData.name,
                }
              : undefined,
          })
          if (response.approval_status === 'approved') {
            EventEmitter.dispatch('ENTITY_UPDATE', {
              entity: 'POSTS',
              actionType: EVENT_ACTION_TYPES.ADD,
              data: {
                id: response.id,
                data: response,
              },
            })
            postStore.appendPost(response)
          }
          if (
            !postStore.globalFilters?.assignee_id &&
            response.approval_status === 'approved'
          ) {
            EventEmitter.dispatch('ENTITY_UPDATE', {
              entity: ENTITIES.POST_COUNTS,
              actionType: EVENT_ACTION_TYPES.ADD,
              fromTab: response.status.value,
            })
          }
          if (response.approval_status !== 'approved') {
            if (updateContext && pendingPostsCount)
              updateContext({
                pendingPostsCount: pendingPostsCount + 1,
              })
          }
          if (
            response.approval_status === 'approved' ||
            userProfile?.is_admin_of_organization
          ) {
            updateContext?.({
              buckets: buckets.map((b) => {
                return b.id === response.bucket_id
                  ? {
                      ...b,
                      feature_requests_count:
                        (b?.feature_requests_count || 0) + 1,
                    }
                  : b
              }),
            })
            toaster.success({
              message: t('newPost.messages.createdSuccessfully'),
            })
            roadmapStores[response.status.value]?.appendPost(response)
            if (
              onCreate &&
              typeof onCreate === 'function' &&
              router.pathname !== '/roadmap'
            )
              if (!createAnother) onCreate(response)
          } else {
            toaster.success({
              message: t('newPost.messages.submittedForModeration'),
            })
          }
          attachmentUploaderRef.current?.resetAttachments()
        })
        .then(() => {
          resetFields()
          if (!createAnother) handleClose()
        })
        .catch((err) => {
          const message = handleNewPostErrors(err.message, userProfile)
          recordEvent('NEW_POST_FAILED', {
            message: err.message,
            skipSegment: true,
          })
          toaster.error({ message: postOnBehalfErrorCode || message })
        })
        .finally(() => setSubmitting(false))
    }

    const handlePostSubmit: React.FormEventHandler<HTMLFormElement> = (
      e
    ): any => {
      e.preventDefault()
      return handleSubmit()
    }

    const handleShortcut = (eventData: any) => {
      if (eventData.action === 'CREATE' && shortcutTriggered) {
        setOpen(true)
      }
    }

    const handleOpen = () => {
      recordEvent('NEW_POST_MODAL_OPENED', {
        shortcutTriggered,
        isWidget,
        skipSegment: true,
      })
      if (!userProfile && !organizationSetting?.guest_submission) {
        if (organizationCustomization?.sso_redirect_enabled) {
          return router.push(
            getSSOLoginPath({
              domain: organization?.domain as string,
              redirect: window.location.href,
              sso_redirect_url: organizationCustomization.sso_redirect_url,
            })
          )
        }
        if (organizationCustomization?.open_id_enabled) {
          return router.push(
            getOpenIDInitiatePath({
              domain: organization?.domain as string,
              redirect: window.location.href,
              open_id_connect_redirect_url:
                organizationCustomization.open_id_redirect_url,
            })
          )
        }
        return EventEmitter.dispatch('CONFIG_PUSH', {
          action: 'TOGGLE_AUTH_MODAL',
        })
      }
      return setOpen(true)
    }

    useEffect(() => {
      if (shortcutTriggered)
        EventEmitter.subscribe('SHORTCUT_TRIGGERED', handleShortcut)
      return () => {
        if (shortcutTriggered)
          EventEmitter.unsubscribe('SHORTCUT_TRIGGERED', handleShortcut)
      }
    }, [])

    useEffect(() => {
      if (open) {
        setTimeout(() => {
          titleRef.current?.focus()
        }, 200)
      } else if (titleRef.current && !open) titleRef.current.blur()
      return () => {
        if (titleRef.current) titleRef.current.blur()
      }
    }, [open])

    useEffect(() => {
      if (boardId) {
        const selectedBoard = buckets.find(
          (b) => b?.id && b?.id.toString() === boardId.toString()
        )
        if (selectedBoard) {
          setTemplateToEditor(selectedBoard?.submission_template)
        }
        setNewPostData({
          ...newPostData,
          bucket_id: boardId,
          description_html: selectedBoard?.submission_template,
        })
      }
    }, [boardId])

    useEffect(() => {
      const autoSelectBucket = defaultBoardInNewPost({
        buckets,
        newPostData: {},
        globalSubmissionForm: removeKeyFromObject(
          ['id'],
          global_submission_form
        ),
        autoSelect: !!organizationSetting?.auto_select_bucket,
        boardId,
        userProfile,
      })
      setNewPostData(() => ({
        description_html: autoSelectBucket?.submission_template,
        bucket_id: autoSelectBucket?.id || undefined,
      }))
    }, [organizationSetting])

    useEffect(() => {
      if (
        previousActiveBoard.current?.submission_template !==
          activeBoard?.submission_template &&
        !!doesBoardHasSubmissionTemplate(activeBoard)
      ) {
        if (
          newPostData.description_html ===
          previousActiveBoard?.current?.submission_template
        ) {
          setNewPostData({
            ...newPostData,
            description_html: activeBoard?.submission_template,
          })
          setTemplateToEditor(activeBoard?.submission_template)
        }
      }
    }, [activeBoard])

    useImperativeHandle(ref, () => ({
      toggle: () => setOpen(!open),
      setData: (data: Partial<INewPost>) => {
        setNewPostData((oldData) => ({ ...oldData, ...data }))
      },
      setDescritpion: (description: string) => {
        descriptionEditorRef.current?.setValue(description)
      },
    }))

    const renderAddAsCustomerSection = () => {
      const privateBoardMembersTabUrl = adminSideSingleBoardModulePath(
        activeBoard?.name || '',
        'members'
      )
      if (!userProfile?.is_admin_of_organization) return null

      if (activeBoard?.private) {
        return (
          <div className='flex items-center gap-x-1'>
            <Typography.Text>
              {t('newPost.labels.privateBoardInviteText')}
            </Typography.Text>
            <UnstyledLink
              className='text-primary'
              href={privateBoardMembersTabUrl}
              target='_blank'
            >
              {t('common.here', { ignorePrefix: true })}
            </UnstyledLink>
          </div>
        )
      }
      if (organizationSetting?.organization_privacy)
        return (
          <Checkbox
            label={t('newPost.labels.addCustomer')}
            checked={addAsCustomer}
            data-testid='new-post-add-as-customer-checkbox'
            onCheckedChange={() => setAddAsCustomer(!addAsCustomer)}
          />
        )
      return null
    }

    const renderContent = (board: IBoardDetails) => (
      <>
        <FieldError
          error={errors.title}
          label={board.form_title}
          mandatory={true}
          rightComponent={
            <NewPostSuggestionsDialog suggestions={suggestions} />
          }
        >
          <SearchInput
            autoFocus={false}
            inputProps={{
              placeholder: board?.form_title_placeholder,
              dataTestId: 'new-post-title-input',
              size: 'md',
            }}
            hideIcon
            inputRef={titleRef}
            value={newPostData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('title', e.target.value)
            }
            onSearch={(results) => {
              setSuggestions(results?.[0]?.hits as unknown as IPost[])
            }}
            indexes={[
              {
                indexName: 'FeatureRequest',
                label: 'FeatureRequest',
                attributesToRetrieve: ['title', 'slug', 'id', 'description'],
                filters: userSidePostListSearch(
                  userProfile as IUserProfile,
                  buckets,
                  organization?.id || ''
                ),
              },
            ]}
          />
        </FieldError>
        <div
          className={clsxm('space-5 grid grid-cols-1 gap-5 md:grid-cols-2', {
            'md:grid-cols-1': !(
              organizationSetting?.allow_users_to_select_tags &&
              activeBoard &&
              !activeBoard.hide_tags
            ),
          })}
        >
          <FieldError
            error={errors.bucket_id}
            label={organizationSetting?.bucket_singular_name}
            mandatory={true}
          >
            <AdminPostBoardFilter
              filters={{ bucket_id: newPostData.bucket_id }}
              itemFilter={(item: IBoardDetails) =>
                !item.read_only || !!userProfile?.is_admin_of_organization
              }
              onChange={(_key, value) =>
                handleBoardChange(value && value.length ? value[0] : null)
              }
              placeholder={t('newPost.boardSearch.placeholder')}
              behaviour='select'
              disabled={
                submitting || Boolean(boardId && isWidget) || isBoardEmbed
              }
              hidePlaceholder
              selectProps={{
                dataTestId: 'new-post-board-select',
                className: '!py-2 !rounded-lg',
              }}
            />
          </FieldError>
          <AuthenticatedView
            shouldShow={Boolean(
              organizationSetting?.allow_users_to_select_tags &&
                activeBoard &&
                !activeBoard.hide_tags
            )}
          >
            <div className='group relative space-y-1'>
              <Label>{t('moduleLabels.tags')}</Label>
              <AdminPostTagsFilter
                multiple
                filters={{ bucket_id: newPostData.bucket_id, board: true }}
                onChange={(_label: any, value: string[]) => {
                  handleChange('tag_ids', value)
                }}
                placeholder={t('newPost.tags.placeholder')}
                behaviour='filter'
                selectProps={{
                  dataTestId: 'new-post-tag-select',
                  className: '!py-2 !rounded-lg',
                }}
              />
            </div>
          </AuthenticatedView>
        </div>

        <FieldError
          error={errors.description_html}
          label={board.form_details}
          mandatory={board.description_required}
        >
          <RichTextEditor
            height='small'
            ref={descriptionEditorRef}
            // onChange={(value) => handleChange('description_html', value)}
            placeholder={board.form_details_placeholder}
            defaultValue={newPostData.description_html}
            showBubbleMenu={false}
            resource={{
              type: 'FeatureRequest',
            }}
            support={{
              video: true,
            }}
            mentionsConfig={[
              {
                indexName: 'FeatureRequest',
                filter: getNewPostMentions(
                  userProfile as IUserProfile,
                  buckets,
                  organization as IOrganizationData
                ),
              },
            ]}
          />
        </FieldError>
        {!userProfile && organizationSetting?.guest_submission && (
          <>
            <FieldError
              error={errors.email}
              label={t('newPost.postOnBehalf.email.label')}
              mandatory={true}
            >
              <Input
                placeholder={t('newPost.postOnBehalf.email.placeholder')}
                value={newPostData.email}
                dataTestId='new-post-email-input'
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange('email', e.target.value)
                }}
              />
            </FieldError>
            {organizationSetting?.post_captcha_verification && (
              <div className='w-full'>
                <ReCAPTCHA
                  id='post-recaptcha'
                  size='normal'
                  ref={recaptchaRef}
                  sitekey={String(process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY)}
                  onChange={(value) => handleChange('captcha', value)}
                />
              </div>
            )}
          </>
        )}
        {additionalFields?.assignee && (
          <AdminPostAssigneeFilter
            size='md'
            filters={{ assignee_id: newPostData.assignee_id }}
            onChange={(_key, value) => {
              const assignee =
                value && Array.isArray(value) && value.length ? value[0] : null
              handleChange('assignee_id', assignee)
            }}
            multiple={false}
            behaviour='filter'
            placeholder='Select Assignee'
            clearable
          />
        )}
        {additionalFields?.status && (
          <AdminPostStatusFilter
            size='md'
            behaviour='filter'
            filters={{ state: newPostData.status }}
            onChange={(_key, value) => {
              const status = value && Array.isArray(value) ? value[0] : null
              handleChange('state_id', status)
            }}
            placeholder='Select Status'
            multiple={false}
          />
        )}
        {additionalFields?.etc && (
          <DatePicker
            size='md'
            mode='single'
            numberOfMonths={1}
            onDaySelect={(date) =>
              handleChange('etc_date', convertToUnixTime(date))
            }
            placeholder='Select ETC'
            range={{
              from: newPostData.etc_date
                ? parseUnixTimeToDate(newPostData.etc_date)
                : undefined,
              to: undefined,
            }}
            showInPopOver
            popoverContentProps={{
              align: 'start',
            }}
          />
        )}
        <NewPostCustomFieldsForm
          bucketId={activeBoard?.id}
          onChange={(key, value) => {
            const newData = {
              ...newPostData.config,
            }
            newData[key] = {
              value,
            }
            handleChange('config', newData)
          }}
          errors={errors}
          values={newPostData.config}
        />

        <div className='group relative space-y-2'>
          <NewPostPostOnBehalf
            board={activeBoard}
            onUpdate={handleChange}
            newPostData={newPostData}
          />
          <div>{renderAddAsCustomerSection()}</div>
        </div>
        <EditorExternalAttchmentsList
          defaultAttachments={newPostData.attachments || []}
          ref={attachmentUploaderRef}
          resourceType='FeatureRequest'
          onUpdate={(attachments) => {
            handleChange('attachments', attachments)
          }}
        />

        <AlertDialog
          open={showAlert}
          title={t('newPost.boardChangeAlert.title')}
          description={t('newPost.boardChangeAlert.description')}
          onConfirm={handleConfirmBoardChange}
          onCancel={() => setShowAlert(false)}
          onClose={() => setShowAlert(false)}
        />
      </>
    )

    if (
      !activeBoard ||
      isProfilePage ||
      (activeBoard.read_only && !userProfile?.is_admin_of_organization)
    )
      return <></>

    if (isWidget && !userProfile && !organizationSetting?.guest_submission)
      return <></>

    // if (
    //   organizationPlan?.posts_limit_reached &&
    //   !userProfile?.is_csm_of_organization
    // )
    //   return <></>
    return (
      <>
        <Dialog open={open} modal={true} onClose={() => handleClose()}>
          <DialogContent size='md' backdrop className='max-h-[90vh] !p-0'>
            <DialogClose />
            <div className='space-y-1 border-b border-gray7 px-4 py-2'>
              <DialogTitle>{activeBoard?.form_heading}</DialogTitle>
              {!!activeBoard?.form_description && (
                <DialogDescription>
                  {activeBoard?.form_description}
                </DialogDescription>
              )}
            </div>
            <form
              onSubmit={handlePostSubmit}
              className='flex flex-1 flex-col overflow-hidden'
            >
              <div className='overflow-y-auto'>
                <div className='flex-1 space-y-4 px-6 py-2'>
                  {renderContent(activeBoard)}
                </div>
              </div>
              <span className='flex items-center justify-between gap-3 border-t border-gray7 bg-gray5 p-2 pt-2 only:justify-end'>
                <div>
                  {userProfile?.is_csm_of_organization && (
                    <Checkbox
                      label={t('newPost.labels.createAnother')}
                      checked={createAnother}
                      data-testId='new-post-create-another-checkbox'
                      onCheckedChange={() => setCreateAnother(!createAnother)}
                    />
                  )}
                </div>

                <span className='flex items-center space-x-2'>
                  <EditorAddExternalAttchments
                    onAdd={(files) =>
                      attachmentUploaderRef.current?.attachFiles(files)
                    }
                    buttonSize='sm'
                  />
                  <Button
                    size='sm'
                    dataTestId='new-post-submit-button'
                    disabled={
                      submitting ||
                      (organizationSetting?.post_captcha_verification &&
                        isGuest &&
                        !newPostData.captcha)
                    }
                    loading={submitting}
                    type='submit'
                  >
                    {activeBoard.submit_button_text}
                  </Button>
                </span>
              </span>
            </form>
          </DialogContent>
        </Dialog>
        {!!(!shortcutTriggered && !noTrigger) && (
          <>
            {children ? (
              <div onClick={handleOpen}>{children}</div>
            ) : (
              <ConditonalWrapper
                condition={!!organizationPlan?.posts_limit_reached}
                wrapper={(tChildren) => {
                  return (
                    <Tooltip
                      text={t(
                        `errors.${
                          userProfile?.is_admin_of_organization
                            ? 'ERROR_CODE_POSTS_LIMIT_REACHED_ADMIN'
                            : 'ERROR_CODE_POSTS_LIMIT_REACHED'
                        }`,
                        {
                          ignorePrefix: true,
                        }
                      )}
                    >
                      {tChildren}
                    </Tooltip>
                  )
                }}
              >
                <Button
                  size='xs'
                  variant={isAdmin ? 'default' : 'primary'}
                  dataTestId={testId}
                  onClick={handleOpen}
                  icon={<Plus size={16} />}
                  disabled={organizationPlan?.posts_limit_reached}
                >
                  {activeBoard?.submit_button_text}
                </Button>
              </ConditonalWrapper>
            )}
          </>
        )}
      </>
    )
  }
)

NewPostButton.displayName = 'NewPostButton'
export default NewPostButton
