import { INTEGRATION_MAPPING } from '@/config/module/integrationConstants'
import type {
  IAdminAnalyticsIntegrationMapping,
  IAdminChatWidgetIntegrationMapping,
  IAdminIntegrationMapping,
  IAdminLinkIntegrationMapping,
  IAdminPatreonIntegrationMapping,
  IAdminWithSubdomainIntegrationMapping,
  IChatWidgetIntegrationInfo,
  IChatWidgetPlatformInfo,
  IIntegrationPlatforms,
  IIntegrationsInfo,
} from '@/types/integration'
import type { IAdminAsanaIntegrationMapping } from '@/types/integration/asana'
import type { IAdminAzureAdIntegrationMapping } from '@/types/integration/azure_ad'
import type { IAdminClickUpIntegrationMapping } from '@/types/integration/click_up'
import type { IAdminDiscordIntegrationMapping } from '@/types/integration/discord'
import type { IAdminGithubIntegrationMapping } from '@/types/integration/github'
import type { IAdminGitlabIntegrationMapping } from '@/types/integration/gitlab'
import type { IAdminJiraIntegrationMapping } from '@/types/integration/jira'
import type { IAdminLinearIntegrationMapping } from '@/types/integration/linear'
import type { IAdminSalesforceIntegrationMapping } from '@/types/integration/salesforce'
import type { IAdminSlackIntegrationMapping } from '@/types/integration/slack'

export const getChatWidgetInfo = (integrations: IIntegrationsInfo) => {
  return {
    belco_app_id: integrations.belco_app_id,
    crisp_chat_app_id: integrations.crisp_chat_app_id,
    drift_app_id: integrations.drift_app_id,
    freshchat_app_id: integrations.freshchat_app_id,
    helpscout_app_id: integrations.helpscout_app_id,
    hub_spot_app_id: integrations.hub_spot_app_id,
    tidio_app_id: integrations.tidio_app_id,
    trengo_app_id: integrations.trengo_app_id,
    intercom_app_id: integrations.intercom_app_id,
  }
}

export const getSeletcedChatWidgetKey = (
  chatWidgetInfo: IChatWidgetIntegrationInfo
) => {
  const keys = Object.keys(chatWidgetInfo)
  const selectedKey = keys.find(
    (key) => chatWidgetInfo[key] && chatWidgetInfo[key] !== ''
  )
  return selectedKey
}

export const getSeletcedChatWidget = (
  widgets: IChatWidgetPlatformInfo[],
  chatWidgetInfo: IChatWidgetIntegrationInfo
) => {
  return widgets.find(
    (widget) => widget.value === getSeletcedChatWidgetKey(chatWidgetInfo)
  )
}

export const getIntegrationInfo = (
  platform: IIntegrationPlatforms | string
) => {
  switch (platform) {
    case 'jira':
      return INTEGRATION_MAPPING.jira as IAdminJiraIntegrationMapping
    case 'click_up':
      return INTEGRATION_MAPPING.click_up as IAdminClickUpIntegrationMapping
    case 'azure_ad':
      return INTEGRATION_MAPPING.azure_ad as IAdminAzureAdIntegrationMapping
    case 'fullstory_analytics':
      return INTEGRATION_MAPPING.fullstory_analytics as IAdminAnalyticsIntegrationMapping
    case 'segment_analytics':
      return INTEGRATION_MAPPING.segment_analytics as IAdminAnalyticsIntegrationMapping
    case 'google_analytics':
      return INTEGRATION_MAPPING.google_analytics as IAdminAnalyticsIntegrationMapping
    case 'plausible_analytics':
      return INTEGRATION_MAPPING.plausible_analytics as IAdminAnalyticsIntegrationMapping
    case 'fathom_analytics':
      return INTEGRATION_MAPPING.fathom_analytics as IAdminAnalyticsIntegrationMapping
    case 'patreon':
      return INTEGRATION_MAPPING.patreon as IAdminPatreonIntegrationMapping
    case 'chat_widget':
      return INTEGRATION_MAPPING.chat_widget as IAdminChatWidgetIntegrationMapping
    case 'intercom':
      return INTEGRATION_MAPPING.intercom as IAdminIntegrationMapping
    case 'linear':
      return INTEGRATION_MAPPING.linear as IAdminLinearIntegrationMapping
    case 'slack':
      return INTEGRATION_MAPPING.slack as IAdminSlackIntegrationMapping
    case 'zendesk':
      return INTEGRATION_MAPPING.zendesk as IAdminWithSubdomainIntegrationMapping
    case 'zapier':
      return INTEGRATION_MAPPING.zapier as IAdminLinkIntegrationMapping
    case 'github':
      return INTEGRATION_MAPPING.github as IAdminGithubIntegrationMapping
    case 'salesforce':
      return INTEGRATION_MAPPING.salesforce as IAdminSalesforceIntegrationMapping
    case 'discord':
      return INTEGRATION_MAPPING.discord as IAdminDiscordIntegrationMapping
    case 'asana':
      return INTEGRATION_MAPPING.asana as IAdminAsanaIntegrationMapping
    case 'heap_analytics':
      return INTEGRATION_MAPPING.heap_analytics as IAdminAsanaIntegrationMapping
    case 'gitlab':
      return INTEGRATION_MAPPING.gitlab as IAdminGitlabIntegrationMapping
    default:
      return null
  }
}
