// TODO Need to Update icon for activity type testing icon and color only here need to update actual

import {
  ChatCircle,
  ChatText,
  CheckCircle,
  Clock,
  Eye,
  GitMerge,
  PlusCircle,
  PushPinSlash,
  Robot,
  Tag,
  UserPlus,
  UserSwitch,
} from '@phosphor-icons/react'

export const ACTIVITY = {
  submitted: {
    icon: PlusCircle,
    color: 'text-green8',
  },
  commented: {
    icon: ChatCircle,
    color: 'text-zinc7',
  },
  status_changed: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  assigned: {
    icon: UserPlus,
    color: 'text-purple8',
  },
  tag_added: {
    icon: Tag,
    color: 'text-yellow8',
  },
  tag_removed: {
    icon: Tag,
    color: 'text-blue8',
  },
  merged: {
    icon: GitMerge,
    color: 'text-yellow8',
  },
  unmerged: {
    icon: GitMerge,
    color: 'text-yellow8',
  },
  commented_internally: {
    icon: ChatCircle,
    color: 'text-yellow8',
  },
  title_changed: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  description_changed: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  pinned: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  unpinned: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  approved: {
    icon: CheckCircle,
    color: 'text-green8',
  },
  pending: {
    icon: UserSwitch,
    color: 'text-blue8',
  },
  disabled_comments: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  enabled_comments: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  bucket_changed: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  feature_request_hidden: {
    icon: Eye,
    color: 'text-blue8',
  },
  feature_request_unhidden: {
    icon: Eye,
    color: 'text-blue8',
  },
  feature_request_etc_scheduled: {
    icon: Clock,
    color: 'text-blue8',
  },
  changelog_created: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  changelog_published: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  changelog_unpublished: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  changelog_description_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  changelog_category_added: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  changelog_category_removed: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  changelog_feature_request_added: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  changelog_feature_request_removed: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_allow_admin_emails_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_allow_downvote_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_allow_guest_submission_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_allow_guest_votes_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_allow_users_to_select_tags_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_api_key_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_auto_select_bucket_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_auto_upvote_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_avatar_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_changelog_partial_privacy_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_comments_moderation_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_enable_kanban_roadmap_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_hide_assignee_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_hide_completed_feature_requests_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_hide_tags_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_locale_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_mask_user_details_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_moderation_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_notify_vote_on_behalf_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_organization_privacy_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_partial_mask_user_details_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_post_captcha_verification_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_priority_rating_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_roadmap_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_search_privacy_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_sso_token_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_user_notification_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_widget_key_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_brand_color_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_custom_about_us_url_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_custom_contact_us_url_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_custom_privacy_policy_url_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_custom_support_url_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_default_sort_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_display_branded_content_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_email_redirect_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_email_redirect_url_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_enable_max_votes_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_favicon_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_max_votes_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_max_votes_period_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_og_image_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_return_url_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_roadmap_default_sort_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_sso_redirect_enabled_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_sso_redirect_url_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_customization_whitelist_domain_config_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_display_name_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  webhook_created: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  webhook_deleted: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  webhook_events_added: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  webhook_events_removed: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  webhook_url_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  webhook_description_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_changelog_privacy_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_roadmap_privacy_updated: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  tag_created: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  tag_deleted: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  organization_setting_toggled: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  bucket_setting_toggled: {
    icon: PushPinSlash,
    color: 'text-blue8',
  },
  feature_request_updated: {
    icon: ChatText,
    color: 'text-blue8',
  },
  ai: {
    icon: Robot,
    color: 'text-grass9',
  },
  user: {
    icon: UserPlus,
    color: 'text-purple8',
  },
}
