import type { Editor } from '@tiptap/core'
import { mergeAttributes, Node } from '@tiptap/core'
import { BubbleMenu } from '@tiptap/react'
import clsx from 'clsx'

export interface CalloutOptions {
  HTMLAttributes: Record<string, any>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    callout: {
      /**
       * Toggle a callout
       */
      setCallout: () => ReturnType
      toggleCallout: () => ReturnType
    }
  }
}

export const CalloutExtension = Node.create<CalloutOptions>({
  name: 'callout',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  addAttributes() {
    return {
      class: {
        default: 'callout',
      },
    }
  },

  group: 'block',

  content: 'inline*',

  parseHTML() {
    return [{ tag: 'div', class: 'callout' }]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ]
  },

  addCommands() {
    return {
      setCallout:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name)
        },

      toggleCallout:
        () =>
        ({ commands, editor }) => {
          if (editor.isActive('callout')) {
            return commands.setNode('paragraph')
          }
          return commands.setNode('callout')
        },
    }
  },
})

const colors: {
  name: string
  class: string
  previewClass: string
}[] = [
  {
    name: 'Blue',
    class: '',
    previewClass: '!bg-blue6',
  },
  {
    name: 'Green',
    class: 'green',
    previewClass: '!bg-green6',
  },
  {
    name: 'Yellow',
    class: 'yellow',
    previewClass: '!bg-orange6',
  },
  {
    name: 'Red',
    class: 'red',
    previewClass: '!bg-red6',
  },
]

interface CalloutBubbleMenuProps {
  editor: Editor
}

export const CalloutBubbleMenu: React.FC<CalloutBubbleMenuProps> = ({
  editor,
}) => {
  const setColor = (color: string) => {
    editor
      .chain()
      .focus()
      .setNode('callout', { class: `callout ${color}` })
      .run()
  }

  return (
    <BubbleMenu
      className={clsx(
        'absolute flex items-center justify-start gap-2 overflow-hidden rounded-full border border-gray7 bg-gray1 px-2 py-0.5 shadow-sm focus:outline-none'
        // {
        //   hidden: !editor.isActive('callout')
        // }
      )}
      tippyOptions={{
        duration: 100,
        maxWidth: 'none',
        popperOptions: { strategy: 'fixed' },
        placement: 'bottom',
      }}
      shouldShow={() => editor.isActive('callout')}
      editor={editor}
    >
      {colors.map((color) => (
        <button
          key={color.name}
          className={clsx('my-1 h-4 w-4 rounded-full', color.previewClass)}
          onClick={() => setColor(color.class)}
          title={color.name}
          aria-label={color.name}
          type='button'
          tabIndex={-1}
        ></button>
      ))}
    </BubbleMenu>
  )
}
