import { type NextRouter, useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import HNContext from '@/context/HNContext'
import { recordView } from '@/models/Organization'

const pathParser = (router: NextRouter) => {
  const { pathname, query } = router

  if (pathname.startsWith('/changelog/[changelogSlug')) {
    const { changelogSlug } = query as { changelogSlug: string }
    return {
      resource_model: 'Changelog',
      resource_slug: changelogSlug,
      language: 'en',
    }
  }
  return null
}

export default function PageViewAnalytics() {
  const router = useRouter()
  const { organization } = useContext(HNContext)

  const logInitialView = () => {
    if (!organization) return Promise.resolve(null)
    try {
      const referror = document.referrer
      const { asPath } = router
      const data = pathParser(router)
      if (!data) return null

      const { resource_model, resource_slug, language } = data
      const timezone = new Date().getTimezoneOffset()
      if (!resource_slug && resource_model === 'Changelog')
        return Promise.resolve(null)
      return recordView({
        referrer: referror,
        resource_model,
        resource_slug: resource_slug || organization.name,
        language,
        path: asPath,
        timezone: timezone || 'UTC',
      })
    } catch (error) {
      console.error(error)
    }
    return null
  }

  useEffect(() => {
    try {
      logInitialView()
    } catch (error) {
      console.error(error)
    }
  }, [router.pathname])

  return <></>
}
