import AwsS3 from '@uppy/aws-s3'
import type { UppyFile } from '@uppy/core'
import Uppy from '@uppy/core'
import type { Restrictions } from '@uppy/core/lib/Restricter'

import {
  generateAttachmentUploadParams,
  processCompletedUpload,
} from '@/models/Common'
import type { IResourceTypes } from '@/types/common'
import type { IAttachment } from '@/types/organization'

type Meta = any

export interface IUppyHandlerFunctionParams extends IUppyParams {
  file: File
}

export interface IUppyParams {
  autoProceed?: boolean
  restrictions?: Restrictions
  kind: 'inline_videos' | 'external_files' | 'inline_images'
  resource_type?: IResourceTypes
  resource_id?: string | number
  is_editor_attachment?: boolean
  onComplete: (result: {
    success: boolean
    url?: string
    attachment?: IAttachment
    error?: string
    fileId?: string
  }) => void
  onProgress?: (
    progress: UppyFile<Meta, any>['progress'] & {
      fileId: string
    }
  ) => void
  onAdd?: (params: { file: IAttachment; fileId: string }) => void
}
export interface IUppyFile extends UppyFile<Meta, any> {}

export interface IUppyResponse {
  status: number
  body?: any
  uploadURL?: string
}

export const getUppy = ({
  onComplete,
  onProgress,
  onAdd,
  autoProceed,
  kind,
  restrictions,
  ...options
}: IUppyParams) => {
  const uppy = new Uppy<Meta, any>({
    meta: {
      kind,
    },
    autoProceed,
    restrictions,
  })
  uppy
    .use(AwsS3, {
      id: 's3',
      shouldUseMultipart: () => false,
      getUploadParameters: (file: UppyFile<Meta, any>) => {
        return generateAttachmentUploadParams({
          file_name: file.name || '',
          extension: file.extension,
          is_editor_attachment: options.is_editor_attachment,
        })
      },
      createMultipartUpload: async (_file: UppyFile<Meta, any>) => {
        return Promise.resolve({
          uploadId: '123',
          key: '123',
        })
      },
      listParts: async (_file: UppyFile<Meta, any>, _opts: any) => {
        return Promise.resolve([])
      },
      signPart: async (_file: UppyFile<Meta, any>, _partData: any) => {
        return Promise.resolve({
          url: '',
          method: 'PUT',
          fields: {},
          headers: {},
        })
      },
      abortMultipartUpload: async (_file: UppyFile<Meta, any>, _opts: any) => {
        return Promise.resolve()
      },
      completeMultipartUpload: async (
        _file: UppyFile<Meta, any>,
        _parts: any
      ) => {
        return Promise.resolve({
          location: '',
        })
      },
    })
    .on('file-added', (file: UppyFile<Meta, any>) => {
      onAdd?.({
        file: {
          name: file.name || '',
          size: file.size || 0,
          type: file.type,
          progress: 0,
          url: '',
          id: file.id,
          bytesTotal: file.size || 0,
          bytesUploaded: 0,
        },
        fileId: file.id,
      })
    })
    .on('upload-success', (file?: IUppyFile, response?: IUppyResponse) => {
      processCompletedUpload({
        resource_id: options.resource_id,
        resource_type: options.resource_type,
        kind,
        size: file?.size || 0,
        file_path: response?.uploadURL || '',
        original_filename: file?.name || '',
      })
        .then((attachment) => {
          onComplete?.({
            success: true,
            url: attachment.url,
            attachment: attachment as IAttachment,
            fileId: file?.id,
          })
        })
        .catch((error) => {
          onComplete?.({
            success: false,
            error: error.message,
            fileId: file?.id,
          })
        })
      // Remove the file from the list
      if (file?.id) uppy.removeFile(file.id)
      return true
    })
    .on('upload-error', (file?: IUppyFile, error?: any) => {
      onComplete?.({
        success: false,
        error: error.message,
        fileId: file?.id,
      })
    })
    .on('upload-progress', (file?: IUppyFile) => {
      if (file)
        onProgress?.({
          ...file.progress,
          fileId: file.id,
        })
    })
  return uppy
}
