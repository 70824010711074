import type { SeverityLevel } from '@sentry/react'
import * as Sentry from '@sentry/react'
import { Crisp } from 'crisp-sdk-web'
import type { IncomingMessage } from 'http'
import type { NextApiRequest } from 'next'
import url from 'url'

import { DOMAIN } from '@/config/appConstants'
import {
  getLocalStorageData,
  POSTS_HIGHLIGHT_KEY,
  setLocalStorageData,
} from '@/lib/helpers/localStorageHelper'
import { getUserRole } from '@/lib/helpers/modules/userHelper'
import { getHost } from '@/lib/helpers/urlHelpers'
import type { IKeyValueMap, IMetricEvents } from '@/types/common'
import type {
  IEmbedType,
  IOrganizationData,
  IOrganizationSetting,
  IRootData,
} from '@/types/organization'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

export const getDomain = (host: string): string =>
  url.parse(host).hostname || ''

export const getSubdomain = (host: string) => {
  const domainSplit: string[] = (host || '').split('.')
  let subdomain: string = 'portal'
  let maindomain: string = host
  if (
    host &&
    !host.includes(process.env.NEXT_PUBLIC_HOSTED_DOMAIN || '') &&
    !host.includes(process.env.NEXT_PUBLIC_HOSTED_DOMAIN_OLD || '') &&
    process.env.NODE_ENV === 'production'
  ) {
    subdomain = host
    maindomain = host
  } else if (domainSplit.length > 2) {
    const [firstItem] = domainSplit
    subdomain = String(firstItem)
    maindomain = domainSplit
      .slice(0)
      .slice(-(domainSplit.length === 4 ? 3 : 2))
      .join('.')
  }
  return { subdomain, maindomain, isPresent: domainSplit.length > 2 }
}

export const stateKeyValueChange = (
  key: string,
  value: any,
  stateSetter: (_prevState: any) => void
) => {
  stateSetter((_prevState: any) => ({
    ..._prevState,
    [key]: value,
  }))
}
export const stateMerge = (
  target: any,
  stateSetter: (_prevState: any) => void
) => {
  stateSetter((_prevState: any) => ({
    ..._prevState,
    ...target,
  }))
}

export const copyClipboard = (
  text: string,
  translation: string
): Promise<any> => {
  const defaultCopyHandler = (): any => {
    const span = document.createElement('span')
    span.textContent = text
    span.style.whiteSpace = 'pre'
    span.style.position = 'absolute'
    span.style.left = '-9999px'
    document.body.appendChild(span)
    const selection = window.getSelection()
    if (!selection) return Promise.reject()
    const range = window.document.createRange()
    selection.removeAllRanges()
    range.selectNode(span)
    selection.addRange(range)
    window.document.execCommand('copy')
    selection.removeAllRanges()
    window.document.body.removeChild(span)
    return toaster.success({ message: translation })
  }
  if (!navigator.clipboard) {
    defaultCopyHandler()
    return Promise.resolve()
  }
  return navigator.clipboard
    .writeText(text)
    .then(() => {
      return toaster.success({ message: translation })
    })
    .catch(() => {
      defaultCopyHandler()
    })
}

export const isPromise = (promise: any) =>
  !!promise && typeof promise.then === 'function'

export const getDimensions = {
  height: (offset = 0) => window.innerHeight + offset,
  width: (offset = 0) => window.innerWidth + offset,
}

export const pluralize = (value: number, singular: string, plural: string) =>
  value > 1 || value === 0 ? plural : singular

export const stopPropagation = (event: any) => event.stopPropagation()

export const routerPathQueries = (path: string, queryObject: IKeyValueMap) => {
  const regex = /(\[)([^{}]+)(\])/g
  // @ts-ignore
  const keys = [...path.matchAll(regex)].map((item) => item[2])
  const values: IKeyValueMap = {}
  Object.keys(queryObject).forEach((key) => {
    if (keys.includes(key)) {
      values[key] = queryObject[key]
    }
  })
  return values
}

export const getEmbedsContextData = (data: {
  pathname: string
  asPath: string
  query: any
}): {
  embedType: IEmbedType | null
  noHeader: boolean
} => {
  const { pathname, query } = data
  let embedType = null
  const noHeader = query.no_header === 'true' && pathname.includes('/embed/')
  if (pathname.includes('/embed/home')) embedType = 'orgEmbed'
  else if (pathname.includes('/embed/')) embedType = 'moduleEmbed'
  else if (pathname.includes('/widget/post')) embedType = 'postWidget'
  else if (pathname.includes('/widget/changelog')) embedType = 'changelogWidget'
  return { embedType, noHeader } as {
    embedType: IEmbedType | null
    noHeader: boolean
  }
}

export const sentry = {
  addInfo: ({
    userProfile,
    organization,
    organizationSetting,
  }: {
    userProfile: IUserProfile
    organization: IOrganizationData
    organizationSetting: IOrganizationSetting
  }) => {
    Sentry.setUser({
      email: userProfile?.email,
      id: userProfile?.id,
    }) // identity of user
    Sentry.setContext('Role', {
      // role of user
      is_admin: userProfile?.is_admin_of_organization,
      is_interactor: !userProfile?.is_organization_member,
      is_member: userProfile?.is_organization_member,
      locale: organizationSetting.locale,
      organization: organization.domain,
    })
  },
  log: (
    category = 'javascript',
    message = 'Error',
    level: SeverityLevel = 'debug',
    data = {}
  ) => {
    Sentry.addBreadcrumb({
      category,
      message,
      level,
      data,
    })
  },
  exception: (err: Error) => Sentry.captureException(err),
}

export const getPostHighlightFlag = () =>
  !getLocalStorageData(POSTS_HIGHLIGHT_KEY)
export const setPostHighlightFlag = (state: boolean) =>
  setLocalStorageData(POSTS_HIGHLIGHT_KEY, state)

export const recordEvent = async (
  event: IMetricEvents,
  data: {
    [key: string]: any
    skipSegment?: boolean
  }
) => {
  try {
    const segmentAttributesToSkip = ['pathname']
    const posthog = (await import('posthog-js')).default
    posthog.capture(event, data)

    if (window && window.umami) {
      window.umami.track(event, data)
    }

    if (typeof Crisp !== 'undefined') {
      try {
        const isCrispLoaded = Crisp.session.getIdentifier()
        if (isCrispLoaded) {
          Crisp.session.pushEvent(event, data)
        }
      } catch {
        // Silently handle case when Crisp is not available for event tracking
      }
    }

    if (window && window.analytics && !data.skipSegment) {
      const cleanData: {
        [key: string]: any
      } = {}
      Object.keys(data).forEach((key) => {
        if (!segmentAttributesToSkip.includes(key)) {
          cleanData[key] = data[key]
        }
      })
      window.analytics.track(event, cleanData)
    }
  } catch (e: any) {
    sentry.exception(e)
  }
}

export const cleanUserForSegment = (user?: IUserProfile | null) => {
  if (!user) return {}
  try {
    return {
      id: user.id,
      email: user.email,
      name: user.name,
      confirmed: user.confirmed,
      attributes: user.attributes,
      profile_picture: user.profile_picture,
      role: getUserRole(user),
    }
  } catch {
    return {}
  }
}

export const getHostFromRequest = (req: IncomingMessage | NextApiRequest) => {
  let host = req.headers.host || 'portal'
  if (req.headers['x-forwarded-host']) {
    host = req.headers['x-forwarded-host'] as string
  }
  return host || 'portal'
}

export const intercomTrackEvent = (eventName: string) => {
  try {
    if (window && window.Intercom) {
      window.Intercom('trackEvent', eventName)
    }
  } catch (e: any) {
    sentry.exception(e)
  }
}

export const isHostAndRootDataSame = (host: string, rootData: IRootData) => {
  sentry.log('rootdata', 'isHostAndRootDataSame', 'debug', {
    host,
    domain: rootData?.organization?.domain,
    customDomain: rootData?.organization?.home_page,
  })
  if (host.includes(DOMAIN)) {
    const subdomain = getSubdomain(host)

    sentry.log('rootdata', 'isHostAndRootDataSame - subdomain', 'debug', {
      host,
      domain: rootData?.organization?.domain || 'undefined',
      subdomain: subdomain?.subdomain,
    })

    if (subdomain?.subdomain === 'portal') {
      return true
    }

    return subdomain?.subdomain === rootData?.organization?.domain
  }
  const customDomainHost = getHost(rootData?.organization?.home_page)
  sentry.log('rootdata', 'isHostAndRootDataSame - custom domain', 'debug', {
    host,
    domain: customDomainHost,
  })
  return host === customDomainHost
}
