import { Plus } from '@phosphor-icons/react'
import React, { useContext, useMemo, useRef, useState } from 'react'

import AdminUserCustomAttributesFilter, {
  BUILT_IN_CUSTOM_ATTRIBUTES_FIELDS,
} from '@/components/admin/users/filters/AdminUserCustomAttributesFilter'
import Alert from '@/components/shared/ui/Alert'
import Button from '@/components/shared/ui/Button'
import DatePicker from '@/components/shared/ui/DatePicker'
import Input from '@/components/shared/ui/Input'
import Popover from '@/components/shared/ui/Popover'
import {
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/ui/Popover/Popover'
import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import { BOOLEAN_DROPDOWN } from '@/config/module/dropdownConstants'
import HNContext from '@/context/HNContext'
import { useHNUserContext } from '@/context/HNInteractorContext'
import { useTranslations } from '@/hooks/useTranslations'
import { addCustomAttributes } from '@/models/User'
import type { ICustomAttribute } from '@/types/customAttributes'
import toaster from '@/utils/toast'

interface IPropTypes {
  onAdd?: (
    data: {
      key: string
      value: string | number | boolean | null
    },
    selectedAttribute: ICustomAttribute
  ) => void
  children?: React.ReactNode
}

export default function CreateUserAttributes({ onAdd, children }: IPropTypes) {
  const t = useTranslations('')

  const { updateContext, custom_fields = [], id } = useHNUserContext()
  const keyRef = useRef<HTMLInputElement>(null)
  const { customAttributes = [] } = useContext(HNContext)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [formData, setFormData] = useState<{
    key: string
    value: string | number | boolean | null
  }>({
    key: '',
    value: '',
  })

  const selectedAttribute = useMemo(() => {
    const allAttributes = [
      ...BUILT_IN_CUSTOM_ATTRIBUTES_FIELDS,
      ...(customAttributes || []),
    ]
    return allAttributes.find(
      (customAttribute) => customAttribute.slug === formData.key
    )
  }, [formData.key, customAttributes])

  const handleChange = (key: string, value?: typeof formData.value) => {
    setFormData((prev) => ({ ...prev, [key]: value }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (
      formData.value === null ||
      formData.value === undefined ||
      formData.value === ''
    ) {
      setErrorMessage('Value is required')
      return
    }
    if (!id) {
      if (onAdd && selectedAttribute) {
        onAdd(formData, selectedAttribute)
        setFormData({ key: '', value: '' })
      }
      return
    }

    keyRef.current?.focus()
    addCustomAttributes(id, {
      slug: formData.key,
      value: formData.value,
    })
      .then((addedFeild) => {
        setFormData({ key: '', value: '' })
        updateContext?.({
          custom_fields: [...custom_fields, addedFeild],
        })
      })
      .catch(toaster.error)
  }

  const renderValueInput = () => {
    if (selectedAttribute?.field_type === 'boolean') {
      return (
        <Select
          options={BOOLEAN_DROPDOWN}
          placeholder='Select a value'
          className='!rounded-lg'
          value={BOOLEAN_DROPDOWN.filter((op) => op.value === formData.value)}
          onChange={(selected) => {
            const { value } = selected as ISelectOption
            handleChange('value', value)
          }}
        />
      )
    }
    if (selectedAttribute?.field_type === 'date') {
      return (
        <DatePicker
          mode={'single'}
          showInPopOver
          placeholder='Value'
          className='!rounded-lg'
          range={
            formData.value
              ? {
                  from: new Date(formData.value as string),
                  to: undefined,
                }
              : {
                  from: undefined,
                  to: undefined,
                }
          }
          onDaySelect={(day) => {
            // onChange({ ...filter, value: day })
            handleChange('value', day)
          }}
          numberOfMonths={1}
        />
      )
    }

    if (selectedAttribute?.field_type === 'integer') {
      const value = formData.value as number
      return (
        <Input
          placeholder='Value'
          value={value || ''}
          onChange={(e) => handleChange('value', parseFloat(e.target.value))}
        />
      )
    }

    return (
      <Input
        placeholder='Value'
        value={formData.value?.toString() || ''}
        onChange={(e) => {
          handleChange('value', e.target.value)
        }}
      />
    )
  }

  return (
    <Popover>
      <PopoverTrigger>
        {children || (
          <Button as='span' icon={<Plus />} size='sm' fab variant='secondary' />
        )}
      </PopoverTrigger>
      <PopoverContent>
        <form onSubmit={handleSubmit}>
          <div className='space-y-2'>
            {errorMessage && <Alert type={'error'} message={errorMessage} />}
            <AdminUserCustomAttributesFilter
              onChange={(_key, _value, selectedValues) => {
                const value = selectedValues?.[0]?.value
                handleChange('key', value)
                handleChange('value', undefined)
              }}
              filters={{
                attribute_id: [formData.key],
              }}
              filterOptions={(option) => {
                return !custom_fields.find(
                  (customField) =>
                    customField.custom_field.id.toString() ===
                    option.id.toString()
                )
              }}
              behaviour='select'
              placeholder='Select a field'
              className='!rounded-lg'
              includeBuiltInFields={false}
            />
            {renderValueInput()}
            <Button
              block
              disabled={
                !formData.key ||
                formData.value === '' ||
                formData.value === undefined ||
                formData.value === null
              }
            >
              {t('buttons.create')}
            </Button>
          </div>
        </form>
      </PopoverContent>
    </Popover>
  )
}
