import {
  Code,
  Image,
  Info,
  ListBullets,
  ListNumbers,
  Paragraph,
  Quotes,
  Table,
  TextB,
  TextHThree,
  TextHTwo,
  TextItalic,
  Video,
} from '@phosphor-icons/react'
import type { Editor, Range } from '@tiptap/react'
import React from 'react'

export interface ISlashCommandItemCommandProps {
  editor: Editor
  range: Range
}
export interface ISlashCommandItem {
  title: string
  description: string
  icon?: JSX.Element
  command: ({ editor, range }: ISlashCommandItemCommandProps) => void
}

export const commands: ISlashCommandItem[] = [
  {
    title: 'H2',
    description: 'Big section heading',
    icon: <TextHTwo />,
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode('heading', { level: 2 })
        .run()
    },
  },
  {
    title: 'H3',
    description: 'Medium section heading',
    icon: <TextHThree />,
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode('heading', { level: 3 })
        .run()
    },
  },
  {
    title: 'Text',
    description: 'Just start typing with plain text.',
    icon: <Paragraph />,
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .toggleNode('paragraph', 'paragraph')
        .run()
    },
  },
  {
    title: 'Callout',
    description: 'Add a info callout',
    icon: <Info />,
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .toggleNode('callout', 'callout', {
          class: 'callout',
        })
        .run()
    },
  },
  {
    title: 'Bold',
    description: 'Bold text',
    icon: <TextB />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setMark('bold').run()
    },
  },
  {
    title: 'Italic',
    description: 'Italic text',
    icon: <TextItalic />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).setMark('italic').run()
    },
  },
  {
    title: 'Bullet List',
    description: 'Create a simple bullet list.',
    icon: <ListBullets />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleBulletList().run()
    },
  },
  {
    title: 'Numbered List',
    description: 'Create a list with numbering.',
    icon: <ListNumbers />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).toggleOrderedList().run()
    },
  },
  {
    title: 'Quote',
    description: 'Capture a quote.',
    icon: <Quotes />,
    command: ({ editor, range }) =>
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .toggleNode('paragraph', 'paragraph')
        .toggleBlockquote()
        .run(),
  },
  {
    title: 'Code',
    description: 'Capture a code snippet.',
    icon: <Code />,
    command: ({ editor, range }) =>
      editor.chain().focus().deleteRange(range).toggleCodeBlock().run(),
  },

  {
    title: 'Image',
    description: 'Insert image',
    icon: <Image alt='Insert image' />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).run()
      editor.chain().focus().triggerPicker()
    },
  },
  {
    title: 'Video',
    description: 'Insert video',
    icon: <Video />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).run()
      editor.chain().focus().triggerVideoUpload()
    },
  },
  {
    title: 'Table',
    description: 'Create a table',
    icon: <Table />,
    command: ({ editor, range }) => {
      editor.chain().focus().deleteRange(range).run()
      editor.chain().focus().openTableCreation()
    },
  },
]
