import React, { useRef, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import EmptyState from '@/components/shared/ui/EmptyState'
import Input from '@/components/shared/ui/Input'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Spinner from '@/components/shared/ui/Loader'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getLinearIssues,
  linkPostLinearIssue,
} from '@/models/integration/Linear'
import type {
  ILinearIssue,
  ILinearSearchIssue,
} from '@/types/integration/linear'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  onLink: (data: ILinearIssue, action: string) => void
}
export default function LinkLinearIssue({ post, onLink }: IPropTypes) {
  const t = useTranslations(`post.integrations.linear`)
  const timer = useRef<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [linking, setLinking] = useState<string>()
  const [issues, setIssues] = useState<ILinearSearchIssue[]>([])
  const [query, setQuery] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleSearch = (queryString: string) => {
    setIsLoading(true)
    setQuery(queryString)
    if (timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      getLinearIssues({ query: queryString, feature_request_id: post.id })
        .then((data) => {
          setErrorMessage(null)
          setIssues(data)
        })
        .catch((err) => setErrorMessage(err.message))
        .finally(() => setIsLoading(false))
    }, 500)
  }

  const handleSelect = (issue: ILinearSearchIssue) => {
    setLinking(issue.issue_id)
    linkPostLinearIssue(post.slug, issue)
      .then((data) => onLink(data, 'link'))
      .then(() => toaster.success({ message: t(`postLinkedToLinear`) }))
      .catch((err) => toaster.error({ message: err.message }))
      .then(() => setLinking(''))
  }

  const renderItems = () => {
    if (!issues.length && query.length)
      return <EmptyState description={t('noIssues')} />
    if (!issues.length && !query.length) return <></>
    return issues.map((issue, index) => (
      <li
        className='flex items-center justify-between space-x-2 overflow-hidden border-b-[0.5px] border-gray5 p-2 '
        key={index}
      >
        <div className='grow space-x-2 truncate'>
          <span className='truncate text-xs'>{issue.number}</span>
          <UnstyledLink
            className='truncate text-xs font-medium'
            href={issue.url}
            target='_blank'
          >
            {issue.title}
          </UnstyledLink>
        </div>
        <Button
          disabled={Boolean(issue.issue_id === linking)}
          onClick={() => handleSelect(issue)}
          size='xxs'
        >
          {issue.issue_id === linking
            ? t(`buttons.linking`, {
                ignorePrefix: true,
              })
            : t(`buttons.link`, {
                ignorePrefix: true,
              })}
        </Button>
      </li>
    ))
  }

  if (errorMessage) return <EmptyState title={errorMessage} />

  return (
    <div className='mb-4 mt-3 space-y-4'>
      <div>
        <p
          className='pb-2 text-gray10'
          dangerouslySetInnerHTML={{
            __html: t(`linkSearchText`),
          }}
        />
        <Input
          autoFocus
          placeholder={t(`searchPlaceholder`, {
            data: {
              productName: 'Linear',
            },
          })}
          onChange={(e) => handleSearch((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className='mt-2.5'>{isLoading ? <Spinner /> : renderItems()}</div>
    </div>
  )
}
