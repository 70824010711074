import { Eye, EyeSlash } from '@phosphor-icons/react'

import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'
import { objectHasProperty } from '@/lib/helpers/dataHelpers'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function SinglePostViewedToggle({ post }: IPropTypes) {
  const t = useTranslations()

  const handlePostViewed = () => {
    return postStore
      .postReadOrUnread(post.id, post.viewed, post.slug)
      .catch(toaster.error)
  }

  const getIcon = () => {
    if (post.viewed)
      return (
        <EyeSlash
          weight='fill'
          className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
        />
      )
    return (
      <Eye
        weight='fill'
        className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
      />
    )
  }

  if (!objectHasProperty(post, 'viewed')) return <></>

  return (
    <Button
      icon={getIcon()}
      size='xs'
      onClick={handlePostViewed}
      variant='outline'
      className={'group !text-gray12/80 hover:text-gray12'}
      isResponsive
    >
      {post.viewed ? (
        <span className='hidden pl-1 md:block'>
          {t('post.labels.markAsUnread')}
        </span>
      ) : (
        <span className='hidden pl-1 md:block'>
          {t('post.labels.markAsRead')}
        </span>
      )}
    </Button>
  )
}
