import { ErrorBoundary } from '@sentry/nextjs'
import dynamic from 'next/dynamic'
import type { ReactNode } from 'react'
import React, { Component } from 'react'

import HNTranslationContext from '@/context/HNTranslationContext'
import API from '@/lib/API'
import type { IKeyValueMap, IRequestHeaders } from '@/types/common'

const HNSegment = dynamic(
  () => import('@/components/users/shared/integration_snippets/HNSegment')
)

const HNPaddleAnalytics = dynamic(
  () => import('@/components/users/shared/HNPaddleAnalytics')
)

interface IPropTypes {
  children: ReactNode
  session?: string | null
  translations: any
  widgetKey?: string
  subdomain?: string
}
interface IStateTypes {
  translations: IKeyValueMap
}

const setHeaders = (
  session?: string | null,
  widgetKey?: string,
  subdomain?: string
) => {
  const headers: IRequestHeaders = {
    Authorization: session ? `Bearer ${session}` : '',
    'X-Organization': subdomain || 'portal',
  }
  if (widgetKey) {
    headers['x-widget-key'] = widgetKey
  } else {
    headers['x-widget-key'] = ''
  }
  if (typeof window !== 'undefined') {
    API.setHeaders(headers)
  }
}

export default class PreProcessWrapper extends Component<
  IPropTypes,
  IStateTypes
> {
  constructor(props: IPropTypes) {
    super(props)
    setHeaders(props.session, props.widgetKey, props.subdomain)
    this.state = { translations: this.props.translations }
  }

  updateContext = (newTranslation: IKeyValueMap) => {
    this.setState({
      translations: { ...this.state.translations, ...newTranslation },
    })
  }

  render() {
    return (
      <HNTranslationContext.Provider
        value={{
          ...this.state.translations,
          updateTranslationContext: this.updateContext,
        }}
      >
        <>
          {this.props.children}
          <ErrorBoundary fallback={<></>}>
            <HNSegment />
            <HNPaddleAnalytics />
          </ErrorBoundary>
        </>
      </HNTranslationContext.Provider>
    )
  }
}
