import React, { useContext } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { clearIdxDBData, deleteLocalHashData } from '@/lib/localDB/localStorage'
import { signOut } from '@/models/Auth'
import toaster from '@/utils/toast'

interface IPropTypes {
  children: React.ReactNode
  showAlert?: boolean
  as?: keyof JSX.IntrinsicElements
}
export default function SignoutTrigger({
  children,
  showAlert = false,
  as = 'p',
}: IPropTypes) {
  const t = useTranslations('auth.signOut')
  const { organizationSetting } = useContext(HNContext)

  const handleSignoutTrigger = () => {
    signOut()
      .then(deleteLocalHashData)
      .then(clearIdxDBData)
      .then(() => {
        if (organizationSetting && !organizationSetting?.organization_privacy) {
          window.location.href = '/'
        } else {
          window.location.href = '/auth/signin'
        }
      })
      .catch((err) => toaster.error({ message: err.message }))
  }

  if (showAlert) {
    return (
      <AlertDialog
        title={t('alertTexts.title')}
        description={t('alertTexts.description')}
        onConfirm={handleSignoutTrigger}
      >
        {children}
      </AlertDialog>
    )
  }
  const Component = as || 'p'
  return (
    <Component className='w-full' onClick={handleSignoutTrigger}>
      {children}
    </Component>
  )
}
