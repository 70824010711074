import { CaretRight } from '@phosphor-icons/react'
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'
import { cva } from 'class-variance-authority'
import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

import UnstyledLink from '../Links/UnstyledLink'

const contextMenuContentStyles = cva(
  'min-w-[220px] rounded-md bg-white dark:bg-gray-800 p-1 shadow-md ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-5 focus:outline-none',
  {
    variants: {
      variant: {
        default: '',
        red: 'text-red-600 dark:text-red-400',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

const contextMenuItemStyles = cva(
  'relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none text-gray-900 dark:text-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:text-gray-900 dark:focus:text-gray-100 data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
  {
    variants: {
      variant: {
        default: '',
        red: 'text-red-600 dark:text-red-400 focus:text-red-600 dark:focus:text-red-400',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

const contextMenuTriggerStyles = cva(
  'inline-flex items-center w-full justify-center rounded-md text-sm font-medium ring-offset-white dark:ring-offset-gray-800 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-gray-400 dark:focus-visible:ring-gray-500 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: '',
        red: 'text-red-600 dark:text-red-400',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

interface IContextMenu {
  children?: React.ReactNode
  className?: string
}

interface IContextMenuSubContentProps
  extends ContextMenuPrimitive.ContextMenuSubContentProps {}

export const ContextSubContent: FC<IContextMenuSubContentProps> = ({
  children,
  ...props
}) => (
  <ContextMenuPrimitive.Portal {...props}>
    <ContextMenuPrimitive.ContextMenuSubContent
      sideOffset={2}
      alignOffset={5}
      className={contextMenuContentStyles()}
    >
      {children}
    </ContextMenuPrimitive.ContextMenuSubContent>
  </ContextMenuPrimitive.Portal>
)

interface IContextMenuSubTriggerProps
  extends ContextMenuPrimitive.ContextMenuSubTriggerProps {
  icon?: React.ReactNode
  itemValue?: React.ReactNode
  RightIcon?: React.ReactNode
  color?: 'default' | 'red'
}

export const ContextSubTrigger: FC<IContextMenuSubTriggerProps> = ({
  children,
  color = 'default',
  disabled,
  onSelect,
  icon,
  itemValue,
  RightIcon,
  ...props
}) => (
  <ContextMenuPrimitive.SubTrigger
    className={contextMenuItemStyles({ variant: color })}
    disabled={disabled}
    onSelect={onSelect}
    {...props}
  >
    {!children && (
      <div className='flex w-full items-center justify-between'>
        <div className='flex flex-1 items-center gap-2'>
          <span className='shrink-0'>{icon}</span>
          <span className='truncate'>{itemValue}</span>
        </div>
        <div className='flex items-center gap-1'>
          <span className='shrink-0'>{RightIcon}</span>
          <CaretRight className='h-4 w-4' />
        </div>
      </div>
    )}
    {Boolean(children) && children}
  </ContextMenuPrimitive.SubTrigger>
)

interface IContextMenuSubProps
  extends ContextMenuPrimitive.ContextMenuSubProps {}

export const ContextSub: FC<IContextMenuSubProps> = ({
  children,
  ...props
}) => <ContextMenuPrimitive.Sub {...props}>{children}</ContextMenuPrimitive.Sub>

export const ContextSeparator: FC<IContextMenu> = ({
  children,
  className,
  ...props
}) => (
  <ContextMenuPrimitive.Group
    {...props}
    className={`-mx-1 my-1 h-px bg-gray-200 dark:bg-gray-700 ${
      className || ''
    }`}
  >
    {children}
  </ContextMenuPrimitive.Group>
)

export const ContextGroup: FC<IContextMenu> = ({ children, ...props }) => (
  <ContextMenuPrimitive.Separator {...props}>
    {children}
  </ContextMenuPrimitive.Separator>
)

interface IContextMenuContentItemProps
  extends ContextMenuPrimitive.ContextMenuItemProps {
  color?: 'default' | 'red'
  icon?: React.ReactNode
  itemValue?: React.ReactNode
  href?: string
  RightIcon?: React.ReactNode
  target?: '_blank' | '_self' | '_parent' | '_top'
}

export const ContextItem: FC<IContextMenuContentItemProps> = ({
  children,
  color = 'default',
  disabled,
  onSelect,
  icon,
  itemValue,
  href,
  RightIcon,
  target,
  ...props
}) => {
  const content = (
    <>
      <div className='flex flex-1 items-center gap-2'>
        <span className='shrink-0'>{icon}</span>
        <span className='truncate'>{itemValue}</span>
      </div>
      <span className='shrink-0'>{RightIcon}</span>
    </>
  )

  if (!children && href) {
    return (
      <UnstyledLink
        href={href}
        target={target}
        className={contextMenuItemStyles({ variant: color })}
      >
        {content}
      </UnstyledLink>
    )
  }

  return (
    <ContextMenuPrimitive.Item
      {...props}
      className={contextMenuItemStyles({ variant: color })}
      disabled={disabled}
      onSelect={onSelect}
    >
      {!children && content}
      {Boolean(children) && children}
    </ContextMenuPrimitive.Item>
  )
}

export const ContextLabel: FC<IContextMenu> = ({ children, ...props }) => (
  <ContextMenuPrimitive.Label
    {...props}
    className='px-2 py-1.5 text-sm font-semibold text-gray-900 dark:text-gray-100'
  >
    {children}
  </ContextMenuPrimitive.Label>
)

interface IContextMenuContentProps
  extends ContextMenuPrimitive.ContextMenuContentProps {}

export const ContextContent: FC<IContextMenuContentProps> = ({
  children,
  ...props
}) => (
  <ContextMenuPrimitive.Portal {...props}>
    <ContextMenuPrimitive.Content className={contextMenuContentStyles()}>
      {children}
    </ContextMenuPrimitive.Content>
  </ContextMenuPrimitive.Portal>
)

interface IContextMenuTriggerProps
  extends ContextMenuPrimitive.ContextMenuTriggerProps {}

export const ContextTrigger: FC<IContextMenuTriggerProps> = ({
  children,
  ...props
}) => (
  <ContextMenuPrimitive.Trigger
    {...props}
    className={clsx(contextMenuTriggerStyles(), props.className)}
  >
    {children}
  </ContextMenuPrimitive.Trigger>
)

const ContextMenu = Object.assign(ContextMenuPrimitive.Root, {
  Trigger: ContextTrigger,
  Content: ContextContent,
  Item: ContextItem,
  Label: ContextLabel,
  Separator: ContextSeparator,
  Group: ContextGroup,
  Sub: ContextSub,
  SubTrigger: ContextSubTrigger,
  SubContent: ContextSubContent,
})

export default ContextMenu
