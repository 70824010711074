import { Plus } from '@phosphor-icons/react'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import Avatar from '@/components/shared/ui/Avatar'
import AvatarGroup from '@/components/shared/ui/AvatarGroup'
import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectPropTypes,
} from '@/components/shared/ui/Select/Select'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { sortObjectWithoutEmoji } from '@/lib/helpers/dataHelpers'
import { setIdxDBData } from '@/lib/localDB/localDB'
import { getAssignees } from '@/models/Organization'
import { getLocalAssignees } from '@/models/Team'
import type { IFilters } from '@/types/common'
import type { IPostListAPIParams } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

interface IPropType {
  onChange?: (key: string, value: any, selectedOption: any[]) => void
  filters: IFilters | IPostListAPIParams
  multiple?: boolean
  placeholder?: string
  disabled?: boolean
  behaviour?: string
  clearable?: boolean
  showSelectedOptionsCount?: boolean
  variant?: 'blank' | 'outline' | 'dashed'
  iconOnly?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  showUnassigned?: boolean
  itemFilter?: (item: IUserProfile & { archived: boolean }) => boolean
  renderTrigger?: (props: IPropType) => JSX.Element
  selectProps?: Partial<ISelectPropTypes>
  noCache?: boolean
}

const FILTER_KEY = 'assignee_id'

export default function AdminPostAssigneeFilter(props: IPropType) {
  const {
    onChange,
    filters,
    multiple = true,
    placeholder = '',
    disabled = false,
    behaviour,
    clearable = false,
    showSelectedOptionsCount = true,
    variant = 'outline',
    size = 'xs',
    iconOnly = false,
    showUnassigned = false,
    renderTrigger: _renderTriggerProp,
    itemFilter,
    selectProps = {},
    noCache = false,
  } = props
  const [loading, setLoading] = useState(false)
  const { assignees = [], updateContext } = useContext(HNContext)
  const t = useTranslations()

  const assigneeOptions = useMemo(() => {
    const options = showUnassigned
      ? [
          {
            id: 'unassigned',
            name: t('common.labels.unassigned', { ignorePrefix: true }),
          },
          ...assignees,
        ]
      : [...assignees]

    return sortObjectWithoutEmoji(options, 'letter')
      .filter(itemFilter || (() => true))
      ?.map((a: IUserProfile) => {
        if (a.id === 'unassigned') {
          return { ...a, label: a.name, value: a.id, svg: 'user' }
        }
        return {
          ...a,
          label: a.name,
          value: a.id,
          imageURL: a.profile_picture ? a.profile_picture.url : null,
        }
      })
  }, [assignees])

  const handleChange = (value: ISelectOption | ISelectOption[] | undefined) => {
    if (!onChange) return
    if (!value && clearable) {
      onChange(FILTER_KEY, [], [])
      return
    }
    const selectedValue = Array.isArray(value) ? value : [value]
    const selectedAssignee = selectedValue.map((item) => item?.value)
    const selectedOptions = assigneeOptions.filter((a: ISelectOption) =>
      selectedAssignee.includes(a.value)
    )
    onChange(FILTER_KEY, selectedAssignee, selectedOptions)
  }

  const value = useMemo(() => {
    if (filters && filters.assignee_id) {
      const filteredItems = Array.isArray(filters.assignee_id)
        ? filters.assignee_id.map((i) => i.toString())
        : [filters.assignee_id.toString()]
      return assigneeOptions.filter((a: ISelectOption) =>
        filteredItems.includes(a.value.toString())
      )
    }
    return null
  }, [filters.assignee_id, assigneeOptions])

  const fetchData = () => {
    setLoading(true)

    getLocalAssignees()
      .then((_assignees) => _assignees?.filter((a: IUserProfile) => a))
      .then((_assignees) => {
        if (_assignees?.length && !noCache) {
          if (updateContext) updateContext({ assignees: _assignees })
          return _assignees
        }
        return getAssignees()
          .then((_assignee) => {
            const promises = _assignee.map((assignee: IUserProfile) =>
              setIdxDBData('ASSIGNEES', assignee.id, assignee, 'id')
            )
            return Promise.all(promises)
          })
          .then((_assignee) => {
            if (updateContext) {
              updateContext({ assignees: _assignee })
            }
          })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchData()
  }, [])

  const renderTrigger = () => {
    if (_renderTriggerProp) return _renderTriggerProp(props)
    const selectedItems = value as ISelectOption[]
    if (!selectedItems || !selectedItems.length)
      return (
        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-gray4 '>
          <Plus weight='bold' className='h-3 w-3 shrink-0' />
        </div>
      )
    if (Array.isArray(selectedItems) && selectedItems.length > 1) {
      return (
        <AvatarGroup
          avatars={selectedItems.map((item) => {
            return {
              id: item.value,
              profile_picture: {
                url: item.imageURL.toString(),
              },
              name: item.label,
            }
          })}
          size='sm'
          totalCounts={selectedItems.length}
          rounded
          extras={0}
        />
      )
    }
    if (Array.isArray(selectedItems) && selectedItems.length === 1)
      return (
        <Avatar
          url={selectedItems[0]?.imageURL}
          name={selectedItems[0]?.label}
          size='sm'
          rounded
        />
      )
    return <Plus />
  }

  return (
    <Select
      {...selectProps}
      loading={loading}
      size={size}
      disabled={disabled}
      variant={variant}
      clearable={clearable}
      searchable
      value={value}
      onChange={handleChange}
      options={assigneeOptions}
      multiple={multiple}
      showAvatar
      placeholder={placeholder.toString()}
      placeholderIcon={behaviour === 'filter' ? <></> : <Plus />}
      inputProps={{
        placeholder,
      }}
      dataTestId='admin_post_assignee_filter'
      showSelectedOptionsCount={showSelectedOptionsCount}
      renderTrigger={iconOnly ? renderTrigger : undefined}
    />
  )
}
