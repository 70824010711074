import { humanizeByte } from '@/lib/helpers/dataHelpers'
import { formatJSONTimestamp } from '@/lib/helpers/dateHelpers'
import { snakeToCamel } from '@/lib/helpers/stringHelpers'
import type {
  IBillingInfo,
  IOrgViewablePlan,
  IParsePlanInfo,
  IPaymentSource,
  IPlanInterval,
  IPlanName,
} from '@/types/billing'
import type { IIntegrationsInfo } from '@/types/integration'
import type { IOrganizationPlan } from '@/types/organization'

export const ORGANIZATION_PLANS: IOrgViewablePlan[] = [
  { plan: 'freeway', name: 'Freeway', monthly_price: 0, yearly_price: 0 },
  {
    plan: 'take_flight',
    name: 'Take Flight',
    monthly_price: 49,
    yearly_price: 499,
  },
  { plan: 'fly_high', name: 'Fly High', monthly_price: 99, yearly_price: 999 },
  {
    plan: 'enterprise',
    name: 'Enterprise',
  },
  {
    plan: 'starter',
    name: 'Starter',
  },
  {
    plan: 'growth',
    name: 'Growth',
  },
  {
    plan: 'business',
    name: 'Business',
  },
]

export const ORGANIZATION_PLANS_MAP = ORGANIZATION_PLANS.reduce((acc, plan) => {
  acc[plan.plan] = plan
  return acc
}, {} as Record<string, IOrgViewablePlan>)

export const getExpiryDate = (billingInfo: IBillingInfo) => {
  const { plan_info, plan_subscription } = billingInfo

  if (plan_info.due) {
    return plan_info.end_date
  }

  return plan_subscription ? plan_subscription.end_date : plan_info.end_date
}

export const parsePlanInfo = (
  billingInfo: IBillingInfo,
  translations?: any
): IParsePlanInfo => {
  const { plan_info, plan_subscription } = billingInfo
  const planSlug = plan_subscription
    ? plan_subscription.plan_slug
    : plan_info.slug

  const planData = billingInfo?.plans.find((plan) => plan.plan === planSlug)
  return {
    plan: planData?.plan || plan_info.slug,
    name:
      plan_info.slug === 'trial'
        ? 'Trial'
        : translations[`${snakeToCamel(planSlug)}`]?.name,
    expiryDate: formatJSONTimestamp(getExpiryDate(billingInfo)),
    due: plan_info.due,
    inTrial: plan_info.slug === 'trial',
    canManage:
      planSlug === 'trial' ? false : !!plan_info?.source.includes('stripe'),
    amount: plan_subscription?.amount || 0,
    interval: plan_subscription?.interval || '',
    activeSubscription: billingInfo.active_plan_subscription || false,
    source: (plan_subscription
      ? plan_subscription.source
      : plan_info.source) as IPaymentSource,
    trialExtended: plan_info?.trial_extended,
  }
}

export const showPaymentHistory = (planInfo: IParsePlanInfo) => {
  if (planInfo.activeSubscription) {
    return true
  }
  if (planInfo.due) {
    return false
  }

  if (!planInfo.activeSubscription && !planInfo.due && !planInfo.inTrial) {
    return true
  }

  return false
}

export const showPlanList = (planInfo: IParsePlanInfo) => {
  if (planInfo.inTrial) {
    return true
  }
  if (planInfo.due) {
    return true
  }

  return false
}

export const inTrial = (plan: IOrganizationPlan | undefined) => {
  if (!plan) return false
  return (
    plan?.slug?.toLowerCase() === 'trial' ||
    plan?.slug?.toLowerCase() === 'free'
  )
}

export const inPlan = (
  organizationPlan: IOrganizationPlan | undefined,
  plan: string
) => {
  if (!organizationPlan) return false
  return organizationPlan?.slug?.toLowerCase() === plan?.toLowerCase()
}

export const parsePlanState = (plan: IOrganizationPlan | undefined) => {
  return {
    inTrial: inTrial(plan),
    inFreeway: inPlan(plan, 'freeway'),
    inTakeFlight: inPlan(plan, 'take_flight'),
    inFlyHigh: inPlan(plan, 'fly_high'),
    isExpired: plan?.trial_expired || false,
  }
}

export const planCheckToDisable = (
  recommendedPlan: IPlanName,
  plan: IPlanName
) => {
  let isButtonDisabled = false
  if (
    recommendedPlan === 'business' &&
    Boolean(plan === 'growth' || plan === 'starter')
  ) {
    isButtonDisabled = true
    return isButtonDisabled
  }
  if (recommendedPlan === 'growth' && Boolean(plan === 'starter')) {
    isButtonDisabled = true
    return isButtonDisabled
  }
  if (
    recommendedPlan === 'fly_high' &&
    Boolean(plan === 'take_flight' || plan === 'runway' || plan === 'freeway')
  ) {
    isButtonDisabled = true
    return isButtonDisabled
  }
  if (
    recommendedPlan === 'take_flight' &&
    Boolean(plan === 'freeway' || plan === 'runway')
  ) {
    isButtonDisabled = true
    return isButtonDisabled
  }
  return isButtonDisabled
}

export const disablePlanBasedOnRecommendedPlan = (
  recommendedPlan: IPlanName,
  plan: IPlanName,
  planCancelled: boolean,
  planInterval?: 'monthly' | 'yearly',
  subscriptionInterval?: 'monthly' | 'yearly'
) => {
  let isButtonDisabled = false
  const isTrial = plan === 'trial' || plan === 'free'
  if (!isTrial && !planCancelled) {
    if (planInterval === 'monthly' && subscriptionInterval === 'yearly') {
      isButtonDisabled = true
      return isButtonDisabled
    }
    if (planInterval === 'yearly' && subscriptionInterval === 'monthly') {
      return planCheckToDisable(recommendedPlan, plan)
    }
    return planCheckToDisable(recommendedPlan, plan)
  }
  return false
}

export const getIntervalText = (interval: string, translations: any) => {
  if (interval === 'month' || interval === 'monthly')
    return translations.perMonth
  return translations.perYear
}

export const getSubscriptionInterval = (
  interval: IPlanInterval,
  subscriptionInterval: 'month' | 'year' | null
) => {
  let intervalText = 'monthly'
  if (subscriptionInterval === null) {
    intervalText = interval
  }
  if (subscriptionInterval === 'month') intervalText = 'monthly'
  if (subscriptionInterval === 'year') intervalText = 'yearly'
  return intervalText as IPlanInterval
}

export const checkPaymentSourceIsStripe = (paymentSource: string) => {
  if (Boolean(paymentSource?.length) && paymentSource?.includes('stripe'))
    return true
  return false
}

export const getIntegratedCount = (integrationsInfo: IIntegrationsInfo) => {
  const cloneData = { ...integrationsInfo }
  const integratesArray: any = []
  Object.keys(cloneData).map((key) => {
    // @ts-ignore
    const value = cloneData[key]
    if (typeof value === 'object' && value !== null) {
      return integratesArray.push({ ...value, slug: key })
    }
    return null
  })
  return integratesArray?.filter((i: any) => i.allowed && i.integrated)?.length
}

export const checkSourceIsStripe = (billingInfo: {
  plan_info: { source: string }
}) => {
  const { plan_info } = billingInfo
  if (plan_info.source === 'stripe_v1') return true
  if (plan_info.source === 'stripe_v2') return true
  return false
}

export const multiplierCleaners = {
  ext_files_storage: (multiplier: number) => humanizeByte(multiplier),
}

export const powerUpUnitConverter = (powerUp: string, value: number) => {
  const cleaner =
    multiplierCleaners[powerUp as keyof typeof multiplierCleaners] ||
    ((multiplier: number) => multiplier.toString())

  return cleaner(value)
}
