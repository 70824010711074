import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'

import HNContext from '@/context/HNContext'

export default function CustomHeaderBanner() {
  const router = useRouter()
  const { pathname } = router
  const { organizationCustomization, default_module } = useContext(HNContext)
  const { home_page_config } = organizationCustomization || {}

  const { title, subtitle } = useMemo(() => {
    let cModule = default_module || 'boards'
    const { rTitle, rSubtitle, cTitle, cSubtitle, bTitle, bSubtitle } =
      home_page_config?.banner || {}

    if (pathname !== '/') {
      if (
        pathname === '/b' ||
        pathname === '/p' ||
        pathname === '/b/[boardSlug]'
      ) {
        cModule = 'boards'
      } else if (pathname === '/roadmap' || pathname === '/roadmap/roadmap') {
        cModule = 'roadmap'
      } else if (
        pathname === '/changelog' ||
        pathname === '/changelog/[changelogSlug]'
      ) {
        cModule = 'changelog'
      }
    }

    if (cModule === 'boards') {
      return {
        title: bTitle,
        subtitle: bSubtitle,
      }
    }
    if (cModule === 'roadmap') {
      return {
        title: rTitle,
        subtitle: rSubtitle,
      }
    }
    if (cModule === 'changelog') {
      return {
        title: cTitle,
        subtitle: cSubtitle,
      }
    }
    return {
      title: bTitle,
      subtitle: bSubtitle,
    }
  }, [home_page_config, module, pathname])

  if (!home_page_config?.custom_banner_image) return <div className='mt-16' />

  return (
    <div
      className='mt-16 flex flex-col items-center justify-center'
      style={{
        backgroundImage: `url('${home_page_config?.custom_banner_image}')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className='container mx-auto flex flex-col gap-1 py-6 text-center text-gray12 dark:text-gray1'>
        <p
          className='text-2xl'
          style={{
            color: home_page_config?.banner?.titleColor ?? undefined,
          }}
        >
          {title}
        </p>
        {subtitle && (
          <div
            className='prose-xs italic prose-a:underline'
            style={{
              color: home_page_config?.banner?.subtitleColor ?? undefined,
            }}
            dangerouslySetInnerHTML={{
              __html: subtitle || '',
            }}
          />
        )}
      </div>
    </div>
  )
}
