import { parseDate } from '@/lib/helpers/dateHelpers'
import type { IBoardDetails } from '@/types/board'
import type { IChangelogAPIParams } from '@/types/changelog'
import type { ICustomStatusKey } from '@/types/organization'
import type { IPost, IPostListAPIParams, TPostPriority } from '@/types/post'
import type { IUserProfile } from '@/types/user'

const getBuckets = (buckets: IBoardDetails[]) => {
  return buckets.map((b) => `bucket_id=${b.id}`)
}

export const adminFeatureRequestSearchFilter = (
  id: string | undefined,
  boards: IBoardDetails[] | undefined
) => {
  if (!id || !boards?.length) return []
  return [
    'approval_status=approved',
    `organization_id=${id}`,
    'merged_with_id=0',
    getBuckets(boards),
  ]
}

export const adminSavedFiltersSearchFilter = (
  id: string | undefined,
  globalFilters: IPostListAPIParams,
  boards: IBoardDetails[]
) => {
  return [
    'approval_status=approved',
    `organization_id=${id}`,
    'merged_with_id=0',
    globalFilters.bucket_id
      ? globalFilters.bucket_id?.map((b: string) => `bucket_id=${b}`)
      : getBuckets(boards || []),
  ]
}

export const singlePostMergePostSearchFilter = (
  buckets: IBoardDetails[],
  orgId: string,
  postId?: string
) => {
  const searchFilters = [
    `organization_id=${orgId}`,
    `merged_with_id=0`,
    'approval_status=approved',
  ]
  if (postId) {
    searchFilters.push(`id!=${postId}`)
  }
  const bucketIds = (buckets || []).map((bucket) => `bucket_id=${bucket.id}`)
  if (bucketIds.length) {
    return [...searchFilters, bucketIds]
  }
  return [...searchFilters]
}

export const completedPostsSearchFilter = (
  buckets: IBoardDetails[],
  orgId: string | undefined
) => {
  const bucketIds = (buckets || []).map((bucket) => `bucket_id=${bucket.id}`)
  return [
    `organization_id=${orgId}`,
    'merged_with_id=0',
    'linked_to_changelog=false',
    'approval_status=approved',
    'hidden=false',
    bucketIds,
  ]
}

export const userSidePostListSearch = (
  userProfile: IUserProfile,
  boards: IBoardDetails[],
  orgId: string,
  additionalInfo: {
    status?: ICustomStatusKey
  } = {}
) => {
  const bucketIds = (boards || []).map((bucket) => `bucket_id=${bucket.id}`)
  const searchFilters = [
    bucketIds,
    `organization_id=${orgId}`,
    'merged_with_id=0',
  ]

  if (additionalInfo?.status) {
    searchFilters.push(`post_status=${additionalInfo?.status}`)
  }

  if (userProfile?.is_csm_of_organization)
    return [...searchFilters, 'approval_status=approved']
  if (userProfile) {
    return [
      ...searchFilters,
      ['hidden=false', `submitter_id=${userProfile?.id}`],
      'approval_status=approved',
    ]
  }

  return [...searchFilters, 'hidden=false', 'approval_status=approved']
}

export const convertArrayStringToArrayObject = (arr: any) => {
  const groupedArray: any = []
  arr.map((value: any) => {
    const [key, id] = value.split('=')
    return groupedArray.push({ [key]: id })
  })
  return groupedArray
}

const convertToArray = (value?: string | string[]) => {
  if (!value) return []
  return Array.isArray(value) ? value : [value]
}

export const dbFilterToSearchFilter = (postFilters: IPostListAPIParams) => {
  const searchFilters: string[][] = [['merged_with_id=0']]

  const bucketIds = convertToArray(postFilters.bucket_id)
  searchFilters.push(bucketIds.map((id) => `bucket_id=${id}`))

  const selectedStatuses = convertToArray(postFilters.status)
  searchFilters.push(selectedStatuses.map((status) => `post_status=${status}`))

  const selectedStates = convertToArray(postFilters.state)
  searchFilters.push(
    selectedStates.map((state) => `custom_status.value=${state}`)
  )

  const tagIds = convertToArray(postFilters.tag_id)
  searchFilters.push(tagIds.map((id) => `tag_ids=${id}`))

  const assigneeIds = convertToArray(postFilters.assignee_id)
  searchFilters.push(assigneeIds.map((id) => `assignee_id=${id}`))

  const submitterIds = convertToArray(postFilters.user)
  searchFilters.push(submitterIds.map((id) => `submitter_id=${id}`))

  if (postFilters.date_range) {
    const dateGroup = postFilters.date_range.split(',') as string[]
    const [minTimestamp, maxTimestamp] = dateGroup.map((etc) => {
      return [parseDate(etc, 'yyyy-MM-dd').getTime() / 1000]
    })
    searchFilters.push([
      `numeric_created_at ${minTimestamp} TO ${maxTimestamp}`,
    ])
  }

  const emailDomains = convertToArray(postFilters.domains)
  searchFilters.push(emailDomains.map((id) => `email_domain=${id}`))

  const upvote_range = convertToArray(postFilters.upvote_range)
  if (upvote_range.length) {
    const [min, max] = upvote_range
    searchFilters.push([`votes_count ${min} TO ${max}`])
  }

  const downvote_range = convertToArray(postFilters.downvote_range)
  if (downvote_range.length) {
    const [min, max] = downvote_range
    searchFilters.push([`downvotes_count ${min} TO ${max}`])
  }
  if (postFilters.etc) {
    const etcGroup = postFilters.etc.split(',') as string[]
    const [minTimestamp, maxTimestamp] = etcGroup.map((etc) => {
      return [parseDate(etc, 'yyyy-MM-dd').getTime() / 1000]
    })
    searchFilters.push([`numeric_etc_date ${minTimestamp} TO ${maxTimestamp}`])
  }

  const sentiments = convertToArray(postFilters.sentiment_type)
  searchFilters.push(sentiments.map((id) => `sentiment_type=${id}`))

  const keywords = convertToArray(postFilters.keywords)
  searchFilters.push(keywords.map((id) => `keywords=${id}`))

  const sources = convertToArray(postFilters.sources)
  searchFilters.push(sources.map((id) => `source=${id}`))

  const priorities = convertToArray(postFilters.priority)
  searchFilters.push(priorities.map((id) => `priority=${id}`))

  const approvalStatuses = convertToArray(
    postFilters.approval_status || 'approved'
  )
  searchFilters.push(
    approvalStatuses.map((status) => `approval_status=${status}`)
  )

  return searchFilters.filter((filter) => filter.length)
}

const PRIORITY_MAP: {
  [key in TPostPriority]: number
} = {
  no_priority: 0,
  low: 1,
  medium: 2,
  high: 3,
  urgent: 4,
}
export const sortAdminSearchedPosts = (
  searchedPosts: IPost[],
  sort: string
) => {
  if (sort === 'top') {
    return searchedPosts.sort(
      (a, b) => Number(b.votes_count) - Number(a.votes_count)
    )
  }
  if (sort === 'least_upvoted') {
    return searchedPosts.sort(
      (a, b) => Number(a.votes_count) - Number(b.votes_count)
    )
  }
  if (sort === 'most_downvoted') {
    return searchedPosts.sort(
      (a, b) => Number(b.downvotes_count) - Number(a.downvotes_count)
    )
  }
  if (sort === 'least_downvoted') {
    return searchedPosts.sort(
      (a, b) => Number(a.downvotes_count) - Number(b.downvotes_count)
    )
  }
  if (sort === 'latest') {
    return searchedPosts.sort(
      (a, b) => Number(b.numeric_created_at) - Number(a.numeric_created_at)
    )
  }
  if (sort === 'oldest_created_at') {
    return searchedPosts.sort(
      (a, b) => Number(a.numeric_created_at) - Number(b.numeric_created_at)
    )
  }
  if (sort === 'oldest_completed_at') {
    return searchedPosts.sort(
      (a, b) => Number(b.numeric_completed_at) - Number(a.numeric_completed_at)
    )
  }
  if (sort === 'latest_completed_at') {
    return searchedPosts.sort(
      (a, b) => Number(b.numeric_completed_at) - Number(a.numeric_completed_at)
    )
  }
  if (sort === 'latest_updated_at') {
    return searchedPosts.sort(
      (a, b) => Number(b.numeric_updated_at) - Number(a.numeric_updated_at)
    )
  }
  if (sort === 'oldest_updated_at') {
    return searchedPosts.sort(
      (a, b) => Number(a.numeric_updated_at) - Number(b.numeric_updated_at)
    )
  }
  if (sort === 'oldest_etc') {
    return searchedPosts.sort(
      (a, b) => Number(b.numeric_etc_date) - Number(a.numeric_etc_date)
    )
  }
  if (sort === 'latest_etc') {
    return searchedPosts.sort(
      (a, b) => Number(a.numeric_etc_date) - Number(b.numeric_etc_date)
    )
  }
  if (sort === 'status_asc') {
    return searchedPosts.sort((a, b) =>
      b.custom_status.status.localeCompare(a.custom_status.status)
    )
  }
  if (sort === 'status_desc') {
    return searchedPosts.sort((a, b) =>
      a.custom_status.status.localeCompare(b.custom_status.status)
    )
  }
  if (sort === 'sentiment_asc') {
    return searchedPosts.sort(
      (a, b) => Number(b.sentiment_score) - Number(a.sentiment_score)
    )
  }
  if (sort === 'sentiment_desc') {
    return searchedPosts.sort(
      (a, b) => Number(a.sentiment_score) - Number(b.sentiment_score)
    )
  }
  if (sort === 'priority_asc') {
    return searchedPosts.sort(
      (a, b) =>
        PRIORITY_MAP[b.priority as keyof typeof PRIORITY_MAP] -
        PRIORITY_MAP[a.priority as keyof typeof PRIORITY_MAP]
    )
  }
  if (sort === 'priority_desc') {
    return searchedPosts.sort(
      (a, b) =>
        PRIORITY_MAP[a.priority as keyof typeof PRIORITY_MAP] -
        PRIORITY_MAP[b.priority as keyof typeof PRIORITY_MAP]
    )
  }
  return searchedPosts
}

export const dbFilterToSearchSort = (sort: string) => {
  if (sort === 'top') return ['votes_count:desc']
  if (sort === 'least_upvoted') return ['votes_count:asc']
  if (sort === 'most_downvoted') return ['downvotes_count:desc']
  if (sort === 'least_downvoted') return ['downvotes_count:asc']

  if (sort === 'latest') return ['numeric_created_at:desc']
  if (sort === 'oldest_created_at') return ['numeric_created_at:asc']

  if (sort === 'oldest_completed_at') return ['numeric_completed_at:asc']
  if (sort === 'latest_completed_at') return ['numeric_completed_at:desc']
  if (sort === 'latest_updated_at') return ['numeric_updated_at:desc']
  if (sort === 'oldest_updated_at') return ['numeric_updated_at:asc']
  if (sort === 'oldest_etc') return ['numeric_etc_date:asc']
  if (sort === 'latest_etc') return ['numeric_etc_date:desc']

  if (sort === 'status_asc') return ['custom_status.status:desc']
  if (sort === 'status_desc') return ['custom_status.status:asc']

  if (sort === 'sentiment_asc') return ['sentiment_score:asc']
  if (sort === 'sentiment_desc') return ['sentiment_score:desc']

  if (sort === 'priority_asc') return ['priority:asc']
  if (sort === 'priority_desc') return ['priority:desc']
  return []
}

export const dbChangelogFilterToSearchFilter = (
  changelogFilter: IChangelogAPIParams
) => {
  const searchFilters: string[][] = []

  if (changelogFilter.date_range) {
    const dateGroup = changelogFilter.date_range.split(',') as string[]
    const [minTimestamp, maxTimestamp] = dateGroup.map((etc) => {
      return [parseDate(etc, 'yyyy-MM-dd').getTime() / 1000]
    })
    searchFilters.push([
      `numeric_created_at ${minTimestamp} TO ${maxTimestamp}`,
    ])
  }

  if (changelogFilter.published_date_range) {
    const dateGroup = changelogFilter.published_date_range.split(
      ','
    ) as string[]
    const [minTimestamp, maxTimestamp] = dateGroup.map((etc) => {
      return [parseDate(etc, 'yyyy-MM-dd').getTime() / 1000]
    })
    searchFilters.push([
      `numeric_published_at ${minTimestamp} TO ${maxTimestamp}`,
    ])
  }

  if (changelogFilter.scheduled_date_range) {
    const dateGroup = changelogFilter.scheduled_date_range.split(
      ','
    ) as string[]
    const [minTimestamp, maxTimestamp] = dateGroup.map((etc) => {
      return [parseDate(etc, 'yyyy-MM-dd').getTime() / 1000]
    })
    searchFilters.push([
      `numeric_scheduled_at ${minTimestamp} TO ${maxTimestamp}`,
    ])
  }
  if (changelogFilter.labels) {
    searchFilters.push(changelogFilter.labels.map((id) => `labels.id=${id}`))
  }
  if (changelogFilter.authors) {
    searchFilters.push(changelogFilter.authors.map((id) => `user_id=${id}`))
  }

  return searchFilters.filter((filter) => filter.length)
}
