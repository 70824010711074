export const ENV = {
  HOSTED_DOMAIN: process.env.NEXT_PUBLIC_HOSTED_DOMAIN,
  APP_ENV: process.env.NEXT_PUBLIC_APP_ENV,
  USER_SIDE_ANALYTICS_ID: process.env.NEXT_PUBLIC_USER_SIDE_ANALYTICS_ID,
  ADMIN_SIDE_ANALYTICS_ID: process.env.NEXT_PUBLIC_ADMIN_SIDE_ANALYTICS_ID,
  ANALYTICS_URL: process.env.NEXT_PUBLIC_ANALYTICS_URL,
  PADDLE_ANALYTICS_AUTHKEY: process.env.NEXT_PUBLIC_PADDLE_ANALYTICS_AUTHKEY,
  KB_DOMAIN: process.env.NEXT_PUBLIC_KB_DOMAIN,
  PROTOCOL: process.env.NODE_ENV === 'production' ? 'https' : 'http',
  NODE_ENV: process.env.NODE_ENV,
}
