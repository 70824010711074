import { X } from '@phosphor-icons/react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { type VariantProps, cva } from 'class-variance-authority'
import clsx from 'clsx'
import type { ElementRef, FC, HTMLAttributes, ReactNode } from 'react'
import React, { useState } from 'react'

import clsxm from '@/lib/clsxm'

const dialogOverlayVariants = cva(
  'fixed inset-0 z-[6] grid overflow-y-auto overflow-x-hidden bg-gray-900/50 transition-opacity duration-200 dark:bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0'
)

const dialogContentVariants = cva(
  // Base styles common to all modes/sizes
  'fixed z-[6] flex flex-col overflow-hidden bg-snow shadow-lg transition-all duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 dark:bg-gray-900 border border-gray-200 dark:border-gray-700',
  {
    variants: {
      mode: {
        // Add positioning, padding, rounding etc. specific to default modal
        default:
          'left-[50%] top-1/2 h-auto w-11/12 -translate-x-1/2 -translate-y-1/2 transform pointer-events-auto p-6 max-h-[85vh] md:rounded-lg md:p-3 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
        // Keep specific mode styles
        full: 'top-0 w-full place-items-center overflow-y-auto shadow-none rounded-none h-screen', // Explicitly use h-screen
        aside:
          'left-0 top-0 h-screen min-w-[230px] max-w-[20rem] place-items-start overflow-hidden rounded-none data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left',
        drawer:
          'right-0 top-0 h-screen w-5/6 max-w-[20rem] place-items-end overflow-y-auto p-4 drop-shadow-2xl data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right sm:min-w-[420px] lg:m-5 lg:h-[calc(100vh-40px)] lg:rounded-md', // Kept min-w-full originally, but max-w seems more appropriate? Adjusted based on old CSS.
      },
      // Size variants - should only apply when mode='default'
      size: {
        xs: 'max-w-xs', // Removed overflow-y-auto, handle scrolling within content if needed or per mode
        sm: 'max-w-md', // Adjusted responsive min-width for default modal
        md: 'max-w-2xl',
        lg: 'max-w-4xl',
        xl: 'mx-auto w-[calc(100vw-10px)] sm:max-w-6xl',
        full: 'max-w-5xl', // This size variant likely makes less sense outside default mode
      },
      backdropAnimate: {
        true: 'ring-2 ring-red-500 ring-offset-2',
        false: '',
      },
    },
    // Remove compound variants - logic handled by separating base and default styles
    // compoundVariants: [
    //    { mode: ['aside', 'drawer', 'full'], size: ['xs', 'sm', 'md', 'lg', 'xl', 'full'], className: '' },
    //    { mode: undefined, size: 'sm', className: 'md:rounded-lg p-6 md:p-3' }
    // ],
    defaultVariants: {
      mode: 'default',
      size: 'sm',
      backdropAnimate: false,
    },
  }
)

const dialogTitleVariants = cva(
  'mr-6 pr-2 text-base font-medium leading-6 text-gray-900 dark:text-gray-50'
)

const dialogDescriptionVariants = cva(
  'mb-2 w-full whitespace-pre-line text-gray-500 dark:text-gray-400'
)

const dialogCloseVariants = cva(
  'absolute right-[50px] top-2 flex h-4 w-4 scale-100 cursor-pointer appearance-none items-center justify-center rounded-md bg-gray-100 transition-all hover:scale-105 hover:bg-gray-300 active:scale-95 dark:bg-gray-800 dark:hover:bg-gray-700 md:right-3'
)

const dialogCloseIconVariants = cva(
  'h-3 w-3 shrink-0 text-gray-500 dark:text-gray-400'
)

// --- Prop Types (minor adjustments might be needed based on cva) ---

interface DialogPropType extends DialogPrimitive.DialogProps {
  children?: React.ReactNode
  onOpen?: (...args: any[]) => any
  onClose?: (...args: any[]) => any
  modal?: boolean
  className?: string // Keep className for overrides
  asChild?: boolean
}

// Adjust DialogContentPropType to extend VariantProps
interface DialogContentPropType
  extends Omit<DialogPrimitive.DialogContentProps, 'size'>, // Omit original size if conflicting
    VariantProps<typeof dialogContentVariants> {
  backdrop?: boolean
  container?: HTMLElement | undefined | null
  // overlay?: Boolean // Seems unused? Keep if needed.
  className?: string
  headerTitle?: string
  rightComponents?: ReactNode
}

// --- Components ---

const DialogContent = React.forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  DialogContentPropType
>(
  (
    {
      size, // Use size from VariantProps
      children,
      backdrop,
      container,
      className = '',
      mode,
      headerTitle,
      rightComponents,
      ...props
    },
    ref
  ) => {
    const [backdropAnimate, setBackdropAnimate] = useState(false)

    const handleBackdrop = (event: any) => {
      if (backdrop) {
        event.preventDefault()
        // Simplified backdrop check - check if the click is directly on the overlay
        if (event.target === event.currentTarget) {
          setBackdropAnimate(true)
          setTimeout(() => setBackdropAnimate(false), 150) // Keep visual feedback
        }
      }
      // Allow closing by default unless backdrop prevents it
      // Radix handles closing on Escape and pointer down outside by default
      // We only prevent default if backdrop is true and click is inside content
    }

    return (
      <DialogPrimitive.Portal container={container || undefined}>
        <DialogPrimitive.Overlay
          className={clsxm(dialogOverlayVariants(), 'backdrop-blur-sm')} // Added blur example
          data-modal-overlay // Keep this if used elsewhere
        />
        <DialogPrimitive.Content
          ref={ref}
          onPointerDownOutside={(event: any) => {
            // Prevent closing if backdrop is enabled and click is NOT on overlay
            if (backdrop && event.target !== event.currentTarget) {
              handleBackdrop(event) // Still show animation
            } else if (!backdrop) {
              // Default Radix behavior (close)
            } else {
              // Click was on overlay with backdrop=true, allow potential animation but Radix likely won't close
              handleBackdrop(event)
            }
          }}
          onEscapeKeyDown={(event: any) => {
            if (backdrop) {
              event.preventDefault() // Prevent closing on escape if backdrop is true
              handleBackdrop(event) // Show animation
            }
            // If backdrop is false, Radix handles closing
          }}
          {...props}
          // Apply CVA variants, handling potential undefined mode/size
          className={clsxm(
            dialogContentVariants({
              mode: mode ?? 'default',
              size: mode === 'default' || !mode ? size : undefined, // Only apply size if mode is default
              backdropAnimate,
            }),
            className
          )}
          data-modal={true} // Keep this if used elsewhere
        >
          {mode === 'full' ? ( // Use mode here instead of size === 'full'
            <div className='flex h-full w-full flex-col'>
              {' '}
              {/* Ensure container takes full height */}
              {/* Assume header styling is handled within the children or specific use case */}
              <div className='flex w-full flex-shrink-0 items-center justify-between border-b bg-snow px-4 py-2 dark:border-gray-700 dark:bg-gray-900'>
                {' '}
                {/* Prevent header shrink */}
                <span>{headerTitle}</span>
                <span className='flex items-center space-x-2'>
                  <span className='space-x-2 pr-3'>{rightComponents}</span>
                  {/* Consider adding DialogClose here automatically? */}
                </span>
              </div>
              {/* Remove extra padding div, let children manage their own padding if needed, ensure it can grow */}
              <div className='flex-grow overflow-auto'>
                {' '}
                {/* Allow content to scroll */}
                {children}
              </div>
            </div>
          ) : (
            children
          )}
          {/* Optionally add DialogClose here for non-full modes if standard */}
          {/* <DialogClose /> */}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    )
  }
)
DialogContent.displayName = DialogPrimitive.Content.displayName

// --- Title, Description, Close ---

// Interface for Title (inherits DialogPropType for className)
interface DialogTitleProps
  extends HTMLAttributes<HTMLHeadingElement>,
    DialogPropType {}

const DialogTitle = React.forwardRef<
  ElementRef<typeof DialogPrimitive.Title>,
  DialogTitleProps
>(({ children, className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    {...props}
    className={clsxm(dialogTitleVariants(), className)} // Use CVA + override
  >
    {children}
  </DialogPrimitive.Title>
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

// Interface for Description (inherits DialogPropType for className)
interface DialogDescriptionProps
  extends HTMLAttributes<HTMLParagraphElement>,
    DialogPropType {}

const DialogDescription = React.forwardRef<
  ElementRef<typeof DialogPrimitive.Description>,
  DialogDescriptionProps
>(({ children, className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    {...props}
    className={clsx(dialogDescriptionVariants(), className)} // Use CVA + override
  >
    {children}
  </DialogPrimitive.Description>
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

// Interface for Close (inherits Radix props directly)
interface DialogCloseProps extends DialogPrimitive.DialogCloseProps {}

const DialogClose = React.forwardRef<
  ElementRef<typeof DialogPrimitive.Close>,
  DialogCloseProps
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Close
    ref={ref}
    {...props}
    className={clsx(dialogCloseVariants(), className)} // Use CVA + override
    data-testid='dialog-close'
  >
    <X weight='bold' className={dialogCloseIconVariants()} />
  </DialogPrimitive.Close>
))
DialogClose.displayName = DialogPrimitive.Close.displayName

// --- Trigger ---

// Interface for Trigger (inherits Radix props directly)
interface DialogTriggerPropType extends DialogPrimitive.DialogTriggerProps {
  // Keep custom props if needed
  onOpen?: (...args: any[]) => any
  onClose?: (...args: any[]) => any
}

const DialogTrigger = React.forwardRef<
  ElementRef<typeof DialogPrimitive.Trigger>,
  DialogTriggerPropType
>(({ children, ...props }, ref) => (
  <DialogPrimitive.Trigger ref={ref} {...props}>
    {children}
  </DialogPrimitive.Trigger>
))
DialogTrigger.displayName = DialogPrimitive.Trigger.displayName

// --- Root Dialog Component (Handles state) ---

const Dialog: FC<DialogPropType> = ({
  children,
  open,
  onClose,
  onOpen,
  modal = true,
  ...props
}) => {
  const currentState = React.useRef(open)
  // Use controlled state from props if provided, otherwise internal state
  const [internalOpen, setInternalOpen] = React.useState(open ?? false)
  const isControlled = open !== undefined
  const dialogOpen = isControlled ? open : internalOpen

  // Effect for handling onOpen/onClose callbacks
  React.useEffect(() => {
    // Check previous state to only call callbacks on change
    if (dialogOpen && !currentState.current && onOpen) {
      onOpen()
    }
    if (!dialogOpen && currentState.current && onClose) {
      onClose()
    }
    currentState.current = dialogOpen
  }, [dialogOpen, onOpen, onClose])

  // Update internal state if controlled `open` prop changes
  React.useEffect(() => {
    if (isControlled) {
      // This might not be strictly necessary if parent manages state correctly
      // but can help sync if the prop changes unexpectedly.
      // setInternalOpen(open); // Reconsider if this causes issues
    }
  }, [open, isControlled])

  const handleOpenChange = (newOpenState: boolean) => {
    if (!isControlled) {
      setInternalOpen(newOpenState)
    }
    // Call Radix's onOpenChange if provided in props
    props.onOpenChange?.(newOpenState)

    // Trigger internal callbacks based on the change
    if (newOpenState && onOpen) {
      // Check if it wasn't already open (relevant for controlled)
      if (!currentState.current) onOpen()
    } else if (!newOpenState && onClose) {
      // Check if it was open (relevant for controlled)
      if (currentState.current) onClose()
    }
    currentState.current = newOpenState // Update ref immediately after change
  }

  return (
    <DialogPrimitive.Root
      open={dialogOpen}
      modal={modal}
      onOpenChange={handleOpenChange} // Use combined handler
      {...props}
    >
      {children}
    </DialogPrimitive.Root>
  )
}

// DialogClose.displayName = 'DialogClose' // Already set above
Dialog.displayName = 'Dialog'
// No default export if components are exported individually
// export default Dialog // Remove default export

// --- Exports ---
// Export all components for individual usage
export {
  Dialog, // Root component
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  // DialogPortal, DialogOverlay // Re-export Radix ones if needed directly
}
