import { useRouter } from 'next/router'
import React, { useMemo } from 'react'

import NextImage from '@/components/shared/ui/Image/NextImage'
import ArrowLink from '@/components/shared/ui/Links/ArrowLink'
import Typography from '@/components/shared/ui/Typography'
import { settingsHelpLinks } from '@/config/staticURLs'
import { useTranslations } from '@/hooks/useTranslations'
import { adminSideSettingPath } from '@/lib/helpers/pathHelpers'

const AVAILABLE_INTEGRATIONS = [
  {
    name: 'Slack',
    logo: `${settingsHelpLinks.cdnLogo}/slack.svg`,
  },
  {
    name: 'Intercom',
    logo: `${settingsHelpLinks.cdnLogo}/intercom-blue.svg`,
  },
  {
    name: 'Linear',
    logo: `${settingsHelpLinks.cdnLogo}/linear.svg`,
  },
  {
    name: 'Salesforce',
    logo: `${settingsHelpLinks.cdnLogo}/salesforce.svg`,
  },
  {
    name: 'Asana',
    logo: `${settingsHelpLinks.cdnLogo}/asana.svg`,
  },
]

export default function AvailableIntegrationModal({
  show = false,
}: {
  show?: boolean
}) {
  const router = useRouter()
  const { query } = router
  const t = useTranslations('board')

  const showSection = useMemo(() => {
    return Boolean(query.integration_prompt === 'true') || show
  }, [query, show])

  if (showSection) {
    return (
      <div className='mx-auto mt-5 flex w-full max-w-3xl flex-col overflow-hidden rounded-lg border border-gray5 px-4 py-3 shadow-lg '>
        <Typography.Title level={5}>
          {t('integrationPrompt.description')}
        </Typography.Title>
        <div className='mt-3'>
          <div className='flex h-14 w-14 flex-row space-x-4'>
            {AVAILABLE_INTEGRATIONS.map((integration) => (
              <NextImage
                key={integration.name}
                src={integration.logo}
                className='mt-0.5 flex shrink-0'
                alt={integration.name}
                height='35'
                width='35'
              />
            ))}
          </div>
        </div>
        <ArrowLink
          className='float-right mt-3 justify-end text-primary'
          href={adminSideSettingPath('integrations')}
        >
          {t('integrationPrompt.buttonText')}
        </ArrowLink>
      </div>
    )
  }

  return <></>
}
