import React from 'react'

import ErrorPage from '@/components/shared/components/ErrorPage'
import { useTranslations } from '@/hooks/useTranslations'

export default function FourNotFour() {
  const t = useTranslations('common.messages.pageMessages.fourNotFour')
  return (
    <ErrorPage
      error={{
        status: '404',
        message: t('title', { fallback: () => `We couldn't find it!` }),
        description: t('message', {
          fallback: () =>
            `The page you're looking for doesn't exist. It may have been deleted or moved.`,
        }),
        ctaText: t('cta', { fallback: () => 'Go back home' }),
        ctaComponent: 'a',
      }}
    />
  )
}
