import { deletedAssigneeOrSubmitter } from '@/config/module/postsConstants'
import { formatJSONTimestamp } from '@/lib/helpers/dateHelpers'
import type { ITagData } from '@/types/organization'
import type { IPost, IPostBucket, IPostCustomStatus } from '@/types/post'
import type { IUserProfileMini, IUserProfileWithoutStar } from '@/types/user'

interface IFlatPost extends IPost {
  submitter_id: string
  assignee_id?: string | null
  tags_ids: string[]
  custom_state_id: string
}

interface IAdminPostListMap {
  feature_requests: IFlatPost[]
  tags: ITagData[]
  users: IUserProfileWithoutStar[]
  buckets: IPostBucket[]
  custom_states: IPostCustomStatus[]
}

interface IUsersMap {
  [key: string]: IUserProfileWithoutStar
}
interface ITagsMap {
  [key: string]: ITagData
}
interface IBucketsMap {
  [key: string]: IPostBucket
}

interface ICustomStatesMap {
  [key: string]: IPostCustomStatus
}

export const transformPostList = ({
  feature_requests,
  tags = [],
  users = [],
  buckets = [],
  custom_states,
}: IAdminPostListMap): IPost[] => {
  try {
    const usersMap: IUsersMap = {}
    users.forEach((user) => {
      usersMap[user.id] = user
    })

    const tagsMap: ITagsMap = {}
    tags.forEach((tag) => {
      tagsMap[tag.id] = tag
    })

    const bucketsMap: IBucketsMap = {}
    buckets.forEach((bucket) => {
      bucketsMap[bucket.id] = bucket
    })

    const customStatesMap: ICustomStatesMap = {}
    custom_states.forEach((customState) => {
      customStatesMap[customState.id] = customState
    })

    const newPosts = feature_requests.map((post) => {
      const customState = customStatesMap[
        post.custom_state_id
      ] as IPostCustomStatus
      return {
        ...post,
        submitter:
          (usersMap[post.submitter_id] as IUserProfileMini) ||
          deletedAssigneeOrSubmitter,
        assignee: post.assignee_id
          ? (usersMap[post.assignee_id] as IUserProfileWithoutStar) ||
            deletedAssigneeOrSubmitter
          : undefined,
        tags: (post.tags_ids || [])
          .map((tag_id) => tagsMap[tag_id])
          .filter((t) => !!t) as ITagData[],
        bucket: bucketsMap[post.bucket_id] as IPostBucket,
        custom_status:
          customState ??
          custom_states.find((cs) => cs.value === 'under_review'),
        createdTimeText: formatJSONTimestamp(
          post?.created_at?.timestamp ?? post?.created_at,
          'MMM dd'
        ),
        createdTimeTooltip: formatJSONTimestamp(
          post?.created_at?.timestamp ?? post?.created_at,
          'PPP'
        ),
        status: customState
          ? {
              value: customState.status,
              title: customState.title,
            }
          : {
              value: 'under_review',
              title: 'Under Review',
            },
      }
    })
    return newPosts
  } catch (error) {
    console.error(error)
    return []
  }
}
