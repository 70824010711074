import type { IModels } from '@/lib/localDB/localDB'
import { setIdxDBData } from '@/lib/localDB/localDB'

// local storage cache constants
export const CACHE_HASH_PREFIX = 'hn-cache#'
export const LOCALSTROAGE_PREFIX = 'hn'
export const ADMIN_POST_DESCRIPTION_KEY = `${CACHE_HASH_PREFIX}hn-show-post-description`
export const ADMIN_POST_FILTERS_KEY = (module: string) =>
  `${CACHE_HASH_PREFIX}hn${module}-post-filters`
export const ADMIN_SSO_DOMAIN_KEY = `${CACHE_HASH_PREFIX}hn-sso-redirect-url`
export const NEW_POST_KEY = `${CACHE_HASH_PREFIX}hn-new-post`
export const NEW_CHANGELOG_KEY = `${CACHE_HASH_PREFIX}hn-new-changelog`
export const NOTIFICATION_KEY = `${CACHE_HASH_PREFIX}hn-notification`
export const POSTS_HIGHLIGHT_KEY = `${CACHE_HASH_PREFIX}hn-post-highlight`
export const SIGN_UP_USER_KEY = `${CACHE_HASH_PREFIX}hn-sign-up-user`
export const CHANGELOG_WIDGET_OPENED_KEY = `${CACHE_HASH_PREFIX}hn-changelog-widget`
export const FILTER_CACHE_KEY = `${CACHE_HASH_PREFIX}hn-filters`
export const FILTER_DISPLAY_CACHE_KEY = `${CACHE_HASH_PREFIX}hn-filters-shown`
export const SELECTED_CANNEDRESPONSE_ID_CACHE_KEY = `${CACHE_HASH_PREFIX}hn-selected-canned-response`
export const ORG_CARD_DETAILS = `${CACHE_HASH_PREFIX}hn-card-details`
export const NOTIFICATION_VIEW_KEY = `${CACHE_HASH_PREFIX}hn-notification-view`
export const CREATED_ORG_KEY = `${CACHE_HASH_PREFIX}hn-created-org`
export const NOTIFICATIONS_FILTERS_KEY = `${CACHE_HASH_PREFIX}hn-notifications-filters`
export const AI_GENERATED_CHANGELOG_LIST_KEY = `${CACHE_HASH_PREFIX}hn-ai-generated-changelog-list`

export const checkLocalStorageSupport = () => {
  try {
    return 'localStorage' in window && window.localStorage !== null
  } catch (e) {
    return false
  }
}

export const clearAllLocalStorage = (prefix: string) => {
  const localStorageArray = []
  for (let i = 0; i < localStorage.length; i += 1) {
    if (localStorage.key(i)?.startsWith(prefix)) {
      localStorageArray.push(localStorage.key(i))
    }
  }
  for (let i = 0; i < localStorageArray.length; i += 1) {
    const item = localStorageArray[i]
    if (item) {
      localStorage.removeItem(item)
    }
  }
}

export const setLocalStorageData = (key: string, data: any) => {
  if (checkLocalStorageSupport()) {
    try {
      localStorage.setItem(key, JSON.stringify(data))
    } catch (err) {
      // TODO add sentry for localstorage error
    }
  }
}

export const getLocalStorageData = (key: string) => {
  if (!key) return null
  if (checkLocalStorageSupport()) {
    try {
      // @ts-ignore
      if (key) return JSON.parse(localStorage.getItem(key))
    } catch (err) {
      return null
    }
  }
  return null
}

export const iterateAndAddToIdxDB = (module: IModels, data: any[]) => {
  if (!data) return []

  return data.map((item) => {
    const slug = item.slug || item.id
    return setIdxDBData(module, slug, item)
  })
}
