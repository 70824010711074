import { Lock } from '@phosphor-icons/react'
import React, { useContext } from 'react'

import { Pill } from '@/components/shared/ui/Pill/Pill'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IPost } from '@/types/post'

interface IProps {
  post: IPost
}
export default function SinglePostReadOnlyStatus({ post }: IProps) {
  const { userProfile } = useContext(HNContext)
  const t = useTranslations('post.lockPost')

  if (!post.read_only) return null

  if (!userProfile) return null

  if (
    !userProfile?.is_csm_of_organization &&
    userProfile?.id !== post.submitter.id
  )
    return null
  return (
    <Tooltip
      text={`${t('tooltip')} ${
        post.read_only_reason
          ? t('reasonTooltip', {
              data: {
                reason: post.read_only_reason,
              },
            })
          : ''
      }`.trim()}
    >
      <Pill color='gray' className='flex items-center gap-1'>
        <Lock size={16} />{' '}
        {t('common.labels.flags.locked', { ignorePrefix: true })}
      </Pill>
    </Tooltip>
  )
}
