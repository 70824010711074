import React, { useContext, useEffect, useMemo, useState } from 'react'

import AdminPostBoardFilter from '@/components/admin/posts/filters/AdminPostBoardFilter'
import AdminPostParentStatusFilter from '@/components/admin/posts/filters/AdminPostParentStatusFilter'
import AdminPostStatusFilter from '@/components/admin/posts/filters/AdminPostStatusFilter'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Label from '@/components/shared/ui/Label'
import Select from '@/components/shared/ui/Select'
import Typography from '@/components/shared/ui/Typography'
import { THIRD_PARTY_APPS } from '@/config/appConstants'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  convertObjForSelect,
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import type {
  IIntegrationPushRuleKey,
  IIntegrationPushRuleValue,
} from '@/types/integration/github'
import type {
  ILinearIntegrationConfig,
  ILinearPushRule,
} from '@/types/integration/linear'

interface IAdminIntegrationLinearPushRuleProps {
  configData: ILinearIntegrationConfig
  onChange: (
    key: IIntegrationPushRuleKey,
    value: IIntegrationPushRuleValue
  ) => void
  pushRule: ILinearPushRule
  isSubmitting: boolean
  onSave: (data: ILinearPushRule) => void
  useCustomStates?: boolean
}

export default function AdminIntegrationLinearPushRule({
  configData,
  onChange,
  pushRule,
  isSubmitting,
  onSave,
  useCustomStates,
}: IAdminIntegrationLinearPushRuleProps) {
  const t = useTranslations(`integrations.linear.configure`)
  const { organizationSetting, customStatus, custom_states } =
    useContext(HNContext)
  const [autoPush, setAutoPush] = useState<boolean>(pushRule.enabled)
  const [autoPushRule, setAutoPushRule] = useState<ILinearPushRule>(pushRule)
  const [teams, setTeams] = useState(
    convertObjForSelect(configData.teams, 'name', 'id')
  )
  const [projects, setProjects] = useState(
    convertObjForSelect(configData.projects, 'name', 'id')
  )

  const selectedStatus = useMemo(() => {
    if (!customStatus) return ''
    if (useCustomStates) {
      return custom_states?.find((state) => state.slug === autoPushRule.status)
        ?.name
    }
    const status = Object.keys(customStatus).find((key) => {
      return key === autoPushRule.status
    })
    if (status) return customStatus[status]
    return ''
  }, [customStatus, autoPushRule.status])

  const handleChange = (
    key: IIntegrationPushRuleKey,
    value: IIntegrationPushRuleValue
  ) => {
    const newRule = {
      ...autoPushRule,
      [key]: value,
    } as ILinearPushRule
    onChange(key, value)
    if (key === 'team_id') {
      newRule.project_id = ''
      setProjects(
        convertObjForSelect(
          configData.projects.filter((p) =>
            p.team_ids.includes(value as string)
          ) || [],
          `name`,
          `id`
        )
      )
    }
    setAutoPushRule(newRule)
  }

  useEffect(() => {
    setAutoPush(pushRule.enabled)
  }, [pushRule])

  useEffect(() => {
    setTeams(convertObjForSelect(configData.teams, 'name', 'id'))
  }, [configData])

  useEffect(() => {
    if (autoPushRule.team_id) {
      setProjects(
        convertObjForSelect(
          configData.projects.filter((p) =>
            p.team_ids.includes(autoPushRule?.team_id)
          ) || [],
          `name`,
          `id`
        )
      )
    }
  }, [])

  return (
    <div className='mt-4 border-t border-gray5 pt-4 '>
      <div className='flex items-start justify-between py-2'>
        <span>
          <Label
            className='mb-1 !text-sm !font-medium text-carbon transition '
            htmlfor={t(`pushRule.title`)}
          >
            {t(`pushRule.title`)}
          </Label>
          <Typography.Text className='block text-gray8'>
            {t(`pushRule.description`)}
          </Typography.Text>
        </span>
        <Checkbox
          id={t(`pushRule.title`)}
          isSwitch
          checked={autoPush}
          onCheckedChange={(checked) => {
            setAutoPush(checked)
            handleChange('enabled', checked)
          }}
        />
      </div>
      {autoPush && (
        <>
          <div className='grid grid-cols-1 items-center gap-4 pb-2 pt-5 sm:grid-cols-2 md:grid-cols-2'>
            <div className='space-y-1'>
              <Label className='truncate'>{t(`pushRule.when`)}</Label>
              {useCustomStates ? (
                <AdminPostStatusFilter
                  behaviour='select'
                  filters={{ state: autoPushRule.status }}
                  multiple={false}
                  clearable
                  placeholder={t(`common.statusPlaceholder`)}
                  onChange={(_key, value) => handleChange('status', value[0])}
                />
              ) : (
                <AdminPostParentStatusFilter
                  filters={{ status: autoPushRule.status }}
                  multiple={false}
                  placeholder={t(`common.statusPlaceholder`)}
                  onChange={(_key, value) => handleChange('status', value[0])}
                />
              )}
            </div>
            <div className='space-y-1'>
              <Label>
                {t(`pushRule.in`, {
                  data: {
                    boards:
                      organizationSetting?.bucket_plural_name.toLowerCase(),
                  },
                })}
              </Label>
              <AdminPostBoardFilter
                filters={{ bucket_id: autoPushRule.buckets }}
                multiple
                clearable
                placeholder={t(`common.boardsPlaceholder`, {
                  data: {
                    boards:
                      organizationSetting?.bucket_plural_name.toLowerCase(),
                  },
                })}
                onChange={(_key, value) => handleChange('buckets', value)}
              />
            </div>
            <div className='space-y-1'>
              <Label>{t(`pushRule.select`)}</Label>
              <Select
                placeholder={t(`pushRule.team.placeholder`)}
                searchPlaceholder={t(`pushRule.team.searchPlaceholder`)}
                loading={false}
                value={setSelectedValue(teams, autoPushRule.team_id || '')}
                searchable
                size='xs'
                options={teams}
                onChange={(value) => {
                  const selectedValue = getSelectedValueByKey(teams, value)
                  if (selectedValue && Array.isArray(selectedValue)) {
                    handleChange('team_id', selectedValue[0]?.value)
                  } else {
                    handleChange('team_id', selectedValue?.value)
                  }
                }}
              />
            </div>
            <div className='space-y-1'>
              <Label>{t(`pushRule.inProject`)}</Label>
              <Select
                placeholder={t(`pushRule.project.placeholder`)}
                searchPlaceholder={t(`pushRule.project.searchPlaceholder`)}
                loading={false}
                value={setSelectedValue(
                  projects,
                  autoPushRule.project_id || ''
                )}
                searchable
                size='xs'
                clearable
                options={projects}
                onChange={(value) => {
                  const selectedValue = getSelectedValueByKey(projects, value)
                  if (selectedValue && Array.isArray(selectedValue)) {
                    handleChange('project_id', selectedValue[0]?.value)
                  } else {
                    handleChange('project_id', selectedValue?.value)
                  }
                }}
                disabled={!autoPushRule.team_id}
              />
            </div>
            <div className='space-y-1' />
            <div className='space-y-4 md:col-span-2'>
              <Checkbox
                label={t(`integrations.pushRule.pushOnCreate.label`, {
                  ignorePrefix: true,
                  data: {
                    status: selectedStatus,
                    productName: THIRD_PARTY_APPS.linear.name,
                  },
                })}
                checked={autoPushRule.enable_during_creation}
                onCheckedChange={(value) =>
                  handleChange('enable_during_creation', value)
                }
              />
              <Checkbox
                label={t(`integrations.pushRule.pushOnApprove.label`, {
                  ignorePrefix: true,
                  data: {
                    status: selectedStatus,
                    productName: THIRD_PARTY_APPS.linear.name,
                  },
                })}
                checked={autoPushRule.enable_during_approval}
                onCheckedChange={(value) =>
                  handleChange('enable_during_approval', value)
                }
              />
            </div>
          </div>
        </>
      )}
      <div className='mt-3 flex items-center justify-end'>
        <Button
          size='xs'
          loading={isSubmitting}
          disabled={
            isSubmitting ||
            JSON.stringify(autoPushRule) ===
              JSON.stringify(configData.push_rule)
          }
          onClick={() => onSave(autoPushRule)}
        >
          {t(`buttons.save`, {
            ignorePrefix: true,
          })}
        </Button>
      </div>
    </div>
  )
}
