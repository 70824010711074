import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Spinner from '@/components/shared/ui/Loader'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getIntegrationConfig,
  updateIntegrationConfig,
} from '@/models/Integration'
import type {
  ISalesforceAccountField,
  ISalesforceConfiguration,
  ISalesforceConfigurationProps,
} from '@/types/integration/salesforce'
import toaster from '@/utils/toast'

export default function SalesforceConfiguration(
  props: ISalesforceConfigurationProps
) {
  const t = useTranslations(`integrations.salesforce`)
  const router = useRouter()
  const { provider, integrated } = router.query
  const { onDisconnect } = props
  const [showConfig, setShowConfig] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [configData, setConfigData] = useState<ISalesforceConfiguration>()
  const [accountFields, setAccountFields] = useState<ISalesforceAccountField[]>(
    []
  )

  const fetchConfiguration = () => {
    if (!configData) {
      setIsLoading(true)
    } else {
      setIsSyncing(true)
    }

    return getIntegrationConfig('salesforce')
      .then((data) => {
        if (configData) {
          setConfigData({
            ...configData,
            ...data,
          })
        } else {
          setConfigData(data)
        }
        setAccountFields(data.account_fields)
      })
      .catch(toaster.error)
      .finally(() => {
        setIsLoading(false)
        setIsSyncing(false)
      })
  }

  const handleConfigUpdate = (key: string, value: any) => {
    const newConfig = {
      ...configData,
      [key]: value,
    } as ISalesforceConfiguration

    setConfigData(newConfig)
  }

  const handleDisconnect = () => {
    onDisconnect()
  }

  const handleSubmit = () => {
    setIsSubmitting(true)

    return updateIntegrationConfig(
      'salesforce',
      configData?.id.toString() || '',
      {
        sync_fields: configData?.sync_fields,
        sync_accounts: configData?.sync_accounts,
        sync_contacts: configData?.sync_contacts,
      }
    )
      .then(() => {
        toaster.success({
          message: t(`messages.successfullySaved`, {
            ignorePrefix: true,
          }),
        })
      })
      .catch((error) => toaster.error({ message: error.message }))
      .finally(() => setIsSubmitting(false))
  }

  const handleFieldSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const fields = configData
      ? configData.account_fields.filter((field) =>
          field.label.toLowerCase().includes(value.toLowerCase())
        )
      : []
    setAccountFields(fields)
  }

  const handleSyncFieldsChange = (key: string, value: boolean) => {
    if (!configData) return

    const fields = value
      ? [...configData.sync_fields, key]
      : configData.sync_fields.filter((field) => field !== key)
    handleConfigUpdate('sync_fields', fields)
  }

  const renderLoadingOrEmpty = () => {
    if (isLoading) return <Spinner />
    if (configData) return <></>

    return <EmptyState description={t(`config.empty`)} />
  }

  const renderConfigurations = () => {
    if (!configData) return <></>

    return (
      <div className='mt-2 space-y-2 border-t border-gray5 pt-4 '>
        <div className='space-y-2 pb-2'>
          <div className='space-y-1'>
            <Typography.Title level={5}>
              {t(`config.appComponent.title`)}
            </Typography.Title>
            <Typography.Text type='secondary'>
              {t(`config.appComponent.description`)}
            </Typography.Text>
          </div>
          <UnstyledLink
            href={configData.app_install_link}
            target='_blank'
            rel='noreferrer'
          >
            <Button variant='outline' size='xs' className='mt-2'>
              {t(`config.appComponent.buttonText`)}
            </Button>
          </UnstyledLink>
        </div>
        <div className='mt-2 space-y-2 border-t border-gray5 pt-4 '>
          <Typography.Title level={5}>
            {t(`config.settings.title`)}
          </Typography.Title>
          <div className='space-y-3'>
            <div className='space-y-1'>
              <div className='flex items-center justify-between'>
                <Label htmlfor='syncContacts'>
                  {t(`config.settings.syncContacts.title`)}
                </Label>
                <Checkbox
                  id='syncContacts'
                  isSwitch
                  checked={configData.sync_contacts}
                  onCheckedChange={(checked) => {
                    setConfigData({
                      ...configData,
                      sync_contacts: checked,
                    })
                  }}
                />
              </div>
              <Typography.Text type='secondary'>
                {t(`config.settings.syncContacts.description`)}
              </Typography.Text>
            </div>
            <div className='space-y-1'>
              <div className='flex items-center justify-between transition'>
                <Label htmlfor='syncAccounts'>
                  {t(`config.settings.syncAccounts.title`)}
                </Label>
                <Checkbox
                  id='syncAccounts'
                  isSwitch
                  checked={configData.sync_accounts}
                  onCheckedChange={(checked) => {
                    setConfigData({
                      ...configData,
                      sync_accounts: checked,
                    })
                  }}
                />
              </div>
              <Typography.Text type='secondary'>
                {t(`config.settings.syncAccounts.description`)}
              </Typography.Text>
            </div>
          </div>
          <div className='space-y-1'>
            <Label htmlfor='searchAccountFields'>
              {t(`config.settings.syncAccounts.accountFields.title`)}
            </Label>
            <Input
              placeholder={t(
                `config.settings.syncAccounts.accountFields.searchPlaceholder`
              )}
              onChange={handleFieldSearch}
              id='searchAccountFields'
            />
            <ul className='flex h-96 flex-col overflow-y-auto py-2 scrollbar-hide'>
              {configData &&
                accountFields.map((field) => (
                  <li
                    className='flex items-center space-x-2 p-2'
                    key={field.value}
                  >
                    <Checkbox
                      label={field.label}
                      checked={configData.sync_fields.includes(field.value)}
                      onCheckedChange={(checked) =>
                        handleSyncFieldsChange(field.value, checked)
                      }
                    />
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (provider === 'salesforce' && integrated === 'true') {
      setShowConfig(true)
    } else {
      fetchConfiguration()
    }
  }, [])

  useEffect(() => {
    if (showConfig) fetchConfiguration()
  }, [showConfig])

  return (
    <Dialog open={showConfig} onClose={() => setShowConfig(false)}>
      <DialogTrigger asChild>
        <Button size='xs' onClick={() => setShowConfig(true)}>
          {t('buttons.configure', {
            ignorePrefix: true,
          })}
        </Button>
      </DialogTrigger>
      <DialogContent size='md' backdrop>
        <DialogTitle>
          <div className='flex items-center justify-between'>
            {t(`config.title`)}
            {isSyncing && <SpinnerIcon className='h-4 w-4 animate-spin' />}
          </div>
        </DialogTitle>
        {isLoading || !configData
          ? renderLoadingOrEmpty()
          : renderConfigurations()}
        {!isLoading && (
          <div className='mb-2 mt-4 flex w-full justify-end space-x-2'>
            <AlertDialog
              type='danger'
              confirmText={t(`buttons.disconnect`, { ignorePrefix: true })}
              title={t(`disconnectConfirmationTitle`)}
              description={t(`disconnectConfirmationMessage`)}
              onConfirm={handleDisconnect}
            >
              <Button variant='danger' size='xs' disabled={isSubmitting}>
                {t(`buttons.disconnect`, { ignorePrefix: true })}
              </Button>
            </AlertDialog>
            <Button
              variant='primary'
              size='xs'
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              {t(`buttons.save`, { ignorePrefix: true })}
            </Button>
          </div>
        )}
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
