import { DotsThree, Info, MinusCircle, Trash } from '@phosphor-icons/react'
import React, { useContext, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  blockInteractor,
  deleteInteractor,
  unblockInteractor,
} from '@/models/User'
import type { IInteractorRecord } from '@/types/user'
import toaster from '@/utils/toast'

interface IPropTypes {
  user: IInteractorRecord
  userId: string
  onOpen?: () => any
  onUpdate?: (user: IInteractorRecord) => any
  onDelete?: (user: IInteractorRecord) => any
}
export default function AdminSingleUserActions({
  userId,
  user,
  onOpen,
  onUpdate,
  onDelete,
}: IPropTypes) {
  const t = useTranslations('users.userItem')
  const { userProfile } = useContext(HNContext)
  const [deleting, setDeleting] = useState(false)
  const [blocking, setBlocking] = useState(false)
  const [blockDropdown, setBlockDropdown] = useState(false)
  const [deleteDropdown, setDeleteDropdown] = useState(false)
  const disabled = userProfile?.id === user.id

  const handleBlock = (rec: IInteractorRecord) => {
    setBlocking(true)
    const func = rec.blocked ? unblockInteractor : blockInteractor
    return func(userId)
      .then((result) => {
        if (onUpdate) onUpdate({ ...rec, ...result })
        if (rec.blocked) {
          toaster.success({ message: t('actions.unblock.messages.success') })
        } else {
          toaster.success({ message: t('actions.block.messages.success') })
        }
      })
      .finally(() => setBlocking(false))
  }

  const handleDelete = () => {
    setDeleting(true)
    return deleteInteractor(userId)
      .then(() => {
        if (onDelete) onDelete(user)
        toaster.success({ message: t('actions.delete.messages.success') })
      })
      .finally(() => setDeleting(false))
  }

  if (disabled && !onOpen) return <></>
  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button fab variant='outline' as={'span'}>
            <DotsThree
              weight='bold'
              className='h-3.5 w-3.5 shrink-0 text-gray11'
            />
          </Button>
        </DropdownTrigger>
        <DropdownContent align='end'>
          {Boolean(onOpen) && (
            <DropdownItem
              itemValue={t('buttons.info', { ignorePrefix: true })}
              disabled={blocking}
              onSelect={onOpen}
              icon={<Info />}
            />
          )}
          {Boolean(!user.is_csm_of_organization && !disabled) && (
            <>
              {(user.can_block || user.blocked) && (
                <DropdownItem
                  disabled={blocking}
                  icon={<MinusCircle />}
                  onSelect={() => setBlockDropdown(true)}
                  itemValue={
                    user.blocked
                      ? t('buttons.unblock', { ignorePrefix: true })
                      : t('buttons.block', { ignorePrefix: true })
                  }
                />
              )}
              {user.can_delete && (
                <DropdownItem
                  icon={<Trash className='text-red10' />}
                  disabled={deleting}
                  onSelect={() => setDeleteDropdown(true)}
                  itemValue={t('buttons.remove', { ignorePrefix: true })}
                  textClassName='!text-red10'
                />
              )}
            </>
          )}
        </DropdownContent>
      </Dropdown>
      <AlertDialog
        open={blockDropdown}
        onOpen={() => setBlockDropdown(true)}
        onClose={() => setBlockDropdown(false)}
        type={user.blocked ? undefined : 'danger'}
        title={
          user.blocked
            ? t('actions.unblock.alertTexts.title')
            : t('actions.block.alertTexts.title')
        }
        description={
          user.blocked
            ? t('actions.unblock.alertTexts.description')
            : t('actions.block.alertTexts.description')
        }
        confirmText={
          user.blocked
            ? t('buttons.unblock', { ignorePrefix: true })
            : t('buttons.block', { ignorePrefix: true })
        }
        onConfirm={() => handleBlock(user)}
      />
      <AlertDialog
        open={deleteDropdown}
        onOpen={() => setDeleteDropdown(true)}
        onClose={() => setDeleteDropdown(false)}
        type='danger'
        confirmText={t('buttons.remove', { ignorePrefix: true })}
        title={t('actions.delete.alertTexts.title')}
        description={t('actions.delete.alertTexts.description', {
          data: {
            name: user.name,
            email: user.email,
          },
        })}
        onConfirm={handleDelete}
      />
    </>
  )
}
