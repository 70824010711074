import { EyeClosed } from '@phosphor-icons/react'
import React, { useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function SinglePostHideToggle({ post }: IPropTypes) {
  const t = useTranslations()
  const [openHidePostAlertDialog, setOpenHidePostAlertDialog] = useState(false)

  const handleUnhidePost = () => {
    EventEmitter.dispatch('ENTITY_UPDATE', {
      entity: ENTITIES.POST_COUNTS,
      actionType: EVENT_ACTION_TYPES.UPDATE,
      fromTab: !post.hidden ? post.status.value : 'hidden',
      toTab: !post.hidden ? 'hidden' : post.status.value,
    })
    toaster.success({ message: t('post.messages.hidden.unhide') })

    return postStore
      .updatePost(
        post.slug,
        { hidden: !post.hidden },
        { ...post, hidden: false }
      )
      .catch((err) => {
        EventEmitter.dispatch('ENTITY_UPDATE', {
          entity: ENTITIES.POST_COUNTS,
          actionType: EVENT_ACTION_TYPES.UPDATE,
          fromTab: 'hidden',
          toTab: post.status.value,
        })
        toaster.error({ message: err.message })
      })
  }

  if (!post.hidden) return <></>

  return (
    <>
      <Button
        icon={<EyeClosed weight='fill' className='h-3.5 w-3.5' />}
        size='xs'
        isResponsive
        variant='outline'
        onClick={() => setOpenHidePostAlertDialog(true)}
      >
        <span className='hidden pl-1 md:block'>{t('post.labels.unhide')}</span>
      </Button>
      <AlertDialog
        type='danger'
        open={openHidePostAlertDialog}
        onOpen={() => setOpenHidePostAlertDialog(true)}
        onClose={() => setOpenHidePostAlertDialog(false)}
        confirmText={post.hidden ? t('buttons.unhide') : t('buttons.hide')}
        cancelText={t('buttons.cancel')}
        title={t('post.unHidePost.alertTexts.title')}
        description={t('post.unHidePost.alertTexts.description')}
        onConfirm={() => handleUnhidePost()}
        onCancel={() => setOpenHidePostAlertDialog(false)}
      />
    </>
  )
}
