import React, { useState } from 'react'

import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { cloneArray } from '@/lib/helpers/dataHelpers'
import type { IJiraConfig } from '@/types/integration'
import type {
  IAdminJiraIntegrationMapping,
  IJiraPushRule,
  IJiraSyncRule,
} from '@/types/integration/jira'

import AdminIntegrationJiraNewSyncRule from './AdminIntegrationJiraNewSyncRule'
import AdminIntegrationJiraSyncRule from './AdminIntegrationJiraSyncRule'

interface IAdminIntegrationJiraSyncStatusProps {
  integrationMapping?: IAdminJiraIntegrationMapping
  configData: IJiraConfig
  pushRule: IJiraPushRule
  onPushRuleChange: (newPushRule: IJiraPushRule, action: string) => void
}
export default function AdminIntegrationJiraSyncStatus({
  pushRule,
  onPushRuleChange,
  configData,
}: IAdminIntegrationJiraSyncStatusProps) {
  const t = useTranslations(`integrations.jira.configure`)
  const allowSync = Boolean(
    pushRule.project_id &&
      pushRule.jira_status_list &&
      Boolean(pushRule.jira_status_list.length > 0)
  )
  const [syncRules, setSyncRules] = useState<IJiraSyncRule[]>(pushRule.rules)

  const handleRemoveSyncRule = (index: number) => {
    const newSyncRules = cloneArray(syncRules)
    newSyncRules.splice(index, 1)
    setSyncRules(newSyncRules)
    const newPushRule = {
      ...pushRule,
      rules: newSyncRules,
    }
    onPushRuleChange(newPushRule, 'save')
  }

  const handleNewSyncRule = (newSyncRule: IJiraSyncRule) => {
    const newSyncRules = [...syncRules, newSyncRule]
    setSyncRules(newSyncRules)
    const newPushRule = {
      ...pushRule,
      rules: newSyncRules,
    }
    onPushRuleChange(newPushRule, 'save')
  }

  return (
    <>
      <div className='mt-5 flex items-center justify-between border-t border-gray5 pt-4 '>
        <Typography.Text className='mb-0 !font-medium !text-gray11 '>
          {t(`statusSync.description`)}
        </Typography.Text>
      </div>
      <div className='space-y-1'>
        {syncRules.map((syncRule: IJiraSyncRule, index: number) => {
          return (
            <div key={index}>
              <AdminIntegrationJiraSyncRule
                syncRule={syncRule}
                handleRemove={handleRemoveSyncRule}
                pushRule={pushRule}
                index={index}
                useCustomStates={configData.jira_info.sync_by_custom_state}
              />
            </div>
          )
        })}
      </div>

      {allowSync && (
        <div className='mt-4 flex'>
          <AdminIntegrationJiraNewSyncRule
            pushRule={pushRule}
            onAdd={handleNewSyncRule}
            useCustomStates={!!configData.jira_info.sync_by_custom_state}
          />
        </div>
      )}
    </>
  )
}
