import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useRef, useState } from 'react'

import HNContext from '@/context/HNContext'

const KanbanNewEntryDialog = dynamic(
  import('@/components/roadmap/kanban/KanbanNewEntryDialog'),
  {
    ssr: false,
  }
)

export default function SingleCustomRoadmapItemWrapper() {
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const currentOpenState = useRef(open)
  const lastURL = useRef(router.asPath)
  const { isAdmin } = useContext(HNContext)

  useEffect(() => {
    // if (
    //   router.pathname === '/roadmap/[roadmapId]' ||
    //   router.pathname === '/roadmap/r/[roadmapId]' ||
    //   router.pathname === '/admin/r/p/[roadmapId]' ||
    //   router.pathname === '/embed/roadmap/[roadmapId]' ||
    //   router.pathname === '/embed/home/roadmap/[roadmapId]'
    // )
    //   return

    if (router.query.roadmapId) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [router.query.roadmapId])

  useEffect(() => {
    if (!open && currentOpenState.current) {
      router.push(lastURL.current, undefined, { shallow: true })
    }
    currentOpenState.current = open
  }, [open])

  useEffect(() => {
    if (router.pathname === '/roadmap/[roadmapId]') {
      lastURL.current = '/roadmap'
    }
    if (router.pathname === '/admin/r/p/[roadmapId]') {
      lastURL.current = '/admin/r'
    }
    if (router.pathname.includes('/admin/r')) {
      lastURL.current = '/admin/r/'
    } else if (router.pathname.includes('/embed/roadmap')) {
      lastURL.current = '/embed/roadmap'
    } else if (router.pathname.includes('/embed/home')) {
      lastURL.current = '/embed/home/roadmap'
    } else if (router.pathname.includes('/roadmap')) {
      lastURL.current = '/roadmap'
    }
  }, [router.pathname, router.query])

  return (
    <KanbanNewEntryDialog
      open={open}
      onClose={() => setOpen(false)}
      noTrigger
      readonly={!isAdmin}
    />
  )
}
