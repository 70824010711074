import { Chats } from '@phosphor-icons/react'
import dynamic from 'next/dynamic'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import type { INewChangelogButtonActions } from '@/components/posts/singlePost/postActions/MarkAsAnswerDialog'
import { MarkAsAnswerDialog } from '@/components/posts/singlePost/postActions/MarkAsAnswerDialog'
import InfiniteScroll from '@/components/shared/components/InfiniteLoaders'
import PostCommentLoader from '@/components/shared/components/PostCommentLoader'
import Alert from '@/components/shared/ui/Alert/Alert'
import EmptyState from '@/components/shared/ui/EmptyState'
import { PAGINATION_LIMIT } from '@/config/appConstants'
import { CommentAnswered } from '@/context/HNCommentContext'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IEntityUpdateEventData } from '@/lib/eventEmitter'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import {
  removeDuplicatesInArray,
  sortArrayOfObjectsMultiple,
} from '@/lib/helpers/dataHelpers'
import {
  getPostComments,
  getPostCommentsFromDB,
  updateCommentInDB,
} from '@/models/Comment'
import type { IComment } from '@/types/comment'
import type { IPost } from '@/types/post'

import CommentItem from './CommentItem'

const CommentForm = dynamic(() => import('./CommentForm'), {
  ssr: false,
})

const SigninAlert = dynamic(
  () => import('@/components/shared/components/SigninAlert'),
  {
    ssr: false,
  }
)

interface IPropTypes {
  post: IPost
}

export default function CommentsList({ post }: IPropTypes) {
  const t = useTranslations('comments')
  const { userProfile, trialExpired } = useContext(HNContext)
  const [comments, setComments] = useState<IComment[]>([])
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [canFetchMore, setCanFetchMore] = useState(false)
  const [show, setShow] = useState(false)
  const page = useRef(1)
  const markAsAnswerDialog = useRef<INewChangelogButtonActions>(null)

  const hasAnsweredComment = useMemo(() => {
    return post.has_solution
  }, [post.has_solution])

  const handleStatusChangeOpen = (comment: IComment) => {
    if (markAsAnswerDialog.current) {
      markAsAnswerDialog.current.open(comment)
    }
  }

  const fetchData = () => {
    if (page.current === 1) {
      setComments([])
    }
    getPostCommentsFromDB(post.slug)
      .then((commentsFromDB) => {
        if (page.current === 1) {
          if (commentsFromDB?.length) {
            setComments(commentsFromDB)
          }
          setLoading(true)
          setShow(true)
        }
      })
      .then(() =>
        getPostComments(post.slug, {
          page: page.current,
          per_page: PAGINATION_LIMIT.comment,
        })
      )
      .then((data: IComment[]) =>
        data.map((comment) => ({
          ...comment,
          isOld: true,
        }))
      )
      .then((data: IComment[]) => {
        setCanFetchMore(data?.length >= PAGINATION_LIMIT.comment)

        setComments((_prevData) => {
          return page.current === 1 ? data : [..._prevData, ...data]
        })
      })
      .catch((err) => setErrorMessage(err.message))
      .finally(() => {
        setLoading(false)
      })
  }

  const handleNewComment = (comment: IComment) => {
    if (+post.id === +comment.feature_request_id) {
      if (comment.parent_id) {
        setComments((oldComments) =>
          oldComments.map((oldComment: IComment) => {
            const commentHasReplies = oldComment?.replies?.filter(
              (c) => c.id !== comment.id
            )?.length
            return String(oldComment.id) === String(comment.parent_id)
              ? {
                  ...oldComment,
                  replies: [
                    ...(commentHasReplies
                      ? // eslint-disable-next-line no-unsafe-optional-chaining
                        oldComment?.replies?.filter((c) => c.id !== comment.id)
                      : []),
                    comment,
                  ],
                }
              : oldComment
          })
        )
      } else {
        setComments((oldComments) => {
          const existingComment = oldComments.find((c) => c.id === comment.id)
          if (existingComment) {
            return oldComments.map((c) => (c.id === comment.id ? comment : c))
          }
          return [comment, ...oldComments]
        })
      }
    }
  }

  const handleDelete = (comment: IComment) => {
    setComments((oldComments) => {
      return oldComments
        .map((c) => ({
          ...c,
          replies: c?.replies?.filter(
            (r) => String(r.id) !== String(comment.id)
          ),
        }))
        .filter((c) => c.id !== comment.id)
    })
  }

  const handleUpdate = (comment: IComment) => {
    if (comment.parent_id) {
      setComments((oldComments) =>
        oldComments.map((oldComment: IComment) => {
          const commentHasReplies = oldComment?.replies?.filter(
            (c) => c.id !== comment.id
          )?.length
          return String(oldComment.id) === String(comment.parent_id)
            ? {
                ...oldComment,
                replies: [
                  ...(commentHasReplies
                    ? // eslint-disable-next-line no-unsafe-optional-chaining
                      oldComment?.replies?.filter((c) => c.id !== comment.id)
                    : []),
                  comment,
                ],
              }
            : oldComment
        })
      )
      updateCommentInDB(post.slug, comment)
    } else {
      setComments((oldComments) =>
        oldComments.map((c) => (c.id === comment.id ? { ...c, ...comment } : c))
      )
    }
  }

  const handleCommentEvents = (eventData: IEntityUpdateEventData): any => {
    if (eventData.entity !== ENTITIES.COMMENT) return
    const incomingComment = eventData.data.data as IComment
    switch (eventData.actionType) {
      case EVENT_ACTION_TYPES.ADD:
        handleNewComment(incomingComment)
        break
      case EVENT_ACTION_TYPES.UPDATE:
        handleUpdate(incomingComment as IComment)
        break
      case EVENT_ACTION_TYPES.DELETE:
        handleDelete(incomingComment as IComment)
        break
      default:
        break
    }
  }

  const updatePage = (incrementor: number) => {
    page.current += incrementor
    fetchData()
  }

  useEffect(() => {
    fetchData()
    EventEmitter.subscribe('ENTITY_UPDATE', handleCommentEvents)
    return () => {
      EventEmitter.unsubscribe('ENTITY_UPDATE', handleCommentEvents)
    }
  }, [post.slug])

  const renderComment = (comment: IComment) => (
    <CommentItem
      comment={comment}
      post={post}
      key={comment.id}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      onAnswerSuccess={handleStatusChangeOpen}
    />
  )

  const filtered_comments = useMemo(() => {
    return sortArrayOfObjectsMultiple(removeDuplicatesInArray(comments, 'id'), [
      'pinned',
      'is_solution',
    ])
  }, [comments])

  const renderContent = () => {
    if (!show) return null
    if (loading && !comments.length) return <PostCommentLoader />
    if (errorMessage) return <div>{errorMessage}</div>
    if (!comments.length)
      return (
        <EmptyState
          customIcon={<Chats size={60} className='text-gray7' />}
          title={t('messages.empty')}
        />
      )
    return (
      <div className='flex flex-col gap-4'>
        {filtered_comments.map(renderComment)}

        <InfiniteScroll
          count={comments.length}
          showIf={Boolean(comments.length)}
          canFetchMore={canFetchMore}
          onIntersect={() => updatePage(1)}
          loaderComponent={<PostCommentLoader />}
        />
      </div>
    )
  }

  return (
    <CommentAnswered.Provider value={{ hasAnswer: hasAnsweredComment }}>
      <div className='mb-7 space-y-4'>
        <div>
          {post.disable_comments && (
            <Alert
              type='info'
              rounded
              message={t('newComment.labels.disabledAlert.title')}
            />
          )}
          {!!(
            !post.allowPublicComment &&
            !post.merged &&
            !userProfile &&
            !post.disable_comments
          ) && <SigninAlert />}
          {(!trialExpired && post.allowPublicComment) ||
          userProfile?.is_csm_of_organization ? (
            <CommentForm
              post={post}
              onNewComment={handleNewComment}
              autoSaveKey={`NEW_COMMENT_DRAFT_${post.id}`}
            />
          ) : null}
        </div>
        {renderContent()}
        {userProfile?.is_csm_of_organization && (
          <MarkAsAnswerDialog
            post={post}
            onUpdate={handleUpdate}
            ref={markAsAnswerDialog}
          />
        )}
      </div>
    </CommentAnswered.Provider>
  )
}
