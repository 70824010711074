import * as React from 'react'

interface IPropTypes {
  size?: number
  percentage?: number
}
const InProgressIcon = (props: IPropTypes) => (
  <svg
    height={props.size || 25}
    width={props.size || 25}
    viewBox='0 0 25 25'
    {...props}
  >
    <circle
      r={3}
      cx={12.5}
      cy={12.5}
      fill='transparent'
      stroke='currentColor'
      strokeWidth={6}
      strokeDasharray={`calc(${props.percentage || 50} * 18.8 / 100) 18.8`}
      transform='rotate(-90) translate(-25)'
    />
    <circle
      r={11}
      cx={12.5}
      cy={12.5}
      fill='transparent'
      stroke='currentColor'
      strokeWidth={3}
    />
  </svg>
)

export default InProgressIcon
