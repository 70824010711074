import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import React, { useMemo } from 'react'

import { useTranslations } from '@/hooks/useTranslations'
import { AppConfig } from '@/utils/AppConfig'

interface IPropTypes {
  title?: string | null
  description?: string
}

export default function AdminPageTitle({
  title,
  description = '',
}: IPropTypes) {
  const t = useTranslations()
  const router = useRouter()
  // const { organizationSetting } = useContext(HNContext) as IRootData

  const titles: {
    [key: string]: string
  } = {
    '/admin/filters': t('posts.savedFilters.title'),
    '/admin/users': t('users.title'),
    '/admin/activity': t('common.recentActivities'),
    '/admin/dashboard': t('analytics.title'),
    '/admin/r/[[...roadmapTab]]': t('sidebar.items.roadmap'),
    '/admin/settings/team/[[...role]]': t('sidebar.items.team'),
    '/admin/settings/billing/[[...billing]]': t('billing.title'),
  }
  const pageTitle = useMemo(() => {
    const currentPageTitle = titles[router.pathname]
    if (!currentPageTitle) return null
    return currentPageTitle
      ? `${currentPageTitle} | ${AppConfig.site_name}`
      : AppConfig.site_name
  }, [title, router.pathname])

  if (pageTitle || title)
    return (
      <NextSeo
        title={pageTitle || `${title} | ${AppConfig.site_name}` || ''}
        description={description}
      />
    )
  return null
}
