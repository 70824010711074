import { CheckCircle, Confetti, Info, XCircle } from '@phosphor-icons/react'
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import clsx from 'clsx'
import type { FC, ReactNode } from 'react'
import React, { useEffect, useRef, useState } from 'react'

import Label from '@/components/shared/ui/Label/Label'
import { useTranslations } from '@/hooks/useTranslations'
import { isPromise } from '@/lib/helpers/appHelpers'

import Button from '../Button/Button'

const renderIcon = (type: string) => {
  const iconClasses = {
    success: 'text-green-500 h-5 w-5',
    danger: 'text-red-500 h-5 w-5',
    confetti: 'text-yellow-500 h-5 w-5',
    info: 'text-blue-500 h-5 w-5',
  }

  if (type === 'success')
    return <CheckCircle weight='fill' className={iconClasses.success} />
  if (type === 'danger')
    return <XCircle weight='fill' className={iconClasses.danger} />
  if (type === 'confetti')
    return <Confetti weight='fill' className={iconClasses.confetti} />
  return <Info weight='fill' className={iconClasses.info} />
}

export type IAlertDialogVariant =
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'link'
  | 'text'

export type IAlertDialogType = 'success' | 'danger' | 'info' | 'confetti'

interface AlertDialogPropTypes {
  type?: IAlertDialogType
  variant?: IAlertDialogVariant
  open?: boolean
  children?: ReactNode
  cancelText?: string
  confirmText?: string
  description?: string
  descriptionComponent?: ReactNode
  onlyCancel?: boolean
  waitForPromise?: boolean
  title?: string
  onConfirm?: (...args: any[]) => any
  onCancel?: (...args: any[]) => any
  onClose?: (...args: any[]) => any
  onOpen?: (...args: any[]) => any
  disabled?: boolean
  confirmValue?: any
  showCheckbox?: boolean
  checked?: boolean
  checkboxLabel?: string
}

const AlertDialog: FC<AlertDialogPropTypes> = ({
  type = 'info',
  variant = 'primary',
  open = false,
  children = null,
  cancelText = '',
  confirmText = '',
  description = '',
  descriptionComponent = null,
  onlyCancel = false,
  waitForPromise = true,
  title = 'Are you sure?',
  onConfirm = () => {},
  onCancel = () => {},
  onClose = () => {},
  onOpen = () => {},
  disabled = false,
  confirmValue: _confirmValue = null,
  showCheckbox = false,
  checked: _checked = false,
  checkboxLabel = '',
  ...props
}) => {
  const t = useTranslations()
  const confirmButtonText = confirmText || t('buttons.confirm')
  const cancelButtonText = cancelText || t('buttons.cancel')
  const [dialogOpen, setDialogOpen] = useState(open)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmValue, setConfirmValue] = useState(_confirmValue)
  const [checked, setChecked] = useState(_checked)
  const currentState = useRef(open)

  const handleConfirm = () => {
    if (onConfirm && typeof onConfirm === 'function') {
      const result = onConfirm(confirmValue, checked)
      if (isPromise(result) && waitForPromise) {
        setIsLoading(true)
        return result
          .then(() => setDialogOpen(false))
          .finally(() => setIsLoading(false))
      }
      setDialogOpen(false)
      return setIsLoading(false)
    }
    return false
  }

  const handleCancel = () => {
    setChecked(false)
    onCancel()
  }

  useEffect(() => {
    setDialogOpen(open)
  }, [open])

  useEffect(() => {
    return () => {
      setDialogOpen(false)
    }
  }, [])

  useEffect(() => {
    setConfirmValue(_confirmValue)
  }, [_confirmValue])

  useEffect(() => {
    if (dialogOpen && !currentState.current) {
      onOpen()
    }
    if (!dialogOpen && currentState.current) {
      onClose()
      setChecked(false)
    }
    currentState.current = dialogOpen
  }, [dialogOpen])

  return (
    <AlertDialogPrimitive.Root
      {...props}
      open={dialogOpen}
      onOpenChange={setDialogOpen}
    >
      <AlertDialogPrimitive.Trigger asChild {...props}>
        {children}
      </AlertDialogPrimitive.Trigger>
      <AlertDialogPrimitive.Portal {...props}>
        <AlertDialogPrimitive.Overlay className='fixed inset-0 bg-black/30 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0' />
        <AlertDialogPrimitive.Content
          {...props}
          data-testid='confirmation_modal'
          className='fixed left-[50%] top-[50%] z-50 w-full max-w-md translate-x-[-50%] translate-y-[-50%] rounded-lg bg-white p-4 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] dark:bg-gray-800'
        >
          <div className='flex items-start gap-3'>
            <div className='mt-1 flex-shrink-0' aria-hidden>
              {renderIcon(type)}
            </div>
            <div className='flex-1'>
              <AlertDialogPrimitive.Title
                {...props}
                className='text-base font-semibold text-gray-900 dark:text-white'
                data-testid='confirmation_modal_title'
              >
                {title}
              </AlertDialogPrimitive.Title>

              {!descriptionComponent && description && (
                <AlertDialogPrimitive.Description
                  className='mt-2 text-sm text-gray-500 dark:text-gray-400'
                  dangerouslySetInnerHTML={{ __html: description }}
                  {...props}
                />
              )}
              {descriptionComponent && !description && (
                <AlertDialogPrimitive.Description
                  className='mt-2 text-sm text-gray-500 dark:text-gray-400'
                  {...props}
                >
                  {descriptionComponent}
                </AlertDialogPrimitive.Description>
              )}
            </div>
          </div>

          <div
            className={clsx(
              'mt-4 flex items-center gap-2',
              showCheckbox ? 'justify-between' : 'justify-end'
            )}
            data-testid='confirmation-modal'
          >
            {showCheckbox && (
              <div className='flex items-center gap-2'>
                <input
                  type='checkbox'
                  className='h-4 w-4 rounded border-gray-300 text-primary focus:ring-2 focus:ring-primary/20 dark:border-gray-600 dark:bg-gray-700'
                  id='alertCheck'
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                />
                <Label
                  htmlfor='alertCheck'
                  className='text-sm font-medium text-gray-700 dark:text-gray-200'
                >
                  {checkboxLabel}
                </Label>
              </div>
            )}
            <div className='flex items-center gap-2'>
              <AlertDialogPrimitive.Cancel asChild>
                <Button
                  variant='text'
                  onClick={handleCancel}
                  dataTestId='confirmation-modal-cancel-button'
                  size='sm'
                >
                  {cancelButtonText}
                </Button>
              </AlertDialogPrimitive.Cancel>
              {!onlyCancel && (
                <Button
                  variant={type === 'danger' ? 'danger' : variant}
                  onClick={handleConfirm}
                  disabled={isLoading || disabled}
                  loading={isLoading}
                  dataTestId='confirmation-modal-confirm-button'
                  size='sm'
                >
                  {confirmButtonText}
                </Button>
              )}
            </div>
          </div>
        </AlertDialogPrimitive.Content>
      </AlertDialogPrimitive.Portal>
    </AlertDialogPrimitive.Root>
  )
}

export default AlertDialog
