import type { AxiosRequestConfig } from 'axios'
import { isArray } from 'highcharts'

import {
  ADD_CHANGELOG_LABEL_PATH,
  ADD_CHANGELOG_REACTION_PATH,
  ADD_CONTRIBUTOR_PATH,
  ADMIN_AI_CHANGELOG_PATH,
  ADMIN_CHANGELOG_PATH,
  ADMIN_CHANGELOG_REMOVE_COVER_PATH,
  ADMIN_CHANGELOG_SETTINGS_PATH,
  ADMIN_CHANGELOGS_PATH,
  CHANGELOG_AUTHORS_PATH,
  CHANGELOG_COMPLETED_POSTS_PATH,
  CHANGELOG_LIST_COUNTS_PATH,
  LINK_POST_TO_CHANGELOG_PATH,
  REMOVE_CHANGELOG_LABEL_PATH,
  REMOVE_CHANGELOG_REACTION_PATH,
  REMOVE_CONTRIBUTOR_PATH,
  RESOURCE_SUBSCRIBE_PATH,
  RESOURCE_SUBSCRIPTION_PATH,
  RESOURCE_USER_SUBSCRIPTION_PATH,
  SHARE_CHANGELOG_PATH,
  UNLINK_POST_FROM_CHANGELOG_PATH,
  USER_API_CHANGELOGS_PATH,
  USER_CHANGELOG_PATH,
  USER_CHANGELOGS_PATH,
  USER_NEXT_API_CHANGELOGS_PATH,
  USER_SSR_CHANGELOG_PATH,
  USER_SSR_CHANGELOGS_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import { sentry } from '@/lib/helpers/appHelpers'
import { buildURL } from '@/lib/helpers/urlHelpers'
import {
  getIdxDBData,
  removeIdxDBData,
  setIdxDBData,
} from '@/lib/localDB/localDB'
import type {
  IAiChangelogParams,
  IChangelog,
  IChangelogAPIParams,
  IChangelogList,
  IChangelogListCounts,
  IChangelogReactAPIParams,
  IChangelogSettings,
  INewChangelogAPIParams,
  IRSSChangelog,
} from '@/types/changelog'
import type {
  IKeyValueMap,
  IListAPIParams,
  IRequestHeaders,
} from '@/types/common'
import type { ISubscriberRecord, IUserProfile } from '@/types/user'

export const getChangelogs = (
  params: IListAPIParams,
  headers: IRequestHeaders = {}
): Promise<IChangelog[]> => {
  return API.get(USER_CHANGELOGS_PATH, params, {
    headers,
  })
}

export const getSSRChangelogs = (
  params: IListAPIParams,
  headers: IRequestHeaders = {}
): Promise<IChangelog[]> => {
  return API.get(USER_SSR_CHANGELOGS_PATH, params, {
    headers,
  })
}

export const getApiChangelogs = (
  headers: IRequestHeaders = {}
): Promise<IRSSChangelog[]> => {
  return API.get(USER_API_CHANGELOGS_PATH, {}, { headers })
}

export const getChangelogSingle = (
  changelogSlug: string,
  headers: IRequestHeaders = {}
): Promise<IChangelog> => {
  return API.get(
    USER_CHANGELOG_PATH(changelogSlug),
    {},
    {
      headers,
    }
  )
}
export const getSSRChangelogSingle = (
  changelogSlug: string,
  headers: IRequestHeaders = {}
): Promise<IChangelog> => {
  return API.get(
    USER_SSR_CHANGELOG_PATH(changelogSlug),
    {},
    {
      headers,
    }
  )
}

export const addChangelogReaction = (data: IChangelogReactAPIParams) =>
  API.post(ADD_CHANGELOG_REACTION_PATH, data)
export const removeChangelogReaction = (
  id: string,
  data: IChangelogReactAPIParams
) => API.delete(REMOVE_CHANGELOG_REACTION_PATH(id), data)

export const getAdminChangelogList = (
  params: IChangelogAPIParams,
  otherOptions: AxiosRequestConfig = {}
): Promise<{
  changelogs: IChangelog[]
  changelogs_count_by_status: IChangelogListCounts
}> => {
  return API.get(ADMIN_CHANGELOGS_PATH, params, otherOptions)
}
export const getChangelogCount = (filters: IChangelogAPIParams) =>
  API.get(CHANGELOG_LIST_COUNTS_PATH, filters).then(
    (data) => data.counts_data.counts
  )

export const getAdminSingleChangelog = (
  changelogSlug: string
): Promise<IChangelog> => API.get(ADMIN_CHANGELOG_PATH(changelogSlug))

export const getCompletedPosts = (queryParams: IChangelogAPIParams) =>
  API.get(CHANGELOG_COMPLETED_POSTS_PATH, queryParams)

export const deleteChangelog = (slug: string) =>
  API.delete(ADMIN_CHANGELOG_PATH(slug))

export const createChangelog = (data: INewChangelogAPIParams) => {
  const formData = new FormData()
  try {
    if (data.feature_image && typeof data.feature_image !== 'string') {
      formData.append('feature_image', data.feature_image)
    }
  } catch (e: any) {
    sentry.log('createChangelog', `Error in createChangelog`)
    sentry.exception(e)
  }

  Object.keys(data).forEach((key) => {
    if (key !== 'feature_image') {
      // @ts-ignore
      if (isArray(data[key])) {
        // @ts-ignore
        data[key].forEach((item) =>
          formData.append(`${key}[]`, JSON.stringify(item))
        )
      } else {
        // @ts-ignore
        formData.append(key, data[key])
      }
    }
  })

  return API.postWithFiles(ADMIN_CHANGELOGS_PATH, formData)
}

export const shareChangelog = (
  slug: string,
  data: { users: string[]; segment_ids?: string[] }
) => API.post(SHARE_CHANGELOG_PATH(slug), data)

export const updateChangelog = (slug: string, data: IChangelogAPIParams) =>
  API.put(ADMIN_CHANGELOG_PATH(slug), data)

export const updateChangelogCover = (slug: string, file: File) => {
  const formData = new FormData()
  formData.append('feature_image', file)
  return API.putWithFiles(ADMIN_CHANGELOG_PATH(slug), formData).then(
    ({ feature_image_url }) => feature_image_url
  )
}

export const removeChangelogCover = (slug: string) =>
  API.post(ADMIN_CHANGELOG_REMOVE_COVER_PATH(slug))

export const addChangelogLabels = (slug: string, data: { tag_id: string }) =>
  API.post(ADD_CHANGELOG_LABEL_PATH(slug), data)

export const removeChangelogLabels = (slug: string, id: string) =>
  API.delete(REMOVE_CHANGELOG_LABEL_PATH(slug, id))

export const linkPostToChangelog = (
  slug: string,
  data: { feature_request_id: string }
) => API.post(LINK_POST_TO_CHANGELOG_PATH(slug), data)

export const unlinkPostFromChangelog = (slug: string, id: string) =>
  API.delete(UNLINK_POST_FROM_CHANGELOG_PATH(slug, id))

// Contributors
export const addChangelogContributor = (data: IUserProfile) =>
  API.post(ADD_CONTRIBUTOR_PATH, data)

export const removeChangelogContributor = (id: string, data: any) =>
  API.delete(REMOVE_CONTRIBUTOR_PATH(id), data)

export const getChangelogSettings = (): Promise<IChangelogSettings> => {
  return API.get(ADMIN_CHANGELOG_SETTINGS_PATH)
}
export const updateChangelogSettings = (params: IKeyValueMap) => {
  return API.post(ADMIN_CHANGELOG_SETTINGS_PATH, params)
}

export const nextApiGetChangelogs = (
  params: IListAPIParams
): Promise<IChangelogList[]> => {
  return API.get(USER_NEXT_API_CHANGELOGS_PATH, params)
}

export const getAIGeneratedChangelog = (data: IAiChangelogParams) => {
  return API.post(ADMIN_AI_CHANGELOG_PATH, data)
}

export const setChangelogToDB = (slug: string, data: any) =>
  setIdxDBData('CHANGELOG', slug, data)

export const getChangelogIdsFromIndexDB = async (
  params: IListAPIParams
): Promise<IChangelog[]> => {
  const url = buildURL('', params)
  const payload = await getIdxDBData('CHANGELOG_IDS', url, 'hash')

  if (
    !payload ||
    !payload.changelogs ||
    !payload.expireAt ||
    payload.expireAt < Date.now()
  ) {
    await removeIdxDBData('CHANGELOG_IDS', url, 'hash')
    return [] as IChangelog[]
  }

  const changelogsFromIds = payload.changelogs?.length
    ? await Promise.all(
        payload.changelogs.map((id: string) =>
          getIdxDBData('CHANGELOG', id, 'id')
        )
      )
    : []
  return changelogsFromIds as IChangelog[]
}

export const setChangelogIdsToIndexDB = async (
  params: IListAPIParams,
  data: IChangelog[]
): Promise<IChangelog[]> => {
  const url = buildURL('', params)
  const payload = {
    hash: url,
    // Cache to expire after 5 hours of non usage
    expireAt: new Date().getTime() + 1000 * 60 * 60 * 5,
    changelogs: data.map((changelog) => changelog.id),
  }
  await setIdxDBData('CHANGELOG_IDS', url, payload, 'hash')
  return data
}

export const subscribeToChangelog = () => {
  return API.post(RESOURCE_SUBSCRIBE_PATH, {
    resource_module: 'changelog',
  })
}

export const unsubscribeToChangelog = () => {
  return API.delete(RESOURCE_SUBSCRIBE_PATH, {
    resource_module: 'changelog',
  })
}

export const getChangelogSubscribers = () => {
  return API.get(RESOURCE_SUBSCRIPTION_PATH, {
    resource_module: 'changelog',
  })
}

export const addChangelogSubscriber = (
  email: string
): Promise<ISubscriberRecord> => {
  return API.post(RESOURCE_SUBSCRIPTION_PATH, {
    resource_module: 'changelog',
    email,
  })
}

export const removeChangelogSubscriber = (userId: string) => {
  return API.delete(RESOURCE_USER_SUBSCRIPTION_PATH(userId), {
    resource_module: 'changelog',
  })
}

export const getAuthors = (): Promise<IUserProfile[]> => {
  return API.get(CHANGELOG_AUTHORS_PATH)
}
