import React, { useContext } from 'react'

import Status from '@/components/posts/Status'
import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import HNContext from '@/context/HNContext'
import type { IFilters } from '@/types/common'
import type { ICustomState } from '@/types/organization'
import type { IPostListAPIParams } from '@/types/post'

const FILTER_KEY = 'status'

interface IPropType {
  onChange?: (key: string, value: any) => void
  filters: IFilters | IPostListAPIParams
  multiple?: boolean
  placeholder?: string
  disabled?: boolean
  clearable?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}

export default function AdminPostParentStatusFilter({
  onChange,
  filters,
  multiple = true,
  placeholder = '',
  disabled = false,
  clearable = false,
  size = 'xs',
}: IPropType) {
  const { custom_states = [], customStatus = {} } = useContext(HNContext)

  const onlyParentStatus = custom_states.filter((st) => st.slug === st.status)

  const statusOptions = (st: ICustomState) => ({
    label: customStatus[st.slug],
    value: st.slug,
    icon: <Status iconOnly status={{ ...st, value: st.name }} />,
    className: `font-medium text-${st.slug}`,
    status: {
      title: st.name,
      value: st.slug,
      ...st,
    },
  })

  const handleChange = (value: ISelectOption | ISelectOption[] | undefined) => {
    if (!onChange) return
    if (!value) {
      onChange(FILTER_KEY, [])
      return
    }
    const selectedValue = Array.isArray(value) ? value : [value]
    const selectedStatus = selectedValue.map((item) => item.value)
    onChange(FILTER_KEY, selectedStatus)
  }

  const getValue = () => {
    if (filters && filters[FILTER_KEY]) {
      const filteredStatus = Array.isArray(filters[FILTER_KEY])
        ? filters[FILTER_KEY]
        : [filters[FILTER_KEY]]
      return onlyParentStatus
        .map(statusOptions)
        .filter((b) =>
          filteredStatus.map((id) => id.toString()).includes(b.value.toString())
        )
    }
    return null
  }

  return (
    <>
      <Select
        size={size}
        disabled={disabled}
        searchable
        value={getValue()}
        clearable={clearable}
        onChange={handleChange}
        options={onlyParentStatus.map(statusOptions)}
        multiple={multiple}
        placeholder={placeholder.toString()}
        inputProps={{
          placeholder,
        }}
      />
    </>
  )
}
