export const EVENTS = {
  ENTITY_UPDATE: 'ENTITY_UPDATE',
  CONFIG_UPDATE: 'CONFIG_UPDATE',
  CONFIG_PUSH: 'CONFIG_PUSH',
  SHORTCUT_TRIGGERED: 'SHORTCUT_TRIGGERED',
  NEW_POST_ADDON: 'NEW_POST_ADDON',
  POST_ADDON_REMOVED: 'POST_ADDON_REMOVED',
  RESET_ADDON_PROVIDER_PUSH: 'RESET_ADDON_PROVIDER_PUSH',
  OPEN_UPGRADE_MODAL: 'OPEN_UPGRADE_MODAL',
  CLOSE_POST: 'CLOSE_POST',
  OPEN_IMAGE: 'OPEN_IMAGE',
  TEAM_COUNTS: 'TEAM_COUNTS',
  UPDATE_BOARD: 'UPDATE_BOARD',
  TOGGLE_COMMAND_PALLET: 'TOGGLE_COMMAND_PALLET',
  OPEN_USER_INFO: 'OPEN_USER_INFO',
  ONBOARDING_STEP_PERFORMED: 'ONBOARDING_STEP_PERFORMED',
  OPEN_COOKIES_MODAL: 'OPEN_COOKIES_MODAL',
}

export const ENTITIES = {
  COMMENT: 'COMMENT',
  TEMPLATE: 'TEMPLATE',
  POST_COUNTS: 'POST_COUNTS',
  COMMENT_COUNTS: 'COMMENT_COUNTS',
  SAVED_FILTER: 'SAVED_FILTER',
  BOARD_MEMBER: 'BOARD_MEMBER',
  CATEGORY: 'CATEGORY',
  POSTS: 'POSTS',
  TEAM: 'TEAM',
  KANBAN_ITEM: 'KANBAN_ITEM',
  ROADMAP: 'ROADMAP',
  ROADMAP_ITEM: 'ROADMAP_ITEM',
  SEARCH: 'SEARCH',
  TOGGLE_PURCHASE_MODAL: 'TOGGLE_PURCHASE_MODAL',
  TOGGLE_SUCCESSFUL_PURCHASE_MODAL: 'TOGGLE_SUCCESSFUL_PURCHASE_MODAL',
  TOGGLE_REMOVE_SEATS_MODAL: 'TOGGLE_REMOVE_SEATS_MODAL',
  POWER_UPS: 'POWER_UPS',
  INTERACTOR: 'INTERACTOR',
  INTEGRATION_LIST: 'INTEGRATION_LIST',
  NOTIFICATIONS: 'NOTIFICATIONS',
  HEADER_BOARD_NAME: 'HEADER_BOARD_NAME',
  TOGGLE_MIGRATE_MODAL: 'TOGGLE_MIGRATE_MODAL',
  TOGGLE_SEARCH_MODAL: 'TOGGLE_SEARCH_MODAL',
  POST_INSIGHTS: 'POST_INSIGHTS',
}

export const ACTIONS = {
  SHOW_NEW_DEPLOYMENT_ALERT: 'SHOW_NEW_DEPLOYMENT_ALERT',
}

export type IEventActionType = 'UPDATE' | 'ADD' | 'DELETE' | 'CLEAR' | 'RESET'

export const EVENT_ACTION_TYPES: Record<IEventActionType, IEventActionType> = {
  UPDATE: 'UPDATE',
  ADD: 'ADD',
  DELETE: 'DELETE',
  CLEAR: 'CLEAR',
  RESET: 'RESET',
}

export type IEventType = keyof typeof EVENTS
type EventAction = (data: any) => any
type EventListItem = {
  [key in IEventType]?: EventAction[]
}

export interface IEntityUpdateEventData {
  actionType: keyof typeof EVENT_ACTION_TYPES
  entity: keyof typeof ENTITIES
  data: {
    id: string
    data: any
  }
  updateKey?: string
}

interface EventEmitterType {
  events: EventListItem
  dispatch: (event: IEventType, data: any) => void
  subscribe: (event: IEventType, func: (data?: any) => any) => void
  unsubscribe: (event: IEventType, func: (data?: any) => any) => void
}

export const EventEmitter: EventEmitterType = {
  events: {},
  dispatch(event, data) {
    if (process.env.NODE_ENV === 'development') {
      // console.log('Event Dispatched : ', event, 'with data : ', data)
    } else {
      // sentry.log('EventEmitter', `Event Dispatched : ${event}`, 'info', data)
    }
    if (this.events && this.events[event]) {
      this.events[event]?.forEach((callback) => callback(data))
    }
  },

  subscribe(event, func) {
    if (!this.events[event]) this.events[event] = []
    this.events[event]?.push(func)
  },

  unsubscribe(event, func) {
    if (!this.events[event]) return
    if (!func) delete this.events[event]
    else {
      this.events[event] = this.events[event]?.filter((callback) => {
        return func.toString() !== callback.toString()
      })
    }
  },
}
