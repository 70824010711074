import { addDays } from 'date-fns'

import { APP_VERSION } from '@/config/appConstants'
import type { IKeyValueMap } from '@/types/common'

import { createHashFromString } from '../helpers/dataHelpers'
import {
  CACHE_HASH_PREFIX,
  clearAllLocalStorage,
  LOCALSTROAGE_PREFIX,
} from '../helpers/localStorageHelper'

// import { sentry } from './utils'

type IModels = 'COMMENTS_DRAFTS' | 'POSTS_DRAFTS' | 'POSTS' | 'TAGS'

export const checkLocalStorageSupport = () => {
  try {
    return 'localStorage' in window && window.localStorage !== null
  } catch (e) {
    return false
  }
}

export const getVersion = () => {
  if (checkLocalStorageSupport()) {
    return localStorage.getItem('hn_app_version')
  }
  return true
}

export const updateVersion = (version: number) => {
  if (checkLocalStorageSupport()) {
    return localStorage.setItem('hn_app_version', version.toString())
  }
  return true
}

export const deleteLocalHashData = () => {
  if (checkLocalStorageSupport()) {
    const keys = Object.keys(localStorage)
    keys.forEach((key) => {
      if (key.startsWith(CACHE_HASH_PREFIX)) {
        localStorage.removeItem(key)
      }
    })
  }
}

export const generateExpiryDate = (days: number) => addDays(new Date(), days)
export const genDataKey = (key: string, model?: IModels) => {
  const hash = createHashFromString(key)
  return model
    ? `${CACHE_HASH_PREFIX}${hash}_${model}`
    : `${CACHE_HASH_PREFIX}${hash}`
}
export const setLocalCacheData = (
  url: string,
  data: any,
  model?: IModels,
  expiry?: number
) => {
  const dataKey = genDataKey(url, model)
  const newData = Array.isArray(data) ? [...data] : { ...data }
  if (checkLocalStorageSupport()) {
    if (expiry) {
      newData.expiryDate = generateExpiryDate(expiry)
    }
    return localStorage.setItem(dataKey, JSON.stringify(newData))
  }
  return false
}

export const removeLocalCacheData = (url: string, model?: IModels) => {
  const dataKey = genDataKey(url, model)
  if (checkLocalStorageSupport()) {
    return localStorage.removeItem(dataKey)
  }
  return false
}

export const getLocalCacheData = (key: string, model?: IModels) => {
  if (checkLocalStorageSupport()) {
    updateVersion(APP_VERSION)
    const dataKey = genDataKey(key, model)
    let data = {}
    try {
      data = JSON.parse(localStorage.getItem(dataKey) || '') || null
      return data
    } catch (err) {
      return null
    }
  }
  return null
}
export const setLocalStorage = (key: string, data: IKeyValueMap) => {
  try {
    return localStorage.setItem(key, JSON.stringify(data))
  } catch (err) {
    return null
  }
}

export const getLocalStorage = (key: string) => {
  try {
    const data = localStorage.getItem(key)
    return data ? JSON.parse(data) : null
  } catch (err) {
    return null
  }
}

export const removeLocalStorage = (key: string) => {
  try {
    return localStorage.removeItem(key)
  } catch (err) {
    return null
  }
}

export const clearIdxDBData = () => {
  clearAllLocalStorage(CACHE_HASH_PREFIX)
  clearAllLocalStorage(LOCALSTROAGE_PREFIX)
}
