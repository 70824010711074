import React, { useEffect, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import DatePicker from '@/components/shared/ui/DatePicker'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import Spinner from '@/components/shared/ui/Loader'
import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import Textarea from '@/components/shared/ui/Textarea'
import { useTranslations } from '@/hooks/useTranslations'
import {
  convertObjForSelect,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import { formatDate } from '@/lib/helpers/dateHelpers'
import {
  searchGitlabAssignees,
  searchGitlabLabels,
  searchGitlabMilestones,
  searchGitlabProjects,
} from '@/models/Integration'
import { createGitlabIssue, getGitlabConfig } from '@/models/integration/Gitlab'
import type {
  IGitlabIntegrationConfig,
  IGitlabIssue,
} from '@/types/integration/gitlab'
import type { IPost } from '@/types/post'
import type { IPostAddon } from '@/types/post/postAddon'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  onLink: (data: IPostAddon) => void
}
export default function CreateGitLabIssue({ post, onLink }: IPropTypes) {
  const t = useTranslations(`post.integrations.gitlab`)
  const [issue, setIssue] = useState<IGitlabIssue>({
    title: post.title,
    description: post.text_description,
    project_id: '',
    feature_request_id: post.id,
    milestone_id: '',
    labels: [],
    assignee_ids: [],
    due_date: '',
    issue_type: 'issue',
    confidential: false,
  })
  const [gitlabData, setGitlabData] = useState<IGitlabIntegrationConfig>()
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filteredRepos, setFilteredRepos] = useState<ISelectOption[]>([])

  const fetchData = () => {
    setIsLoading(true)
    return getGitlabConfig()
      .then((data) => setGitlabData(data))
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsLoading(false))
  }

  const validate = () => !(issue.title && issue.project_id)

  const handleSubmit = () => {
    setIsSubmitting(true)
    createGitlabIssue({
      ...issue,
      due_date: issue.due_date
        ? formatDate(new Date(issue.due_date), 'yyyy-MM-dd')
        : '',
    })
      .then((data) => {
        toaster.success({ message: t(`gitlabIssueCreated`) })
        onLink(data)
      })
      .catch((err) => toaster.error({ message: err.message }))
      .then(() => setIsSubmitting(false))
  }

  const handleChange = (
    key: keyof IGitlabIssue,
    value: string | undefined | string[] | boolean
  ) => {
    const newIssue = { ...issue, [key]: value }
    setIssue(newIssue)
  }

  const handleRepoSearch = (query: string) => {
    return searchGitlabProjects({ query }).then((data) => {
      return convertObjForSelect(data || [], 'name', 'id')
    })
  }

  const handleMilestoneSearch = (query: string) => {
    if (!issue.project_id) return Promise.resolve([])
    return searchGitlabMilestones({ query, project_id: issue.project_id }).then(
      (data) => {
        return convertObjForSelect(data || [], 'title', 'id')
      }
    )
  }

  const handleLabelSearch = (query: string) => {
    if (!issue.project_id) return Promise.resolve([])
    return searchGitlabLabels({ query, project_id: issue.project_id }).then(
      (data) => {
        return convertObjForSelect(data || [], 'name', 'name')
      }
    )
  }

  const handleAssigneeSearch = (query: string) => {
    if (!issue.project_id) return Promise.resolve([])
    return searchGitlabAssignees({ query, project_id: issue.project_id }).then(
      (data) => {
        return convertObjForSelect(data || [], 'username', 'id')
      }
    )
  }

  useEffect(() => {
    if (!gitlabData) fetchData()
  }, [])

  useEffect(() => {
    setIssue({
      title: post.title,
      description: post.text_description,
      feature_request_id: post.id.toString(),
      project_id: gitlabData?.push_rule?.project_id || '',
      milestone_id: '',
      labels: [],
      assignee_ids: [],
      due_date: '',
      issue_type: 'issue',
      confidential: false,
    })
    // remove the last item from the array
    setFilteredRepos(
      convertObjForSelect(gitlabData?.projects || [], 'name', 'id')
    )
  }, [gitlabData])

  if (isLoading) return <Spinner />

  return (
    <div className='mt-3 space-y-3'>
      <Input
        autoFocus
        label={t(`form.title`)}
        onChange={(event) =>
          handleChange('title', (event.target as HTMLInputElement).value)
        }
        type='text'
        placeholder={t(`form.title`)}
        value={issue?.title}
        defaultValue={post.title}
      />
      <div className='w-full'>
        <Textarea
          label={t(`form.description`)}
          placeholder={t(`form.description`)}
          onChange={(event: { target: { value: string } }) =>
            handleChange('description', event.target.value.trim())
          }
        >
          {issue.description}
        </Textarea>
      </div>
      <div>
        <Checkbox
          label={t(`form.confidential.title`)}
          description={t(`form.confidential.description`)}
          checked={issue.confidential}
          onCheckedChange={(checked) => {
            handleChange('confidential', !!checked)
          }}
        />
      </div>
      <div className='w-full space-y-1'>
        <Label>{t(`form.type.title`)}</Label>
        <Select
          searchable
          clearable
          placeholder={t(`form.type.placeholder`)}
          searchPlaceholder={t(`form.type.searchPlaceholder`)}
          options={[
            { label: t(`form.type.issue`), value: 'issue' },
            { label: t(`form.type.incident`), value: 'incident' },
          ]}
          onChange={(selectedValue) => {
            const selectedIssueType = Array.isArray(selectedValue)
              ? selectedValue[0]
              : selectedValue
            handleChange('issue_type', selectedIssueType?.value)
          }}
        />
      </div>
      <div className='w-full space-y-1'>
        <Label>{t(`form.project.title`)}</Label>
        <Select
          searchable
          clearable
          placeholder={t(`form.project.placeholder`)}
          searchPlaceholder={t(`form.project.searchPlaceholder`)}
          options={filteredRepos}
          loadOptions={handleRepoSearch}
          value={setSelectedValue(filteredRepos, Number(issue?.project_id))}
          emptyMessage='Please search for a project'
          emptySearchMessage='No Projects'
          onChange={(selectedValue) => {
            const selectedProject = Array.isArray(selectedValue)
              ? selectedValue[0]
              : selectedValue
            if (selectedProject) {
              const isAlreadyPresent = filteredRepos.find(
                (repo) => repo.value === selectedProject?.value
              )
              if (!isAlreadyPresent) {
                setFilteredRepos([...filteredRepos, selectedProject])
              }
              handleChange('project_id', selectedProject?.value)
            } else {
              handleChange('project_id', undefined)
            }
          }}
        />
      </div>

      {issue.project_id && (
        <>
          <div className='flex items-center gap-2'>
            <div className='flex w-full flex-1 flex-col gap-1'>
              <Label>{t(`form.milestone.title`)}</Label>
              <Select
                searchable
                clearable
                placeholder={t(`form.milestone.placeholder`)}
                searchPlaceholder={t(`form.milestone.searchPlaceholder`)}
                options={[]}
                loadOptions={handleMilestoneSearch}
                onChange={(selectedValue) => {
                  const selectedMilestone = Array.isArray(selectedValue)
                    ? selectedValue[0]
                    : selectedValue
                  handleChange('milestone_id', selectedMilestone?.value)
                }}
                emptySearchMessage='No milestones'
                emptyMessage='Search for milestones'
              />
            </div>
            <div className='flex w-full flex-1 flex-col gap-1'>
              <Label>{t(`form.label.title`)}</Label>
              <Select
                searchable
                clearable
                placeholder={t(`form.label.placeholder`)}
                searchPlaceholder={t(`form.label.searchPlaceholder`)}
                options={[]}
                loadOptions={handleLabelSearch}
                multiple
                onChange={(selectedValue) => {
                  if (!selectedValue) {
                    handleChange('labels', [])
                  } else {
                    const selectedLabels = Array.isArray(selectedValue)
                      ? selectedValue
                      : [selectedValue]
                    handleChange(
                      'labels',
                      selectedLabels.map(
                        (label: { value: string }) => label?.value
                      )
                    )
                  }
                }}
                emptySearchMessage='No labels'
                emptyMessage='Search for labels'
              />
            </div>
          </div>

          <div className='flex items-center gap-2'>
            <div className='flex w-full flex-1 flex-col gap-1'>
              <Label>{t(`form.dueDate.title`)}</Label>
              <DatePicker
                placeholder={t(`form.dueDate.placeholder`)}
                mode='single'
                numberOfMonths={1}
                showInPopOver
                rounded={false}
                clearable
                onDaySelect={(day) => {
                  handleChange('due_date', day ? day.toISOString() : null)
                }}
                range={{
                  from: issue.due_date ? new Date(issue.due_date) : undefined,
                }}
              />
            </div>
            <div className='flex w-full flex-1 flex-col gap-1'>
              <Label>{t(`form.assignee.title`)}</Label>
              <Select
                searchable
                clearable
                placeholder={t(`form.assignee.placeholder`)}
                searchPlaceholder={t(`form.assignee.searchPlaceholder`)}
                options={[]}
                loadOptions={handleAssigneeSearch}
                multiple
                onChange={(selectedValue) => {
                  if (!selectedValue) {
                    handleChange('assignee_ids', [])
                  } else {
                    const selectedAssignees = Array.isArray(selectedValue)
                      ? selectedValue
                      : [selectedValue]
                    handleChange(
                      'assignee_ids',
                      selectedAssignees.map(
                        (assignee: { value: string }) => assignee?.value
                      )
                    )
                  }
                }}
              />
            </div>
          </div>
        </>
      )}
      <div className='flex w-full justify-end pt-3'>
        <Button
          size='xs'
          disabled={validate() || isSubmitting}
          onClick={handleSubmit}
        >
          {t(`pushToGitlab`)}
        </Button>
      </div>
    </div>
  )
}
