import React, { useContext } from 'react'

import Alert from '@/components/shared/ui/Alert'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'

export default function PlanExpiredAlert() {
  const { userProfile, organizationPlan, trialExpired } = useContext(HNContext)
  const t = useTranslations('billing')

  if (userProfile?.is_member_of_organization && trialExpired) {
    return (
      <Alert
        className='mx-auto mt-1 max-w-5xl px-4'
        rounded
        type='error'
        message={t('planExpiredAlertText.alertDescription')}
      >
        {t('planExpiredAlertText.planExpiryDateText', {
          data: {
            expiryDate: organizationPlan?.end_date,
          },
        })}
      </Alert>
    )
  }
  return <></>
}
