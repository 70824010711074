import * as React from 'react'

import clsxm from '@/lib/clsxm'

import type { UnstyledLinkProps } from './UnstyledLink'
import Link from './UnstyledLink'

const UnderlineLink = React.forwardRef<HTMLAnchorElement, UnstyledLinkProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <Link
        ref={ref}
        {...rest}
        className={clsxm(
          'animated-underline custom-link inline-flex items-center',
          'focus-visible:ring-primary8 focus:outline-none focus-visible:rounded focus-visible:ring focus-visible:ring-offset-2',
          'border-dark hover:border-carbon/0',
          className
        )}
      >
        {children}
      </Link>
    )
  }
)

UnderlineLink.displayName = 'UnderlineLink'

export default UnderlineLink
