import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'

import MainContainer from '@/components/admin/shared/MainContainer'
import HNContext from '@/context/HNContext'
import { EventEmitter } from '@/lib/eventEmitter'
import FourNotFour from '@/pages/404'
import type { IPost } from '@/types/post'

import AdminSinglePostHeader from './AdminSinglePostHeader'
import SinglePostDetails from './SinglePostDetails'

interface IPropTypes {
  post?: IPost
  onClose?: () => void
}
const SinglePost = observer(({ post, onClose }: IPropTypes) => {
  const [editing, setEditing] = useState<boolean>(false)
  const { userProfile } = useContext(HNContext)

  const closeEdit = () => {
    setEditing(false)
  }

  const handleToggleEdit = () => setEditing(!editing)

  const handleShortcut = ({ action }: { action: string }) => {
    if (action === 'EDIT') {
      if (userProfile?.is_csm_of_organization) setEditing(true)
    }
  }

  useEffect(() => {
    EventEmitter.subscribe('SHORTCUT_TRIGGERED', handleShortcut)
    return () => EventEmitter.unsubscribe('SHORTCUT_TRIGGERED', handleShortcut)
  }, [])

  if (!post) return <FourNotFour />

  return (
    <>
      <AdminSinglePostHeader
        post={post}
        onClose={onClose}
        onEdit={handleToggleEdit}
      />
      <MainContainer>
        <div
          className={
            'singlepostwidth fixed inset-x-0 z-[4] h-screen min-h-screen bg-snow dark:bg-gray1  md:left-[270px]'
          }
        >
          <SinglePostDetails
            post={post}
            editing={editing}
            closeEdit={closeEdit}
          />
        </div>
      </MainContainer>
    </>
  )
})

export default SinglePost
