import {
  ASANA_AUTH_PATH,
  ASANA_TASKS_PATH,
  POST_ASANA_TASK_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import type {
  IAsanaSearchIssue,
  IAsanaSearchRequestPayload,
  IAsanaTaskPayload,
} from '@/types/integration/asana'

export const getAsanaConfigData = () => API.get(ASANA_AUTH_PATH)
export const getAsanaTasks = (data: IAsanaSearchRequestPayload) =>
  API.get(ASANA_TASKS_PATH, data)

export const createAsanaTask = (data: IAsanaTaskPayload) =>
  API.post(ASANA_TASKS_PATH, data)

export const linkPostAsanaTask = (slug: string, data: IAsanaSearchIssue) =>
  API.post(POST_ASANA_TASK_PATH(slug), data)
