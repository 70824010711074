import {
  HN_SECRET,
  HN_SESSION_KEY,
  SERVER_USER_AGENT,
} from '@/config/appConstants'
import { removeNullOrUndefinedKeys } from '@/lib/helpers/dataHelpers'
import type { IMixedContext } from '@/types/app'
import type { IRequestHeaders } from '@/types/common'

import { verifySSOToken } from '../../models/Auth'
import { getCookie } from '../Cookie'
import { getHostFromRequest, getSubdomain, sentry } from './appHelpers'

const TOKEN_CHECK_SKIP_PATH = [
  '/actions/user/confirm_email',
  '/user/confirm_email',
  'auth/resetpassword',
]
export const withSessionInServer = (
  fn: (
    ctx: IMixedContext,
    headers: IRequestHeaders,
    session: string | null,
    addtionalData: { subdomain: string }
  ) => {}
) => {
  return async (context: IMixedContext) => {
    const { req, query, pathname } = context.ctx || context
    const widgetKey = (query?.widgetKey as string) || null
    const isServer = !!req
    let session
    let headers: IRequestHeaders = {}
    let subdomain = 'portal'
    if (isServer) {
      const { subdomain: sb } = getSubdomain(getHostFromRequest(req))
      subdomain = sb
      let ip = 'default-value'
      let realIP = 'default-value-realip'
      try {
        ip = req.headers['x-forwarded-for'] as string
        realIP = req.headers['x-real-ip'] as string
      } catch (error: any) {
        ip = error?.message
        realIP = `realIP${error?.message}`
      }

      headers = {
        ...headers,
        'X-Organization': subdomain || 'portal',
        'X-IP': ip,
        'X-Real-ip-from-vercel': realIP,
      }
      session = getCookie(req, HN_SESSION_KEY)

      try {
        if (query.token || query.sso_token) {
          if (!TOKEN_CHECK_SKIP_PATH.includes(pathname)) {
            const token = query.token || query.sso_token
            const apiHeader: IRequestHeaders = {
              'X-Organization':
                (headers['X-Organization'] as string) || 'portal',
            }
            const data = await verifySSOToken(
              String(token),
              {
                ssr: true,
                skipVerification: true,
              },
              apiHeader
            )
            if (data.token) session = data.token
          } else {
            sentry.log('debug', `SKIPPING TOKEN CHECK ${pathname}`)
          }
        }
      } catch (_err) {
        session = getCookie(req, HN_SESSION_KEY)
      }

      headers = {
        ...headers,
        'X-Organization': subdomain || 'portal',
        Authorization: session ? `Bearer ${session}` : '',
        'x-widget-key': '',
      }

      if (widgetKey && subdomain === 'portal')
        headers['x-widget-key'] = widgetKey
      else if (headers['x-widget-key'] && subdomain === 'portal')
        headers['x-widget-key'] = ''

      try {
        if (query.JWTToken) {
          const apiHeader: IRequestHeaders = {
            'X-Organization': (headers['X-Organization'] as string) || 'portal',
            'X-WIDGET-KEY': (headers['x-widget-key'] as string) || '',
          }
          const { token } = await verifySSOToken(
            query.JWTToken as string,
            {
              ssr: true,
              skipVerification: true,
            },
            apiHeader
          )
          session = token
          headers.Authorization = `Bearer ${token}`
        }
      } catch (error) {
        console.error(error)
      }
      headers = {
        ...headers,
        'User-Agent': SERVER_USER_AGENT,
        'X-HN-SECRET': HN_SECRET as string,
      }
    }

    const baseData: any = await fn(
      context,
      removeNullOrUndefinedKeys(headers),
      session,
      {
        subdomain,
      }
    )
    return {
      ...baseData,
      props: {
        ...baseData.props,
        session,
        widgetKey,
      },
    }
  }
}
