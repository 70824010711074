import { Node, nodeInputRule } from '@tiptap/core'
import type { NodeSelection, Transaction } from '@tiptap/pm/state'
import { mergeAttributes, ReactNodeViewRenderer } from '@tiptap/react'

import EditorVideoUploader from './EditorVideoUploader'
import type { UploadFn } from './uploadVideoPlugin'
import { uploadVideoPlugin } from './uploadVideoPlugin'

export interface VideoOption {
  inline: boolean
  HTMLAttributes: Record<string, any>
  sizes: TVideoSize[]
}
type TVideoSize = 'full' | 'medium' | 'small' | 'auto'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    video: {
      setVideo: (options: {
        src: string
        alt?: string
        title?: string
        'data-size'?: TVideoSize
      }) => ReturnType
      setVideoSize: (size: TVideoSize) => ReturnType
    }
  }
}

const IMAGE_INPUT_REGEX = /!\[(.+|:?)\]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/

export const EditorVideo = (uploadFn: UploadFn) => {
  return Node.create<VideoOption>({
    name: 'video',
    addOptions: () => ({
      inline: false,
      HTMLAttributes: {},
      sizes: ['full', 'medium', 'small', 'auto'],
    }),

    inline() {
      return this.options.inline || false
    },

    group() {
      return this.options.inline ? 'inline' : 'block'
    },

    draggable: true,

    addAttributes() {
      return {
        uploadId: {
          default: null,
        },
        src: {
          default: null,
        },
        alt: {
          default: null,
        },
        title: {
          default: null,
        },
        'data-size': {
          default: 'auto',
        },
        controls: {
          default: true,
        },
        'data-percentage': {
          default: 0,
        },
        preload: {
          default: 'metadata',
        },
      }
    },
    parseHTML: () => [
      {
        tag: 'video[src]',
        getAttrs: (dom) => {
          if (typeof dom === 'string') return {}
          const element = dom as HTMLVideoElement

          const obj = {
            src: element.getAttribute('src'),
            title: element.getAttribute('title'),
            alt: element.getAttribute('alt'),
            'data-size': element.getAttribute('data-size'),
            'data-percentage': element.getAttribute('data-percentage'),
            controls: element.getAttribute('controls'),
            preload: element.getAttribute('preload'),
          }
          return obj
        },
      },
    ],
    renderHTML: ({ HTMLAttributes }) => [
      'video',
      mergeAttributes({
        ...HTMLAttributes,
        controls: true,
        preload: 'metadata',
      }),
    ],

    addCommands() {
      return {
        setVideo:
          (attrs) =>
          ({ state, dispatch }) => {
            const { selection } = state
            const position = selection.$head
              ? selection.$head.pos
              : selection.$to.pos

            const node = this.type.create(attrs)
            const transaction = state.tr.insert(position, node)
            return dispatch?.(transaction)
          },

        setVideoSize:
          (size) =>
          ({ tr, dispatch }) => {
            // Check it's a valid size option
            if (!this.options.sizes.includes(size)) {
              return false
            }

            const { selection } = tr as Transaction

            const cNode = (selection as NodeSelection).node
            const options = {
              ...(cNode?.attrs || {}),
              'data-size': size,
              'data-percentage': 0,
            }

            const node = this.type.create(options)

            if (dispatch) {
              tr.replaceRangeWith(selection.from, selection.to, node)
            }
            return false
          },
      }
    },
    addInputRules() {
      return [
        nodeInputRule({
          find: IMAGE_INPUT_REGEX,
          type: this.type,
          getAttributes: (match) => {
            const [, alt, src, title, controls] = match
            return {
              src,
              alt,
              title,
              controls,
              'data-size': 'auto',
              'data-percentage': 0,
              preload: 'metadata',
            }
          },
        }),
      ]
    },
    addProseMirrorPlugins() {
      return [uploadVideoPlugin(uploadFn)]
    },
    addNodeView() {
      return ReactNodeViewRenderer(EditorVideoUploader)
    },
  })
}
