import type { AxiosRequestConfig } from 'axios'
import type { Meilisearch } from 'meilisearch'

import { POST as resource_type } from '@/config/appConstants'
import {
  ADMIN_OPTIMIZED_POSTS_PATH,
  ADMIN_POST_PATH,
  ADMIN_POST_STATUS_UPDATE_PATH,
  ADMIN_POSTS_PATH,
  ADMIN_SUBSCRIBE_USER_PATH,
  CREATE_ISSUE_TO_JIRA,
  CREATE_JIRA_INSIGHT,
  EMAIL_DOMAINS_PATH,
  GET_VOTE_ON_BEHALF_POST,
  GET_VOTES_OF_POSTS_PATH,
  MATCHING_KB_ARTICLE_PATH,
  MERGE_POST_COUNT_PATH,
  MERGE_POST_PATH,
  OPTIMIZED_POSTS_PATH,
  POST_ACTIVITIES_PATH,
  POST_ADD_TAGS_PATH,
  POST_ADDON_PATH,
  POST_ADDONS_PATH,
  POST_AI_INSIGHTS_PATH,
  POST_AI_RECOMMENCE_PATH,
  POST_AI_UPDATE_SENTIMENT_PATH,
  POST_ASSIGNEE_PATH,
  POST_BULK_DELETE_PATH,
  POST_BULK_UPDATE_PATH,
  POST_CUSTOM_FIELD_PATH,
  POST_CUSTOM_FIELDS_PATH,
  POST_DOWNVOTERS_PATH,
  POST_LIST_COUNTS_PATH,
  POST_MAX_VOTE_VALUE_PATH,
  POST_MERGE_RECOMMENDATION_PATH,
  POST_MERGED_POSTS_PATH,
  POST_RATING_PATH,
  POST_REMOVE_ASSIGNEE_PATH,
  POST_REMOVE_ETC_DATE_PATH,
  POST_REMOVE_TAG_PATH,
  POST_ROADMAPS_PATH,
  POST_SHOW_DESCRIPTION_PATH,
  POST_SUBMITTERS_PATH,
  POST_SUBSCRIBE_USER_PATH,
  POST_SUBSCRIBER_LIST_PATH,
  POST_TAT_SUMMARY_PATH,
  POST_UNMERGE_PATH,
  POST_UPVOTE_PATH,
  POST_UPVOTERS_PATH,
  POST_VOTE_ON_BEHALF_PATH,
  POST_VOTERS_EXPORT_PATH,
  POSTS_EXPORT_PATH,
  POTS_SUBSCRIBE_ETC_PATH,
  READ_ITEM_PATH,
  REMOVE_VOTE,
  RESET_DOWNVOTES_PATH,
  RESET_VOTES_PATH,
  SCORE_PATH,
  SEARCH_JIRA_ISSUES,
  TAG_JIRA_ISSUE,
  UNREAD_ITEM_PATH,
  UNTAG_JIRA_ISSUE,
  USER_POST_PATH,
  USER_POSTS_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import { transformActivity } from '@/lib/helpers/modules/activityHelper'
import { transformPostList } from '@/lib/helpers/modules/postListHelper'
import { buildURL } from '@/lib/helpers/urlHelpers'
import {
  getIdxDBData,
  removeIdxDBData,
  setIdxDBData,
} from '@/lib/localDB/localDB'
import {
  getLocalCacheData,
  removeLocalCacheData,
  setLocalCacheData,
} from '@/lib/localDB/localStorage'
import type { IActivityItem } from '@/types/activity'
import type {
  IKeyValuePair,
  IListAPIParams,
  IRequestHeaders,
} from '@/types/common'
import type { IActivitiesListAPIParams } from '@/types/organization'
import type {
  INewPost,
  IPost,
  IPostJiraIssue,
  IPostListAPIParams,
  ISentimentType,
  IVoteDataMap,
  IVoteType,
} from '@/types/post'
import type {
  IPostAddonPayload,
  IPostAddonQueryParams,
} from '@/types/post/postAddon'
import type { IRoadmap } from '@/types/roadmap'
import toaster from '@/utils/toast'

// Local DB
export const getPostsFromDB = (slug: string) => getIdxDBData('POSTS', slug)

export const getPostFromDB = (slug: string) =>
  getIdxDBData('POSTS', slug, 'slug')

export const setPostToDB = (slug: string, data: any) =>
  setIdxDBData('POSTS', slug, data)
export const removePostFromDB = (slug: string) => removeIdxDBData('POSTS', slug)

export const createPost = (postData: INewPost): Promise<IPost> => {
  const { isAdmin = false, ...rest } = postData
  return API.post(isAdmin ? ADMIN_POSTS_PATH : USER_POSTS_PATH, rest)
}

export const getPostFromLocalstorage = (
  params: IListAPIParams,
  role?: 'admin' | 'members'
): IPost[] => {
  const path = role === 'admin' ? ADMIN_POSTS_PATH : USER_POSTS_PATH
  const url = buildURL(path, params)
  const payload = getLocalCacheData(url, 'POSTS') as {
    posts: IPost[]
    expireAt: number
  }
  if (
    !payload ||
    !payload.posts ||
    !payload.expireAt ||
    payload.expireAt < Date.now()
  ) {
    removeLocalCacheData(url, 'POSTS')
    return []
  }
  if (payload && payload.expireAt > Date.now()) {
    return Array.isArray(payload.posts) ? payload.posts : []
  }
  return []
}

export const getPostIdsFromIndexDB = async (
  params: IListAPIParams
): Promise<IPost[]> => {
  const url = buildURL('', params)
  const payload = await getIdxDBData('POSTS_IDS', url, 'hash')

  if (
    !payload ||
    !payload.posts ||
    !payload.expireAt ||
    payload.expireAt < Date.now()
  ) {
    await removeIdxDBData('POSTS_IDS', url, 'hash')
    return [] as IPost[]
  }
  const postsFromIds = await Promise.all(
    payload.posts.map((id: string) => getIdxDBData('POSTS', id, 'id'))
  )
  return postsFromIds as IPost[]
}

export const setPostIdsToIndexDB = async (
  params: IListAPIParams,
  data: IPost[]
): Promise<IPost[]> => {
  const url = buildURL('', params)
  const payload = {
    hash: url,
    // Cache to expire after 5 hours of non usage
    expireAt: new Date().getTime() + 1000 * 60 * 60 * 5,
    posts: data.map((post) => post.id),
  }
  await setIdxDBData('POSTS_IDS', url, payload, 'hash')
  return data
}

export const setPostToLocalStorage = (
  params: IListAPIParams,
  data: IPost[],
  role: 'admin' | 'user'
) => {
  try {
    const path = role === 'admin' ? ADMIN_POSTS_PATH : USER_POSTS_PATH
    const url = buildURL(path, params)
    const payload = {
      // Cache to expire after 5 hours of non usage
      expireAt: new Date().getTime() + 1000 * 60 * 60 * 5,
      posts: data,
    }
    setLocalCacheData(url, payload, 'POSTS')
    return data
  } catch {
    return data
  }
}

export const getPosts = (
  params: IListAPIParams,
  otherOptions: AxiosRequestConfig = {}
): Promise<IPost[]> => {
  return API.get(USER_POSTS_PATH, params, otherOptions)
    .then((result) =>
      Promise.all(result.map((post: IPost) => setPostToDB(post.slug, post)))
    )
    .then((result) => {
      try {
        setPostToLocalStorage(params, result, 'admin')
      } catch {
        console.error('Error in setting posts to local storage')
      }
      return result
    })
}

export const getOptimizedPosts = (
  params: IListAPIParams,
  otherOptions: AxiosRequestConfig = {}
): Promise<IPost[]> => {
  return API.get(OPTIMIZED_POSTS_PATH, params, otherOptions)
    .then(transformPostList)
    .then((result) => {
      return Promise.all(
        result.map((post: IPost) => setPostToDB(post.slug, post))
      )
    })
}

export const getPost = (
  slug: string,
  headers: IRequestHeaders = {}
): Promise<IPost> => {
  return API.get(USER_POST_PATH(slug), {}, { headers }).then((post) =>
    setPostToDB(post.slug, { ...post, fullData: true })
  )
}
export const votePost = (
  slug: string,
  type: IVoteType,
  isAdmin: boolean
): Promise<IPost> => {
  return API.post(
    POST_UPVOTE_PATH(slug, type),
    {},
    {
      params: {
        admin_dashboard: isAdmin,
      },
    }
  )
}

export const getPostActivities = (
  postSlug: string,
  params: IActivitiesListAPIParams
) => {
  return API.get(POST_ACTIVITIES_PATH(postSlug), params).then((data) => {
    return Object.keys(data)
      .map((key) => data[key])
      .flat()
      .map(transformActivity)
  })
}

export const ratePost = (slug: string, data: any) =>
  API.post(POST_RATING_PATH(slug), data)

// Admin Side APIs

export const getAdminPosts = (
  params: IPostListAPIParams,
  otherOptions: AxiosRequestConfig = {}
): Promise<IPost[]> => {
  return API.get(ADMIN_POSTS_PATH, params, otherOptions)
    .then((result) => {
      return Promise.all(
        result.map((post: IPost) => setPostToDB(post.slug, post))
      )
    })
    .then((result) => {
      setPostToLocalStorage(params, result, 'admin')
      return result
    })
}
export const getAdminOptimizedPosts = (
  params: IPostListAPIParams,
  otherOptions: AxiosRequestConfig = {}
): Promise<IPost[]> => {
  return API.get(ADMIN_OPTIMIZED_POSTS_PATH, params, otherOptions)
    .then(transformPostList)
    .then((result) => {
      return Promise.all(
        result.map((post: IPost) => setPostToDB(post.slug, post))
      )
    })
    .then((result) => {
      setPostToLocalStorage(params, result, 'admin')
      return result
    })
}

export const getPostsCount = (filters: IPostListAPIParams) =>
  API.get(POST_LIST_COUNTS_PATH, filters).then(
    (data) => data.counts_data.counts
  )

export const getAdminPost = (slug: string): Promise<IPost> => {
  return API.get(ADMIN_POST_PATH(slug)).then((post) =>
    setPostToDB(post.slug, { ...post, fullData: true })
  )
}

// TODO move to common API
export const updateAdminPostStatus = (
  slug: string,
  data: IKeyValuePair
): Promise<IPost> => {
  return API.put(ADMIN_POST_STATUS_UPDATE_PATH(slug), data).then((post) =>
    setPostToDB(slug, { ...post, fullData: true })
  )
}
export const updateAdminPost = (
  slug: string,
  data: IKeyValuePair
): Promise<IPost> => {
  return API.put(ADMIN_POST_PATH(slug), data).then((post) =>
    setPostToDB(slug, { ...post, fullData: true })
  )
}

export const addTags = (slug: string, data: IKeyValuePair) =>
  API.post(POST_ADD_TAGS_PATH(slug), data)

export const adminSubscribeUserAction = (
  slug: string,
  data: { email: string }
) => {
  return API.post(ADMIN_SUBSCRIBE_USER_PATH(slug), data)
}

export const subscribeUserAction = (slug: string) =>
  API.post(POST_SUBSCRIBE_USER_PATH(slug))

export const removeSubscriber = (slug: string, data: { email: string }) => {
  return API.delete(ADMIN_SUBSCRIBE_USER_PATH(slug), data)
}

export const subscribeETC = (slug: string) =>
  API.post(POTS_SUBSCRIBE_ETC_PATH(slug))

export const getPostSubscribersList = (slug: string, data: IListAPIParams) =>
  API.get(POST_SUBSCRIBER_LIST_PATH(slug), data).then(
    (response) => response.subscribers
  )

export const postVoteOnBehalf = (slug: string, data: any) =>
  API.post(POST_VOTE_ON_BEHALF_PATH(slug), data)

export const getPostUpvoters = (slug: string, data: IListAPIParams) =>
  API.get(POST_UPVOTERS_PATH(slug), data)
export const getPostDownvoters = (slug: string, data: IListAPIParams) =>
  API.get(POST_DOWNVOTERS_PATH(slug), data)

export const editAssignee = (slug: string, data: any) =>
  API.post(POST_ASSIGNEE_PATH(slug), data)

export const removeAssignee = (slug: string, id: string) =>
  API.delete(POST_REMOVE_ASSIGNEE_PATH(slug, id))

export const removeTag = (slug: string, data: any) =>
  API.post(POST_REMOVE_TAG_PATH(slug), data)

export const removeETCDate = (slug: string) =>
  API.post(POST_REMOVE_ETC_DATE_PATH(slug))

export const updateShowDescription = (data: any, id: string) =>
  API.patch(POST_SHOW_DESCRIPTION_PATH(id), data)

export const getSubmitters = () => API.get(POST_SUBMITTERS_PATH)

export const getEmailDomains = () => API.get(EMAIL_DOMAINS_PATH)

export const getOrganizationMaxVoteCount = () =>
  API.get(POST_MAX_VOTE_VALUE_PATH)

export const readPost = (id: string) =>
  API.post(READ_ITEM_PATH, { resource_id: id, resource_type })
export const unReadPost = (id: string) =>
  API.delete(UNREAD_ITEM_PATH(id), { resource_type })

export const resetUpvotes = (slug: string) => API.post(RESET_VOTES_PATH(slug))
export const resetDownVotes = (slug: string) =>
  API.post(RESET_DOWNVOTES_PATH(slug))
export const deletePost = (slug: string) =>
  API.delete(ADMIN_POST_PATH(slug)).then((data) =>
    removePostFromDB(slug)?.then(() => data)
  )

export const bulkUpdate = (data: any) => API.post(POST_BULK_UPDATE_PATH, data)
export const bulkDelete = (data: any) => API.delete(POST_BULK_DELETE_PATH, data)

export const editPost = (slug: string, data: INewPost) =>
  API.put(USER_POST_PATH(slug), data)
export const unmergePost = (slug: string, child_id: string) =>
  API.delete(POST_UNMERGE_PATH(slug, child_id))

export const getMergePostCount = (slug: string, id: string) =>
  API.get(MERGE_POST_COUNT_PATH(slug, id))

export const mergePost = (slug: string, data: any) =>
  API.post(MERGE_POST_PATH(slug), data)

export const bulkMergePosts = (parentSlug: string, childIds: string[]) => {
  return API.post(MERGE_POST_PATH(parentSlug), {
    child_feature_request_ids: childIds,
  })
}
// Search recommendation posts
export const mergePostGetRecommendations = (
  slug: string,
  data: {
    blacklisted_feature_request_ids: number[]
  }
) => API.post(POST_MERGE_RECOMMENDATION_PATH(slug), data)

export const postSearch = (query: string, client: Meilisearch, options: any) =>
  client
    .index('FeatureRequest')
    .search(query, options)
    .then(({ hits }) => hits)
    .catch(toaster.error)

// Addons
export const getPostAddons = (slug: string, data: IPostAddonQueryParams) =>
  API.get(POST_ADDONS_PATH(slug), data)
export const linkPostAddon = (slug: string, data: IPostAddonPayload) =>
  API.post(POST_ADDONS_PATH(slug), data)
export const removePostAddon = (slug: string, id: string) =>
  API.delete(POST_ADDON_PATH(slug, id))

// Jira Issue
export const createInJira = (slug: string) =>
  API.post(CREATE_ISSUE_TO_JIRA(slug))
export const searchJiraIssues = (
  slug: string,
  queryParams: { query: string; only_ideas?: boolean }
) => API.get(SEARCH_JIRA_ISSUES(slug), queryParams)
export const tagJiraIssues = (slug: string, data: IPostJiraIssue) =>
  API.post(TAG_JIRA_ISSUE(slug), data)
export const untagJiraIssues = (slug: string, data: IPostJiraIssue) =>
  API.post(UNTAG_JIRA_ISSUE(slug), data)
export const createJiraInsight = (slug: string, data: any) =>
  API.post(CREATE_JIRA_INSIGHT(slug), data)

export const createPostFromSubmissionForm = (url: string, data: INewPost) =>
  API.post(url, data)

export const getSinglePostTATSummary = (slug: string) =>
  API.get(POST_TAT_SUMMARY_PATH(slug))

export const getPostRoadmaps = (slug: string): Promise<IRoadmap[]> =>
  API.get(POST_ROADMAPS_PATH(slug))

export const getPostScore = (id: string) => {
  return API.get(SCORE_PATH, {
    resource_id: id,
    resource_type: 'FeatureRequest',
  })
}
export const getMergedPosts = (slug: string) =>
  API.get(POST_MERGED_POSTS_PATH(slug))

export const removePostVote = (postSlug: string, voterId: string) =>
  API.delete(REMOVE_VOTE(postSlug, voterId))

// Vote on behalf
export const getVoteOnBehalfPost = (slug: string, key: string) =>
  API.get(GET_VOTE_ON_BEHALF_POST(slug, key))
export const revokeVoteOnBehalfPost = (slug: string, key: string) =>
  API.delete(GET_VOTE_ON_BEHALF_POST(slug, key))

export const exportPosts = (data: IListAPIParams) =>
  API.post(POSTS_EXPORT_PATH, data)

export const exportPostVoters = (slug: string) =>
  API.post(POST_VOTERS_EXPORT_PATH(slug))

export const getVotesOfPosts = (ids: string[]): Promise<IVoteDataMap> => {
  return API.get(GET_VOTES_OF_POSTS_PATH, {
    feature_request_ids: ids,
  })
}

export const getPostActivitiesFromDB = (
  slug: string
): Promise<IActivityItem[]> => {
  return getIdxDBData('POST_ACTIVITIES', slug, 'slug')
    .then((data) => {
      if (!data) return []
      return data.activities || []
    })
    .catch(() => [])
}

export const setPostActivitiesToIdxDB = (
  post: IPost,
  activities: IActivityItem[]
) => {
  setIdxDBData(
    'POST_ACTIVITIES',
    post.slug,
    {
      activities,
      slug: post.slug,
    },
    'slug'
  )
}

export const postAiRecommence = (slug: string) =>
  API.post(POST_AI_RECOMMENCE_PATH(slug))

export const updatePostSentiment = (
  slug: string,
  data: { sentiment_type: ISentimentType }
) => API.post(POST_AI_UPDATE_SENTIMENT_PATH(slug), data)

export const getAIPostInsights = (slug: string) =>
  API.get(POST_AI_INSIGHTS_PATH(slug))

export const getMatchingKBArticles = (slug: string) => {
  return API.post(MATCHING_KB_ARTICLE_PATH(slug))
    .then(({ hits }) => hits)
    .then((hits) => {
      return hits.splice(0, 5)
    })
}

export const getPostCustomFields = (slug: string) =>
  API.get(POST_CUSTOM_FIELDS_PATH(slug))

export const addPostCustomField = (slug: string, data: any) =>
  API.post(POST_CUSTOM_FIELDS_PATH(slug), data)

export const updatePostCustomField = (slug: string, id: string, data: any) =>
  API.put(POST_CUSTOM_FIELD_PATH(slug, id), data)
