import { Star as StarIcon } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'

import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { starInteractor } from '@/models/User'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

interface IPropTypes {
  starred: boolean
  user: IUserProfile
  disable: boolean
  onUpdate?: (user: IUserProfile) => void
}
export default function AdminSingleUserStar({
  starred,
  user,
  disable,
  onUpdate,
}: IPropTypes) {
  const t = useTranslations('users.userActions')
  const [userStarred, setUsersStarred] = useState(starred)
  const { userProfile } = useContext(HNContext)

  const handleClick = () => {
    const prevUserData = { ...user }
    if (onUpdate) onUpdate({ ...prevUserData, starred: !userStarred })
    toaster.success({
      message: userStarred
        ? t('actions.star.unstarred_message')
        : t('actions.star.starred_message'),
    })
    starInteractor(user?.user_id || user.id, { starred: !userStarred })
      .then((data) => setUsersStarred(data.starred))
      .catch((err) => {
        if (onUpdate) onUpdate(prevUserData)
        toaster.error({
          message: err.message,
        })
      })
  }

  useEffect(() => {
    setUsersStarred(starred)
  }, [starred])

  const renderStarButton = () => {
    if (userProfile?.is_admin_of_organization) {
      if (userProfile?.id === user.id) {
        return (
          <Tooltip text={t('actions.star.cannot_star_message')}>
            <StarIcon
              weight='fill'
              className='flex h-5 w-5 shrink-0 text-gray6 transition'
              data-testid={`admin_single_user_star_button_${user.id}`}
            />
          </Tooltip>
        )
      }
      if (user.is_admin_of_organization) {
        return (
          <Tooltip text={t('actions.star.cannot_star_message')}>
            <StarIcon
              weight='fill'
              className='flex h-5 w-5 shrink-0 text-gray6 transition'
              data-testid={`admin_single_user_star_button_${user.id}`}
            />
          </Tooltip>
        )
      }
      return (
        <button
          type='button'
          className='flex items-center justify-center focus:outline-none'
          onClick={handleClick}
          disabled={disable}
          data-testid={`admin_single_user_star_button_${user.id}`}
        >
          <StarIcon
            weight='fill'
            className={clsx(
              'flex h-5 w-5 shrink-0 transition hover:text-yellow5',
              userStarred ? 'text-yellow9' : 'text-gray8'
            )}
          />
        </button>
      )
    }
    return (
      <StarIcon
        weight='fill'
        className={clsx(
          'flex h-5 w-5 shrink-0',
          userStarred ? 'text-yellow9' : 'text-gray8'
        )}
        data-testid={`admin_single_user_star_button_${user.id}`}
      />
    )
  }

  return <> {renderStarButton()} </>
}
