import type { Editor } from '@tiptap/core'
import type { ForwardedRef } from 'react'
import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'

import Alert from '@/components/shared/ui/Alert/Alert'
import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import Input from '@/components/shared/ui/Input'
import { useTranslations } from '@/hooks/useTranslations'
import { RegexValidator } from '@/lib/helpers/stringHelpers'
import type { IKeyValueMap } from '@/types/common'

import {
  LOOM_VIDEO_CONVERSION_EXP,
  LOOM_VIDEO_VALIDATION_EXP,
  YOUTUBE_CONVERSION_EXP,
  YOUTUBE_VALIDATION_EXP,
} from '../editorConstants'

interface IPropTypes {
  editor: Editor
}

const platformConfig: IKeyValueMap = {
  youtube: {
    validationExp: YOUTUBE_VALIDATION_EXP,
    conversionExp: YOUTUBE_CONVERSION_EXP,
    embedURL: (id: string) => `https://www.youtube.com/embed/${id}`,
  },
  loom: {
    validationExp: LOOM_VIDEO_VALIDATION_EXP,
    conversionExp: LOOM_VIDEO_CONVERSION_EXP,
    embedURL: (id: string) => `https://www.loom.com/embed/${id}`,
  },
}

const EditorEmbedDialog = forwardRef(
  (props: IPropTypes, ref: ForwardedRef<any>) => {
    const { editor } = props
    const [open, setOpen] = React.useState(false)
    const [linkURL, setLinkURL] = useState<string | null>(null)
    const t = useTranslations('editor')
    const [platform, setPlatform] = useState<string>('youtube')
    const [errorMessage, setErrorMessage] = useState(null)

    const getEmbedVideoId = (url: string) => {
      const regExp = platformConfig[platform].conversionExp
      const match = url.match(regExp) || []
      const id = match && match.length > 1 ? match[2] : null
      return id ? platformConfig[platform].embedURL(id) : null
    }

    const handleLink = (link: string | null) => {
      if (link && link.trim().length) {
        const videoId = getEmbedVideoId(link)
        if (videoId) {
          editor.chain().focus().setExternalVideo({ src: videoId }).run()
          setOpen(false)
        } else {
          setErrorMessage(t('invalidMessage'))
        }
      }
    }

    useImperativeHandle(ref, () => ({
      openDialog: (pltfrm: string) => {
        setPlatform(pltfrm)
        setOpen(true)
      },
    }))

    const isValidURL = useMemo(() => {
      const regExp = platformConfig[platform].validationExp
      return RegexValidator.isValidWithRegex(linkURL || '', regExp)
    }, [linkURL])

    return (
      <Dialog
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <DialogContent>
          <DialogClose />
          <DialogTitle>{t(`embed.${platform}.title`)}</DialogTitle>
          <form
            className='mt-3'
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              return handleLink(linkURL)
            }}
          >
            {errorMessage && <Alert message={errorMessage} type='error' />}
            <Input
              autoFocus
              placeholder={t(`embed.${platform}.placeholder`)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLinkURL(e.target.value.trim())
              }
              type='url'
              required
            />
            <button type='submit' className='sr-only'>
              {t('buttons.submit', { ignorePrefix: true })}
            </button>
          </form>
          <div className='mt-4 flex justify-end space-x-1'>
            <Button onClick={() => handleLink(linkURL)} disabled={!isValidURL}>
              {t('buttons.embed', { ignorePrefix: true })}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    )
  }
)

EditorEmbedDialog.displayName = 'EditorEmbedDialog'
export default EditorEmbedDialog
