import React, { useContext, useState } from 'react'

import Alert from '@/components/shared/ui/Alert/Alert'
import Button from '@/components/shared/ui/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { resendEmailConfirmation } from '@/models/Auth'
import toaster from '@/utils/toast'

export default function AccountConfirmationAlert() {
  const t = useTranslations()
  const { userSignedIn, userProfile } = useContext(HNContext)
  const [isLoading, setIsLoading] = useState(false)

  if (!userSignedIn || userProfile?.confirmed) return null

  const handleResendSubmit = () => {
    setIsLoading(true)
    resendEmailConfirmation()
      .then(() =>
        toaster.success({
          message: t(`auth.accountConfirmation.resentConfirmation`),
        })
      )
      .catch(toaster.error)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <Alert
        type='warning'
        rounded
        className='mx-auto my-2 max-w-7xl items-center px-4'
        message={t(`auth.accountConfirmation.confirmAlertText`, {
          data: {
            user: userProfile?.email,
          },
        })}
        rightComponent={
          <Button
            disabled={isLoading}
            variant='outline'
            onClick={handleResendSubmit}
          >
            {t(`buttons.resendConfirmation`)}
          </Button>
        }
      />
    </>
  )
}
