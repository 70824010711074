import React, { useEffect, useRef, useState } from 'react'

import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Input from '@/components/shared/ui/Input'
import Label from '@/components/shared/ui/Label'
import {
  type ISelectOption,
  Select,
} from '@/components/shared/ui/Select/Select'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { setSelectedValue } from '@/lib/helpers/dataHelpers'
import { capitalize, snakeToCamel } from '@/lib/helpers/stringHelpers'
import { updateIntegrationConfig } from '@/models/Integration'
import type { IChatVoteMilestoneRule } from '@/types/integration/chat_common'
import type {
  IAdminDiscordIntegrationMapping,
  IDiscordIntegrationConfig,
} from '@/types/integration/discord'
import toaster from '@/utils/toast'

interface IAdminIntegrationDiscordEventsProps {
  configData: IDiscordIntegrationConfig
  integrationMapping: IAdminDiscordIntegrationMapping
  onChange: (data: IDiscordIntegrationConfig) => void
}
export default function AdminIntegrationDiscordEvents({
  configData,
  integrationMapping,
  onChange,
}: IAdminIntegrationDiscordEventsProps) {
  const t = useTranslations()
  const debounceRef = useRef<NodeJS.Timeout>()
  const translationMapping = `integrations.discord.configure.events`
  const [notificationRule, setNotificationRule] = useState(
    configData.notifications_rules
  )
  const [voteMilestoneRules, setVoteMilestoneRules] = useState(
    configData.vote_milestone_rules
  )
  const events = integrationMapping.events || []
  const [isUpdating, setIsUpdating] = useState(false)
  const voteMilestoneFrequency: ISelectOption[] = [
    {
      label: t(`${translationMapping}.voteMilestone.frequency.once`),
      value: 'once',
    },
    {
      label: t(`${translationMapping}.voteMilestone.frequency.every`),
      value: 'every',
    },
  ]

  const handleUpdate = (
    updatedEvents: string[],
    voteMilestoneRule: IChatVoteMilestoneRule
  ) => {
    const data = {
      notification_rules: {
        ...notificationRule,
        events: updatedEvents,
      },
      vote_milestone_rules: {
        ...voteMilestoneRules,
        ...voteMilestoneRule,
      },
    }
    setIsUpdating(true)
    return updateIntegrationConfig('discord', configData.id.toString(), data)
      .then((config: IDiscordIntegrationConfig) => {
        setNotificationRule(config.notifications_rules)
        setVoteMilestoneRules(config.vote_milestone_rules)
        onChange(config)
        toaster.success({
          message: capitalize(
            t(`integrations.discord.messages.successfullySaved`, {
              ignorePrefix: true,
            }).toLowerCase()
          ),
        })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsUpdating(false))
  }

  useEffect(() => {
    setNotificationRule(configData.notifications_rules)
  }, [configData])

  if (!integrationMapping || !integrationMapping.events) return null

  return (
    <div className='my-3'>
      <div className='flex flex-col'>
        <Typography.Text className='mb-0.5 mt-2 !font-medium !text-gray11 '>
          {t(`${translationMapping}.title`)}
        </Typography.Text>
        <Typography.Text className='mb-2 text-gray8'>
          {t(`${translationMapping}.description`)}
        </Typography.Text>
      </div>
      <ul className='flex flex-col gap-2'>
        {events.map((event) => (
          <div className='bg-gray3 p-2' key={event}>
            <div className='flex items-center justify-between gap-1 rounded'>
              <Label htmlfor={t(`${translationMapping}.${event}`)}>
                {t(`${translationMapping}.${snakeToCamel(event)}`)}
              </Label>
              <Checkbox
                isSwitch
                disabled={isUpdating || !configData.message_channel_id}
                id={t(`${translationMapping}.${event}`)}
                checked={notificationRule.events?.includes(event)}
                onCheckedChange={(checked) => {
                  if (checked) {
                    handleUpdate(
                      [...notificationRule.events, event],
                      voteMilestoneRules
                    )
                  } else {
                    handleUpdate(
                      notificationRule.events.filter((e) => e !== event),
                      voteMilestoneRules
                    )
                  }
                }}
              />
            </div>
            {event === 'vote_milestone_reached' && (
              <div className='mt-1 grid grid-cols-2 gap-2'>
                <div>
                  <Label className='mb-1'>
                    <span className='text-gray10'>
                      {t(`${translationMapping}.voteMilestone.frequency.title`)}
                    </span>
                  </Label>
                  <Select
                    size='xs'
                    placeholder={t(
                      `${translationMapping}.voteMilestone.frequency.placeholder`
                    )}
                    defaultValue={setSelectedValue(
                      voteMilestoneFrequency,
                      voteMilestoneRules.frequency
                    )}
                    disabled={
                      isUpdating || !notificationRule.events.includes(event)
                    }
                    options={voteMilestoneFrequency}
                    onChange={(d) => {
                      setVoteMilestoneRules({
                        ...voteMilestoneRules,
                        frequency: Array.isArray(d) ? d[0]?.value : d?.value,
                      })
                      handleUpdate([...notificationRule.events], {
                        ...voteMilestoneRules,
                        frequency: Array.isArray(d) ? d[0]?.value : d?.value,
                      })
                    }}
                    disableSelected
                  />
                </div>
                <div>
                  <Label className='mb-1'>
                    <span className='text-gray10'>
                      {t(`${translationMapping}.voteMilestone.milestone.title`)}
                    </span>
                  </Label>
                  <Input
                    size='xs'
                    defaultValue={voteMilestoneRules.milestone}
                    disabled={!notificationRule.events.includes(event)}
                    placeholder={t(
                      `${translationMapping}.voteMilestone.milestone.placeholder`
                    )}
                    type='number'
                    min={1}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (debounceRef.current) clearTimeout(debounceRef.current)
                      debounceRef.current = setTimeout(() => {
                        const value = parseInt(e.target.value, 10)
                        setVoteMilestoneRules({
                          ...voteMilestoneRules,
                          milestone: value,
                        })
                        handleUpdate([...notificationRule.events], {
                          ...voteMilestoneRules,
                          milestone: value,
                        })
                      }, 500)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </ul>
    </div>
  )
}
