import { useRouter } from 'next/router'
import React, { useContext } from 'react'

import UserAvatar from '@/components/shared/components/UserAvatar'
import Avatar from '@/components/shared/ui/Avatar'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import UserSideUserAuthButton from '@/components/users/shared/UserSideUserAuthButton'
import HNContext from '@/context/HNContext'
import { stringifyValue } from '@/lib/helpers/stringHelpers'
import type { IOrganizationData } from '@/types/organization'
import type { IUserProfile } from '@/types/user'

export default function EmbedHeader() {
  const { organization, userSignedIn, userProfile } = useContext(HNContext) as {
    organization: IOrganizationData
    userSignedIn: boolean
    userProfile: IUserProfile
  }
  const router = useRouter()
  const { query, pathname } = router
  const { boardSlug, single_board, b } = query

  const resolveHomePath = () => {
    if (pathname.includes('/embed/home')) return '/embed/home'
    if (pathname.includes('/embed/c')) return '/embed/c'
    if (pathname.includes('/embed/roadmap') && !single_board)
      return '/embed/roadmap'
    if (single_board) return `/embed/roadmap/b/${boardSlug}?single_board=true`
    if (boardSlug || b) return `/embed/b/${boardSlug || b}`
    return '/'
  }

  return (
    <header className='relative bg-gray3 transition '>
      <div className='mx-auto max-w-7xl px-2'>
        <div className='flex w-full items-center justify-between px-1 py-3'>
          <div className='flex min-w-0'>
            <UnstyledLink
              href={resolveHomePath()}
              className='group flex shrink-0 cursor-pointer items-center justify-between hover:no-underline focus:outline-none focus:ring'
            >
              <div className='flex items-center truncate'>
                <Avatar
                  rounded
                  size='sm'
                  url={organization?.avatar.thumb_url}
                  name={organization?.name}
                />
                <p className='mb-0 ml-2 truncate text-base font-semibold text-gray11 transition group-hover:text-gray12  '>
                  {stringifyValue(organization?.name)}
                </p>
              </div>
            </UnstyledLink>
          </div>
          {!userSignedIn && <UserSideUserAuthButton />}
          {userSignedIn && <UserAvatar user={userProfile} rounded />}
        </div>
      </div>
    </header>
  )
}
