import type { PublicKeyCredentialWithAttestationJSON } from '@github/webauthn-json/dist/types/basic/json'
import type { NextParsedUrlQuery } from 'next/dist/server/request-meta'

import { ROOT_META, SSO_TOKEN_MIN_LENGTH } from '@/config/appConstants'
import {
  ADMIN_BOOTSTRAP_PATH,
  ADMIN_ROOT_PATH,
  BOOTSTRAP_PATH,
  CONFIRM_EMAIL_WITH_TOKEN_PATH,
  CONFIRM_PASSWORD_AND_SIGNIN_PATH,
  FORGOT_PASSWORD_PATH,
  GET_APP_AUTH_TOKEN,
  GET_AUTHENTICATE_PASSKEY_OPTIONS,
  GET_REGISTRATION_PASSKEY_OPTIONS,
  INVITATION_PATH,
  LIST_PASSKEYS,
  MAGIC_LINK_PASSWORD_CHECK_PATH,
  META_PATH,
  PROCESS_EMAIL_CONFIRMATION_PATH,
  RESEND_CONFIRMATION_EMAIL_PATH,
  RESEND_EMAIL_CONFIRMATION_PATH,
  RESET_PASSWORD_CONFIRMATION_PATH,
  SEND_OTP_TO_EMAIL_PATH,
  SIGN_IN_INTERNAL_PATH,
  SIGN_IN_WITH_PROVIDER_PATH,
  SIGN_OUT_INTERNAL_PATH,
  SIGN_UP_INTERNAL_PATH,
  SIGN_UP_WITH_CODE_INTERNAL_PATH,
  SINGLE_PASSKEYS,
  TRANSLATION_INTERNAL_PATH,
  TRANSLATION_PATH,
  USER_ROOT_PATH,
  VERIFY_AUTH_TOKEN_INTERNAL_FULL_PATH,
  VERIFY_AUTH_TOKEN_INTERNAL_PATH,
  VERIFY_AUTH_TOKEN_PATH,
  VERIFY_AUTHENTICATE_PASSKEY,
  VERIFY_REGISTRATION_PASSKEY,
  VERIFY_SSO_INTERNAL_FULL_PATH,
  VERIFY_SSO_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import { orgColorConversion } from '@/lib/helpers/organizationHelper'
import { cleanUserRootData } from '@/lib/helpers/rootHelpers'
import type {
  IConfirmPasswordData,
  IForgotPasswordData,
  IOTPEmailRequestResponse,
  IResendConfirmationEmailData,
  IResetPasswordData,
  ISigninData,
  ISigninResponse,
  ISignupData,
  ISignupResponse,
} from '@/types/auth'
import type {
  APIResponseWithMessage,
  IKeyValueMap,
  IRequestHeaders,
  ISocialProviders,
} from '@/types/common'
import type { IRootData } from '@/types/organization'

export const signIn = (data: ISigninData): Promise<ISigninResponse> => {
  return API.post(SIGN_IN_INTERNAL_PATH, data)
}
export const signUp = (data: ISignupData): Promise<ISignupResponse> => {
  return API.post(SIGN_UP_INTERNAL_PATH, data)
}

export const signOut = (): Promise<ISignupResponse> => {
  return API.delete(SIGN_OUT_INTERNAL_PATH)
}

export const verifyServerAuthToken = (token: string) => {
  return API.post(VERIFY_AUTH_TOKEN_INTERNAL_FULL_PATH, { token })
}
export const verifyAuthInServerToken = (token: string) => {
  return API.post(VERIFY_AUTH_TOKEN_INTERNAL_PATH, { token })
}

export const verifyAuthToken = (
  authToken: string,
  headers: IRequestHeaders
) => {
  return API.post(
    VERIFY_AUTH_TOKEN_PATH,
    {
      token: authToken,
    },
    { headers }
  )
}

export const verifySSOToken = (
  ssoToken: string,
  options: {
    ssr: boolean
    skipVerification: boolean
  },
  headers: IRequestHeaders
) => {
  const url = options.ssr ? VERIFY_SSO_INTERNAL_FULL_PATH : VERIFY_SSO_PATH
  if (ssoToken?.length < SSO_TOKEN_MIN_LENGTH)
    return Promise.reject(new Error('SSO token invalid'))
  return API.post(
    url,
    {
      sso_token: ssoToken,
      skip_login_method_validation: options.skipVerification,
    },
    { headers }
  )
}

export const verifySSOTokenWithDomain = (
  ssoToken: string,
  domain: string,
  headers: IRequestHeaders
) => {
  if (ssoToken?.length < SSO_TOKEN_MIN_LENGTH)
    return Promise.reject(new Error('SSO token invalid'))
  return API.post(
    VERIFY_SSO_PATH,
    {
      sso_token: ssoToken,
      domain,
    },
    { headers }
  )
}

export const signInWithOTP = ({
  userId,
  otpCode,
  fromApp,
}: {
  userId: string
  otpCode: string
  fromApp?: string
}) =>
  API.post(SIGN_UP_WITH_CODE_INTERNAL_PATH, {
    userId,
    otpCode,
    from_app: fromApp,
  })

export const signInWithProvider = (
  data: { token: string; skip_login_method_validation?: boolean },
  provider: ISocialProviders,
  headers: IRequestHeaders
) =>
  API.post(SIGN_IN_WITH_PROVIDER_PATH(provider), data, {
    headers,
  })

export const sendOTPToEmail = (data: {
  email: string
}): Promise<IOTPEmailRequestResponse> => {
  return API.post(SEND_OTP_TO_EMAIL_PATH, data)
}

export const confirmPasswordAndSignin = (data: IConfirmPasswordData) =>
  API.put(CONFIRM_PASSWORD_AND_SIGNIN_PATH, data)

export const resetPasswordConfirmation = (
  token: string,
  data: IResetPasswordData
): Promise<APIResponseWithMessage> => {
  return API.put(RESET_PASSWORD_CONFIRMATION_PATH(token), data)
}

export const forgotPassword = (data: IForgotPasswordData) =>
  API.post(FORGOT_PASSWORD_PATH, data)

export const checkIfEmailHasPassword = (email: string) => {
  return API.get(MAGIC_LINK_PASSWORD_CHECK_PATH, { email })
}

export const confirmEmailWithToken = (token: string) => {
  return API.post(CONFIRM_EMAIL_WITH_TOKEN_PATH(token))
}

export const getMetaDataParams = (
  query: NextParsedUrlQuery,
  pathname: string
) => {
  let params = {}
  if (pathname === '/p/[postSlug]') {
    params = {
      type: 'FeatureRequest',
      slug: query.postSlug,
    }
  } else if (pathname === '/b/[boardSlug]') {
    params = {
      type: 'Bucket',
      slug: query.boardSlug,
    }
  } else if (pathname === '/changelog/[changelogSlug]') {
    params = {
      type: 'Changelog',
      slug: query.changelogSlug,
    }
  }
  return params
}

export const getMetaData = (
  query: NextParsedUrlQuery,
  pathname: string,
  headers: IRequestHeaders
) => {
  const params = getMetaDataParams(query, pathname)
  return API.get(META_PATH, params, {
    headers,
  }).catch(() => ROOT_META)
}

export const getRootData = (
  isAdmin: boolean,
  params: IKeyValueMap,
  headers: IRequestHeaders
): Promise<IRootData> => {
  const URL = isAdmin ? ADMIN_ROOT_PATH : USER_ROOT_PATH
  return API.get(URL, params, {
    headers,
  })
    .then((data: Partial<IRootData>) => {
      return {
        ...data,
        userSignedIn: !!data.userProfile,
        theme: {
          ...data.theme,
          ...(isAdmin
            ? orgColorConversion(ROOT_META.theme)
            : orgColorConversion(data.theme || ROOT_META.theme)),
        },
      } as IRootData
    })
    .then(cleanUserRootData)
}

export const bootstrapData = (isAdmin: boolean) =>
  isAdmin ? API.get(ADMIN_BOOTSTRAP_PATH) : API.get(BOOTSTRAP_PATH)

export const getTranslation = (
  pathname: string,
  lang: string,
  withinApp?: boolean
): Promise<any> => {
  const section = pathname.includes('/admin') ? 'admin' : 'user'
  return API.get(
    withinApp
      ? TRANSLATION_PATH(section, lang)
      : TRANSLATION_INTERNAL_PATH(section, lang)
  )
}

export const getInvitation = (token: string) =>
  API.get(INVITATION_PATH, { token })

export const resendEmailConfirmation = () =>
  API.post(RESEND_EMAIL_CONFIRMATION_PATH)

export const processEmailConfirmation = (token: string) =>
  API.post(`${PROCESS_EMAIL_CONFIRMATION_PATH}?token=${token}`)

export const resendConfirmationEmail = (data: IResendConfirmationEmailData) =>
  API.post(RESEND_CONFIRMATION_EMAIL_PATH, data)

// Registration of Passkey
export const getRegisterPasskeyOptions = () => {
  return API.post(GET_REGISTRATION_PASSKEY_OPTIONS)
}

export const verifyRegistrationPassKey = (data: {
  publicKeyCredential: PublicKeyCredentialWithAttestationJSON
  name: string
}) => {
  return API.post(VERIFY_REGISTRATION_PASSKEY, data)
}

// Authentication Passkey
export const getAuthenticationPasskeyOptions = () => {
  return API.get(GET_AUTHENTICATE_PASSKEY_OPTIONS)
}

export const verifyAuthenticatePassKey = (data: any) => {
  return API.post(VERIFY_AUTHENTICATE_PASSKEY, data)
}

export const listRegisteredPasskeys = () => {
  return API.get(LIST_PASSKEYS)
}

export const removePassKey = (id: string) => {
  return API.delete(SINGLE_PASSKEYS(id))
}

export const updatePasskey = (id: string, data: any) => {
  return API.put(SINGLE_PASSKEYS(id), data)
}

export const getAppToken = (
  app: string,
  headers: IRequestHeaders = {}
): Promise<{
  kb_auth_token: string
}> => {
  return API.get(
    GET_APP_AUTH_TOKEN(app),
    {},
    {
      headers,
    }
  )
}
