const assetPrefixDomains = [
  'https://assets.production.skcript.com',
  'https://assets.dev.skcript.com',
  'https://vault.featureos.app',
  'https://assets.featureos.app',
]

const normalizeSrc = (src) => {
  const assetPrefixDomain = assetPrefixDomains.find((domain) => {
    return src.startsWith(domain)
  })
  if (assetPrefixDomain) return src.slice(assetPrefixDomain.length + 1)
  return src.startsWith('/') ? src.slice(1) : src
}

const cloudflareLoader = ({ src, width, quality }) => {
  const urlWithoutParams = src.split('?')[0]
  if (urlWithoutParams.endsWith('.svg')) {
    return src
  }
  const assetPrefixDomain = assetPrefixDomains.find((domain) => {
    return src.startsWith(domain) && !src.includes('/cdn-cgi/image')
  })
  if (!assetPrefixDomain) return src
  const params = [`w=${width}`]
  if (quality) {
    params.push(`quality=${quality}`)
  }
  const paramsString = params.join(',')
  return `${assetPrefixDomain}/cdn-cgi/image/${paramsString}/${normalizeSrc(
    src
  )}`
}

export default cloudflareLoader
