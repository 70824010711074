/* eslint-disable no-underscore-dangle */
import { ArrowLeft, ArrowRight, GitPullRequest } from '@phosphor-icons/react'
import { useContext, useEffect, useState } from 'react'

import MergePostItem from '@/components/posts/singlePost/mergepost/MergePostItem'
import SearchInput from '@/components/shared/components/SearchInput'
import AISparkle from '@/components/shared/icons/AISparkle'
import Badge from '@/components/shared/ui/Badge'
import Button from '@/components/shared/ui/Button'
import EmptyState from '@/components/shared/ui/EmptyState'
import Spinner from '@/components/shared/ui/Loader'
import { Pill } from '@/components/shared/ui/Pill/Pill'
import { POST_SEARCH_ATTRIBUTES_TO_RETRIVE } from '@/config/module/postsConstants'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { singlePostMergePostSearchFilter } from '@/lib/helpers/modules/searchHelper'
import { mergePostGetRecommendations } from '@/models/Post'
import type { IRootData } from '@/types/organization'
import type { IPost } from '@/types/post'
import type { ISearchResponse } from '@/types/search'
import toaster from '@/utils/toast'

interface IPropTypes {
  onSelect: (post: IPost) => void
  post?: IPost
  selectedPosts?: IPost[]
}

export interface IMergePostCounts {
  comments_count: number
  downvotes_count: number
  internal_comments_count: number
  votes_count: number
}

export interface IMergingPost {
  fromPost: IPost
  toPost: IPost | any
  newCounts: IMergePostCounts | any
}

export interface IRecommendedPost extends IPost {
  _semanticScore: number
}

const HITS_PER_PAGE = 10

export default function PostMergeSuggestions({
  onSelect,
  post,
  selectedPosts = [],
}: IPropTypes) {
  const t = useTranslations()
  const { buckets, organization, userProfile } = useContext(
    HNContext
  ) as IRootData

  const [searchedPosts, setSearchedPosts] = useState<IRecommendedPost[]>([])
  const [recommendedPosts, setRecommendedPosts] = useState<IRecommendedPost[]>(
    []
  )
  const [recommendedByAI, setRecommendedByAI] = useState<boolean>(false)
  const [query, setQuery] = useState('')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [numberOfPages, setNumberOfPages] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)

  const fetchRecommendations = () => {
    if (!post?.slug) return
    mergePostGetRecommendations(post.slug, {
      blacklisted_feature_request_ids: selectedPosts.map((p) => Number(p.id)),
    })
      .then(({ hits, merge_suggestion_enabled }: any) => {
        setRecommendedByAI(merge_suggestion_enabled)
        return setRecommendedPosts(hits)
      })
      .catch(toaster.error)
      .finally(() => setLoading(false))
  }

  const handleSearch = (data: ISearchResponse[]) => {
    const indexResponses = data.find(
      (result) => result.indexName === 'FeatureRequest'
    )
    if (!indexResponses?.query?.length) setCurrentPage(1)
    const searchedItems = indexResponses?.hits as IRecommendedPost[]
    const bucketIds = buckets.map((bucket) => bucket.id?.toString())
    const itemsToRemove = searchedItems?.filter(
      (pst) => !bucketIds.includes(pst.bucket_id?.toString())
    )
    const itemsToRemoveCount = itemsToRemove?.length
    const itemsToRetain = searchedItems?.filter((pst) =>
      bucketIds.includes(pst.bucket_id?.toString())
    )

    setSearchedPosts(itemsToRetain)
    setNumberOfPages(
      (indexResponses?.estimatedTotalHits || 0 - itemsToRemoveCount) /
        HITS_PER_PAGE
    )
    setQuery(indexResponses?.query as string)
  }

  useEffect(() => {
    if (searchedPosts.length) return
    setLoading(true)
    fetchRecommendations()
  }, [post?.slug])

  const renderRecommendedPostList = (recommendedPost: IRecommendedPost) => {
    const selected = selectedPosts.some((p) => p.id === recommendedPost.id)
    return (
      <MergePostItem
        key={recommendedPost.id}
        post={recommendedPost}
        actionComponent={
          <div className='flex items-center gap-1'>
            {!!recommendedPost._semanticScore && (
              <Pill variant={'blue'}>{`${Math.round(
                (recommendedPost._semanticScore || 0) * 100
              )}% Match`}</Pill>
            )}
            <Button
              size='xxs'
              variant='outline'
              onClick={() => onSelect(recommendedPost)}
              disabled={selected}
              icon={
                <GitPullRequest
                  weight='fill'
                  className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
                />
              }
            >
              {selected ? t('buttons.added') : t('buttons.add')}
            </Button>
          </div>
        }
      />
    )
  }

  const renderPaginationButton = () => (
    <div className='mb-4 mt-2 flex items-center justify-center space-x-1'>
      <Button
        variant='text'
        size='xs'
        icon={<ArrowLeft weight='bold' className='h-4 w-4' />}
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(currentPage - 1)}
      />
      <Button
        variant='text'
        size='xs'
        icon={<ArrowRight weight='bold' className='h-4 w-4' />}
        disabled={numberOfPages < 1 || currentPage >= numberOfPages}
        onClick={() => setCurrentPage(currentPage + 1)}
      />
    </div>
  )

  const renderRecommendedPosts = () => {
    if (loading) return <Spinner />
    if (recommendedPosts?.length && !query?.length) {
      return (
        <>
          <div className='flex items-center justify-between'>
            <p className='text-xs font-bold uppercase tracking-wider text-gray11'>
              {t('post.mergePost.recommendedPostToMerge')}
            </p>
            {recommendedByAI && (
              <div>
                <Badge
                  className='w-full !max-w-full !bg-[#f7f3f8] !text-[#a782c3]'
                  icon={<AISparkle />}
                  value={t('ai.recByKal')}
                />
              </div>
            )}
          </div>
          <div className='flex flex-col gap-2'>
            {recommendedPosts.map(renderRecommendedPostList)}
          </div>
        </>
      )
    }
    if (searchedPosts?.length) {
      return (
        <>
          <p className='text-xs font-bold uppercase tracking-wider text-gray11'>
            {query !== post?.title
              ? ''
              : t('post.mergePost.recommendedPostToMerge')}
          </p>
          <div className='flex flex-col gap-2'>
            {searchedPosts.map(renderRecommendedPostList)}
          </div>
          {Boolean(numberOfPages > 1) && renderPaginationButton()}
        </>
      )
    }
    return (
      <EmptyState
        title={query ? 'Empty' : t('post.mergePost.noRecommendedPostTitle')}
        description={
          query
            ? `${t('post.mergePost.noPostsFoundText')} ${query || post?.title}`
            : t('post.mergePost.noRecommendedPostMessage')
        }
      />
    )
  }

  return (
    <div className='mt-3 space-y-4'>
      <SearchInput
        indexes={[
          {
            indexName: 'FeatureRequest',
            label: 'Feature Request',
            attributesToRetrieve: POST_SEARCH_ATTRIBUTES_TO_RETRIVE,
            filters: singlePostMergePostSearchFilter(
              userProfile?.is_organization_member ? [] : buckets,
              organization?.id,
              post?.id
            ),
          },
        ]}
        inputProps={{ placeholder: t('post.mergePost.search'), size: 'sm' }}
        onSearch={(data) => handleSearch(data)}
        hitsPerPage={HITS_PER_PAGE}
        offset={HITS_PER_PAGE * (currentPage - 1)}
        page={currentPage}
        autoFocus
        onSearchState={(_loading: boolean) => setLoading(_loading)}
      />
      {renderRecommendedPosts()}
    </div>
  )
}
