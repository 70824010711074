import { type VariantProps, cva } from 'class-variance-authority'
import React from 'react'

import Text from './Text'
import Title from './Title'

const typographyVariants = cva(['block'])

interface TypographyProps extends VariantProps<typeof typographyVariants> {
  children: React.ReactNode
  className?: string
  tag?: string
  style?: React.CSSProperties
}

function Typography({
  children,
  className,
  tag = 'div',
  style,
}: TypographyProps) {
  const CustomTag: any = `${tag}`
  return (
    <CustomTag style={style} className={typographyVariants({ className })}>
      {children}
    </CustomTag>
  )
}

Typography.Title = Title
Typography.Text = Text

export default Typography
