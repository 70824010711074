import { Info } from '@phosphor-icons/react'
import React, { useContext } from 'react'

import UserAvatar from '@/components/shared/components/UserAvatar'
import Tooltip from '@/components/shared/ui/Tooltip'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
}

export default function SinglePostPostedBy({ post }: IPropTypes) {
  const t = useTranslations('post')
  const { userProfile } = useContext(HNContext)
  const toShowTooltip =
    Boolean(
      userProfile?.is_member_of_organization && !post?.submitter?.confirmed
    ) || Boolean(userProfile?.is_csm_of_organization && post?.posted_user)

  if (!post.submitter) return <></>
  return (
    <div className='grid grid-cols-5 items-center gap-4 px-4 empty:hidden'>
      <Typography.Text className='col-span-2 font-medium'>
        {t('labels.postedBy')}
      </Typography.Text>
      <span className='col-span-3 flex items-center space-x-2'>
        <UserAvatar
          user={post?.submitter}
          rounded
          size='xs'
          starred={post?.submitter.starred}
          showProfileCard
          allowUserInfoTrigger
        />
        <Tooltip
          asChild
          text={post.submitter.name}
          wrapperClass={'truncate cursor-default'}
        >
          <Typography.Text className='truncate font-medium !text-gray12 dark:!text-gray-light'>
            {post.submitter.name}
          </Typography.Text>
        </Tooltip>
        {toShowTooltip && (
          <Tooltip
            asChild
            customtext={
              <div className='flex flex-col'>
                {userProfile?.is_member_of_organization &&
                  !post?.submitter?.confirmed &&
                  t('messages.unconfirmedUser')}
                {userProfile?.is_member_of_organization &&
                  !post?.submitter?.confirmed &&
                  userProfile?.is_csm_of_organization &&
                  post.posted_user &&
                  t('common.andText', { ignorePrefix: true })}
                {userProfile?.is_csm_of_organization &&
                  post.posted_user &&
                  t('messages.PostOnBehalfBy', {
                    data: {
                      userName: post.posted_user.name,
                    },
                  })}
              </div>
            }
          >
            <Info weight='fill' className='h-4 w-4 cursor-pointer text-gray8' />
          </Tooltip>
        )}
      </span>
    </div>
  )
}
