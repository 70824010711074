import clsx from 'clsx'
import type { FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'

import ClosedIcon from '@/components/posts/statusIcons/ClosedIcon'
import CompletedIcon from '@/components/posts/statusIcons/CompletedIcon'
import InProgressIcon from '@/components/posts/statusIcons/InProgressIcon'
import PauseIcon from '@/components/posts/statusIcons/PauseIcon'
import PlannedIcon from '@/components/posts/statusIcons/PlannedIcon'
import ReviewIcon from '@/components/posts/statusIcons/ReviewIcon'
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import {
  ROADMAP_STATUS_LIST,
  ROADMAP_STATUS_LIST_SORT_ORDER,
} from '@/config/appConstants'
import { useTranslations } from '@/hooks/useTranslations'
import type { IKeyValueMap } from '@/types/common'
import type { IRoadmap } from '@/types/roadmap'

const ROADMAP_STATUS_ICON_MAP: {
  backlog: FC<any>
  planned: FC<any>
  in_progress: FC<any>
  paused: FC<any>
  completed: FC<any>
  cancelled: FC<any>
} = {
  backlog: ReviewIcon,
  planned: PlannedIcon,
  in_progress: InProgressIcon,
  paused: PauseIcon,
  completed: CompletedIcon,
  cancelled: ClosedIcon,
}
interface IPropTypes {
  roadmap: IRoadmap
  onUpdate?: (data: any) => void
  showName?: boolean
  readonly?: boolean
}
export default function RoadmapStatusPicker({
  roadmap: _roadmap,
  onUpdate,
  showName,
  readonly,
}: IPropTypes) {
  const t = useTranslations('roadmap.status')
  const [roadmap, setRoadmap] = useState(_roadmap)

  const handleUpdate = (data: IKeyValueMap) => {
    if (onUpdate) onUpdate(data)
  }

  const selectedStatus = useMemo(
    () => ROADMAP_STATUS_LIST.find((st) => roadmap.status === st.value),
    [roadmap]
  )

  useEffect(() => {
    setRoadmap(_roadmap)
  }, [_roadmap])

  const Icon =
    ROADMAP_STATUS_ICON_MAP[selectedStatus ? selectedStatus.value : 'backlog']

  if (readonly)
    return (
      <span className='inline-flex items-center'>
        <Icon
          size={13}
          className={selectedStatus?.iconClass}
          percentage={roadmap.percentage}
        />
        {showName && selectedStatus && (
          <span className='ml-2'>{t(selectedStatus?.value)}</span>
        )}
      </span>
    )
  return (
    <Dropdown>
      <DropdownTrigger asChild={false}>
        <div
          className={clsx(
            'flex items-center',
            showName
              ? 'select-none rounded-md border border-gray5 bg-snow px-2 py-1.5 font-medium text-gray11 shadow-sm transition duration-200 dark:border-gray-dark-border dark:bg-gray-dark '
              : 'rounded p-1 transition duration-200 hover:bg-gray5'
          )}
        >
          <Icon
            size={13}
            className={selectedStatus?.iconClass}
            percentage={roadmap.percentage}
          />
          {showName && selectedStatus && (
            <span className='ml-2 font-medium text-gray11 '>
              {t(selectedStatus?.value)}
            </span>
          )}
        </div>
      </DropdownTrigger>
      <DropdownContent>
        {[...ROADMAP_STATUS_LIST]
          .sort(
            (a, b) =>
              ROADMAP_STATUS_LIST_SORT_ORDER.indexOf(a.value) -
              ROADMAP_STATUS_LIST_SORT_ORDER.indexOf(b.value)
          )
          .map((status) => {
            const StatusIcon = ROADMAP_STATUS_ICON_MAP[status.value]
            return (
              <DropdownItem
                key={status.value}
                itemValue={t(status.value)}
                icon={
                  <StatusIcon
                    size={12}
                    className={status.iconClass}
                    percentage={roadmap.percentage}
                  />
                }
                onSelect={() => handleUpdate({ status: status.value })}
              />
            )
          })}
      </DropdownContent>
    </Dropdown>
  )
}
