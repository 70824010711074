import {
  Link,
  TextBolder,
  TextItalic,
  TextUnderline,
} from '@phosphor-icons/react'
import React from 'react'

import Tooltip from '@/components/shared/ui/Tooltip/Tooltip'
import { useTranslations } from '@/hooks/useTranslations'
import type { IMenuPropTypes } from '@/types/editor'

import {
  ACTIVE_CLASS,
  DEFAULT_BUTTON_CLASS,
  ICON_CLASS,
} from '../editorConstants'

const EditorTextOnlyMenuBar = ({
  editor,
  editable,
  actions,
}: IMenuPropTypes) => {
  const t = useTranslations('editor')

  if (!editor || !editable) {
    return null
  }

  return (
    <div className='flex space-x-1 overflow-x-auto sm:justify-center'>
      <div className={'flex space-x-1 overflow-x-auto sm:justify-center'}>
        <Tooltip asChild text={t('bold')} disableHoverableContent>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleMark('bold').run()}
            className={`${
              editor.isActive('bold') ? ACTIVE_CLASS : DEFAULT_BUTTON_CLASS
            }`}
          >
            <TextBolder weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('italic')} disableHoverableContent>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleMark('italic').run()}
            className={
              editor.isActive('italic') ? ACTIVE_CLASS : DEFAULT_BUTTON_CLASS
            }
          >
            <TextItalic weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('underline')} disableHoverableContent>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleMark('underline').run()}
            className={
              editor.isActive('underline') ? ACTIVE_CLASS : DEFAULT_BUTTON_CLASS
            }
          >
            <TextUnderline weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('button.link')} disableHoverableContent>
          <button
            type='button'
            data-testid='link_button'
            className={`${DEFAULT_BUTTON_CLASS}`}
            onClick={() => actions.openLinkDialog()}
          >
            <Link weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
      </div>
    </div>
  )
}
export default EditorTextOnlyMenuBar
