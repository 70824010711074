import React, { useState } from 'react'

import Button from '@/components/shared/ui/Button'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import { useTranslations } from '@/hooks/useTranslations'
import { snakeToCamel } from '@/lib/helpers/stringHelpers'
import { addRuleToConfig } from '@/models/Integration'
import type {
  IIntegrationStatusSyncData,
  IStatusSyncRule,
} from '@/types/integration/statusSync'
import toaster from '@/utils/toast'

import AdminIntegrationHellonextSyncRule from './AdminIntegrationHellonextSyncRule'
import AdminIntegrationProviderSyncRule from './AdminIntegrationProviderSyncRule'

interface IAdminIntegrationNewSyncRuleProps {
  syncRule: IStatusSyncRule
  configData: IIntegrationStatusSyncData
  onAdd: (config: IStatusSyncRule) => void
  provider: string
  showSyncRule: boolean
  onCancel: () => void
  useCustomStates?: boolean
}

export default function AdminIntegrationNewSyncRule({
  syncRule,
  configData,
  provider,
  showSyncRule,
  onAdd,
  onCancel,
  useCustomStates,
}: IAdminIntegrationNewSyncRuleProps) {
  const t = useTranslations(`integrations.${snakeToCamel(provider)}`)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [newSyncRule, setNewSyncRule] = useState<IStatusSyncRule>(syncRule)
  const [source, setSource] = useState(newSyncRule.source)

  if (!configData) return null

  const valid = () => {
    if (provider === 'linear') {
      return !(
        newSyncRule.source &&
        newSyncRule.target &&
        newSyncRule.trigger_status &&
        newSyncRule.status &&
        newSyncRule.team_id
      )
    }
    if (provider === 'asana') {
      return !(
        newSyncRule.source &&
        newSyncRule.target &&
        newSyncRule.trigger_status &&
        newSyncRule.status &&
        newSyncRule.project_id
      )
    }
    return !(
      newSyncRule.source &&
      newSyncRule.target &&
      newSyncRule.trigger_status &&
      newSyncRule.status
    )
  }

  const reset = () => {
    setNewSyncRule(syncRule)
    setSource(syncRule.source)
    onCancel()
  }

  const checkAndUpdateSyncRule = (_newSyncRule: IStatusSyncRule) => {
    const updatedRule = { ..._newSyncRule }
    if (provider === 'asana') {
      if (updatedRule.source === 'asana') {
        return {
          ...updatedRule,
          trigger_status: {
            custom_field_id: updatedRule.custom_field_id,
            custom_field_value: updatedRule.custom_field_value,
          },
        }
      }
      if (updatedRule.source === 'hellonext') {
        return {
          ...updatedRule,
          status: {
            custom_field_id: updatedRule.custom_field_id,
            custom_field_value: updatedRule.custom_field_value,
          },
        }
      }
    }
    return updatedRule
  }

  const handleChange = (key: string, value: string) => {
    let newRule = {
      ...newSyncRule,
      [key]: value,
    } as IStatusSyncRule
    if (key === 'team_id' && newRule.trigger_status) {
      if (source === provider) newRule.trigger_status = ''
      else newRule.status = ''
    }
    if (key === 'project_id') {
      newRule.custom_field_id = ''
      newRule.custom_field_value = ''
    }
    if (key === 'custom_field_id') {
      newRule.custom_field_value = ''
    }
    if (provider === 'asana') {
      newRule = checkAndUpdateSyncRule(newRule) as IStatusSyncRule
    }
    setNewSyncRule(newRule)
    if (key === 'source') {
      setSource(value)
      const updatedRule = {
        ...newSyncRule,
        source: value,
        target: configData.sources.find((s) => s.value !== value)?.value,
      } as IStatusSyncRule
      setNewSyncRule(updatedRule)
    }
  }

  const handleAddRule = () => {
    setIsSubmitting(true)
    return addRuleToConfig(provider, newSyncRule)
      .then(() => {
        onAdd(newSyncRule)
        reset()
        toaster.success({
          message: t(`messages.successfullySaved`, {
            ignorePrefix: true,
          }),
        })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .then(() => setIsSubmitting(false))
  }

  if (!showSyncRule) return <></>
  return (
    <div className='flex w-full flex-col rounded border-2 border-dashed border-gray5 px-3 pb-2 '>
      {source === provider ? (
        <AdminIntegrationProviderSyncRule
          provider={provider}
          configData={configData}
          syncRule={newSyncRule}
          edit={true}
          onChange={handleChange}
          newRule={true}
          useCustomStates={useCustomStates}
        />
      ) : (
        <AdminIntegrationHellonextSyncRule
          provider={provider}
          configData={configData}
          syncRule={newSyncRule}
          edit={true}
          onChange={handleChange}
          newRule={true}
          useCustomStates={useCustomStates}
        />
      )}
      <div className='mt-3 flex space-x-1'>
        <Button
          data-testid='add_sync_rule_button'
          size='xs'
          disabled={valid() || isSubmitting}
          onClick={handleAddRule}
        >
          {isSubmitting ? (
            <SpinnerIcon className='h-4 w-4 animate-spin' />
          ) : (
            <span>{t(`configure.syncRule.add`)}</span>
          )}
        </Button>
        <Button
          data-testid='cancel_sync_rule_button'
          size='xs'
          onClick={() => reset()}
          variant='outline'
        >
          <span>
            {t(`buttons.cancel`, {
              ignorePrefix: true,
            })}
          </span>
        </Button>
      </div>
    </div>
  )
}
