import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import React, { useContext, useMemo } from 'react'

import HNContext from '@/context/HNContext'
import { AppConfig } from '@/utils/AppConfig'

interface IPropTypes {
  title?: string | null
  description?: string
}

export default function PageTitle({ title, description = '' }: IPropTypes) {
  const { organization } = useContext(HNContext)
  const router = useRouter()

  const pageTitle = useMemo(
    () =>
      title
        ? `${title} | ${organization?.name || AppConfig.site_name}`
        : organization?.name || AppConfig.site_name,
    [title]
  )

  if (router.asPath === '/' && organization) return null
  return <NextSeo title={pageTitle} description={description} />
}
