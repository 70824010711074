import React, { useContext, useEffect, useMemo, useState } from 'react'

import PowerUpPurchaseButton from '@/components/admin/powerups/PowerUpPurchaseButton'
import PowerUpsForm from '@/components/admin/powerups/PowerUpsForm'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EventEmitter } from '@/lib/eventEmitter'
import { capitalize, snakeToCamel } from '@/lib/helpers/stringHelpers'
import { openContactChat } from '@/lib/utils'
import type {
  IPlanName,
  IPowerUpInfo,
  IPurchasePowerUpsParams,
} from '@/types/billing'

export default function PowerUpPurchaseModal() {
  const t = useTranslations('billing')
  const { organizationPlan } = useContext(HNContext)
  const [openDialog, setOpenDialog] = useState(false)
  const [powerUps, setPowerUps] = useState<IPowerUpInfo[] | null>(null)
  const [plan, setPlan] = useState<IPlanName | null>(null)
  const [isPurchasing, setIsPurchasing] = useState(false)
  const [configData, setConfigData] = useState<IPurchasePowerUpsParams | null>(
    null
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [activePlan, setActivePlan] = useState(false)
  const [showAddAndRemoveButton, setShowAddAndRemoveButton] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionId, setSubscriptionId] = useState<string | null>(null)
  const [isFullPowerUpData, setIsFullPowerUpData] = useState(false)

  const dialogTitle = useMemo(() => {
    if (plan) {
      return capitalize(t(`plans.${snakeToCamel((plan as string) || '')}.name`))
    }
    return capitalize(
      t(`powerUps.${snakeToCamel(powerUps?.[0]?.slug || '')}.title`)
    )
  }, [plan, powerUps])

  const handleDialogClose = () => {
    if (isPurchasing) return null
    setOpenDialog(false)
    return setIsPurchasing(false)
  }

  const handleOpenDialog = (eventData: any) => {
    const {
      event,
      data,
      showButtons,
      isFullPowerUpData: _isFullPowerUpData = false,
    } = eventData
    if (event !== ENTITIES.TOGGLE_PURCHASE_MODAL) return null
    setConfigData(null)
    if (organizationPlan?.source === 'stripe_v1') return openContactChat()

    setConfigData(data)
    setErrorMessage(null)
    setActivePlan(data?.activePlan)
    if (data?.plan) setPlan(data?.plan)
    if (data?.power_ups) setPowerUps(data?.power_ups)
    if (data.subscriptionId) setSubscriptionId(data.subscriptionId)
    setIsFullPowerUpData(_isFullPowerUpData)
    setShowAddAndRemoveButton(showButtons)
    return setOpenDialog(true)
  }

  const handleUpdatePowerUps = (data: IPowerUpInfo[]) => setPowerUps(data)

  useEffect(() => {
    EventEmitter.subscribe('CONFIG_PUSH', handleOpenDialog)
    return () => {
      EventEmitter.unsubscribe('CONFIG_PUSH', handleOpenDialog)
    }
  }, [])

  const renderDialogContent = () => {
    if (configData && Object.keys(configData)?.length) {
      return (
        <>
          <PowerUpsForm
            configData={configData}
            onUpdate={handleUpdatePowerUps}
            onError={setErrorMessage}
            showAddAndRemoveButton={showAddAndRemoveButton}
            loadingState={(data) => setIsLoading(data)}
            activePlan={activePlan}
            interval={configData?.interval || organizationPlan?.interval}
            isFullPowerUpData={isFullPowerUpData}
          />
          <div className='flex items-center justify-end'>
            <PowerUpPurchaseButton
              plan={plan}
              activePlan={activePlan}
              interval={configData?.interval || undefined}
              disabled={isLoading}
              loading={isLoading}
              powerUps={powerUps}
              subscriptionId={subscriptionId}
              onClose={handleDialogClose}
            />
          </div>
        </>
      )
    }
    return <></>
  }

  return (
    <Dialog open={openDialog} onClose={() => handleDialogClose()}>
      <DialogContent size='sm' backdrop>
        <div className='p-1'>
          {!isPurchasing && <DialogClose />}
          <DialogTitle>
            {t('texts.purchaseModalTitle', {
              data: {
                powerUp: dialogTitle,
              },
            })}
          </DialogTitle>
          {errorMessage ? (
            <EmptyState description={errorMessage} />
          ) : (
            renderDialogContent()
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
