import { List, X } from '@phosphor-icons/react'
import clsx from 'clsx'
import Image from 'next/image'
import React, { useContext, useState } from 'react'

import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import CustomHeaderBanner from '@/components/users/shared/custom/CustomHeaderBanner'
import CustomHeaderBoardsButton from '@/components/users/shared/custom/CustomHeaderBoardsButton'
import CustomHeaderChaneglogButton from '@/components/users/shared/custom/CustomHeaderChaneglogButton'
import CustomHeaderCustomLinks from '@/components/users/shared/custom/CustomHeaderCustomLinks'
import CustomHeaderRoadmapButton from '@/components/users/shared/custom/CustomHeaderRoadmapButton'
import UserSideUserAuthButton from '@/components/users/shared/UserSideUserAuthButton'
import HNContext from '@/context/HNContext'

export default function CustomUserSideHeader() {
  const { organization, organizationCustomization } = useContext(HNContext)
  const { home_page_config } = organizationCustomization || {}

  const logo = home_page_config?.custom_logo || organization?.avatar.url

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev)
  }

  const closeMenu = () => {
    const menu = document.getElementById('mobile-menu')
    if (menu) {
      menu.classList.remove('animate-slide-in')
      menu.classList.add('animate-slide-out')
      setTimeout(() => {
        setIsMenuOpen(false)
      }, 300) // Match this duration with your slide-out animation duration
    }
  }

  return (
    <header>
      {/* First Navs */}
      <div className='fixed top-0 z-[5] flex min-h-[4rem] w-full flex-col justify-center bg-gray1 py-2'>
        <div className='container mx-auto flex items-center justify-between'>
          <UnstyledLink
            target='_blank'
            href={home_page_config?.logoLink || '/'}
            className={clsx('flex items-center space-x-2')}
          >
            {logo ? (
              <Image
                src={logo}
                alt={organization?.name || ''}
                width={120}
                height={80}
                loader={({ src }) => src}
              />
            ) : (
              <div className='text-lg font-semibold text-gray12'>
                {organization?.name}
              </div>
            )}
          </UnstyledLink>
          <div className='hidden gap-6 text-base text-primary md:flex'>
            <CustomHeaderBoardsButton />
            <CustomHeaderRoadmapButton />
            <CustomHeaderChaneglogButton />
            <CustomHeaderCustomLinks />
          </div>
          <div className='flex items-center justify-end'>
            <UserSideUserAuthButton />
            <div className='flex items-center md:hidden'>
              <button className='ml-4 text-carbon' onClick={toggleMenu}>
                <List size={24} />
              </button>
            </div>
          </div>
          {isMenuOpen && (
            <div
              id='menu-overlay'
              className='z-9 fixed inset-0 bg-black bg-opacity-50'
              onClick={closeMenu}
            ></div>
          )}
          <div
            id='mobile-menu'
            className={`absolute left-0 top-0 z-10 w-full bg-gray1 ${
              isMenuOpen ? 'animate-slide-in' : 'hidden'
            }`}
          >
            <div className='relative flex flex-col items-start justify-center gap-6 px-4 py-4 text-base text-primary'>
              <button
                className='absolute right-2 top-2 text-carbon'
                onClick={closeMenu}
              >
                <X size={24} />
              </button>
              <CustomHeaderBoardsButton />
              <CustomHeaderRoadmapButton />
              <CustomHeaderChaneglogButton />
              <CustomHeaderCustomLinks />
            </div>
          </div>
          <style jsx>{`
            @keyframes slide-in {
              from {
                transform: translateY(-100%);
              }
              to {
                transform: translateY(0);
              }
            }
            @keyframes slide-out {
              from {
                transform: translateY(0);
              }
              to {
                transform: translateY(-100%);
              }
            }
            .animate-slide-in {
              animation: slide-in 0.3s forwards;
            }
            .animate-slide-out {
              animation: slide-out 0.3s forwards;
            }
          `}</style>
        </div>
      </div>
      {/* Banner */}
      <CustomHeaderBanner />
    </header>
  )
}
