import { useContext } from 'react'

import { APP_NAME, DOMAIN, THIRD_PARTY_APPS } from '@/config/appConstants'
import HNTranslationContext from '@/context/HNTranslationContext'
import { isNullOrUndefined } from '@/lib/helpers/dataHelpers'
import { templateStringReplace } from '@/lib/helpers/stringHelpers'

import HNContext from '../context/HNContext'

interface IConfig {
  ignorePrefix?: boolean
  data?: {
    [key: string]: string | number | undefined
  }
  fallback?: (key: string) => any
}

export const useTranslations = (prefix?: string) => {
  const translations = useContext(HNTranslationContext)
  const { organizationSetting, organization, organizationPlan } =
    useContext(HNContext)

  const translateNonStringText: any = (text: any, templateData: any) => {
    if (Array.isArray(text)) {
      return text.map((item: any) => translateNonStringText(item, templateData))
    }
    if (typeof text === 'object') {
      const result: any = {}
      Object.keys(text).forEach((key) => {
        result[key] = translateNonStringText(text[key], templateData)
      })
      return result
    }
    return templateStringReplace(text, templateData)
  }

  const t = (key: string, config: IConfig = {}): any => {
    let fullKey = key
    if (isNullOrUndefined(key)) return ''

    try {
      if (key.startsWith('{') && key.endsWith('}')) {
        return t(key.slice(1, -1), { ignorePrefix: true })
      }
      if (!config.ignorePrefix && prefix) {
        fullKey = prefix && prefix.length ? `${prefix}.${key}` : key
      }
      const text = fullKey.split('.').reduce((o, i) => o[i], translations || {})
      const templateData = {
        boardsModule: organizationSetting?.bucket_plural_name,
        boardModule: organizationSetting?.bucket_singular_name,
        roadmapModule: organizationSetting?.roadmap_name,
        roadmapsModule: organizationSetting?.roadmap_plural_name,
        changelogModule: organizationSetting?.changelog_singular_name,
        changelogsModule: organizationSetting?.changelog_plural_name,
        organizationName: organization?.name || translations.common.appName,
        currentPlan: organizationPlan?.plan,
        appName: APP_NAME,
        appDomain: DOMAIN,
        salesforceAppName: THIRD_PARTY_APPS.salesforce.name,
        memberSingularName: translations.common.modules.member,
        memberPluralName: translations.common.modules.members,
        seatPluralName: translations.common.modules.seats,
        seatSingularName: translations.common.modules.seat,
        clickUpAppName: THIRD_PARTY_APPS.clickup.name,
        azureAdAppName: THIRD_PARTY_APPS.azure.name,
        discordAppName: THIRD_PARTY_APPS.discord.name,
        slackAppName: THIRD_PARTY_APPS.slack.name,
        zendeskAppName: THIRD_PARTY_APPS.zendesk.name,
        githubAppName: THIRD_PARTY_APPS.github.name,
        gitlabAppName: THIRD_PARTY_APPS.gitlab.name,
        jiraAppName: THIRD_PARTY_APPS.jira.name,
        intercomAppName: THIRD_PARTY_APPS.intercom.name,
        zapierAppName: THIRD_PARTY_APPS.zapier.name,
        linearAppName: THIRD_PARTY_APPS.linear.name,
        asanaAppName: THIRD_PARTY_APPS.asana.name,
        patreonAppName: THIRD_PARTY_APPS.patreon.name,
      }

      if (text) {
        if (config.data) {
          return templateStringReplace(text, {
            ...templateData,
            ...config.data,
          })
        }

        return typeof text !== 'string'
          ? translateNonStringText(text, templateData)
          : templateStringReplace(text, templateData)
      }
      if (config.fallback) {
        return config.fallback(key)
      }
      if (config.data) {
        return templateStringReplace(fullKey, config.data)
      }
      return fullKey
    } catch (err: any) {
      if (config.fallback) {
        return config.fallback(key)
      }
      return fullKey
    }
  }
  return t
}
