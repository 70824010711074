import { ArrowRight } from '@phosphor-icons/react'
import { useContext } from 'react'

import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { userSideChangelogSinglePath } from '@/lib/helpers/pathHelpers'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
}

export default function SinglePostLinkedChangelog(props: IPropTypes) {
  const { post } = props
  const t = useTranslations()
  const { organization } = useContext(HNContext)

  if (!post?.changelog) return <></>

  return (
    <div className='grid grid-cols-5 items-center gap-4 px-4 empty:hidden'>
      <Typography.Text className='col-span-2 font-medium'>
        {t('post.labels.inChangelog')}
      </Typography.Text>
      <UnstyledLink
        className='col-span-3 flex items-center font-medium !text-brand hover:!text-primary-dark'
        target={'__blank'}
        href={`${organization?.home_page}${userSideChangelogSinglePath(
          post.changelog.slug
        )}`}
      >
        <span className='pr-2'>{post.changelog.title}</span>
        <ArrowRight weight='bold' />
      </UnstyledLink>
    </div>
  )
}
