import React, { useEffect, useState } from 'react'

import Typography from '@/components/shared/ui/Typography'

interface IPropTypes {
  durationInSeconds: number
  onTimeEnd: () => void
  show: boolean
}

export default function TimeCounter({
  durationInSeconds,
  onTimeEnd,
  show,
}: IPropTypes) {
  const [minutes, setMinutes] = useState(Math.floor(durationInSeconds / 60))
  const [seconds, setSeconds] = useState(Math.floor(durationInSeconds % 60))

  const updateTime = () => {
    if (minutes === 0 && seconds === 0) {
      onTimeEnd()
    } else if (seconds === 0) {
      setMinutes((_minutes) => _minutes - 1)
      setSeconds(durationInSeconds)
    } else {
      setSeconds((_seconds) => _seconds - 1)
    }
  }

  useEffect(() => {
    const token = setTimeout(updateTime, 1000)
    return () => {
      clearTimeout(token)
    }
  })

  useEffect(() => {
    if (show) {
      setMinutes(Math.floor(durationInSeconds / 60))
      setSeconds(Math.floor(durationInSeconds % 60))
    }
  }, [show])

  if (minutes === 0 && seconds === 0 && !show) return <></>
  return (
    <Typography.Text>
      {minutes.toString()}:{seconds.toString()}
    </Typography.Text>
  )
}
