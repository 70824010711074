import React, { useContext, useMemo } from 'react'

import AdminPostSentimentFilter from '@/components/admin/posts/filters/AdminPostSentimentFilter'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { checkUserCanAccessAI } from '@/lib/helpers/modules/postHelper'
import { updatePostSentiment } from '@/models/Post'
import postStore from '@/stores/PostListStore'
import type { IOrganizationPlan } from '@/types/organization'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function SinglePostSentimentChange(props: IPropTypes) {
  const { post } = props
  const t = useTranslations('post')
  const { organizationPlan, userProfile } = useContext(HNContext) as {
    organizationPlan: IOrganizationPlan
    userProfile: IUserProfile
  }

  const userHaveAccess = useMemo(
    () => checkUserCanAccessAI(userProfile, organizationPlan),
    [post]
  )

  const handleChange = (_key: string, value: ISelectOption) => {
    const selectedSentiment = value && value.length ? value[0] : null
    const updatedPost = {
      ...post,
      sentiment_type: selectedSentiment,
    }
    if (!selectedSentiment) return null
    postStore.updateSinglePost(post.slug, updatedPost)
    toaster.success({
      message: t('messages.sentiment.success'),
    })
    return updatePostSentiment(post.slug, {
      sentiment_type: selectedSentiment,
    })
      .then((response) => {
        postStore.updateSinglePost(post.slug, { ...post, ...response })
      })
      .catch((error) => {
        postStore.updateSinglePost(post.slug, post)
        toaster.error({ message: error.message })
      })
  }

  if (!userHaveAccess) return <></>
  return (
    <div
      className='grid grid-cols-5 items-center gap-4 px-4'
      data-testid='post_board_change_editable'
    >
      <Typography.Text className='col-span-2 font-medium'>
        {t('sentiment.title')}
      </Typography.Text>
      <span className='col-span-3'>
        <AdminPostSentimentFilter
          multiple={false}
          filters={{ sentiment_type: post?.sentiment_type }}
          onChange={handleChange}
          behaviour='select'
          placeholder={t('fields.sentiment.placeholder')}
        />
      </span>
    </div>
  )
}
