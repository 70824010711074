import type { ITheme } from '@/types/organization'

// Set a color to a CSS variable
function setColor(variable: string, color: string) {
  document.documentElement.style.setProperty(variable, color)
}

// Set CSS Brand Variables
const setColors = (theme: ITheme) => {
  if (theme) {
    setColor('--primary', theme.brandColorHSL)
    setColor('--primary-dark', theme.brandColorDarkHSL)
    setColor('--primary-light', theme.brandColorLightHSL)
    if (theme && theme.darkMode) {
      setColor('--link', theme.brandColorLightHSL)
      setColor('--link-dark', theme.brandColorHSL)
      setColor('--brand-shade', 'transparent')
    } else {
      setColor('--link', theme.brandColorHSL)
      setColor('--link-dark', theme.brandColorDarkHSL)
      setColor('--brand-shade', theme.brandColorLightHSL)
    }
  }
}

// Set dark mode meta tag
const setDarkMeta = (theme: ITheme) => {
  if (theme && theme.darkMode) {
    document
      .querySelector('meta[name=theme-color]')
      ?.setAttribute('content', 'hsla(var(--dark-bg))')
    document
      .querySelector('meta[name=color-scheme]')
      ?.setAttribute('content', 'dark')
  } else {
    document
      .querySelector('meta[name=theme-color]')
      ?.setAttribute('content', 'hsla(var(--primary))')
    document
      .querySelector('meta[name=color-scheme]')
      ?.setAttribute('content', 'light')
  }
}

// Set dark class
const setDark = (theme: ITheme) => {
  if (theme) document.documentElement.classList.remove('theme-default', 'dark')
  if (theme && theme.darkMode) {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.add('theme-default')
  }
}

const setTheme = (theme: ITheme) => {
  try {
    if (!window || !document) return

    const css = document.createElement('style')
    css.type = 'text/css'
    css.appendChild(
      document.createTextNode(
        `* {
        transition: none !important;
      }`
      )
    )

    document.head.appendChild(css)

    // Add dark class to html tag
    setDark(theme)

    // Change theme colors css variables
    setColors(theme)

    // Change theme meta tag value
    setDarkMeta(theme)

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window.getComputedStyle(css).opacity
    document.head.removeChild(css)
  } catch (error) {
    console.error(error)
  }
}

export { setTheme }
