import {
  GITHUB_ISSUES,
  GITHUB_REPO_SEARCH,
  INTEGRATION_CONFIG,
} from '@/config/routes'
import API from '@/lib/API'
import type { IGithubIssue } from '@/types/integration/github'

export const getGithubConfig = () => API.get(INTEGRATION_CONFIG('github'))
export const searchGithubIssues = (data: { query: string }) =>
  API.get(GITHUB_ISSUES, data)
export const searchGithubRepos = (data: { query: string }) =>
  API.get(GITHUB_REPO_SEARCH, data)
export const createGithubIssue = (data: IGithubIssue) =>
  API.post(GITHUB_ISSUES, data)
