import React from 'react'

import type {
  ISelectOption,
  ISelectPropTypes,
} from '@/components/shared/ui/Select/Select'
import { Select } from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import { titleize } from '@/lib/helpers/stringHelpers'
import type { IStatusSyncRule } from '@/types/integration/statusSync'

interface IAdminIntegrationLinearTeamsDropDownProps {
  syncRule: IStatusSyncRule
  provider: string
  edit: boolean
  ruleKey: string
  onChange: (key: string, value: string) => void
  teams: ISelectOption[]
  size?: ISelectPropTypes['size']
}

export default function AdminIntegrationLinearTeamsDropDown({
  syncRule,
  provider,
  edit,
  onChange,
  teams,
  ruleKey,
}: IAdminIntegrationLinearTeamsDropDownProps) {
  const t = useTranslations(`integrations.${provider}`)
  const parseTeams = teams.map((team) => {
    return {
      ...team,
      label: titleize(team.name),
      value: team.id,
    }
  })

  return (
    <Select
      placeholder={t(`configure.pushRule.team.placeholder`)}
      searchPlaceholder={t(`configure.pushRule.team.searchPlaceholder`)}
      loading={false}
      value={setSelectedValue(parseTeams, syncRule.team_id || '')}
      searchable
      size='xs'
      options={parseTeams}
      onChange={(value) => {
        const selectedValue = getSelectedValueByKey(parseTeams || [], value)
        if (selectedValue && Array.isArray(selectedValue)) {
          onChange(ruleKey, selectedValue[0]?.value)
        } else {
          onChange(ruleKey, selectedValue?.value)
        }
      }}
      disabled={!edit}
    />
  )
}
