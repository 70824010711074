import { Copy, X } from '@phosphor-icons/react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'

import RecentActivities from '@/components/activites/RecentActivities'
import UserAvatar from '@/components/shared/components/UserAvatar'
import Button from '@/components/shared/ui/Button'
import EmptyState from '@/components/shared/ui/EmptyState'
import Spinner from '@/components/shared/ui/Loader'
import Tabs from '@/components/shared/ui/Tabs'
import {
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/shared/ui/Tabs/Tabs'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { HNInteractorContext } from '@/context/HNInteractorContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { copyClipboard } from '@/lib/helpers/appHelpers'
import { removeKeyFromObject } from '@/lib/helpers/dataHelpers'
import { relativeDate } from '@/lib/helpers/dateHelpers'
import { getInteractor } from '@/models/User'
import { userPostStore } from '@/stores/PostListStore'
import type { IInteractorRecord } from '@/types/user'

import AdminPostList from '../../posts/AdminPostList'
import AdminUserLabels from '../labels/AdminUserLabels'
import AdminSingleUserActions from './AdminSingleUserActions'
import AdminSingleUserAttributes from './AdminSingleUserAttributes'
import AdminSingleUserStar from './AdminSingleUserStar'

const AdminSingleUserStats = dynamic(() => import('./AdminSingleUserStats'), {
  ssr: false,
})

interface IPropTypes {
  onUpdate?: (user: IInteractorRecord) => void
  onDelete?: (user: IInteractorRecord) => any
}
export default function AdminSingleUserPage({
  onUpdate,
  onDelete,
}: IPropTypes) {
  const t = useTranslations('users.userItem')
  const router = useRouter()
  const { userProfile } = useContext(HNContext)
  const { userId } = router.query as { userId: string }
  const [userDetails, setUserDetails] = useState<IInteractorRecord>()
  const [loading, setLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [activeUserId, setActiveUserId] = useState<string | null>(userId)
  const isUserPage = router.pathname.includes('/admin/users')

  const fetchUserDetails = () => {
    if (!activeUserId) return
    setLoading(true)
    setErrorMessage(null)
    getInteractor(activeUserId)
      .then(setUserDetails)
      .catch((err: any) => {
        if (err?.status === 404) {
          return setErrorMessage('ERROR_CODE_USER_IS_NOT_A_CUSTOMER')
        }
        return setErrorMessage(err.message)
      })
      .then(() => setLoading(false))
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  const handleClose = () => {
    if (router.query.userId) {
      router.push({
        pathname: router.pathname,
        query: removeKeyFromObject(['userId', 'postSlug'], router.query),
      })
    } else {
      setActiveUserId(null)
    }
  }

  const handleShortcut = (data: any) => {
    if (data.action === 'ESCAPE') {
      handleClose()
    }
  }

  const updateActiveUserEvent = (data: any) => {
    setActiveUserId(data.userId)
  }

  useEffect(() => {
    if (activeUserId) fetchUserDetails()
    else {
      setLoading(true)
    }
  }, [activeUserId])

  useEffect(() => {
    setActiveUserId(userId)
  }, [userId])

  useEffect(() => {
    userPostStore.updateSearchedPosts(undefined)
    EventEmitter.subscribe('OPEN_USER_INFO', updateActiveUserEvent)
    EventEmitter.subscribe('SHORTCUT_TRIGGERED', handleShortcut)
    return () => {
      EventEmitter.unsubscribe('OPEN_USER_INFO', updateActiveUserEvent)
      EventEmitter.unsubscribe('SHORTCUT_TRIGGERED', handleShortcut)
    }
  }, [])

  useEffect(() => {
    if (userDetails) {
      onUpdate?.(userDetails)
      EventEmitter.dispatch('ENTITY_UPDATE', {
        actionType: EVENT_ACTION_TYPES.UPDATE,
        data: {
          data: userDetails,
          id: userDetails.id,
        },
      })
    }
  }, [userDetails])

  useEffect(() => {
    EventEmitter.subscribe('SHORTCUT_TRIGGERED', handleShortcut)
    return () => EventEmitter.unsubscribe('SHORTCUT_TRIGGERED', handleShortcut)
  }, [])

  const renderContent = () => {
    if (loading) return <Spinner />
    if (!activeUserId) return <></>
    if (errorMessage) return <EmptyState title={errorMessage} />
    if (!userDetails) return <Spinner isScreen />
    return (
      <HNInteractorContext.Provider
        value={{
          ...userDetails,
          close: handleClose,
          updateContext: (userData: Partial<IInteractorRecord>) => {
            setUserDetails({
              ...userDetails,
              ...userData,
            })
          },
        }}
      >
        <div className='h-full overflow-y-auto' role='dialog' data-state='open'>
          <div className='sticky top-0 z-10 flex items-center justify-between bg-snow px-4 py-2 shadow'>
            <h4 className='text-lg font-medium'>{userDetails?.name}</h4>
            <div className='space-x-2'>
              <AdminSingleUserActions
                user={userDetails}
                userId={userDetails?.id}
                onUpdate={(newUser) => setUserDetails(newUser)}
                onDelete={() => {
                  if (userDetails) {
                    onDelete?.(userDetails)
                    EventEmitter.dispatch('ENTITY_UPDATE', {
                      actionType: EVENT_ACTION_TYPES.DELETE,
                      data: {
                        data: userDetails,
                        id: userDetails.id,
                      },
                    })
                  }
                  handleClose()
                }}
              />
              <Button fab variant='outline' onClick={handleClose}>
                <X weight='bold' className='h-3.5 w-3.5 shrink-0 text-gray11' />
              </Button>
            </div>
          </div>
          <div className='px-4'>
            <div className='space-y-4 pb-3'>
              <div className='flex items-center space-x-2 pt-3'>
                <span className='relative'>
                  <UserAvatar rounded size='md' user={userDetails} />
                  {userDetails?.starred && (
                    <span className='absolute -bottom-1 -left-0'>
                      <AdminSingleUserStar
                        starred={userDetails.starred}
                        user={userDetails}
                        disable={userProfile?.id === userDetails.id}
                      />
                    </span>
                  )}
                </span>
                <span className='flex flex-col space-y-1'>
                  <span className='flex items-center space-x-1 text-base font-medium'>
                    <span className='select-all'>
                      <a
                        target='_blank'
                        href={`mailto:${userDetails.email}`}
                        rel='noreferrer'
                      >
                        {userDetails.email}
                      </a>
                    </span>
                    <Copy
                      className='cursor-pointer text-gray8 '
                      onClick={() =>
                        copyClipboard(
                          userDetails.email,
                          t('buttons.copied', { ignorePrefix: true })
                        )
                      }
                    />
                  </span>
                  <span className='text-xs text-gray9 '>
                    {t(`common.modules.${userDetails.role}`, {
                      ignorePrefix: true,
                    })}
                  </span>
                </span>
              </div>
              <div>
                <div className='grid grid-cols-10 gap-4 pt-3'>
                  <Typography.Text className='col-span-3 truncate whitespace-nowrap font-medium text-gray9 '>
                    {t('fields.labels')}
                  </Typography.Text>
                  <span className='col-span-6'>
                    <AdminUserLabels
                      user={userDetails}
                      onUpdate={(newUser) => setUserDetails(newUser)}
                    />
                  </span>
                </div>
                <div className='grid grid-cols-10 gap-4 pt-3'>
                  <Typography.Text className='col-span-3 truncate whitespace-nowrap font-medium text-gray9 '>
                    {t('fields.signedUpDate')}
                  </Typography.Text>
                  <Typography.Text className='col-span-6 !text-gray11 '>
                    {userDetails.created_at
                      ? relativeDate(userDetails.created_at.timestamp)
                      : 'Never'}
                  </Typography.Text>
                </div>
                <div className='grid grid-cols-10 gap-4 pt-3'>
                  <Typography.Text className='col-span-3 truncate whitespace-nowrap font-medium text-gray9 '>
                    {t('fields.lastInteractedAt')}
                  </Typography.Text>
                  <Typography.Text className='col-span-6 !text-gray11 '>
                    {userDetails.last_interacted_at
                      ? relativeDate(userDetails.last_interacted_at.timestamp)
                      : 'Never'}
                  </Typography.Text>
                </div>

                <div className='grid grid-cols-10 items-center gap-4 pt-3'>
                  <Typography.Text className='col-span-3 truncate whitespace-nowrap font-medium text-gray9 '>
                    {t('fields.attributes')}
                  </Typography.Text>
                  <div className='col-span-7'>
                    <AdminSingleUserAttributes />
                  </div>
                </div>
                <div className='grid grid-cols-10 items-center gap-4 pt-3'>
                  <Typography.Text className='col-span-3 truncate whitespace-nowrap font-medium text-gray9 '>
                    {t('common.modules.segments', {
                      ignorePrefix: true,
                    })}
                  </Typography.Text>
                  <div className='col-span-7'>
                    {userDetails.segments.map((seg) => (
                      <span key={seg.id}>{seg.name}</span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Tabs defaultValue='user-stats' className='px-4'>
            <TabsList>
              <TabsTrigger value='user-stats'>
                <span>{t('tabs.stats')}</span>
              </TabsTrigger>
              <TabsTrigger value='submitted-posts'>
                <span>{t('tabs.posts')}</span>
              </TabsTrigger>
              <TabsTrigger value='voted-posts'>
                <span>{t('tabs.voted_posts')}</span>
              </TabsTrigger>
              <TabsTrigger value='commented-posts'>
                <span>{t('tabs.commented_posts')}</span>
              </TabsTrigger>
              <TabsTrigger value='recent-activity'>
                <span>{t('tabs.activity')}</span>
              </TabsTrigger>
            </TabsList>
            <TabsContent value='user-stats'>
              <div className='h-full overflow-y-auto pb-[10%]'>
                <AdminSingleUserStats user={userDetails} />
              </div>
            </TabsContent>
            <TabsContent value='submitted-posts'>
              <div className='!-pt-4 !-mt-4 overflow-y-auto pb-[10%]'>
                <AdminPostList
                  globalFilters={{
                    user: [activeUserId],
                    show_hidden: true,
                    show_closed: true,
                  }}
                  newTabOpen
                  hideModules={['selectBox', 'submitter', 'assignee']}
                  incomingPostStore={userPostStore}
                />
              </div>
            </TabsContent>
            <TabsContent value='voted-posts'>
              <div className='!-pt-4 !-mt-4 max-h-[100vh] overflow-hidden overflow-y-auto'>
                <AdminPostList
                  globalFilters={{
                    voted_by: [activeUserId],
                    show_hidden: true,
                    show_closed: true,
                  }}
                  newTabOpen
                  hideModules={['selectBox', 'submitter', 'assignee']}
                  incomingPostStore={userPostStore}
                />
              </div>
            </TabsContent>
            <TabsContent value='commented-posts'>
              <div className='!-pt-4 !-mt-4 max-h-[100vh] overflow-y-auto'>
                <AdminPostList
                  globalFilters={{
                    commented_by: [activeUserId],
                    show_hidden: true,
                    show_closed: true,
                  }}
                  newTabOpen
                  hideModules={['selectBox', 'submitter', 'assignee']}
                  incomingPostStore={userPostStore}
                />
              </div>
            </TabsContent>
            <TabsContent value='recent-activity'>
              <div className='-mx-4 h-full overflow-y-auto pb-[10%]'>
                <RecentActivities userIds={[activeUserId]} />
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </HNInteractorContext.Provider>
    )
  }

  return (
    <>
      {userProfile?.is_admin_of_organization && activeUserId && !isUserPage && (
        <div
          className='fixed inset-0 z-[6] bg-gray9/60'
          onClick={handleClose}
        />
      )}
      <div
        className='fixed -right-full top-0 z-[7] h-screen min-w-[100%] max-w-[100%] overflow-hidden bg-snow shadow-lg transition-all duration-200 data-[show=true]:right-0 dark:bg-gray1 sm:min-w-[80%] sm:max-w-[80%] md:min-w-[50%] md:max-w-[50%] '
        data-show={
          Boolean(userProfile?.is_admin_of_organization) &&
          Boolean(activeUserId)
        }
      >
        {renderContent()}
      </div>
    </>
  )
}
