/* eslint-disable prettier/prettier */
import { V3_WIDGET_THRESHOLD_DATE } from '@/config/appConstants'
import {
  ORG_NAME_MAX_CHARACTER_LENGTH,
  ORG_NAME_MIN_CHARACTER_LENGTH,
  ORG_SLUG_MAX_CHARACTER_LENGTH,
  ORG_SLUG_MIN_CHARACTER_LENGTH,
} from '@/config/module/organizationConstants'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import { hexToHSL } from '@/lib/helpers/stringHelpers'
import type {
  IOrganizationCreateData,
  IOrganizationData,
  IOrganizationPlan,
  IRootData,
} from '@/types/organization'

export const checkNameHasSpecialChar = (value: string) => {
  // eslint-disable-next-line no-useless-escape
  // Regex to check if string has special characters but can have space and alphanumeric and hyphen
  const regex = /^[a-zA-Z0-9- ]*$/
  return !regex.test(value)
}

export const regexToGetValueBetweenDoubleQuotes = (value: string) => {
  const regex = /"(.*?)"/g
  const result = regex.exec(value)
  return result ? result[1] : ''
}

export const validateNewOrganizationData = (
  orgData: IOrganizationCreateData
): { field: 'name' | 'domain'; error: string } | null => {
  if (orgData.name) {
    if (checkNameHasSpecialChar(orgData.name)) {
      return { field: 'name', error: 'ERROR_CODE_ORG_SPECIAL_CHARACTER' }
    }
    if (orgData.name.length < ORG_NAME_MIN_CHARACTER_LENGTH) {
      return { field: 'name', error: 'ERROR_CODE_ORG_NAME_SHORT' }
    }
    if (orgData.name.length >= ORG_NAME_MAX_CHARACTER_LENGTH) {
      return {
        field: 'name',
        error: `Organization name must be less than ${ORG_NAME_MAX_CHARACTER_LENGTH} characters long`,
      }
    }
  }
  if (orgData.domain) {
    if (orgData.domain?.startsWith('deleted')) {
      return { field: 'domain', error: 'ERROR_CODE_BAD_ORGANIZATION_DOMAIN' }
    }
    if (orgData.domain.length < ORG_SLUG_MIN_CHARACTER_LENGTH) {
      return { field: 'domain', error: 'ERROR_CODE_ORG_DOMAIN_SHORT' }
    }
    if (orgData.domain.length >= ORG_SLUG_MAX_CHARACTER_LENGTH) {
      return {
        field: 'domain',
        error: `Organization slug must be less than ${ORG_SLUG_MAX_CHARACTER_LENGTH} characters long`,
      }
    }
  }
  return null
}

export const shouldShowNewWidget = (organization: IOrganizationData) =>
  new Date(organization.created_at) >= new Date(V3_WIDGET_THRESHOLD_DATE)

export const orgPassed3Weeks = (organization: IOrganizationData) => {
  const createdAt = new Date(organization.created_at)
  const now = new Date()
  const diffTime = Math.abs(now.getTime() - createdAt.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays > 21
}

export const orgColorConversion = (
  theme: Pick<
    IRootData['theme'],
    'brandColor' | 'brandColorDark' | 'brandColorLight'
  >
): Pick<
  IRootData['theme'],
  'brandColorHSL' | 'brandColorDarkHSL' | 'brandColorLightHSL'
> => {
  return {
    brandColorHSL: hexToHSL(theme?.brandColor),
    brandColorDarkHSL: hexToHSL(theme?.brandColorDark),
    brandColorLightHSL: hexToHSL(theme?.brandColorLight),
  }
}

export const articleSuggestionEnaledCheck = (
  organizationPlan: IOrganizationPlan,
  organization: IOrganizationData,
  // eslint-disable-next-line prettier/prettier
  show_knowledge_base_tab: boolean
) => {
  if (organization.name === 'feedback') return true
  if (!show_knowledge_base_tab) return false
  return featureIsEnabled(
    'kb_ai_suggestions',
    organizationPlan as IOrganizationPlan
  )
}
