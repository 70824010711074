import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    'external-video': {
      setExternalVideo: (options: any) => ReturnType
    }
  }
}
export default Node.create({
  name: 'external-video',
  addOptions: () => ({
    inline: true,
    HTMLAttributes: {},
    class: 'h-full w-full',
  }),

  inline() {
    return this.options.inline
  },

  group() {
    return this.options.inline ? 'inline' : 'block'
  },

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      title: {
        default: null,
      },
      frameborder: {
        default: '0',
      },
      allow: {
        default:
          'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
      },
      allowfullscreen: {
        default: 'allowfullscreen',
      },
      class: {
        default: 'h-full w-full',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'iframe[src]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const isArcade =
      HTMLAttributes.src.includes('arcade.software') ||
      HTMLAttributes.src.includes('threads.net')

    return [
      'div',
      { class: isArcade ? 'my-3 h-full w-full' : 'aspect-video my-3' },
      ['iframe', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)],
    ]
  },
  addCommands() {
    return {
      setExternalVideo:
        (options) =>
        ({ commands }) =>
          commands.insertContent({
            type: this.name,
            attrs: options,
          }),
    }
  },
  addPasteRules() {
    return [
      nodePasteRule({
        type: this.type,
        find: /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^?&"'>]+)(?:.*[?&]t=([0-9]+))?/g,
        getAttributes: (match) => {
          return {
            src: `https://www.youtube.com/embed/${match[1]}?start=${
              match[2] || 0
            }`,
          }
        },
      }),
      nodePasteRule({
        type: this.type,
        find: /^(?:https?:\/\/)?(?:www\.)?(?:m\.)?loom.com\/share\/([a-zA-Z0-9]+)(?:\?[\w=&-]+)?$/g,

        getAttributes: (match) => {
          return {
            src: `https://www.loom.com/embed/${match[1]}`,
          }
        },
      }),
      nodePasteRule({
        type: this.type,
        find: /^(?:https?:\/\/)?(?:www\.)?figma\.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/g,
        getAttributes: (match) => {
          return {
            src: `https://www.figma.com/embed?embed_host=share&url=${encodeURIComponent(
              match[0]
            )}`,
          }
        },
      }),
      nodePasteRule({
        type: this.type,
        find: /^(?:https?:\/\/)?(?:app\.)?arcade\.software\/share\/([0-9a-zA-Z]{20,})$/g,
        getAttributes: (match) => {
          return {
            src: `https://app.arcade.software/${match[1]}?embed`,
          }
        },
      }),
      nodePasteRule({
        type: this.type,
        find: /^(?:https?:\/\/)?(?:app\.)?guidde\.com\/share\/playbooks\/([0-9a-zA-Z]{10,})(\?.*)?$/g,
        getAttributes: (match) => {
          return {
            src: `https://embed.app.guidde.com/playbooks/${match[1]}`,
          }
        },
      }),
      nodePasteRule({
        type: this.type,
        find: /^(?:https?:\/\/)?(?:www\.)?threads\.net\/t\/([0-9a-zA-Z]{10,})(\?.*)?$/g,
        getAttributes: (match) => {
          return {
            src: `https://threads.net/t/${match[1]}/embed`,
          }
        },
      }),
    ]
  },
})
