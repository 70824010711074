import { Activity, Chat } from '@phosphor-icons/react'
import React, { useContext } from 'react'

import RecentActivities from '@/components/activites/RecentActivities'
import CommentsList from '@/components/posts/comments/CommentsList'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/shared/ui/Tabs/Tabs'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
}
export default function SinglePostTab({ post }: IPropTypes) {
  const { userSignedIn } = useContext(HNContext)
  const t = useTranslations('common.modules')

  return (
    <Tabs defaultValue='Comments' className='px-2'>
      <TabsList>
        <TabsTrigger value='Comments' icon={<Chat />}>
          {t('comments')}
        </TabsTrigger>
        {userSignedIn && (
          <TabsTrigger value='Activities' icon={<Activity weight='duotone' />}>
            {t('activities')}
          </TabsTrigger>
        )}
      </TabsList>
      <TabsContent value='Comments'>
        <CommentsList post={post} />
      </TabsContent>
      {userSignedIn && (
        <TabsContent value='Activities'>
          <RecentActivities post={post} />
        </TabsContent>
      )}
    </Tabs>
  )
}
