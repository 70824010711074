import { Circle, Plus } from '@phosphor-icons/react'
import React, { useMemo } from 'react'

import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import { SENTIMENT_ANALYSIS_COLORS } from '@/config/module/colorConstant'
import { useTranslations } from '@/hooks/useTranslations'
import type { IFilters } from '@/types/common'
import type { IPostListAPIParams } from '@/types/post'

interface IPropType {
  onChange?: (key: string, value: any) => void
  filters: IFilters | IPostListAPIParams
  multiple?: boolean
  placeholder?: string
  disabled?: boolean
  behaviour: string
  clearable?: boolean
  showSelectedOptionsCount?: boolean
  itemFilter?: (item: ISelectOption) => boolean
  moreOptions?: ISelectOption[]
}

const FILTER_KEY = 'sentiment_type'

const SENTIMENTS = [
  {
    value: 'positive',
  },
  {
    value: 'negative',
  },
  {
    value: 'neutral',
  },
]

export default function AdminPostSentimentFilter({
  onChange,
  filters,
  multiple = true,
  placeholder = '',
  disabled = false,
  behaviour = 'filter',
  clearable = false,
  showSelectedOptionsCount = true,
  moreOptions = [],
  itemFilter,
}: IPropType) {
  const t = useTranslations('post')
  const sentimentsOptions = useMemo(() => {
    const sentimentArray = [...SENTIMENTS, ...moreOptions]
    return sentimentArray
      .map((s) => ({
        ...s,
        label: t(`sentiment.${s.value}`),
        icon: (
          <Circle
            weight='fill'
            className='h-3 w-3'
            color={
              SENTIMENT_ANALYSIS_COLORS.find((c) => c.value === s.value)?.hex
            }
          />
        ),
      }))
      .filter(itemFilter || (() => true))
  }, [SENTIMENTS, filters])

  const handleChange = (value: ISelectOption | ISelectOption[] | undefined) => {
    if (!onChange) return
    if (!value) return
    const selectedValue = Array.isArray(value) ? value : [value]
    const selectedTag = selectedValue.map((item) => item.value)
    onChange(FILTER_KEY, selectedTag)
  }

  const getValue = () => {
    if (filters && filters[FILTER_KEY]) {
      const filteredSentiment = filters[FILTER_KEY]
      return sentimentsOptions.filter((s) =>
        filteredSentiment.includes(s.value.toString())
      )
    }
    return null
  }

  return (
    <Select
      size='xs'
      disabled={disabled}
      clearable={clearable}
      searchable
      value={getValue()}
      onChange={handleChange}
      options={sentimentsOptions}
      multiple={multiple}
      placeholder={placeholder.toString()}
      placeholderIcon={behaviour === 'filter' ? <></> : <Plus />}
      showAvatar
      inputProps={{
        placeholder,
      }}
      showSelectedOptionsCount={showSelectedOptionsCount}
    />
  )
}
