import React, { useContext } from 'react'

import Avatar from '@/components/shared/ui/Avatar'
import { HelloNextIcon } from '@/components/shared/ui/Icons'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Typography from '@/components/shared/ui/Typography'
import { APP_NAME, SUPPORT_EMAIL } from '@/config/appConstants'
import HNContext from '@/context/HNContext'
import { stringifyValue } from '@/lib/helpers/stringHelpers'

const ORGANIZATION_CLASSNAME =
  'group flex items-center justify-between hover:no-underline focus:outline-none truncate min-w-0'
export default function GuestHeader() {
  const { organization } = useContext(HNContext)

  const renderOrgName = () => {
    const props = {
      avatar: { thumb_url: organization ? organization.avatar : null },
      name: organization ? stringifyValue(organization.name) : APP_NAME,
    }
    return (
      <>
        <Avatar {...props} rounded />
        <Typography.Text className='ml-2 truncate text-base font-semibold !text-carbon transition '>
          {props.name}
        </Typography.Text>
      </>
    )
  }

  const renderHeader = () => {
    if (organization) {
      return (
        <UnstyledLink href='/' className={ORGANIZATION_CLASSNAME}>
          {renderOrgName()}
        </UnstyledLink>
      )
    }
    return <HelloNextIcon className='text-carbon ' />
  }

  return (
    <header className='relative border-b border-gray4 bg-snow transition '>
      <div className='mx-auto flex min-h-[50px] w-full max-w-5xl items-center justify-between px-4 py-2'>
        {renderHeader()}
        <div className='flex shrink-0 items-center justify-end space-x-3 sm:ml-4'>
          <a
            href={`mailto:${SUPPORT_EMAIL}`}
            className='font-medium text-gray8'
          >
            Need Help?
          </a>
        </div>
      </div>
    </header>
  )
}
