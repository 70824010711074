import { Article, Plus, RocketLaunch } from '@phosphor-icons/react'
import React, { useContext, useRef } from 'react'

import type { INewChangelogButtonActions } from '@/components/admin/changelog/new/NewChangelogButton'
import NewChangelogButton from '@/components/admin/changelog/new/NewChangelogButton'
import type { AdminKBNewArticleDialogActions } from '@/components/admin/knowledgeBase/article/AdminKBNewArticleDialog'
import AdminKBNewArticleDialog from '@/components/admin/knowledgeBase/article/AdminKBNewArticleDialog'
import Button from '@/components/shared/ui/Button'
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import { recordEvent } from '@/lib/helpers/appHelpers'

export default function AsideNewDropdown() {
  const t = useTranslations('sidebar.actions')
  const { organizationPlan } = useContext(HNContext)

  const createArticleDialog = useRef<AdminKBNewArticleDialogActions>(null)
  const createChangelogDialog = useRef<INewChangelogButtonActions>(null)

  const handleNewPost = () => {
    recordEvent('CREATE_NEW_TRIGGERED', {
      action: 'NEW_POST',
    })
    EventEmitter.dispatch('SHORTCUT_TRIGGERED', { action: 'CREATE' })
  }

  const handleNewArticle = () => {
    recordEvent('CREATE_NEW_TRIGGERED', {
      action: 'NEW_ARTICLE',
    })
    createArticleDialog.current?.openDialog({})
  }

  const handleNewChangelog = () => {
    recordEvent('CREATE_NEW_TRIGGERED', {
      action: 'NEW_CHANGELOG',
    })
    createChangelogDialog.current?.toggleOpen()
  }

  return (
    <Dropdown>
      <DropdownTrigger asChild>
        <div className={'m-2'}>
          <Button variant='default' icon={<Plus weight='bold' />} block>
            {t('new')}
          </Button>
        </div>
      </DropdownTrigger>
      <DropdownContent className='!w-[213px] !max-w-none'>
        <DropdownItem
          className='font-semibold'
          itemValue={t('post')}
          onSelect={handleNewPost}
          icon={<Plus weight='bold' />}
        />
        <DropdownItem
          className='font-semibold'
          itemValue={t('changelog')}
          onSelect={handleNewChangelog}
          icon={<RocketLaunch weight='bold' />}
        />
        {organizationPlan?.allow_knowledge_base && (
          <DropdownItem
            className='font-semibold'
            itemValue={t('article')}
            onSelect={handleNewArticle}
            icon={<Article weight='bold' />}
          />
        )}
      </DropdownContent>
      <NewChangelogButton ref={createChangelogDialog} noTrigger />
      <AdminKBNewArticleDialog ref={createArticleDialog} />
    </Dropdown>
  )
}
