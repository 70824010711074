import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import SinglePost from '@/components/posts/singlePost/SinglePost'
import Spinner from '@/components/shared/ui/Loader'
import FourNotFour from '@/pages/404'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

import SinglePostModal from '../../posts/singlePost/SinglePostModal'

interface IPropTypes {
  onClose?: () => void
  isModal?: boolean
}

const AdminSinglePost = observer((props: IPropTypes) => {
  const { onClose, isModal } = props
  const router = useRouter()
  const { postSlug } = router.query

  const fetchData = () => {
    postStore.getPost(postSlug?.toString() || '').catch(toaster.error)
  }

  useEffect(() => {
    if (router.query.postSlug) {
      fetchData()
    }
  }, [router.query.postSlug])

  const post = postStore.posts.find(
    (p: IPost) => (p.slug === postSlug || p.oldSlug === postSlug) && !!p.tags
  )
  if (!postStore.postNotFound && (!post || !Object.keys(post)?.length)) {
    return isModal ? (
      <div className='absolute z-[100] mx-auto h-full w-full bg-snow dark:bg-[#1c1d1f]'>
        <Spinner isScreen />
      </div>
    ) : (
      <Spinner isScreen />
    )
  }

  if (!post) return <FourNotFour />

  if (isModal) {
    return <SinglePostModal post={post} onClose={onClose} />
  }
  return <SinglePost post={post} onClose={onClose} />
})

export default AdminSinglePost
