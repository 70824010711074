import { X } from '@phosphor-icons/react'
import clsx from 'clsx'
import Link from 'next/link'
import React, { useMemo } from 'react'

import { USER_ATTRIBUTE_FIELD_TYPES_ICONS_MAP } from '@/config/module/userConstants'
import { RegexValidator } from '@/lib/helpers/stringHelpers'
import type { IUserAttribute } from '@/types/user'

interface IPropTypes {
  item: IUserAttribute
  onRemove: (keyId: number) => void
}

export const PILL_CLASS =
  'text-xs relative inline-flex gap-1 items-center cursor-default border border-gray6    bg-snow px-2 py-1 text-sm font-medium text-gray10 hover:bg-gray3 focus:z-10 focus:border-indigo8 focus:outline-none focus:ring-1 focus:ring-indigo8'

export default function UserAttributePill({ item, onRemove }: IPropTypes) {
  const Icon = useMemo(
    () => USER_ATTRIBUTE_FIELD_TYPES_ICONS_MAP[item.custom_field.field_type],
    [item.custom_field.field_type]
  )
  const value = useMemo(() => {
    if (item.custom_field.field_type === 'boolean') {
      return item.value ? 'True' : 'False'
    }
    if (item.custom_field.field_type === 'date') {
      return new Date(item.value).toLocaleDateString()
    }
    if (RegexValidator.isValid(item.value, 'reg_url')) {
      return (
        <Link
          href={item.value}
          target='_blank'
          className={clsx(
            PILL_CLASS,
            '-ml-px cursor-pointer whitespace-nowrap text-primary'
          )}
        >
          {item.value}
        </Link>
      )
    }
    return item.value || 'No Value'
  }, [item])

  return (
    <span className='isolate inline-flex items-center rounded-md' key={item.id}>
      <div
        className={clsx(PILL_CLASS, '-ml-px whitespace-nowrap rounded-l-md')}
      >
        <Icon className='h-3.5 w-3.5' />
        <p>{item.custom_field.title}</p>
      </div>

      <div
        className={clsx(PILL_CLASS, '-ml-px whitespace-nowrap text-primary')}
      >
        {value}
      </div>
      <div
        className={clsx(
          PILL_CLASS,
          '-ml-px cursor-pointer whitespace-nowrap rounded-r-md bg-gray3 py-[6px] text-primary'
        )}
        onClick={() => onRemove(item.id)}
      >
        <X className='h-3.5 w-3.5' />
      </div>
    </span>
  )
}
