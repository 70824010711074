import { GitFork } from '@phosphor-icons/react'
import { AccordionItem } from '@radix-ui/react-accordion'
import React, { useEffect, useState } from 'react'

import UserPostItem from '@/components/posts/UserPostItem'
import Accordion from '@/components/shared/ui/Accordion'
import {
  AccordionContent,
  AccordionTrigger,
} from '@/components/shared/ui/Accordion/Accordion'
import { useTranslations } from '@/hooks/useTranslations'
import type { IEntityUpdateEventData } from '@/lib/eventEmitter'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { getOptimizedPosts } from '@/models/Post'
import type { IPost } from '@/types/post'

interface IPropTypes {
  post: IPost
}
export default function SinglePostMergedPostsList({ post }: IPropTypes) {
  const t = useTranslations('post.unmergePost')

  const [posts, setPosts] = React.useState<IPost[] | null>(null)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const fetchData = () => {
    if (post.merged_posts_count === 0) return
    if (posts) return
    if (loading) return
    setLoading(true)
    getOptimizedPosts({
      parent_id: [post.id],
    })
      .then((res) => {
        setPosts(res)
      })
      .catch((err) => {
        setErrorMessage(err.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleUpdatePosts = (eventData: IEntityUpdateEventData) => {
    const { actionType, entity, data } = eventData
    if (entity !== ENTITIES.POSTS) return
    if (actionType === EVENT_ACTION_TYPES.UPDATE) setPosts(data.data)
  }

  useEffect(() => {
    if (post) fetchData()
  }, [post])

  useEffect(() => {
    EventEmitter.subscribe('ENTITY_UPDATE', handleUpdatePosts)
    return () => {
      EventEmitter.unsubscribe('ENTITY_UPDATE', handleUpdatePosts)
    }
  }, [])

  if (!posts || loading) return null
  if (errorMessage) return <p>{errorMessage}</p>
  if (!posts.length) return null

  return (
    <div>
      <Accordion type='single' collapsible className='-mt-1'>
        <AccordionItem value='posts'>
          <AccordionTrigger className='!rounded-lg bg-gray2 px-2 text-left data-[state=open]:!rounded-b-none'>
            <div className='w-full py-1 text-left text-gray12'>
              <p className='inline-flex items-center gap-2 font-semibold'>
                <GitFork weight='fill' />
                <span>{t('titlePlural')}</span>
              </p>
            </div>
          </AccordionTrigger>
          <AccordionContent className='max-h-64 overflow-y-auto overflow-x-hidden rounded-b-lg border border-t-0 border-gray6'>
            {posts.map((mergedPost) => (
              <UserPostItem
                className='!py-2 px-4'
                post={mergedPost}
                hideVotesSection
                key={mergedPost.id}
                openInNewTab
                hideInfoSection
                showDescriptionPlaceholder
              />
            ))}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
