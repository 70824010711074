import React, { useEffect, useState } from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import { setSelectedValue } from '@/lib/helpers/dataHelpers'
import type { IStatusSyncRule } from '@/types/integration/statusSync'

interface IAdminIntegrationSyncSourceDropdownProps {
  sourceData: ISelectOption[]
  syncRule: IStatusSyncRule
  edit: boolean
  onChange: (key: string, value: string) => void
  provider: string
}

export default function AdminIntegrationSyncSourceDropdown({
  sourceData,
  provider,
  syncRule,
  edit,
  onChange,
}: IAdminIntegrationSyncSourceDropdownProps) {
  const t = useTranslations(`integrations.${provider}`)
  const [syncSources, setSyncSources] = useState(sourceData)

  useEffect(() => {
    if (!syncSources) {
      setSyncSources([
        {
          label: t(`title`),
          value: provider,
        },
        {
          label: t(`common.hellonext`, {
            ignorePrefix: true,
          }),
          value: 'hellonext',
        },
      ])
    }
  }, [syncSources])

  if (!syncSources) return null

  const handleChange = (source: ISelectOptionGroup) => {
    if (Array.isArray(source)) {
      const value = source[0]?.value
      if (value) {
        onChange('source', value)
      }
    } else {
      const value = source?.value
      if (value) {
        onChange('source', value)
      }
    }
  }

  return (
    <Select
      size='xs'
      placeholder={t(`configure.syncRule.hellonext.source`)}
      defaultValue={setSelectedValue(syncSources, syncRule.source)}
      options={syncSources}
      onChange={handleChange}
      disabled={!edit}
      disableSelected
    />
  )
}
