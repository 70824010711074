import type { Icon } from '@phosphor-icons/react'
import { CaretDown } from '@phosphor-icons/react'
import {
  RepoIcon,
  RocketIcon,
  RowsIcon,
  TelescopeIcon,
} from '@primer/octicons-react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useState } from 'react'

import UserAvatar from '@/components/shared/components/UserAvatar'
import {
  OrgEmbedNoShowWrapper,
  OrgEmbedShowWrapper,
} from '@/components/shared/components/wrapper/EmbedWrapper'
import Avatar from '@/components/shared/ui/Avatar'
import Badge from '@/components/shared/ui/Badge'
import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownLabel,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Typography from '@/components/shared/ui/Typography'
import UserSideUserAuthButton from '@/components/users/shared/UserSideUserAuthButton'
import { HELP_PAGE_DOMAIN, KB_DOMAIN } from '@/config/appConstants'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import clsx from '@/lib/clsxm'
import type { IEntityUpdateEventData } from '@/lib/eventEmitter'
import { ENTITIES, EventEmitter } from '@/lib/eventEmitter'
import { pluralize } from '@/lib/helpers/appHelpers'
import {
  embedHomePath,
  postWidgetPath,
  userSideModulePath,
  userSidePostSinglePath,
} from '@/lib/helpers/pathHelpers'
import { templateStringReplace } from '@/lib/helpers/stringHelpers'
import { getBasePathPrefix } from '@/lib/helpers/urlHelpers'
import type { IBoardDetails } from '@/types/board'
import type {
  IEmbedType,
  IOrganizationCustomization,
  IOrganizationData,
  IOrganizationSetting,
} from '@/types/organization'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'

const NewPostButton = dynamic(
  () => import('@/components/posts/newPost/NewPostButton'),
  { ssr: false }
)

export default function UserSideHeader() {
  const router = useRouter()
  const t = useTranslations()
  const [open, setOpen] = useState(false)
  const {
    organization,
    organizationSetting,
    default_module: defaultModule,
    buckets,
    show_changelog_tab,
    show_roadmap_tab,
    show_boards_tab,
    show_knowledge_base_tab,
    embedType,
    noHeader,
    userProfile,
    organizationCustomization,
  } = useContext(HNContext) as {
    organization: IOrganizationData
    organizationSetting: IOrganizationSetting
    default_module: string
    buckets: IBoardDetails[]
    show_changelog_tab: boolean
    show_roadmap_tab: boolean
    show_boards_tab: boolean
    show_knowledge_base_tab: boolean
    embedType: string
    noHeader: boolean
    userProfile: IUserProfile
    organizationCustomization: IOrganizationCustomization
  }
  const [boardId, setBoardId] = useState<string | null>(null)
  const activeBoard = useMemo(() => {
    const { bucket_id, boardSlug } = router.query
    let board
    if (boardSlug) board = buckets?.find((bucket) => bucket.name === boardSlug)
    if (bucket_id)
      board = buckets?.find((bucket) => bucket?.id?.toString() === bucket_id)
    if (boardId) board = buckets?.find((bucket) => bucket?.id === boardId)
    return board
  }, [buckets, router.query.boardSlug, router.query.bucket_id, boardId])

  const publicBoards = useMemo(
    () => buckets?.filter((bucket) => !bucket.private),
    [buckets]
  )
  const privateBoards = useMemo(
    () => buckets?.filter((bucket) => bucket.private),
    [buckets]
  )

  const isActive = (module: string) => {
    switch (module) {
      case 'roadmap':
        return (
          (defaultModule === 'roadmap' && router.pathname === '/') ||
          router.pathname.includes('/roadmap') ||
          router.asPath.includes('/embed/roadmap') ||
          (defaultModule === 'roadmap' && router.pathname === embedHomePath)
        )
      case 'changelog':
        return (
          (defaultModule === 'changelog' && router.pathname === '/') ||
          router.pathname === '/changelog' ||
          router.pathname === '/changelog/[changelogSlug]' ||
          router.asPath.includes('/embed/home/changelog') ||
          (defaultModule === 'changelog' && router.pathname === embedHomePath)
        )
      case 'knowledgeBase':
        return (
          (defaultModule === 'knowledgeBase' && router.pathname === '/') ||
          router.pathname === '/kb'
        )
      case 'boards':
      default:
        return (
          (defaultModule === 'feedback' && router.pathname === '/') ||
          router.pathname === '/b' ||
          router.pathname === '/embed/home/b' ||
          router.pathname === '/b/[boardSlug]' ||
          router.pathname === '/p/[postSlug]' ||
          router.asPath.includes('/embed/b') ||
          router.pathname === '/embed/home/p/[postSlug]' ||
          (defaultModule === 'feedback' && router.pathname === embedHomePath)
        )
    }
  }

  const handleSinglePostRedirect = (post: IPost) => {
    if (router.asPath.includes(postWidgetPath)) {
      router.push(`/postwidget/p/${post.slug}`)
    } else if (router.asPath.includes(embedHomePath)) {
      router.push(`/embed/home/p/${post.slug}`)
    } else {
      router.push(userSidePostSinglePath(post.slug))
    }
    return true
  }

  const renderNewpost = () => {
    if (router.pathname) {
      return null
    }
    if (
      (router.pathname === '/' &&
        ['roadmap', 'feedback'].includes(defaultModule)) ||
      router.pathname === '/b' ||
      router.pathname === '/b/[boardSlug]' ||
      ((router.pathname === '/roadmap' ||
        router.pathname === '/roadmap/b/[boardSlug]') &&
        organizationCustomization?.published_roadmap_type === 'default') ||
      router.asPath.includes('/embed/home')
    )
      return (
        <NewPostButton
          boardId={activeBoard?.id}
          onCreate={handleSinglePostRedirect}
          testId='user_side_header_new_post_button'
        />
      )
    return false
  }

  const resolveHomePath = () => {
    if (show_boards_tab && defaultModule === 'feedback') {
      return userSideModulePath(
        'boards',
        defaultModule,
        getBasePathPrefix(embedType as IEmbedType)
      )
    }
    if (show_roadmap_tab && defaultModule === 'roadmap') {
      return userSideModulePath(
        'roadmap',
        defaultModule,
        getBasePathPrefix(embedType as IEmbedType)
      )
    }
    if (
      show_changelog_tab &&
      (defaultModule === 'changelog' ||
        (!userProfile && organizationSetting?.organization_privacy))
    ) {
      return userSideModulePath(
        'changelog',
        defaultModule,
        getBasePathPrefix(embedType as IEmbedType)
      )
    }
    return '/'
  }

  const handleUpdateActiveBoard = (data: IEntityUpdateEventData) => {
    const { entity, data: _data } = data
    if (entity !== ENTITIES.HEADER_BOARD_NAME) return
    setBoardId(_data.data.boardId)
  }

  useEffect(() => {
    EventEmitter.subscribe('CONFIG_UPDATE', handleUpdateActiveBoard)

    return () => {
      EventEmitter.unsubscribe('CONFIG_UPDATE', handleUpdateActiveBoard)
    }
  }, [])

  const numberOfTabs = useMemo(() => {
    let count = 0
    if (show_boards_tab) count += 1
    if (show_roadmap_tab) count += 1
    if (show_changelog_tab) count += 1
    if (show_knowledge_base_tab) count += 1
    return count
  }, [
    show_boards_tab,
    show_roadmap_tab,
    show_changelog_tab,
    show_knowledge_base_tab,
  ])

  const renderModuleLink = (
    moduleName: string,
    label: string,
    NavIcon: Icon,
    beta: boolean
  ) => {
    let url = userSideModulePath(
      moduleName,
      defaultModule,
      getBasePathPrefix(embedType as IEmbedType)
    )
    if (moduleName === 'knowledgeBase' && organization.domain === 'feedback') {
      url = `https://${HELP_PAGE_DOMAIN}`
    } else if (moduleName === 'knowledgeBase') {
      url = `https://${organization.domain}.${KB_DOMAIN}`
    }
    return (
      <UnstyledLink
        key={`user_side_header_${moduleName}_tab`}
        data-testid={`user_side_header_${moduleName}_tab`}
        href={url}
        className={clsx(
          'group flex items-center space-x-1 px-2 py-1 text-sm font-bold uppercase text-gray11 hover:!text-primary',
          defaultModule === moduleName ? 'order-first' : '',
          isActive(moduleName) ? 'text-primary' : ''
        )}
      >
        <>
          <NavIcon className='flex h-4 w-4 shrink-0 items-center opacity-60 hover:opacity-100 group-hover:opacity-100' />
          <Typography.Text
            className={clsx(
              'max-w-xs truncate !text-[10px] font-bold !text-gray12/70 duration-150 group-hover:!text-primary',
              isActive(moduleName) ? '!text-primary' : ''
            )}
          >
            {label}
          </Typography.Text>
          {!!beta && (
            <Badge
              testId={`user_side_header_${moduleName}_beta_badge`}
              className='ml-auto flex rounded bg-gray5 px-1.5 py-1 font-mono text-xxs font-semibold lowercase leading-none text-gray10 transition group-hover:!text-primary'
              size='xxs'
              value='β'
            />
          )}
        </>
      </UnstyledLink>
    )
  }

  const renderBoardLink = (board: IBoardDetails) => (
    <DropdownItem
      dataTestId={`user_side_header_${board.name}_dropdown_item`}
      key={board.id}
      itemValue={board.display_name}
      href={`${embedType === 'orgEmbed' ? '/embed/home/' : ''}/b/${board.name}`}
      onSelect={() => {
        setOpen(false)
      }}
    />
  )

  const renderBoardPostListTab = () => {
    if (buckets?.length === 1)
      return (
        <>
          {buckets?.map((board) =>
            renderModuleLink(
              'boards',
              board.display_name,
              RowsIcon as Icon,
              false
            )
          )}
        </>
      )
    return (
      <>
        <Dropdown open={open} onOpenChange={setOpen}>
          <DropdownTrigger asChild={false}>
            <div
              className={clsx(
                'hn-animate-duration group mr-0.5 flex items-center space-x-1 px-2 py-1.5 text-sm uppercase !tracking-wider text-gray11 hover:!text-primary',
                defaultModule === 'roadmap' ? 'order-first' : '',
                isActive('boards') ? 'text-primary' : ''
              )}
              data-testid='user_side_header_boards_tab'
            >
              <RowsIcon className='flex h-4 w-4 shrink-0 items-center opacity-60 hover:opacity-100 group-hover:opacity-100' />
              <Typography.Text
                className={clsx(
                  'max-w-xs truncate !text-[10px] font-bold !text-gray12/70 group-hover:text-primary',
                  isActive('boards') ? '!text-primary' : ''
                )}
              >
                {activeBoard && isActive('boards')
                  ? activeBoard.display_name
                  : organizationSetting.bucket_plural_name}
              </Typography.Text>
              <CaretDown />
            </div>
          </DropdownTrigger>
          <DropdownContent
            data-testid='user_side_header_boards_dropdown'
            align='start'
          >
            <DropdownItem
              dataTestId='user_side_header_all_boards_dropdown_item'
              itemValue={templateStringReplace(
                t('posts.moduleLabels.allBoards'),
                {
                  boards: organizationSetting.bucket_plural_name,
                }
              )}
              href={userSideModulePath(
                'boards',
                defaultModule,
                getBasePathPrefix(embedType as IEmbedType)
              )}
              onSelect={() => setOpen(false)}
            />
            {Boolean(!!publicBoards.length && !!privateBoards.length) && (
              <DropdownLabel>
                {templateStringReplace(t('posts.moduleLabels.publicBoards'), {
                  boards: pluralize(
                    publicBoards.length,
                    organizationSetting.bucket_singular_name,
                    organizationSetting.bucket_plural_name
                  ),
                })}
              </DropdownLabel>
            )}
            {publicBoards.map(renderBoardLink)}
            {Boolean(privateBoards.length) && (
              <DropdownLabel>
                {templateStringReplace(t('posts.moduleLabels.privateBoards'), {
                  boards: pluralize(
                    privateBoards.length,
                    organizationSetting.bucket_singular_name,
                    organizationSetting.bucket_plural_name
                  ),
                })}
              </DropdownLabel>
            )}
            {privateBoards.map(renderBoardLink)}
          </DropdownContent>
        </Dropdown>
      </>
    )
  }

  return (
    <header
      data-testid='user_side_header_wrapper'
      className={clsx(
        'sticky top-0 z-[2] border-b border-gray4/50 bg-layoutBase bg-opacity-50 bg-blend-overlay backdrop-blur-3xl transition dark:bg-[#1c1d1f]/10'
      )}
    >
      <div className={clsx('mx-auto max-w-7xl')}>
        <div
          className={clsx(
            'flex w-full items-center justify-between px-2 py-2 md:px-4'
          )}
        >
          {!noHeader && (
            <div
              className={clsx(
                'group flex min-w-0 items-center justify-between truncate focus:outline-none'
              )}
            >
              <UnstyledLink
                href={resolveHomePath()}
                className={clsx('flex items-center space-x-2')}
              >
                <>
                  <span className={clsx('h-8 w-8')}>
                    <Avatar
                      url={organization?.avatar?.thumb_url || ''}
                      name={organization?.name || ''}
                      rounded
                    />
                  </span>
                  <span
                    className={clsx(
                      'mb-0 ml-2 truncate text-base font-semibold text-gray12'
                    )}
                  >
                    {organization?.name}
                  </span>
                </>
              </UnstyledLink>
            </div>
          )}
          {/* <UserSideSearchInput /> */}
          <UserSideUserAuthButton />
        </div>
        <div
          className={clsx(
            'flex items-center justify-center space-x-2 pt-1 md:px-3'
          )}
        >
          <div
            className={clsx(
              'flex w-full items-center justify-between py-0.5 pr-1'
            )}
          >
            <div
              className={clsx(
                'flex items-center overflow-x-auto scrollbar-hide md:justify-start',
                numberOfTabs > 3 ? 'gradient-mask-right' : ''
              )}
            >
              {show_boards_tab && renderBoardPostListTab()}
              {show_roadmap_tab &&
                renderModuleLink(
                  'roadmap',
                  organizationSetting.roadmap_name,
                  TelescopeIcon as Icon,
                  false
                )}
              {show_changelog_tab &&
                renderModuleLink(
                  'changelog',
                  organizationSetting.changelog_plural_name,
                  RocketIcon as Icon,
                  false
                )}
              <OrgEmbedNoShowWrapper>
                <>
                  {show_knowledge_base_tab &&
                    renderModuleLink(
                      'knowledgeBase',
                      t('header.moduleLinks.knowledgeBase'),
                      RepoIcon as Icon,
                      false
                    )}
                </>
              </OrgEmbedNoShowWrapper>
            </div>
            <div className='flex items-center space-x-2'>
              {renderNewpost()}
              {noHeader && (
                <OrgEmbedShowWrapper>
                  {userProfile && <UserAvatar rounded user={userProfile} />}
                </OrgEmbedShowWrapper>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
