/* eslint-disable prettier/prettier */
import type { IKeyValueMap } from 'mobx'

import {
  CUSTOM_DOMAIN_VERIFICATION_PATH,
  EMAILS_PATH,
  ENTERPRISE_CUSTOMIZATION_PATH,
  GENERATE_API_KEY_PATH,
  GENERATE_LETTERBOX_MAIL,
  GENERATE_SSO_KEY_PATH,
  GET_ONBOARDING_STEPS_PATH,
  LIST_KEYWORDS_PATH,
  LIST_ORGANIZATIONS_PATH,
  ORGANIZATION_ANNOUNCEMENT_PATH,
  ORGANIZATION_CUSTOMIZATION_PATH,
  ORGANIZATION_DELETION_INFO,
  ORGANIZATION_DELETION_REQUEST,
  ORGANIZATION_DELETION_REQUESTS,
  ORGANIZATION_INFO_PATH,
  ORGANIZATION_SETTINGS_PATH,
  ORGANIZATION_USAGE_PATH,
  ORGANIZATION_VALIDATION_PATH,
  PAGE_VIEW_PATH,
  POST_ASSIGNEES_PATH,
  RECENT_ACTIVITIES_PATH,
  REMOVE_FAVICON_PATH,
  REMOVE_LOGO_PATH,
  REMOVE_OG_IMAGE_PATH,
  RESOURCE_ACTIVITIES_PATH,
  SKIP_ONBOARDING_STEPS_PATH,
  SMTP_CREATION_PATH,
  SMTP_TEST_PATH,
  TRAIL_MODULE_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import { transformActivity } from '@/lib/helpers/modules/activityHelper'
import type { IActivityItem } from '@/types/activity'
import type { IRequestHeaders, IResourceTypes } from '@/types/common'
import type {
  IActivitiesListAPIParams,
  IOrganizationCreateData,
  IOrganizationItem,
} from '@/types/organization'
import type { IUserProfile } from '@/types/user'

export const listOrganizations = (): Promise<IOrganizationItem[]> => {
  return API.get(LIST_ORGANIZATIONS_PATH)
}

export const createOrganization = (data: IOrganizationCreateData) => {
  const formData = new FormData()
  Object.keys(data).forEach((key: string) => {
    const value = data[key as keyof IOrganizationCreateData]
    if (value) {
      formData.append(key, value as string | File)
    }
  })

  return API.postWithFiles(LIST_ORGANIZATIONS_PATH, formData)
}

export const getAssignees = (): Promise<IUserProfile[]> => {
  return API.get(POST_ASSIGNEES_PATH)
}

export const getOrganizationSettings = () => {
  return API.get(ORGANIZATION_SETTINGS_PATH)
}
export const updateOrganizationSettings = (data: IKeyValueMap) => {
  return API.put(ORGANIZATION_SETTINGS_PATH, data)
}
export const updateOrganizationCustomization = (
  data: IKeyValueMap | FormData
) => {
  if (data instanceof FormData) {
    return API.putWithFiles(ORGANIZATION_CUSTOMIZATION_PATH, data)
  }
  return API.put(ORGANIZATION_CUSTOMIZATION_PATH, data)
}
export const updateOrganizationInfo = (data: IKeyValueMap | FormData) => {
  if (data instanceof FormData) {
    return API.putWithFiles(ORGANIZATION_INFO_PATH, data)
  }
  return API.put(ORGANIZATION_INFO_PATH, data)
}

export const getRecentActivities = (
  params: IActivitiesListAPIParams
): Promise<IActivityItem[]> => {
  return API.get(RECENT_ACTIVITIES_PATH, params).then((data) => {
    return Object.keys(data)
      .map((key) => data[key])
      .flat()
      .map(transformActivity)
  })
}

export const getOnboardingSteps = () => API.get(GET_ONBOARDING_STEPS_PATH)
export const skipOnboardingSteps = () => API.post(SKIP_ONBOARDING_STEPS_PATH)

export const removeFavicon = () => API.post(REMOVE_FAVICON_PATH)
export const removeAvatar = () => API.post(REMOVE_LOGO_PATH)
export const removeOGImage = () => API.post(REMOVE_OG_IMAGE_PATH)

export const generateAPIKey = () => API.post(GENERATE_API_KEY_PATH)
export const generateSSOKey = () => API.post(GENERATE_SSO_KEY_PATH)
export const generateLetterBoxMail = () => API.post(GENERATE_LETTERBOX_MAIL)

export const checkOrganizationSlug = (
  data: any
): Promise<{ domain: string }> => {
  return API.get(ORGANIZATION_VALIDATION_PATH, data)
}
export const getOrganizationDeletionInfo = () =>
  API.get(ORGANIZATION_DELETION_INFO)

export const deleteOrganization = (data: { status: string }) =>
  API.post(ORGANIZATION_DELETION_REQUESTS, data)
export const getDeleteOrganizationRequest = (id: string) =>
  API.get(ORGANIZATION_DELETION_REQUEST(id))
export const cancelDeleteOrganization = (
  id: string,
  data: { status: string }
) => API.patch(ORGANIZATION_DELETION_REQUEST(id), data)

export const listActivities = (data: {
  id?: string | number
  resource?: IResourceTypes
  page?: number
}) => {
  return API.get(RESOURCE_ACTIVITIES_PATH, {
    resource_type: data.resource,
    resource_id: data.id,
    page: data.page || 1,
  }).then((activities: IActivityItem[]) => {
    return activities.map(transformActivity)
  })
}
export const updateCustomDomain = (data: { custom_domain: string }) =>
  API.put(ORGANIZATION_INFO_PATH, data)
export const verifyCustomDomain = () =>
  API.post(CUSTOM_DOMAIN_VERIFICATION_PATH)

export const sendEmail = (
  emailType: string,
  emailAddresses: string[],
  headers: IRequestHeaders = {}
) => {
  return API.post(
    EMAILS_PATH,
    {
      type: emailType,
      emails: emailAddresses,
    },
    { headers }
  )
}

export const getOrganizationUsage = () => API.get(ORGANIZATION_USAGE_PATH)

export const updateAnnouncementData = (data: { title: string[] }) => {
  return API.post(ORGANIZATION_ANNOUNCEMENT_PATH, data)
}

export const listKeywords = (params: any) => API.get(LIST_KEYWORDS_PATH, params)

export const getSMTPSettings = () => {
  return API.get(SMTP_CREATION_PATH)
}
export const createSMTPSettings = (data: any) => {
  return API.post(SMTP_CREATION_PATH, data)
}

export const updateSMTPSettings = (data: any) => {
  return API.put(SMTP_CREATION_PATH, data)
}

export const deleteSMTPSettings = () => {
  return API.delete(SMTP_CREATION_PATH)
}
export const testSMTPSettings = (data: any) => {
  return API.post(SMTP_TEST_PATH, data)
  // eslint-disable-next-line prettier/prettier
}

interface IViewData {
  path: string
  referrer: string
  resource_slug: string
  resource_model: string
  language: string
  timezone: string | number
}

export const recordView = (data: IViewData) => {
  return API.post(PAGE_VIEW_PATH, data)
}

export const startModuleTrial = (
  data: { module: string },
  headers?: IRequestHeaders
) =>
  API.post(
    TRAIL_MODULE_PATH,
    data,
    headers
      ? {
          headers,
        }
      : {}
  )

export const updateEnterpriseCustomization = (data: IKeyValueMap) =>
  API.put(ENTERPRISE_CUSTOMIZATION_PATH, data)
