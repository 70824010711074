import { ArrowRight, Lightbulb } from '@phosphor-icons/react'
import Link from 'next/link'
import React from 'react'

import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import { Pill } from '@/components/shared/ui/Pill/Pill'
import { useTranslations } from '@/hooks/useTranslations'
import { userSidePostSinglePath } from '@/lib/helpers/pathHelpers'
import type { IPost } from '@/types/post'

interface NewPostSuggestionsDialogProps {
  suggestions?: IPost[]
}

export default function NewPostSuggestionsDialog({
  suggestions,
}: NewPostSuggestionsDialogProps) {
  const t = useTranslations('posts.newPost.labels')
  if (!suggestions || suggestions.length === 0) return null
  return (
    <Dialog>
      <DialogTrigger>
        <Pill className='text-sm' variant={'blue'}>
          <div className='flex items-center gap-1'>
            <Lightbulb className='h-4 w-4' />
            <span>
              {t('similarPosts.cta', {
                data: {
                  count: suggestions.length,
                },
              })}
            </span>
          </div>
        </Pill>
      </DialogTrigger>
      <DialogContent className='max-h-[80vh] !px-0' size='md'>
        <DialogClose />
        <div className='flex flex-col gap-1 border-b border-gray7 px-2 pb-2'>
          <DialogTitle>{t('similarPosts.title')}</DialogTitle>
          <DialogDescription>{t('similarPosts.description')}</DialogDescription>
        </div>
        <div className='flex flex-col gap-2 overflow-y-auto px-2 py-2'>
          {suggestions.map((suggestion: IPost) => (
            <Link
              href={userSidePostSinglePath(suggestion.slug)}
              key={suggestion.id}
              className='group flex items-center justify-between gap-2 rounded-md border border-transparent px-2 py-2 hover:border-gray7 hover:bg-gray3'
            >
              <div className='flex flex-col gap-1'>
                <p className='truncate text-sm'>{suggestion.title}</p>
                {!!suggestion.description && (
                  <p className='line-clamp-2 text-sm text-gray11'>
                    {suggestion.description}
                  </p>
                )}
              </div>
              <div className='h-4 w-4'>
                <ArrowRight className='hidden h-4 w-4 text-gray11 group-hover:block' />
              </div>
            </Link>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}
