import { ArrowLeft, Plus, X } from '@phosphor-icons/react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useContext, useMemo } from 'react'

import HNContext from '../../../context/HNContext'
import { useTranslations } from '../../../hooks/useTranslations'
import PageBasedView from '../../shared/components/PageBasedView'
import Avatar from '../../shared/ui/Avatar'
import Button from '../../shared/ui/Button'
import UnstyledLink from '../../shared/ui/Links/UnstyledLink'
import Typography from '../../shared/ui/Typography'

const NewPostButton = dynamic(
  () => import('@/components/posts/newPost/NewPostButton'),
  { ssr: false }
)

export default function WidgetHeader() {
  const router = useRouter()
  const t = useTranslations('')

  const { organization, buckets } = useContext(HNContext)

  const activeBoard = useMemo(() => {
    const { bucket_id, boardSlug } = router.query
    let board
    if (boardSlug) board = buckets?.find((bucket) => bucket.name === boardSlug)
    if (bucket_id)
      board = buckets?.find((bucket) => bucket?.id?.toString() === bucket_id)
    return board
  }, [buckets, router.query.boardSlug, router.query.bucket_id])

  const handleClose = () => {
    window.parent.postMessage(
      JSON.stringify({ action: 'close', payload: router.query?.iframe_id }),
      '*'
    )
    window.parent.postMessage(
      JSON.stringify({ action: 'indicate', payload: false }),
      '*'
    )
  }

  return (
    <PageBasedView>
      <div data-path={['/widget/post']}>
        <div className='flex items-center justify-between p-2'>
          <div className='flex items-center space-x-2'>
            <Avatar
              url={organization?.avatar?.thumb_url || undefined}
              name={organization?.name}
              rounded
              size='xs'
            />
            <Typography.Text className='text-xs'>
              {organization?.name}
            </Typography.Text>
          </div>
          <NewPostButton isWidget boardId={activeBoard?.id || undefined}>
            <Button as='span' fab icon={<Plus />} />
          </NewPostButton>
        </div>
      </div>
      <div
        data-path={[
          `/widget/post/p/${router.query.postSlug}`,
          `/widget/changelog/${router.query.changelogSlug}`,
        ]}
        className='p-2'
      >
        <Button
          fab
          icon={<ArrowLeft />}
          variant='secondary'
          onClick={() => router.back()}
        />
      </div>
      <div data-path={['/widget/changelog']}>
        <div className='flex items-center justify-between p-2'>
          <UnstyledLink
            target='_blank'
            href={`${organization?.home_page}/changelog`}
          >
            <Typography.Text>{t('changelog.widget.viewAll')}</Typography.Text>
          </UnstyledLink>
          <Button icon={<X />} variant='secondary' onClick={handleClose} />
        </div>
      </div>
    </PageBasedView>
  )
}
