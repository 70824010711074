import type { ReactNode } from 'react'
import React from 'react'

interface IPropTypes {
  children: ReactNode
}

export default function AuthSideLayout(props: IPropTypes) {
  return <>{props.children}</>
}
