import { CaretDown } from '@phosphor-icons/react'
import React, { useContext } from 'react'

import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import HNContext from '@/context/HNContext'
import type { ICustomOrganizationPreferences } from '@/types/organization'

export default function CustomHeaderCustomLinks() {
  const { organizationCustomization } = useContext(HNContext)
  const { home_page_config } = organizationCustomization || {}
  const { customLinks = [] } =
    home_page_config as ICustomOrganizationPreferences

  return (
    <>
      {customLinks.map((customLink) =>
        customLink.subLinks ? (
          <Dropdown className='z-20' modal={false}>
            <DropdownTrigger asChild={false}>
              <div
                className={'flex items-center gap-1'}
                data-testid='user_side_header_boards_tab'
              >
                <p className={'!text-primary'}>{customLink.title}</p>
                <CaretDown />
              </div>
            </DropdownTrigger>
            <DropdownContent
              data-testid='user_side_header_boards_dropdown'
              align='center'
              className='z-20'
            >
              {customLink.subLinks.map((subLink) => (
                <DropdownItem
                  key={subLink.link}
                  dataTestId='user_side_header_all_boards_dropdown_item'
                  textClassName='!text-primary'
                  href={subLink.link}
                  target='_blank'
                  itemValue={subLink.title}
                />
              ))}
            </DropdownContent>
          </Dropdown>
        ) : (
          <UnstyledLink
            href={customLink.link}
            className=''
            target='_blank'
            key={customLink.link}
          >
            {customLink.title}
          </UnstyledLink>
        )
      )}
    </>
  )
}
