import '@/lib/polyfills'
import '../styles/global.scss'

import { ErrorBoundary } from '@sentry/react'
import { de, enUS, es, fr, ja, ko, pt, ru } from 'date-fns/locale'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'

import AdminSinglePostWrapper from '@/components/admin/posts/AdminSinglePostWrapper'
import SingleCustomRoadmapItemWrapper from '@/components/roadmap/kanban/SingleCustomRoadmapItemWrapper'
import CommandPalette from '@/components/shared/common/CommandPalette'
import ImagePreviewer from '@/components/shared/common/ImagePreviewer'
import NewDeploymentAlert from '@/components/shared/common/NewDeploymentAlert'
import ErrorPage from '@/components/shared/components/ErrorPage'
import KeyboardShortcutsEvents from '@/components/shared/components/KeyboardShortcutsEvents'
import RealTimeUpdate from '@/components/shared/components/RealtimeUpdate'
import UpgradePlanModal from '@/components/shared/components/UpgradePlanModal'
import { PerformanceHUDProvider } from '@/components/shared/dev/PerformanceHUDProvider'
import { HN_SESSION_KEY, LANGUAGES } from '@/config/appConstants'
import { ENV } from '@/config/environment'
import { searchClient } from '@/config/search'
import type { IHNContext } from '@/context/HNContext'
import HNContext from '@/context/HNContext'
import { HNSearchContext } from '@/context/HNSearchContext'
import HNTranslationContext from '@/context/HNTranslationContext'
import AdminSideLayout from '@/layouts/AdminSideLayout'
import AuthSideLayout from '@/layouts/AuthSideLayout'
import EmbedLayout from '@/layouts/EmbedLayout'
import ErrorLayout from '@/layouts/ErrorLayout'
import GuestLayout from '@/layouts/GuestLayout'
import { Meta } from '@/layouts/Meta'
import PreProcessWrapper from '@/layouts/PreProcessWrapper'
import UILibraryLayout from '@/layouts/UILibraryLayout'
import UserProfileLayout from '@/layouts/UserProfileLayout'
import UserSideLayout from '@/layouts/UserSideLayout'
import { serializeCookie } from '@/lib/Cookie'
import {
  getEmbedsContextData,
  getHostFromRequest,
  getSubdomain,
  isHostAndRootDataSame,
  sentry,
} from '@/lib/helpers/appHelpers'
import { removeKeysFromUrlString } from '@/lib/helpers/dataHelpers'
import { setDateOptions } from '@/lib/helpers/dateHelpers'
import { withSessionInServer } from '@/lib/helpers/nextHelpers'
import { buildURL, encodeURL } from '@/lib/helpers/urlHelpers'
import { UserRedirect } from '@/lib/userRedirect'
import {
  bootstrapData,
  getMetaDataParams,
  getRootData,
  getTranslation,
} from '@/models/Auth'
import type { IMixedContext, IPageError } from '@/types/app'
import type { IRequestHeaders } from '@/types/common'
import type { IRootData } from '@/types/organization'
import { AppConfig } from '@/utils/AppConfig'
import toaster from '@/utils/toast'

import PlanRestrictionsDialog from '../components/shared/components/PlanRestrictionsDialog'
import WidgetLayout from '../layouts/WidgetLayout'

const CookieBanner = dynamic(
  () => import('@/components/users/shared/CookieBanner'),
  { ssr: false }
)
const ToastContainer = dynamic(
  () => import('@/components/shared/ui/Toast/ToastContainer'),
  {
    ssr: false,
  }
)

const dateLocale = {
  en: enUS,
  fr,
  es,
  de,
  ru,
  pt,
  ja,
  ko,
}
export interface IAppPros extends AppProps {
  props: {
    subdomain: string
    rootData: IRootData
    translations: any
    session: string
    host: string
    error: IPageError
    widgetKey?: string
    country: string
    isError: boolean
    isFromOldApp: boolean
  }
}

const Huracan = ({ Component, pageProps, props }: IAppPros) => {
  const router = useRouter()
  const embedsContextData = getEmbedsContextData({
    pathname: router.pathname,
    asPath: router.asPath,
    query: router.query,
  })
  const subdomain = useRef(props.subdomain)
  const [initialData, setInitialData] = useState<IHNContext>({
    ...props.rootData,
    session: props.session,
    widgetKey: props.widgetKey,
    ...embedsContextData,
    country: props.country || 'US',
    subdomain: props.subdomain,
  })
  const translations = useRef(props.translations)
  const isWidget =
    router.pathname.startsWith('/widget') ||
    router.pathname.includes('/submission_form_with_key')
  const isAdmin = router.pathname.includes('/admin')

  const srchClient = useMemo(
    () => searchClient(initialData.search_auth_token),
    [initialData.search_auth_token]
  )

  const Layout = useMemo(() => {
    const isAuth =
      router.pathname.includes('/auth') ||
      router.pathname.includes('/mauth') ||
      router.pathname.includes('/user') ||
      router.pathname.includes('/users') ||
      router.pathname.includes('/tp') ||
      router.pathname === '/new' ||
      subdomain.current === 'portal'
    const isUILibrary = router.pathname.includes('/ui-library')
    const isProfilePages = router.pathname.includes('/profile')
    const isEmbedPage = router.pathname.includes('/embed')
    const isErrorPage =
      router.pathname.includes('/404') ||
      router.pathname.includes('/500') ||
      router.pathname.includes('/403')
    const isRedirectingPage = router.pathname.includes('/user/redirecting')
    const isPurchaseSuccessPage = router.pathname.includes('/purchase/validate')
    const isReviewVoteOnBehalf = router.pathname.includes(
      '/review_vote_on_behalf/[voteKey]'
    )
    if (isWidget) return WidgetLayout
    if (isAdmin) return AdminSideLayout
    if (isRedirectingPage || isPurchaseSuccessPage) return AuthSideLayout
    if (isAuth) return AuthSideLayout
    if (isUILibrary) return UILibraryLayout
    if (isProfilePages) return UserProfileLayout
    if (isEmbedPage) return EmbedLayout
    if (isErrorPage) return ErrorLayout
    if (isReviewVoteOnBehalf) return GuestLayout
    return UserSideLayout
  }, [router.pathname])

  useEffect(() => {
    try {
      if (
        initialData &&
        initialData?.userSignedIn &&
        initialData?.organization
      ) {
        sentry.addInfo({
          userProfile: initialData?.userProfile,
          organization: initialData?.organization,
          organizationSetting: initialData?.organizationSetting,
        })
      }
      const lng = initialData.organizationSetting?.locale
      // @ts-ignore
      const dateLocal = dateLocale[lng]
      if (dateLocal) {
        setDateOptions({ locale: dateLocal })
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    if (subdomain.current === 'portal') return
    if (!initialData.userProfile) return
    bootstrapData(isAdmin)
      .then((data) =>
        setInitialData((_oldData: IHNContext) => ({
          ..._oldData,
          ...data,
          userProfile: _oldData.userProfile
            ? {
                ..._oldData.userProfile,
                ...data.userProfile,
              }
            : data.userProfile,
          bootstraped: true,
        }))
      )
      .catch(toaster.error)
  }, [])

  const handleUpdateContext = (data: any) => {
    setInitialData((_oldData: IHNContext) => ({ ..._oldData, ...data }))
  }

  if (props.isError)
    return (
      <HNTranslationContext.Provider
        value={{
          ...translations.current,
          updateTranslationContext: () => {},
        }}
      >
        <Component />
      </HNTranslationContext.Provider>
    )
  if (props.error)
    return (
      <HNTranslationContext.Provider
        value={{
          ...translations.current,
          updateTranslationContext: () => {},
        }}
      >
        <ErrorPage error={props.error} />
      </HNTranslationContext.Provider>
    )

  return (
    <>
      <style jsx global>{`
        :root {
          font-feature-settings: 'kern' 1, 'liga' 1, 'calt' 1;
        }
      `}</style>
      <HNContext.Provider
        value={{
          ...initialData,
          updateContext: handleUpdateContext,
          isWidget,
          isAdmin,
        }}
      >
        <PreProcessWrapper
          session={initialData.session}
          translations={translations.current}
          widgetKey={initialData.widgetKey}
          subdomain={subdomain.current}
        >
          <HNSearchContext.Provider value={{ searchClient: srchClient }}>
            <ErrorBoundary
              fallback={
                <ErrorPage
                  error={{
                    status: '500',
                    message: 'Oops! Something went wrong.',
                    ctaText: 'Refresh',
                  }}
                />
              }
            >
              <PerformanceHUDProvider
                initialVisible={
                  process.env.NODE_ENV === 'development' &&
                  Boolean(process.env.ENABLE_PERFORMANCE_HUD)
                }
                initialPosition={{ x: 20, y: 60 }}
                initialOpacity={0.9}
              >
                <NewDeploymentAlert />
                <Meta
                  {...initialData}
                  title={initialData.meta?.title}
                  description={initialData.meta?.description}
                  favicon={initialData.meta?.favicon}
                  image={
                    initialData.meta?.resource_og_image ||
                    initialData.meta?.image
                  }
                  orgName={
                    initialData?.organization?.name || AppConfig.site_name
                  }
                />
                <Layout>
                  <Component {...pageProps} />
                  <AdminSinglePostWrapper />
                  <SingleCustomRoadmapItemWrapper />
                  <UpgradePlanModal />
                  <CommandPalette />
                </Layout>
                <ToastContainer />
                <ImagePreviewer />
                <ErrorBoundary fallback={<></>}>
                  <RealTimeUpdate />
                </ErrorBoundary>
                <KeyboardShortcutsEvents />
                <CookieBanner />
                {!isWidget && (
                  <>
                    <PlanRestrictionsDialog />
                  </>
                )}
              </PerformanceHUDProvider>
            </ErrorBoundary>
          </HNSearchContext.Provider>
        </PreProcessWrapper>
      </HNContext.Provider>
    </>
  )
}

Huracan.getInitialProps = withSessionInServer(
  async (
    ctx: IMixedContext,
    headers: IRequestHeaders = {},
    session: string | null = null
  ) => {
    const { req, res } = ctx.ctx
    const isServer = !!req
    const isDataReq = ctx.ctx.req?.url?.startsWith('/_next/data')

    if (isServer && !isDataReq) {
      const country = req.headers['x-vercel-ip-country'] || 'US'
      const host = getHostFromRequest(req)
      const { subdomain } = getSubdomain(String(host))

      const { query, pathname, asPath } = ctx.ctx
      const { lang } = query as { lang: string }
      let translations: any = {}

      let forcedLang: string | undefined = lang
      if (lang && lang === 'browser') {
        const browserLanguage = req?.headers['accept-language']?.split(',')[0]
        const supportedBrowserLanguage = LANGUAGES.find(
          (lng) =>
            lng.value === browserLanguage ||
            lng.otherValue?.includes(browserLanguage || '')
        )?.value
        forcedLang = supportedBrowserLanguage
      }

      if (pathname === '/404' || pathname === '/500') {
        return { props: { isError: true } }
      }
      const isAdmin = pathname.includes('/admin/')
      // return empty props for rss
      if (pathname.startsWith('/rss')) {
        return {
          props: {},
        }
      }

      const DO_NOT_REDIRECT_TOKEN_PATHS = ['/user/confirm_email']
      const skipTokenCheck = DO_NOT_REDIRECT_TOKEN_PATHS.some((path) =>
        pathname.startsWith(path)
      )

      if (session && (query.token || query.sso_token) && !skipTokenCheck) {
        res?.setHeader(
          'Set-Cookie',
          serializeCookie(HN_SESSION_KEY, session, req, {})
        )
        const protocol =
          process.env.NODE_ENV === 'production' ? 'https' : 'http'
        const url = buildURL(`${protocol}://${host}${asPath || '/'}`, {})
        const cleanedUrl = removeKeysFromUrlString(url || '', [
          'sso_token',
          'token',
        ]) as string
        res?.writeHead(302, {
          Location: cleanedUrl,
        })
        res?.end()
        return {}
      }

      try {
        const metaDataParam = await getMetaDataParams(query, pathname)

        const rootData: IRootData = await getRootData(
          isAdmin,
          {
            otl_key: query?.boardInviteKey || undefined,
            ...metaDataParam,
          },
          headers
        )

        try {
          if (
            ENV.NODE_ENV === 'production' &&
            !isHostAndRootDataSame(host, rootData)
          ) {
            sentry.exception(new Error('Host and root data do not match'))
          }
        } catch (err: any) {
          console.warn(err)
          sentry.exception(new Error(err.message))
        }

        req.headers.defaultModule = rootData?.default_module

        translations = await getTranslation(
          pathname,
          forcedLang ||
            (rootData.organization ? rootData.organizationSetting.locale : 'en')
        )

        if (pathname !== '/_error') {
          const redirectCheck = new UserRedirect({
            pathname,
            rootData,
            subdomain,
            fullPath: asPath || '/',
            currentHost: String(host),
            query,
            headers,
          })

          const redirectURL = (await redirectCheck.redirectCheck()) as string

          if (redirectURL) {
            res?.writeHead(302, {
              Location: redirectURL,
            })
            res?.end()
            return {}
          }
        }

        if (rootData.organization) {
          const embedOriginSettings =
            rootData.organizationCustomization.embed_origin_settings
          if (embedOriginSettings) {
            if (embedOriginSettings.enabled) {
              const embedOrigins = embedOriginSettings.domains.join(' ')
              res?.setHeader(
                'Content-Security-Policy',
                `frame-ancestors ${embedOrigins}`
              )
            }
          }
        }

        return {
          props: {
            subdomain,
            rootData,
            translations,
            host,
            country,
          },
        }
      } catch (err: any) {
        if (Object.keys(translations).length === 0) {
          translations = await getTranslation(pathname, forcedLang || 'en')
        }
        if (err && err.status && err.status === 401) {
          res?.setHeader(
            'Set-Cookie',
            serializeCookie(HN_SESSION_KEY, '', req, {})
          )
          res?.writeHead(302, {
            Location: buildURL('/auth/signin', {
              redirectURL: encodeURL(asPath || '/'),
            }),
          })
          res?.end()
          return {}
        }
        if (err && err.status && err.status === 404) {
          return {
            props: {
              error: { message: err.message, status: 404 },
            },
          }
        }
        console.warn(err)
        sentry.exception(new Error(err.message))
        return {
          props: {
            translations,
            error: {
              message: err.message,
              status: err.status || 500,
            },
          },
        }
      }
    }
    return { props: { isServer } }
  }
)

export default Huracan
