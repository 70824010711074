import { useRouter } from 'next/router'
import React, { useCallback, useEffect, useState } from 'react'

import AdminIntegrationSlackAdditionalConfig from '@/components/admin/settings/Integrations/config/Slack/AdminIntegrationSlackAdditionalConfig'
import Alert from '@/components/shared/ui/Alert'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import Spinner from '@/components/shared/ui/Loader'
import { useTranslations } from '@/hooks/useTranslations'
import { getIntegrationConfig } from '@/models/Integration'
import type {
  ISlackConfigurationProps,
  ISlackIntegrationConfig,
} from '@/types/integration/slack'
import toaster from '@/utils/toast'

import AdminIntegrationSlackBoards from './Slack/AdminIntegrationSlackBoards'
import AdminIntegrationSlackEvents from './Slack/AdminIntegrationSlackEvents'

export default function SlackConfiguration({
  integrationMapping,
  onDisconnect,
}: ISlackConfigurationProps) {
  const t = useTranslations(`integrations.slack`)
  const router = useRouter()
  const { provider, integrated } = router.query
  const [showConfig, setShowConfig] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [configData, setConfigData] = useState<ISlackIntegrationConfig>()

  const fetchConfig = () => {
    if (!configData) {
      setIsLoading(true)
    } else {
      setIsSyncing(true)
    }
    getIntegrationConfig('slack')
      .then((data) => {
        if (configData) {
          setConfigData({ ...configData, ...data })
        } else {
          setConfigData(data)
        }
      })
      .catch((err) => toaster.error(err.message))
      .finally(() => {
        setIsLoading(false)
        setIsSyncing(false)
      })
  }

  const updateConfigData = (data: ISlackIntegrationConfig) => {
    setConfigData(data)
  }

  useEffect(() => {
    fetchConfig()
  }, [])

  useEffect(() => {
    if (showConfig) fetchConfig()
  }, [showConfig])

  useEffect(() => {
    if (provider === 'slack' && integrated === 'true') {
      setShowConfig(true)
    }
  }, [])

  const renderLoadingOrEmpty = useCallback(() => {
    if (isLoading) return <Spinner />

    if (!configData || !integrationMapping)
      return (
        <EmptyState
          title={t('messages.something_went_wrong', { ignorePrefix: true })}
        />
      )

    return null
  }, [isLoading, configData, integrationMapping])

  return (
    <Dialog open={showConfig} onClose={() => setShowConfig(false)}>
      <DialogTrigger asChild>
        <Button size='xs' onClick={() => setShowConfig(true)}>
          {t('buttons.configure', { ignorePrefix: true })}
        </Button>
      </DialogTrigger>
      <DialogContent mode='drawer'>
        <div className='flex w-full flex-col justify-between'>
          <div className='flex items-center justify-start'>
            <DialogTitle className='!m-0'>{t(`configure.title`)}</DialogTitle>
            {isSyncing && <SpinnerIcon className='h-4 w-4 animate-spin' />}
          </div>
          <DialogDescription className='!mb-0'>
            {t(`configure.description`)}
          </DialogDescription>
        </div>
        {configData?.team_name && (
          <div className='flex w-full items-center justify-start py-2'>
            <Alert
              type='info'
              className='w-full'
              rounded
              message={
                <p className='text-inherit'>
                  Connected to <b>{configData.channel_name}</b> channel in{' '}
                  <b>{configData.team_name}</b> workspace
                </p>
              }
              icon={<></>}
            />
          </div>
        )}

        {isLoading || !configData || !integrationMapping ? (
          renderLoadingOrEmpty()
        ) : (
          <>
            <AdminIntegrationSlackEvents
              configData={configData}
              integrationMapping={integrationMapping}
              onChange={updateConfigData}
            />
            <AdminIntegrationSlackBoards
              configData={configData}
              onChange={updateConfigData}
            />
            <AdminIntegrationSlackAdditionalConfig
              configData={configData}
              integrationMapping={integrationMapping}
              onChange={updateConfigData}
            />
            <AlertDialog
              type='danger'
              confirmText={t(`buttons.disconnect`, {
                ignorePrefix: true,
              })}
              title={t(`disconnectConfirmationTitle`)}
              description={t(`disconnectConfirmationMessage`)}
              onConfirm={onDisconnect}
            >
              <Button variant='danger' size='xs'>
                {t(`buttons.disconnect`, {
                  ignorePrefix: true,
                })}
              </Button>
            </AlertDialog>
          </>
        )}
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
