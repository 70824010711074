import { CaretDown } from '@phosphor-icons/react'
import * as Accordion from '@radix-ui/react-accordion'
import clsx from 'clsx'
import React, { useRef } from 'react'

import HelpIcon from '@/components/shared/components/HelpIcon'
import Button from '@/components/shared/ui/Button'
import { getLocalStorage } from '@/lib/localDB/localStorage'

interface CollapsibleCardProps {
  children: React.ReactNode
  title: string
  icon?: React.ReactNode
  actions?: React.ReactNode
  collapsible?: boolean
  infoText?: string
  learnMoreText?: string
  learnMoreLink?: string
  collapseLocalKey?: string
  defaultCollapsed?: boolean
  classNames?: {
    root?: string
    header?: string
    content?: string
    item?: string
  }
  forceMount?: boolean
}

const getInitialCollapsed = (
  defaultCollapsed: boolean | undefined,
  collapseLocalKey?: string | undefined
): boolean => {
  if (defaultCollapsed !== undefined) return defaultCollapsed

  const localValue = collapseLocalKey ? getLocalStorage(collapseLocalKey) : {}
  if (!localValue) return defaultCollapsed ?? false
  return !!localValue?.isCollapsed
}

export default function CollapsibleCard({
  children,
  title,
  icon,
  actions,
  collapsible = true,
  infoText,
  learnMoreText,
  learnMoreLink,
  collapseLocalKey,
  defaultCollapsed,
  classNames = {},
  forceMount = false,
}: CollapsibleCardProps) {
  const randomKey = useRef(Math.random().toString(36).substring(2, 15))
  const initialCollapsed = getInitialCollapsed(
    defaultCollapsed,
    collapseLocalKey
  )

  return (
    <Accordion.Root
      type='single'
      collapsible
      defaultValue={initialCollapsed ? undefined : randomKey.current}
      className={classNames?.root}
    >
      <Accordion.Item
        value={randomKey.current}
        className={
          clsx('rounded-md border border-gray5', classNames?.item) as string
        }
      >
        <Accordion.Header asChild>
          <div className='flex items-center justify-between gap-x-1 px-3.5 py-2'>
            <div className='flex items-center gap-x-1'>
              {icon}
              <div className='text-sm font-semibold text-gray12/80'>
                {title}
              </div>
              {infoText && (
                <HelpIcon
                  text={infoText}
                  learnMoreText={learnMoreText}
                  href={learnMoreLink}
                />
              )}
            </div>
            <div className='flex items-center gap-x-1 '>
              {actions}
              {collapsible && (
                <Accordion.Trigger>
                  <Button as='span' icon={<CaretDown />} fab variant='naked' />
                </Accordion.Trigger>
              )}
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Content
          forceMount={forceMount === true ? true : undefined}
          className={clsx(
            'data-[state=closed]:animate-height-up data-[state=open]:animate-height-down',
            classNames?.content
          )}
        >
          <div className='flex flex-col gap-4 border-t border-gray5 p-4 transition duration-200'>
            {children}
          </div>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}
