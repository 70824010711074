import React from 'react'

import Badge from '@/components/shared/ui/Badge'
import Tooltip from '@/components/shared/ui/Tooltip'
import type { ICommentReaction } from '@/types/comment'

interface IPropTypes {
  reaction: ICommentReaction
  onReaction: (reactionId: string) => void
}
export default function CommentReactionItem({
  reaction,
  onReaction,
}: IPropTypes) {
  return (
    <Tooltip
      show={reaction.show_tooltip}
      text={reaction?.reacted_users}
      key={reaction.id}
    >
      <Badge
        icon={reaction.emoji}
        value={reaction.count ? `${reaction.count}` : undefined}
        onClick={() => onReaction(reaction.id)}
        variant='blue'
        rounded
      />
    </Tooltip>
  )
}
