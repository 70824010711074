import { GearSix } from '@phosphor-icons/react'
import clsx from 'clsx'
import dynamic from 'next/dynamic'
import React, { useContext } from 'react'

import UserAvatar from '@/components/shared/components/UserAvatar'
import {
  OrgEmbedNoShowWrapper,
  OrgEmbedShowWrapper,
} from '@/components/shared/components/wrapper/EmbedWrapper'
import Button from '@/components/shared/ui/Button'
import type { IButtonVariant } from '@/components/shared/ui/Button/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'

import UserProfileDropdown from './UserProfileDropdown'

const AuthModal = dynamic(() => import('@/components/auth/shared/AuthModal'))

interface IPropTypes {
  buttonVariant?: IButtonVariant
}

export default function UserSideUserAuthButton({ buttonVariant }: IPropTypes) {
  const { userSignedIn, userProfile, noHeader } = useContext(HNContext)
  const t = useTranslations()

  if (noHeader) return <></>
  if (userSignedIn) {
    return (
      <>
        <OrgEmbedNoShowWrapper>
          <div className='flex items-center space-x-3'>
            {userProfile?.is_csm_of_organization && (
              <Button
                as='a'
                href='/admin'
                size='xs'
                variant='outline'
                icon={<GearSix className='h-4 w-4' weight='fill' />}
                className=''
              >
                {t('buttons.dashboard')}
              </Button>
            )}
            <UserProfileDropdown />
          </div>
        </OrgEmbedNoShowWrapper>
        <OrgEmbedShowWrapper>
          {userProfile && <UserAvatar rounded user={userProfile} />}
        </OrgEmbedShowWrapper>
      </>
    )
  }

  return (
    <div
      className={clsx(
        'flex shrink-0 items-center justify-end space-x-2 sm:ml-4'
      )}
    >
      <AuthModal>
        <Button size='xs' variant={buttonVariant || 'outline'}>
          {t('auth.labels.signIn')}
        </Button>
      </AuthModal>
      <AuthModal hideWhenSingleAuth disableShortcut>
        <Button size='xs' variant={buttonVariant || 'primary'}>
          {t('auth.labels.signUp')}
        </Button>
      </AuthModal>
    </div>
  )
}
