import React, { useMemo, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import Alert from '@/components/shared/ui/Alert/Alert'
import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { stateKeyValueChange } from '@/lib/helpers/appHelpers'
import { resendConfirmationEmail } from '@/models/Auth'
import type { IResendConfirmationEmailData } from '@/types/auth'

interface IPropTypes {
  onNavigation: (page: string) => void
}
export default function ResendConfirmation({ onNavigation }: IPropTypes) {
  const t = useTranslations(`auth`)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [resendConfirmationData, setResendConfirmationData] =
    useState<IResendConfirmationEmailData>({
      email: ``,
    })

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    const { email } = resendConfirmationData
    if (!email?.length) {
      return setErrorMessage('Please enter email')
    }
    setLoading(true)
    return resendConfirmationEmail(resendConfirmationData)
      .then(() => onNavigation('home'))
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setLoading(false))
  }
  const validate = useMemo(() => {
    const { email } = resendConfirmationData
    if (!email?.length) {
      return false
    }

    return true
  }, [resendConfirmationData])

  return (
    <>
      <Typography.Text>{t(`accountConfirmation.description`)}</Typography.Text>
      <form onSubmit={handleSubmit} className='mx-auto mt-2 space-y-2'>
        {errorMessage && <Alert message={errorMessage} type={'error'} />}
        <Input
          type='email'
          placeholder={t(`forgotPassword.emailPlaceholder`)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            stateKeyValueChange(
              'email',
              e.target.value,
              setResendConfirmationData
            )
          }
        />
        <div className='-ml-1'>
          <ReCAPTCHA
            data-testid='forgot_password_captcha'
            id='auth-recaptcha'
            sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY || ''}
            onChange={(value: string | null) =>
              stateKeyValueChange('captcha', value, setResendConfirmationData)
            }
          />
        </div>
        <Button
          className='float-right'
          loading={loading}
          disabled={loading || !validate}
        >
          {t(`resendConfrimation.buttonText`)}
        </Button>
      </form>
    </>
  )
}
