import { Folder, List } from '@phosphor-icons/react'
import { ChevronDownIcon } from '@primer/octicons-react'
import clsx from 'clsx'
import React, { useMemo, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/ui/Popover/Popover'
import type { IClickUpConfig } from '@/types/integration/click_up'

interface IAdminIntegrationClickupListSelectorProps {
  config: IClickUpConfig
  value: { label: string; value: string }[]
  onChange: (value: { label: string; value: string }[]) => void
}

const IconMap = {
  space: List,
  folder: Folder,
  list: List,
}

const getChildren: any = (node: any, key: string, items: any[]) => {
  return items
    .filter((item) => item[key] === node.id)
    .map((item: any) => ({
      id: item.id,
      name: item.name,
      type: 'list',
      children:
        key === 'list_id'
          ? getChildren(item, 'list_id', items)
          : getChildren(item, 'folder_id', items),
    }))
}

export default function AdminIntegrationClickupListSelector({
  config,
  value,
  onChange,
}: IAdminIntegrationClickupListSelectorProps) {
  const [open, setOpen] = useState(false)
  const { lists, folders, spaces } = config

  const treeNodes = useMemo(() => {
    return spaces.map((space) => {
      return {
        id: space.id,
        name: space.name,
        type: 'space',
        children: [
          ...folders
            .filter((folder) => folder.space_id === space.id)
            .map((folder) => ({
              id: folder.id,
              name: folder.name,
              type: 'folder',
              children: getChildren(folder, 'folder_id', lists),
            })),
          ...lists
            .filter(
              (list) => list.folder_id === null && list.space_id === space.id
            )
            .map((list) => ({
              id: list.id,
              name: list.name,
              type: 'list',
              children: getChildren(list, 'list_id', lists),
            })),
        ],
      }
    })
  }, [])

  const renderNode = (node: any, level: number = 0) => {
    const Icon = IconMap[node.type as keyof typeof IconMap]
    return (
      <>
        <li
          key={node.id}
          className={clsx(
            'flex  items-center gap-2 rounded-lg px-2 py-1 text-gray12',
            {
              'cursor-pointer hover:bg-gray6': node.type === 'list',
              'bg-gray5': node.id === value[0]?.value,
            }
          )}
          onClick={() => {
            if (node.type === 'list') {
              onChange([{ label: node.name, value: node.id }])
              setOpen(false)
            }
          }}
        >
          <Icon />
          <span>{node.name}</span>
        </li>
        <div className='ml-4'>
          {node.children?.map((child: any) => renderNode(child, level + 1))}
        </div>
      </>
    )
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger>
        <Button variant='outline' size='xs' iconRight={<ChevronDownIcon />}>
          {value.length ? value.map((v) => v.label).join(', ') : 'Select List'}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <div>
          <p>Lists</p>
          {treeNodes.map(renderNode)}
        </div>
      </PopoverContent>
    </Popover>
  )
}
