import * as ProgressPrimitive from '@radix-ui/react-progress'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import type { FC, ForwardedRef } from 'react'
import React from 'react'

interface ProgressPropType extends ProgressPrimitive.ProgressProps {
  children?: React.ReactNode
  color?: string
  value?: number
  tooltipText?: React.ReactNode
  showTooltip?: boolean
  height?: number
}

export const ProgressIndicator: FC<ProgressPropType> = React.forwardRef(
  (
    {
      color,
      value,
      showTooltip = false,
      height,
      tooltipText,
    }: ProgressPropType,
    ref: ForwardedRef<any>
  ) => {
    return (
      <>
        {showTooltip ? (
          <TooltipPrimitive.Provider delayDuration={0}>
            <TooltipPrimitive.Root>
              <TooltipPrimitive.Trigger asChild>
                <ProgressPrimitive.Indicator
                  ref={ref}
                  style={{
                    backgroundColor: color,
                    width: `${value}%`,
                    height: `${height || 10}px`,
                  }}
                  className={color || 'bg-brand'}
                />
              </TooltipPrimitive.Trigger>
              <TooltipPrimitive.Portal>
                <TooltipPrimitive.Content className='z-[6] max-w-xs break-words rounded border border-gray4 bg-snow p-2 text-xs font-medium leading-[1.1rem] text-gray9 shadow-lg outline-none will-change-[transform,opacity]   '>
                  {tooltipText}
                  <TooltipPrimitive.Arrow className='fill-current text-gray4 ' />
                </TooltipPrimitive.Content>
              </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
          </TooltipPrimitive.Provider>
        ) : (
          <ProgressPrimitive.Indicator
            ref={ref}
            style={{
              backgroundColor: color,
              width: `${value}%`,
              height: `${height || 10}px`,
              borderRadius: 999,
            }}
            className={color || 'bg-brand'}
          />
        )}
      </>
    )
  }
)

export const Progress: FC<ProgressPropType> = React.forwardRef(
  (
    { children, value, height, ...rest }: ProgressPropType,
    _ref: ForwardedRef<any>
  ) => {
    return (
      <ProgressPrimitive.Root
        // style={{
        //   backgroundColor: 'gray',
        //   height: '10px',
        //   width: '100%',
        //   overflow: 'hidden',
        //   display: 'inline-flex',
        //   borderRadius: '9999px',
        // }}
        className='inline-flex w-full overflow-hidden rounded-full bg-gray5 '
        style={{
          height: `${height || 10}px`,
        }}
        value={value}
        {...rest}
      >
        {children}
      </ProgressPrimitive.Root>
    )
  }
)

Progress.displayName = 'Progress'
ProgressIndicator.displayName = 'ProgressIndications'
export default Progress
