import { ArrowRight } from '@phosphor-icons/react'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import Alert from '@/components/shared/ui/Alert'
import Button from '@/components/shared/ui/Button'
import Counter from '@/components/shared/ui/Counter'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EventEmitter } from '@/lib/eventEmitter'
import {
  formatJSONTimestamp,
  getNextMonthWithSpecificDate,
} from '@/lib/helpers/dateHelpers'
import {
  pluralize,
  snakeToCamel,
  templateComponentReplace,
  templateStringReplace,
} from '@/lib/helpers/stringHelpers'
import { purchaseQuantityPowerUp } from '@/models/Billing'
import type { IPowerUpInfo } from '@/types/billing'
import toaster from '@/utils/toast'

interface IPropTypes {
  onUpdate: (powerUp: IPowerUpInfo) => void
}

export default function PowerUpSeatsRemoveModal({ onUpdate }: IPropTypes) {
  const t = useTranslations('billing')
  const { organizationSetting } = useContext(HNContext)
  const [openDialog, setOpenDialog] = useState(false)
  const [powerUp, setPowerUp] = useState<IPowerUpInfo>()
  const [currentQuantity, setCurrentQuantity] = useState<number>()
  const [isRemoving, setIsRemoving] = useState(false)
  const purchasedSeats = Number(powerUp?.purchased)
  const [billingEndDate, setBillingEndDate] = useState<string>()
  const dialogTranslations = useMemo(() => {
    if (powerUp?.slug === 'boards') return t('removeSeats.boards')
    if (powerUp?.slug === 'ai_credits') return t('removeSeats.aiCredits')
    return t('removeSeats.userRole')
  }, [powerUp?.slug])

  const handleOpenDialog = (eventData: any) => {
    const { event, data } = eventData
    if (event !== ENTITIES.TOGGLE_REMOVE_SEATS_MODAL) return null
    const powerUpData = data.power_ups[0]
    if (powerUpData) {
      // @ts-ignore
      setPowerUp({
        ...powerUpData,
      })
      setCurrentQuantity(powerUpData.purchased)
      setBillingEndDate(formatJSONTimestamp(getNextMonthWithSpecificDate(3)))
    }
    return setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
    setPowerUp(undefined)
  }

  const handleUpdateQuantity = () => {
    if (!powerUp) return null
    setIsRemoving(true)
    const powerUpsData = {
      slug: powerUp.slug,
      source: powerUp.source,
      quantity:
        powerUp.multiplier && currentQuantity
          ? Number(currentQuantity / powerUp.multiplier)
          : currentQuantity,
    }
    return purchaseQuantityPowerUp(powerUpsData)
      .then(() => {
        toaster.success({ message: t('messages.powerUpRemove') })
        handleDialogClose()
        onUpdate({
          ...powerUp,
          purchased: Number(currentQuantity),
          removed:
            Number(Number(powerUp.purchased) + Number(powerUp.removed)) -
            Number(currentQuantity),
        })
      })
      .catch((err) => {
        if (
          err.message ===
          'ERROR_CODE_UNACCEPTABLE_PAYLOAD_POWER_UP_QUANTITY_FORMAT'
        ) {
          const errorMessage = t(
            'errors.ERROR_CODE_UNACCEPTABLE_PAYLOAD_POWER_UP_QUANTITY_FORMAT',
            { ignorePrefix: true, data: { quantity: powerUp.removed } }
          )
          return toaster.info({ message: errorMessage })
        }
        return toaster.error({ message: err.message })
      })
      .finally(() => setIsRemoving(false))
  }

  useEffect(() => {
    EventEmitter.subscribe('CONFIG_PUSH', handleOpenDialog)

    return () => {
      EventEmitter.unsubscribe('CONFIG_PUSH', handleOpenDialog)
    }
  }, [])

  const renderRemovedSeatsInfoAlert = () => {
    if (Number(powerUp?.removed) === 0) return <></>
    const getSeatsText = (value: number) =>
      pluralize(
        Number(value),
        t('common.seat', { ignorePrefix: true }),
        t('common.seats', { ignorePrefix: true })
      )?.toLowerCase()
    const getAlertMessage = () => {
      return `
      ${t('removeSeats.removedSeatsManageModalAlertText', {
        data: {
          br: '</br>',
          nextBillingDate: billingEndDate,
          totalPurchased: String(
            Number(powerUp?.purchased) + Number(powerUp?.removed)
          ),
          purchasedSeats: powerUp?.purchased,
          removedSeats: powerUp?.removed,
          removedSeatText: getSeatsText(Number(powerUp?.removed)),
          remainingSeatsText: getSeatsText(Number(powerUp?.purchased)),
        },
      })}`
    }

    return <Alert className='my-2' type='warning' message={getAlertMessage()} />
  }

  if (!powerUp) return <></>

  return (
    <Dialog open={openDialog} onClose={() => handleDialogClose()}>
      <DialogContent size='md' backdrop>
        <div className='p-1'>
          <DialogClose />
          <DialogTitle>
            <p className='text-lg font-medium'>
              {templateStringReplace(dialogTranslations?.title, {
                powerUp: t(`powerUps.${powerUp?.slug}.title`),
                boards:
                  Number(purchasedSeats) > 1
                    ? organizationSetting?.bucket_plural_name
                    : organizationSetting?.bucket_singular_name,
                aiCredits: t(`powerUps.${snakeToCamel(powerUp?.slug)}.title`),
              })}
            </p>
          </DialogTitle>
          <div className='mt-2 flex flex-col'>
            {renderRemovedSeatsInfoAlert()}
            <Typography.Text className='!text-gray11 '>
              {templateStringReplace(dialogTranslations?.dialogDescription, {
                purchasedSeats:
                  Number(powerUp.purchased) + Number(powerUp?.removed),
                role: t(`powerUps.${powerUp.slug}.title`)?.toLowerCase(),
                aiCredits: t(`powerUps.${snakeToCamel(powerUp?.slug)}.title`),
              })}
            </Typography.Text>
            <div className='mt-1 flex items-center space-x-1'>
              <ArrowRight />
              <Typography.Text className='!text-gray11 '>
                {templateStringReplace(dialogTranslations.description, {
                  amount: powerUp?.amount,
                  interval: powerUp?.interval,
                  powerUp: powerUp?.slug,
                  aiCredits: t(`powerUps.${snakeToCamel(powerUp?.slug)}.title`),
                  multiplier: powerUp?.multiplier,
                })}
              </Typography.Text>
            </div>
            <div className='mt-1 flex items-center space-x-1'>
              <ArrowRight />
              <Typography.Text className='!text-gray11 '>
                {templateComponentReplace(dialogTranslations.effectOn, {
                  nextBillingDate: (
                    <Typography.Text className='font-semibold !text-gray11 '>
                      {billingEndDate}
                    </Typography.Text>
                  ),
                })}
              </Typography.Text>
            </div>
            <div className='mt-4'>
              <Typography.Text className='font-medium !text-gray11 '>
                {templateStringReplace(dialogTranslations.removeSeats, {
                  aiCredits: t(`powerUps.${snakeToCamel(powerUp?.slug)}.title`),
                })}
              </Typography.Text>
            </div>
            <div className='mb-4 mt-0 flex items-center justify-between space-x-3 border-b border-gray5 pb-2 '>
              <Typography.Text className='!text-gray11 '>{`${purchasedSeats} ${
                powerUp.slug === 'ai_credits'
                  ? t('texts.credits')
                  : t('texts.seats')
              }`}</Typography.Text>
              <ArrowRight weight='bold' />
              <Counter
                defaultValue={
                  powerUp.multiplier && currentQuantity
                    ? currentQuantity / powerUp.multiplier
                    : currentQuantity || 0
                }
                onCountChange={setCurrentQuantity}
                min={0}
                max={
                  powerUp.multiplier
                    ? purchasedSeats / powerUp.multiplier
                    : purchasedSeats
                }
              />
            </div>
            <Typography.Text>
              {templateComponentReplace(dialogTranslations.downGradeInfo, {
                difference: (
                  <Typography.Text className='font-semibold'>
                    {powerUp.multiplier
                      ? String(
                          Number(purchasedSeats / powerUp.multiplier) -
                            Number(currentQuantity)
                        )
                      : String(
                          Number(purchasedSeats) - Number(currentQuantity)
                        )}
                  </Typography.Text>
                ),
                currentSeats: (
                  <Typography.Text className='font-semibold'>
                    {currentQuantity?.toString()}{' '}
                  </Typography.Text>
                ),
                boards:
                  Number(currentQuantity) > 1
                    ? organizationSetting?.bucket_plural_name?.toLowerCase()
                    : organizationSetting?.bucket_singular_name?.toLocaleLowerCase(),
                aiCredits: t(`powerUps.${snakeToCamel(powerUp?.slug)}.title`),
              })}
            </Typography.Text>
            <Typography.Text>
              {templateComponentReplace(dialogTranslations.nextPaymentInfo, {
                totalAmount: (
                  <Typography.Text className='font-semibold'>
                    {`$${String(
                      Number(powerUp.amount) * Number(currentQuantity)
                    )}`}
                  </Typography.Text>
                ),
                nextBillingDate: billingEndDate,
              })}
            </Typography.Text>
          </div>
        </div>
        <div className='flex items-center justify-end space-x-2 pt-2'>
          <Button
            variant='text'
            size='xs'
            disabled={isRemoving}
            onClick={handleDialogClose}
          >
            {t('buttons.cancel', { ignorePrefix: true })}
          </Button>
          <Button
            variant='danger'
            size='xs'
            disabled={
              isRemoving ||
              Boolean(
                powerUp.multiplier
                  ? purchasedSeats / powerUp.multiplier === currentQuantity
                  : purchasedSeats === currentQuantity
              )
            }
            loading={isRemoving}
            onClick={handleUpdateQuantity}
          >
            {t('buttons.remove', { ignorePrefix: true })}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
