/* eslint-disable @next/next/no-html-link-for-pages */

import clsx from 'clsx'
import router from 'next/router'
import { useContext, useMemo } from 'react'

import ErrorString from '@/components/shared/common/ErrorString'
import Typography from '@/components/shared/ui/Typography'
import UserSideUserAuthButton from '@/components/users/shared/UserSideUserAuthButton'
import { APP_NAME, DOMAIN } from '@/config/appConstants'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import type { IPageError } from '@/types/app'

import Button from '../ui/Button'

interface IPropTypes {
  error: IPageError
}

export default function ErrorPage({ error }: IPropTypes) {
  const { userProfile, organization } = useContext(HNContext)
  const t = useTranslations()
  const errorString = useMemo(() => {
    if (error.ctaText) return error.ctaText
    if (
      error.status?.toString() === '404' ||
      error.status?.toString() === '403' ||
      error.status?.toString() === '500'
    )
      return 'Go back home'
    return 'Refresh'
  }, [error])

  const errorMessage = useMemo(() => {
    if (error.status?.toString() === '403') {
      if (!userProfile)
        return t(
          'errors.ERROR_CODE_RESOURCE_POST_NOT_FOUND_WITHOUT_USERPROFILE'
        )
      return t('errors.ERROR_CODE_RESOURCE_POST_NOT_FOUND')
    }
    return error.message
  }, [error.message])

  const onCTAClick = () => {
    if (error.ctaLink) return router.push(error.ctaLink)
    if (
      error.status?.toString() === '404' ||
      error.status?.toString() === '500' ||
      error.status?.toString() === '403'
    ) {
      window.location.href = '/'
      return null
    }
    return window.location.reload()
  }

  if (error.status?.toString() === '403') {
    return (
      <div className='mx-auto flex h-[calc(100vh-130px)] flex-col place-content-center items-center justify-center text-center'>
        <div className={clsx('max-w-md space-y-3  pb-4')}>
          <Typography.Title className='font-mono' level={1}>
            {error?.status || '404'}
          </Typography.Title>
          <Typography.Title level={3}>
            {error?.message ? (
              <ErrorString message={errorMessage} />
            ) : (
              `Something isn't right`
            )}
          </Typography.Title>
          {userProfile && (
            <Button size='xs' onClick={onCTAClick}>
              {errorString}
            </Button>
          )}
          {!!(!userProfile && !!organization) && (
            <div className='flex items-center justify-center'>
              <UserSideUserAuthButton />
            </div>
          )}
        </div>
      </div>
    )
  }
  return (
    <div
      className='flex h-[calc(100vh-130px)] flex-col items-center justify-center px-10 md:flex-row md:items-center md:justify-center md:space-x-6'
      data-testid='not_found_layout_container'
    >
      <div className='space-x-2 pb-8 pt-6 md:space-y-5'>
        <h1 className='md:leading-14 font-mono text-2xl leading-9 tracking-tight text-gray12 md:px-6'>
          {error?.status || '404'}
        </h1>
      </div>
      <div className='max-w-md space-y-2 border-gray4  md:border-l-2 md:pl-6'>
        <h2 className='text-xl font-semibold text-gray12'>
          {error?.message ? (
            <ErrorString message={errorMessage} />
          ) : (
            `Something isn't right`
          )}
        </h2>
        {error.description ? (
          <p>{error.description}</p>
        ) : (
          <p>
            It looks like you&apos;ve hit an issue with {APP_NAME}. Don&apos;t
            worry though! We use tools to monitor these issues and it&apos;s
            likely we&apos;re already looking into this!
          </p>
        )}
        <p className='mb-1'>If you would like, you may want to try:</p>
        <ul className='mb-3 list-inside list-disc space-y-1 pb-4'>
          <li>
            <button
              type='button'
              className='text-blue8 underline'
              onClick={() => window.location.reload()}
            >
              Refresh this page
            </button>{' '}
            after a few seconds
          </li>
          <li>
            <a
              href={`https://feedback.${DOMAIN}/b/Support`}
              className='text-blue8 underline'
            >
              Contact support
            </a>{' '}
            if you think something is terribly wrong
          </li>
        </ul>
        <Button onClick={onCTAClick} variant='outline'>
          {errorString}
        </Button>
      </div>
    </div>
  )
}
