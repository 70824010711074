import type { MeiliSearch } from 'meilisearch'
import { makeAutoObservable } from 'mobx'

import { PAGINATION_LIMIT } from '@/config/appConstants'
import { SEARCH_INDEXES } from '@/context/HNSearchContext'
import { cleanChangelogSearchResults } from '@/lib/helpers/modules/changelogHelper'
import { dbChangelogFilterToSearchFilter } from '@/lib/helpers/modules/searchHelper'
import {
  addChangelogContributor,
  addChangelogLabels,
  deleteChangelog,
  getAdminChangelogList,
  getChangelogCount,
  getChangelogIdsFromIndexDB,
  linkPostToChangelog,
  removeChangelogContributor,
  removeChangelogLabels,
  setChangelogIdsToIndexDB,
  setChangelogToDB,
  unlinkPostFromChangelog,
  updateChangelog,
} from '@/models/Changelog'
import type { IChangelog, IChangelogAPIParams } from '@/types/changelog'
import type { IKeyValueMap } from '@/types/common'
import type { IChangelogLabel } from '@/types/organization'
import type { IUserProfile } from '@/types/user'

class ChangelogStore {
  controller = new AbortController()

  changelogItems: IChangelog[] = []

  loading: boolean = true

  canFetchMore: boolean = false

  filters: IChangelogAPIParams = { page: 1 }

  globalFilters: IChangelogAPIParams = { page: 1 }

  searchClient: MeiliSearch | null = null

  counts: any = {}

  constructor() {
    makeAutoObservable(this)
  }

  setSearchClient = (client: MeiliSearch) => {
    this.searchClient = client
  }

  setGlobalFilters(filters: IChangelogAPIParams) {
    this.globalFilters = filters
  }

  updateFilters(
    filters?: IChangelogAPIParams,
    options?: {
      hardReset?: boolean
      skipReload?: boolean
    }
  ) {
    this.filters = options?.hardReset
      ? { ...filters, page: 1 }
      : { page: 1, ...this.filters, ...filters }
    if (!options?.skipReload) {
      // this.resetSelection()
      this.list()
    }
    return this.filters
  }

  updatePage = (incrementor: number) => {
    this.updateFilters({ page: (this.filters.page || 1) + incrementor })
  }

  resetPage = () => {
    this.updateFilters({ page: 1 })
  }

  searchChangelogs(appliedFilters: IKeyValueMap) {
    const { query, ...restFilters } = appliedFilters
    if (!this.searchClient) return Promise.resolve()
    return this.searchClient
      .index<IChangelog>(SEARCH_INDEXES.Changelog)
      .search<IChangelog>(query, {
        filter: dbChangelogFilterToSearchFilter(restFilters),
        attributesToHighlight: ['title', 'description'],
        limit: PAGINATION_LIMIT.adminPostsList,
        offset:
          ((this.filters.page || 0) - 1) * PAGINATION_LIMIT.adminPostsList,
      })
      .then(({ hits, estimatedTotalHits }) => {
        const searchHits = hits.map(cleanChangelogSearchResults)
        if (this.filters.page === 1) {
          this.changelogItems = searchHits
        } else {
          this.changelogItems = [...(this.changelogItems || []), ...searchHits]
        }
        this.loading = false
        this.canFetchMore = estimatedTotalHits > this.changelogItems?.length
        this.counts = {
          ...this.counts,
          search: estimatedTotalHits,
        }
        return hits
      })
  }

  list() {
    this.canFetchMore = false
    const appliedFilters = {
      ...this.filters,
      ...this.globalFilters,
    }
    if (this.loading) {
      this.controller.abort()
      this.controller = new AbortController()
      this.loading = false
    }

    if (appliedFilters.query) {
      return this.searchChangelogs(appliedFilters)
    }

    if (this.filters.page === 1) {
      getChangelogIdsFromIndexDB(appliedFilters).then((localChangelogs) => {
        if (localChangelogs && localChangelogs.length) {
          this.changelogItems = localChangelogs
        } else {
          this.changelogItems = []
        }
      })
    }
    this.loading = true
    return getAdminChangelogList(appliedFilters, {
      signal: this.controller.signal,
    })
      .then(
        ({
          changelogs: newChangelogList,
          changelogs_count_by_status: counts,
        }) => {
          this.changelogItems =
            this.filters.page === 1
              ? newChangelogList
              : [...this.changelogItems, ...newChangelogList]
          this.loading = false
          this.canFetchMore = !(newChangelogList.length < 30)
          this.counts = counts
          return newChangelogList
        }
      )
      .then((newChangelogList: IChangelog[]) => {
        Promise.all(
          newChangelogList.map((changelog) =>
            setChangelogToDB(changelog.slug, changelog)
          )
        )
        return newChangelogList
      })
      .then((newChangelogList: IChangelog[]) => {
        if (this.filters.page === 1) {
          return setChangelogIdsToIndexDB(
            appliedFilters,
            newChangelogList
          ).then(() => newChangelogList)
        }
        return newChangelogList
      })
  }

  updateTabCount() {
    getChangelogCount({ ...this.filters }).then((data: any) => {
      this.counts = data
    })
  }

  deleteSingleChangelog(slug: string) {
    this.changelogItems = [...this.changelogItems].filter(
      (c) => c.slug !== slug
    )
  }

  updateSingleChangelog(
    slug: string,
    newChangelog: Partial<IChangelog>
  ): IChangelog {
    const changelogIndex = this.changelogItems.findIndex(
      (changelog) => changelog.slug === slug
    )

    if (changelogIndex === -1) throw new Error('CHANGELOG_MISSING')

    const currentChangelog = this.changelogItems[changelogIndex]
    if (!currentChangelog) throw new Error('CHANGELOG_MISSING')
    const updatedChangelog: IChangelog = {
      ...currentChangelog,
      ...newChangelog,
    }
    this.changelogItems[changelogIndex] = updatedChangelog
    return updatedChangelog
  }

  appendChangelog(incomingChanglog: IChangelog) {
    const changelogIndex = this.changelogItems.findIndex(
      (_changelog) =>
        _changelog.id.toString() === incomingChanglog.id.toString()
    )
    if (changelogIndex < 0) {
      this.changelogItems = [...this.changelogItems, incomingChanglog]
    } else {
      this.changelogItems[changelogIndex] = {
        ...this.changelogItems[changelogIndex],
        ...incomingChanglog,
      }
    }
  }

  get(slug: string) {
    return this.changelogItems.find((c) => c.slug === slug)
  }

  update(slug: string, data: any) {
    return updateChangelog(slug, data).then((newChangelog: IChangelog) =>
      this.updateSingleChangelog(slug, newChangelog)
    )
  }

  delete(slug: string) {
    return deleteChangelog(slug).then((newChangelog: IChangelog) => {
      this.deleteSingleChangelog(slug)
      return newChangelog
    })
  }

  addLabel(slug: string, data: any, changelog: IChangelog) {
    return addChangelogLabels(slug, data).then((newLabel: IChangelogLabel) => {
      const labels = [...changelog.labels, newLabel]
      return this.updateSingleChangelog(slug, {
        ...changelog,
        labels,
      })
    })
  }

  removeLabel(slug: string, id: string, changelog: IChangelog) {
    return removeChangelogLabels(slug, id).then(
      (deletedLabel: IChangelogLabel) => {
        const labels = changelog.labels.filter(
          (l) => +l.id !== +deletedLabel.id
        )
        return this.updateSingleChangelog(slug, {
          ...changelog,
          labels,
        })
      }
    )
  }

  addContributor(
    slug: string,
    data: any,
    changelog: IChangelog,
    contributor: IUserProfile
  ) {
    return addChangelogContributor(data).then(() => {
      const contributors = [...changelog.contributors, contributor]
      this.updateSingleChangelog(slug, {
        ...changelog,
        contributors,
      })
    })
  }

  removeContributor(
    slug: string,
    data: any,
    changelog: IChangelog,
    contributor: IUserProfile
  ) {
    return removeChangelogContributor(contributor.id, data).then(() => {
      const contributors = changelog.contributors.filter(
        (contri) => +contri.id !== +contributor.id
      )
      this.updateSingleChangelog(slug, {
        ...changelog,
        contributors,
      })
    })
  }

  linkPost(slug: string, data: { feature_request_id: string }) {
    return linkPostToChangelog(slug, data).then((newChangelog: IChangelog) =>
      this.updateSingleChangelog(slug, newChangelog)
    )
  }

  unlinkPost(slug: string, id: string) {
    return unlinkPostFromChangelog(slug, id).then((newChangelog: IChangelog) =>
      this.updateSingleChangelog(slug, newChangelog)
    )
  }
}

const changelogStore = new ChangelogStore()

export default changelogStore
