import React, { useContext } from 'react'

import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import HNContext from '@/context/HNContext'
import { userSideModulePath } from '@/lib/helpers/pathHelpers'
import { getBasePathPrefix } from '@/lib/helpers/urlHelpers'
import type { IEmbedType } from '@/types/organization'

export default function CustomHeaderChaneglogButton() {
  const { show_changelog_tab, default_module, embedType, organizationSetting } =
    useContext(HNContext)

  if (!show_changelog_tab) return null

  const url = userSideModulePath(
    'changelog',
    default_module || 'changelog',
    getBasePathPrefix(embedType as IEmbedType)
  )
  return (
    <UnstyledLink href={url} className=''>
      {organizationSetting?.changelog_plural_name}
    </UnstyledLink>
  )
}
