import { QueryClientProvider } from '@tanstack/react-query'
import type { ReactNode } from 'react'
import React, { useContext } from 'react'

import EmbedHeader from '@/components/admin/shared/EmbedHeader'
import AuthModal from '@/components/auth/shared/AuthModal'
import AccountConfirmationAlert from '@/components/shared/components/userAccount/AccountConfirmationAlert'
import Wrapper from '@/components/shared/ui/Wrapper/Wrapper'
import UserSideHeader from '@/components/users/shared/UserSideHeader'
import { queryClient } from '@/config/queryClient'
import HNContext from '@/context/HNContext'

interface IPropTypes {
  children: ReactNode
}

export default function EmbedLayout(props: IPropTypes) {
  const { noHeader = false, embedType } = useContext(HNContext)

  return (
    <QueryClientProvider client={queryClient}>
      <Wrapper>
        <>
          {embedType === 'orgEmbed' && <UserSideHeader />}
          {!noHeader && embedType !== 'orgEmbed' && <EmbedHeader />}
          {noHeader && <AuthModal noTrigger />}
          <AccountConfirmationAlert />
          {props.children}
        </>
      </Wrapper>
    </QueryClientProvider>
  )
}
