import { useContext, useMemo, useState } from 'react'

import AIWand from '@/components/shared/icons/AIWand'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import type { ButtonProps } from '@/components/shared/ui/Button/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { checkUserCanAccessAI } from '@/lib/helpers/modules/postHelper'
import { checkAndUpdateUsage } from '@/lib/helpers/powerUpsHelper'
import { postAiRecommence } from '@/models/Post'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  buttonProps?: ButtonProps
}
export default function SinglePostAIAnalyzeButton({
  post,
  buttonProps,
}: IPropTypes) {
  const t = useTranslations('post.sentiment')
  const {
    userProfile,
    organizationPlan,
    updateContext,
    generation_credits_remaining,
  } = useContext(HNContext)
  const [loading, setLoading] = useState(false)
  const userHaveAccess = useMemo(() => {
    if (!userProfile || !organizationPlan) return false
    return checkUserCanAccessAI(userProfile, organizationPlan)
  }, [userProfile, organizationPlan])

  const handleAiRecommence = () => {
    if (!userProfile || !organizationPlan) return false
    setLoading(true)
    return postAiRecommence(post.slug)
      .then((response) => {
        EventEmitter.dispatch('ENTITY_UPDATE', {
          actionType: EVENT_ACTION_TYPES.UPDATE,
          entity: 'POST_INSIGHTS',
          data: {
            id: post.id,
            data: response,
          },
        })
        checkAndUpdateUsage(
          organizationPlan,
          updateContext,
          null,
          'ai_credits',
          'add',
          'used'
        )
        updateContext?.({
          generation_credits_remaining: generation_credits_remaining
            ? generation_credits_remaining - 1
            : 0,
        })
        // @ts-ignore
        postStore.updateSinglePost(post.slug, {
          sentiment_type: response.sentiment_type,
        })
        toaster.success({
          message: t('post.messages.aiAnalysis.success', {
            ignorePrefix: true,
          }),
        })
      })
      .catch((error) => {
        toaster.error({ message: error.message })
      })
      .finally(() => setLoading(false))
  }

  if (!userHaveAccess) return null
  return (
    <AlertDialog
      title={t('posts.aiAnalysis.reprocess.alertTexts.title', {
        ignorePrefix: true,
      })}
      description={t('posts.aiAnalysis.reprocess.alertTexts.description', {
        ignorePrefix: true,
        data: {
          credits: 1,
        },
      })}
      onConfirm={handleAiRecommence}
    >
      <Button
        isResponsive
        variant='primary'
        size='xs'
        icon={<AIWand className='h-3.5 w-3.5' />}
        loading={loading}
        disabled={loading}
        {...buttonProps}
      >
        {buttonProps?.children || t('labels.aiReprocess')}
      </Button>
    </AlertDialog>
  )
}
