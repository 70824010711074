import { CircleWavyCheck, Star } from '@phosphor-icons/react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { type VariantProps, cva } from 'class-variance-authority'
import clsx from 'clsx'
import type { FC } from 'react'
import React, { memo, useMemo } from 'react'

const avatarVariants = cva(
  'relative inline-flex items-center justify-center text-fosblue',
  {
    variants: {
      size: {
        xxs: 'h-5 w-5 text-xs',
        xs: 'h-6 w-6 text-xs',
        sm: 'h-8 w-8 text-sm',
        md: 'h-10 w-10 text-base',
        lg: 'h-12 w-12 text-lg',
        xl: 'h-16 w-16 text-xl',
      },
      rounded: {
        true: 'rounded-full',
        false: 'rounded-md',
      },
      isMulti: {
        true: 'border-2 border-snow',
        false: '',
      },
    },
    defaultVariants: {
      size: 'sm',
      rounded: false,
      isMulti: false,
    },
  }
)

const imageVariants = cva('h-full w-full object-cover', {
  variants: {
    rounded: {
      true: 'rounded-full',
      false: 'rounded-md',
    },
  },
  defaultVariants: {
    rounded: false,
  },
})

const fallbackVariants = cva(
  'flex h-full w-full items-center justify-center bg-blue4 font-medium',
  {
    variants: {
      rounded: {
        true: 'rounded-full',
        false: 'rounded-md',
      },
    },
    defaultVariants: {
      rounded: false,
    },
  }
)

export interface IAvatarProps extends VariantProps<typeof avatarVariants> {
  name: string | undefined
  url?: string
  showdot?: boolean
  starred?: boolean
  showBadge?: boolean
  className?: string
}

export type IAvatarPropsWithoutName = Omit<IAvatarProps, 'name'>

const Avatar: FC<IAvatarProps> = ({
  url = '',
  name = '',
  size = 'sm',
  rounded = false,
  isMulti = false,
  starred = false,
  showdot = false,
  showBadge = false,
  className,
}) => {
  const dimension = useMemo(() => {
    if (size === 'xxs') return '20'
    if (size === 'xs') return '24'
    if (size === 'sm') return '32'
    if (size === 'md') return '40'
    if (size === 'lg') return '48'
    if (size === 'xl') return '64'
    return size
  }, [size])

  return (
    <AvatarPrimitive.Root
      className={clsx(avatarVariants({ size, rounded, isMulti }), className)}
    >
      <AvatarPrimitive.Image
        className={imageVariants({ rounded })}
        src={url}
        alt={name}
        height={Number(dimension)}
        width={Number(dimension)}
      />
      <AvatarPrimitive.Fallback
        className={fallbackVariants({ rounded })}
        delayMs={0}
      >
        {name?.charAt(0).toUpperCase() || '*'}
      </AvatarPrimitive.Fallback>
      {Boolean(starred) && !showBadge && (
        <Star
          weight='fill'
          data-testid='ui_avatar_show_star'
          className='absolute -bottom-1 -right-1 h-3.5 w-3.5 text-yellow9'
        />
      )}
      {Boolean(showBadge) && (
        <CircleWavyCheck
          className='absolute bottom-[-3px] right-[-3px] h-3.5 w-3.5 rounded-full bg-snow text-green8'
          weight='fill'
        />
      )}
      {Boolean(showdot) && (
        <span className='absolute -right-0 top-0.5 h-1.5 w-1.5 rounded-full bg-red8 ring-1 ring-snow' />
      )}
    </AvatarPrimitive.Root>
  )
}

Avatar.displayName = 'Avatar'

export default memo(Avatar)
