import { EventEmitter } from '@/lib/eventEmitter'
import { setTheme } from '@/lib/theming'
import { toggleUserTheme } from '@/models/User'

export const handleToggleTheme = (theme: any, updateContext: any) => {
  return toggleUserTheme().then(() => {
    if (theme) {
      const newTheme = { ...theme, darkMode: !theme.darkMode }
      setTheme(newTheme)
      if (updateContext) updateContext({ theme: newTheme })
    }
  })
}

export const checkAction = (command: any, options?: any) => {
  const { updateContext, theme } = options
  switch (command.key) {
    case 'newPost':
      return EventEmitter.dispatch('SHORTCUT_TRIGGERED', { action: 'CREATE' })
    case 'theme':
      return handleToggleTheme(theme, updateContext)
    default: {
      return null
    }
  }
}
