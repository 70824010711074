import type { ReactNode } from 'react'
import React from 'react'

import Wrapper from '@/components/shared/ui/Wrapper/Wrapper'
import UserProfileHeader from '@/components/users/shared/UserProfileHeader'

// @ts-ignore

interface IPropTypes {
  children: ReactNode
}

export default function UserProfileLayout(props: IPropTypes) {
  return (
    <Wrapper>
      <UserProfileHeader />
      {props.children}
    </Wrapper>
  )
}
