import { CloudArrowUp, Video, YoutubeLogo } from '@phosphor-icons/react'
import React from 'react'

import { DEFAULT_BUTTON_CLASS } from '@/components/shared/components/editor/editorConstants'
import SVG from '@/components/shared/components/SVG'
import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import Tooltip from '@/components/shared/ui/Tooltip'
import { useTranslations } from '@/hooks/useTranslations'
import type { IEditorActions, IRichTextEditorProps } from '@/types/editor'

interface IPropTypes {
  actions: IEditorActions
  children?: React.ReactNode
  support?: IRichTextEditorProps['support']
}

export default function VideoUploadButton({
  actions,
  children,
  support,
}: IPropTypes) {
  const t = useTranslations('editor')
  return (
    <Dropdown>
      <DropdownTrigger asChild>
        {children || (
          <span>
            <Tooltip
              asChild
              disableHoverableContent
              placement='bottom'
              text={t('video')}
            >
              <button type='button' className={DEFAULT_BUTTON_CLASS}>
                <Video weight='bold' className='h-4 w-4' />
              </button>
            </Tooltip>
          </span>
        )}
      </DropdownTrigger>
      <DropdownContent>
        {support?.video && (
          <DropdownItem
            onSelect={() => {
              actions.triggerVideoUpload()
            }}
            itemValue={t('uploadVideo')}
            icon={<CloudArrowUp />}
          />
        )}
        <DropdownItem
          onSelect={() => actions.openEmbedDialog('youtube')}
          itemValue={t('youtube_embed')}
          icon={<YoutubeLogo />}
        />
        <DropdownItem
          onSelect={() => actions.openEmbedDialog('loom')}
          itemValue={t('embed.loom.title')}
          icon={<SVG url={`/assets/images/loom.svg`} className='p-[1px]' />}
        />
      </DropdownContent>
    </Dropdown>
  )
}
