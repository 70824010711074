import { Check } from '@phosphor-icons/react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import * as SwitchPrimitive from '@radix-ui/react-switch'
import { type VariantProps, cva } from 'class-variance-authority'
import clsx from 'clsx'
import type { FC, Ref } from 'react'
import React, { useEffect, useRef, useState } from 'react'

import type { IAlertDialogType } from '@/components/shared/ui/AlertDialog/AlertDialog'
import Label from '@/components/shared/ui/Label'

import AlertDialog from '../AlertDialog'

// Update CVA variants for checkbox
const checkboxVariants = cva(
  'flex h-3.5 w-3.5 flex-shrink-0 overflow-hidden rounded border border-gray6 bg-snow focus:outline-none dark:bg-gray4 dark:focus:border-primary-dark dark:focus:ring-primary-dark [&[data-state="checked"]]:border-primary-dark [&[data-state="checked"]]:bg-primary [&[data-state="checked"]]:text-snow [&[data-state="checked"]]:transition [&[data-state="checked"]]:duration-200',
  {
    variants: {
      disabled: {
        true: '!cursor-not-allowed',
        false: '',
      },
    },
    defaultVariants: {
      disabled: false,
    },
  }
)

// Update CVA variants for switch
const switchVariants = cva(
  'relative inline-flex h-5 w-8 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition focus:outline-none [&[data-state="checked"]]:bg-grass10 [&[data-state="unchecked"]]:bg-gray5',
  {
    variants: {
      disabled: {
        true: '!cursor-not-allowed opacity-50',
        false: '',
      },
    },
    defaultVariants: {
      disabled: false,
    },
  }
)

const CheckboxIndicator: FC<any> = React.forwardRef(
  ({ ...props }, forwardedRef: Ref<any>) => (
    <CheckboxPrimitive.Indicator
      {...props}
      ref={forwardedRef}
      className='flex items-center justify-center'
    >
      <Check weight='bold' className='h-3 w-3 text-gray1 text-snow' />
    </CheckboxPrimitive.Indicator>
  )
)

export interface ICheckboxProps {
  label?: string
  id?: string
  description?: string
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  isSwitch?: boolean
  onCheckedChange?: (checked: boolean) => void
  alertVariant?: IAlertDialogType
  alertTexts?: {
    title: string
    description?: string
    cancelText?: string
    confirmText?: string
  }
  disabledAlertTexts?: {
    title: string
    description?: string
    cancelText?: string
    confirmText?: string
  }
  alertDisabled?: boolean
  checkboxClassName?: string
  onClick?: (e: React.MouseEvent) => void
  leftToRight?: boolean
  mandatory?: boolean
}

const SwitchThumb: FC<any> = React.forwardRef(
  ({ ...props }, forwardedRef: Ref<any>) => (
    <SwitchPrimitive.Thumb
      {...props}
      ref={forwardedRef}
      className='inline-block h-4 w-4 rounded-full bg-snow transition will-change-transform dark:bg-gray12 [&[data-state="checked"]]:translate-x-3 [&[data-state="unchecked"]]:translate-x-0'
    />
  )
)

const Checkbox: FC<ICheckboxProps & VariantProps<typeof checkboxVariants>> =
  React.forwardRef(
    (
      {
        label,
        description,
        checked: _checked,
        defaultChecked,
        disabled,
        onCheckedChange,
        isSwitch,
        alertVariant,
        alertTexts,
        alertDisabled,
        disabledAlertTexts = {},
        checkboxClassName,
        id,
        leftToRight,
        mandatory,
        ...props
      },
      ref: Ref<any>
    ) => {
      const [checked, setChecked] = useState<boolean | undefined>(_checked)
      const [showAlert, setShowAlert] = useState(false)
      const valueToSet = useRef<Boolean | null>()

      const handleConfirm = async () => {
        if (
          onCheckedChange &&
          (valueToSet.current !== null || valueToSet.current !== undefined)
        ) {
          await onCheckedChange(valueToSet.current as boolean)
          setChecked(!!valueToSet.current)
          return true
        }
        setShowAlert(false)
        return true
      }

      const handleCancel = () => {
        setShowAlert(false)
        setChecked(!valueToSet.current)
        valueToSet.current = null
      }

      const handleToggle = (newChecked: boolean) => {
        if (alertTexts) {
          setShowAlert(true)
          valueToSet.current = newChecked
        } else if (onCheckedChange) {
          onCheckedChange(newChecked)
        }
      }

      useEffect(() => {
        setChecked(_checked)
      }, [_checked])

      const renderCheckbox = () => {
        if (isSwitch) {
          return (
            <SwitchPrimitive.Root
              ref={ref}
              id={id || label}
              checked={checked}
              disabled={disabled}
              defaultChecked={defaultChecked}
              onCheckedChange={handleToggle}
              className={switchVariants({
                disabled,
              })}
              {...props}
            >
              <SwitchThumb />
            </SwitchPrimitive.Root>
          )
        }
        return (
          <CheckboxPrimitive.Root
            ref={ref}
            id={id || label}
            checked={checked}
            disabled={disabled}
            defaultChecked={defaultChecked}
            onCheckedChange={handleToggle}
            className={checkboxVariants({
              disabled,
              className: checkboxClassName,
            })}
            {...props}
          >
            <CheckboxIndicator />
          </CheckboxPrimitive.Root>
        )
      }

      const alertTextsMerged = checked
        ? { ...alertTexts, ...disabledAlertTexts }
        : alertTexts

      return (
        <>
          <Label htmlfor={id || label}>
            <span
              className={clsx(
                'flex cursor-pointer items-start gap-2 transition duration-200',
                {
                  '!items-center': !description,
                  'flex-row-reverse': leftToRight,
                }
              )}
            >
              {renderCheckbox()}
              {label && (
                <span className='-mt-px flex w-full flex-col space-y-0'>
                  <div className='flex items-center gap-1'>
                    <span
                      className='text-gray12 transition dark:text-gray12 [&_a]:text-primary [&_a]:underline'
                      dangerouslySetInnerHTML={{ __html: label }}
                    />
                    {mandatory && <span className='text-red-500'>*</span>}
                  </div>
                  {description ? (
                    <span
                      className='mt-1 text-gray11 dark:text-gray11 [&_a]:text-primary [&_a]:underline'
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  ) : null}
                </span>
              )}
            </span>
          </Label>
          <AlertDialog
            type={alertVariant || 'info'}
            disabled={alertDisabled}
            open={showAlert}
            title={alertTextsMerged?.title}
            description={alertTextsMerged?.description}
            confirmText={alertTextsMerged?.confirmText}
            cancelText={alertTextsMerged?.cancelText}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            waitForPromise={true}
            onClose={() => setShowAlert(false)}
          />
        </>
      )
    }
  )

SwitchThumb.displayName = 'SwitchThumb'
Checkbox.displayName = 'Checkbox'
CheckboxIndicator.displayName = 'CheckboxIndicator'
export default Checkbox
