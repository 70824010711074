import React from 'react'

import Avatar from '@/components/shared/ui/Avatar'
import type { IAvatarPropsWithoutName } from '@/components/shared/ui/Avatar/Avatar'
import type { IOrganizationData } from '@/types/organization'

interface IPropTypes extends IAvatarPropsWithoutName {
  organization: IOrganizationData
}

export default function OrganizationAvatar(props: IPropTypes) {
  const { organization, ...rest } = props

  if (!organization) return null
  return (
    <Avatar
      {...rest}
      url={organization.avatar?.thumb_url}
      name={organization.name}
      rounded
    />
  )
}
