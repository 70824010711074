import React, { useEffect, useMemo, useState } from 'react'

import PowerUpItem from '@/components/admin/powerups/PowerUpItem'
import Spinner from '@/components/shared/ui/Loader'
import Typography from '@/components/shared/ui/Typography'
import { onTimePowerUps } from '@/config/billingConstants'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getIntervalText,
  multiplierCleaners,
} from '@/lib/helpers/billingHelpers'
import {
  convertObjectsOfObjectsToArrayOfObjects,
  objectHasProperty,
} from '@/lib/helpers/dataHelpers'
import { QUANTITY_POWER_UPS } from '@/lib/helpers/powerUpsHelper'
import { getPlanAndPowerUps, getPowerUpsInfo } from '@/models/Billing'
import type {
  ICreateSubscriptionParams,
  IPowerUpInfo,
  IProratedPlan,
  IPurchasePowerUpsParams,
} from '@/types/billing'
import toaster from '@/utils/toast'

interface IPropTypes {
  configData: IPurchasePowerUpsParams
  onUpdate: (powerUps: IPowerUpInfo[]) => void
  onError?: (error: string | null) => void
  showAddAndRemoveButton?: boolean
  loadingState?: (state: boolean) => void
  activePlan?: boolean
  interval?: string
  isFullPowerUpData?: boolean
}

export default function PowerUpsForm({
  configData,
  onUpdate,
  onError,
  showAddAndRemoveButton = true,
  loadingState,
  activePlan,
  interval,
  isFullPowerUpData,
}: IPropTypes) {
  const t = useTranslations('billing')
  const [isLoading, setIsLoading] = useState(false)
  const [powerUps, setPowerUps] = useState<IPowerUpInfo[] | null>(null)
  const [plan, setPlan] = useState<IProratedPlan | null>()

  const totalAmount = useMemo(() => {
    if (powerUps?.length && plan) return plan.amount
    return powerUps?.reduce(
      (acc, curr) => {
        if (curr.amount) return acc + curr.amount * (curr.quantity || 1)
        return acc + 0
      },
      plan ? Number(plan.amount) : 0
    )
  }, [powerUps, plan])

  const fetchData = (
    data: IPurchasePowerUpsParams | ICreateSubscriptionParams
  ) => {
    setIsLoading(true)
    loadingState?.(true)
    return getPlanAndPowerUps(data)
      .then((response) => {
        onError?.(null)
        if (response.power_ups)
          setPowerUps(
            convertObjectsOfObjectsToArrayOfObjects(response?.power_ups)
          )
        if (response.plan) setPlan(response.plan)
      })
      .catch((error) => {
        onError?.(error.message)
        toaster.error({ message: error.message })
      })
      .finally(() => {
        setIsLoading(false)
        loadingState?.(false)
      })
  }

  const fetchPowerUpsInfo = (
    data: IPurchasePowerUpsParams | ICreateSubscriptionParams
  ) => {
    setIsLoading(true)
    loadingState?.(true)
    const newData = { ...data }
    // @ts-ignore
    if (objectHasProperty(newData, 'activePlan')) newData.activePlan = null
    return getPowerUpsInfo(newData)
      .then((response) => {
        if (response.power_ups) {
          const powerUpsArray = convertObjectsOfObjectsToArrayOfObjects(
            response?.power_ups
          )

          const purchasedAndIncludedPowerUps = powerUpsArray.filter(
            (powerUp) =>
              powerUp?.included &&
              powerUp?.purchased &&
              !QUANTITY_POWER_UPS.includes(powerUp.slug)
          )
          if (activePlan && purchasedAndIncludedPowerUps) {
            setPowerUps?.(purchasedAndIncludedPowerUps)
          } else {
            setPowerUps(() => {
              const newPowerUps = powerUpsArray.filter((powerUp) =>
                Boolean(!powerUp?.included && powerUp?.purchased)
              )
              return [...newPowerUps]
            })
          }
        }
        if (response.plan) setPlan(response.plan)
      })
      .catch(toaster.error)
      .finally(() => {
        setIsLoading(false)
        loadingState?.(false)
      })
  }

  // TODO: It will use for future use
  // const handleSelect = (powerUp: IPowerUpInfo | IProratedPlan | any) => {
  //   setPowerUps((prevData) => {
  //     return prevData?.length ? [...prevData, powerUp] : [powerUp]
  //   })
  // }

  const handleUpdate = (powerUp: IPowerUpInfo | IProratedPlan | any) => {
    setPowerUps((prevData) => {
      const updatedPowerUps = [...(prevData || [])]
      const index = prevData?.findIndex((p) => p.slug === powerUp.slug)
      if (index !== -1) {
        // @ts-ignore
        updatedPowerUps[index] = powerUp
      }
      return updatedPowerUps
    })
  }

  const handleRemove = (powerUp: IPowerUpInfo | IProratedPlan | any) => {
    setPowerUps((prevData) => {
      const newData = prevData?.length
        ? prevData?.filter((p) => p.slug !== powerUp.slug)
        : [powerUp]
      return newData
    })
  }

  useEffect(() => {
    if (isFullPowerUpData) setPowerUps(configData.power_ups)
    else if (activePlan || configData.plan) fetchPowerUpsInfo(configData)
    else fetchData(configData)
  }, [])

  useEffect(() => {
    if (powerUps && onUpdate) {
      const newPowerUps = powerUps.map((power) => {
        if (QUANTITY_POWER_UPS.includes(power.slug)) {
          return {
            ...power,
            quantity: power.limitDifference || power.quantity || 1,
          }
        }
        return { ...power, quantity: 1 }
      })
      onUpdate(newPowerUps)
    }
  }, [powerUps])

  const renderPowerUps = () => {
    if (!powerUps?.length) return null
    return (
      <div className='flex flex-col p-3'>
        <Typography.Title level={5} className='mb-2 font-medium !text-carbon '>
          {powerUps?.length > 1
            ? t('texts.powerUpsText')
            : t('texts.powerUpText')}
        </Typography.Title>
        {powerUps.map((powerUp, index) => {
          return (
            <PowerUpItem
              key={index}
              powerUp={powerUp}
              onRemove={handleRemove}
              onUpdate={handleUpdate}
              showAddAndRemoveButton={showAddAndRemoveButton}
              planIncluded={activePlan}
              multiplierCleaner={
                multiplierCleaners[
                  powerUp.slug as keyof typeof multiplierCleaners
                ]
              }
            />
          )
        })}
      </div>
    )
  }

  const renderTotalAmount = () => {
    const isOneTime =
      powerUps?.length === 1 &&
      powerUps[0]?.slug &&
      onTimePowerUps.includes(powerUps[0]?.slug)

    return (
      <div className='flex items-center justify-between border-t border-gray5 p-3 '>
        <Typography.Text className='!text-gray12 '>
          {t('texts.totalAmount')}
        </Typography.Text>
        <span className='flex space-x-1 text-right'>
          <Typography.Text className='font-mono !text-gray12 '>
            {`$${totalAmount}`}
          </Typography.Text>
          {!isOneTime && (
            <Typography.Text className='!text-gray8 '>
              {getIntervalText(
                // @ts-ignore
                plan ? interval : powerUps[0]?.interval,
                t('texts')
              )}
            </Typography.Text>
          )}
        </span>
      </div>
    )
  }

  if (isLoading) return <Spinner />
  return (
    <div className='my-4 divide-y divide-gray5 overflow-hidden rounded-lg border border-gray5 bg-gray4   '>
      {plan && (
        <div className='p-3'>
          <PowerUpItem powerUp={plan} />
        </div>
      )}
      {renderPowerUps()}
      {Boolean(plan || powerUps?.length) && renderTotalAmount()}
    </div>
  )
}
