import React, { useContext, useRef, useState } from 'react'

import type { IPostStatusChangeModalRef } from '@/components/posts/singlePost/PostStatusChangeModal'
import PostStatusChangeModal from '@/components/posts/singlePost/PostStatusChangeModal'
import Status from '@/components/posts/Status'
import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import type { ICustomState } from '@/types/organization'
import type { IPost, IPostCustomStatus } from '@/types/post'

interface IPropsStatus {
  post: IPost
}

export default function PostInlineStatusChange({ post }: IPropsStatus) {
  const {
    custom_states = [],
    customStatus = {},
    userProfile,
  } = useContext(HNContext)
  const statusModalRef = useRef<IPostStatusChangeModalRef>(null)
  const [statusValue, setStatusValue] = useState<IPostCustomStatus>(
    post?.custom_status
  )

  const statusOptions = (st: ICustomState) => {
    return {
      label: st.name,
      value: st.slug,
      group: customStatus[st.status],
      icon: <Status iconOnly status={{ ...st, value: st.name }} />,
      className: `font-medium text-${st.slug}`,
      status: {
        title: st.name,
        value: st.slug,
        ...st,
      },
    }
  }

  const handleChange = (
    value: ISelectOption | ISelectOption[] | undefined | any
  ) => {
    if (!value) return
    setStatusValue(value?.status)
    statusModalRef.current?.toggle()
  }

  const getValue = () => {
    if (post?.custom_status) {
      return custom_states
        .map(statusOptions)
        .filter((s) =>
          [post?.custom_status.value]
            .map((id) => id?.toString())
            .includes(s?.value?.toString())
        )
    }
    return null
  }

  const renderTrigger = () => {
    return (
      <Tooltip asChild text={post.custom_status.title}>
        <Status status={post.custom_status} iconOnly />
      </Tooltip>
    )
  }

  if (!post.custom_status) return null

  if (
    !userProfile?.is_member_of_organization &&
    post.assignee?.id !== userProfile?.id
  )
    return <Status status={post.custom_status} iconOnly />
  return (
    <>
      <Select
        searchable
        variant={'blank'}
        group
        side='right'
        value={getValue()}
        onChange={handleChange}
        options={custom_states.map(statusOptions)}
        multiple={false}
        renderTrigger={renderTrigger}
        placeholder={''}
      />
      <PostStatusChangeModal
        post={post}
        ref={statusModalRef}
        toStatus={statusValue}
      />
    </>
  )
}
