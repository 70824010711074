import { Clock } from '@phosphor-icons/react'
import React, { useMemo } from 'react'

import Tooltip from '@/components/shared/ui/Tooltip/Tooltip'
import { formatJSONTimestamp } from '@/lib/helpers/dateHelpers'
import { classifyETCPriority } from '@/lib/helpers/modules/postHelper'
import type { IDateTime } from '@/types/common'
import type { IPostStatus } from '@/types/post'

type IETCProps = {
  etc?: IDateTime
  status?: IPostStatus
}

export default function ETCDateNotifier({ etc, status }: IETCProps) {
  const etcColor = useMemo(() => {
    if (!etc) return ''
    const days = classifyETCPriority(etc.timestamp || etc)
    if (days < 0) return '#da4141'
    if (days <= 3) return '#ff9b59'
    return '#71717A'
  }, [etc])

  const etcValue = useMemo(() => {
    if (typeof etc === 'string') return new Date(etc)
    if (etc?.timestamp) return etc.timestamp
    return null
  }, [etc])

  if (
    !etcValue ||
    !etc ||
    status?.value === 'completed' ||
    status?.value === 'closed'
  )
    return <></>
  return (
    <Tooltip
      text={formatJSONTimestamp(etcValue, 'dd MMM yyyy')}
      wrapperClass='text-right text-xxs whitespace-nowrap'
      asChild
    >
      <Clock color={etcColor} weight='bold' size='14' className='shrink-0' />
    </Tooltip>
  )
}
