import { DOMAIN, HELP_PAGE_DOMAIN } from '@/config/appConstants'

export const notificationHelpURL = `https://${HELP_PAGE_DOMAIN}/articles/handling-profile-notifications-on-featureos`
export const notificationEnforcedHelpURL = `https://${HELP_PAGE_DOMAIN}/articles/enforced-email-notifications`
export const notificationProfileSettingsHelpURL = `https://${HELP_PAGE_DOMAIN}/articles/managing-email-notification-settings-in-your-profile`

export const webhookHelpURl = `https://developers.${DOMAIN}/docs/webhooks`
export const widgetHelpURL = `https://developers.${DOMAIN}/docs/widgets#options`
export const cookiePolicyURL = `https://alphaos.app/legal/cookies`
export const settingsHelpLinks: { [key: string]: string } = {
  cdnLogo: `https://static.${DOMAIN}/logos/`,
  apiKey: `https://developers.${DOMAIN}/`,
  widgetDocs: `https://developers.${DOMAIN}/docs/widgets`,
  orgPrivacy: `https://${HELP_PAGE_DOMAIN}/articles/public-vs-private-organizations`,
  adminEmailNotifications: `https://${HELP_PAGE_DOMAIN}/articles/enabledisable-email-notifications`,
  homepage: `https://${HELP_PAGE_DOMAIN}/articles/setting-up-url-host`,
  showBrandedContent: `https://${HELP_PAGE_DOMAIN}/en/articles/email-white-labeling-with-featureos`,
  guestVotes: `https://${HELP_PAGE_DOMAIN}/articles/guest-actions-on-featureos`,
  guestComments: `https://${HELP_PAGE_DOMAIN}/articles/guest-actions-on-featureos`,
  guestSubmission: `https://${HELP_PAGE_DOMAIN}/articles/guest-actions-on-featureos`,
  moderation: `https://${HELP_PAGE_DOMAIN}/articles/setting-up-post-comment-moderation`,
  additionalSettings: `https://${HELP_PAGE_DOMAIN}/articles/setting-up-post-comment-moderation`,
  voteLimit: `https://${HELP_PAGE_DOMAIN}/articles/how-to-set-vote-limit-per-user`,
  hideAssignee: `https://${HELP_PAGE_DOMAIN}/articles/hide-assignee-from-your-customers`,
  hideTags: `https://${HELP_PAGE_DOMAIN}/articles/hide-tags-from-customers`,
  disableComments: `https://${HELP_PAGE_DOMAIN}/articles/disable-comments-for-a-feedback-board`,
  readOnlyBoard: `https://${HELP_PAGE_DOMAIN}/articles/setting-up-read-only-boards`,
  importPosts: `https://${HELP_PAGE_DOMAIN}/articles/importing-posts-into-featureos`,
  changelogWidget: `https://${HELP_PAGE_DOMAIN}/articles/implementing-changelog-as-a-widget-v2`,
  changelogEmbed: `https://${HELP_PAGE_DOMAIN}/articles/embedding-a-changelog`,
  customDomain: `https://${HELP_PAGE_DOMAIN}/articles/mapping-custom-domain-to-featureos`,
  letterBox: `https://${HELP_PAGE_DOMAIN}/articles/submit-posts-via-letterbox`,
  embedBoardWidget: `https://${HELP_PAGE_DOMAIN}/articles/embed-a-board-as-a-widget`,
  statusCustomizations: `https://${HELP_PAGE_DOMAIN}/articles/setting-up-custom-states`,
  standaloneSSO: `https://${HELP_PAGE_DOMAIN}/articles/embed-your-featureos-portal-as-a-stand-alone-page-with-sso`,
  ssoKey: `https://${HELP_PAGE_DOMAIN}/articles/setting-up-single-sign-on-for-your-featureos-portal`,
  ssoHelp: `https://${HELP_PAGE_DOMAIN}/articles/setting-up-single-sign-on-for-your-featureos-portal`,
  ssoRedirect: `https://${HELP_PAGE_DOMAIN}/articles/understanding-sso-redirects`,
  ssoRedirectOneLoginHelp: `https://${HELP_PAGE_DOMAIN}/articles/setting-up-sso-redirect-using-onelogin-via-saml`,
  paddleMigration: `https://${HELP_PAGE_DOMAIN}/articles/smooth-transition-to-paddle-billing-method`,
  embedHeaderHelpPage: `https://${HELP_PAGE_DOMAIN}/articles/how-to-hide-header-for-embeds`,
  jsSDK: `https://${HELP_PAGE_DOMAIN}/articles/using-js-sdk-for-sso`,
  embedNoHeaderHelp: `https://${HELP_PAGE_DOMAIN}/articles/how-to-hide-header-for-embeds`,
  tatSummary: `https://${HELP_PAGE_DOMAIN}/articles/status-turn-around-time-tat-for-posts`,
  ssoEmbedHelp: `https://${HELP_PAGE_DOMAIN}/articles/embed-a-feedback-board`,
  roadmapEmbedHelp: `https://${HELP_PAGE_DOMAIN}/articles/embedding-a-roadmap`,
  ssoRoadmapEmbedHelp: `https://${HELP_PAGE_DOMAIN}/articles/setting-up-single-sign-on-for-your-featureos-portal`,
  embedOriginSettings: `https://${HELP_PAGE_DOMAIN}/articles/trusted-domains-for-iframe-embeds`,
  openIDConnect: `https://${HELP_PAGE_DOMAIN}/articles/setting-up-sso-with-openid-connect`,
  whiteListDomainConfig: `https://${HELP_PAGE_DOMAIN}/articles/guide-to-whitelisting-email-domains`,
  userRole: `https://${HELP_PAGE_DOMAIN}/articles/a-breakdown-of-featureos-user-roles`,
  socialProvidersHelp: `https://${HELP_PAGE_DOMAIN}/articles/configuring-authentication-methods`,
  multipleRoadmapSwitchHelpArticle: `https://${HELP_PAGE_DOMAIN}/articles/setting-roadmap-view-for-your-organization`,
  notificationActualHelpURL: `https://${HELP_PAGE_DOMAIN}/articles/enabledisable-email-notifications`,
  advancedNotificationHelpURL: `https://${HELP_PAGE_DOMAIN}/articles/configuring-advanced-email-notifications`,
  passkeyHelp: `https://${HELP_PAGE_DOMAIN}/articles/using-passkeys-to-sign-into-featureos`,
  analyticsHelp: `https://${HELP_PAGE_DOMAIN}/articles/navigating-the-featureos-analytics-dashboard`,
  userWeeklyEmailNotifications: `https://${HELP_PAGE_DOMAIN}/en/articles/weekly-email-updates-for-customers`,
  smtpSettings: `https://${HELP_PAGE_DOMAIN}/articles/smtp-configuration`,
  whitelistDomain: `https://${HELP_PAGE_DOMAIN}/articles/guide-to-whitelisting-email-domains`,
  starACustomer: `https://${HELP_PAGE_DOMAIN}/en/articles/how-to-star-a-customer`,
  kalDashboard: `https://${HELP_PAGE_DOMAIN}/en/articles/kal-dashboard`,
  jwtToken: `https://${HELP_PAGE_DOMAIN}/en/articles/setting-up-single-sign-on-for-your-featureos-portal#how-to-generate-jwt-on-your-server`,
}

export const integrationHelpLinks: { [key: string]: string } = {
  integrationSlack: `https://${HELP_PAGE_DOMAIN}/articles/slack-integration`,
  integrationIntercom: `https://${HELP_PAGE_DOMAIN}/articles/intercom-integration`,
  integrationZapier: `https://${HELP_PAGE_DOMAIN}/articles/zapier-integration`,
  integrationJira: `https://${HELP_PAGE_DOMAIN}/articles/jira-integration`,
  integrationLinear: `https://${HELP_PAGE_DOMAIN}/articles/linear-integration`,
  integrationAzure: `https://${HELP_PAGE_DOMAIN}/articles/azure-active-directory-integration`,
  integrationZendesk: `https://${HELP_PAGE_DOMAIN}/articles/zendesk-integration`,
  integrationPatreon: `https://${HELP_PAGE_DOMAIN}/articles/linking-patreon-id-with-featureos`,
  integrationChat: `https://${HELP_PAGE_DOMAIN}/articles/integrating-hubspot-chat-widget`,
  integrationClickUp: `https://${HELP_PAGE_DOMAIN}/articles/clickup-integration`,
  integrationGithub: `https://${HELP_PAGE_DOMAIN}/articles/github-integration`,
  integrationPlausible: `https://${HELP_PAGE_DOMAIN}/articles/plausible-integration`,
  integrationSegment: `https://${HELP_PAGE_DOMAIN}/articles/segment-integration`,
  integrationFullStory: `https://${HELP_PAGE_DOMAIN}/articles/fullstory-integration`,
  integrationFathom: `https://${HELP_PAGE_DOMAIN}/articles/fathom-analytics-integration`,
  integrationSalesforce: `https://${HELP_PAGE_DOMAIN}/articles/salesforce-integration`,
  integrationDiscord: `https://${HELP_PAGE_DOMAIN}/articles/discord-integration`,
  integrationAsana: `https://${HELP_PAGE_DOMAIN}/articles/asana-integration`,
}

export const featureOSLogoURL = `https://static.${DOMAIN}/brand/fos_logo.svg`
export const alphaOSLogoURL = `https://assets.alphaos.app/brand/alphaos-icon.svg`

export const jiraMarketplaceURL =
  'https://marketplace.atlassian.com/apps/1227649/hellonext-for-jira'

export const bulkImportLinks = {
  team: `https://vault.${DOMAIN}/templates/team_members_import.csv`,
  post: `https://vault.${DOMAIN}/templates/feature_requests_import.csv `,
  bucketMember: `https://vault.${DOMAIN}/templates/bucket_members_import.csv`,
  customers: `https://vault.${DOMAIN}/templates/customers_import.csv`,
}

export const FALLBACK_ICON_URL = `https://assets.featureos.app/featureos/app-assets/icons/regular/rocket-launch.svg`
