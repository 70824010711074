import { createContext, useContext } from 'react'

import type { IPost } from '@/types/post'

interface IHNAdminPostContextProps extends IPost {
  updateContext: (post: Partial<IPost>) => void
}
// React Contexts
export const HNAdminPostContext =
  createContext<IHNAdminPostContextProps | null>(null)

export const useHNAdminPost = () =>
  useContext(HNAdminPostContext) as IHNAdminPostContextProps
