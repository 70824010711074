import { observer } from 'mobx-react'
import React from 'react'

import AdminPostPriorityFilter from '@/components/admin/posts/filters/AdminPostPriorityFilter'
import PriorityIcon from '@/components/posts/PriorityIcon'
import { useTranslations } from '@/hooks/useTranslations'
import postStore from '@/stores/PostListStore'
import type { IPost, TPostPriority } from '@/types/post'
import toaster from '@/utils/toast'

interface IProps {
  post: IPost
}
const PostInlinePriorityChange = observer(({ post }: IProps) => {
  const t = useTranslations('post')

  const handleChange = (selectedPriority: string[]) => {
    const firstOption = selectedPriority[0] as TPostPriority
    if (!firstOption) return null
    const currentPriority = post.priority
    postStore.updateSinglePost(post.slug, { ...post, priority: firstOption })
    return postStore
      .updatePost(post.slug, { priority: firstOption })
      .then(() => {
        toaster.success({ message: t('messages.priority.success') })
      })
      .catch(() => {
        postStore.updateSinglePost(post.slug, {
          ...post,
          priority: currentPriority,
        })
      })
  }

  return (
    <div className='flex items-center justify-center'>
      <AdminPostPriorityFilter
        placeholder='Select Priority'
        behaviour='select'
        filters={{
          priority: post.priority,
        }}
        multiple={false}
        onChange={(_key, value) => {
          handleChange(value)
        }}
        renderTrigger={() => (
          <div className='rounded p-1 hover:bg-gray5'>
            <PriorityIcon post={post} />
          </div>
        )}
      />
    </div>
  )
})

export default PostInlinePriorityChange
