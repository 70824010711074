/* eslint-disable @typescript-eslint/naming-convention */
import { CaretDown } from '@phosphor-icons/react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import { cleanUserForSegment, recordEvent } from '@/lib/helpers/appHelpers'
import { isNullOrUndefined, objectHasProperty } from '@/lib/helpers/dataHelpers'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import {
  checkVoteLimitExistsAndThrowError,
  getTooltipMessage,
  updateBoardMaxVoteSummary,
  updateBoards,
  updateOrgMaxVoteSummary,
  updateUpvoterAndDownvotersList,
  votedToastMessage,
} from '@/lib/helpers/modules/Post/votesHelper'
import { checkIsUser } from '@/lib/helpers/modules/userHelper'
import { friendlyNumber } from '@/lib/helpers/stringHelpers'
import postStore from '@/stores/PostListStore'
import type { IBoardDetails, IBoardMaxVoteSummary } from '@/types/board'
import type { IOrganizationPlan } from '@/types/organization'
import type { IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

import Tooltip from '../shared/ui/Tooltip'

const iconClassName = 'w-3 h-3 shrink-0'
const buttonClassName =
  'box-border flex cursor-pointer flex-col items-center justify-center gap-0.5 rounded-lg border border-gray7 px-2 py-1.5 hover:no-underline h-auto w-9 text-xs leading-3'
const votedClass = 'bg-primary/5 border border-primary dark:bg-primary/20'
interface IPropTypes {
  post: IPost
  size?: 'sm' | 'md'
  // TODO Make it mandatory when working on admin side
  onUpdate?: (post: IPost) => void
  disabled?: boolean
  classNames?: {
    buttonClassName?: string
    iconClassName?: string
  }
}

export default function DownvoteButton({
  post: _post,
  size,
  onUpdate,
  disabled: _disabled = false,
  classNames = {},
}: IPropTypes) {
  const router = useRouter()
  const t = useTranslations('posts')
  const [disabled, setDisabled] = useState<boolean>(false)
  const [post, setPost] = useState<IPost>(_post)
  const {
    userSignedIn,
    userProfile,
    organizationPlan,
    organizationCustomization,
    buckets,
    max_votes_summary,
    organizationSetting,
    isAdmin,
    updateContext,
  } = useContext(HNContext)
  const {
    can_downvote,
    slug,
    downvoted,
    downvotes_count,
    downvotes_count_number,
  } = post

  const currentPostBucket = useMemo(
    () => buckets?.find((b) => b?.id === post?.bucket_id),
    [post, buckets]
  )

  const count = useMemo(() => {
    if (isNullOrUndefined(downvotes_count_number)) {
      return downvotes_count
    }
    return friendlyNumber(downvotes_count_number || 0)
  }, [downvotes_count, downvotes_count_number])

  const isCountHidden = useMemo(() => {
    if (isAdmin) return false
    return isNullOrUndefined(count) || currentPostBucket?.hide_downvotes_count
  }, [count])

  const globalContextUpdate = (updatedVote: IPost) => {
    if (max_votes_summary && updatedVote.max_votes_summary) {
      const summary = updateOrgMaxVoteSummary(
        // @ts-ignore
        max_votes_summary,
        updatedVote,
        post
      )
      if (updateContext) updateContext({ max_votes_summary: summary })
    }
  }

  const singleBoardContextUpdate = (updatedVote: IPost) => {
    if (!max_votes_summary || !currentPostBucket) return false

    let boardsData = buckets
    if (buckets && currentPostBucket)
      boardsData = updateBoards(buckets, updatedVote, currentPostBucket)

    if (max_votes_summary && updatedVote.max_votes_summary) {
      const summary = updateBoardMaxVoteSummary(
        // @ts-ignore
        max_votes_summary,
        updatedVote,
        post,
        currentPostBucket
      )
      if (updateContext)
        updateContext({ buckets: boardsData, max_votes_summary: summary })
    }
    return true
  }

  const handleUpdateContext = (data: IPost) => {
    if (currentPostBucket?.enable_max_votes) {
      return singleBoardContextUpdate(data)
    }
    return globalContextUpdate(data)
  }

  useEffect(() => {
    setPost(_post)
  }, [_post])

  const handleDownvote = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (!can_downvote)
      return EventEmitter.dispatch('CONFIG_PUSH', {
        action: 'TOGGLE_AUTH_MODAL',
      })
    // Check vote limit exceeds and throw an error
    if (organizationPlan?.enterprise_features_plans) {
      const toasterror = checkVoteLimitExistsAndThrowError(
        post,
        Boolean(userSignedIn),
        userProfile,
        currentPostBucket,
        max_votes_summary,
        t('maxVoteSummary')
      )
      if (toasterror && toasterror.length)
        return toaster.error({ message: toasterror })
    }
    setDisabled(true)

    const currentPostData = { ...post }
    const newDownVoteCount = currentPostData.downvoted
      ? (downvotes_count_number || 0) - 1
      : (downvotes_count_number || 0) + 1
    const upvoters = currentPostData.upvoters || []
    const downvoters = currentPostData.downvoters || []
    const newVoters = currentPostData.upvoted
      ? upvoters.filter((voter) => voter.id !== userProfile?.id)
      : [...upvoters]

    const newDownvoters = currentPostData.downvoted
      ? [...downvoters.filter((voter) => voter.id !== userProfile?.id)]
      : [...downvoters, userProfile as IUserProfile]

    const newVoteCount = currentPostData.upvoted
      ? (currentPostData.votes_count_number || 0) - 1
      : currentPostData.votes_count_number || 0

    const optimisticData = {
      ...currentPostData,
      voting: true,
      downvoted: !currentPostData.downvoted,
      downvotes_count_number: isCountHidden ? null : newDownVoteCount,
      upvoters: newVoters,
      downvoters: newDownvoters,
      upvoted: false,
      votes_count_number: isCountHidden ? null : newVoteCount,
    }
    postStore.updateSinglePost(currentPostData.slug, optimisticData)
    if (onUpdate) onUpdate(optimisticData)

    recordEvent('POST_DOWNVOTE', {
      post_id: currentPostData.id,
      post_slug: currentPostData.slug,
      post_title: currentPostData.title,
      user: cleanUserForSegment(userProfile),
      pathname: router.pathname,
    })
    return postStore
      .vote(slug, 'downvote', !!isAdmin)
      .then((updatedPost) => {
        const newPostData = updateUpvoterAndDownvotersList(
          { ...post, ...updatedPost },
          userProfile,
          'downvote'
        )
        postStore.updateSinglePost(newPostData.slug, {
          ...newPostData,
          voting: false,
        })
        // Toast Message
        toaster.info({
          message: votedToastMessage(
            updatedPost.downvoted,
            Boolean(userSignedIn),
            userProfile,
            organizationCustomization,
            organizationPlan,
            t('posts', { ignorePrefix: true }),
            updatedPost?.max_votes_summary?.user_votes,
            max_votes_summary as IBoardMaxVoteSummary,
            'downvote'
          ),
        })
        if (
          Boolean(
            organizationCustomization?.enable_max_votes &&
              organizationPlan?.enterprise_features_plans
          ) &&
          (checkIsUser(userProfile) || !userSignedIn)
        )
          // Check to update max vote limit
          handleUpdateContext(updatedPost)
        if (onUpdate) onUpdate({ ...newPostData, voting: false })
        return true
      })
      .catch((err) => {
        const postReverseData = {
          ...currentPostData,
          voting: false,
        }
        postStore.updateSinglePost(post.slug, postReverseData)
        if (onUpdate) onUpdate(postReverseData)

        if (err.message.toString().startsWith('ERROR_CODE')) {
          toaster.error({
            message: t(`error.${err.message}`, { ignorePrefix: true }),
          })
        } else {
          toaster.error({
            message: err.message,
          })
        }
      })
      .finally(() => setDisabled(false))
  }

  if (
    (!objectHasProperty(post, 'downvotes_count_number') &&
      !objectHasProperty(post, 'downvotes_count')) ||
    !organizationSetting?.downvotes
  )
    return <></>
  return (
    <Tooltip
      text={getTooltipMessage(
        t('post.messages.votesRemaining', { ignorePrefix: true }),
        currentPostBucket as IBoardDetails,
        max_votes_summary as IBoardMaxVoteSummary
      )}
      show={
        featureIsEnabled(
          'max_votes_per_user',
          organizationPlan as IOrganizationPlan
        ) &&
        organizationCustomization?.enable_max_votes &&
        !organizationPlan?.trial_expired &&
        (checkIsUser(userProfile) || !userSignedIn) &&
        !disabled
      }
      asChild
    >
      <button
        type='button'
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          handleDownvote(event)
        }
        data-testid='post_downvote_button'
        disabled={
          disabled ||
          Boolean(userSignedIn && !can_downvote) ||
          _disabled ||
          post.voting
        }
        className={clsx(
          buttonClassName,
          size === 'sm'
            ? 'w-[50px] !flex-row space-x-1'
            : 'h-[40px] w-[35px] !flex-col-reverse space-x-0 space-x-reverse',
          downvoted ? votedClass : '',
          classNames.buttonClassName
        )}
      >
        <CaretDown className={clsx(iconClassName, classNames.iconClassName)} />
        {!isCountHidden && (
          <span data-testid='post_downvote_counts'>{count}</span>
        )}
      </button>
    </Tooltip>
  )
}
