export const parseStatusSyncData = (data: any, provider: string) => {
  switch (provider) {
    case 'github':
      return {
        statuses: data.statuses,
        sources: data.sync_data.sources,
        when_data: data.sync_data.when_data,
        notify_customers: data.sync_data.notify_customers,
      }
    case 'click_up':
      return {
        statuses: data.config.statuses,
        sources: data.sync_data.sources,
        when_data: data.sync_data.when_data,
        notify_customers: data.sync_data.notify_customers,
      }
    case 'jira':
      return {
        statuses: data.jira_status_list,
      }
    case 'linear':
      return {
        statuses: data.statuses,
        sources: data.sync_data.sources,
        when_data: data.sync_data.when_data,
        notify_customers: data.sync_data.notify_customers,
        teams: data.teams,
      }
    case 'asana':
      return {
        sources: data.sync_data.sources,
        when_data: data.sync_data.when_data,
        notify_customers: data.sync_data.notify_customers,
        projects: data.projects,
      }
    case 'gitlab':
      return {
        statuses: data.statuses,
        sources: data.sync_data.sources,
        when_data: data.sync_data.when_data,
        notify_customers: data.sync_data.notify_customers,
        projects: data.projects,
      }
    default:
      return null
  }
}
