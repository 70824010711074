import type { IKnowledgeBaseCollectionWithChildren } from '@/types/knowledgeBase'

export const findNodesRecursive = (
  node: IKnowledgeBaseCollectionWithChildren,
  condition: any
) => {
  // Check if the current node satisfies the condition
  if (condition(node)) {
    return [node]
  }

  if (node.children && node.children.length > 0) {
    const matchingNodes: IKnowledgeBaseCollectionWithChildren[] = []
    const { children } = node
    for (let i = 0; i < children.length; i += 1) {
      const child = children[i]
      if (child) {
        const childMatches = findNodesRecursive(child, condition)
        matchingNodes.push(...childMatches)
      }
    }
    return matchingNodes
  }

  return []
}
