import type { MutableRefObject } from 'react'
import React from 'react'

interface IPropTypes {
  root?: MutableRefObject<Element | null>
  target: MutableRefObject<Element | null>
  onIntersect: () => any
  threshold?: number
  rootMargin?: string
  enabled?: boolean
}
export default function useIntersectionObserver(
  {
    root,
    target,
    onIntersect,
    threshold = 1.0,
    rootMargin = '0px',
    enabled = true,
  }: IPropTypes,
  dependencies: any[]
) {
  React.useEffect((): any => {
    if (!enabled || typeof window.IntersectionObserver === 'undefined') {
      return undefined
    }
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    )
    const el = target && target.current

    if (!el) {
      return undefined
    }

    observer.observe(el)
    return () => {
      observer.unobserve(el)
      observer.disconnect()
    }
  }, [target.current, enabled, ...dependencies])
}
