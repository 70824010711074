import { objectHasProperty } from '@/lib/helpers/dataHelpers'
import { checkIfDateIsInFuture } from '@/lib/helpers/dateHelpers'
import type { IOrganizationPlan, IPowerUp } from '@/types/organization'

const featureEnableMatrix: {
  [key: string]: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) => boolean
} = {
  sso: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_sso_features ||
    powerups.includes('sso') ||
    options.trialIsEnabled,
  custom_domain: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_basic_features ||
    powerups.includes('custom_domain') ||
    options.trialIsEnabled,
  custom_roadmap: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_basic_features ||
    powerups.includes('custom_roadmap') ||
    options.trialIsEnabled,
  analytics: (powerups: IPowerUp[], plan: IOrganizationPlan, options) =>
    plan.allow_basic_features ||
    powerups.includes('analytics') ||
    options.trialIsEnabled,
  ai_analytics: (_powerups: IPowerUp[], plan: IOrganizationPlan) =>
    plan.allow_basic_features,
  letterbox: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_pro_features ||
    powerups.includes('letterbox') ||
    options.trialIsEnabled,
  canned_responses: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_basic_features ||
    powerups.includes('canned_responses') ||
    powerups.includes('canned_response') ||
    options.trialIsEnabled,
  moderation: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('moderation') ||
    plan.allow_runway_features ||
    options.trialIsEnabled,
  favicon: (_powerups: IPowerUp[], plan: IOrganizationPlan) =>
    plan.allow_pro_features ||
    plan.enterprise_features_plans ||
    !plan.in_grandfather_plan,
  og_image: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('og_image') ||
    plan.enterprise_features_plans ||
    options.trialIsEnabled ||
    !plan.in_grandfather_plan,
  email_whitelabeling: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_pro_features ||
    powerups.includes('show_branded_content') ||
    options.trialIsEnabled,
  smtp_config: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_pro_features ||
    powerups.includes('custom_smtp') ||
    options.trialIsEnabled,
  custom_url: (_powerups: IPowerUp[], plan: IOrganizationPlan) =>
    plan.allow_pro_features ||
    plan.enterprise_features_plans ||
    !plan.in_grandfather_plan,
  team_inbox: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('team_inbox') ||
    options.trialIsEnabled,
  schedule_changelog: (
    _powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_basic_features ||
    plan.allow_runway_features ||
    options.trialIsEnabled,
  api_key: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    (plan.allow_basic_features && plan.slug !== 'free') ||
    powerups.includes('api') ||
    options.trialIsEnabled,
  webhooks: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_pro_features ||
    powerups.includes('webhook') ||
    options.trialIsEnabled,
  whitelist_domain: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_pro_features ||
    powerups.includes('whitelist_domain_config') ||
    options.trialIsEnabled,
  max_votes_per_user: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_pro_features ||
    powerups.includes('vote_limit_per_user') ||
    options.trialIsEnabled,
  otl: (_powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features || options.trialIsEnabled,

  csm: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.enterprise_features_plans ||
    powerups.includes('csm') ||
    options.trialIsEnabled,
  knowledge_base: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_knowledge_base ||
    powerups.includes('knowledge_base') ||
    options.trialIsEnabled,
  guest_votes_rate_limit: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_pro_features ||
    powerups.includes('vote_limit_per_user') ||
    options.trialIsEnabled,
  custom_states: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_pro_features ||
    powerups.includes('custom_states') ||
    options.trialIsEnabled,
  private_board: (
    _powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_basic_features ||
    plan.allow_new_plans_features ||
    options.trialIsEnabled ||
    options.privateBucketsCount > 0,
  changelog_labels: (
    _powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) => {
    if (options?.changelog_labels) {
      return (
        plan.slug === 'freeway' &&
        options?.changelog_labels.length >= 4 &&
        options.isNew
      )
    }
    return false
  },
  js_sdk: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_sso_features ||
    powerups.includes('sso') ||
    options.trialIsEnabled,
  chat_support: (_powerups: IPowerUp[], plan: IOrganizationPlan) =>
    plan.allow_basic_features,

  import_posts: (_powerups: IPowerUp[], plan: IOrganizationPlan) =>
    Boolean(plan.slug !== 'free' && plan.slug !== 'freeway'),

  azure_ad: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_pro_features ||
    powerups.includes('azure_ad') ||
    options.trialIsEnabled,
  click_up: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_pro_features ||
    powerups.includes('click_up') ||
    options.trialIsEnabled,
  rename_module: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_runway_features ||
    powerups.includes('rename_modules') ||
    options.trialIsEnabled,
  github: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('github') ||
    options.trialIsEnabled,
  linear: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('linear') ||
    options.trialIsEnabled,
  zapier: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('zapier') ||
    options.trialIsEnabled,
  patreon: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('patreon') ||
    options.trialIsEnabled,
  jira: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('jira') ||
    options.trialIsEnabled,
  salesforce: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_pro_features ||
    powerups.includes('salesforce') ||
    options.trialIsEnabled,
  intercom: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('intercom') ||
    options.trialIsEnabled,
  slack: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('slack') ||
    options.trialIsEnabled,
  discord: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('discord') ||
    options.trialIsEnabled,
  zendesk: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('zendesk') ||
    options.trialIsEnabled,
  asana: (powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_basic_features ||
    powerups.includes('asana') ||
    options.trialIsEnabled,
  multiple_roadmap: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    powerups.includes('multiple_roadmap') ||
    plan.allow_multiple_roadmaps ||
    options.trialIsEnabled,
  board_member_import: (_powerups: IPowerUp[], plan: IOrganizationPlan) =>
    plan.slug !== 'freeway' && plan.slug !== 'free',

  team_member_import: (_powerups: IPowerUp[], plan: IOrganizationPlan) =>
    plan.slug !== 'freeway' && plan.slug !== 'free',

  export_posts: (_powerups: IPowerUp[], plan: IOrganizationPlan) =>
    plan.slug !== 'freeway' && plan.slug !== 'free',

  // TODO: Need to check on this AI.
  ai: (_powerups: IPowerUp[], plan: IOrganizationPlan, options: any) =>
    plan.allow_pro_features ||
    options.trialIsEnabled ||
    !plan.in_grandfather_plan,

  login_providers: (powerups: IPowerUp[], plan: IOrganizationPlan) =>
    plan.allow_pro_features || powerups.includes('login_methods'),
  advanced_notification: (
    powerUps: IPowerUp[],
    _plan: IOrganizationPlan,
    options: any
  ) => powerUps.includes('advanced_notification') || options.trialIsEnabled,

  ai_changelog: (
    _powerups: IPowerUp[],
    plan: IOrganizationPlan,
    _options: any
  ) => plan.allow_pro_features || !plan.in_grandfather_plan,

  weekly_digest: (
    _powerups: IPowerUp[],
    plan: IOrganizationPlan,
    _options: any
  ) => plan.allow_pro_features,

  ai_feature: (_powerups: IPowerUp[], plan: IOrganizationPlan, _options: any) =>
    plan.allow_basic_features || !plan.in_grandfather_plan,

  kb_ai_suggestions: (
    _powerups: IPowerUp[],
    plan: IOrganizationPlan,
    _options: any
  ) => plan.allow_pro_features,

  user_segmentations: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) =>
    plan.allow_pro_features ||
    powerups.includes('user_segmentations') ||
    options.trialIsEnabled,
  ai_chat: (_powerups: IPowerUp[], plan: IOrganizationPlan) =>
    plan.allow_pro_features && !plan.in_grandfather_plan,
  global_search: (_powerups: IPowerUp[], plan: IOrganizationPlan) =>
    (plan.allow_basic_features && !plan.in_grandfather_plan) ||
    plan.allow_pro_features,
  custom_fields: (powerups: IPowerUp[]) => powerups.includes('custom_fields'),
}

export const featureIsEnabled = (
  feature: string,
  plan: IOrganizationPlan,
  options = {}
) => {
  if (!feature || !plan) return false

  const checkFunction: (
    powerups: IPowerUp[],
    plan: IOrganizationPlan,
    options: any
  ) => boolean =
    featureEnableMatrix[feature] || ((_powerups_plan, _options) => false)

  const powerUps = Object.keys(plan.power_ups) as IPowerUp[]
  const filteredPowerUps = powerUps.filter((powerUp) => {
    if (objectHasProperty(plan.power_ups?.[powerUp], 'limit')) {
      return (plan.power_ups?.[powerUp]?.limit || 0) > 0
    }
    return true
  })

  const trialIsEnabled = checkIfDateIsInFuture(
    Number(plan.trial_modules[feature]?.end_date) * 1000
  )

  return checkFunction(filteredPowerUps, plan, { ...options, trialIsEnabled })
}
