import { LinkBreak } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Badge from '@/components/shared/ui/Badge'
import Button from '@/components/shared/ui/Button'
import NextImage from '@/components/shared/ui/Image/NextImage'
import { settingsHelpLinks } from '@/config/staticURLs'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import { snakeToCamel } from '@/lib/helpers/stringHelpers'
import {
  getPostLinearIssues,
  removePostLinearIssue,
} from '@/models/integration/Linear'
import { getPostAddons, removePostAddon } from '@/models/Post'
import type { IPost } from '@/types/post'
import type { IPostAddon, IPostAddonEvent } from '@/types/post/postAddon'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}
export default function PostAddonsList({ post }: IPropTypes) {
  const t = useTranslations('post.postAddons')
  const { integrations, updateContext } = useContext(HNContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isRemoving, setIsRemoving] = useState(false)
  const [postAddons, setPostAddons] = useState<IPostAddon[]>([])

  const getAddons = async () => {
    setIsLoading(true)

    await getPostAddons(post.slug, {})
      .then((addons) => setPostAddons(addons))
      .catch((err) => {
        if (err.status === 404 && updateContext) {
          updateContext({
            integrations: { ...integrations, can_manage_post_addons: false },
          })
        }
        toaster.error({ message: err.message })
      })

    if (integrations?.can_manage_linear) {
      await getPostLinearIssues(post.slug)
        .then((addons) => {
          const parsedAddons = addons.map((addon: IPostAddon) => ({
            ...addon,
            provider: 'linear',
          }))
          setPostAddons((prev) => [...prev, ...parsedAddons])
        })
        .catch((err) => toaster.error({ message: err.message }))
    }
    setIsLoading(false)
  }

  const handleRemoveAddon = (addon: IPostAddon) => {
    if (addon.provider === 'linear') {
      setIsRemoving(true)
      return removePostLinearIssue(post.slug, addon.id.toString())
        .then(() => {
          const updatedPostAddons = postAddons.filter(
            (_issue) => _issue.id !== addon.id
          )
          setPostAddons(updatedPostAddons)
          if (
            !updatedPostAddons.filter((issue) => issue.provider === 'linear')
              ?.length
          ) {
            EventEmitter.dispatch('RESET_ADDON_PROVIDER_PUSH', {
              provider: addon.provider.toLowerCase(),
              addon: { ...addon, action: 'push' },
              action: 'unlink',
            })
          }
          toaster.success({
            message: t('messages.unlinkSuccessfully', { ignorePrefix: true }),
          })
        })
        .catch((err) => toaster.error({ message: err.message }))
        .finally(() => setIsRemoving(false))
    }
    setIsRemoving(true)
    return removePostAddon(post.slug, addon.id.toString())
      .then(() => {
        const updatedPostAddons = postAddons.filter(
          (_issue) => _issue.id !== addon.id
        )
        setPostAddons(updatedPostAddons)
        if (
          !updatedPostAddons?.filter(
            (issue) => issue.provider === addon.provider
          )?.length
        ) {
          EventEmitter.dispatch('RESET_ADDON_PROVIDER_PUSH', {
            provider: addon.provider.toLowerCase(),
            addon: { ...addon, action: 'push' },
            action: 'unlink',
          })
        }
        toaster.success({
          message: t('messages.unlinkSuccessfully', { ignorePrefix: true }),
        })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsRemoving(false))
  }

  const handleNewPostAddon = (data: IPostAddonEvent) => {
    if (data.provider === 'linear' || data.provider === 'click_up') {
      setPostAddons((prev) => [
        ...prev,
        {
          ...data.addon,
          provider: data.provider === 'linear' ? 'linear' : 'click_up',
        },
      ])
    } else {
      setPostAddons((prev) => [...prev, data.addon])
    }
    EventEmitter.dispatch('RESET_ADDON_PROVIDER_PUSH', data)
  }

  const getPostAddonNumber = (addon: IPostAddon) => {
    if (addon.provider === 'linear') {
      return addon.number
    }
    return `#${addon.number}`
  }

  useEffect(() => {
    EventEmitter.subscribe('NEW_POST_ADDON', handleNewPostAddon)
    if (integrations?.can_manage_post_addons) getAddons()
    return () => {
      EventEmitter.unsubscribe('NEW_POST_ADDON', handleNewPostAddon)
    }
  }, [])

  if (!integrations?.can_manage_post_addons || isLoading) return null

  return (
    <ul className='mb-4 space-y-1.5 empty:hidden'>
      {postAddons.map((postAddon, index) => (
        <li
          className='flex items-center justify-between space-x-2 rounded-md border border-gray5 px-2.5 py-2 transition hover:bg-gray2'
          key={index}
        >
          <div className='flex grow items-center space-x-2 truncate'>
            <NextImage
              src={`${
                settingsHelpLinks.cdnLogo
              }${postAddon.provider?.toLowerCase()}.svg`}
              className={clsx('flex h-4 w-4 shrink-0 transition')}
              width={16}
              height={16}
              alt=''
            />
            <a
              href={postAddon.url}
              className='grow truncate text-xs font-medium text-gray11'
              target='_blank'
              rel='noopener noreferrer'
            >
              <div className='flex items-center justify-between space-x-2'>
                <span className='grow truncate'>
                  {postAddon.number && `${getPostAddonNumber(postAddon)} - `}
                  {postAddon.title}
                </span>
                {postAddon.state && (
                  <Badge
                    customStyle={{
                      backgroundColor: `${postAddon?.state?.color}5`,
                      color: `${postAddon?.state?.color}100`,
                      border: `solid 0.5px ${postAddon?.state?.color}`,
                    }}
                    size='xxs'
                    value={
                      postAddon?.state?.name ||
                      postAddon?.state?.status ||
                      (typeof postAddon?.state === 'string' &&
                        t(`common.labels.${postAddon?.state}`, {
                          ignorePrefix: true,
                          fallback: () => postAddon?.state,
                        }))
                    }
                  />
                )}
              </div>
            </a>
          </div>
          <AlertDialog
            type='danger'
            title={t(
              `${snakeToCamel(postAddon.provider?.toLowerCase())}.unlinkTitle`
            )}
            description={t(
              `${snakeToCamel(
                postAddon.provider?.toLowerCase()
              )}.unlinkDescription`
            )}
            confirmText={t('buttons.unlink', {
              ignorePrefix: true,
            })}
            onConfirm={() => handleRemoveAddon(postAddon)}
          >
            <Button size='xxs' variant='outline' disabled={isRemoving}>
              <LinkBreak weight='regular' className='h-3.5 w-3.5' />
            </Button>
          </AlertDialog>
        </li>
      ))}
    </ul>
  )
}
