import { ListPlus } from '@phosphor-icons/react'
import clsx from 'clsx'
import type { ReactNode } from 'react'
import React from 'react'

import ErrorString from '@/components/shared/common/ErrorString'

export interface IEmptyStateProps {
  title?: string
  description?: string
  descriptionComponent?: ReactNode
  children?: ReactNode
  actionComponent?: ReactNode
  icon?: React.ElementType
  customIcon?: ReactNode
  size?: number
  isFullScreen?: boolean
  dataTestId?: string
  className?: string
}

export default function EmptyState(props: IEmptyStateProps) {
  const {
    title,
    description,
    descriptionComponent,
    children,
    customIcon,
    icon,
    size = 60,
    actionComponent = <></>,
    isFullScreen = false,
    dataTestId = 'empty-state',
    className = '',
  } = props
  const Icon = icon

  return (
    <div
      data-testid={dataTestId}
      className={clsx(
        'flex items-center justify-center px-4 py-12',
        isFullScreen ? 'h-screen' : 'h-full',
        className
      )}
    >
      <div className='text-center'>
        {Icon && (
          <Icon size={size} className='mx-auto text-gray9' weight='duotone' />
        )}
        {!customIcon && !icon && (
          <ListPlus size={size} className='mx-auto text-gray9' />
        )}
        {customIcon ? (
          <div className='flex justify-center py-3'>{customIcon}</div>
        ) : null}

        <div className='max-w-sm space-y-4'>
          {title && (
            <h3 className='mt-2 text-base font-medium text-gray-light'>
              <ErrorString message={title} />
            </h3>
          )}
          {descriptionComponent && (
            <div className='mt-1 text-sm font-medium text-gray-light'>
              {descriptionComponent}
            </div>
          )}
          {description && !descriptionComponent && (
            <p className='mt-1 text-sm font-medium text-gray-light'>
              {description}
            </p>
          )}
          <div className='mt-6 empty:hidden'>{children}</div>
          <div>{actionComponent}</div>
        </div>
      </div>
    </div>
  )
}
