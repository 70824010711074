import { Eye, EyeSlash } from '@phosphor-icons/react'
import React, { useContext, useState } from 'react'

import AdminPostModerationActions from '@/components/admin/posts/moderation/AdminPostModerationActions'
import Alert from '@/components/shared/ui/Alert/Alert'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function SinglePostModerationAlert({ post }: IPropTypes) {
  const t = useTranslations()
  const { userProfile } = useContext(HNContext)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [openHidePostAlertDialog, setOpenHidePostAlertDialog] = useState(false)

  const handleUnhide = () => {
    setDisabled(true)
    return postStore
      .updatePost(post.slug, { hidden: !post.hidden })
      .then(() => toaster.success({ message: t('posts.messages.visible') }))
      .catch(toaster.error)
      .finally(() => setDisabled(false))
  }

  const handleClose = () => {
    setDisabled(true)
    return postStore
      .updatePostStatus(post.slug, { status: 'closed' })
      .then((response) => {
        postStore.updateSinglePost(post.slug, response)
        toaster.success({
          message: t('posts.moderation.postModerations.messages.closed'),
        })
      })
      .catch(toaster.error)
      .finally(() => setDisabled(false))
  }

  const getAlertDescription = () => {
    if (
      post.hidden &&
      Boolean(post.status.value !== 'closed') &&
      post.approval_status === 'approved'
    )
      return t('alerts.postHidden')
    return t('alerts.postUnderModeration')
  }

  const renderRightComponent = () => {
    return (
      <>
        <Button
          icon={post.hidden ? <Eye /> : <EyeSlash />}
          className={'mr-1'}
          variant='text'
          size='xxs'
          disabled={disabled}
          onClick={() => setOpenHidePostAlertDialog(true)}
        >
          {post.hidden ? t('buttons.unhide') : t('buttons.hide')}
        </Button>
        {post.hidden &&
          Boolean(post.status.value !== 'closed') &&
          post.approval_status === 'approved' && (
            <Button
              disabled={disabled}
              size='xxs'
              onClick={handleClose}
              variant='danger'
            >
              {t('posts.labels.moveToClosed')}
            </Button>
          )}
        {post.approval_status === 'pending' && (
          <AdminPostModerationActions post={post} />
        )}
      </>
    )
  }

  if (!userProfile?.is_member_of_organization) return <></>
  return (
    <>
      <Alert
        rounded
        className='mx-auto my-2 max-w-7xl'
        type='warning'
        message={getAlertDescription()}
        rightComponent={renderRightComponent()}
      />
      <AlertDialog
        type='danger'
        open={openHidePostAlertDialog}
        onOpen={() => setOpenHidePostAlertDialog(true)}
        onClose={() => setOpenHidePostAlertDialog(false)}
        confirmText={
          post.hidden
            ? t('buttons.unhide', { ignorePrefix: true })
            : t('buttons.hide', { ignorePrefix: true })
        }
        cancelText={t('buttons.cancel', { ignorePrefix: true })}
        title={
          post.hidden
            ? t('post.unHidePost.alertTexts.title')
            : t('post.hidePost.alertTexts.title')
        }
        description={
          post.hidden
            ? t('post.unHidePost.alertTexts.description')
            : t('post.hidePost.alertTexts.description')
        }
        onConfirm={() => handleUnhide()}
        onCancel={() => setOpenHidePostAlertDialog(false)}
      />
    </>
  )
}
