import type { MeiliSearch, Meilisearch } from 'meilisearch'
import type { IKeyValueMap } from 'mobx'
import { makeAutoObservable } from 'mobx'

import { PAGINATION_LIMIT } from '@/config/appConstants'
import { SEARCH_INDEXES } from '@/context/HNSearchContext'
import { dbChangelogFilterToSearchFilter } from '@/lib/helpers/modules/searchHelper'
import { getRecentArticles } from '@/models/KnowledgeBase'
import type {
  IKnowledgeBaseArticle,
  IKnowledgeBaseArticleListParams,
} from '@/types/knowledgeBase'

class KnowledgebaseStore {
  articles: IKnowledgeBaseArticle[] = []

  loading: boolean = true

  globalFilters: IKnowledgeBaseArticleListParams = { page: 1 }

  filters: IKnowledgeBaseArticleListParams = { page: 1 }

  canFetchMore: boolean = false

  searchClient: Meilisearch | null = null

  counts: any = {}

  controller = new AbortController()

  constructor() {
    makeAutoObservable(this)
  }

  setSearchClient = (client: MeiliSearch) => {
    this.searchClient = client
  }

  setGlobalFilters(filters: IKnowledgeBaseArticleListParams) {
    this.globalFilters = filters
  }

  updateFilters(filters?: IKnowledgeBaseArticleListParams) {
    this.filters = { ...this.filters, ...(filters || {}) }
    this.list()
  }

  updatePage = (incrementor: number) => {
    this.updateFilters({ page: (this.filters.page || 1) + incrementor })
  }

  resetFilters() {
    this.filters = { page: 1 }
  }

  setArticles(articles: IKnowledgeBaseArticle[]) {
    this.articles = articles
  }

  searchArticles(appliedFilters: IKeyValueMap) {
    const { query, ...restFilters } = appliedFilters
    if (!this.searchClient) return Promise.resolve()
    this.loading = true
    return this.searchClient
      .index<IKnowledgeBaseArticle>(SEARCH_INDEXES.Article)
      .search<IKnowledgeBaseArticle>(query, {
        filter: dbChangelogFilterToSearchFilter(restFilters),
        attributesToHighlight: ['title', 'description'],
        limit: PAGINATION_LIMIT.adminPostsList,
        offset:
          ((this.filters.page || 0) - 1) * PAGINATION_LIMIT.adminPostsList,
      })
      .then(({ hits, estimatedTotalHits }) => {
        // const searchHits = hits.map(cleanChangelogSearchResults)
        const searchHits = hits
        if (this.filters.page === 1) {
          this.articles = searchHits
        } else {
          this.articles = [...(this.articles || []), ...searchHits]
        }
        this.loading = false
        this.canFetchMore = estimatedTotalHits > this.articles?.length
        this.counts = {
          search: estimatedTotalHits,
        }
        return hits
      })
  }

  list() {
    this.canFetchMore = false
    const appliedFilters = {
      ...this.filters,
      ...this.globalFilters,
    }
    if (this.loading) {
      this.controller.abort()
      this.controller = new AbortController()
      this.loading = false
    }

    if (appliedFilters.query) {
      return this.searchArticles(appliedFilters)
    }

    if (this.filters.page === 1) {
      this.articles = []
    }
    this.loading = true
    return getRecentArticles(appliedFilters, {
      signal: this.controller.signal,
    }).then((newArticles: IKnowledgeBaseArticle[]) => {
      this.articles =
        this.filters.page === 1
          ? newArticles
          : [...this.articles, ...newArticles]
      this.loading = false
      this.canFetchMore = !(newArticles.length < 30)
      return newArticles
    })
  }
}

const knowledgebaseStore = new KnowledgebaseStore()

export default knowledgebaseStore
