import type { AxiosRequestConfig } from 'axios'
import type { IKeyValueMap } from 'mobx'

import {
  ADMIN_COMMENTS_BULK_ACTIONS_PATH,
  ADMIN_COMMENTS_COUNTS_PATH,
  ADMIN_POST_COMMENT_PATH,
  CANNED_RESPONSES_PATH,
  COMMENT_MODERATION_LIST_PATH,
  POST_COMMENT_MANAGE_PATH,
  POST_COMMENT_PATH,
  POST_COMMENT_REACTION_PATH,
  POST_COMMENTS_PATH,
} from '@/config/routes'
import API from '@/lib/API'
import {
  getIdxDBData,
  removeIdxDBData,
  setIdxDBData,
} from '@/lib/localDB/localDB'
import type {
  IComment,
  ICommentBulkSelectConfig,
  ICommentReactAPIParams,
  ICreateCommentData,
} from '@/types/comment'

import type { ICannedResponseListAPIParams } from './CannedResponse'

// Comments
export const getPostComments = (
  slug: string,
  params: any
): Promise<IComment[]> => {
  return API.get(POST_COMMENTS_PATH(slug), params)
    .then((data) => [...data.pinned_comments, ...data.unpinned_comments])
    .then((data) => {
      return params.page === 1
        ? removeIdxDBData('POST_COMMENTS', slug, 'slug')
            .then(() =>
              setIdxDBData(
                'POST_COMMENTS',
                slug,
                {
                  comments: data,
                  slug,
                },
                'slug'
              )
            )
            .then(() => data)
        : data
    })
}
export const getPostCommentsFromDB = (slug: string): Promise<IComment[]> => {
  return getIdxDBData('POST_COMMENTS', slug, 'slug')
    .then((data) => {
      if (!data) return []
      return data.comments || []
    })
    .catch(() => [])
}

export const updateCommentInDB = (
  slug: string,
  comment: IComment
): Promise<IComment> => {
  return getIdxDBData('POST_COMMENTS', slug, 'slug').then((data) => {
    if (!data) return comment
    const comments = data.comments.map((item: IComment) => {
      return item.id === comment.id
        ? {
            ...item,
            ...comment,
          }
        : item
    })
    return removeIdxDBData('POST_COMMENTS', slug, 'slug')
      .then(() =>
        setIdxDBData(
          'POST_COMMENTS',
          slug,
          {
            comments,
            slug,
          },
          'slug'
        )
      )
      .then(() => comment)
  })
}

export const addPostComments = (
  slug: string,
  commentData: ICreateCommentData
): Promise<IComment> => {
  return API.post(POST_COMMENTS_PATH(slug), commentData).then(
    (comment: IComment) =>
      getIdxDBData('POST_COMMENTS', slug, 'slug').then((data) => {
        if (!data) return comment
        const comments = [...data.comments].map((item: IComment) => {
          if (item.id === comment.id) {
            return {
              ...item,
              ...comment,
            }
          }
          return item
        })
        return setIdxDBData(
          'POST_COMMENTS',
          slug,
          {
            comments,
            slug,
          },
          'slug'
        ).then(() => comment)
      })
  )
}

export const updatePostComment = (
  slug: string,
  commentId: string,
  commentData: ICreateCommentData
): Promise<IComment> => {
  return API.put(POST_COMMENT_PATH(slug, commentId), commentData).then(
    (comment) => updateCommentInDB(slug, comment)
  )
}

// TODO Check if we can modify the API to react without post slug
export const commentReaction = (
  postSlug: string,
  commentId: string,
  data: ICommentReactAPIParams
) =>
  API.post(POST_COMMENT_REACTION_PATH(postSlug, commentId), data).then(
    (comment) => updateCommentInDB(postSlug, comment)
  )

export const deleteComment = (postSlug: string, commentId: string) => {
  // TODO Check if we can modify the API to delete without post slug
  return API.delete(POST_COMMENT_PATH(postSlug, commentId)).then(
    (deletedComment) => {
      return removeIdxDBData('POST_COMMENTS', postSlug, 'slug').then(
        () => deletedComment
      )
    }
  )
}

export const updateComment = (
  postSlug: string,
  commentId: string,
  comment: IKeyValueMap,
  asAdmin?: boolean
) => {
  // TODO Check if we can modify the API to delete without post slug
  const route = asAdmin ? POST_COMMENT_MANAGE_PATH : POST_COMMENT_PATH
  return API.put(route(postSlug, commentId), comment)
}

export const getCannedResponses = (params: ICannedResponseListAPIParams) => {
  return API.get(CANNED_RESPONSES_PATH, params)
}

export const getCommentModerationItems = (
  queryParams: IKeyValueMap,
  otherOptions: AxiosRequestConfig = {}
): Promise<IComment[]> =>
  API.get(COMMENT_MODERATION_LIST_PATH, { ...queryParams }, otherOptions)

export const updateAdminComment = (
  postSlug: string,
  id: string,
  data: IKeyValueMap
) => {
  return API.put(ADMIN_POST_COMMENT_PATH(postSlug, id), data)
}

export const getCommentsCount = (type: string) => {
  return API.get(ADMIN_COMMENTS_COUNTS_PATH, { type }).then(
    (data) => data.counts_data.counts
  )
}

export const adminBulkUpdateComments = (data: ICommentBulkSelectConfig) => {
  return API.put(ADMIN_COMMENTS_BULK_ACTIONS_PATH, data)
}
export const adminBulkDeleteComments = (data: ICommentBulkSelectConfig) => {
  return API.delete(ADMIN_COMMENTS_BULK_ACTIONS_PATH, data)
}
