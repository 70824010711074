import { useRouter } from 'next/router'
import React, { useContext, useEffect, useRef } from 'react'

import HNContext from '@/context/HNContext'
import { getSubdomain } from '@/lib/helpers/appHelpers'
import { syncInit } from '@/lib/syncEngine/websocket/syncEngine'

export default function RealTimeUpdate() {
  const router = useRouter()
  const unsubscribe = useRef<Function | null>(null)
  const { userProfile, session } = useContext(HNContext)

  useEffect(() => {
    try {
      if (
        !!userProfile &&
        !router.asPath.includes('/changelogwidget') &&
        !router.asPath.includes('/postwidget') &&
        session &&
        session.toString().length
      ) {
        const { subdomain: _subdomain } = getSubdomain(window.location.hostname)
        syncInit({
          orgDomain: _subdomain,
          session: session as string,
          channel: userProfile?.is_csm_of_organization
            ? 'ResourceSyncChannel'
            : 'CustomerResourceSyncChannel',
        }).then((unsub) => {
          unsubscribe.current = unsub
        })
      }
    } catch (e) {
      console.error('Error in initializing sync engine', e)
    }
    return () => {
      try {
        if (unsubscribe.current) {
          if (typeof unsubscribe.current === 'function') unsubscribe.current()
        }
      } catch (e) {
        console.error('Error in unsubscribing sync engine', e)
      }
    }
  }, [])

  return <></>
}
