import { Bell, GearSix, User } from '@phosphor-icons/react'
import { useRouter } from 'next/router'
import React, { useContext } from 'react'

import Avatar from '@/components/shared/ui/Avatar'
import NavLink from '@/components/shared/ui/Links/NavLink'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import clsx from '@/lib/clsxm'
import { userNotificationsPath } from '@/lib/helpers/pathHelpers'

import UserSideUserAuthButton from './UserSideUserAuthButton'

export default function UserProfileHeader() {
  const { organization } = useContext(HNContext)
  const t = useTranslations()
  const router = useRouter()

  const isActive = (path: string) => {
    return router.pathname === path
  }
  const notificationPath = userNotificationsPath()
  return (
    <header
      className={clsx(
        'sticky top-0 z-[2] border-b border-gray4/50 bg-layoutBase bg-opacity-50 bg-blend-overlay backdrop-blur-3xl transition dark:bg-[#1c1d1f]/10'
      )}
    >
      <div className={clsx('mx-auto max-w-7xl')}>
        <div
          className={clsx(
            'flex w-full items-center justify-between px-2 py-2 md:px-4'
          )}
        >
          <div
            className={clsx(
              'group flex min-w-0 items-center justify-between truncate focus:outline-none'
            )}
          >
            <NavLink
              href='/'
              className={clsx('flex items-center space-x-2')}
              activeClass='text-primary'
            >
              <>
                <span className={clsx('h-8 w-8')}>
                  <Avatar
                    url={organization?.avatar?.thumb_url || ''}
                    name={organization?.name || ''}
                    rounded
                  />
                </span>
                <span
                  className={clsx(
                    'mb-0 ml-2 truncate text-base font-semibold text-gray12'
                  )}
                >
                  {organization?.name}
                </span>
              </>
            </NavLink>
          </div>
          <UserSideUserAuthButton />
        </div>
        <div
          className={clsx(
            'flex items-center justify-center space-x-2 pt-1 md:px-3'
          )}
        >
          <div
            className={clsx(
              'flex w-full items-center justify-between py-0.5 pr-1'
            )}
          >
            <div
              className={clsx(
                'flex items-center overflow-x-auto scrollbar-hide md:justify-start'
              )}
            >
              <UnstyledLink
                href='/profile/[[...profileTab]]'
                className={clsx(
                  'hn-animate-duration group flex items-center space-x-1 px-2 py-1 text-sm font-medium text-gray8 hover:!text-primary',
                  isActive('/profile/[[...profileTab]]') ? '!text-primary' : ''
                )}
              >
                <>
                  <User className='h-3 w-3' />
                  <Typography.Text
                    className={clsx(
                      'max-w-xs truncate !text-[12px] group-hover:text-primary',
                      isActive('/profile/[[...profileTab]]')
                        ? '!text-primary'
                        : ''
                    )}
                  >
                    {t('profile.headerTabs.profile')}
                  </Typography.Text>
                </>
              </UnstyledLink>
              <UnstyledLink
                href={notificationPath}
                className={clsx(
                  'hn-animate-duration group flex items-center space-x-1 px-2 py-1 text-sm font-medium text-gray8 hover:!text-primary',
                  isActive('/profile/notifications/[[...notificationsType]]')
                    ? '!text-primary'
                    : ''
                )}
              >
                <>
                  <Bell className='h-3 w-3' />
                  <Typography.Text
                    className={clsx(
                      'max-w-xs truncate !text-[12px] group-hover:text-primary',
                      isActive(
                        '/profile/notifications/[[...notificationsType]]'
                      )
                        ? '!text-primary'
                        : ''
                    )}
                  >
                    {t('profile.headerTabs.notifications')}
                  </Typography.Text>
                </>
              </UnstyledLink>
              <UnstyledLink
                href='/profile/settings'
                className={clsx(
                  'hn-animate-duration group flex items-center space-x-1 px-2 py-1 text-sm font-medium text-gray8 hover:!text-primary',
                  isActive('/profile/settings') ? '!text-primary' : ''
                )}
              >
                <>
                  <GearSix className='h-3 w-3' />
                  <Typography.Text
                    className={clsx(
                      'max-w-xs truncate !text-[12px] group-hover:text-primary',
                      isActive('/profile/settings') ? '!text-primary' : ''
                    )}
                  >
                    {t('profile.headerTabs.settings')}
                  </Typography.Text>
                </>
              </UnstyledLink>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
