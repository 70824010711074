import clsx from 'clsx'
import React, { useMemo } from 'react'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/shared/ui/Accordion/Accordion'
import Tooltip from '@/components/shared/ui/Tooltip'
import { groupBy } from '@/lib/helpers/dataHelpers'

import SidebarItem from './SidebarItem'
import type {
  ISidebarGroups,
  ISidebarListNavItem,
  ISidebarNavItem,
} from './types'

export interface IApp {
  key: string
  icon: React.ReactNode
  onClick?: () => void
  href?: string
  isActive: boolean
  name: string
  wrapper?: React.FC<any>
  appRenderer?: (app: IApp) => React.ReactNode
  noToolTip?: boolean
}

export interface IPropTypes {
  open?: boolean
  header?: React.ReactNode
  actions?: React.ReactNode
  footer?: React.ReactNode
  appFooter?: React.ReactNode
  appHeader?: React.ReactNode
  sidebarItems: ISidebarListNavItem[]
  sidebarGroups: ISidebarGroups
  apps?: IApp[]
  footerApps?: IApp[]
  onClose?: () => void
}

const Sidebar = (props: IPropTypes) => {
  const {
    open = true,
    header,
    footer,
    actions,
    sidebarItems,
    sidebarGroups,
    apps,
    appHeader = null,
    appFooter = null,
    footerApps = [],
  } = props

  const itemsWithoutGroup = useMemo(
    () => sidebarItems.filter((item) => !item.group),
    [sidebarItems]
  )

  const groups = useMemo(() => {
    const filteredItem = sidebarItems.filter((item) => item.group)
    const groupedItems = groupBy(filteredItem, 'group')

    const groupedItemsKeys = Object.keys(groupedItems)
    return groupedItemsKeys.map((key) => ({
      ...sidebarGroups[key],
      key,
      items: groupedItems[key] || [],
    }))
  }, [sidebarItems, sidebarGroups])

  const openedMenu = useMemo(
    () => Object.keys(sidebarGroups).filter((key) => sidebarGroups[key]?.open),
    [sidebarGroups]
  )

  const renderGroupItem = (item: ISidebarListNavItem) => {
    return <SidebarItem item={item as ISidebarNavItem} key={item.key} />
  }

  const renderGroup = (group: any) => {
    if (!group.items.length) return null
    return (
      <AccordionItem value={group.key} key={group.key}>
        <AccordionTrigger className='group'>
          <p className='truncate px-1.5 py-1 text-[12px] font-semibold leading-4 !text-gray12 group-hover:text-primary'>
            {group.label}
          </p>
        </AccordionTrigger>
        <AccordionContent>{group.items.map(renderGroupItem)}</AccordionContent>
      </AccordionItem>
    )
  }

  const renderAppIcon = (app: IApp) => {
    if (app.appRenderer) return app.appRenderer(app)
    const Component = app.wrapper || 'a'
    const content = (
      <div
        key={`${app.key}icon`}
        className={clsx(
          'hover:text-foreground my-1 flex h-[32px] w-[32px] cursor-pointer items-center justify-center gap-2 rounded-lg opacity-70 hover:bg-elementHighlight hover:opacity-100',
          app.isActive && app
            ? '-mx-2 bg-elementHighlight text-primary !opacity-100'
            : 'border-transparent'
        )}
        onClick={app.onClick}
      >
        {app.icon}
      </div>
    )

    if (app.href) return <Component href={app.href}>{content}</Component>
    return content
  }

  const renderApps = () => {
    return (
      <div className='flex h-full w-[44px] flex-col items-center justify-between bg-gray3 !text-gray12'>
        {appHeader}
        <div className='flex flex-1 flex-col items-center'>
          {apps?.map((app, index) => {
            return app.noToolTip ? (
              <div key={app.key || `${app.href}-${index}`}>
                {renderAppIcon(app)}
              </div>
            ) : (
              <Tooltip
                asChild
                text={app.name}
                placement='right'
                key={app.key || `${app.href}-${index}`}
              >
                {renderAppIcon(app)}
              </Tooltip>
            )
          })}
        </div>
        <div className='flex flex-1 flex-col items-center justify-end'>
          {footerApps?.map((app, index) => {
            return app.noToolTip ? (
              <div key={app.key || `${app.href}-${index}`}>
                {renderAppIcon(app)}
              </div>
            ) : (
              <Tooltip
                asChild
                text={app.name}
                placement='right'
                key={app.key || `${app.href}-${index}`}
              >
                {renderAppIcon(app)}
              </Tooltip>
            )
          })}
          {appFooter}
        </div>
      </div>
    )
  }

  return (
    <div
      className={clsx(
        'flew-row fixed z-[5] flex md:relative ',
        open ? 'transition-[274px]' : 'hidden'
      )}
    >
      {apps && <div className='flex'>{renderApps()}</div>}
      <aside
        style={{
          maxWidth: '240px',
          width: '230px',
          display: sidebarItems?.length ? 'block' : 'none',
        }}
        className={clsx('h-screen md:z-0 md:shrink-0')}
      >
        <div className='relative flex h-full w-full flex-1 flex-col border-r border-gray4 bg-snow dark:bg-gray1'>
          {header && <>{header}</>}
          <div className='relative flex flex-1 scroll-pt-10 flex-col overflow-y-auto scrollbar-hide'>
            <nav className='mt-0 h-full flex-1 px-4'>
              <div className='flex flex-1 flex-col'>
                {!!actions && <div className='py-4'>{actions}</div>}
                {itemsWithoutGroup.length ? (
                  <div className='mb-3'>
                    {itemsWithoutGroup.map(renderGroupItem)}
                  </div>
                ) : null}
                <Accordion type='multiple' defaultValue={openedMenu}>
                  {groups.map(renderGroup)}
                </Accordion>
              </div>
            </nav>
          </div>
          {footer && <>{footer}</>}
        </div>
      </aside>
    </div>
  )
}

Sidebar.displayName = 'Sidebar'
export default Sidebar
