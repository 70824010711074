import clsx from 'clsx'
import React, { useState } from 'react'

import { useTranslations } from '@/hooks/useTranslations'
import {
  doesFilesContainsFolder,
  fileListToFileArray,
} from '@/lib/helpers/dataHelpers'
import toaster from '@/utils/toast'

interface IPropTypes {
  onDrop: (files: File[]) => void
  children?: React.ReactNode
  disabled?: boolean
}
export default function Dropzone({
  onDrop,
  children,
  disabled = false,
}: IPropTypes) {
  const t = useTranslations()

  const [isDragging, setIsDragging] = useState(false)

  const dragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!isDragging) setIsDragging(true)
  }

  const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (!isDragging) setIsDragging(true)
  }

  const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (isDragging) setIsDragging(false)
  }

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (isDragging) setIsDragging(false)
    // If folder is dropped, get the files from the folder

    const files = fileListToFileArray(e.dataTransfer.files)
    const containsFolder = doesFilesContainsFolder(e.dataTransfer.files)
    if (containsFolder) {
      toaster.error({
        message: t('common.extAttachments.uploadArea.folderError'),
      })
    }

    return onDrop(files)
  }

  return (
    <div
      onDragOver={!disabled ? dragOver : undefined}
      onDragEnter={!disabled ? dragEnter : undefined}
      onDragLeave={!disabled ? dragLeave : undefined}
      onDrop={!disabled ? handleOnDrop : undefined}
      className={clsx(isDragging ? 'bg-gray3' : '')}
    >
      {children}
    </div>
  )
}
