import { SpinnerGap } from '@phosphor-icons/react'
import { type VariantProps } from 'class-variance-authority'
import clsx from 'clsx'
import type { LinkProps } from 'next/link'
import type { FC } from 'react'
import React, { memo, useMemo } from 'react'

import { buttonVariants } from './button.variants'

export type IButtonSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export type IButtonVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'link'
  | 'text'
  | 'naked'
  | 'warning'
  | 'success'
  | 'danger'
  | 'dashed'

export type ButtonProps = React.HTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    className?: string
    children?: React.ReactNode
    disabled?: boolean
    onClick?: React.MouseEventHandler<HTMLButtonElement>
    icon?: React.ReactNode
    iconRight?: React.ReactNode
    loading?: boolean
    size?: IButtonSize
    variant?: IButtonVariant
    danger?: boolean
    type?: 'button' | 'submit' | 'reset'
    ref?: React.Ref<HTMLButtonElement>
    fab?: boolean
    textAlign?: 'left' | 'center' | 'right'
    as?: keyof JSX.IntrinsicElements | FC<any>
    href?: string | LinkProps['href']
    rounded?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'
    target?: string
    isResponsive?: boolean
    truncate?: boolean
    htmlFor?: string
    dataTestId?: string
    block?: boolean
    style?: React.CSSProperties
    tabIndex?: number
  }

const CustomButton = memo(
  ({
    as,
    ...props
  }: {
    as: keyof JSX.IntrinsicElements | FC<any>
  } & React.HTMLAttributes<HTMLOrSVGElement>) => {
    const Tag = as as keyof JSX.IntrinsicElements
    return <Tag {...props} />
  }
)

CustomButton.displayName = 'CustomButton'

interface RenderedButtonProps extends Omit<ButtonProps, 'as'> {
  as?: keyof JSX.IntrinsicElements | FC<any>
  buttonClasses: string
  children: React.ReactNode
}

const RenderedButton = memo(
  ({
    as,
    buttonClasses,
    children,
    disabled,
    onClick,
    style,
    dataTestId,
    ...props
  }: RenderedButtonProps) => {
    const commonProps = {
      'data-testid': dataTestId,
      className: clsx(
        buttonClasses,
        disabled ? 'cursor-not-allowed decoration-current opacity-50' : ''
      ),
      onClick,
      style,
      ...props,
    }

    if (as) {
      return (
        <CustomButton as={as} {...commonProps}>
          {children}
        </CustomButton>
      )
    }

    return (
      <button {...commonProps} disabled={disabled}>
        {children}
      </button>
    )
  }
)

RenderedButton.displayName = 'RenderedButton'

const Button: FC<ButtonProps> = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(
  (
    {
      className,
      children,
      danger,
      disabled = false,
      onClick,
      icon,
      iconRight,
      loading = false,
      size = 'sm',
      variant = 'primary',
      type,
      tabIndex,
      as,
      textAlign = 'center',
      rounded = 'md',
      fab,
      isResponsive = false,
      truncate = false,
      dataTestId,
      block,
      style,
      ...props
    },
    ref
  ) => {
    const renderButtonChildren = useMemo(() => {
      if (loading) {
        return (
          <SpinnerGap
            size={12}
            className={clsx(
              'shrink-0 animate-spin py-0.5',
              loading && 'm-auto h-auto w-auto'
            )}
            aria-hidden='true'
          />
        )
      }
      if (!children) return null
      return (
        <span
          className={clsx(
            isResponsive ? 'hidden md:block' : '',
            truncate ? 'truncate' : ''
          )}
        >
          {children}
        </span>
      )
    }, [loading, children, isResponsive, truncate])

    const buttonClasses = useMemo(
      () =>
        buttonVariants({
          variant: danger ? 'danger' : variant,
          size,
          rounded,
          fab,
          isResponsive,
          block,
          textAlign,
          className,
        }),
      [
        danger,
        variant,
        size,
        rounded,
        fab,
        isResponsive,
        block,
        textAlign,
        className,
      ]
    )

    const ariaLabel = useMemo(() => {
      if (typeof children === 'string') return children
      if (icon && !children) return 'Button with icon'
      return undefined
    }, [children, icon])

    return (
      <RenderedButton
        ref={ref}
        as={as}
        buttonClasses={buttonClasses}
        disabled={loading || disabled}
        onClick={onClick}
        style={style}
        type={type}
        tabIndex={tabIndex}
        dataTestId={dataTestId}
        aria-label={ariaLabel}
        aria-busy={loading}
        {...props}
      >
        {icon && !loading && icon}
        {renderButtonChildren}
        {iconRight && !loading && iconRight}
      </RenderedButton>
    )
  }
)

Button.displayName = 'Button'

export default Button
