import { Bell, BellRinging, BellSlash } from '@phosphor-icons/react'
import React, { useContext, useState } from 'react'

import AuthenticatedView from '@/components/auth/shared/AuthenticatedView'
import Button from '@/components/shared/ui/Button'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import { cleanUserForSegment, recordEvent } from '@/lib/helpers/appHelpers'
import { subscribeETC, subscribeUserAction } from '@/models/Post'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function SinglePostUserSubscribeButton({ post }: IPropTypes) {
  const t = useTranslations('post')
  const { userProfile, customStatus } = useContext(HNContext)
  const [isSubscribing, setIsSubscribing] = useState<boolean>(false)
  const [isSubscribingETC, setIsSubscribingETC] = useState(false)

  const handleUserSubscribe = () => {
    if (!userProfile) {
      return EventEmitter.dispatch('CONFIG_PUSH', {
        action: 'TOGGLE_AUTH_MODAL',
      })
    }
    setIsSubscribing(true)
    // update post before api call
    recordEvent('POST_SUBSCRIBE', {
      post_id: post?.id,
      post_slug: post?.slug,
      post_title: post?.title,
      user: cleanUserForSegment(userProfile),
    })
    postStore.updateSinglePost(post.slug, {
      ...post,
      subscribed: !post.subscribed,
    })
    return subscribeUserAction(post.slug)
      .then((response) => {
        postStore.updateSinglePost(post.slug, response)
        return toaster.success({
          message: response.subscribed
            ? t('messages.subscribeUser.userSubscribed')
            : t('messages.subscribeUser.userUnsubscribed'),
        })
      })
      .catch((err) => {
        // update post api call fails
        postStore.updateSinglePost(post.slug, {
          ...post,
        })
        toaster.error({
          message: err.message,
        })
      })
      .finally(() => setIsSubscribing(false))
  }

  const handleETCSubscribe = () => {
    if (!userProfile) {
      return EventEmitter.dispatch('CONFIG_PUSH', {
        action: 'TOGGLE_AUTH_MODAL',
      })
    }
    setIsSubscribingETC(true)
    postStore.updateSinglePost(post.slug, {
      ...post,
      etc_subscribed: !post.etc_subscribed,
    })
    return subscribeETC(post.slug)
      .then((response) => {
        postStore.updateSinglePost(post.slug, response)
        toaster.success({
          message: response.etc_subscribed
            ? t('messages.subscribeUser.notifySubscribed')
            : t('messages.subscribeUser.notifyUnsubscribed', {
                data: { statusCompleted: customStatus?.completed },
              }),
        })
      })
      .catch((err) => {
        postStore.updateSinglePost(post.slug, {
          ...post,
        })
        toaster.error({ message: err.message })
      })
      .finally(() => setIsSubscribingETC(false))
  }

  const renderSubscribeETCButton = () => {
    const subscribeButton = (
      <Button
        truncate
        disabled={isSubscribing}
        onClick={handleUserSubscribe}
        size='xs'
        icon={<Bell />}
      >
        {post?.subscribed
          ? t('fields.subscribeUser.unSubscribeButtonText')
          : t('fields.subscribeUser.subscribeButtonText')}
      </Button>
    )
    if (post.etc_scheduled && post.etc_date) {
      return (
        <>
          <Button
            truncate
            disabled={isSubscribingETC}
            onClick={handleETCSubscribe}
            size='xs'
            variant={'default'}
            icon={
              post.etc_subscribed ? (
                <BellRinging weight='bold' />
              ) : (
                <BellSlash weight='bold' />
              )
            }
          >
            {t('messages.subscribeUser.notifySubscribe', {
              data: {
                status_completed: customStatus?.completed,
              },
            })}
          </Button>
        </>
      )
    }
    return subscribeButton
  }

  return (
    <AuthenticatedView
      shouldShow={Boolean(!userProfile?.is_admin_of_organization)}
    >
      <div className='flex flex-col gap-4 py-2 empty:hidden'>
        <div>
          <Typography.Title level={5} className='!font-semibold'>
            {t('messages.subscribeUser.title')}
          </Typography.Title>
          <p className='text-gray10'>
            {t('messages.subscribeUser.description')}
          </p>
        </div>

        <div>{renderSubscribeETCButton()}</div>
      </div>
    </AuthenticatedView>
  )
}
