export const APP_PATHS = {
  DASHBOARD_HOME: '/admin',
  DASHBOARD_KB_ARTICLES: '/admin/kb',

  DASHBOARD_ASSIGNED: '/admin/assigned',
  DASHBOARD_TEAMINBOX: '/admin/teaminbox',
  DASHBOARD_FILTERS: '/admin/filters',
  DASHBOARD_MODERATION_POSTS: '/admin/moderation/posts',
  DASHBOARD_MODERATION_COMMENTS: '/admin/moderation/comments',
  DASHBOARD_BOARDS: '/admin/boards',
  DASHBOARD_R: '/admin/r',
  DASHBOARD_CHANGELOG: '/admin/changelog',
  DASHBOARD_CHANGELOG_SINGLE: (slug: string) => `/admin/changelog/${slug}`,
  DASHBOARD_KB: '/admin/kb',
  DASHBOARD_USERS: '/admin/users',
  DASHBOARD_ACTIVITY: '/admin/activity',
  DASHBOARD_DASHBOARD: '/admin/dashboard',
  DASHBOARD_TEAM: '/admin/settings/team',
  DASHBOARD_SETTINGS: '/admin/settings',
  DASHBOARD_SETTINGS_BILLING: '/admin/settings/billing',
  KB_ARTICLE_GENERATE: '/admin/kb/generate',

  DASHBOARD_ARTICLE_PATH: (slug: string, lang: string = 'en') =>
    `/admin/kb/a/${slug}/${lang}`,
  DASHBOARD_ARTICLE_EDIT_PATH: (slug: string, lang: string = 'en') =>
    `/admin/kb/a/${slug}/${lang}/edit`,

  // KB
  DASHBOARD_KB_NEW_ARTICLE_PATH: '/admin/kb/a/new',

  // Public KB
  PUBLIC_ARTICLE_PATH: (slug: string, lang: string) =>
    `/kb/${lang}/articles/${slug}`,
  PUBLIC_CATEGORY_PATH: (slug: string, lang: string) => `/kb/${lang}/c/${slug}`,

  // Auth Redirect Path
  AUTH_REDIRECT_PATH: (app: string) => `/user/redirect/${app}`,
}
