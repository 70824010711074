import { ArrowRight, Crown } from '@phosphor-icons/react'
import React, { useContext, useMemo, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import type { IButtonSize } from '@/components/shared/ui/Button/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EventEmitter } from '@/lib/eventEmitter'
import { adminSideBillingPath } from '@/lib/helpers/pathHelpers'
import { POWER_UPS, REMOVED_POWER_UPS } from '@/lib/helpers/powerUpsHelper'

interface IPropTypes {
  size?: IButtonSize
  description?: string
  featureName?: string
  buttonText?: string
  className?: string
  showUpgradeButton?: boolean
  canBuyPowerUp?: boolean
  isPopover?: boolean
  image?: string
  moreInfo?: React.ReactNode
}
export default function UpgradeButton({
  size = 'xs',
  description = '',
  featureName,
  buttonText,
  className = '',
  showUpgradeButton = false,
  canBuyPowerUp = false,
}: IPropTypes) {
  const t = useTranslations('common.upgradeButton')
  const { organizationPlan } = useContext(HNContext)
  const descriptionText = description || t('description')
  const [show, setShow] = useState(false)
  const isPowerUp = useMemo(
    () =>
      canBuyPowerUp ||
      POWER_UPS.filter((powerUp) => {
        return organizationPlan?.in_grandfather_plan
          ? true
          : !REMOVED_POWER_UPS.includes(powerUp)
      }).includes(featureName || ''),
    [canBuyPowerUp, featureName]
  )
  const isButtonDisabled = Boolean(
    !organizationPlan?.active_plan_subscription &&
      featureName?.length &&
      organizationPlan?.slug !== 'free'
  )

  const handleClick = () => {
    if (isButtonDisabled) return null
    if (isPowerUp && !showUpgradeButton) {
      return EventEmitter.dispatch('CONFIG_PUSH', {
        event: ENTITIES.TOGGLE_PURCHASE_MODAL,
        data: {
          power_ups: [{ slug: featureName, quantity: 1 }],
        },
        showButtons: false,
      })
    }
    return setShow(true)
  }

  const getButtonText = () => {
    if (isPowerUp && !showUpgradeButton) {
      return t('buttons.getPowerUp', { ignorePrefix: true })
    }
    return buttonText || t('buttons.upgrade', { ignorePrefix: true })
  }

  const trigger = useMemo(
    () => (
      <Button
        as='span'
        size={size}
        variant='warning'
        type='button'
        icon={<Crown weight='bold' className='h-4 w-4 shrink-0' />}
        onClick={() => handleClick()}
        className={className}
        disabled={isButtonDisabled}
      >
        {getButtonText()}
      </Button>
    ),
    [size, buttonText, className, isButtonDisabled]
  )

  return (
    <Dialog
      open={show}
      onOpen={() => setShow(true)}
      onClose={() => setShow(false)}
    >
      <DialogTrigger>{trigger}</DialogTrigger>
      <DialogContent>
        <DialogClose />
        <DialogTitle>{t('title')}</DialogTitle>
        <DialogDescription>{descriptionText}</DialogDescription>
        <div className='mt-4 flex justify-end'>
          <UnstyledLink
            href={adminSideBillingPath('/plans')}
            className='hover:no-underline focus:outline-none'
          >
            <Button
              onClick={() => setShow(false)}
              iconRight={
                <ArrowRight weight='bold' className='h-4 w-4 shrink-0' />
              }
            >
              {t('buttons.goToBilling', { ignorePrefix: true })}
            </Button>
          </UnstyledLink>
        </div>
      </DialogContent>
    </Dialog>
  )
}
