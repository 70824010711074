import { Meilisearch } from 'meilisearch'
import React from 'react'

export const SEARCH_INDEXES = {
  FeatureRequest: 'FeatureRequest',
  Changelog: 'Changelog',
  Article: 'Article',
}

export const HNSearchContext = React.createContext<{
  searchClient: Meilisearch
}>({
  searchClient: new Meilisearch({
    host: process.env.NEXT_PUBLIC_MEILIE_URL || '',
    apiKey: '',
  }),
})

export const useSearchClient = () =>
  React.useContext(HNSearchContext) as {
    searchClient: Meilisearch
  }
