import { EyeClosed } from '@phosphor-icons/react'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import type { ReactNode } from 'react'
import React, { useContext, useEffect, useState } from 'react'

import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { stateMerge } from '@/lib/helpers/appHelpers'
import { pathCleaner, userSidePostSinglePath } from '@/lib/helpers/pathHelpers'
import type { IPost } from '@/types/post'

import { getBasePathPrefix } from '../../lib/helpers/urlHelpers'
import Badge from '../shared/ui/Badge'
import UnstyledLink from '../shared/ui/Links/UnstyledLink'
import DownvoteButton from './DownvoteButton'
import UpvoteButton from './UpvoteButton'
import UserPostInfo from './UserPostInfo'

export interface IUserPostItemProps {
  post: IPost
  className?: string
  hideVotesSection?: boolean
  hideInfoSection?: boolean
  openInNewTab?: boolean
  showDescriptionPlaceholder?: boolean
  actionComponent?: ReactNode
  disableVotes?: boolean
}
export default function UserPostItem({
  post: _post,
  hideVotesSection = false,
  openInNewTab = false,
  actionComponent = null,
  disableVotes = false,
  hideInfoSection = false,
  showDescriptionPlaceholder = false,
  className,
}: IUserPostItemProps) {
  const t = useTranslations()
  const router = useRouter()
  const [post, setPost] = useState<IPost>(_post)
  const { organizationSetting, embedType, organization } = useContext(HNContext)

  const handleUpdate = (newPost: IPost) => {
    stateMerge(newPost, setPost)
  }

  useEffect(() => {
    setPost(_post)
  }, [_post])

  return (
    <div
      itemProp='itemListElement'
      itemScope
      itemType='https://schema.org/ListItem'
      data-testid='user_post_item'
    >
      <div
        className={clsx(
          'flex select-none gap-3 border-b border-gray5 px-4 py-4 hover:bg-gray2',
          className
        )}
        data-testid={`user_post_item_${post.id}`}
      >
        {!hideVotesSection && (
          <div
            className='mt-1 flex min-w-[30px] shrink-0 flex-col space-y-1'
            data-testid='user_post_item_votes_section'
          >
            <UpvoteButton
              size={organizationSetting?.downvotes ? 'sm' : 'md'}
              post={post}
              onUpdate={handleUpdate}
              disabled={disableVotes}
            />
            <DownvoteButton
              size='sm'
              post={post}
              onUpdate={handleUpdate}
              disabled={disableVotes}
            />
          </div>
        )}
        <UnstyledLink
          className='relative mt-0 w-full flex-col items-start space-y-2 overflow-hidden'
          params={{
            widgetKey: router.query?.widgetKey as string,
            JWTToken: router.query?.JWTToken as string,
            b: router.query.boardSlug as string,
          }}
          data-testid='user_post_item_link'
          href={
            embedType === 'changelogWidget'
              ? pathCleaner(
                  userSidePostSinglePath(
                    post.slug,
                    `${organization?.home_page}`
                  )
                )
              : userSidePostSinglePath(post.slug, getBasePathPrefix(embedType))
          }
          target={openInNewTab ? '_blank' : undefined}
          onClick={() =>
            EventEmitter.dispatch('CONFIG_UPDATE', {
              actionType: EVENT_ACTION_TYPES.UPDATE,
              entity: ENTITIES.HEADER_BOARD_NAME,
              data: {
                data: { boardId: post.bucket_id },
              },
            })
          }
        >
          <div className='flex items-center justify-between'>
            <h3 className='text-base font-[500] text-gray12'>{post.title}</h3>
            {post.hidden && (
              <div className='flex items-start justify-end'>
                <Badge
                  variant='outline'
                  rounded
                  size='xxs'
                  icon={<EyeClosed />}
                  value={t('posts.labels.hidden')}
                />
              </div>
            )}
          </div>
          <p className='line-clamp-2 text-[14px] !font-[400] text-gray12/80'>
            {post?.short_description}
          </p>
          {Boolean(
            showDescriptionPlaceholder && !post.short_description.length
          ) && (
            <p className='line-clamp-2 text-[13px] !font-[400] italic text-gray11'>
              {t('posts.labels.noDescription')}
            </p>
          )}
          <div className='flex items-center justify-between'>
            {!hideInfoSection && <UserPostInfo post={post} />}
          </div>
        </UnstyledLink>
        {actionComponent && (
          <div className='inset-y-0 right-2 flex items-center'>
            {actionComponent}
          </div>
        )}
      </div>
    </div>
  )
}
