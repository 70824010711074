import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import Spinner from '@/components/shared/ui/Loader'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getJiraConfig,
  getJiraIssueTypes,
  updateJiraConfig,
} from '@/models/Integration'
import type {
  IAdminJiraIntegrationContentProps,
  IJiraConfigUpdatePayload,
  IJiraIntegrationConfig,
  IJiraPushRule,
} from '@/types/integration/jira'
import toaster from '@/utils/toast'

import AdminIntegrationJiraPushRule from './Jira/AdminIntegrationJiraPushRule'
import AdminIntegrationJiraSyncStatus from './Jira/AdminIntegrationJiraSyncStatus'

export default function JiraConfiguration({
  onDisconnect,
  integrationMapping,
}: IAdminJiraIntegrationContentProps) {
  const t = useTranslations(`integrations.jira`)
  const router = useRouter()
  const { provider, integrated } = router.query
  const [showConfig, setShowConfig] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [configData, setConfigData] = useState<IJiraIntegrationConfig>()
  const [pushRule, setPushRule] = useState<IJiraPushRule>()
  const [jiraIssueTypes, setJiraIssueTypes] = useState([])
  const [jiraStatusList, setJiraStatusList] = useState([])

  const fetchJiraIssueTypes = (projectId: string) => {
    return getJiraIssueTypes(projectId)
      .then((data) => {
        setJiraIssueTypes(data.issuetypes)
        setJiraStatusList(data.jira_status_list)
      })
      .catch((error) => {
        toaster.error({ message: error.message })
      })
  }

  const fetchConfiguration = () => {
    setIsLoading(true)
    return getJiraConfig()
      .then((data) => {
        setConfigData(data)
        setPushRule(data.jira_info)
        return data.jira_info.project_id
      })
      .then((projectId) => {
        if (projectId) return fetchJiraIssueTypes(projectId)
        return null
      })
      .catch((err) => {
        toaster.error({ message: err.message })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const validate = () => {
    if (
      pushRule?.push_to_jira &&
      (!pushRule?.project_id || !pushRule?.issuetype_name)
    ) {
      return toaster.error({
        message: t(`configure.messages.invalidPushToJira`),
      })
    }
    if (pushRule?.push_from_jira != null && !pushRule?.board_id) {
      return toaster.error({
        message: t(`configure.messages.invalidPushFromJira`),
      })
    }
    return true
  }

  const handleSave = (newPushRule: IJiraPushRule) => {
    if (validate()) {
      setIsSubmitting(true)
      updateJiraConfig(newPushRule as IJiraConfigUpdatePayload)
        .then(() => {
          toaster.success({
            message: t(`messages.successfullySaved`, {
              ignorePrefix: true,
            }),
          })
        })
        .catch((err) => {
          toaster.error({ message: err.message })
        })
        .finally(() => {
          setIsSubmitting(false)
        })
    }
  }

  const handlePushRuleChange = (
    newPushRule: IJiraPushRule,
    action?: string
  ) => {
    setPushRule(newPushRule)

    if (action === 'save') {
      handleSave(newPushRule)
    }
  }

  const handleToggleCustomStatus = (checked: boolean) => {
    if (!configData) return null
    return updateJiraConfig({
      ...configData.jira_info,
      sync_by_custom_state: checked,
    }).then(() => {
      setConfigData({
        ...configData,
        jira_info: {
          ...configData.jira_info,
          sync_by_custom_state: checked,
        },
      })
    })
  }

  useEffect(() => {
    if (showConfig) fetchConfiguration()
  }, [showConfig])

  useEffect(() => {
    if (provider === 'jira' && integrated === 'true') {
      setShowConfig(true)
    }
  }, [])

  const renderLoadingOrEmpty = () => {
    if (isLoading) {
      return <Spinner />
    }
    return (
      <EmptyState
        title={t('messages.something_went_wrong', { ignorePrefix: true })}
      />
    )
  }

  const renderConfiguration = () => {
    if (configData && integrationMapping && pushRule) {
      return (
        <>
          <AdminIntegrationJiraPushRule
            configData={configData}
            integrationMapping={integrationMapping}
            onPushRuleChange={handlePushRuleChange}
            jiraIssueTypesData={jiraIssueTypes}
            jiraStatuses={jiraStatusList}
          />
          <AdminIntegrationJiraSyncStatus
            configData={configData}
            pushRule={pushRule}
            onPushRuleChange={handlePushRuleChange}
          />
        </>
      )
    }

    return null
  }

  return (
    <Dialog open={showConfig} onClose={() => setShowConfig(false)}>
      <DialogTrigger asChild>
        <Button size='xs' onClick={() => setShowConfig(true)}>
          {t('buttons.configure', {
            ignorePrefix: true,
          })}
        </Button>
      </DialogTrigger>
      <DialogContent size='lg' backdrop>
        <DialogTitle>
          <div className='flex items-center justify-between'>
            {t(`title`)}
            {!!(!!configData && !isLoading) && (
              <Checkbox
                isSwitch
                defaultChecked={configData?.jira_info.sync_by_custom_state}
                onCheckedChange={handleToggleCustomStatus}
                alertTexts={{
                  title: 'Are sure you want to use custom states?',
                  description:
                    'All your sync rule will be used to sync custom statuses.',
                }}
                disabledAlertTexts={{
                  title: 'Use parent status for sync?',
                  description:
                    'All your sync rule will be used to sync parent statuses.',
                }}
                label='Use custom states'
              />
            )}
          </div>
        </DialogTitle>

        <div className='overflow-y-auto py-3'>
          {isLoading ? renderLoadingOrEmpty() : renderConfiguration()}

          <div className='mb-2 mt-4 flex w-full justify-end space-x-2 '>
            <AlertDialog
              type='danger'
              confirmText={t(`buttons.disconnect`, {
                ignorePrefix: true,
              })}
              title={t(`disconnectConfirmationTitle`)}
              description={t(`disconnectConfirmationMessage`)}
              onConfirm={onDisconnect}
            >
              <Button variant='danger' size='xs' disabled={isSubmitting}>
                {t(`buttons.disconnect`, {
                  ignorePrefix: true,
                })}
              </Button>
            </AlertDialog>
            <Button
              size='xs'
              variant='primary'
              disabled={isSubmitting}
              onClick={() => {
                if (pushRule) handleSave(pushRule)
              }}
            >
              {t(`buttons.save`, {
                ignorePrefix: true,
              })}
            </Button>
          </div>
        </div>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
