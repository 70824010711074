import { PushPin, PushPinSlash } from '@phosphor-icons/react'

import Button from '@/components/shared/ui/Button'
import { useTranslations } from '@/hooks/useTranslations'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
}

export default function SinglePostPinOption(props: IPropTypes) {
  const { post } = props
  const t = useTranslations()

  const handlePostPin = () => {
    if (!post) return null
    toaster.success({
      message: post?.pinned
        ? t('post.messages.pin.remove')
        : t('post.messages.pin.success'),
    })
    return postStore
      .updatePost(
        post.slug,
        { pinned: !post.pinned },
        { ...post, pinned: !post.pinned }
      )
      .catch(toaster.error)
  }

  if (post.can_pin) {
    return (
      <Button
        variant='outline'
        isResponsive
        size='xs'
        className={'group !text-gray12/80 hover:text-gray12'}
        icon={
          post.pinned ? (
            <PushPinSlash
              weight='fill'
              className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
            />
          ) : (
            <PushPin
              weight='fill'
              className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
            />
          )
        }
        onClick={handlePostPin}
      >
        {post.pinned ? t('post.labels.unpinPost') : t('post.labels.pinPost')}
      </Button>
    )
  }
  return <></>
}
