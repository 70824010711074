import { Plus } from '@phosphor-icons/react'
import React, { useMemo } from 'react'

import PriorityIcon from '@/components/posts/PriorityIcon'
import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectPropTypes,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import type { IFilters } from '@/types/common'
import type { IPostListAPIParams } from '@/types/post'

interface IPropType {
  onChange?: (key: string, value: any) => void
  filters: IFilters | IPostListAPIParams
  multiple?: boolean
  placeholder?: string
  disabled?: boolean
  behaviour: string
  clearable?: boolean
  showSelectedOptionsCount?: boolean
  itemFilter?: (item: ISelectOption) => boolean
  moreOptions?: ISelectOption[]
  renderTrigger?: (props: ISelectPropTypes) => JSX.Element
}

const FILTER_KEY = 'priority'

export const PRIORITIES = [
  {
    value: 'urgent',
  },
  {
    value: 'high',
  },
  {
    value: 'medium',
  },
  {
    value: 'low',
  },
  {
    value: 'no_priority',
  },
]

export default function AdminPostPriorityFilter({
  onChange,
  filters,
  multiple = true,
  placeholder = '',
  disabled = false,
  behaviour = 'filter',
  clearable = false,
  showSelectedOptionsCount = true,
  moreOptions = [],
  renderTrigger,
  itemFilter,
}: IPropType) {
  const t = useTranslations('post')
  const priorityOptions = useMemo(() => {
    const prioritysArray = [...PRIORITIES, ...moreOptions]
    return prioritysArray
      .map((s) => ({
        ...s,
        label: t(`priority.${s.value}`),
        icon: (
          <PriorityIcon
            post={{
              priority: s.value,
            }}
          />
        ),
      }))
      .filter(itemFilter || (() => true))
  }, [PRIORITIES, filters])

  const handleChange = (value: ISelectOption | ISelectOption[] | undefined) => {
    if (!onChange) return
    if (!value) return
    const selectedValue = Array.isArray(value) ? value : [value]
    const selectedTag = selectedValue.map((item) => item.value)
    onChange(FILTER_KEY, selectedTag)
  }

  const getValue = () => {
    if (filters && filters[FILTER_KEY]) {
      const filteredSentiment = filters[FILTER_KEY]
      return priorityOptions.filter((s) =>
        filteredSentiment.includes(s.value.toString())
      )
    }
    return null
  }

  return (
    <Select
      size='xs'
      disabled={disabled}
      clearable={clearable}
      searchable
      avoidSort
      value={getValue()}
      onChange={handleChange}
      options={priorityOptions}
      multiple={multiple}
      placeholder={placeholder.toString()}
      placeholderIcon={behaviour === 'filter' ? <></> : <Plus />}
      renderTrigger={renderTrigger}
      inputProps={{
        placeholder,
      }}
      showSelectedOptionsCount={showSelectedOptionsCount}
    />
  )
}
