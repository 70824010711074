import React, { useRef, useState } from 'react'

import Input from '@/components/shared/ui/Input'
import Spinner from '@/components/shared/ui/Loader'
import { useTranslations } from '@/hooks/useTranslations'
import { searchGithubIssues } from '@/models/integration/Github'
import { linkPostAddon } from '@/models/Post'
import type { IGithubSearchIssue } from '@/types/integration/github'
import type { IPost } from '@/types/post'
import type { IPostAddon } from '@/types/post/postAddon'
import toaster from '@/utils/toast'

import GithubIssuesList from './GithubIssuesList'

interface IPropTypes {
  post: IPost
  onLink: (data: IPostAddon) => void
}
export default function LinkGithubIssue({ post, onLink }: IPropTypes) {
  const t = useTranslations(`post.integrations.github`)
  const timer = useRef<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState(``)
  const [issues, setIssues] = useState<IGithubSearchIssue[]>([])

  const handleSearch = (value: string) => {
    if (value.length > 0) {
      setIsLoading(true)
      setQuery(value)
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        searchGithubIssues({ query: value })
          .then((data) => setIssues(data))
          .catch((err) => toaster.error({ message: err.message }))
          .finally(() => setIsLoading(false))
      }, 500)
    } else {
      setQuery(query)
      setIssues([])
      if (timer.current) clearTimeout(timer.current)
      setIsLoading(false)
    }
  }

  const handleSelect = (value: IGithubSearchIssue) => {
    const task_data = {
      reference_id: value.id.toString(),
      repository: value.repository,
      provider: 'github',
      number: value.number.toString(),
    }
    linkPostAddon(post.slug, task_data)
      .then((data) => onLink(data))
      .then(() => toaster.success({ message: t(`postLinkedToGithub`) }))
      .catch((err) => toaster.error({ message: err.message }))
  }

  return (
    <div className='mb-4 mt-3 space-y-4'>
      <div>
        <p
          className='pb-2 text-gray10'
          dangerouslySetInnerHTML={{ __html: t(`searchText`) }}
        />
        <Input
          autoFocus
          placeholder={t(`searchPlaceholder`)}
          onChange={(e) => handleSearch((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className='mt-2.5 rounded transition'>
        {isLoading ? (
          <Spinner />
        ) : (
          <GithubIssuesList
            issues={issues}
            onSelect={handleSelect}
            query={query}
          />
        )}
      </div>
    </div>
  )
}
