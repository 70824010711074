import { useEffect, useState } from 'react'

interface IConfig {
  selector?: string
}
export const useDimension = (
  offset: number,
  config: IConfig = {
    selector: '#main-container',
  }
) => {
  const [windowSize, setWindowSize] = useState({
    width: 1,
    height: 1,
  })

  useEffect(() => {
    const resize_ob = new ResizeObserver((entries) => {
      if (!entries || !entries.length) return
      const rect = entries[0]?.contentRect
      if (!rect) return
      const { width, height } = rect
      setWindowSize({
        width,
        height,
      })
    })

    function handleResize() {
      if (typeof window !== 'undefined') {
        const element: HTMLElement = config?.selector
          ? document.querySelector(config.selector) ||
            (document.querySelector('body') as HTMLElement)
          : (document.querySelector('body') as HTMLElement)
        const changeInHeight =
          Math.abs(
            (element.offsetHeight - windowSize.height) / windowSize.height
          ) * 100
        if (changeInHeight > 10) {
          setWindowSize({
            width: element.offsetWidth + offset,
            height: element.offsetHeight + offset,
          })
        }
        window.addEventListener('resize', handleResize)
        const selectorEl = config?.selector
          ? document.querySelector(config?.selector)
          : null
        if (selectorEl) {
          resize_ob.observe(selectorEl)
        }
      }
    }
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
      const selectorEl = config?.selector
        ? document.querySelector(config?.selector)
        : null
      if (selectorEl) {
        resize_ob.unobserve(selectorEl)
      }
    }
  }, [])

  return windowSize
}
