import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

import AdminIntegrationDiscordBoards from '@/components/admin/settings/Integrations/config/Discord/AdminIntegrationDiscordBoards'
import AdminIntegrationDiscordEvents from '@/components/admin/settings/Integrations/config/Discord/AdminIntegrationDiscordEvents'
import Alert from '@/components/shared/ui/Alert'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import { SpinnerIcon } from '@/components/shared/ui/Icons'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Spinner from '@/components/shared/ui/Loader'
import Select from '@/components/shared/ui/Select'
import type { ISelectOption } from '@/components/shared/ui/Select/Select'
import Typography from '@/components/shared/ui/Typography'
import { integrationHelpLinks } from '@/config/staticURLs'
import { useTranslations } from '@/hooks/useTranslations'
import { EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import {
  convertObjForSelect,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import { templateComponentReplace } from '@/lib/helpers/stringHelpers'
import {
  getIntegrationConfig,
  updateIntegrationConfig,
} from '@/models/Integration'
import type {
  IDiscordConfigurationProps,
  IDiscordIntegrationConfig,
} from '@/types/integration/discord'
import toaster from '@/utils/toast'

export default function DiscordConfiguration({
  integrationMapping,
  onDisconnect,
}: IDiscordConfigurationProps) {
  const t = useTranslations(`integrations.discord`)
  const router = useRouter()
  const { provider, integrated } = router.query
  const [showConfig, setShowConfig] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSyncing, setIsSyncing] = useState(false)
  const [configData, setConfigData] = useState<IDiscordIntegrationConfig>()
  const [channels, setChannels] = useState<ISelectOption[]>([])
  const [isUpdating, setIsUpdating] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const fetchConfig = () => {
    if (!configData) {
      setIsLoading(true)
    } else {
      setIsSyncing(true)
    }
    getIntegrationConfig('discord')
      .then((data) => {
        setErrorMessage(null)
        setChannels(convertObjForSelect(data?.channels, `name`, `id`))
        if (configData) {
          setConfigData({ ...configData, ...data })
        } else {
          setConfigData(data)
        }
      })
      .catch((err) => {
        if (err.message === 'ERROR_CODE_RESOURCE_DISCORD_RESTRICTED_ACCESS') {
          setErrorMessage(
            t('errors.ERROR_CODE_RESOURCE_DISCORD_RESTRICTED_ACCESS', {
              ignorePrefix: true,
            })
          )
        } else {
          toaster.error({ message: err.message })

          if (err.message === 'ERROR_CODE_INTEGRATED_BOT_REMOVED') {
            EventEmitter.dispatch('ENTITY_UPDATE', {
              actionType: EVENT_ACTION_TYPES.UPDATE,
              entity: 'INTEGRATION_LIST',
              data: {
                data: { provider: 'discord' },
              },
            })
            setShowConfig(false)
          }
        }
      })
      .finally(() => {
        setIsLoading(false)
        setIsSyncing(false)
      })
  }

  const updateConfigData = (data: IDiscordIntegrationConfig) => {
    setConfigData(data)
  }

  const handleUpdate = (value: ISelectOption) => {
    if (!configData) return null
    const data = {
      message_channel_id: value.id,
    }
    setIsUpdating(true)
    return updateIntegrationConfig('discord', configData.id?.toString(), data)
      .then((config: IDiscordIntegrationConfig) => {
        updateConfigData(config)
        toaster.success({ message: t(`messages.successfullySaved`) })
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => setIsUpdating(false))
  }

  useEffect(() => {
    fetchConfig()
  }, [])

  useEffect(() => {
    if (provider === 'discord' && integrated === 'true') {
      setShowConfig(true)
    }
  }, [])

  useEffect(() => {
    if (showConfig) fetchConfig()
  }, [showConfig])

  const renderLoadingOrEmpty = () => {
    if (isLoading) return <Spinner />

    if (!configData || !integrationMapping)
      return (
        <EmptyState
          title={t('messages.something_went_wrong', { ignorePrefix: true })}
        />
      )

    return null
  }

  const renderRestrictedAlertMessage = () => {
    if (!errorMessage) return <></>
    const message = templateComponentReplace(
      t('errors.ERROR_CODE_RESOURCE_DISCORD_RESTRICTED_ACCESS', {
        ignorePrefix: true,
      }),
      {
        learnMore: (
          <UnstyledLink
            href={integrationHelpLinks?.integrationDiscord || ''}
            target='_blank'
            className='text-primary hover:no-underline dark:text-primary-dark'
          >
            {`${t('buttons.learnMore', { ignorePrefix: true })} ->`}
          </UnstyledLink>
        ),
      }
    )
    return <Alert rounded type='info' message={message} />
  }

  return (
    <Dialog open={showConfig} onClose={() => setShowConfig(false)}>
      <DialogTrigger asChild>
        <Button size='xs' onClick={() => setShowConfig(true)}>
          {t('buttons.configure', { ignorePrefix: true })}
        </Button>
      </DialogTrigger>
      <DialogContent mode='drawer'>
        <DialogTitle>
          <div className='flex items-center justify-between'>
            {t(`configure.title`)}
            {isSyncing && <SpinnerIcon className='h-4 w-4 animate-spin' />}
          </div>
        </DialogTitle>
        {isLoading || !configData || !integrationMapping ? (
          renderLoadingOrEmpty()
        ) : (
          <>
            <Typography.Text className='whitespace-pre-line !text-gray8 '>
              {t(`configure.description`)}
            </Typography.Text>

            <div className='mt-2 flex flex-col'>
              {renderRestrictedAlertMessage()}
              <Typography.Text className='mt-2 !font-medium !text-gray11 '>
                {t(`channels`)}
              </Typography.Text>
              <Typography.Text className='mb-2 text-gray8'>
                {t(`channelDescription`)}
              </Typography.Text>
              <Select
                searchable
                options={channels}
                value={
                  configData?.message_channel_id
                    ? setSelectedValue(channels, configData?.message_channel_id)
                    : null
                }
                placeholder={t(`selectPlaceholder`)}
                onChange={(value) => handleUpdate(value as ISelectOption)}
                disabled={isUpdating}
              />
            </div>
            <AdminIntegrationDiscordEvents
              configData={configData}
              integrationMapping={integrationMapping}
              onChange={updateConfigData}
            />
            <AdminIntegrationDiscordBoards
              configData={configData}
              onChange={updateConfigData}
            />
            <AlertDialog
              type='danger'
              confirmText={t(`buttons.disconnect`, {
                ignorePrefix: true,
              })}
              title={t(`disconnectConfirmationTitle`)}
              description={t(`disconnectConfirmationMessage`)}
              onConfirm={onDisconnect}
            >
              <Button variant='danger' size='xs'>
                {t(`buttons.disconnect`, {
                  ignorePrefix: true,
                })}
              </Button>
            </AlertDialog>
          </>
        )}
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
