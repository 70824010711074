import React, { useMemo } from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectPropTypes,
} from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import {
  getSelectedValueByKey,
  setSelectedValue,
} from '@/lib/helpers/dataHelpers'
import { titleize } from '@/lib/helpers/stringHelpers'
import type { IAsanaCustomFieldSetting } from '@/types/integration/asana'
import type { IAsanaIntegrationStatusSyncRule } from '@/types/integration/statusSync'

interface IAdminIntegrationCustomFieldsDropDownProps {
  syncRule: IAsanaIntegrationStatusSyncRule
  provider: string
  edit: boolean
  ruleKey: string
  onChange: (key: string, value: string) => void
  projects: ISelectOption[]
  size?: ISelectPropTypes['size']
}

export default function AdminIntegrationCustomFieldsDropDown({
  syncRule,
  provider,
  edit,
  onChange,
  projects,
  ruleKey,
}: IAdminIntegrationCustomFieldsDropDownProps) {
  const t = useTranslations(`integrations.${provider}`)
  const selectedProjects = useMemo(
    () => projects.find((project) => project.gid === syncRule.project_id),
    [projects, syncRule.project_id]
  )

  const parseCustomFields = useMemo(() => {
    if (!selectedProjects) return []
    return (
      selectedProjects?.custom_field_settings
        ?.filter(
          (_customField: IAsanaCustomFieldSetting) =>
            _customField.custom_field.resource_subtype === 'enum'
        )
        ?.map((data: any) => {
          return {
            ...data.custom_field,
            label: titleize(data.custom_field.name),
            value: data.custom_field.gid,
          }
        }) || []
    )
  }, [selectedProjects])

  const getSelectedValue = () => {
    if (typeof syncRule.trigger_status === 'object') {
      return syncRule.trigger_status?.custom_field_id
    }
    if (typeof syncRule.status === 'object') {
      return syncRule.status?.custom_field_id
    }
    return ''
  }

  return (
    <Select
      placeholder={t(`configure.syncRule.customFields.placeholder`)}
      searchPlaceholder={t(`configure.syncRule.customFields.searchPlaceholder`)}
      loading={false}
      value={setSelectedValue(parseCustomFields || [], getSelectedValue())}
      searchable
      size='xs'
      options={parseCustomFields}
      onChange={(value) => {
        const selectedValue = getSelectedValueByKey(
          parseCustomFields || [],
          value
        )
        if (selectedValue && Array.isArray(selectedValue)) {
          onChange(ruleKey, selectedValue[0]?.value)
        } else {
          onChange(ruleKey, selectedValue?.value)
        }
      }}
      disabled={!edit || !selectedProjects}
    />
  )
}
